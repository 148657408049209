import { CommonModule } from "@angular/common";
import { Component, ElementRef, Renderer2, ViewChild, inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
/* eslint-disable */
import { IonicModule, ModalController, NavParams } from "@ionic/angular";
import { GlobalVarsModel } from "@models/index";
import { TranslateModule } from "@ngx-translate/core";
import { TrackingService } from "@providers/TrackingService";
import { EventTypes } from "../../models/TrackingEvents";
import { TriggerTypes } from "../../models/csbgonlinecasinobonuses/bonus-enums";
import { LogService, SeverityLevel, TraceCategory } from "../../providers/LogService";
import { SlotsBonusesService } from "../../providers/SlotsBonusesService";
import { NewBaseService } from "../../providers/newBase.service";
import { DeviceService } from "../../services/device.service";

@Component({
  templateUrl: "./playtech-iframe-pc.html",
  selector: "playtech-iframe-page",
  styleUrls: ["./playtech-iframe-pc.scss"],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule],
})

/** This component opens the view \Codere.SBGOnlineGames.Bonuses\Playtech\BonusAcceptDecline inside an iframe.
 * The script inside this iframe uses IMS JavaScript API to try to get the messages for the user.
 * This sholud be invoked only after checking that there are bonuses in waiting status, which should
 * be sent in navParams waitingBonuses.
 * There are two possible outcomes:
 * 1) the api gets a message, and the user accepts or declines it
 * 2) the api is unable to get messages after trying several times to do so
 * After finishing this process, the iframe posts a message with the outcome of the execution.
 * If the api couldn't find messages, no more actions are taken
 * If the user accepted or declined, invoke showBonusesWaitingMessages in case there are more messages
 */
export class PlaytechIframePage {
  url: any;
  iframePostedMessagesListener: (event: any) => void;

  /** List of bonuses in waiting status, received from ShowBonusWaitingMessages, because of which this page was shown */
  private waitingBonuses: IWaitingBonusInfo[];
  @ViewChild("iframe") iframe: ElementRef;
  cont = 0;

  // private viewCtrl: ViewController, // **+** ViewController deprecado
  globalVars!: GlobalVarsModel;
  isDesktop;

  modalCtrl = inject(ModalController);
  navParams = inject(NavParams);
  sanitizer = inject(DomSanitizer);
  renderer = inject(Renderer2);
  element = inject(ElementRef);
  trackingService = inject(TrackingService);
  logService = inject(LogService);
  slotsBonusesService = inject(SlotsBonusesService);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);

  constructor() {
    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
    this.isDesktop = this.deviceService.isDesktop();
    this.waitingBonuses = this.navParams.get("waitingBonuses");
    // At this moment, you can't do nothing with the type of bonuses, because there is no guarantee that a message
    // for a given bonus will appear when calling GetWaitingMessages. See:
    // https://codere.visualstudio.com/cSBGOnline/_wiki/wikis/cSBGOnline.wiki/31/Available-API-functions-to-show-messages-to-the-user

    let url;
    if (this.waitingBonuses?.length > 0) {
      if (this.waitingBonuses[0].RemoveUrl) {
        url = `${this.globalVars.URLBASE_CASINO_BONUSES}${this.waitingBonuses[0].RemoveUrl}/BonusAcceptDecline`;
      } else {
        url = `${this.globalVars.URLBASE_CASINO_BONUSES}/PlayTech/BonusAcceptDecline`;
      }
    }

    if (url) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.iframePostedMessagesListener = this.listenEvents.bind(this);
    }
  }

  ionViewWillEnter() {
    window.addEventListener("message", this.iframePostedMessagesListener);
  }

  ionViewWillLeave() {
    window.removeEventListener("message", this.iframePostedMessagesListener);
  }

  close() {
    if (!!this.modalCtrl) {
      this.modalCtrl.dismiss();
    }
  }

  // These events are posted from the script BonusAccettDecline.js, executed inside the iframe
  listenEvents(event: any) {
    // Determine the type of message based on the existance of a property, and do the corresponding actions
    // It can be IBonusAction, INoMessages or ITrace
    const postedData = event.data;
    if (!!postedData.whatHappened) {
      // Bonus action: user accepted or declined
      const bonusAction = <IBonusAction>postedData;
      // At least a waiting message was found, and the user accepted or declinen it
      /* console.log(
        `The view in the iframe informed that the user ${bonusAction.whatHappened} the bonus with code ${bonusAction.bonusInstanceCode}`
      ); // ` */
      const eventType: EventTypes =
        bonusAction.whatHappened == "accepted" ? EventTypes.CasinoBonusAccepted : EventTypes.CasinoBonusDeclined;
      // The accepted bonus should be in the list of received bonus waiting bonuses
      const bonusData = this.waitingBonuses.filter((b) => b.bonusInstanceCode == bonusAction.bonusInstanceCode);
      const bonusDataFound = bonusData.length === 1 ? bonusData[0] : null;
      const triggerTypeName = bonusDataFound != null ? bonusDataFound.triggerTypeName : "(unknown trigger)";
      this.trackingService.track({
        eventType: eventType,
        description: `${bonusAction.whatHappened} ${triggerTypeName} Bonus with instance code ${bonusAction.bonusInstanceCode}`,
        additionalData: { bonusData: bonusDataFound, bonusAction: bonusAction },
      });
      // If the bonus was accepted, and its trigger is Deposit, or unknown, cancel the sport bonus if needed
      if (
        bonusAction.whatHappened == "accepted" &&
        (bonusDataFound == null || bonusDataFound.triggerType == TriggerTypes.Deposit)
      ) {
        const Hab = "/habanero";
        if (this.waitingBonuses[0]?.RemoveUrl !== Hab) {
          this.slotsBonusesService
            .cancelSportBonusIfNeeded(bonusAction.bonusInstanceCode)
            .subscribe((result: ICancelSportBonusOutcome) => {
              this.trackingService.track({
                eventType: EventTypes.CasinoBonusCancelSportBonusIfNeeded,
                description: `Result of cancelSportBonusIfNeeded, invoked by accepting ${bonusAction.bonusInstanceCode}: ${result.ProcessDescription}`,
                additionalData: {
                  result: result,
                  bonusData: bonusDataFound,
                  bonusAction: bonusAction,
                },
              });
            });
        }
      }
      // As the user accepted or declined, it's necessary to close the modal...
      this.modalCtrl.dismiss();
      // ... and  invoke showBonusesWaitingMessages so that this modal is shown again if there are additional messages.
      this.checkForMoreBonuses();
    } else if (!!postedData.reason) {
      // No messages could be retrieved, so the modal must be closed, and no extra actions should be executed
      const noMessages = <INoMessages>postedData;
      this.trackingService.track({
        eventType: EventTypes.CasinoBonusNotAvailableMessages,
        description: noMessages.reason,
        additionalData: noMessages,
      });
      // As no messages were found, close the modal, and don't try any more
      this.modalCtrl.dismiss();
    } else if (!!postedData.traceType) {
      // This is a trace, log it
      const traceData = <ITrace>postedData;
      // Serialize parameters and response
      if (!!traceData.parameters) {
        traceData.parameters = JSON.stringify(traceData.parameters);
      }
      if (!!traceData.response) {
        traceData.response = JSON.stringify(traceData.response);
      }
      const msgPrefix = `${traceData.traceType} ${traceData.functionName}`; // `
      switch (traceData.traceType) {
        case "functionCall":
        case "apiResponse":
          this.logService.trace(TraceCategory.WaitingMessages, SeverityLevel.Verbose, msgPrefix, traceData);
          break;
        case "calloutAction":
          this.logService.trace(
            TraceCategory.WaitingMessages,
            SeverityLevel.Verbose,
            `${msgPrefix}: ${traceData.nextAction}`,
            traceData,
          ); // `
          break;
        case "apiErrorResponse":
        case "error":
          this.logService.trace(
            TraceCategory.WaitingMessages,
            SeverityLevel.Error,
            `${msgPrefix}: ${traceData.error}`,
            traceData,
          ); // `
          break;
      }
    }
  }

  reSize(obj) {
    if (this.iframe) {
      const container = this.iframe.nativeElement.contentDocument.querySelector("html> body >#bonusCasino");
      const divTextIframe = this.iframe.nativeElement.contentDocument.querySelector(
        "html> body >#bonusCasino >#contentbonus",
      );
      if (divTextIframe) {
        this.renderer.setStyle(container, "overflow", "auto");
      }
    }
  }

  setScroll() {
    if (this.iframe) {
      const container = this.iframe.nativeElement.contentDocument.querySelector("html> body >#bonusCasino");
      if (container) {
        this.renderer.setStyle(container, "overflow-y", "scroll");
        this.renderer.setStyle(container, "-webkit-overflow-scrolling", "touch");
      }
    }
  }

  checkForMoreBonuses() {
    setTimeout(() => {
      this.slotsBonusesService.showBonusesWaitingMessages();
    }, 15000);
  }
}

/** Shape of the information in the events posted from the view in the iframe to this module */
interface IBonusAction {
  /** Indicates what the user did with the bonus
   */
  whatHappened: "accepted" | "declined";

  /** Includes the bonus instance code (as recovered from SearchPlayerBonuses) */
  bonusInstanceCode: number;

  /** As received in the API callback to calloutGetWaitingMessages (template name) */
  templateId: string;

  /* Indicates if there are remaining bonuses, apart from the one the user acted on.
   *  Not possible at the moment, because GetWaitingMessages doesn't return a message for each available bonus. See:
   * https://codere.visualstudio.com/cSBGOnline/_wiki/wikis/cSBGOnline.wiki/31/Available-API-functions-to-show-messages-to-the-user
   */
  //remainingBonuses: boolean;
}

/** This message is received when no messages were found, so that the modal can be closed and the loop broken */
interface INoMessages {
  /** Function which sent the no messages notification */
  functionName: string;
  /** Reason for which the no messages notification is shown: either an error, or no messages found */
  reason: string;
}

interface ITrace {
  traceType: "error" | "functionCall" | "apiErrorResponse" | "apiResponse" | "calloutAction";
  functionName: string;
  /** For function calls */
  parameters?: {};
  /** For api responses, with or w/o errors */
  response?: {};
  /** For error, and apiErrorResponse */
  error?: string;
  /** For calloustAction */
  nextAction?: string;
}

/**
 * Indicates what happened when trying to cancel the bonus. If the cancellation succeded, or was not necessary,
 * the Cancelled property is true. ProcessDescription includes information about the cancellation
 */
interface ICancelSportBonusOutcome {
  OriginalPagerValue: string;
  NeededCancellation: boolean;
  Cancelled: boolean;
  ProcessDescription: string;
}

export interface IWaitingBonusInfo {
  marketingName: string;
  name: string;
  triggerType: TriggerTypes;
  /** To obtaing, use TriggerTypes[triggerTypeValue] */
  triggerTypeName: string;
  bonusInstanceCode: number;
  RemoveUrl: string;
}
