/* eslint-disable eqeqeq */
import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild, inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { EventTypes } from "@models/TrackingEvents";
import { Bonus } from "@models/csbgonlinecasinobonuses/Bonus";
import { ContactPage } from "@mso-pages/contact/contact/contact";
import { TranslateModule } from "@ngx-translate/core";
import { SlotsBonusesService } from "@providers/SlotsBonusesService";
import { TrackingService } from "@providers/TrackingService";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { CASINO_PATHS } from "@shared-constants/routes";
import { Utils } from "@utils/Utils";
import { ExtractDatePipe } from "@pipes/extractDate.pipe";
import { CONSTANTS } from "@constants";
import { CommonModule } from "@angular/common";
import { LicenseTypes } from "@models/MasterData";
import { CasinoCategorySliderNewComponent } from "@components/casino/lobby/casino-category-slider-new/casino-category-slider-new.component";
import { LobbyFacadeService } from "@services/casino/loobyFacade.service";
import { Category } from "@models/lobby/casinoDataModels";

@Component({
  selector: "slot-bonus-detail",
  templateUrl: "./slot-bonus-detail.html",
  styleUrls: ["./slot-bonus-detail.scss"],
  standalone: true,
  imports: [IonicModule, TranslateModule, ExtractDatePipe, CommonModule, CasinoCategorySliderNewComponent],
})

//eslint-disable-next-line
export class SlotBonusDetail {
  @Input() bonus: Bonus;
  iframeUrl: SafeResourceUrl;
  globalVars!: GlobalVarsModel;
  @ViewChild("iframe") iframe: ElementRef;
  cont = 0;
  eventClick = null;
  @Output() Refresh: EventEmitter<any> = new EventEmitter();
  isMobile = false;
  provider = "";
  newBaseService = inject(NewBaseService);
  slotsBonusesService = inject(SlotsBonusesService);
  router = inject(Router);
  trackingService = inject(TrackingService);
  utils = inject(Utils);
  renderer = inject(Renderer2);
  deviceService = inject(DeviceService);
  lobbyFacadeService = inject(LobbyFacadeService);
  licenseTypes = LicenseTypes;
  BonusTypes = CONSTANTS.bonusTypes;
  percentage = 0;
  cateWithGames: Category = {
    Name: "Bonus",
    Order: 1,
    type: "Bonus",
    Games: [],
    typeCategory: "Bonus",
    grid: "",
    DySelector: "",
    JackpotId: "",
    additionalData: "",
    BackgroundImageUrl: "",
  };
  activeGames: any;
  category$: BehaviorSubject<any> = new BehaviorSubject(false);
  fakeGames = [
    { gameId: "7eaed3df-3531-ea11-9504-000d3a226503" },
    { gameId: "0a7ae9c9-df41-ea11-9504-000d3a226503" },
    { gameId: "1f60326d-d038-e811-94e9-000d3a226503" },
    { gameId: "ccf34d08-f71a-e911-94f5-000d3a226503" },
    { gameId: "087ae9c9-df41-ea11-9504-000d3a226503" },
    { gameId: "31c54f33-e242-e811-94e9-000d3a226503" },
    { gameId: "74e9903a-c967-e811-94e9-000d3a226503" },
    { gameId: "ada2a603-9036-ee11-951f-000d3a226503" },
  ];
  fakeGamesMob = [
    { gameId: "6a7ae9c9-df41-ea11-9504-000d3a226503" },
    { gameId: "6c7ae9c9-df41-ea11-9504-000d3a226503" },
    { gameId: "04822bdb-5895-e811-94ee-000d3a226503" },
    { gameId: "acd7062a-f71a-e911-94f5-000d3a226503" },
    { gameId: "b44549f0-de42-e811-94e9-000d3a226503" },
    { gameId: "e5d540d0-b151-e711-94e7-000d3a226503" },
  ];

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit() {
    this.provider = this.bonus?.RemoveUrl?.replace("/", "");
    this.provider = this.provider[0]?.toUpperCase() + this.provider.slice(1);
    this.calculatePercentage();
    this.iframeUrl = this.slotsBonusesService.getUserBonusDetailIframeUrl(this.bonus);
    if (this.globalVars.FEATURES.CAS_BonusActiveGames) {
      this.setGames();
    }
  }

  async setGames() {
    let gamesSet;
    this.activeGames = await this.lobbyFacadeService.getGames();
    if (this.bonus.SelectedGames) {
      //juegos reales
      gamesSet = this.getGames(this.bonus.SelectedGames);
    } else {
      //juegos fake para probar
      gamesSet = this.getGames(this.isMobile ? this.fakeGamesMob : this.fakeGames);
    }
    if (gamesSet.length > 0 && gamesSet.length <= 20) {
      this.cateWithGames.Games = gamesSet;
      this.category$.next(true);
    }
  }

  getGames(gameList) {
    const returnGames = [];
    gameList.map((game) => {
      const setter = this.activeGames[game.gameId];
      if (setter) {
        const newGame = Object.assign({}, setter);
        newGame.GameId = game.gameId;
        newGame.gameTileSize = "Square";
        newGame.imageType = setter.DefaultImageType;
        newGame.lobby = "Bonus";
        newGame.product = "Casino";
        returnGames.push(newGame);
      }
    });
    return returnGames;
  }

  playGame(pGame) {
    pGame.game.GameCategoryType = this.cateWithGames.typeCategory + this.bonus.RemoteBonusId;
    pGame.game.GameCategoryName = this.cateWithGames.typeCategory + this.bonus.RemoteBonusId;
    this.lobbyFacadeService.playGame(pGame);
  }

  ngAfterViewInit() {
    this.iframe = this.renderer.selectRootElement("#iframe");
  }

  setCancelBtn() {
    const container = this.iframe.nativeElement.contentDocument.querySelector(
      "html > body > .wrapper > .left > .divtit",
    );

    if (container) {
      const div: HTMLDivElement = this.renderer.createElement("div");
      div.innerHTML =
        '<button #eventclick class="btnBonusCancel" style="all: unset; color: #FB3737 !important; text-decoration: underline 2px #FB3737;padding-top: 0.4rem;font-weight: 400;font-size: 0.9rem;cursor: pointer;"><span>Cancelar bono</span></button>';
      this.renderer.appendChild(container, div);
      const btn = this.iframe.nativeElement.contentDocument.querySelector(
        "html > body > .wrapper > .left > .divtit > div > .btnBonusCancel",
      );
      setTimeout(() => {
        this.eventClick = this.renderer.listen(btn, "click", (ev) => {
          this.cancelBonus(ev);
        });
      }, 10);
    }
  }

  async cancelBonus(event) {
    const msn =
      "Si cancelas perderás de tu saldo la cantidad correspondiente al bono que está compuesta por el Importe actual de bono y las Ganancias del bono.";
    const alertOptions = {
      enableBackdropDismiss: false,
      title: "BONO - " + this.bonus.MarketingName,
      cssClass: "bonusCancelCasino",
      message: msn,
      buttons: [
        {
          role: "eliminar",
          text: "Cancelar bono",
          handler: () => {
            this.cancelbonusService();
          },
        },
        {
          role: "cancel",
          text: "Quiero mi bono",
          handler: () => {},
        },
      ],
    };
    const alert = await this.utils.alertController.create(alertOptions);
    alert.present();
  }

  cancelbonusService() {
    const PT = "/playtech";
    const provider = this.bonus.RemoveUrl ? this.bonus.RemoveUrl : PT;
    this.slotsBonusesService
      .cancelBonusCasino(this.bonus.BonusInstanceCode, this.globalVars.user.username, provider)
      .subscribe(async (result) => {
        if (result.Error === "0") {
          this.trackingService.track({
            eventType: EventTypes.CasinoBonusCancelActiveBonus,
            description: `Result of cancelCasinoBonus, invoked by accepting ${this.bonus.BonusInstanceCode} of user ${this.globalVars.user.username}`,
            additionalData: {
              result: result,
            },
          });
          this.Refresh.emit(true);
        } else {
          //"Error": "UMS-67" Sesión de juego abierta
          //"Error":"UMS-105" No se a encontrado el bono que borrar
          let msn = "";
          if (result.Error == "UMS-67") {
            msn = "Lo sentimos no se ha podido cancelar tu bono por tener una sesión de juego abierta";
            const alertOptions = {
              enableBackdropDismiss: false,
              title: "Error",
              message: msn,
              buttons: [
                {
                  role: "cancel",
                  text: "Ok",
                  handler: () => {},
                },
              ],
            };
            const alert = await this.utils.alertController.create(alertOptions);
            alert.present();
          } else {
            msn =
              "Lo sentimos ha habido un problema al cancelar tu bono, por favor ponte en contacto con nuestro servicio de atención al cliente";
            const alertOptions = {
              enableBackdropDismiss: false,
              title: "Error",
              message: msn,
              buttons: [
                {
                  role: "ok",
                  text: "Contacto",
                  handler: () => {
                    this.openContact();
                  },
                },
                {
                  role: "cancel",
                  text: "Ok",
                  handler: () => {},
                },
              ],
            };
            const alert = await this.utils.alertController.create(alertOptions);
            alert.present();
          }
        }
      });
  }

  openContact() {
    this.router.navigate(["/" + ContactPage]);
  }

  playBonus() {
    let params;
    if (
      this.globalVars.FEATURES.CAS_BonusBalanceBDRedirectMOB !== undefined &&
      this.globalVars.FEATURES.CAS_BonusBalanceBDRedirectPC !== undefined
    ) {
      params = this.isMobile
        ? JSON.parse(this.globalVars.FEATURES.CAS_BonusBalanceBDRedirectMOB)
        : JSON.parse(this.globalVars.FEATURES.CAS_BonusBalanceBDRedirectPC);
    }
    let lobby = this.globalVars.FEATURES.Lobbies.find((lb) => lb.LobbyName.toLowerCase().indexOf("playtech") < 0);

    lobby === undefined ? (lobby = this.globalVars.FEATURES.Lobbies[0].LobbyName) : (lobby = lobby.LobbyName);

    if (params !== undefined && params !== "") {
      this.router.navigate([`/${CASINO_PATHS.BASE}/${CASINO_PATHS.CasinoPage}`], { queryParams: params });
    } else {
      if (!!this.bonus.SelectedGames && this.bonus.SelectedGames.length == 1) {
        // Navigate to casino lobby and play game
        this.router.navigate([`/${CASINO_PATHS.BASE}/${CASINO_PATHS.CasinoPage}`], {
          queryParams: { lobby: lobby, playgame: this.bonus.SelectedGames[0] },
        });
      } else {
        // Navigate to casino lobby
        this.router.navigate([`/${CASINO_PATHS.BASE}/${CASINO_PATHS.CasinoPage}`], {
          queryParams: { lobby: lobby },
        });
      }
    }
  }

  isSpain(): boolean {
    return (
      this.globalVars?.licenseType === LicenseTypes?.PaisVasco ||
      this.globalVars?.licenseType === LicenseTypes?.Nacional ||
      this.globalVars?.licenseType === LicenseTypes?.Madrid
    );
  }
  calculatePercentage() {
    // desde el back  se calcula y viene siendo el wageredrequired - wageringremine / wageredrequired
    // si el wagered required tiene 0 se divide entre 1 para que no de error
    if (this.bonus.BonusType === 1) {
      this.percentage = Math.round(
        ((this.bonus.FreeSpinCount - this.bonus.FreeSpinRemain) / this.bonus.FreeSpinCount) * 100,
      );
    } else if (this.bonus.BonusType === 6) {
      this.percentage = Math.round(
        ((this.bonus.GoldenChipsCount - this.bonus.GoldenChipsRemain) / this.bonus.GoldenChipsCount) * 100,
      );
    } else {
      this.percentage = Math.round(
        ((this.bonus.WageringRequirement - this.bonus.WageringRemain) /
          (this.bonus.WageringRequirement > 0 ? this.bonus.WageringRequirement : 1)) *
          100,
      );
    }
  }
}
