import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FirebaseCrashlytics, LogOptions } from "@capacitor-firebase/crashlytics";

@Injectable({
  providedIn: "root",
})
export class FirebaseCrashlyticsService {
  constructor() {}

  async crash(message: string) {
    await FirebaseCrashlytics.crash({ message });
  }

  async setUserId() {
    await FirebaseCrashlytics.setUserId({
      userId: "123",
    });
  }

  async setEnabled(enabled: boolean) {
    await FirebaseCrashlytics.setEnabled({
      enabled,
    });
  }

  async isEnabled() {
    const result = await FirebaseCrashlytics.isEnabled();
    return result.enabled;
  }

  async didCrashDuringPreviousExecution() {
    const result = await FirebaseCrashlytics.didCrashOnPreviousExecution();
    return result.crashed;
  }

  async sendUnsentReports() {
    await FirebaseCrashlytics.sendUnsentReports();
  }

  async deleteUnsentReports() {
    await FirebaseCrashlytics.deleteUnsentReports();
  }

  /**
   * Adds a custom log message that is sent with your crash data to
   * give yourself more context for the events leading up to a crash.
   */
  async addLogMessage(message: string) {
    const opt: LogOptions = {
      message,
    };
    await FirebaseCrashlytics.log(opt);
  }

  /**
   * Records a non-fatal report to send to Crashlytics.
   */
  async recordException(message: string) {
    await FirebaseCrashlytics.recordException({
      message,
    });
  }

  async recordRequestError(error: HttpErrorResponse): Promise<void> {
    if (error.message && !error.ok) {
      await this.recordException(JSON.stringify(error.message));
    }
  }
}
