import { Route } from "@angular/router";
import { AuthGuard } from "@guards/auth.guard";
import { ModalGuard } from "@guards/modal.guard";
import { PAYMENTS_PATHS } from "@shared-constants/routes";
import { CashierPage } from "@shared-payments-pages/cashier/cashier";
import { ChargeCoderePage } from "./pages/charge-codere/charge-codere";
import { CheckTicketStep2Page } from "./pages/check-ticket-step-2/check-ticket-step-2";
import { CheckTicketPage } from "./pages/check-ticket/check-ticket";
import { PlaySstPage } from "./pages/playsst/playsst";

export const PAYMENTS_ROUTES: Route[] = [
  {
    path: "",
    pathMatch: "full",
    canActivate: [AuthGuard, ModalGuard],
    component: CashierPage,
    data: { openAsPage: "MOBILE", pcCssClass: "modalCashier is-modal" },
  },
  {
    path: PAYMENTS_PATHS.PLAYSSTPAGE,
    canActivate: [AuthGuard],
    component: PlaySstPage,
  },
  {
    path: PAYMENTS_PATHS.CashierPage,
    canActivate: [AuthGuard, ModalGuard],
    component: CashierPage,
    data: { openAsPage: "MOBILE", pcCssClass: "modalCashier is-modal" },
  },
  {
    path: PAYMENTS_PATHS.ChargeCoderePage,
    canActivate: [AuthGuard],
    component: ChargeCoderePage,
  },
  {
    path: PAYMENTS_PATHS.CheckTicketPage,
    canActivate: [AuthGuard],
    component: CheckTicketPage,
    data: { section: "user" },
  },
  {
    path: PAYMENTS_PATHS.CheckTicketStep2Page,
    canActivate: [AuthGuard],
    component: CheckTicketStep2Page,
    data: { section: "user" },
  },
];
