import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { BaseFilter } from "../base-filter/base-filter";

@Component({
  selector: "options-filter",
  imports: [IonicModule, CommonModule, TranslateModule],
  standalone: true,
  providers: [],
  templateUrl: "./options-filters.component.html",
  styleUrls: ["./options-filters.component.scss"],
  host: {
    class: "options-filters",
  },
})
export class OptionsFiltersComponent extends BaseFilter {
  constructor() {
    super();
  }
}
