import * as MyBetsActions from "./my-bets.actions";
import { MyBetsEffects } from "./my-bets.effects";
import { MyBetsFacade } from "./my-bets.facade";
import { myBetsReducer } from "./my-bets.reducers";

export { MyBetsEffects } from "./my-bets.effects";
export { MyBetsFacade } from "./my-bets.facade";
export { myBetsReducer, initialState, MyBetsState } from "./my-bets.reducers";
export * as MyBetsActions from "./my-bets.actions";

export const MyBetsStoreIndex = [MyBetsActions, MyBetsEffects, MyBetsFacade, myBetsReducer];
