/* eslint-disable */
import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { Router } from "@angular/router";
import { IonicModule, ModalController } from "@ionic/angular";
import { C_ItemMenu } from "@models/C_ItemMenu";
import { C_ItemsMenu } from "@models/C_ItemsMenu";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { LangChangeEvent, TranslateModule, TranslateService } from "@ngx-translate/core";
import { EventsService } from "@providers/EventsService";
import { NavService } from "@providers/NavService";
import { TrackingService } from "@providers/TrackingService";
import { UserService } from "@providers/UserService";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { CASINO_PATHS, MSO_PATHS, PAYMENTS_PATHS, SPORTS_PATHS } from "@shared-constants/routes";
import { Login } from "@shared-pages/mso/login/login";
import { Utils } from "@utils/Utils";
import { Subscription } from "rxjs";
@Component({
  selector: "codere-user-sidebar",
  templateUrl: "./codere-user-sidebar.html",
  styleUrls: ["./codere-user-sidebar.scss"],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule],
})
export class CodereUserSidebar implements OnInit, OnDestroy {
  //@Inject(forwardRef(() => TrackingService)) private trackingService: TrackingService;
  globalVars!: GlobalVarsModel;
  isMobile: boolean;
  currentPage: string;
  langChangeSubscription: Subscription;
  title: string;
  contact: string;

  translate = inject(TranslateService);
  router = inject(Router);
  navService = inject(NavService);
  modalController = inject(ModalController);
  deviceService = inject(DeviceService);
  newBaseService = inject(NewBaseService);
  userService = inject(UserService);
  utils = inject(Utils);
  events = inject(EventsService);
  trackingService = inject(TrackingService);

  constructor() {
    this.isMobile = this.deviceService.isMobile();
    // last route path from current route, e.g.: CashierPage from /payments/CashierPage
    this.currentPage = this.router.url.split("/").at(-1);
    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
    this.langChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // Forzar la actualización de la vista
      this.title = this.translate.instant("uPHBet");
      this.contact = this.translate.instant("tContac");
    });
  }

  ngOnInit() {
    this.title = this.translate.instant("uPHBet");
    this.contact = this.translate.instant("tContac");
  }

  ngOnDestroy() {
    // Limpiar la suscripción al cambiar de idioma
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }
  gotoContact() {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.ContactPage}`]);
  }

  openMisApuestas() {
    this.trackingService.trackEvent(["AccessToBetHistoryClick", "", "", "", "event"]);
    const filterParam = { dateValue: 4, typeValue: 1 };
    this.router.navigate([SPORTS_PATHS.MisApuestasPage], { state: { params: filterParam } });
  }

  /**
   * Collapse dropdowns or navigate main user links
   * @param menu Dropdown with items or main link option
   */
  async categoryMenuClick(menu: C_ItemsMenu) {
    if (menu.items.length === 0) {
      if (menu.link === PAYMENTS_PATHS.CheckTicketPage) {
        this.router.navigate([`/${PAYMENTS_PATHS.BASE}/${menu.link}`]);
      } else if (menu.link === PAYMENTS_PATHS.CashierPage) {
        const params = menu.params ? menu.params : {};
        this.globalVars.rootScope.openModalOrPage(
          `${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CashierPage}`,
          params,
          true,
          "modalCashier is-modal",
        );
      } else {
        this.router.navigate([`/${MSO_PATHS.BASE}/${menu.link}`]);
      }
    } else {
      menu.collapsed = !menu.collapsed;
    }
  }

  /**
   * Navigate to dropdown item page
   * @param menuItem Item link within a dropdown
   */
  async itemMenuClick(menuItem: C_ItemMenu) {
    if (menuItem.label !== "Opciones de apuesta" && menuItem.link !== "LanguagePage") {
      if (!this.userService.userLogged) {
        const modal = await this.modalController.create({ component: Login, cssClass: "is-modal" });
        modal.onDidDismiss().then((data) => {
          if (this.userService.userLogged) this.switchMenuClick(menuItem);
        });
        modal.present();
      } else {
        this.switchMenuClick(menuItem);
      }
    } else {
      this.switchMenuClick(menuItem);
    }
  }

  private async switchMenuClick(item: C_ItemMenu) {
    this.currentPage = item.label;
    switch (item.link) {
      case PAYMENTS_PATHS.CashierPage:
        const params = item.params ? item.params : {};
        this.globalVars.rootScope.openModalOrPage(
          `${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CashierPage}`,
          params,
          true,
          "modalCashier is-modal",
        );
        break;
      case SPORTS_PATHS.MisApuestasPresencialesPage:
        this.router.navigate([`/${SPORTS_PATHS.BASE}/${SPORTS_PATHS.MisApuestasPresencialesPage}`]);
        break;
      case SPORTS_PATHS.FreeBetsPage:
        this.router.navigate([`/${SPORTS_PATHS.BASE}/${SPORTS_PATHS.FreeBetsPage}`]);
        break;
      case CASINO_PATHS.SlotsHistoryPage:
        this.router.navigate([`/${CASINO_PATHS.BASE}/${CASINO_PATHS.SlotsHistoryPage}`]);
        break;
      case CASINO_PATHS.SlotsBonusPage:
        this.router.navigate([`/${CASINO_PATHS.BASE}/${CASINO_PATHS.SlotsBonusPage}`]);
        break;
      case MSO_PATHS.CardLinkPage:
        this.userService.checkCanSendCodere().subscribe(
          (data) => {
            if (!data.success) {
              this.router.navigate([`/${MSO_PATHS.BASE}/${item.link}`, { key: Date.now() }]);
            } else {
              this.utils.confirm(
                false,
                this.translate.instant("tFail"),
                this.translate.instant("associateCard"),
                this.translate.instant("tCancel"),
                () => {},
                this.translate.instant("check"),
                () => {
                  this.events.publish("documentverification:open");
                },
                false,
                "displayrow",
              );
            }
          },
          (err) => {
            this.utils.confirm(
              false,
              this.translate.instant("tFail"),
              this.translate.instant("associateCard"),
              this.translate.instant("tCancel"),
              () => {},
              this.translate.instant("check"),
              () => {
                this.events.publish("documentverification:open");
              },
              false,
              "displayrow",
            );
          },
        );

        break;
      case MSO_PATHS.ResponsibleGamblingTestPage:
        if (this.globalVars.isNative) {
          this.utils.openLinkInInAppBrowser(this.globalVars.FEATURES.MSO_NewUrlResponsibleGamblingTest);
        } else {
          window.open(this.globalVars.FEATURES.MSO_NewUrlResponsibleGamblingTest, "_blank");
        }

        break;
      default:
        if (
          item.link === MSO_PATHS.SelfExclusionPage &&
          this.globalVars.licenseType == LicenseTypes.Colombia &&
          this.globalVars.extraUserData.isRevocationPage
        ) {
          this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.SelfExclusionPage}`], {
            queryParams: { revocation: true },
          });
        } else {
          this.router.navigate([`/${MSO_PATHS.BASE}/${item.link}`, { key: Date.now() }]);
        }
        break;
    }
  }
}
