export class C_UserColombia {
  genero: string;
  documentType: string;
  documentId: string;
  name: string;
  lastName: string;
  lastName2: string;
  birthDate: string;
  placeBirth: string;
  documentDate: string;
  documentPlace: string;
  expirationDate: string;
  countryBirth: string;
  nationality: string;
  department: string;
  municipality: string;
  adress: string;
  postalCode: string;
  email: string;
  cellPhone: string;
  landLinePhone: string;
  customerId: string;
  login: string;
  interdiction: boolean;
  dayLimit: string;
  weekLimit: string;
  monthLimit: string;
  documentValidation: boolean;
  password: string;
  voucher: string;
  smsAccept: boolean;
  emailAccept: boolean;
  origen: string;
  device: string;
  preferredPayment: string;
  cardnumber: string;
  pin: string;
  job: string;
  nameRetry: boolean;

  constructor() {
    // ¿Necesita constructor??
    this.genero = "";
    this.documentType = "";
    this.documentId = "";
    this.name = "";
    this.lastName = "";
    this.lastName2 = "";
    this.birthDate = "";
    this.placeBirth = "";
    this.documentDate = "";
    this.documentPlace = "";
    this.expirationDate = "";
    this.countryBirth = "";
    this.nationality = "";
    this.department = "";
    this.municipality = "";
    this.adress = "";
    this.postalCode = "";
    this.email = "";
    this.cellPhone = "";
    this.landLinePhone = "";
    this.customerId = "";
    this.login = "";
    this.interdiction = false;
    this.dayLimit = "";
    this.weekLimit = "";
    this.monthLimit = "";
    this.documentValidation = false;
    this.password = "";
    this.voucher = "";
    this.preferredPayment = "";
    this.cardnumber = "";
    this.pin = "";
    this.job = "";
    this.nameRetry = false;
  }
}
