import { CommonModule } from "@angular/common";
import { Component, HostBinding, OnInit, Renderer2, inject } from "@angular/core";
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-empty-function */
import { IonicModule, ModalController } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { EventsService } from "@providers/EventsService";
import { UserServiceMSO } from "@providers/UserServiceMSO";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { IonLoaderService } from "@services/loader-feature/ion-loader.service";
import { Utils } from "@utils/Utils";
import { from } from "rxjs";
import { TabComponent } from "../../components/tabs/tab.component";
import { TabsComponent } from "../../components/tabs/tabs.component";
import { BonusComponent } from "./bonus/bonus";

// eslint-disable-next-line no-shadow
export enum PostMessageActions {
  AvailableBonusRequest = "AvailableBonusRequest",
  AvailableBonusResponse = "AvailableBonusResponse",
  PromotionsOptIn = "PromotionsOptIn",
  PromotionsOptOut = "PromotionsOptOut",
  GoToPage = "GoToPage",
}

export interface BonoData {
  promoId: string;
  promoProduct: string;
  promoType?: string;
  tyc?: string;
  promoStart?: string;
  promoEnd?: string;
}

export interface FormatBono {
  bono: BonoData;
  img?: string;
  title?: string;
  txt?: string[];
  tyc?: string;
  button: { txt: string; cssClass: string };
  tabTitle?: string;
}

export interface ResponseBono {
  template: Template;
  bonosToShow: string;
  bonos: FormatBono[];
}

export type Template = "Separate" | "InOne" | "OneclickAll";

@Component({
  selector: "welcome-bonus-page",
  templateUrl: "./welcome-bonus.html",
  styleUrls: ["./welcome-bonus.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, TabsComponent, TabComponent, BonusComponent],
})
export class WelcomeBonusPage implements OnInit {
  totalBonus: ResponseBono;
  textAllInOneBonus: { title: string; subtitle: string }[];

  isDesktop = false;

  currentbono = 0;

  globalVars!: GlobalVarsModel;

  viewCtrl = inject(ModalController);
  translate = inject(TranslateService);
  utils = inject(Utils);
  newBaseService = inject(NewBaseService);
  userServiceMSO = inject(UserServiceMSO);
  events = inject(EventsService);
  deviceService = inject(DeviceService);
  render2 = inject(Renderer2);
  ionLoaderService = inject(IonLoaderService);

  constructor() {
    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
  }

  @HostBinding("class.OneclickAll") get classOneclickAll() {
    return this.totalBonus && this.totalBonus.template === "OneclickAll";
  }

  ngOnInit(): void {
    this.isDesktop = this.deviceService.isDesktop();
    this.events.subscribe("closeWelcomeBonus", () => {
      this.viewCtrl.dismiss();
    });

    this.getNewBonus();
  }

  setCssClass() {
    const modalWrapper = document.querySelector(".modal-welcomebonus");
    if (this.totalBonus.template === "InOne" || this.totalBonus.template === "OneclickAll") {
      this.render2.addClass(modalWrapper, "WBinOne");
      if (this.totalBonus.template === "OneclickAll") {
        this.render2.addClass(modalWrapper, "OneclickAllmodal");
      }
    } else if (this.isDesktop && this.totalBonus.bonosToShow.toLowerCase() !== "all") {
      this.render2.addClass(modalWrapper, "oneWB");
    }
  }

  closeModal() {
    const cssAlert =
      (this.globalVars.FEATURES.MSO_NewReBranding ? "backgroundheader " : "") + "closedwelcomebonus";
    this.utils.confirm(
      false,
      "",
      '<div class="iconalert"><img src="assets/global/img/icon-warning-grey.png" alt="Warning"></div>' +
        "<br>" +
        '<div class="txtalertMessageCustom">Si sale ahora, perderá su Bono de Bienvenida</div>',
      "Cerrar",
      async () => {
        await this.declineBonus();
      },
      "Volver",
      () => {},
      false,
      cssAlert,
    );
  }

  private async declineBonus() {
    this.ionLoaderService.showHideAutoLoader();
    const promises = [];
    const bonustodecline: BonoData[] = [];
    if (this.totalBonus.template === "InOne" || this.totalBonus.template === "OneclickAll") {
      bonustodecline.push(...this.totalBonus.bonos.map((fb) => fb.bono));
    } else {
      this.totalBonus.bonos.forEach(({ bono }) => {
        if (this.canShowPromo(bono.promoProduct)) {
          bonustodecline.push(bono);
        }
      });
    }

    from(bonustodecline).subscribe((data) => {
      promises.push(this.doService(data, PostMessageActions.PromotionsOptOut, false));
    });

    try {
      const data = await Promise.all(promises);
      console.info(data);
    } catch (error) {
      console.error(error);
    }
    this.ionLoaderService.hideLoader();
    if (this.viewCtrl) {
      this.viewCtrl.dismiss("Home");
    }
  }
  private doService(e: BonoData, action: PostMessageActions, loader = true, showAlertConfirm: boolean = true) {
    if (e !== null) {
      if (loader) {
        this.ionLoaderService.showHideAutoLoader();
      }
      switch (action) {
        case PostMessageActions.PromotionsOptIn:
          this.userServiceMSO.setPromotionsOptIn(e).subscribe(async (data) => {
            if (loader) {
              this.ionLoaderService.hideLoader();
            }
            if (!data.promotableErrors && showAlertConfirm) {
              const titleCase: string = e.promoProduct === "Sport" ? "Apuestas Deportivas" : "Casino";
              const msg: string =
                e.promoProduct === "Sport"
                  ? `${this.translate.instant("welcomebonussportsok")} ${titleCase}!<br> <br>${this.translate.instant(
                      "welcomebonussportsok02",
                    )}`
                  : `${this.translate.instant("welcomebonuscasinook")} ${titleCase}!`;
              const cssAlert =
                (this.globalVars.FEATURES.MSO_NewReBranding
                  ? "backgroundheader " + (e.promoProduct !== "Sport" ? "btnconfirmcasino " : "")
                  : "") + "alertokbonus";
              const alert = await this.utils.alert(
                false,
                "",
                '<div class="iconalert"><img src="assets/global/img/icon-check-green.png" alt="OK"></div>' +
                  "<br>" +
                  msg,
                this.translate.instant("welcomebonusokclose"),
                () => {
                  if (this.viewCtrl) {
                    this.viewCtrl.dismiss(e.promoProduct);
                  }
                },
                cssAlert,
              );
              const nodeAlert = alert.querySelector(".alert-wrapper");
              const tycToShow = this.totalBonus.bonos.find((fb) => fb.bono.promoId === e.promoId).tyc;
              nodeAlert.firstElementChild.nextElementSibling.innerHTML += `<div class="alert-footer"><a external-link href="${tycToShow}" target="_blank">${this.translate.instant(
                "InviteFriendtxt06",
              )}</a></div>`;
            } else {
              this.events.publish("bonusServiceEnd", data);
              return new Promise((resolve) => resolve(data));
            }
          });
          break;
        case PostMessageActions.PromotionsOptOut:
          const promise = this.userServiceMSO.setPromotionsOptOutCO(e).toPromise();
          if (loader) {
            this.ionLoaderService.hideLoader();
          }
          return promise;
        default:
          if (loader) {
            this.ionLoaderService.hideLoader();
          }
          break;
      }
    }
  }

  listenEvents(e: string) {
    const bonusAcept: BonoData = this.totalBonus.bonos.map((fb) => fb.bono).find((b) => b.promoProduct === e);
    this.doService(bonusAcept, PostMessageActions.PromotionsOptIn);
  }

  private getNewBonus() {
    this.ionLoaderService.showHideAutoLoader();
    this.userServiceMSO.getGetWelcomeBousInfoNew().subscribe({
      next: (data) => {
        this.totalBonus = data;
        if (this.totalBonus.template === "InOne" || this.totalBonus.template === "OneclickAll") {
          this.textAllInOneBonus = this.formatAllInOneBono(this.totalBonus.bonos[0].txt);
        }
        this.setCssClass();
      },
      error: (error) => {
        this.ionLoaderService.hideLoader();
      },
      complete: () => {
        this.ionLoaderService.hideLoader();
      },
    });
  }

  canShowPromo(type: string): boolean {
    const bonoToShow = this.totalBonus.bonosToShow.toLowerCase();
    return bonoToShow === "all" || bonoToShow === type.toLowerCase();
  }

  formatAllInOneBono(txtBono: string[]): { title: string; subtitle: string }[] {
    const txt = txtBono.map((t) => {
      const tformat = t.split("~");
      return {
        title: tformat[0] ?? "",
        subtitle: tformat[1] ?? "",
      };
    });

    return txt;
  }

  async presentAlertConfirmBonus(index: number) {
    const bono: BonoData = this.totalBonus.bonos[index].bono;
    const msg: string = `${this.translate.instant("welcomebonusconfirm01")} ${this.translate.instant(
      bono.promoProduct === "Casino" ? "welcomebonusconfirm02" : "welcomebonusconfirm03",
    )}`;
    const cssAlert = this.globalVars.FEATURES.MSO_NewReBranding
      ? "backgroundheader " + (bono.promoProduct !== "Sport" ? "btnconfirmcasino " : "")
      : "";
    this.newAlertAcceptBono([bono], msg, cssAlert);
  }

  async acceptOptinWBAll() {
    const msg = `${this.translate.instant("welcomebonusconfirm01")} <br><br> ${this.translate
      .instant("welcomebonusconfirm04")
      .toUpperCase()}`;
    this.newAlertAcceptBono(
      this.totalBonus.bonos.map((bono) => bono.bono),
      msg,
      "",
      this.totalBonus.bonos[0].tyc,
    );
  }

  private async newAlertAcceptBono(bonus: BonoData[], msg: string = "", cssAlert: string = "", tyc?: string) {
    const alert = await this.utils.alert(
      false,
      "",
      `<span class="closeIcon"><i class="color-dark codere-icon icon-close" item-right=""></i></span>` +
        '<div class="iconalert"><img src="assets/global/img/icon-check-green.png" alt="OK"></div>' +
        "<br>" +
        msg,
      this.translate.instant("welcomebonusokclose"),
      () => {
        const request: Promise<any>[] = [];
        bonus.forEach((bono) => {});
        Promise.all(
          bonus.map((bono) => this.doService(bono, PostMessageActions.PromotionsOptIn, true, false)),
        ).then(() => {
          alert.dismiss();
          if (this.viewCtrl) {
            this.viewCtrl.dismiss(bonus.map((bono) => bono.promoProduct).join(";"));
          }
        });
      },
      `alertokbonus alertWbInOne ${cssAlert}`,
    );
    const nodeAlert = alert.querySelector(".alert-wrapper");
    if (tyc) {
      nodeAlert.firstElementChild.nextElementSibling.innerHTML += `<div class="alert-footer"><a external-link href="${tyc}" target="_blank">${this.translate.instant(
        "InviteFriendtxt06",
      )}</a></div>`;
    }
    const icon = nodeAlert.querySelector(".closeIcon");
    icon.addEventListener("click", () => {
      alert.dismiss();
    });
  }
}
