/* eslint-disable eqeqeq */
import { Injectable, inject } from "@angular/core";
import { AlertController, ModalController, NavController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { SlotsConfigPage } from "../../pages/casino/slots-config/slots-config";

// pipes
import { ParseMoney } from "../../pipes/parseMoney";
// Providers
import { BaseService } from "../../providers/BaseService";

import { LicenseTypes } from "@models/index";
import { CookieNames } from "../../models/CookieNames";
// Models
import { Game } from "../../models/lobby/casinoDataModels";
import { BalancesService } from "../../providers/BalancesService";
import { CookiesService } from "../../providers/CookiesService";
import { LogService } from "../../providers/LogService";
import { NativeService } from "../../providers/NativeService";
import { SignalRService } from "../../providers/SignalRService";
import { ErrorAlertSportsCasinoGeolocation, ILicenseFilter } from "../../providers/licenseFilter/ILicenseFilter";
import { FilterFactory } from "../../providers/licenseFilter/filter.factory";
import { LobbyTrackingService } from "./lobbytracking.service";

import { Router } from "@angular/router";
import { CONSTANTS } from "@constants";
import { EventTypes } from "@models/index";
import { SlotsService } from "@providers/SlotsService";
import { CASINO_PATHS } from "@shared-constants/routes";
import { firstValueFrom, lastValueFrom } from "rxjs";

import { VerificationAlerts } from "@providers/VerificationAlert";
import { DeviceService } from "@services/device.service";

//MSO Geolocation Mendoza
import { GeolocationService } from "@providers/GeolocationService";

@Injectable({ providedIn: "root" })
export class GameService extends BaseService {
  alert10Min;
  firstCloseEvent = false;
  lastAdviceTime: number;
  loginSubscribed = false;
  selectedGame: Game;
  showedLast10seconds = false;
  isMobile: boolean;
  lessThan60MinutesMessage: string;

  cookiesService = inject(CookiesService);
  nativeService = inject(NativeService);
  translate = inject(TranslateService);
  signalRService = inject(SignalRService);
  alertCtrl = inject(AlertController);
  balancesService = inject(BalancesService);
  logService = inject(LogService);
  modalController = inject(ModalController);
  navCtrl = inject(NavController);
  parseMoney = inject(ParseMoney);
  slotsService = inject(SlotsService);
  lobbyTrackingService = inject(LobbyTrackingService);
  router = inject(Router);
  deviceService = inject(DeviceService);
  verificationAlertService = inject(VerificationAlerts);
  geolocationService = inject(GeolocationService);

  constructor() {
    super();
    this.isMobile = this.deviceService.isMobile();
  }

  /**
   * Opens login modal if the use is not logged in, used when a user clicks a game
   */
  openLogin() {
    if (!this.globalVars.user.logged) {
      const callBackLogin = () => {
        if (this.globalVars.user?.logged) {
          this.events.publish("gameLoginSuccesful");
        }
      };
      this.globalVars.rootScope.openLogin(callBackLogin);
    }
  }

  async playGame(game) {
    if (this.globalVars.user.logged && this.globalVars.extraUserData.acceptanceComunication) {
      return;
    }
    if (game && game.game) {
      const demo = game.demo;
      game = game.game;
      // demo no existe siempre
      game.demo = demo;
    }
    if (game?.lobbyType === undefined) {
      game.lobbyType = this.getLobbyType(game);
    }
    this.selectedGame = game;

    if (this.globalVars.user.logged) {
      if (this.globalVars?.LICENSE_TYPE === LicenseTypes.Colombia) {
        this.verificationAlertService.checkDNIExpired();
      }
      if (!this.globalVars.extraUserData.blockBets) {
        this.checkGameStart(game);
      }
    } else {
      switch (this.globalVars.licenseType) {
        case LicenseTypes.Nacional:
          this.globalVars.afterLoginParamsUrl = game;
          this.openLogin();
          break;
        default:
          //Comments by BAU
          //Con modal info+demo
          // this.openModal(game);
          this.lobbyTrackingService.track(game, { event: EventTypes.GameDescriptionOpened });
          this.globalVars.afterLoginParamsUrl = game;
          this.openLogin();
          break;
      }
    }
  }
  private checkGameStart(game: Game) {
    if (this.globalVars.playingMiniGame) {
      this.openConfirm(game);
    } else if (game.demo) {
      this.startDemo(game);
    } else {
      this.startGame(game);
    }
  }

  private startDemo(game: Game) {
    if (this.isMobile) {
      this.globalVars.navigateToSlotsGame = true;
      const backButton = this.slotsService.slotsBackButtonEnabled(game.Name);
      const lobbyType = this.utils.getLobbyFromUrl();
      this.router.navigate([`/${CASINO_PATHS.SlotsGamePage}`], {
        queryParams: {
          url: game.DemoUrl,
          buttonenabled: backButton,
          animate: this.isMobile,
          lobbyType: lobbyType,
        },
        state: { prevPage: this.router.url },
      });
      this.navCtrl.navigateBack(["/SlotsGamePage"], {
        queryParams: {
          url: game.DemoUrl,
          buttonenabled: backButton,
          animate: this.isMobile,
          lobbyType: lobbyType,
        },
      });
    } else {
      window.open(game.DemoUrl, CONSTANTS.demoTarget, CONSTANTS.demoFeatures);
    }
    this.lobbyTrackingService.track(game, { isDemo: true });
  }

  private startGame(game: Game) {
    if (this.globalVars.user?.logged) {
      const filter: ILicenseFilter = FilterFactory.getFilter(
        this.globalVars.licenseType,
        this.translate,
        this.newBaseService,
      );

      if (filter && !filter.canPlayCasino()) {
        this.pruebaAlertCasinosGeolocation(filter.geolocationAlertCabaErrorCasino());
        return;
      }
      if (filter && filter.isOrdenCongelamientoFondos()) {
        this.utils.alert(false, "Error", this.translate.instant("accountanalysisplay"), "OK", () => {});
        return;
      }

      this.slotsService.checkSession(this.globalVars.user?.username, this.isCasinoLobbyType(game)).subscribe(
        async (data) => {
          if (data != null) {
            if (!data.Reason) {
              data.SessionType = CONSTANTS.slotsLobbyName;
              data.CanPlay = false;
              data.Less60 = false;
              data.DailyLimit = false;
              data.Reason = CONSTANTS.ok;
            }
            //Comprobar  la sesion
            if (data.SessionType == CONSTANTS.slotsLobbyName) {
              if (data.CanPlay) {
                //session abierta
                if (this.isCasinoLobbyType(game)) {
                  //if is CasinoGame, close session slot and launch game
                  await this.checkLimits(false, true);
                } else {
                  this.initGame();
                }
              } else {
                //session cerrada
                this.checkSlotsSession(data, game);
              }
            } else {
              this.checkSession(data, game);
            }
          } else {
            const title = this.translate.instant("tFail");
            const msg = this.translate.instant("tfailgames1") + game.Name + this.translate.instant("tfailgames2");
            this.utils.alert(true, title, msg, CONSTANTS.ok, null);
          }
        },
        (err) => {
          console.error("GameService.startGame ", err);
        },
      );
    } else {
      //login
      this.globalVars.afterLoginUrl = null;
      this.loginSubscribed = false;
      this.listenLoginEvent();
      this.nativeService.checkTouchId();
    }

    this.lobbyTrackingService.track(game, {});
  }

  private checkSlotsSession(data, game) {
    /* this.onOpenModal(game); */
    if (data.Less60) {
      //menos de 60min
      if (data.AutoExclusion) {
        this.utils.alert(true, "", this.translate.instant("accessSlots"), CONSTANTS.ok, null);
      } else {
        this.lessThan60MinutesMessage = this.translate.instant("exceededSession");
        const min = data.ElapsedTime || 1;
        if (this.globalVars?.LICENSE_TYPE === LicenseTypes.Nacional) {
          this.lessThan60MinutesMessage = `${this.lessThan60MinutesMessage}<br><strong>${this.translate.instant(
            "casino_GSC_elapsed_time",
            { minutes: min },
          )}</strong>`;
        }
        this.openConfig(game);
      }
    } else {
      //mas de 60min
      if (data.DailyLimit) {
        this.utils.alert(true, this.translate.instant("Attention"), data.Reason, CONSTANTS.ok, () => {
          this.startGame(game);
        });
      } else {
        this.openConfig(game);
      }
    }
  }

  private checkSession(data, game) {
    const cantPlay =
      data.SessionType == null && !data.CanPlay && !data.Less60 && !data.AutoExclusion && !data.DailyLimit;
    let title = "";
    let msg = "";
    let handler = null;
    let btn = CONSTANTS.ok;

    switch (true) {
      case cantPlay:
        title = this.translate.instant("tFail");
        msg =
          data.Reason === CONSTANTS.casinoReasonPlayCasino
            ? this.translate.instant("noCasino")
            : this.translate.instant("noslots");
        if (this.globalVars.FEATURES.isOrdenCongelamientoFondos && this.globalVars.isOrdenCongelamientoFondos) {
          msg = this.translate.instant("accountanalysischarge");
        }
        break;
      case data.AutoExclusion:
        //esto es para cuando el usuario se pone límtes entre sesiones - tiempo de descanso
        if (data.Less60) {
          title = this.translate.instant("accessSlotsTitle");
          msg = this.translate.instant("accessSlotsRestTime");
        } else {
          //cuando el usurio está auoexcluido
          msg = this.translate.instant("accessSlots");
        }
        break;
      case !data.CanPlay && data.Reason.toLowerCase() == CONSTANTS.casinoReasonPlaySlotsLc:
        if (!this.isCasinoLobbyType(game)) {
          msg = this.translate.instant("noslots");
          if (
            this.globalVars.licenseType === LicenseTypes.Colombia &&
            this.globalVars.extraUserData.pptNotVerification
          ) {
            handler = () => {
              this.events.publish("documentverification:open");
            };
            msg = this.translate.instant("pptnocasinonoslots");
            btn = this.translate.instant("veridoc");
          }
        } else {
          this.initGame();
        }
        break;
      case !data.CanPlay && data.Reason.toLowerCase() == CONSTANTS.casinoReasonPlayCasinoLc:
        if (this.isCasinoLobbyType(game)) {
          msg = this.translate.instant("noCasino");
          if (
            this.globalVars.licenseType === LicenseTypes.Colombia &&
            this.globalVars.extraUserData.pptNotVerification
          ) {
            handler = () => {
              this.events.publish("documentverification:open");
            };
            msg = this.translate.instant("pptnocasinonoslots");
            btn = this.translate.instant("veridoc");
          }
        } else {
          this.openConfig(game);
        }
        break;
      default:
        this.slotsService.closeSlotSessions();
        this.startGame(game);
        break;
    }
    if (msg !== "") {
      this.utils.alert(true, title, msg, btn, handler);
    }
  }

  private async checkLimits(timeout: boolean, launchGame = false) {
    //checks if logged or cookie
    const userCookie: string = this.cookiesService.getCookie(CookieNames.CodereUser);
    const user: string = userCookie ? userCookie : this.globalVars.user?.username;
    if (user) {
      this.slotsService.getSessionLimits().subscribe(
        async (sessionLimits) => {
          if (
            sessionLimits?.SessionType == CONSTANTS.slotsLobbyName ||
            sessionLimits?.SessionType == "Bingo" ||
            this.isSpain()
          ) {
            this.slotsService.scores.wins = parseFloat(sessionLimits?.WinsValue);
            this.slotsService.scores.bets = parseFloat(sessionLimits?.BetsValue);
            this.slotsService.scores.balance = parseFloat(sessionLimits?.SessionBalanceValue);
            if (!this.globalVars.slotsAdviceIn) {
              this.globalVars.slotsAdviceIn = sessionLimits?.AdviceIn;
            }
            if (sessionLimits?.Active) {
              //hay session activa
              this.globalVars.user.playingSlots = true;
              switch (true) {
                case timeout:
                case launchGame:
                case sessionLimits?.SessionBalanceValue * -1 >= sessionLimits?.AmountUserLimit ||
                  sessionLimits?.TimePlaying >= sessionLimits?.TimePlayingLimit:
                  await this.showResume(
                    sessionLimits?.WinsValue,
                    sessionLimits?.BetsValue,
                    sessionLimits?.SessionBalanceValue,
                    launchGame,
                  );
                  break;
                case sessionLimits?.TimePlaying * 1000 - this.lastAdviceTime > this.globalVars.slotsAdviceIn:
                  if (this.alert10Min) {
                    this.alert10Min.dismiss();
                  }
                  this.alert10Min = this.showAlertSlot(
                    CONSTANTS.notice,
                    CONSTANTS.timePlaying +
                      (sessionLimits?.TimePlaying / 60).toFixed(0) +
                      CONSTANTS.moneyPlayed +
                      this.parseMoney.transform(sessionLimits?.BetsValue, [1]),
                  );
                  break;
                case sessionLimits?.TimePlaying + 10 >= sessionLimits?.TimePlayingLimit &&
                  !this.showedLast10seconds:
                  this.showedLast10seconds = true;
                  if (
                    !this.globalVars.slotWindow ||
                    (!!this.globalVars.slotWindow && !this.globalVars.slotWindow.window)
                  ) {
                    if (this.alert10Min) {
                      this.alert10Min.dismiss();
                    }
                    this.alert10Min = this.showAlertSlot(CONSTANTS.notice, CONSTANTS.tenMinutesRemaining);
                  }
                  break;
                default:
                  break;
              }
            } else {
              this.globalVars.user.playingSlots = false;
            }
          } else {
            if (sessionLimits?.Active) this.slotsService.closeSlotSessions(true);
          }
        },
        (err) => {
          console.error(err);
        },
      );
    }
  }

  private initGame() {
    const filter: ILicenseFilter = FilterFactory.getFilter(
      this.globalVars.licenseType,
      this.translate,
      this.newBaseService,
    );

    if (filter && !filter.canPlayCasino()) {
      this.pruebaAlertCasinosGeolocation(filter.geolocationAlertCabaErrorCasino());
      return;
    }
    if (filter && filter.isOrdenCongelamientoFondos()) {
      this.utils.alert(false, "Error", this.translate.instant("accountanalysisplay"), "OK", () => {});
      return;
    }
    //geolocation mendoza
    if (this.globalVars.FEATURES.MSO_EnableGeolocation && this.globalVars.FEATURES.MSO_TimerGeolocation) {
      this.geolocationService.initTimerGeolocation();
    }
    //end-geolocation-mendoza
    this.slotsService.initGame(
      this.selectedGame.ProviderId,
      this.selectedGame.GameId,
      this.selectedGame.lobbyType ? this.selectedGame.lobbyType.toLowerCase() : "",
      this.selectedGame.Name,
      null,
      "",
      this.selectedGame.GameCategoryName,
      this.selectedGame.dyId,
      this.selectedGame.product,
      this.selectedGame.lobby,
      this.selectedGame.isIframe,
    );
    this.initTimer();
  }

  async openConfig(game: Game) {
    if (this.isCasinoLobbyType(game)) {
      this.initGame();
    } else {
      const modalConfig = await this.modalController.create({
        component: SlotsConfigPage,
        componentProps: {
          item: game,
          typeGame: CONSTANTS.slot,
          lessThan60MinutesMessage: this.lessThan60MinutesMessage,
        },
        cssClass: "slots-config",
      });
      await modalConfig.present();
      this.initTimer();
      modalConfig.onDidDismiss().then(() => {
        this.lessThan60MinutesMessage = "";
      });
    }
  }

  /* async onOpenModal(game: Game): Promise<void> {
    const modal = await this.modalController.create({
      component: SlotsConfigPage,
    });
    await modal.present();
  } */

  private initTimer() {
    if (this.globalVars.timerSlots) {
      clearInterval(this.globalVars.timerSlots);
    }

    this.globalVars.timerSlots = setInterval(
      async () => {
        if (this.globalVars.user?.playingSlots) {
          await this.checkLimits(false);
        }
      },
      1000 * (this.isMobile ? 15 : 5),
    );
  }

  private async showResume(wins, bets, balance, launchGame = false) {
    try {
      clearInterval(this.globalVars.timerSlots);
      // eslint-disable-next-line no-empty
    } catch (err) {}
    if (bets != "0" || launchGame) {
      //show session resume
      let msg: string = this.translate.instant("hPay") + ": " + this.parseMoney.transform(bets, [1]) + "<br>";
      msg += this.translate.instant("prizes") + ": " + this.parseMoney.transform(wins, [1]) + "<br>";
      const buttons = [
        {
          text: CONSTANTS.ok,
          handler: async () => {
            await firstValueFrom(this.slotsService.closeSlotSessions());
            if (launchGame) {
              this.initGame();
            }
            this.balancesService.refreshBalance();
          },
        },
      ];
      if (this.alert10Min) {
        this.alert10Min.dismiss();
      }
      this.showAlertSlot(this.translate.instant("SessionSummary"), msg, buttons);
    }
  }

  private openConfirm(game) {
    this.utils.confirm(
      true,
      CONSTANTS.miniGame,
      CONSTANTS.sessionCloseWarning,
      CONSTANTS.cancel,
      () => {
        //no quiere cerrar sesión
      },
      CONSTANTS.closeSession,
      () => {
        this.events.publish("minigame:askclose", null);
        this.events.subscribe("minigame:closeFromSlots", () => {
          if (!this.firstCloseEvent) {
            this.firstCloseEvent = true;
            this.globalVars.playingMiniGame = false;
            this.startGame(game);
          }
        });
      },
    );
  }

  async showAlertSlot(title: string, message: string, buttons: any = null) {
    if (buttons == null) {
      buttons = [
        {
          text: CONSTANTS.ok,
          handler: () => {},
        },
      ];
    }

    const alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons,
      backdropDismiss: false,
    });
    alert.onWillDismiss();
    alert.present();
    return alert;
  }

  private listenLoginEvent() {
    if (!this.loginSubscribed) {
      this.loginSubscribed = true;
      this.events.subscribe("user:login", this.autoClick);
    }
  }

  private autoClick = () => {
    if (this.selectedGame) {
      setTimeout(() => {
        this.startGame(this.selectedGame);
      }, 750);
    }
  };

  async closeSessionAndRefreshBalance() {
    await lastValueFrom(this.slotsService.closeSlotSessions(true));
    await lastValueFrom(this.balancesService.refreshBalance());
  }

  private getLobbyType(game: Game) {
    let type = "";
    switch (game.GameType) {
      case CONSTANTS.roulettePc:
      case CONSTANTS.rouletteMobile:
      case CONSTANTS.blackJackPc:
      case CONSTANTS.blackJackMobile:
      case CONSTANTS.tablePc:
      case CONSTANTS.tableMobile:
        type = CONSTANTS.casinoLobbyName;
        break;
      default:
        type = CONSTANTS.slotsLobbyName;
        break;
    }
    return type;
  }

  private async pruebaAlertCasinosGeolocation(configAlert: ErrorAlertSportsCasinoGeolocation) {
    let msg = "";
    msg += `<div class="subtitlealert">${configAlert.subtitle}</div>`;
    configAlert.txt.forEach((value) => {
      msg += `<div>${value}</div>`;
    });

    const errorAlert = await this.utils.alert(false, configAlert.title, msg, configAlert.btn, () => {});

    this.utils.formatAlertSportsGeolocalization(
      errorAlert,
      "https://wa.me/5491136822558",
      configAlert.linkwhatsapp,
    );
  }

  private isCasinoLobbyType(game: Game): boolean {
    return game.lobbyType === CONSTANTS.casinoLobbyName && !this.isSpain();
  }

  private isSpain(): boolean {
    return this.globalVars.licenseType === LicenseTypes.Nacional && this.hasRoyalDegreeFlag();
  }

  hasRoyalDegreeFlag(): boolean {
    return this.globalVars.FEATURES.cas_uniqueGamesessionEnabled;
  }
}
