import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class RacePageUtils {
  toDate(str: string): Date {
    return new Date(parseInt(str.slice(6, str.length - 2)));
  }

  setPaddocksByDate(paddocks, endOfToday) {
    let today = paddocks.map((paddock) => ({
      ...paddock,
      Races: paddock.Races.filter((race) => {
        const raceDate = this.toDate(race.StartDate);
        return raceDate < endOfToday;
      }),
    }));
    today = today.filter((paddock) => paddock.Races.length > 0);
    today = this.deselectAllPaddocks(today);

    let tomorrow = paddocks.map((paddock) => ({
      ...paddock,
      Races: paddock.Races.filter((race) => {
        const raceDate = this.toDate(race.StartDate);
        return raceDate > endOfToday;
      }),
    }));
    tomorrow = tomorrow.filter((paddock) => paddock.Races.length > 0);
    tomorrow = this.deselectAllPaddocks(tomorrow);

    return {
      today,
      tomorrow,
    };
  }

  deselectAllPaddocks(paddocks) {
    return paddocks.map((paddock) => ({
      ...paddock,
      Selected: false,
    }));
  }
}
