/* eslint-disable eqeqeq */
import { CommonModule } from "@angular/common";
import { Component, Input, inject } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { Platform } from "@ionic/angular";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { EventsService } from "@providers/EventsService";
import { NewBaseService } from "@providers/newBase.service";

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "user-section-title",
  templateUrl: "./user-section-title.html",
  styleUrls: ["./user-section-title.scss"],
})
export class UserSectionTitle {
  @Input() sectionTitle: string;
  @Input() icon = false;

  globalVars!: GlobalVarsModel;
  translate = inject(TranslateService);
  newBaseService = inject(NewBaseService);
  eventsService = inject(EventsService);
  platform = inject(Platform);

  isDesktop = Capacitor.getPlatform() === "web" || this.platform.is("desktop");

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  onClickIcon() {
    if (
      this.globalVars.licenseType == LicenseTypes.Panama ||
      this.globalVars.licenseType == LicenseTypes.Colombia
    ) {
      this.eventsService.publish("iconBack");
    }
  }
}
