import { CommonModule } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  inject,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ExtractHourPipe } from "@pipes/extractHour.pipe";

@Component({
  standalone: true,
  selector: "codere-grid-filter",
  templateUrl: "codere-grid-filter.component.html",
  styleUrls: ["./codere-grid-filter.component.scss"],
  imports: [TranslateModule, ExtractHourPipe, CommonModule],
})
export class CodereGridFilterComponent implements OnChanges {
  @ViewChild("content") content: ElementRef;
  @Input() bgColor: string;
  @Input() currentEvent: any;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() select: EventEmitter<any> = new EventEmitter();
  @Input() collapsed: boolean = true;
  @Input() blocked: boolean = false;
  @Input() isTicket: boolean = false;
  @Input() isRace: boolean = false;
  @Input() showIcon: boolean = false;
  @Input() isDesktop: boolean = false;

  private _content: HTMLElement;
  private _element = inject(ElementRef);
  private _renderer = inject(Renderer2);

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes[this.currentEvent]) {
      return;
    }
    if (!changes[this.currentEvent].currentValue || !changes[this.currentEvent].previousValue) {
      return;
    }

    if (
      changes[this.currentEvent].currentValue !== changes[this.currentEvent].previousValue &&
      !this.isTicket &&
      !this.isRace
    ) {
      this.toggle();
    }
  }

  ngAfterViewInit() {
    this._content = this.content.nativeElement;
    this._renderer.addClass(this._content, this.bgColor);
  }

  get isCollapsed() {
    return this.isTicket ? this.collapsed : !this.currentEvent.Selected;
  }

  // @HostListener('click')
  public toggle() {
    if (this.blocked) {
      return;
    }

    if (!this.isTicket) {
      this.collapsed = !this.collapsed;
      this.select.emit(this.currentEvent);
    } else {
      this.select.emit(this.collapsed);
    }
  }

  @HostBinding("class.is-mobile")
  get isItMobile(): boolean {
    return !this.isDesktop;
  }

  @HostBinding("class.is-desktop")
  get isItDesktop(): boolean {
    return this.isDesktop;
  }
}
