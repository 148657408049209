import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "getCountryMarker",
  standalone: true,
})
export class countryMarkerPipe implements PipeTransform {
  transform(value: string) {
    value = value + ""; // make sure it's a string
    const valueAuxWithoutNumbers = value.replace(/\d+/g, "");
    return valueAuxWithoutNumbers;
  }
}
