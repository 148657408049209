import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from "@angular/core";
import { AnchorLink } from "@models/footer/anchor-link";

@Directive({
  selector: "[link-rel]", // Attribute selector
  standalone: true,
})
export class LinkRelDirective implements AfterViewInit {
  @Input() linkdescription!: AnchorLink;

  constructor(
    private element: ElementRef,
    private render: Renderer2,
  ) {}
  ngAfterViewInit(): void {
    if (this.linkdescription.relnofollow) {
      this.render.setAttribute(this.element.nativeElement, "rel", this.linkdescription.relnofollow);
    }
  }
}
