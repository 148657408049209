export const PAYMENT_CONSTANTS = {
  METHOD: {
    CREDIT_CARDS: "creditcards",
    BIZUM: "bizum",
    TRUE_LAYER: "truelayer",
    PAY_SAFE: "paysafe",
    PAYPAL: "paypal",
    ASTROPAY: "astropay",
    NETELLER: "neteller",
    SKRILL: "skrill",
    CYBERSOURCE: "cybersource",
    RAPID_TRANSFER: "rapidtransfer",
    BANK_TRANSFER: "banktransfer",
    HAL_CASH: "halcash",
    LOCAL_CODERE: "cajacodere",
    MERCADO_PAGO: "mercadopago",
    TARJETA: "tarjeta",
    DEBIN: "debin",
    PSE: "pse",
    DAVI_PLATA: "daviplata",
    NEQUI: "nequi",
    ALMACENES_EXITO: "almacenesexito",
    CARULLA: "carulla",
    GANA_CERCA: "ganacerca",
    PAGO_TODO: "pagotodo",
    GANA_BANK: "ganabank",
    EFECTY: "efecty",
    DIMONEX: "dimonex",
    PUNTO_RED: "puntored",
    T_PAGA: "tpaga",
    PAY_U: "payu",
  },
  VERSION: {
    CREDIT_CARDS: "v1",
    BIZUM: "v1",
    TRUE_LAYER: "v3",
    PAY_SAFE: "v1",
    PAYPAL: "v1",
    ASTROPAY: "v1",
    NETELLER: "v1",
    SKRILL: "v1",
    CYBERSOURCE: "v1",
    RAPID_TRANSFER: "v1",
    BANK_TRANSFER: "v1",
    HAL_CASH: "v1",
    LOCAL_CODERE: "v1",
    MERCADO_PAGO: "v1",
    TARJETA: "v1",
    DEBIN: "v1",
    PSE: "v1",
    DAVI_PLATA: "v1",
    NEQUI: "v1",
    ALMACENES_EXITO: "v1",
    CARULLA: "v1",
    GANA_CERCA: "v1",
    PAGO_TODO: "v1",
    GANA_BANK: "v1",
    EFECTY: "v1",
    DIMONEX: "v1",
    PUNTO_RED: "v1",
    T_PAGA: "v1",
    PAY_U: "v1",
  },
};
