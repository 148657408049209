// models/C_Participant.ts

export class C_Racer {
  IsNonRunner: boolean;
  EventNodeTypeId: number;
  Priority: number;
  Trap: string;
  Odd: number;
  JockeyColours: string;
  Name: string;
  SportHandle: string;
  JockeyName: string;
  Cloth: string;
  NodeId: string;
  Place: boolean;
  ParentNodeId: string;
  ChildrenCount: number;
  Locked?: boolean;
  Tax?: number;

  constructor() {}
}

//export = C_Participant
