import { CommonModule } from "@angular/common";
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule } from "@ngx-translate/core";
import { NewBaseService } from "@providers/newBase.service";
import { Utils } from "@utils/Utils";
import { FormatBono } from "../welcome-bonus";

@Component({
  selector: "bonus",
  templateUrl: "./bonus.html",
  standalone: true,
  styleUrls: ["./bonus.scss"],
  imports: [CommonModule, IonicModule, TranslateModule],
})
export class BonusComponent {
  @Output() bonoAcept: EventEmitter<string>;
  globalVars!: GlobalVarsModel;
  newBaseService = inject(NewBaseService);
  utils = inject(Utils);

  @Input() bono: FormatBono | null = null;
  @Input() isDesktop = false;

  constructor() {
    this.bonoAcept = new EventEmitter();
    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
  }

  aceptBonus() {
    this.bonoAcept.next(this.bono.bono.promoProduct);
  }
}
