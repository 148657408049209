import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { BaseFilter } from "../base-filter/base-filter";

@Component({
  selector: "card-filter",
  imports: [IonicModule, CommonModule],
  standalone: true,
  providers: [],
  templateUrl: "./card-filter.component.html",
  styleUrls: ["./card-filter.component.scss"],
  host: {
    class: "card-filters",
  },
})
export class CardFilterComponent extends BaseFilter {
  constructor() {
    super();
  }
}
