import { CommonModule } from "@angular/common";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
  inject,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute, NavigationEnd, NavigationSkipped, Router } from "@angular/router";
import { IonContent, IonHeader, IonicModule } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { TranslateModule } from "@ngx-translate/core";
import { BehaviorSubject, Observable, Subscription, combineLatest, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

//Services
import { SportService } from "@sports-services/index";
import { SportBookService } from "@sports-services/index";
import { TicketService } from "@sports-services/index";

import { SbNavbarComponent } from "@components/common/sb-navbar/sb-navbar";
import { CodereFooterComponent } from "@components/mso/footer/codere-footer/codere-footer";
import { SbButtonComponent } from "@components/sports/sb-button/sb-button";
import { SbDropdownComponent } from "@components/sports/sb-dropdown/sb-dropdown";
import { SbFilterComponent } from "@components/sports/sb-filter/sb-filter";
import { SbGridContentExpandedComponent } from "@components/sports/sb-grid-content-expanded/sb-grid-content-expanded";
import { SbGridContentSingleComponent } from "@components/sports/sb-grid-content-single/sb-grid-content-single";
import { SbGridContentTeamsComponent } from "@components/sports/sb-grid-content-teams/sb-grid-content-teams";
import { SbGridHeaderComponent } from "@components/sports/sb-grid-header/sb-grid-header";
import { SbGridItemComponent } from "@components/sports/sb-grid-item/sb-grid-item";
import { SbOverviewScoreRegularComponent } from "@components/sports/sb-overview-score-regular/sb-overview-score-regular";
import { SbOverviewScoreSetsComponent } from "@components/sports/sb-overview-score-sets/sb-overview-score-sets";
import { EventsService } from "@providers/EventsService";
import { ScriptService } from "@providers/ScriptService";
import { StyleService } from "@providers/StyleService";
import { TrackingService } from "@providers/TrackingService";
import { BufferFacade } from "@providers/facades";
import { NewBaseService } from "@providers/newBase.service";
import { StaticStoreService } from "@providers/static-store.service";
import { DeviceService } from "@services/device.service";
import { BasePage, TrackingInfo } from "../../base/BasePage";

// Pages

// Pipes
import { ExtractDatePipe } from "@pipes/extractDate.pipe";
import { MobileBehaviorService } from "@sports-services/mobile-behavior.service";

// Directives
import { BetDisplayDirective } from "@directives/bet-display/bet-display";

import { SportsbookState } from "@sports-stores/sportsbook/index";
import { SportsbookFacade } from "@sports-stores/sportsbook/sportsbook.facade";
import * as sportsbookSelector from "@sports-stores/sportsbook/sportsbook.selectors";
// Stores
import { NewTicketFacade } from "@sports-stores/ticket/index";

import { C_CategoryInfo } from "@models/C_CategoryInfo";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { Store } from "@ngrx/store";
import { SbTabsCategoriesComponent, SbTabsComponent } from "@sports-components/index";
import { C_BetsDataFilter, C_GameGroupByName } from "@sports-models/index";
import { C_League } from "@sports-models/index";
import { C_LeagueCategory } from "@sports-models/index";
import { DropdownMode, SixPack } from "@sports-models/index";
import { SportsConstants } from "@sports-utils/index";
import { SportHandleList } from "@sports-utils/index";
// Utils
import { Utils } from "@utils/Utils";

declare const SIR;
@Component({
  selector: "event-page",
  templateUrl: "./event.html",
  styleUrls: ["./event.scss"],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    SbNavbarComponent,
    SbFilterComponent,
    SbGridItemComponent,
    SbOverviewScoreSetsComponent,
    SbOverviewScoreRegularComponent,
    SbButtonComponent,
    SbDropdownComponent,
    SbGridHeaderComponent,
    SbGridContentSingleComponent,
    CodereFooterComponent,
    SbGridContentTeamsComponent,
    SbGridContentExpandedComponent,
    ExtractDatePipe,
    TranslateModule,
    BetDisplayDirective,
    SbTabsComponent,
    SbTabsCategoriesComponent,
  ],
})
export class EventoPage extends BasePage implements OnInit {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonHeader) header: IonHeader;
  @ViewChild("standardWidgetSIR") standardWidgetSIR: ElementRef;
  @ViewChild("standardWidgetSIR2") standardWidgetSIR2: ElementRef;
  @ViewChild("stickyWidgetSIR") stickyWidgetSIR: ElementRef;

  league: C_League;
  sportHandleEvent: string;
  games$: Observable<any>;
  eventsAux$: Observable<any>;
  currentCategory$: BehaviorSubject<C_LeagueCategory> = new BehaviorSubject(null);
  canGoBack: boolean = true;
  gameTypes: any[] = []; // BAU: Todo: maybe this is no longer needed.
  sixPackMarket: C_GameGroupByName[]; // BAU: Todo no longer neeeded
  hasSixPack: boolean;

  hasMoreBets: boolean;
  initialFilters: C_BetsDataFilter[] = [];
  betsFilters$: BehaviorSubject<C_BetsDataFilter[]> = new BehaviorSubject(null);
  currentBetsFilter$: BehaviorSubject<C_BetsDataFilter> = new BehaviorSubject(null);

  //inside filters swipper
  public gametypes: number[] = [284, 114, 37, 82, 153, 192];
  betsCategories: any[] = [];
  showingMoreBets: boolean = false;
  hideCategories: boolean = false;
  market: any;
  markets$: Observable<any>;

  currentAddedCategory$: BehaviorSubject<C_LeagueCategory> = new BehaviorSubject(null);

  marketDesktopMode = DropdownMode.MARKET_DESKTOP;

  globalVars!: GlobalVarsModel;
  ngZone = inject(NgZone);
  trackingService = inject(TrackingService);
  sportBookService = inject(SportBookService);
  newTicketFacade = inject(NewTicketFacade);
  sportService = inject(SportService);
  ticketService = inject(TicketService);
  translate = inject(TranslateService);

  mobileBehavior = inject(MobileBehaviorService);
  styleService = inject(StyleService);
  scriptService = inject(ScriptService);
  utils = inject(Utils);
  extractDate = inject(ExtractDatePipe);
  bufferFacade = inject(BufferFacade);
  eventService = inject(EventsService);
  newBaseService = inject(NewBaseService);
  activatedRoute = inject(ActivatedRoute);
  deviceService = inject(DeviceService);
  route = inject(ActivatedRoute);
  staticStoreService = inject(StaticStoreService);
  renderer = inject(Renderer2);

  sportsbookFacade = inject(SportsbookFacade);
  sportsbookStore = inject(Store<SportsbookState>);

  isMobile = false;
  isDesktop = false;
  mySubscription: Subscription;
  private subscription: Subscription;
  eventData$: Observable<any>;
  eventDataSub$: Subscription;

  router = inject(Router);
  private fromLeftMenuAZ: boolean = false;
  widgetActive: string;

  eventMainCategories: C_LeagueCategory[];
  selectedEventMainCategory: C_LeagueCategory = null;
  eventMarkesCategories$: Observable<C_LeagueCategory[]>;
  games: [];
  event: null;
  parentId: "";
  selectedMarketsCategory: any;

  constructor() {
    super();

    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
    this.newBaseService.getVars
      .pipe(
        switchMap((data: GlobalVarsModel) => {
          this.globalVars = data;
          return of(null);
        }),
      )
      .subscribe();
    this.league = history.state.params;
    this.fromLeftMenuAZ = !!history.state.isLeftMenuAZ;
    this.hasMoreBets = false;
    this.league.SportHandle = this.league.SportHandle || this.league.SportsHandle;
  }

  subscribeSub() {
    this.eventData$ = this.sportsbookStore.select(sportsbookSelector.getEventDataState);
    this.eventDataSub$ = this.eventData$.subscribe((eventData) => {
      this.eventMainCategories = eventData.eventMainCategories;
      this.eventMarkesCategories$ = of(eventData.eventMarkesCategories);
      this.selectedEventMainCategory = eventData.selectedEventMainCategory;
      this.selectedMarketsCategory = eventData.selectedMarketsCategory;
      this.games$ = of(eventData.games);
      this.market = eventData.specialMarkets;
      if (this.market && this.selectedEventMainCategory?.index == 1) {
        this.currentAddedCategory$.next(new C_LeagueCategory(-1, "Principal", false));
        this.markets$ = combineLatest([this.currentAddedCategory$, this.getMarkets()], (current, markets) => {
          this.showingMoreBets = true;
          if (current.CategoryId === -1) return markets;
          return markets.filter((market) => {
            if (!market.CategoryInfos) {
              return false;
            } else if (market.CategoryInfos.length > 0) {
              return market.CategoryInfos.find((category) => category.CategoryId === current.CategoryId);
            }
            return current.CategoryId === 99;
          });
        });
      } else {
        this.showingMoreBets = false;
        this.markets$ = of(null);
      }
    });
  }

  ionViewWillEnter() {
    this.subscribeSub();
    this.initializePage();
  }

  loadData() {
    this.sportsbookFacade.getEventData(this.league);
  }

  initializePage() {
    this.staticStoreService.callPolling = false;

    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationSkipped) {
        this.router.navigated = false;
        this.sportService.showSportradar$.next(false);

        if (history?.state?.params) {
          this.sportsbookFacade.setSelectedEventData(history.state.params);
          this.league = history.state.params ? history.state.params : this.staticStoreService.params;
          this.fromLeftMenuAZ = !!history.state.isLeftMenuAZ;
          this.hasMoreBets = false;

          this.getData();
        }
      }
    });
    this.league = history.state.params ? history.state.params : this.staticStoreService.params;
    this.sportsbookFacade.setSelectedEventData(history.state.params);
    this.fromLeftMenuAZ = !!history.state.isLeftMenuAZ;
    this.hasMoreBets = false;

    this.getData();

    this.hasSixPack = SixPack.validate(this.league.SportHandle, this.league.CountryCode);

    this.globalVars.hideBetFooterBar = false;
    this.content?.scrollToTop();

    this.trackingService.trackEvent([
      "SelectEvent",
      this.league.NodeId,
      this.globalVars.SportHandleSelected || this.globalVars.SportSelected,
      this.league.Name,
      "pageView",
    ]);
  }

  private getData() {
    this.loadData();
    this.subscription = this.eventService.currentCanGoBack.subscribe((mensaje) => {
      this.canGoBack = mensaje;
    });
  }

  ionViewDidEnter() {
    this.staticStoreService.callPolling = false;
    // for mobile use
    if (this.league.Name.toLowerCase() != "fórmula 1" && this.showTournament()) {
      Promise.all([this.scriptService.load("sportradar-euro"), this.styleService.load("sportradar")]).then(() => {
        this.loadSportRadarWidget();
      });
    }

    this.eventService.publish("footer:refresh", this.content);
    setTimeout(() => this.content.scrollToTop(), 100);
  }

  private loadSportRadarWidget() {
    const uniqueTournamentId: number = this.league.NodeId == SportsConstants.UEFAEuroNodeId ? 1 : 133;
    const disableMoreStats = false;
    const isExpanded = false;
    const disableEventTicker = true;
    const disableCompare = true;

    if (!this.showTournament()) return;
    try {
      SIR("addWidget", ".sr-widget-22", "tournament.preview", {
        branding: {
          tabs: {
            option: "iconText",
            iconPosition: "start",
            variant: "standard",
          },
        },
        uniqueTournamentId: uniqueTournamentId,
        disableMoreStats: disableMoreStats,
        isExpanded: isExpanded,
        disableEventTicker: disableEventTicker,
        disableCompare: disableCompare,
        isMobile: this.isMobile,
      });
    } catch (err) {
      console.log(err);
    }
  }

  getTrackingInfo(): TrackingInfo {
    let sportName: string = this.globalVars.SportHandleSelected;
    if (sportName == "") sportName = this.globalVars.SportSelected;

    return {
      uri: encodeURI(`/${sportName}/${this.league.Name}`),
      description: `Evento page - ${this.league.Name}`,
      additionalData: {
        nodeId: this.league.NodeId,
        nodeName: this.league.Name,
        sportHandle: sportName,
      },
    };
  }

  public navigate(market, gameType?): void {
    const componentName = market.isLive ? "NowLiveDetailPage" : "MercadosPage";
    const eventType = market.isLive ? "pushEventLive" : "pushEvent";
    market = {
      ...market,
      countryCode: this.league.CountryCode,
    };
    const urlComponent = "/" + componentName;
    this.staticStoreService.params = history.state.params;
    this.unsuscribeToSubjects();
    this.router.navigate([urlComponent], { state: { params: market } });

    this.trackingService.trackEvent([eventType, market.NodeId, window.location.hash, market.Name, "event"]);
  }

  public bet(game: any, event: any, gameName: string, type: string) {
    const data = {
      ...game,
      EventDate: this.extractDate.transform(event.StartDate),
      EventName: !!this.market && this.market.LeagueName.includes("Anticipadas") ? this.market.Name : event.Name,
      GameName:
        this.isMobile && event.isSixPack ? event.Games.find((i) => i.NodeId == game.GameId).Name : gameName,
    };

    this.newTicketFacade.checkSelection(data, data.NodeId);
  }

  // CHANGE FILTERS AND CATEGORIES
  changeMainCategory(mainCategorySelected) {
    this.sportsbookFacade.setEventMainSelectedCategory(mainCategorySelected);
  }

  public changeCategory(value: any): void {
    this.sportsbookFacade.setEventMarketSelectedCategory(value);
    this.content.scrollToTop();
  }

  // ANTICIPADAS DEVELOP

  getMarkets() {
    return this.sportService.getGamesNoLiveEvent(this.market.NodeId, null).pipe(
      map((markets: any[]) => {
        markets.forEach((market, index) => {
          market.nameLeague = this.league.SportHandle;
          this.sportBookService.getAllSpovesInMarket(markets, market);
        });

        this.sportBookService.getCombinedMarkets(markets, this.market);
        markets = this.sportBookService.getMarketGoalScorer(markets, this.market);
        this.getBetsCategories(markets, this.betsCategories);
        markets = this.orderThreeColumns(markets);
        return markets;
      }),
    );
  }

  setSpecialMarkets(markets) {
    const AllSpovs = [];
    markets.forEach((market, index) => {
      market = {
        ...market,
        nameLeague: this.league.SportHandle,
      };
      AllSpovs.push(market.Spov);
      // this.sportBookService.getAllSpovesInMarket(markets, market);
    });
    markets = markets.map((market) => (market = { ...market, AllSpov: AllSpovs }));

    this.sportBookService.getCombinedMarkets(markets, this.market);
    markets = this.sportBookService.getMarketGoalScorer(markets, this.market);
    this.getBetsCategories(markets, this.betsCategories);
    markets = this.orderThreeColumns(markets);
    return markets;
  }

  getBetsCategories(games: any[], previousCategories: C_CategoryInfo[]) {
    let indexTemp;
    for (let i: number = 0; i < games.length; i++) {
      if (Array.isArray(games[i].CategoryInfos) && games[i].CategoryInfos.length > 0) {
        games[i].CategoryInfos.forEach((myCategory) => {
          if (myCategory.CategoryName) {
            indexTemp = previousCategories.findIndex((myItem) => myItem.CategoryId == myCategory.CategoryId);
            if (indexTemp == -1) {
              const newCat: C_CategoryInfo = new C_CategoryInfo(
                myCategory.CategoryId,
                myCategory.IsRelevant,
                myCategory.CategoryName,
              );
              newCat.numGames = 1;
              this.betsCategories.push(newCat);
            } else {
              this.betsCategories[indexTemp].numGames++;
            }
          }
        });
      }
    }
  }

  private orderThreeColumns(markets) {
    markets.forEach((item) => {
      const isFinal = this.gametypes.indexOf(item.GameType) != -1;
      if (isFinal) {
        const local = [];
        const tie = [];
        const visitant = [];
        let a;
        let b;
        let tmp = "";
        item.GroupResults.forEach((it) => {
          tmp = it.Name.split(":");
          a = parseInt(tmp[0]);
          b = parseInt(tmp[1]);
          if (a > b) local.push(it);
          else if (b > a) visitant.push(it);
          else tie.push(it);
        });
        item.GroupResults = [local, tie, visitant];
      }
    });
    return markets;
  }

  public showTournament() {
    return (
      this.league &&
      this.league.SportHandle == SportHandleList.soccer &&
      (this.league.NodeId == SportsConstants.UEFAEuroNodeId ||
        this.league.NodeId == SportsConstants.CopaAmericaNodeId ||
        this.league.NodeId == SportsConstants.MundialNodeId) &&
      this.globalVars.FEATURES.euroCopaWidgetEnable
    );
  }

  onScroll(e) {
    this.mobileBehavior.scrolling$.next(true);
  }

  resize() {
    if (!this.content) {
      return;
    }
    setTimeout(() => this.content.scrollToTop(), 200);
  }

  ionViewDidLeave() {
    this.unsuscribeToSubjects();
    // SIR('removeWidget', document.querySelector('.sr-widget-22'));
  }

  unsuscribeToSubjects() {
    const subscriptionList = [this.subscription, this.mySubscription];
    subscriptionList.map((sub) => {
      if (sub && !sub.closed) sub.unsubscribe();
    });
  }
}
