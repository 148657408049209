/* eslint-disable */
export enum C_TicketEnumMode {
  TICKET_SIMPLE = "ticket-simple",
  TICKET_EXPANDED = "ticket-expanded",
  TICKET_EXPANDED_MULTISELECTION = "ticket-expanded-multiselection",
  TICKET_CONFIRMATION_SIMPLE = "confirmation-simple",
  TICKET_CONFIRMATION_EXPANDED = "confirmation-expanded",
  TICKET_CONFIRMATION_COLLAPSED = "confirmation-collapsed",
  TICKET_OVERASK_REJECTED = "overask-rejected",
}
