import { CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component, ViewChild, inject } from "@angular/core";
import { NgZone } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CodereNavbar } from "@components/common/codere-navbar/codere-navbar";
import { CodereFooterComponent } from "@components/mso/footer/codere-footer/codere-footer";
import { UserSectionTitle } from "@components/mso/user-section-title/user-section-title";
import { Loader } from "@googlemaps/js-api-loader";
/* eslint-disable */
import { IonContent, IonicModule, NavController } from "@ionic/angular";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule } from "@ngx-translate/core";
import { EventsService } from "@providers/EventsService";
import { MapsService } from "@providers/MapsService";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { MSO_PATHS } from "@shared-constants/routes";
//import relativo por dependencias ciclicas
import { BasePage, TrackingInfo } from "@shared-pages/base/BasePage";
import { Utils } from "@utils/Utils";
import { Observable, from, map } from "rxjs";

interface Coordinates {
  latitude: number;
  longitude: number;
}

@Component({
  selector: "nearest-local-page",
  templateUrl: "./nearest-local.html",
  styleUrls: ["./nearest-local.scss"],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    CodereNavbar,
    CodereFooterComponent,
    UserSectionTitle,
  ],
})
export class NearestLocalPage extends BasePage {
  @ViewChild(IonContent) content: IonContent;
  pois: Array<any>;
  poisActivos: Array<any> = [];
  position: Coordinates;
  mapCenter: google.maps.LatLng;
  map: google.maps.Map;
  markers: Array<google.maps.Marker>;
  infoWindow: google.maps.InfoWindow;
  markerUserPosition: google.maps.Marker;
  inputSearch: string;
  codigoPais: string;
  isPanamaRegister: boolean;
  globalVars!: GlobalVarsModel;
  isGoogleMapsLoaded: boolean = false;
  isMobile: boolean;
  isDesktopView: boolean;

  nav = inject(NavController);
  utils = inject(Utils);
  newBaseService = inject(NewBaseService);
  activatedRoute = inject(ActivatedRoute);
  route = inject(Router);
  mapSevice = inject(MapsService);
  zone = inject(NgZone);
  events = inject(EventsService);
  device = inject(DeviceService);
  cdRef = inject(ChangeDetectorRef);

  constructor() {
    super();

    this.isMobile = this.device.isMobile();

    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    if (this.activatedRoute.snapshot.paramMap.get("register")) {
      this.isPanamaRegister = true;
    }
    const itemString = this.activatedRoute.snapshot.paramMap.get("item");
    if (itemString) {
      const item = JSON.parse(itemString);
      if (item && item.register) {
        this.isPanamaRegister = true;
      }
    }
  }

  getTrackingInfo(): TrackingInfo {
    return {
      uri: "/shops",
      description: "NearestLocal page",
      additionalData: null,
    };
  }

  ionViewWillEnter(event) {
    this.route.navigate([MSO_PATHS.BASE + "/" + MSO_PATHS.NearestLocalPage]);
    this.trackingService.trackEvent(["AccessToNearestLocalPage", "", "", "Acceder a locales cercanos", "event"]);
    this.events.publish("footer:refresh", this.content);
  }

  initializeGoogleMapsDependentData() {
    //maps is centered in Madrid as Default value
    this.markers = [];
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Mexico: {
        this.codigoPais = "mx";
        this.mapCenter = new google.maps.LatLng(19.4326077, -99.13320799999997);
        break;
      }
      case LicenseTypes.Colombia: {
        this.codigoPais = "co";
        this.mapCenter = new google.maps.LatLng(4.782154, -74.086569);
        break;
      }
      case LicenseTypes.Panama: {
        this.codigoPais = "pa";
        this.mapCenter = new google.maps.LatLng(8.978766, -79.520991);
        break;
      }
      default: {
        this.codigoPais = "es";
        this.mapCenter = new google.maps.LatLng(40.4378698, -3.8196196);
      }
    }

    this.infoWindow = new google.maps.InfoWindow();
    this.inputSearch = "";
  }

  initializeMap() {
    const mapEle = document.getElementById("map");
    this.map = new google.maps.Map(mapEle, {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      center: this.mapCenter,
      zoom: 12,
      disableDefaultUI: true,
      streetViewControl: false,
      zoomControl: true,
      styles: [
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }],
        },
      ],
    });
    mapEle.classList.add("show-map");
  }

  async ngOnInit() {
    const loader = new Loader({
      apiKey: this.globalVars.GOOGLE_MAPS_API_KEY,
      version: "weekly",
      libraries: ["places"],
    });

    await loader.load().then(() => {
      this.isGoogleMapsLoaded = true;

      this.initializeGoogleMapsDependentData();
      this.initializeMap();
      this.initAutoComplete();
      this.getGPS();
      this.loadPois();
      //refresh footer
      this.events.publish("footer:refresh", this.content);
    });
  }

  initAutoComplete() {
    const searchBox: any = document.getElementById("txtSearch");
    const options = {
      types: ["geocode"],
      componentRestrictions: { country: this.codigoPais },
    };
    var autocomplete = new google.maps.places.Autocomplete(searchBox, options);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      const address = place.formatted_address;
      this.placeChanged(place.geometry.location, address);
    });
  }
  placeChanged(location, address) {
    this.map.setCenter(location);
    this.map.setZoom(14);
  }

  onInput(event) {
    //
  }
  onCancel(event) {
    //
  }

  updateGPS() {
    this.getGPS().subscribe((coords: Coordinates) => {
      if (coords) {
        this.mapCenter = new google.maps.LatLng(coords.latitude, coords.longitude);
        this.map.setCenter(this.mapCenter);
        this.map.setZoom(12);
      }
    });
  }

  getGPS(): Observable<any> {
    if (!this.isGoogleMapsLoaded) {
      return from(Promise.reject("Google Maps API no está cargada aún."));
    }
    //
    const q = new Promise((resolve, reject) => {
      //
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            //sucess
            this.position = pos.coords;
            this.mapCenter = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
            //
            if (this.markerUserPosition) {
              //the marker exists
              this.markerUserPosition.setPosition(this.mapCenter);
              //
            } else {
              this.markerUserPosition = new google.maps.Marker({
                position: this.mapCenter,
                map: this.map,
                title: "Tu posición",
              });
            }

            resolve(pos.coords);
          },
          (err) => {
            reject(err);
          },
        );
      } else {
        resolve(null);
      }
    });

    return from(q);
  }

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2): number {
    function deg2rad(deg) {
      return deg * (Math.PI / 180);
    }
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return parseFloat(d.toFixed(2));
  }

  getDistance(data) {
    for (var i = 0; i < data.markers.marker.length; i++) {
      const marker = data.markers.marker[i];

      if (this.position) {
        marker.distance = this.getDistanceFromLatLonInKm(
          this.position.latitude,
          this.position.longitude,
          marker.lat,
          marker.lng,
        );
      } else {
        marker.distance = "-1";
      }
      marker.LatLng = new google.maps.LatLng(marker.lat, marker.lng);
    }
    return data;
  }

  loadPois() {
    this.mapSevice
      .getPois()
      .pipe(map((data) => this.getDistance(data)))
      .subscribe({
        next: (data) => {
          this.pois = data.markers.marker;
          this.updateMap();
          this.cdRef.detectChanges();
        },
        error: (err) => {
          //
          this.utils.showError(err);
        },
      });
  }
  updateMap() {
    this.map.setCenter(this.mapCenter);
    google.maps.event.addListener(this.map, "idle", () => {
      this.updateMarkers();
    });
    this.updateMarkers();
    google.maps.event.trigger(this.map, "resize");
  }

  clickPoi(quePoi: any) {
    this.map.setCenter(quePoi.marker.LatLng);
    this.map.setZoom(15);
    this.content.scrollToTop();
    google.maps.event.trigger(quePoi.marker, "click");
  }
  updateMarkers() {
    this.markers = [];
    this.poisActivos = [];
    this.pois.forEach((markerData) => {
      if (this.map.getBounds().contains(markerData.LatLng)) {
        if (!!markerData.marker) {
          markerData.marker.setVisible(true);
        } else {
          var nombParse: string = markerData.nomb;
          var iconFile: string = "assets/global/img/poi1.png";

          let scaledSize: number = 30;

          if (markerData.nomb.indexOf("@") != -1) {
            nombParse = markerData.nomb.substr(1);
            if (this.globalVars.licenseType != LicenseTypes.Colombia) {
              iconFile = "assets/global/img/poi2.png";
            } else {
              scaledSize = 50;
              iconFile = "assets/global/img/poi2co.png";
            }
            markerData.isOnline = true;
          } else {
            markerData.isOnline = false;
          }

          const povpobl =
            markerData.pobl !== null ? markerData.pobl : markerData.prov !== null ? markerData.prov : "";
          const content: string =
            '<div class="nameLocal"><b>' +
            nombParse +
            '</b></div><br><div class="dirLocal">' +
            markerData.dir +
            "<br>" +
            povpobl +
            "</div>";

          const icono = {
            url: iconFile,
            size: new google.maps.Size(60, 60),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(15, 30),
            scaledSize: new google.maps.Size(scaledSize, scaledSize),
          };

          const marker = new google.maps.Marker({
            position: markerData.LatLng,
            map: this.map,
            icon: icono,
            title: markerData.nomb,
          });

          var vmMap = this.map;

          google.maps.event.addListener(
            marker,
            "click",
            (function (marker, content, infowindow) {
              return function () {
                infowindow.setContent(content);
                infowindow.open(vmMap, marker);
              };
            })(marker, content, this.infoWindow),
          );

          markerData.marker = marker;
        }

        this.markers.push(markerData.marker);

        this.poisActivos.push(markerData);
      } else {
        if (!!markerData.marker) {
          markerData.marker.setVisible(false);
        }
      }
    });
    this.poisActivos.sort((n1, n2) => {
      if (n1.nomb > n2.nomb) {
        return 1;
      }

      if (n1.nomb < n2.nomb) {
        return -1;
      }

      return 0;
    });

    this.poisActivos.sort((n1, n2) => {
      if (n1.distance > n2.distance) {
        return 1;
      }

      if (n1.distance < n2.distance) {
        return -1;
      }

      return 0;
    });

    this.zone.run(() => {
      this.poisActivos = this.poisActivos;
    });
  }
}
