import { Injectable, inject } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { Observable } from "rxjs";

import { C_Event, C_EventLive, C_Game, C_GameGroupByName, C_Result } from "@sports-models/index";

import { BaseServiceExt } from "@providers/BaseServiceExt";
import { DeviceService } from "@services/device.service";

import { orderBy } from "@utils/utils.functions";

@Injectable({ providedIn: "root" })
export class SportsCommonService extends BaseServiceExt {
  deviceService = inject(DeviceService);
  alertCtrl = inject(AlertController);
  isDesktop: boolean;
  isMobile: boolean;

  constructor() {
    super();
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
  }

  getAuthToken(): Observable<any> {
    const url = this.globalVars.DOMAIN + "/checkreceivemailmvc/Orderform/GetAuthToken";
    return this.myGet(url, true);
  }

  getGameResultsOrdered(game: C_Game): any {
    const g = C_Game.parse(game);
    g.Results = Object.assign([], orderBy(game.Results, "SortOrder"));
    return g;
  }

  setLockedResults(ev: C_Event | C_EventLive) {
    ev.Games.forEach((game) => {
      if (!game) return null;
      return game.Results.map((result) => (result.Locked = game.Locked || ev.Locked));
    });
  }

  groupMarkets(games: any[], league = "") {
    let marketGameType: any;
    const groupedMarkets: any = [];

    games.forEach((market, index) => {
      market.Results.forEach((r: any) => {
        r.Spov = market.Spov;
      });

      if (!marketGameType || marketGameType !== market.Name) {
        const newMarket = new C_GameGroupByName();
        newMarket.copyGame(market);
        newMarket.AllSpov = [market.Spov];
        newMarket.nameLeague = league;
        newMarket.Locked = market.Locked;
        groupedMarkets.push(newMarket);
        marketGameType = market.Name;
      } else {
        if (marketGameType === market.Name) {
          groupedMarkets[groupedMarkets.length - 1].GroupResults = [
            ...groupedMarkets[groupedMarkets.length - 1].GroupResults,
            ...market.Results,
          ];
        }
      }
    });

    return groupedMarkets;
  }

  doAlternatives(markets: any[], grouped: any[]) {
    const alternatives = {
      handicaps: [],
      totals: [],
    };

    markets.forEach((market) => {
      if (this.globalVars.gameTypes.alternativeTotals.indexOf(market.GameType) !== -1) {
        alternatives.totals.push(market);
      }

      if (this.globalVars.gameTypes.alternativeHandicaps.indexOf(market.GameType) !== -1) {
        alternatives.handicaps.push(market);
      }
    });

    const mostBalancedTotals = this.getMostBalancedMarkets(alternatives.totals);
    mostBalancedTotals.Priority += 1;
    const mostBalancedHandicap = this.getMostBalancedMarkets(alternatives.handicaps);
    mostBalancedHandicap.Priority += 1;

    grouped = grouped.map((market) => {
      switch (market.GameType) {
        case mostBalancedTotals.GameType:
          market.GroupResults = market.GroupResults.filter((result) => result.Spov !== mostBalancedTotals.Spov);
          if (!market.GroupResults.length) return;
          market.Name = market.Name.includes("Alternativo") ? market.Name : market.Name + " Alternativo";
          market.Collapsed = true;
          break;
        case mostBalancedHandicap.GameType:
          market.GroupResults = market.GroupResults.filter((result) => result.Spov !== mostBalancedHandicap.Spov);
          if (!market.GroupResults.length) return;
          market.Name = market.Name.includes("Alternativo") ? market.Name : market.Name + " Alternativo";
          market.Collapsed = true;
          break;
      }
      return market;
    });
    //eliminate undefineds @a.e.m
    const g = [];
    for (const i of grouped) {
      if (i != null) g.push(i);
    }
    let result: any[] = [];
    result = [...g];
    if (mostBalancedTotals.Name != "") result.push(C_GameGroupByName.parse(mostBalancedTotals));
    if (mostBalancedHandicap.Name != "") result.push(C_GameGroupByName.parse(mostBalancedHandicap));
    return result;
  }

  private getMostBalancedMarkets(markets: any[]) {
    let balanced: number = Number.MAX_SAFE_INTEGER;
    let iterator = -1;
    markets.forEach((market, index) => {
      if (!market.Results[1]) {
        market.Results[1] = { odd: 0 };
      }
      if (!market.Results[0]) {
        market.Results[0] = { odd: 0 };
      }
      const res = Math.abs(market.Results[0].Odd - market.Results[1].Odd);
      if (res < balanced) {
        balanced = res;
        iterator = index;
      }
    });
    if (!markets[iterator]) {
      return this.getMissingMarket();
    }
    return markets[iterator];
  }

  public getMissingMarket() {
    return {
      CategoryInfo: null,
      CategoryInfos: null,
      ChildrenCount: null,
      DisplayTypeName: "",
      EventNodeTypeId: null,
      GameType: null,
      Name: "",
      NodeId: "",
      ParentNodeId: "",
      Priority: null,
      Results: [this.NewResultMissing(), this.NewResultMissing()],
      SmartMarketAvailable: false,
      SportHandle: null,
      Spov: null,
    };
  }

  public NewResultMissing(): C_Result {
    return new C_Result("-", null, null, null, null, null, null, null, null, null, null, null);
  }

  public getEventGameTypes(event) {
    return this.globalVars.gameTypes.getOne(
      event.SportHandle,
      event.CountryCode,
      event.isLive,
      event.ParentNodeId,
      event.SportHandle === "basketball" ? event.Games.map((game: C_Game) => game.GameType) : [],
    );
  }

  async showDismiss(title: string, msg: string) {
    const alertOptions = {
      enableBackdropDismiss: false,
      title: title,
      message: msg,
      buttons: [
        {
          role: "cancel",
          text: "OK",
          handler: () => {
            //nothing, this is just a dismiss
          },
        },
      ],
    };
    const alert = await this.alertCtrl.create(alertOptions);
    alert.present();
  }
}
