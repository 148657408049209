import { LicenseTypes } from "@models/MasterData";
import { TranslateService } from "@ngx-translate/core";
import { NewBaseService } from "@providers/newBase.service";
import { ILicenseFilter } from "./ILicenseFilter";
import { ArgentinaFilter } from "./argentina.filter";
/**
 * This class has a list of filters that exist for a specific
 * license. Classes that apply restrictions, i.e play casino games
 * can get a filter to apply any restriction.
 * In order to do that, any existent filter should be registered
 * here so in the implementation any class can perform this:
 *
 * - check if a specific filter exist for a license
 * - return a new instance of a filter
 *
 * There are not enforcement methods since requirements can vary a lot
 */
export class FilterFactory {
  /**
   * Please register here any license filter
   * @param license the number of the license
   */
  public static getFilter(
    license: number,
    translate: TranslateService,
    newBaseService: NewBaseService,
  ): ILicenseFilter {
    switch (license) {
      case LicenseTypes.ArgentinaCaba:
      case LicenseTypes.ArgentinaMendoza:
        //argentina
        return new ArgentinaFilter(translate, newBaseService);
      default:
        // ** + ** El error indica que no se puede asignar null al de tipo ILicenseFilter.
        return null;
    }
  }
}
