import { GlobalVarsModel } from "@models/index";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
/* eslint-disable */
import { PaySafeCardDepositActionResult } from "../../models/C_Transactions";
import { ErrorService } from "../ErrorService";
import { UserService } from "../UserService";
import { NewBaseService } from "../newBase.service";
import { IFramePaymentMethodBehaviourResult, PaymentMethodBehaviour, SkipAmountResult } from "./PaymentMethodBase";

export class CyberSourceMethodBehaviour extends PaymentMethodBehaviour {
  name = "APMCYBERSOURCE";
  image = "cs-logo_CreditCards";
  track = "AccessToCyberSource";
  isBank = false;
  minDepositAmount = 10;
  maxDepositAmount = 100;
  amountButtons: number[] = [10, 20, 40, 50];
  amount = 20;

  globalVars!: GlobalVarsModel;
  newBaseService: NewBaseService;

  constructor(userService: UserService, errorService: ErrorService, newBaseService: NewBaseService) {
    super(false, userService, errorService);
    this.newBaseService = newBaseService;

    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
  }

  skipAmountInput(): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  paymentMetodExecution(mifunc?: any): Observable<IFramePaymentMethodBehaviourResult> {
    return this.userService.validateDepositCyberSourcePayV2(this.amount).pipe(
      map((obj) => {
        return this.map(obj);
      }),
    );
  }

  private map(input: PaySafeCardDepositActionResult): IFramePaymentMethodBehaviourResult {
    const result = new IFramePaymentMethodBehaviourResult();
    if (input.success) {
      result.success = true;
      result.iframeType = "cybersource";
      result.iframeArgs = { type: "cybersource", url: encodeURI(input.url) };
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams(
        "error" + input.errCode,
        input.errorParameters,
      );
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode == 174 || input.errCode == 2212;
      result.sendToProfesion = input.errCode == 2412;
    }
    return result;
  }
}
