import { CommonModule } from "@angular/common";
import { Component, Input, inject } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule } from "@ngx-translate/core";
import { NewBaseService } from "@providers/newBase.service";

@Component({
  selector: "deposits-amount-input",
  templateUrl: "./deposits-amount-input.html",
  styleUrls: ["./deposits-amount-input.scss"],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, TranslateModule],
})
export class depositsInputsComponent {
  @Input() item: any;
  @Input() t_error: string;
  operationValue: number;
  globalVars!: GlobalVarsModel;

  newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    switch (this.globalVars.licenseType) {
      case LicenseTypes.Nacional:
      case LicenseTypes.Madrid:
      case LicenseTypes.Panama:
        this.operationValue = 10;
        break;
      case LicenseTypes.Mexico:
        this.operationValue = 100;
        break;
      case LicenseTypes.Colombia:
        this.operationValue = 1000;
        break;
    }
  }

  lessValue() {
    const value = this.item.model.depositAmount ? Number.parseInt(this.item.model.depositAmount) : 0;
    const finishValue = value - this.operationValue > 0 ? value - this.operationValue : value;
    this.item.model.depositAmount = finishValue;
  }

  moreValue() {
    const value = this.item.model.depositAmount ? Number.parseInt(this.item.model.depositAmount) : 0;
    const finishValue = value + this.operationValue;
    this.item.model.depositAmount = finishValue;
  }
}
