/* eslint-disable eqeqeq */
import { Component, Input, inject } from "@angular/core";
import { Aenor } from "@models/footer/aenor";
import { TypeRowTextLegal } from "@models/footer/typetextlegal";
import { EventsService } from "@providers/EventsService";

import { CommonModule } from "@angular/common";
import { Browser } from "@capacitor/browser";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

import { Capacitor } from "@capacitor/core";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "codere-footer-legal",
  templateUrl: "./codere-footer-legal.html",
  styleUrls: ["./codere-footer-legal.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, IonicModule],
})
export class CodereFooterLegal {
  isDesktop;
  globalVars!: GlobalVarsModel;
  @Input() rowsTexts: TypeRowTextLegal[] = [];
  @Input() time: string | null = null;
  @Input() timer = false;
  @Input() aenor: Aenor | null = null;
  _CAMBIARPORCLOBAL1 = 1;

  newBaseService = inject(NewBaseService);
  eventsService = inject(EventsService);
  deviceService = inject(DeviceService);

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
  }

  stylesIonRow(classes: string) {
    if (this.globalVars.licenseType == 21) {
      return classes + " backgroundblack-co";
    }
    if (this.globalVars.licenseType == LicenseTypes.ArgentinaCaba) {
      return classes + " align-start height-100";
    }
    return classes;
  }

  styleIonRowText(classes: string) {
    if (this.globalVars.licenseType == LicenseTypes.ArgentinaCaba) {
      return classes + " height-100";
    }
    return classes;
  }

  stylesIonCol() {
    if (this.globalVars.licenseType == LicenseTypes.ArgentinaCaba) {
      return "padding-ar";
    }
  }

  isImageType(anchors_images: any) {
    const o = new Object(anchors_images);
    // eslint-disable-next-line no-prototype-builtins
    return o.hasOwnProperty("external");
  }

  public openEvent(event, typeEvent: string) {
    this.eventsService.publish("footerEvent:open", { event, typeEvent });
  }

  public async openBrowser(link: string) {
    if (Capacitor.getPlatform() === "android") {
      const options = {
        toolbarColor: "#000000",
        windowColor: "#000000",
        backButtonCanClose: true,
        backButtonStyle: "close",
        navigationBarColor: "#000000",
        showTitle: true,
      };

      try {
        await Browser.open({ url: link, ...options });
      } catch (error) {
        console.error("Error opening browser:", error);
      }
    } else {
      // iOS and normal browsers
      window.open(link, "_system", "location=yes");
    }
  }
}
