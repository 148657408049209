import { PAYMENT_CONSTANTS } from "../constants/payment.constants";

export const EmitUserServiceConfig = {
  emitter: {
    methodName: {
      TRUELAYER: "emitTrueLayerWidthrawal",
      TPAGA: "emitTpagaWithdrawal",
      LOCAL: "emitLocalWithdrawal",
      COINSPAID: "emitCoinspaidWithdrawal",
    },
  },
  userService: {
    methodName: {
      GENERIC_WITHDRAWALS: "validateWithdrawals",
      ASTROPAY: "ValidateAstropay",
    },
    url: {
      PSE: { endpoint: "pse/charge?amount=", params: "&code=1551" },
      LOBTA: { endpoint: "lotba/withdrawal?amount=" },
      PAYPAL: { endpoint: "Payments/CreatePaypalCharge?amount=" },
      DAVI_PLATA: { endpoint: "daviplata/charge?amount=", params: "&code=1551" },
      NEQUI: { endpoint: "nequi/charge?amount=", params: "&code=1507" },
      MUCH_BETTER: { endpoint: "epg/muchbetter/charge?amount=" },
      EFECTY: { endpoint: "efecty/withdrawal?amount=" },
    },
    v2: {
      featureFlags: {
        PSE: "Payments_Pse_UsePaymentsv2",
        PAYPAL: "Payments_Paypal_UsePaymentsv2",
        DAVI_PLATA: "Payments_Daviplata_UsePaymentsv2",
        NEQUI: "Payments_Nequi_UsePaymentsv2",
        EFECTY: "Payments_Efecty_UsePaymentsv2",
      },
      constants: {
        PSE: PAYMENT_CONSTANTS.METHOD.PSE,
        PAYPAL: PAYMENT_CONSTANTS.METHOD.PAYPAL,
        DAVI_PLATA: PAYMENT_CONSTANTS.METHOD.DAVI_PLATA,
        NEQUI: PAYMENT_CONSTANTS.METHOD.NEQUI,
        EFECTY: PAYMENT_CONSTANTS.METHOD.EFECTY,
      },
    },
  },
};
