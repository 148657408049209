export const DEV = true;

// Interfaces del funcionario completo

/**
 * Base para la craeación de los interfaces
 * - LabelControls
 * - ErrorControls
 */
interface RegistroControls {
  gender: object;
  name: string;
  name2: string;
  name3: string;
  birthDate: object;
  dni: string;
  resident: string;
  nationality: string;
  country: string;
  documentType: string;
  documentNumber: string;
  address: string;
  postalCode: string;
  mobile: string;
  email: string;
  user: string;
  password: string;
  promoCode: string;
  friendsID: string;
  confirmPass: string;
}

interface LabelControls extends RegistroControls {
  gender: { man: string; woman: string };
  birthDate: { day: string; month: string; year: string };
}

interface ErrorControls {
  // gender: object,
  name: ErrorMsgs;
  name2: ErrorMsgs;
  name3: ErrorMsgs;
  birthDate: ErrorMsgs;
  // birthDate : {error1: string, notValidDate :string, error2 : string },,
  dni: ErrorMsgs;
  resident: ErrorMsgs;
  nationality: ErrorMsgs;
  country: ErrorMsgs;
  documentType: ErrorMsgs;
  documentNumber: ErrorMsgs;
  address: ErrorMsgs;
  postalCode: ErrorMsgs;
  mobile: ErrorMsgs;
  email: ErrorMsgs;
  user: ErrorMsgs;
  password: ErrorMsgs;
  promoCode: ErrorMsgs;
  friendsID: ErrorMsgs;
  confirmPass: ErrorMsgs;
}

export interface ErrorMsgs {
  required: string;
  requiredTrue?: string;
  minlength?: string;
  maxlength?: string;
  pattern?: string;
  validFormat?: string;
  notValidDate?: string;
  validUnique?: string;
  validPassword?: string;
  backError?: string;
  checkIdentity?: string;
}

export interface ErrorsControls {
  [errorName: string]: ErrorMsgs;
}

// Interfaces de la sección Datos personales

/**
 * Base para la craeación del interface LabelPersonalControls
 */

interface PersonalControls {
  gender: object;
  name: string;
  name2: string;
  name3: string;
  birthDate: object;
  dni: string;
  resident: string;
  nationality: string;
  country: string;
  documentType: string;
  documentNumber: string;
  profesion: string;
  supportNumber: string;
}

export interface LabelPersonalControls extends PersonalControls {
  gender: { man: string; woman: string };
  birthDate: { day: string; month: string; year: string };
}

// Interfaces de la sección Datos de contacto

interface ContactoControls {
  address: string;
  postalCode: string;
  mobile: string;
  email: string;
}

export type LabelContactoControls = ContactoControls;

// Interfaces de la sección Datos de usuario

interface UsuarioControls {
  user: string;
  password: string;
  promoCode: string;
  friendsID: string;
}

export type LabelUsuarioControls = UsuarioControls;

// Interfaces de la sección Confirmación

interface ConfirmControls {
  confirm: string;
}

export type LabelConfirmControls = ConfirmControls;

// Otros interfaces del formulario

export interface Secciones {
  titlePersonalData: string;
  titleUserData: string;
  titleBirthDate: string;
  titleContactData: string;
  titleConfirmData: string;
}

export interface Buttons {
  next: string;
  back: string;
  endRegister: string;
}

export interface Mensajes {
  confirmText: string;
  comunicsText: string;
  /* infoPromos : string,
    infoCasino: string */
}

export interface RegistroData {
  Genero_Hombre_Mujer: string;
  NIF: string;
  AceptaEmailPromo_Y_N: boolean;
  AceptaEmailPromo_Y_NSpecified: boolean;
  AceptaSMSPromo_Y_N: boolean;
  AceptaSMSPromo_Y_NSpecified: boolean;
  Ciudad: string | null;
  CodigoPostalExt: string | null;
  CodigoPostal: string;
  Direccion: string;
  EstadoCta: string | null;
  FechaNacimiento_YYYYMMDD: string;
  FechaNacimiento_YYYYMMDDSpecified: boolean;
  NombreUsuario: string;
  Pais: string;
  Password: string;
  Provincia: string | null;
  SegundoApellido: string;
  VerifiDocumental: string;
  NumMovilExt: null;
  NumMovil: string;
  Nacionalidad: string;
  TipoDocumento: string;
  NumeroDocumento: string;
  Direccion_Correo: string;
  Nombre: string;
  PrimerApellido: string;
  Afiliado: string | null;
  VersionTyCAceptada: string;
  DispositivoRegistro: string;
  CodigoAgente: string | null;
  CodigoPartner: string | null;
  OrigenWebLocal: string;
  TipoDocumento_NIF_NIE: string;
  CodigoBono: string;
  NodoSalaId: string | null;
  Profesion: string;
  Prp: boolean;
  NifSupportNumber: string;
}

//eslint-disable-next-line
export enum GenderOptions {
  Hombre = "Hombre",
  Mujer = "Mujer",
}

//eslint-disable-next-line
export enum passwErrors {
  incUser,
  incBirthday,
  incSurname2,
  incSurname,
  incName,
  tooLong,
  tooShort,
  noNum,
  noMay,
  noMin,
}

//eslint-disable-next-line
export enum DeviceType {
  NATIVE = "cordova",
  WEB = "web",
}
