import { Participant } from "../index";
import { C_Game } from "./game.model";

import { SixPack } from "@sports-models/classes/sixpack.model";
import { DisplayType } from "@sports-models/enum/display-tipe.enum";
import { LiveHistory } from "./live-history.model";

export class C_Event {
  ChildrenCount: number;
  CountryCode?: string;
  Games: Array<C_Game>;
  LiveHistory: Array<LiveHistory>;
  LeagueName: string;
  Locked?: boolean;
  ParentNodeId: string;
  Participants: Array<Participant>;
  Priority: any;
  SmartMarketReferenceGameTypeIds: string;
  SportHandle: string;
  SportName?: string;
  StartDate: string;
  StatisticsId: string;
  StreamingEnabled: string;
  detailScoreboard?: string;
  isLive: boolean;
  isSixPack?: boolean;
  isSpecial?: boolean;
  LTMEnabled?: boolean;
  mode?: string = "teams";
  Name: string;
  NodeId: string;
  overviewScoreboard?: string;
  pitcherAway?: string;
  pitcherHome?: string;
  scoreboardType: string; // remove
  sixPack?: any;
  teamAway: string;
  teamHome: string;
  liveData?: any;

  constructor(
    games?: Array<C_Game>,
    leagueName?: string,
    startDate?: string,
    isLive?: boolean,
    statisticsId?: string,
    streamingEnabled?: string,
    name?: string,
    nodeId?: string,
    parentNodeId?: string,
    priority?: any,
    sporthandle?: string,
    childrenCount?: number,
    isSixPack?: boolean,
    isSpecial?: boolean,
    Locked?: boolean,
    sixPack?: any,
    LTMEnabled?: boolean,
  ) {
    this.Games = games;
    this.LeagueName = leagueName;
    this.StartDate = startDate;
    this.isLive = isLive;
    this.StatisticsId = statisticsId;
    this.StreamingEnabled = streamingEnabled;
    this.Name = name;
    this.NodeId = nodeId;
    this.ParentNodeId = parentNodeId;
    this.Priority = priority;
    this.SportHandle = sporthandle;
    this.ChildrenCount = childrenCount;
    this.isSixPack = isSixPack;
    this.Locked = Locked;
    this.sixPack = sixPack;
    this.LTMEnabled = LTMEnabled;
  }

  static parse(obj: any): C_Event {
    if (obj != undefined && obj != null) {
      const event = Object.assign(new C_Event(), obj);
      event.Name = event.Name ? event.Name.replace(/%20/g, " ") : undefined;
      event.LeagueName = event.LeagueName ? event.LeagueName.replace(/%20/g, " ") : undefined;
      event.Games = event && event.Games ? event.Games.filter(Boolean) : [];
      event.setHomeAndAway();
      event.setScoreboardType();
      event.setDetailScoreboardType();
      event.setLockedResults();
      event.setMode();
      return event;
    } else {
      return undefined;
    }
  }

  public setMode() {
    this.Games = this.Games.filter((game) => !!game);
    const hasResults = this.Games.length > 0 && this.Games[0]?.Results?.length > 3;
    const isOutrightList = this.Games.length > 0 && this.Games[0]?.DisplayTypeName === DisplayType.OUTRIGHTLIST;
    this.mode = hasResults || isOutrightList ? "expanded" : "teams";
  }

  public setHomeAndAway() {
    if (!this.Name) return;

    const pitcherNameRegex = /\S [A-Za-z]+/g;
    const parenthesisContentRegex = /\((.*?)\)/g;

    if (this.SportHandle === "baseball" && this.Name.match(parenthesisContentRegex)) {
      const pitchers = this.Name.match(parenthesisContentRegex);
      if (pitchers && pitchers.length === 2) {
        [this.pitcherHome, this.pitcherAway] = pitchers;
        this.Name = this.Name.replace(this.pitcherHome, "").replace(this.pitcherAway, "");

        this.pitcherHome = this.extractPitcherName(this.pitcherHome, pitcherNameRegex);
        this.pitcherAway = this.extractPitcherName(this.pitcherAway, pitcherNameRegex);
      }
    }

    this.Name = this.Name.trimEnd();
    [this.teamHome, this.teamAway] = this.Name.split(" - ");
  }

  public extractPitcherName(pitcher: string, regex: RegExp): string | undefined {
    const match = pitcher.match(regex);
    return match ? pitcher.slice(1, -1).replace(".", "") : undefined;
  }

  public setScoreboardType(): string {
    if (!this.isLive) return "";

    const regularSports = [
      "american_football",
      "baseball",
      "basketball",
      "billard",
      "darts",
      "esports",
      "futsal",
      "golf",
      "handball",
      "ice_hockey",
      "motor",
      "rugby",
      "snooker",
      "soccer_indoor",
      "soccer",
      "ebasket",
      "efootball",
    ];

    const setBasedSports = [
      "badminton",
      "beach_soccer",
      "beach_volleyball",
      "tennis",
      "table_tennis",
      "tennis_clay",
      "tennis_grass",
      "tennis_indoor",
      "volleyball",
    ];

    if (regularSports.includes(this.SportHandle)) {
      this.overviewScoreboard = "regular";
    } else if (setBasedSports.includes(this.SportHandle)) {
      this.overviewScoreboard = "sets";
    } else {
      this.overviewScoreboard = "";
    }

    return this.overviewScoreboard;
  }

  public setDetailScoreboardType(): void {
    if (!this.isLive) return;

    const regularSports = [
      "",
      "billard",
      "darts",
      "esports",
      "futsal",
      "golf",
      "handball",
      "motor",
      "rugby",
      "snooker",
    ];

    const setBasedSports = [
      "american_football",
      "badminton",
      "basketball",
      "beach_soccer",
      "beach_volleyball",
      "ebasket",
      "efootball",
      "eSports_sports",
      "Esports_sports",
      "ice_hockey",
      "soccer",
      "table_tennis",
      "tennis_clay",
      "tennis_grass",
      "tennis_indoor",
      "tennis",
      "volleyball",
    ];

    if (regularSports.includes(this.SportHandle)) {
      this.detailScoreboard = "regular";
    } else if (setBasedSports.includes(this.SportHandle)) {
      this.detailScoreboard = "sets";
    } else if (this.SportHandle === "baseball") {
      this.detailScoreboard = "baseball";
    } else {
      this.detailScoreboard = "";
    }
  }

  public setGamesByPlatform(gameTypes: number[], isMobile: boolean = true, hasCategory: boolean = false) {
    // -> este evento tiene todos los games pedidos como sport;
    if (this.isSpecial || hasCategory) return;
    this.filterGames(gameTypes, isMobile);
  }

  public hasSixPack(category: number = 0): boolean {
    if (category > 0) return (this.isSixPack = false);
    return (this.isSixPack =
      !this.isSpecial && !this.isLive && SixPack.validate(this.SportHandle, this.CountryCode));
  }

  public hasSpecialGame(gameTypes: number[]) {
    this.isSpecial = this.Games && this.Games.length === 1 && gameTypes.indexOf(this.Games[0].GameType) !== -1;
    if (this.isSpecial) {
      this.mode = "expanded";
    }
    return this.isSpecial;
  }

  setLockedResults(): void {
    if (!this.Games) return;
    this.Games.forEach((game) => {
      if (game) {
        game.Results.map((result) => {
          result = {
            ...result,
            Locked: game.Locked,
          };
        });
      }
    });
  }

  public filterGames(gameTypes: number[] = [], isMobile: boolean): void {
    const games = gameTypes
      .map((gt) => {
        const game = this.Games.find((g) => g && g.GameType === gt);
        if (game) return game;
        return isMobile ? null : new C_Game([]);
      })
      .filter(Boolean);
    const limit = isMobile && !this.isSixPack ? 1 : games.length;
    this.Games = games.slice(0, limit);
  }
}
