import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";

import { IonicModule } from "@ionic/angular";

import { FormatOdd } from "@pipes/formatOdd.pipe";

@Component({
  selector: "sb-betbuilder",
  templateUrl: "./sb-betbuilder.html",
  styleUrls: ["./sb-betbuilder.scss"],
  standalone: true,
  imports: [IonicModule, CommonModule, FormatOdd],
})
export class BetbuilderComponent {
  @Input() cuotaText: string = "";
  @Input() MASelections: any[];
  @Input() errorMessage: string = "";
  @Input() messageText: string = "Añadir al menos X selecciones.";
  @Input() isMobile: boolean = false;

  @Output() placeBetbuilderBet: EventEmitter<any> = new EventEmitter();
  @Output() removeBetbuilderBet: EventEmitter<any> = new EventEmitter();

  placeBet() {
    this.placeBetbuilderBet.emit();
  }

  removeSelection() {
    this.removeBetbuilderBet.emit();
  }
}
