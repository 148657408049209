import { SbStreamMarketComponent } from "./sb-stream-market/sb-stream-market";
export { SbStreamMarketComponent } from "./sb-stream-market/sb-stream-market";

import { SbTabsComponent } from "./sb-tabs/sb-tabs.component";
export { SbTabsComponent } from "./sb-tabs/sb-tabs.component";

import { SbTabsCategoriesComponent } from "./sb-tabs-categories/sb-tabs-categories.component";
export { SbTabsCategoriesComponent } from "./sb-tabs-categories/sb-tabs-categories.component";

import { SbMarqueeBetSenseComponent } from "./sb-marquee-betsense/sb-marquee-betsense";
export { SbMarqueeBetSenseComponent } from "./sb-marquee-betsense/sb-marquee-betsense";

import { SbIconBarComponent } from "./sb-icon-bar/sb-icon-bar.component";
export { SbIconBarComponent } from "./sb-icon-bar/sb-icon-bar.component";

import { SBBetslipStakeComponent } from "./betslip/betslip-stake-input/betslip-stake-input.component";
export { SBBetslipStakeComponent } from "./betslip/betslip-stake-input/betslip-stake-input.component";

import { BetslipTicketConfirmedComponent } from "./betslip/betslip-ticket-confirmed/betslip-ticket-confirmed.component";
export { BetslipTicketConfirmedComponent } from "./betslip/betslip-ticket-confirmed/betslip-ticket-confirmed.component";

import { BetslipOveraskComponent } from "./betslip/betslip-overask/betslip-overask.component";
export { BetslipOveraskComponent } from "./betslip/betslip-overask/betslip-overask.component";

import { SportsPageHeaderComponent } from "./sports-page-header/sports-page-header.component";
export { SportsPageHeaderComponent } from "./sports-page-header/sports-page-header.component";

import { BetbuilderComponent } from "./sb-betbuilder/sb-betbuilder";
export { BetbuilderComponent } from "./sb-betbuilder/sb-betbuilder";

import { SbMarqueeBetbuilderComponent } from "./sb-marquee-betbuilder/sb-marquee-betbuilder";
export { SbMarqueeBetbuilderComponent } from "./sb-marquee-betbuilder/sb-marquee-betbuilder";

import { SbMarqueeComponent } from "./sb-marquee/sb-marquee";
export { SbMarqueeComponent } from "./sb-marquee/sb-marquee";

import { TicketBetbuilderComponent } from "./betslip/ticket-betbuilder/ticket-betbuilder";
export { TicketBetbuilderComponent } from "./betslip/ticket-betbuilder/ticket-betbuilder";

import { SportsKeyboardComponent } from "./sports-keyboard/sports-keyboard.component";
export { SportsKeyboardComponent } from "./sports-keyboard/sports-keyboard.component";

import { MyBetsTileComponent } from "./my-bets-tile/my-bets-tile.component";
export { MyBetsTileComponent } from "./my-bets-tile/my-bets-tile.component";

import { SbDropdownMenuComponent } from "./sb-dropdown-menu/sb-dropdown-menu.component";
export { SbDropdownMenuComponent } from "./sb-dropdown-menu/sb-dropdown-menu.component";

import { Ticket } from "./ticket/ticket-base.component";
export { Ticket } from "./ticket/ticket-base.component";

export const Components: any[] = [
  BetslipTicketConfirmedComponent,
  BetslipOveraskComponent,
  SbStreamMarketComponent,
  SbTabsComponent,
  SbMarqueeBetSenseComponent,
  SbIconBarComponent,
  SbMarqueeBetSenseComponent,
  SbStreamMarketComponent,
  SbTabsCategoriesComponent,
  SbTabsComponent,
  SBBetslipStakeComponent,
  SportsPageHeaderComponent,
  BetbuilderComponent,
  SbMarqueeBetbuilderComponent,
  SbMarqueeComponent,
  TicketBetbuilderComponent,
  SportsKeyboardComponent,
  MyBetsTileComponent,
  SbDropdownMenuComponent,
  Ticket,
];
