import { C_CategoryInfo } from "@models/C_CategoryInfo";
import { createReducer, on } from "@ngrx/store";
import {
  eventDataModel,
  homeDataModel,
  liveDataModel,
  liveMarketDataModel,
  marketDataModel,
  raceDataModel,
  raceMarketDataModel,
  sportCountriesDataModel,
  todayDataModel,
} from "@sports-models/index";
import { FLOW_STATUS } from "../../utils/sports.constants";
import * as SportsbookActions from "./sportsbook.actions";

export interface SportsbookState {
  homeData: homeDataModel;
  sportCountriesData: sportCountriesDataModel;
  eventData: eventDataModel;
  marketData: marketDataModel;
  liveData: liveDataModel;
  liveMarketData: {
    prevLiveMarketData: liveMarketDataModel;
    currentLiveMarketData: liveMarketDataModel;
    categories: C_CategoryInfo[];
    selectedCategory: C_CategoryInfo;
    market: any;
    marketLiveState: string;
  };
  raceData: raceDataModel;
  raceMarketData: raceMarketDataModel;
  todayData: todayDataModel;
  specialTeamData: any[];
  sportEventsData: any[];
}

export const initialState: SportsbookState = {
  homeData: {
    marquee: [],
    homeLiveEvents: {
      LiveEventsCount: 0,
      LiveSport: [],
    },
    highlightsEvents: {
      events: [],
      sports: [],
      selectedSport: null,
    },
    betBuilder: [],
  },
  sportCountriesData: {
    parentId: "",
    highlights: [],
    countries: [],
  },
  eventData: {
    eventMainCategories: [],
    eventMarkesCategories: [],
    games: [],
    specialMarkets: null,
    leagueName: "",
    event: null,
    parentId: "",
    selectedEventMainCategory: null,
    selectedMarketsCategory: null,
  },
  marketData: {
    categories: [],
    marketBets: [],
    leagueName: "",
    market: null,
    parentId: "",
    selectedCategory: null,
    betsenseData: null,
  },
  liveData: {
    sportsNav: [],
    selectedSportsNav: null,
    categories: [],
    selectedCategory: null,
    sport: [],
    liveState: FLOW_STATUS.INITIAL_STATUS,
  },
  liveMarketData: {
    prevLiveMarketData: { CategoryInfos: [], Events: [] },
    currentLiveMarketData: { CategoryInfos: [], Events: [] },
    categories: [],
    selectedCategory: null,
    market: null,
    marketLiveState: FLOW_STATUS.INITIAL_STATUS,
  },
  raceData: {
    filters: [
      {
        index: 0,
        name: "Proximamente",
        navigate: "next",
      },
      {
        index: 1,
        name: "Hoy",
        navigate: "todayPaddock",
      },
      {
        index: 2,
        name: "Mañana",
        navigate: "tomorrowPaddock",
      },
    ],
    selectedFilter: null,
    next: [],
    today: [],
    tomorrow: [],
  },
  raceMarketData: {
    filters: [
      { index: 0, name: "tWin", navigate: "win" },
      { index: 1, name: "tTwin", navigate: "forecast" },
      { index: 2, name: "tTrio", navigate: "tricast" },
    ],
    selectedFilter: null,
    dropListEvents: [],
    dropListEventSelected: null,
    participants: "initialState",
  },
  todayData: {
    fiveDays: [],
    selectedDay: null,
    selectedSport: { CategoryId: "soccer", IsRelevant: true, CategoryName: "Fútbol", numGames: 0, name: "Fútbol" },
    sportsFilters: [
      { CategoryId: "soccer", IsRelevant: true, CategoryName: "Fútbol", numGames: 0, name: "Fútbol" },
      { CategoryId: "tennis", IsRelevant: true, CategoryName: "Tenis", numGames: 0, name: "Tenis" },
      { CategoryId: "basketball", IsRelevant: true, CategoryName: "Baloncesto", numGames: 0, name: "Baloncesto" },
    ],
    sportsEvents: [],
  },
  specialTeamData: [],
  sportEventsData: [],
};

export const SportsbookReducer = createReducer(
  initialState,
  // HomePage
  on(SportsbookActions.setHome, (state, { type, ...homeData }) => ({
    ...state,
    homeData,
  })),
  on(SportsbookActions.clearHome, (state) => ({
    ...state,
    homeData: initialState.homeData,
  })),
  // SportCountriesPage
  on(SportsbookActions.setSportCountries, (state, sportCountriesData) => ({
    ...state,
    sportCountriesData,
  })),
  // EventPage
  on(SportsbookActions.setEventGames, (state, eventData) => ({
    ...state,
    eventData,
  })),
  on(SportsbookActions.clearEvent, (state) => ({
    ...state,
    eventData: initialState.eventData,
  })),
  // TodayPage
  on(SportsbookActions.setToday, (state, todayData) => ({
    ...state,
    todayData,
  })),
  on(SportsbookActions.clearToday, (state) => ({
    ...state,
    todayData: initialState.todayData,
  })),
  // MarketPage
  on(SportsbookActions.setMarkets, (state, marketData) => ({
    ...state,
    marketData,
  })),
  on(SportsbookActions.clearMarkets, (state) => ({
    ...state,
    marketData: initialState.marketData,
  })),
  // Race
  on(SportsbookActions.setRace, (state, raceData: raceDataModel) => ({
    ...state,
    raceData,
  })),
  on(SportsbookActions.clearRace, (state) => ({
    ...state,
    raceData: initialState.raceData,
  })),
  // Race Market
  on(SportsbookActions.setRaceMarket, (state, raceMarketData: raceMarketDataModel) => ({
    ...state,
    raceMarketData: { ...state.raceMarketData, ...raceMarketData },
  })),
  on(SportsbookActions.clearRaceMarket, (state) => ({
    ...state,
    raceMarketData: initialState.raceMarketData,
  })),
  // Live
  on(SportsbookActions.setLive, (state, { type, ...liveData }) => ({
    ...state,
    liveData,
  })),
  on(SportsbookActions.setLiveAndFetch, (state, { type, ...liveData }) => ({
    ...state,
    liveData,
  })),
  on(SportsbookActions.setSelectedSportsNav, (state, { type, ...selectedSportsNav }) => ({
    ...state,
    liveData: { ...state.liveData, selectedSportsNav },
  })),
  on(SportsbookActions.clearLive, (state) => ({
    ...state,
    liveData: initialState.liveData,
  })),
  // Live Market
  on(SportsbookActions.initLiveMarket, (state, { type, ...payload }) => ({
    ...state,
    liveMarketData: { ...initialState.liveMarketData, market: payload.market },
  })),
  on(SportsbookActions.setLiveMarket, (state, { type, ...rest }) => ({
    ...state,
    liveMarketData: { ...state.liveMarketData, ...rest },
  })),
  on(SportsbookActions.setLiveMarketBetsense, (state, { type, ...payload }) => ({
    ...state,
    liveMarketData: { ...state.liveMarketData, betsenseData: payload.betsenseData },
  })),
  on(SportsbookActions.setLiveMarketSelectedCategory, (state, { type, ...payload }) => ({
    ...state,
    liveMarketData: { ...state.liveMarketData, selectedCategory: payload.selectedCategory },
  })),
  on(SportsbookActions.clearLiveMarket, (state) => ({
    ...state,
    liveMarketData: initialState.liveMarketData,
  })),
);
