import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot } from "@angular/router";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateService } from "@ngx-translate/core";
import { EventsService } from "@providers/EventsService";
import { SignalRService } from "@providers/SignalRService";
import { SlotsService } from "@providers/SlotsService";
import { NewBaseService } from "@providers/newBase.service";
import { GameService } from "@services/casino/game.service";
import { LobbyFacadeService } from "@services/casino/loobyFacade.service";
import { CASINO_PATHS } from "@shared-constants/routes";
import { Utils } from "@utils/Utils";
import { Observable } from "rxjs";
import { CONSTANTS } from "../constants/lobbyConstants";

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: "root",
})
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  globalVars!: GlobalVarsModel;

  lobbyFacadeService = inject(LobbyFacadeService);
  router = inject(Router);
  translate = inject(TranslateService);
  gamesService = inject(GameService);
  slotsService = inject(SlotsService);
  signalRService = inject(SignalRService);
  newBaseService = inject(NewBaseService);
  utils = inject(Utils);
  events = inject(EventsService);
  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    let navigateToCasinoOrGame = false;
    if (nextState?.url) {
      navigateToCasinoOrGame =
        nextState.url.includes(CASINO_PATHS.BASE) ||
        nextState.url.includes(CASINO_PATHS.SlotsGamePage) ||
        this.globalVars.gameFromQuickLaunch;
      this.globalVars.gameFromQuickLaunch = false;
      const navigateToCasino = nextState.url.includes(CASINO_PATHS.BASE);

      if (navigateToCasino) {
        this.events.publish("changePageCasino:finish");
      }
      if (this.globalVars.FEATURES.balanceCasinoDetails && this.globalVars.user.logged) {
        setTimeout(() => {
          this.events.publish("forceRefreshBalance");
        }, 10);
      }
      this.lobbyFacadeService.unapplyMoreFilters();

      return new Promise((resolve, reject) => {
        if (this.globalVars.user?.playingSlots && !navigateToCasinoOrGame) {
          const title = this.translate.instant("areYouSure");
          const message = this.translate.instant("closeSlotSession");
          const buttons = [
            {
              text: CONSTANTS.exit,
              handler: async () => {
                const callback = async () => {
                  await this.gamesService.closeSessionAndRefreshBalance();
                  resolve(true);
                };
                const alert = await this.slotsService.setCloseSessionAlert(callback).toPromise();
                if (!alert) {
                  await this.gamesService.closeSessionAndRefreshBalance();
                  resolve(true);
                } else {
                  if (this.gamesService.alert10Min) {
                    this.gamesService.alert10Min.dismiss();
                  }
                  await alert.present();
                  const footer = await this.utils.createARFooter();
                  if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
                    // Agregar el pie de página después de que la alerta se haya presentado
                    const ionAlert = await this.utils.alertController.getTop();
                    if (ionAlert) {
                      const alertElement = ionAlert.querySelector(".alert-wrapper");
                      if (alertElement) {
                        alertElement.appendChild(footer);
                      }
                    }
                  }

                  alert.onDidDismiss().then(() => {
                    this.signalRService.stopAll();
                    resolve(true);
                  });
                }
                try {
                  clearInterval(this.globalVars.timerSlots);
                } catch (err) {
                  this.signalRService.stopAll();
                  resolve(true);
                }
              },
            },
            {
              text: CONSTANTS.cancel,
              handler: () => {
                reject(false);
              },
            },
          ];
          this.gamesService.showAlertSlot(title, message, buttons);
        } else {
          this.signalRService.stopAll();
          resolve(true);
          return true;
        }
      });
    }
  }
}
