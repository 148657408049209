import { CommonModule } from "@angular/common";
import { Component, type OnInit, inject } from "@angular/core";
import { type FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, type Params, Router } from "@angular/router";
import { Buttons } from "@interfaces/interfaces";
import { IonicModule } from "@ionic/angular";
import { FsConfirmarComponent } from "../../../components/registro/fs-confirmar/fs-confirmar.component";
import { FsUsuarioComponent } from "../../../components/registro/fs-usuario/fs-usuario.component";
import { ValidationService } from "../../../services/registro/validation.service";

@Component({
  //schemas: [CUSTOM_ELEMENTS_SCHEMA],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "cdr-paso2",
  templateUrl: "./paso2.html",
  //template: '<h1>paso 2</h1>',
  standalone: true,
  styleUrls: ["./paso2.scss"],
  imports: [CommonModule, IonicModule, ReactiveFormsModule, FsUsuarioComponent, FsConfirmarComponent],
})
export class Paso2Page implements OnInit {
  buttons: Buttons;
  fgroup: FormGroup;
  formUsr: FormGroup;
  formConf: FormGroup;
  params: Params;

  vals = inject(ValidationService);
  route = inject(ActivatedRoute);
  router = inject(Router);

  constructor() {
    const navigation = this.router.getCurrentNavigation();
    this.params = navigation.extras.queryParams;
    this.fgroup = this.params["formReg"];
    this.formUsr = this.fgroup?.get("usuario") as FormGroup;
    this.formConf = this.fgroup?.get("confirmacion") as FormGroup;
  }

  ngOnInit() {
    this.buttons = {
      next: "",
      back: "",
      endRegister: "Finalizar Registro",
    };
  }

  onClick() {
    this.vals.validateAllFormFields(this.formUsr); // usuario
    this.vals.validateAllFormFields(this.formConf); // confirmacion

    if (this.formUsr.valid && this.formConf.valid) {
      // paso siguiente
    }
  }
}
