import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { type FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ErrorMsgs } from "@interfaces/interfaces";
import { IonicModule } from "@ionic/angular";
import { InputComponent } from "../input/input.component";

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, InputComponent, ReactiveFormsModule],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "cdr-expansion",
  templateUrl: "./expansion.component.html",
  styleUrls: ["./expansion.component.scss"],
})
export class ExpansionComponent {
  @Input() length: string;
  @Input() label: string;
  @Input() errorsCol: ErrorMsgs;
  @Input() modal: string;
  @Input() fcName: string;
  @Input() fgrp: FormGroup;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
}
