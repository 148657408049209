import { Injectable, signal } from "@angular/core";
import { ConnectionStatus, Network } from "@capacitor/network";

@Injectable({
  providedIn: "root",
})
export class NetworkService {
  statusSignal = signal<ConnectionStatus>(null);

  async initializeNetworkEvents() {
    await Network.getStatus().then((status: ConnectionStatus) => {
      this.statusSignal.set(status);
    });
    await Network.addListener("networkStatusChange", (status: ConnectionStatus) => {
      this.statusSignal.set(status);
    });
  }
}
