import { CommonModule } from "@angular/common";
import { Component, EventEmitter, OnInit, Output, inject } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { UserServiceMSO } from "@providers/UserServiceMSO";
import { Utils } from "@utils/Utils";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "card-personal-data-protection-policy-form",
  templateUrl: "./card-personal-data-protection-policy-form.html",
  styleUrls: ["./card-personal-data-protection-policy-form.scss"],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, ReactiveFormsModule],
})
export class CardPersonalDataProtectionPolicyFormComponent implements OnInit {
  @Output() policyAccepted: EventEmitter<boolean> = new EventEmitter();
  formGroup: FormGroup;
  headLabel: string;
  contentLabelInit: string;
  policyPdfLink: string;
  contentLabelEnd: string;
  url: string;
  hash: string;
  code: string;

  private translate = inject(TranslateService);
  private userServiceMSO = inject(UserServiceMSO);
  private formBuilder = inject(FormBuilder);
  public utils = inject(Utils);

  constructor() {
    this.formGroup = this.formBuilder.group({
      tycCheck: [false, [Validators.required, Validators.requiredTrue]],
    });
    this.headLabel = this.translate.instant("popuptycselfietitle");
    this.contentLabelInit = this.translate.instant("popuptycselfietx01");
    this.contentLabelEnd = this.translate.instant("popuptycselfietx03");
    this.policyPdfLink = this.translate.instant("popuptycselfietx02");
  }

  ngOnInit(): void {
    this.userServiceMSO.getLastTermsAndConditionsSelfieUrlbyRegion("Nacional").subscribe((data) => {
      this.code = data.Code;
      this.url = data.Url;
      this.hash = data.Hash;
    });
  }

  submit() {
    const payload = {
      code: this.code,
      url: this.url,
      hash: this.hash,
    };
    this.utils.loader();

    this.userServiceMSO.updateTyCSelfie(payload).subscribe({
      next: (_) => {
        this.utils.closeLoader();
        this.policyAccepted.emit(true);
        this.formGroup.reset();
      },
      error: (err) => {
        this.utils.closeLoader();
        const { message } = err;
        this.utils.showError(message);
      },
    });
  }
}
