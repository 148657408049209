export interface IAppsFlyerEventLog {
  af_content_id: string;
  af_revenue?: number;
  af_currency?: string;
}

export const EventNames = {
  LOGIN: "af_login",
  REGISTER: "af_complete_registration",
  FIRST_DEPOSIT: "af_purchase",
  SUB_DEPOSIT: "sdeposit",
};

export const DefaultLogData = [
  {
    name: "Login",
    id: EventNames.LOGIN,
  },
  {
    name: "Registration",
    id: EventNames.REGISTER,
  },
  {
    name: "First Deposit",
    id: EventNames.FIRST_DEPOSIT,
  },
  {
    name: "Subsequent Deposit",
    id: EventNames.SUB_DEPOSIT,
  },
];

export const CurrencyList = [{ id: "EUR" }, { id: "MXN" }, { id: "COP" }, { id: "USD" }];
