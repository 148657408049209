import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild, inject } from "@angular/core";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { DomController, IonicModule } from "@ionic/angular";

import { CommonModule, Location } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { EventsService } from "@providers/EventsService";
import { NewBaseService } from "@providers/newBase.service";
import { WindowRef } from "@providers/window";
import { DeviceService } from "@services/device.service";
import { SportService } from "@sports-services/index";

declare let avvpl: any;

@Component({
  selector: "video-player",
  templateUrl: "./video-player.html",
  styleUrls: ["./video-player.scss"],
  standalone: true,
  imports: [IonicModule, CommonModule],
})
export class VideoPlayerComponent implements AfterViewInit {
  public ticketTarget;
  public offsetX = 0;
  public offsetY = 0;
  public isFree = false;
  public hammer;
  public eventListClass = "";

  public event: any;
  public streamingEvents: any[] = [];
  public videotag: string;
  public lastEvent: any;
  public windowMove = false;

  private avvpl: any;

  zoom: any = {
    active: false,
    width: 0,
    height: 0,
    left: 0,
    boxHeight: 0,
    boxStartHeight: 0,
  };

  globalVars!: GlobalVarsModel;
  isDesktop = false;
  isMobile = false;

  events = inject(EventsService);
  element = inject(ElementRef);
  renderer = inject(Renderer2);
  domCtrl = inject(DomController);
  windowRef = inject(WindowRef);
  sportService = inject(SportService);
  sanitizer = inject(DomSanitizer);
  router = inject(Router);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  location = inject(Location);

  @ViewChild("videoPlayerContainer") container: ElementRef;
  @ViewChild("videoPlayerTopBar") topbar: ElementRef;

  constructor() {
    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();

    this.location.onUrlChange((url) => {
      if (
        [
          "HomePage",
          "DirectosPage",
          "EventoPage",
          "EventoMejoraPage",
          "HorsesPage",
          "HorsesMarketPage",
          "MercadosPage",
          "NowLiveDetailPage",
          "SportCountriesPage",
          "RealMadridPage",
          "RiverPlatePage",
          "SpecialTeamPage",
          "TodayPage",
          "SportEventsPage",
          "EventoSpecialPage",
        ].some((str) => url.includes(str))
      ) {
        this.globalVars.hideBetFooterBar = false;
        this.globalVars.hasTicket = true;
        if (!this.globalVars.ApplicationState.StreamingOn) {
          return;
        }
        if (this.isMobile) {
          this.events.publish("videoplayer:close");
        } else {
          this.events.publish("videoplayer:toticket");
          this.sportService.showSportradar$.next(false);
        }
        return;
      }

      this.globalVars.hideBetFooterBar = true;
      this.globalVars.hasTicket = false;
      if (this.globalVars.ApplicationState.StreamingOn) {
        this.events.publish("videoplayer:free");
      }
    });

    this.events.subscribe(
      "videoplayer:play",
      (args: { src: any; target: any; event: any; provider: any; height: any }) => {
        const { src, target, event, provider, height } = args;
        setTimeout(() => {
          this.event = event;
          this.lastEvent = event;
          this.ticketTarget = document.getElementById(target);

          this.videotag = src;

          this.addVideoTag(src);

          this.isFree = false;
          this.goToTarget(false);

          if (this.isDesktop) {
            this.zoom.boxStartHeight = height;
            const box = document.getElementsByClassName("sb-grid-layout")[0];
            this.renderer.setStyle(box, "height", box.getBoundingClientRect().height + "px");
          }

          this.renderer.setStyle(this.element.nativeElement, "display", "block");
          this.renderer.setStyle(this.element.nativeElement, "position", "absolute");
        }, 500);
        this.globalVars.videoPlayerOnTicket = false;
        this.globalVars.videoPlayerWindow = false;
        this.closeConnections();
      },
    );
    this.events.subscribe("videoplayer:playIframe", ({ src, target, event, provider, height }) => {
      setTimeout(() => {
        this.event = event;
        this.ticketTarget = document.getElementById(target);

        this.addIFrameTag(src);

        this.isFree = false;
        this.goToTarget(false);

        this.renderer.setStyle(this.element.nativeElement, "display", "block");
        this.renderer.setStyle(this.element.nativeElement, "position", "absolute");
      }, 500);
      this.globalVars.videoPlayerOnTicket = false;
      this.globalVars.videoPlayerWindow = false;
      this.closeConnections();
    });

    this.events.subscribe("videoplayer:close", () => {
      this.container.nativeElement.innerHTML = "";
      this.isFree = false;
      this.removeHammer();
      this.globalVars.videoPlayerWindow = false;
      this.globalVars.videoPlayerOnTicket = false;
      this.globalVars.ApplicationState.StreamingOn = false;
      this.zoom.active = false;

      if (this.isDesktop) {
        const box = document.getElementsByClassName("sb-grid-layout")[0];
        if (box) {
          this.renderer.setStyle(box, "height", this.zoom.boxStartHeight + "px");
          setTimeout(() => {
            this.renderer.setStyle(box, "height", "auto");
          }, 500);
        }
      }
      this.renderer.setStyle(this.element.nativeElement, "display", "none");
      this.renderer.setStyle(this.element.nativeElement, "position", "absolute");

      this.closeConnections();
    });

    this.events.subscribe("videoplayer:free", () => {
      if (this.isFree) {
        return;
      }

      if (!this.globalVars.ApplicationState.StreamingOn) {
        return;
      }

      if (!this.isDesktop) {
        this.domCtrl.write(() => {
          this.renderer.setStyle(this.element.nativeElement, "width", "58vw");
          this.renderer.setStyle(this.element.nativeElement, "height", "20vh");
        });
      }

      if (this.isDesktop && this.zoom.active) {
        this.handleZoomDesktop();
      }

      setTimeout(() => {
        this.globalVars.videoPlayerOnTicket = false;
        this.renderer.setStyle(this.element.nativeElement, "position", "absolute");

        this.handlePan({ center: { x: 0, y: window.innerHeight } }, true);
        this.isFree = true;

        this.hammer = new window["Hammer"](this.element.nativeElement);

        this.hammer.get("pan").set({ direction: window["Hammer"].DIRECTION_ALL });

        this.hammer.on("pan", (ev) => {
          this.handlePan(ev, false);
        });

        this.hammer.on("panend", (ev) => {
          this.handlePan(ev, true);
          const myNodeList = document.getElementsByClassName("scroll-content") as HTMLCollectionOf<HTMLElement>;
          for (let i = 0; i < myNodeList.length; i++) {
            myNodeList[i].style.overflowY = "scroll";
          }
        });

        this.hammer.on("panstart", (ev) => {
          const myNodeList = document.getElementsByClassName("scroll-content") as HTMLCollectionOf<HTMLElement>;
          for (let i = 0; i < myNodeList.length; i++) {
            myNodeList[i].style.overflowY = "hidden";
          }

          this.renderer.setStyle(this.element.nativeElement, "transition", "unset");

          const bbox = this.element.nativeElement.getBoundingClientRect();
          this.offsetX = ev.center.x - bbox.left;
          this.offsetY = ev.center.y - bbox.top;
        });
      }, 500);
    });

    this.events.subscribe("videoplayer:toticket", () => {
      if (!this.globalVars.ApplicationState.StreamingOn) {
        return;
      }

      if (!this.isDesktop) {
        if (this.isFree) {
          return;
        }

        this.events.publish("videoplayer:free");
        return;
      }

      this.globalVars.videoPlayerWindow = true;
      this.goToTicket();
      this.isFree = false;
      this.removeHammer();
    });

    // todo: revisar events
    this.events.subscribe("videoplayer:tostart", ({ target, content }) => {
      if (!this.globalVars.ApplicationState.StreamingOn) {
        return;
      }

      if (!this.isFree) {
        return;
      }

      this.isFree = false;
      this.removeHammer();

      setTimeout(() => {
        this.ticketTarget = document.getElementById(target);
        this.goToTarget(true, content);
      }, 200);

      this.renderer.setStyle(this.element.nativeElement, "display", "block");
      this.renderer.setStyle(this.element.nativeElement, "position", "absolute");
    });
  }

  addVideoTag(src) {
    this.container.nativeElement.innerHTML = "";
    const videoTag = `<div class="video-player" id="video-player">
							<video class="live-iframe" preload="none" width="100%" height="100%">
							                	<source src="${src}" type="application/x-mpegURL" />
                                <source src="${src}" type="application/smil" />
                                <source src="${src}" type="video/webm" />
                                <source src="${src}" type="video/mp4" />
 							</video>
						</div>`;

    this.container.nativeElement.innerHTML = videoTag;
    this.globalVars.ApplicationState.StreamingOn = true;
    setTimeout(() => this.initStream(), 500);
  }

  addIFrameTag(src) {
    this.container.nativeElement.innerHTML = "";
    const frameTag = `<div class="video-player" id="video-player"><iframe class="live-iframe" width="100%" height="100%" src="${src}"></iframe></div>`;

    this.container.nativeElement.innerHTML = frameTag;
    this.globalVars.ApplicationState.StreamingOn = true;
  }

  close() {
    this.events.publish("videoplayer:close");
  }

  showMaximize(): boolean {
    const box = document.getElementsByClassName("has-two-items")[0];
    return box != undefined;
  }

  handleZoomDesktop() {
    const box = document.getElementsByClassName("has-two-items")[0];
    if (!this.globalVars.videoPlayerWindow) {
      if (!this.zoom.active) {
        this.zoom.active = true;
        const boxPosition = box.getBoundingClientRect();
        const elementPosition = this.element.nativeElement.getBoundingClientRect();
        this.zoom.left = elementPosition.left;
        this.zoom.width = elementPosition.width;
        this.zoom.height = elementPosition.height;
        this.zoom.boxHeight = boxPosition.height;
        this.renderer.setStyle(box, "transition", ".5s ease-out");
        this.renderer.setStyle(this.element.nativeElement, "transition", ".5s ease-out");
        this.renderer.setStyle(this.element.nativeElement, "left", boxPosition.left + "px");
        this.renderer.setStyle(this.element.nativeElement, "width", boxPosition.width + "px");
        this.renderer.setStyle(this.element.nativeElement, "height", "32.4vw");
        this.renderer.setStyle(box, "height", "32.4vw");
      } else {
        this.zoom.active = false;
        if (box) {
          this.renderer.setStyle(box, "transition", ".5s ease-out");
          this.renderer.setStyle(box, "height", this.zoom.boxHeight + "px");
        }
        this.renderer.setStyle(this.element.nativeElement, "transition", ".5s ease-out");
        this.renderer.setStyle(this.element.nativeElement, "left", this.zoom.left + "px");
        this.renderer.setStyle(this.element.nativeElement, "width", this.zoom.width + "px");
        this.renderer.setStyle(this.element.nativeElement, "height", this.zoom.height + "px");
      }
    }
  }

  private handleZoomMobile() {
    if (!this.zoom.active) {
      this.zoom.active = true;
      ScreenOrientation.lock({ orientation: "landscape-primary" });
    } else {
      this.zoom.active = false;
      ScreenOrientation.lock({ orientation: "portrait" });
    }
  }

  closeConnections() {
    if (this.windowRef.nativeWindow.lpInstance) {
      this.windowRef.nativeWindow.lpInstance.stop();
      if (this.windowRef.nativeWindow.lpInstance.activeVideoHandler.hls) {
        this.windowRef.nativeWindow.lpInstance.activeVideoHandler.hls.stopLoad();
      }
    }

    if (this.avvpl) {
      this.avvpl.remove();
    }
  }

  goToEvent() {
    /* this.globalVars.gNav.push(
      NowLiveDetailPage,
      { item: this.event },
      { animate: !this.isDesktop }
    ); */
    this.router.navigate(["/now-live-detail"], {
      queryParams: {
        item: this.event,
        animate: !this.isDesktop,
      },
    });
    this.events.publish("videoplayer:toticket");
  }

  onWindowResize() {
    if (this.isFree || this.isMobile) {
      return;
    }

    this.events.publish("videoplayer:toticket");
  }

  loadLiveEvents() {
    this.sportService.GetLiveEventsBySportHandle().subscribe((data) => {
      this.streamingEvents = [];
      for (const x in data) {
        const liveEvents = data[x].Events.filter(
          (event) => !!event.StreamingEnabled && event.StreamingEnabled != "",
        );
        this.streamingEvents = this.streamingEvents.concat(liveEvents);
      }
    });
  }

  loadEvent(event) {
    setTimeout(() => {
      this.event = event;
    }, 500);
    if (event.StreamingEnabled == "betradar") {
      this.getBetRadarVideoStreaming(event.StreamingId);
    } else if (event.StreamingEnabled == "img") {
      this.getVideoStreaming(event.NodeId, event.StreamingEnabled);
    } else {
      this.getVideoStreaming(event.StatisticsId, event.StreamingEnabled);
    }
  }

  changeEvent() {
    if (this.lastEvent.NodeId !== this.event.NodeId || this.globalVars.videoPlayerWindow) {
      this.lastEvent = this.event;
      this.events.publish("videoplayer:loadEvent", this.event);
    }
  }

  getBetRadarVideoStreaming(streamingId: string) {
    this.sportService.getStreamingBetRadarUrl(streamingId).subscribe((data) => {
      if (!!data && data.isLive) {
        this.videotag = data.url;
        this.addVideoTag(data.url);
      }
    });
  }

  getVideoStreaming(statisticsId: string, provider: string) {
    this.streamingEvents = [];
    this.sportService.getStreamingUrl(statisticsId, provider).subscribe((data) => {
      switch (provider) {
        case "img":
          if (data.success) {
            const token = data.token;
            const timestamp = data.timestamp;
            const id = data.id;

            this.sportService.getStreamingImgUrl(token, timestamp, id).subscribe((streamingdata) => {
              const urlobject = streamingdata;
              if (urlobject.hlsUrl != "") {
                this.videotag = urlobject.hlsUrl;
                this.addVideoTag(urlobject.hlsUrl);
              }
            });
          }
          break;
      }
    });
  }

  removeHammer() {
    if (!this.hammer) {
      return;
    }

    this.hammer.off("pan");
    this.hammer.off("panend");
    this.hammer.off("panstart");
    this.hammer.destroy();
  }

  ngAfterViewInit() {
    this.renderer.setStyle(this.element.nativeElement, "position", "absolute");
    this.renderer.setStyle(this.element.nativeElement, "left", "0px");
    this.renderer.setStyle(this.element.nativeElement, "top", "0px");
  }

  goToTarget(transition = true, content = null) {
    this.domCtrl.write(() => {
      if (!this.ticketTarget) {
        return;
      }

      const bbox = this.ticketTarget.getBoundingClientRect();
      let scrollTop = 0;
      if (content) {
        scrollTop = content.scrollTop;
      }

      const newLeft = bbox.left;
      const newTop = bbox.top + scrollTop;
      const newWidth = bbox.width;

      this.renderer.setStyle(this.element.nativeElement, "transition", transition ? ".5s ease-out" : "unset");
      this.renderer.setStyle(this.element.nativeElement, "width", newWidth + "px");
      this.getHeightVideo();

      if (document.body.getBoundingClientRect().width > 1024 || this.isMobile) {
        this.renderer.setStyle(this.element.nativeElement, "left", newLeft + "px");
      } else {
        this.renderer.setStyle(this.element.nativeElement, "left", "initial");
      }

      this.renderer.setStyle(this.element.nativeElement, "right", "25.7%");
      this.renderer.setStyle(this.element.nativeElement, "top", newTop + "px");
    });
  }

  private getHeightVideo() {
    let height;

    if (this.isMobile) {
      height = "56.4vw";
    } else {
      height = this.event !== "greyhound_racing" && this.event !== "horse_racing" ? "10vw" : "32.4vw";

      this.event !== "greyhound_racing" && this.event !== "horse_racing"
        ? this.renderer.removeClass(this.element.nativeElement, "races-streaming")
        : this.renderer.removeClass(this.element.nativeElement, "regular-streaming");

      this.event !== "greyhound_racing" && this.event !== "horse_racing"
        ? this.renderer.addClass(this.element.nativeElement, "regular-streaming")
        : this.renderer.addClass(this.element.nativeElement, "races-streaming");
    }
    this.renderer.setStyle(this.element.nativeElement, "height", height);
  }

  goToTicket() {
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.element.nativeElement, "transition", ".5s ease-out");
      this.renderer.setStyle(this.element.nativeElement, "width", "calc(25vw - 7px)");
      this.renderer.setStyle(this.element.nativeElement, "height", "10vw");

      this.renderer.setStyle(this.element.nativeElement, "left", "75vw");
      this.renderer.setStyle(this.element.nativeElement, "top", "60px");

      this.renderer.removeClass(this.element.nativeElement, "races-streaming");
      this.renderer.addClass(this.element.nativeElement, "regular-streaming");

      setTimeout(() => {
        this.globalVars.videoPlayerOnTicket = true;
      }, 500);
    });
  }

  handlePan(ev, end) {
    let newLeft = ev.center.x - this.offsetX;
    let newTop = ev.center.y - this.offsetY;

    const bbox = this.element.nativeElement.getBoundingClientRect();
    const margin = 20;

    this.domCtrl.write(() => {
      if (end) {
        this.windowMove = true;
        this.globalVars.videoPlayerWindow = true;
        const midWidth = window.innerWidth / 2;
        const midHeight = window.innerHeight / 2;

        this.renderer.setStyle(this.element.nativeElement, "transition", ".5s ease-out");

        newLeft = ev.center.x < midWidth ? margin : window.innerWidth - bbox.width - margin;
        newTop = ev.center.y < midHeight ? margin : window.innerHeight - bbox.height - margin;

        this.eventListClass = ev.center.y < midHeight ? "list-down" : "list-up";
      }

      this.renderer.setStyle(this.element.nativeElement, "left", newLeft + "px");
      this.renderer.setStyle(this.element.nativeElement, "top", newTop + "px");
    });
  }

  initStream() {
    const config = {
      id: "video-player",
      allowFullScreen: this.isMobile,
      mute: false,
      skin: {
        colors: {
          "icon-button-hover": "#79bf00",
          "label-active": "#79bf00",
          "toggle-button-active": "#79bf00",
          "toggle-button-text-active": "#000000",
          slider: "#79bf00",
        },
      },
    };

    this.avvpl = new avvpl.setupPlayer(config);

    if (this.isMobile) {
      this.avvpl.fullscreenChange.subscribe(() => {
        this.handleZoomMobile();
      });
    }

    if (this.isDesktop) {
      this.avvpl.pause.subscribe(() => {
        const el = document.querySelector(".sravvpl_activePlus") as HTMLElement;
        if (this.windowMove) {
          this.windowMove = false;
          el.click();
          return;
        }
        el.click();
        if (this.globalVars.videoPlayerWindow) {
          this.changeEvent();
        }
      });
    }
  }
}
