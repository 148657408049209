// eslint-disable-next-line @typescript-eslint/no-empty-function

import { Injectable, inject } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { Platform } from "@ionic/angular";
@Injectable({ providedIn: "root" })
export class DeviceService {
  platform = inject(Platform);
  toMatchMobile = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  isDesktop(): boolean {
    return !this.isMobile();
  }

  isMobile(): boolean {
    return this.toMatchMobile.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
  }

  getDevicePlatform = (): string => (Capacitor.getPlatform() === "web" ? "Desktop" : "Mobile");
}
