import { TransactionActionResult } from "@models/C_Transactions";
import { ErrorService } from "@providers/ErrorService";
import { UserService } from "@providers/UserService";
import { Observable, of } from "rxjs";
import { PaymentMethodBehaviour, PaymentMethodBehaviourResult, SkipAmountResult } from "./PaymentMethodBase";

export class PaypalPaymentMethodBehaviourResult extends PaymentMethodBehaviourResult {
  errorOnFindingError: boolean;
  constructor() {
    super();
  }
}

export class PaypalPaymentMethodBehaviour extends PaymentMethodBehaviour {
  name: string;
  image = "payPal";
  track = "AccessToPayPal";
  isBank = false;
  minDepositAmount = 10;
  maxDepositAmount = 100000;
  amountButtons: number[] = [10, 20, 40, 50];
  buttonEnabled = false;
  amount = 15;

  showErrorCallback: (paymentMethodBehaviourResult: PaypalPaymentMethodBehaviourResult) => any;
  executeOkCallback: (transaction: TransactionActionResult) => any;
  constructor(
    userService: UserService,
    showErrorCallback: (paymentMethodBehaviourResult: PaypalPaymentMethodBehaviourResult) => any,
    executeOkCallback: (transaction: TransactionActionResult) => any,
    errorService: ErrorService,
  ) {
    super(false, userService, errorService);
    this.showErrorCallback = showErrorCallback;
    this.executeOkCallback = executeOkCallback;
  }
  skipAmountInput(): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  paymentMetodExecution(): Observable<PaypalPaymentMethodBehaviourResult> {
    throw new Error("Method not implemented.");
  }

  onCreatePayment(data: any): any {
    if (data && data.success) {
      return data.paypalId;
    } else {
      const result = new PaypalPaymentMethodBehaviourResult();
      result.sendToDocumentVerificationPage = data.errCode == 174 || data.errCode == 2212;
      result.errorcode = data.errCode;
      result.erroMessage = this.errorService.searchErrorCodeWithParams(
        "error" + data.errCode,
        data.errorParameters,
      );
      this.showErrorCallback(result);
      result.sendToProfesion = data.errCode == 2412;
    }
  }

  onExecutePayment(transactionResult: TransactionActionResult): any {
    if (transactionResult && transactionResult.success) {
      this.executeOkCallback(transactionResult);
    } else {
      this.errorService
        .searchErrorCodeWithParams(transactionResult.errCode.toString(), transactionResult.errorParameters)
        .subscribe({
          next: (_txt) => {
            const result = new PaypalPaymentMethodBehaviourResult();
            result.sendToDocumentVerificationPage =
              transactionResult.errCode == 174 || transactionResult.errCode == 2212;
            result.errorcode = transactionResult.errCode;
            result.erroMessage =
              transactionResult.errCode == 2413
                ? of(transactionResult.errMessage)
                : this.errorService.searchErrorCodeWithParams(
                    "error" + transactionResult.errCode,
                    transactionResult.errorParameters,
                  );
            this.showErrorCallback(result);
          },
          error: (_error) => {
            const result = new PaypalPaymentMethodBehaviourResult();
            result.errorOnFindingError = true;
            this.showErrorCallback(result);
          },
        });
    }
  }
}
