import { Injectable, inject } from "@angular/core";
import { BaseServiceExt } from "@providers/BaseServiceExt";
import { DeviceService } from "@services/device.service";
import * as models from "@sports-models/index";
import { orderBy } from "@utils/utils.functions";
import { Observable, map } from "rxjs";
import { SportsConstants } from "../utils/sports.constants";
import { SportsCommonService } from "./sports.common.service";

@Injectable({ providedIn: "root" })
export class SportLiveService extends BaseServiceExt {
  deviceService = inject(DeviceService);
  SportsCommonService = inject(SportsCommonService);

  isDesktop: boolean;
  isMobile: boolean;

  EnVivoSportsOrder: string[] = ["Fútbol", "Tenis", "Baloncesto"];

  constructor() {
    super();
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
  }

  getLiveEventsBySportHandleUrl(sportHandler?: string): Observable<models.C_SportsLive[]> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${SportsConstants.getLiveEventsBySportHandleUrl}`;
    const headers = { language: this.globalVars.Idioma };
    const params = {
      ...(sportHandler && { sportHandle: sportHandler }),
      gametypes: this.globalVars.gameTypes.serialize(this.globalVars.gameTypes.getSport(sportHandler)),
    };

    return this.myGet(url, true, { headers, params }).pipe(
      map((data) => {
        const sports: any[] = data.map((sport: models.C_SportsLive) => {
          const s = models.C_SportsLive.parse(sport);
          s.Events = orderBy(s.Events, "").map((event) => {
            const ev = models.C_EventLive.parse(event);
            ev.hasSpecialGame(this.globalVars.gameTypes.apuestas_especiales);
            const eventGameTypes = this.SportsCommonService.getEventGameTypes(ev);
            ev.setGamesByPlatform(eventGameTypes, this.isMobile);
            return ev;
          });

          return s;
        });

        const sortCriteria = this.EnVivoSportsOrder;
        if (!sortCriteria) return sports;

        const sportsAux: any[] = [];
        sortCriteria.forEach((key) => {
          const found: any = sports.find((a) => a.Name === key);
          if (found !== undefined) {
            sportsAux.push(found);
          }
        });

        const rest = sports.filter((item) => sportsAux.indexOf(item) === -1);
        sportsAux.push(...rest);
        return sportsAux;
      }),
    );
  }
}
