<ng-container *ngIf="totalBonus">
  <ng-container
    *ngIf="totalBonus.template === 'InOne' || totalBonus.template === 'OneclickAll'; then InOne; else Separate"
  ></ng-container>
  <ng-template #Separate>
    <ng-container *ngIf="isDesktop; then deskTemplate; else mobileTemplate"></ng-container>
    <ng-template #deskTemplate>
      <ion-header>
        <div class="wb-header">
          <h4 class="m-0 color-light text-center">{{'welcomebonustitle' | translate | uppercase}}</h4>
          <ion-icon name="close-circle-outline" (click)="closeModal()"></ion-icon>
        </div>
      </ion-header>
      <ion-content>
        <ion-row class="allbonus" [ngClass]="{'panamaContainerPc': globalVars.licenseType === 22 }">
          <ng-container *ngFor="let bono of totalBonus.bonos">
            <ion-col class="borderr" *ngIf="canShowPromo(bono.bono.promoProduct)">
              <bonus (bonoAcept)="listenEvents($event)" [bono]="bono" [isDesktop]="isDesktop"></bonus>
            </ion-col>
          </ng-container>
        </ion-row>
      </ion-content>
    </ng-template>
    <ng-template #mobileTemplate>
      <ion-content>
        <my-tabs (closeModal)="closeModal()" [addIcon]="true">
          <ng-container *ngFor="let bono of totalBonus.bonos; let i = index">
            <my-tab
              [tabTitle]="bono.tabTitle | translate"
              *ngIf="canShowPromo(bono.bono.promoProduct)"
              [active]="currentbono === i"
            >
              <bonus (bonoAcept)="listenEvents($event)" [bono]="bono" [isDesktop]="isDesktop"></bonus>
            </my-tab>
          </ng-container>
        </my-tabs>
      </ion-content>
    </ng-template>
  </ng-template>
  <ng-template #InOne>
    <ion-header>
      <div class="wb-header">
        <h4 class="m-0 color-light text-center">{{'wbtitle' | translate | uppercase}}</h4>
        <ion-icon name="close-outline" (click)="closeModal()"></ion-icon>
      </div>
    </ion-header>
    <ion-content>
      <ion-grid class="padding0">
        <ion-row class="bonusinone">
          <ion-col class="padding0" size="12">
            <div class="m-0">
              <img [src]="this.totalBonus.bonos[0].img" alt="Bonus All in One IMG" />
            </div>
          </ion-col>
          <ion-col size="12">
            <article>
              <h3 [innerHTML]="this.totalBonus.bonos[0].title | translate | uppercase"></h3>
            </article>
          </ion-col>
          <ion-col size="12" *ngFor="let item of textAllInOneBonus">
            <article>
              <p class="fontweight">{{ item.title | translate}}</p>
              <p [innerHTML]="item.subtitle| translate"></p>
            </article>
          </ion-col>
          <ion-col size="12" *ngIf="totalBonus.template != 'OneclickAll'">
            <article>
              <h4 class="fontweight">{{'wbchoose' | translate | uppercase}}</h4>
            </article>
          </ion-col>
          <ion-col class="txtcenter">
            <ng-container *ngIf="totalBonus.template != 'OneclickAll'">
              <ion-col size="5" *ngFor="let bono of this.totalBonus.bonos; let i = index">
                <ion-button [class]="bono.button.cssClass" (click)="presentAlertConfirmBonus(i)"
                  >{{bono.button.txt | translate | uppercase}}</ion-button
                >
              </ion-col>
            </ng-container>
            <ng-container *ngIf="totalBonus.template === 'OneclickAll'">
              <ion-col size="5">
                <ion-button class="btntwo" (click)="acceptOptinWBAll()"
                  >{{totalBonus.bonos[0].button.txt | translate | uppercase}}</ion-button
                >
              </ion-col>
            </ng-container>
          </ion-col>
          <ion-col size="12" class="txtcenter">
            <p>
              <a external-link [href]="this.totalBonus.bonos[0].tyc" target="_blank"
                >{{'InviteFriendtxt06' | translate}}</a
              >
            </p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ng-template>
</ng-container>
