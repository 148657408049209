import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { GlobalVarsModel } from "../models/ModelGlobalsVar";
import { NewBaseService } from "../providers/newBase.service";
import { DeviceService } from "../services/device.service";

export const RegisterGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  try {
    let globalVars!: GlobalVarsModel;
    const newBaseService = inject(NewBaseService);
    const deviceService = inject(DeviceService);
    const router = inject(Router);
    newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      globalVars = data;
    });
    if (globalVars.user.logged) {
      router.navigate(["/"]);
      return false;
    }

    if (!globalVars.isCookiesAccept && globalVars.FEATURES.MSO_alertCookiesNoBlock) {
      router.navigate(["/"]);
      globalVars.rootScope.openRegistro();
      return false;
    }

    if (deviceService.isMobile() || route.data["skipModal"]) {
      return true;
    } else {
      if (!globalVars.rootScope.modalRegOpen) {
        router.navigate(["/"]);
        globalVars.rootScope.openRegistro();
      }
      return false;
    }
  } catch (e) {
    console.error(e);
    return false;
  }
};
