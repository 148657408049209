export class C_HighLight {
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  NodeLevel: number;
  SportsHandle: string;
  CountryCode: string;
  EventNodeTypeId: number;
  StartDate: string;
  EndDate: string;

  constructor(
    nombre: string,
    nId: string,
    parentNodeId: string,
    nodeLevel: number,
    sportshandle: string,
    countryCode: string,
    eventNodeTypeId: number,
    startDate: string,
    endDate: string,
  ) {
    this.Name = nombre;
    this.NodeId = nId;
    this.ParentNodeId = parentNodeId;
    this.NodeLevel = nodeLevel;
    this.SportsHandle = sportshandle; //esta propiedad se ha añadido porque por un error desde el back, a veces nos pasan SportHandle y otrás veces SportsHandle.Concretamente en getHighLighSport nos devuelven SportsHandle,en el resto devuelven SportHandle
    this.CountryCode = countryCode;
    this.EventNodeTypeId = eventNodeTypeId;
    this.StartDate = startDate;
    this.EndDate = endDate;
  }
}

//export = C_HighLight
