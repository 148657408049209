import { C_ItemMenu } from "./item-menu.model";

export class C_ItemsMenu {
  title: string;
  items: Array<C_ItemMenu>;
  link: any;
  section: string;
  icon: string;
  isPrivate: boolean;
  badgeCount: number;
  isAlert: boolean;
  params?: any;
  collapsed?: boolean;

  constructor(
    title: string,
    icon: string,
    link: any,
    section: string,
    items: Array<C_ItemMenu>,
    isprivate: boolean = false,
    badgeCount: number = 0,
    _isAlert: boolean = false,
    params?,
    collapsed?: boolean,
  ) {
    this.items = items;
    this.title = title;
    this.icon = icon;
    this.link = link;
    this.section = section;
    this.isPrivate = isprivate;
    this.badgeCount = badgeCount;
    this.isAlert = _isAlert;
    this.params = params;
    this.collapsed = true;
  }
}

//export = C_ItemsMenu
