import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CdrInsightsModule } from "./cdr-insights/cdr-insights.module";

@NgModule({
  declarations: [],
  imports: [CommonModule, CdrInsightsModule],
  exports: [CdrInsightsModule],
})
export class CdrCoreModule {}
