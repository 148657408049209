  <swiper-container #swiperCSV init="false" class="swiper-container">
    <swiper-slide
      *ngFor="let game of games"
      class="slots-slider"
    >
      <casino-banner-card [game]="game" (turnPlay)="playGame($event)"></casino-banner-card>
    </swiper-slide>
  </swiper-container>
  <div #bullets class="swiper-pagination-csv"></div>
  <ng-container *ngIf="games && games?.length > 1 && isDesktop">
    <button #btnprev class="swiper-button-prev-csv slide-control prev" ion-button>
      <ion-icon name="chevron-back-outline"></ion-icon>
    </button>
    <button #btnext class="swiper-button-next-csv slide-control next" ion-button>
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </button>
  </ng-container>
