import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { PAGES_PATHS } from "@shared-constants/routes";
import { GlobalVarsModel } from "../models/ModelGlobalsVar";
import { NewBaseService } from "../providers/newBase.service";
import { DeviceService } from "../services/device.service";

export const ModalGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  try {
    const deviceService = inject(DeviceService);
    const openAsPage: "PC" | "MOBILE" | "BOTH" | "NONE" = route.data["openAsPage"] || "NONE";

    if (openAsPage === "BOTH") {
      return true;
    }
    if (openAsPage === "PC") {
      if (!deviceService.isMobile()) return true;
    }
    if (openAsPage === "MOBILE") {
      if (deviceService.isMobile()) return true;
    }
    const router = inject(Router);

    let globalVars!: GlobalVarsModel;
    const newBaseService = inject(NewBaseService);
    const modalController = inject(ModalController);

    newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      globalVars = data;
    });

    const backdropDismiss: boolean = route.data["backdropDismiss"] || true;
    const atrr: any = route.data["atrr"] || {};
    const pcCssClass: string = route.data["pcCssClass"] || "modalh800";
    const mobileCssClass: string = route.data["mobileCssClass"] || "modalh800";

    const component = globalVars.rootScope.getComponentForRoute(state.url.split("?")[0]);

    const modal = await modalController.create({
      component: component,
      componentProps: { ...atrr, ...route.queryParams },
      cssClass: deviceService.isMobile() ? mobileCssClass : pcCssClass,
      backdropDismiss: backdropDismiss,
    });
    modal.present();

    const stayRoot: boolean = route.data["stayRoot"] || true;
    router.navigateByUrl(stayRoot ? PAGES_PATHS.HomePage : router.url);

    return false;
  } catch (e) {
    console.error(e);
    return false;
  }
};
