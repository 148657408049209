import { CommonModule } from "@angular/common";
import { AfterViewChecked, Component, OnDestroy, OnInit, Renderer2, ViewChild, inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-prototype-builtins */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AlertController, IonContent, IonicModule, NavController } from "@ionic/angular";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { EventsService } from "@providers/EventsService";
import { SlotsService } from "@providers/SlotsService";
import { TrackingService } from "@providers/TrackingService";
import { NewBaseService } from "@providers/newBase.service";
import { CodereFooterComponent } from "@shared-mso-components/footer/codere-footer/codere-footer";
import { UserSectionTitle } from "@shared-mso-components/user-section-title/user-section-title";
import { Utils } from "@utils/Utils";
import { Subscription, map } from "rxjs";

import { ActivatedRoute, Router } from "@angular/router";
import { CookieNames } from "@models/CookieNames";
import {
  DecouplingUserServiceMSO,
  ErrorCodes,
  ErrorDecoupling,
} from "@providers/DecouplingUserServiceMSO.service";
import { UserServiceMSO } from "@providers/UserServiceMSO";
import { DeviceService } from "@services/device.service";
import { MSO_PATHS } from "@shared-constants/routes";
import { FullSeComponent } from "../../components/selfexclusion/full-se/full-se";
import { PagesSeComponent } from "../../components/selfexclusion/pages-se/pages-se";
import { StepRevocationSeComponent } from "../../components/selfexclusion/step-revocation-se/step-revocation-se";

interface Card {
  title: string;
  img: string;
  txt: string;
  txt2: string;
  href?: string;
  type?: "external" | "event";
  canclick: boolean;
  txtmobile: boolean;
  arrow: boolean;
  btnmoreinf: string;
}
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "selfexclusion-page",
  templateUrl: "./selfExclusion.html",
  styleUrls: ["./selfExclusion.scss"],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    UserSectionTitle,
    CodereFooterComponent,
    TranslateModule,
    PagesSeComponent,
    FullSeComponent,
    StepRevocationSeComponent,
  ],
})
export class SelfExclusionPage implements OnDestroy, AfterViewChecked, OnInit {
  cards: Array<Card> = [];
  @ViewChild(IonContent) content: IonContent;
  isRevocationPage = false;
  myForm: FormGroup;
  selfEx: any;
  dayMonth: Array<string>;
  months: Array<{ label: string; data: string }>;
  years: Array<number>;
  validDate: boolean;
  argButtons: { sports: boolean; slots: boolean; casino: boolean; all: boolean };
  argTimes: Array<{ name: string; time: number; selected: boolean }>;
  argErrors: any;
  alertConfirm;
  argSelectProd: string;
  argSelelctTimeIndex: number;

  isDesktop = false;

  startInStep = 1;
  newSE = false;
  stepEvent$: Subscription;
  paramsSubscription$: Subscription;

  public trackEvent: TrackEvent;
  public icon = false;

  stepARG = 0;

  globalVars!: GlobalVarsModel;

  events = inject(EventsService);
  nav = inject(NavController);
  fb = inject(FormBuilder);
  utils = inject(Utils);
  userServiceMSO = inject(UserServiceMSO);
  translate = inject(TranslateService);
  trackingService = inject(TrackingService);
  routerActive = inject(ActivatedRoute);
  alertController = inject(AlertController);
  newBaseService = inject(NewBaseService);
  slotsService = inject(SlotsService);
  router = inject(Router);
  deviceService = inject(DeviceService);
  render2 = inject(Renderer2);
  decouplingService = inject(DecouplingUserServiceMSO);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.isDesktop = this.deviceService.isDesktop();

    this.selfEx = {
      day: "",
      month: "",
      year: "",
    };
    this.myForm = this.fb.group({
      day: ["", Validators.required],
      month: ["", Validators.required],
      year: ["", Validators.required],
    });

    this.dayMonth = [];
    for (let i = 1; i <= 31; i++) {
      this.dayMonth.push(i < 10 ? "0" + i.toString() : i.toString());
    }
    const arrMeses = [
      this.translate.instant("rMEn"),
      this.translate.instant("rMFe"),
      this.translate.instant("rMMa"),
      this.translate.instant("rMAv"),
      this.translate.instant("rMMy"),
      this.translate.instant("rMJn"),
      this.translate.instant("rMJl"),
      this.translate.instant("rMAg"),
      this.translate.instant("rMSe"),
      this.translate.instant("rMOc"),
      this.translate.instant("rMNo"),
      this.translate.instant("rMDi"),
    ];

    this.months = [];

    for (let i = 0; i < 12; i++) {
      this.months.push({ label: arrMeses[i], data: i < 9 ? "0" + (i + 1).toString() : (i + 1).toString() });
    }

    this.years = [];
    const nowYearDD: number = new Date().getFullYear();
    const maxYearDD = nowYearDD + 100;
    for (let i = nowYearDD; i < maxYearDD; i++) {
      this.years.push(i);
    }

    this.myForm.controls["day"].valueChanges.subscribe((value: string) => {
      this.checkValidDate();
    });
    this.myForm.controls["month"].valueChanges.subscribe((value: string) => {
      this.checkValidDate();
    });
    this.myForm.controls["year"].valueChanges.subscribe((value: string) => {
      this.checkValidDate();
    });
    this.stepEvent$ = this.events.subscribe("changeStep", (_) => {
      this.content.scrollToTop();
    });
    this.argButtons = {
      sports: true,
      slots: true,
      casino: true,
      all: true,
    };

    this.argTimes = [
      { name: "timeout24h", time: 24 * 60, selected: false },
      { name: "timeout48h", time: 48 * 60, selected: false },
      { name: "timeout7d", time: 7 * 24 * 60, selected: false },
      { name: "timeout30d", time: 30 * 24 * 60, selected: false },
      { name: "selfexclusionsArpersonal", time: null, selected: false },
    ];

    this.argErrors = null;
    this.createAlertConfirm();

    switch (this.globalVars.licenseType) {
      case 0: //Spain
        this.newSE = true;
        this.startInStep = 1;
        break;
      case 22: //Panama
        this.newSE = true;
        this.startInStep = 1;
        this.icon = true;
        break;
      case 21: //Colombia
        this.newSE = true;
        this.startInStep = 1;
        console.info(21);
        this.icon = true;
        break;
      default:
        console.info(this.globalVars.licenseType);
        this.startInStep = 1;
        this.loadCard();
        if (this.globalVars.licenseType === LicenseTypes.ArgentinaMendoza) {
          this.changeStepARG(1);
        }
        break;
    }
  }
  ngOnInit(): void {
    this.paramsSubscription$ = this.routerActive.queryParams.subscribe((params) => {
      if (params && params["revocation"]) {
        this.isRevocationPage = true;
      }
    });
  }

  /**
   * Crea un alert para confirmar o no el proceso de autoexclusion
   */
  async createAlertConfirm() {
    this.alertConfirm = await this.alertController.create({
      header: this.translate.instant("CodereCardconfir") + " " + this.translate.instant("uPJRAut"),
      // ,'Confirmar' 'autoexclusión'
      message: this.translate.instant("selfexclusionquestion"),
      // '¿Esta seguro de que desea activar la autoexclusión?'
      buttons: [
        {
          text: "NO",
          handler: () => this.createAlertConfirm(),
          role: "cancel",
        },
        {
          text: "Sí",
          handler: () => this.finalExclusionArgentina(),
        },
      ],
    });
  }

  ionViewWillEnter() {
    this.router.navigate([MSO_PATHS.BASE + "/" + MSO_PATHS.SelfExclusionPage]);
    this.trackingService.trackEvent(["AccessToSelfExclusion", "", "", "", "event"]);
    this.events.publish("footer:refresh", this.content);
  }

  ngAfterViewChecked() {
    const bold = document.querySelectorAll("b");
    const strongs = document.querySelectorAll("strong");
    const titletxt = document.querySelectorAll(".titletxt");
    bold.forEach((b) => {
      this.render2.setStyle(b, "color", "#222");
    });
    strongs.forEach((strong) => {
      this.render2.setStyle(strong, "color", "#222");
    });
    titletxt.forEach((t) => {
      t.querySelectorAll("li").forEach((li) => {
        this.render2.setStyle(li, "padding-top", "8px");
      });
    });
  }

  onSelfExclusion(event: { selfExclusionDate: string; canRevocate: boolean }) {
    const { canRevocate, selfExclusionDate } = event;
    const request = this.globalVars.FEATURES.MSO_NewDecouplingSelfExclusion
      ? this.decouplingService
          .selfExclusionDecoupling({
            exclusionDateEnd: selfExclusionDate,
            renewAutoExclusion: this.globalVars.renewSelfExclusion,
            revokeAutoExclusion: false,
          })
          .pipe(map(() => ({ success: true })))
      : this.userServiceMSO.createAutoExclusion(selfExclusionDate, this.globalVars.renewSelfExclusion);
    request.subscribe({
      next: (data) => {
        this.onAutoexclusion(data, selfExclusionDate, canRevocate);
      },
      error: () => {
        this.onAutoexclusion({ success: false }, selfExclusionDate, canRevocate);
      },
    });
  }

  ngOnDestroy(): void {
    this.stepEvent$?.unsubscribe();
    this.paramsSubscription$?.unsubscribe();
  }
  /**
   * Metodo responsable de procesar el resultado de los servicios que
   * hacen las llamadas a los endPoint de autoexclusión en distintos paises.
   * @param data: object con los datos devueltos por el endpoint
   * @param selfExclusionDate : string con la fecha yy-mm-dd
   */
  onAutoexclusion(data, selfExclusionDate: string, canRevocate = false) {
    if (data == true || data.success || data.flag === "true") {
      if (this.globalVars.renewSelfExclusion)
        this.trackingService.trackEvent(["RenewSelfExclusionOk", selfExclusionDate, "", "", "event"]);
      else this.trackingService.trackEvent(["CreateSelfExclusionOk", selfExclusionDate, "", "", "event"]);
      this.globalVars.renewSelfExclusion = false;
      this.utils.alert(true, "", this.translate.instant("exclusiontxt01"), "OK", () => {
        if (this.globalVars.licenseType == LicenseTypes.Nacional) {
          this.events.publish("user:logout");
          this.events.publish("navbarpc:refresh");
        }
        this.router.navigate(["/"]);
      });
      this.changeUserCookie(canRevocate);
    } else {
      if (this.globalVars.renewSelfExclusion)
        this.trackingService.trackEvent(["RenewSelfExclusionKo", selfExclusionDate, "", "", "event"]);
      else this.trackingService.trackEvent(["CreateSelfExclusionKo", selfExclusionDate, "", "", "event"]);

      this.globalVars.renewSelfExclusion = false;
      this.utils.alert(true, "", this.translate.instant("exclusiontxt02"), "OK");
      this.router.navigate(["/"]);
    }
  }

  public changeUserCookie(canRevocate = false) {
    const userInfo = JSON.parse(this.globalVars.rootScope.cookiesService.getCookie(CookieNames.UserInfo));
    if (userInfo) {
      const newUserInfo = {
        ...userInfo,
        selfExcluded: true,
      };
      this.globalVars.rootScope.cookiesService.setCookie(
        CookieNames.UserInfo,
        JSON.stringify(newUserInfo),
        null,
        true,
      );
    }
    if (this.globalVars.licenseType == LicenseTypes.Colombia) {
      this.globalVars.extraUserData.isRevocationPage = canRevocate;
      this.globalVars.rootScope.navService.initRightMenu(true);
    }
  }

  checkValidDate() {
    //checks if the date is valid

    if (!!this.selfEx.day && !!this.selfEx.month && !!this.selfEx.year) {
      //check the date
      const miDate: Date = new Date(
        parseInt(this.selfEx.year),
        parseInt(this.selfEx.month) - 1,
        parseInt(this.selfEx.day),
        0,
        0,
        0,
        0,
      );

      if (
        miDate.getDate() == parseInt(this.selfEx.day) &&
        miDate.getMonth() == parseInt(this.selfEx.month) - 1 &&
        miDate.getFullYear() == parseInt(this.selfEx.year)
      ) {
        this.validDate = miDate > new Date();
      } else {
        this.validDate = false;
      }
    } else {
      this.validDate = false;
    }
  }

  /**
   * Manejador del evento click de los botones de selección de producto
   * @param item : producto seleccionado
   * @param boolean:
   */
  checkArgButtons(item, mobile = false) {
    if (item === "all") {
      const newState = mobile ? this.argButtons[item] : !this.argButtons[item];
      for (const key in this.argButtons) {
        if (this.argButtons.hasOwnProperty(key)) {
          this.argButtons[key] = newState;
        }
      }
    } else {
      if (!mobile) {
        // En Web son botones sin ngModel => el metodo recoge el cambio de valor
        this.argButtons[item] = !this.argButtons[item];
      }
      if (this.argButtons.casino && this.argButtons.slots && this.argButtons.sports) {
        this.argButtons.all = true;
      } else {
        this.argButtons.all = false;
      }
    }
    if (this.validateArgButtons() && this.argErrors && this.argErrors.product) {
      delete this.argErrors.product;
    }
  }

  /**
   * Valida que al menos uno de los botones de producto
   * este seleccionado y si es así devuelve un true
   */
  validateArgButtons() {
    for (const key in this.argButtons) {
      if (this.argButtons.hasOwnProperty(key)) {
        if (this.argButtons[key]) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Manejador del evento click de los botones de selección del
   * @param select : objeto con el estado del tiempo seleccionado
   */
  checkArgTimes(select) {
    this.argTimes.forEach((item) => (item.selected = item.name === select.name));
    if (
      this.validateArgTimes() ||
      (select.name === "selfexclusionsArpersonal" && this.argErrors && this.argErrors.time)
    ) {
      delete this.argErrors.time;
    }
  }

  /**
   * Manejador de los eventos blur y focus en el input
   * para la opcion de periodo de tiempo personalizado
   * @param ev : Event
   */
  managePersonalizada(ev) {
    if (ev.type === "input") {
      if (this.argTimes[4].time > 42) {
        this.argTimes[4].time = 42;
      }
    }
    if (ev.type === "focus") {
      delete this.argErrors.time;
    } else {
      // blur
      if (this.validateArgTimes()) {
        delete this.argErrors.time;
      } else {
        this.argErrors.time = true;
      }
    }
  }

  /**
   * Valida si alguno de los tiempos esta seleccionado
   * y si es así devuelve un true
   */
  validateArgTimes() {
    if (this.argTimes[4].selected) {
      if (this.argTimes[4].time > 0 && this.argTimes[4].time < 42) {
        return true;
      } else {
        return false;
      }
    }
    return this.argTimes.some((item) => item.selected);
  }

  /**
   * Manejador del evento click en el alert que permite confirmar la autoexclusión
   * Comprueba de nuevo que estan seleccionador un producto y un tiempo
   * Y hace una llamada al servicio createAutoExclusionAR,
   * responsable de la llamada al EndPoint
   */
  finalExclusionArgentina() {
    if (this.argTimes[4].time) {
      this.argTimes[4].time *= 24 * 60;
    }
    let valid = false;
    delete this.argButtons.all;
    for (const key in this.argButtons) {
      if (this.argButtons.hasOwnProperty(key)) {
        if (this.argButtons[key]) {
          valid = this.argButtons[key];
          break;
        }
      }
    }
    if (!valid) {
      return;
    }
    let time;
    for (const key in this.argTimes) {
      if (this.argTimes.hasOwnProperty(key)) {
        if (this.argTimes[key].selected) {
          time = this.argTimes[key].time;
          break;
        }
      }
    }
    if (!time) {
      return;
    }
    this.userServiceMSO.createAutoExclusionAR(time, this.argButtons).subscribe((resp) => {
      console.info(resp);
      this.selfEx = new Date();
      const hoyString = this.selfEx.year.toString() + "-" + this.selfEx.month + "-" + this.selfEx.day;
      this.onAutoexclusion(resp, hoyString);
    });
  }

  /**
   * Manejador del evento change de los radio butons que permiten
   * seleccionar el periodo de tiempo fuera en el movil
   *
   */
  changeTime() {
    if (!this.argSelelctTimeIndex) {
      return;
    }
    this.argTimes[this.argSelelctTimeIndex].selected = true;
    this.checkArgTimes(this.argTimes[this.argSelelctTimeIndex]);
  }
  /**
   * Manejador del evento click del btn confirmar autoexclusión
   * en ARGENTINA
   */
  gotoExclusionClick() {
    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      const request = this.globalVars.FEATURES.MSO_NewDecouplingSelfExclusion
        ? this.decouplingService.selfExclusionDecoupling().pipe(
            map((response) => ({
              info: { url: response.url },
              success: true,
            })),
          )
        : this.userServiceMSO.setSelfExcluded();
      request.subscribe({
        next: (data) => {
          if (data.success) {
            // Se redirige a la web de Lotba
            location.href = data.info.url;
          } else {
            this.alertSelfexclusionKO();
          }
        },
        error: (error) => {
          if (this.globalVars.FEATURES.MSO_NewDecouplingSelfExclusion) {
            const { type } = error as ErrorDecoupling;
            if (type === ErrorCodes.LOTBA) {
              this.alertSelfexclusionKO();
            }
          }
        },
      });
    } else if (this.globalVars.licenseType === LicenseTypes.ArgentinaMendoza) {
      this.utils.alert(
        false,
        this.translate.instant("tInf"),
        this.translate.instant("selfexclusionrequest") +
          "<br><br>" +
          this.translate.instant("selfexclusionpbatxturl"),
        "OK",
        () => {
          window.open(this.globalVars.FEATURES.MSO_SelfexclusionURLMZA, "_blank");
        },
      );
    } else {
      this.utils.alert(
        false,
        this.translate.instant("tInf"),
        this.translate.instant("selfexclusionpbatxturl"),
        "OK",
        () => {
          window.open(this.globalVars.FEATURES.msoSelfexclusionURLPBA, "_blank");
        },
      );
    }
  }

  async alertSelfexclusionKO() {
    const msg = `${this.translate.instant("revaouttxt01")} <br><br> ${this.translate.instant("revaouttxt02")}`;
    const icon = '<span class="closeIcon"><i class="color-dark codere-icon icon-close" item-right=""></i></span';
    const finalMSG = `${msg}${icon}`;
    const alertKO = await this.utils.alert(
      false,
      "",
      finalMSG,
      this.translate.instant("revaoutbtn"),
      () => {
        window.open(this.globalVars.FEATURES.MSO_LotbaRevaSystemFailureUrl, "_blank");
      },
      "alertSelfexclusionKO",
    );
    const iconRef = alertKO.querySelector(".closeIcon");
    iconRef.addEventListener("click", () => {
      alertKO.dismiss();
    });
  }
  changeStepARG(step: number) {
    this.stepARG = step;
    if (this.content) {
      this.content.scrollToTop();
    }
  }

  loadCard() {
    this.cards = [
      {
        arrow: true,
        btnmoreinf: this.translate.instant("fbpenmasinfo"),
        canclick: true,
        img: "assets/global/img/icon-info-contact.svg",
        title: this.translate.instant("quickexit"),
        txt: this.translate.instant("quickexittxt"),
        txt2: "",
        txtmobile: this.translate.instant("quickexittxt"),
        type: "event",
        href: MSO_PATHS.QuickExit,
      },
      {
        arrow: true,
        btnmoreinf: this.translate.instant("fbpenmasinfo"),
        canclick: true,
        img: "assets/global/img/icon-info-contact.svg",
        title: this.translate.instant("timeout"),
        txt: this.translate.instant("timeouttxt"),
        txt2: this.translate.instant("timeouttxt2"),
        txtmobile: this.translate.instant("timeouttxt"),
        type: "event",
        href: MSO_PATHS.TimeOutPage,
      },
      {
        arrow: true,
        btnmoreinf: this.translate.instant("fbpenmasinfo"),
        canclick: true,
        img: "assets/global/img/icon-info-contact.svg",
        title: this.translate.instant("selfexclusiontwoyears"),
        txt:
          this.globalVars.licenseType === LicenseTypes.ArgentinaCaba
            ? this.translate.instant("selfexclusiontwoyearstxt01")
            : this.translate.instant("selfexclusiontwoyearstxt01pba"),
        txt2: `<b>${this.translate.instant("selfexclusiontwoyearstxt02")}</b>`,
        txtmobile:
          this.globalVars.licenseType === LicenseTypes.ArgentinaCaba
            ? this.translate.instant("selfexclusiontwoyearstxt01")
            : this.translate.instant("selfexclusiontwoyearstxt01pba"),
        type: "event",
        href: "step1",
      },
    ];
  }
  isImgCustom(img: string) {
    return img.includes("assets");
  }
  onclickCard(c: Card) {
    if (!c.canclick) {
      return;
    }
    if (c.type) {
      if (c.type == "event") {
        if (c.href === "step1") {
          this.changeStepARG(1);
        } else {
          this.router.navigateByUrl(`${MSO_PATHS.BASE}/${c.href}`);
        }
      }
    }
  }
}
