import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { BaseFilter } from "../base-filter/base-filter";

@Component({
  selector: "layout-filter",
  imports: [IonicModule, CommonModule],
  standalone: true,
  providers: [],
  templateUrl: "./layout-filter.component.html",
  styleUrls: ["./layout-filter.component.scss"],
  host: {
    class: "layout-filter",
  },
})
export class LayoutFilterComponent extends BaseFilter {
  selectedRows: number = 0;
  selectedReels: number = 0;
  text: string = "0 filas x 0 columnas";

  constructor() {
    super();
  }

  selectLayout(reels: number, rows: number): void {
    this.selectedRows = rows + 1;
    this.selectedReels = reels + 1;
    this.text = `${this.selectedRows} filas x ${this.selectedReels} columnas`;
    this.filterGroup.filters = [
      {
        id: "layout",
        selected: true,
        layoutValue: {
          reels: this.selectedReels,
          rows: this.selectedRows,
        },
        text: this.text,
      },
    ];
  }

  remove(): void {
    this.selectedRows = 0;
    this.selectedReels = 0;
    this.text = `${this.selectedRows} filas x ${this.selectedReels} columnas`;
    this.filterGroup.filters = null;
  }
}
