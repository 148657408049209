import { Injectable, inject } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { GlobalVarsModel } from "../models/ModelGlobalsVar";
import { NewBaseService } from "../providers/newBase.service";
import { DeviceService } from "../services/device.service";

interface NewChatData {
  id: string;
  src: string;
  data_app_id: string;
  data_lcw_version: string;
  data_org_id: string;
  data_org_url: string;
  unloadAll: boolean;
  V2: boolean;
  data_customization_callback: string;
}

@Injectable({ providedIn: "root" })
export class NewChatService {
  private _isChatReadyToOpen: boolean = false;
  isChatReadyToOpen$ = new BehaviorSubject<boolean>(false);
  private isReadySubs$: Subscription;
  private hiddenBtnChat: boolean = true;
  private btnElement: Element;
  private emailAux: string = "";
  private chatOpen: boolean = false;
  private isMinimizeSDK: boolean = false;
  private isMinimizeIframe: boolean = false;
  private isBtnAdded: boolean = false;
  private isChatMessagesOpen: boolean = false;

  chatReadyHandle: any;
  chatOpenHandle: any;
  chatCloseHandle: any;
  chatMinimizeHandle: any;
  chatQueuedHandle: any;

  globalVars!: GlobalVarsModel;
  isMobile: boolean;

  device = inject(DeviceService);

  get isChatReadyToOpen(): boolean {
    return this._isChatReadyToOpen;
  }

  private setisChatReadyToOpen(ready: boolean) {
    this._isChatReadyToOpen = ready;
    this.isChatReadyToOpen$.next(this.isChatReadyToOpen);
  }

  newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.isMobile = this.device.isMobile();

    this.chatReadyHandle = (openOnLoad: boolean, typeChat: "normal" | "proactive" = "normal") => {
      this.setisChatReadyToOpen(true);
      if (openOnLoad) {
        this.openContactChat(typeChat);
      }
    };
    this.chatOpenHandle = () => {
      if (this.isReadySubs$) {
        this.isReadySubs$.unsubscribe();
      }
      this.isMinimizeSDK = false;
      this.chatOpen = true;
      this.toggleBtnChat(true);
    };
    this.chatCloseHandle = () => {
      this.chatOpen = false;
      this.isMinimizeSDK = false;
      this.isChatMessagesOpen = false;
      this.toggleBtnChat(true);
    };

    this.chatMinimizeHandle = () => {
      this.isMinimizeSDK = true;
      this.chatOpen = false;
      this.toggleBtnChat(!this.isChatMessagesOpen);
    };

    this.chatQueuedHandle = () => {
      this.isChatMessagesOpen = true;
    };

    this.createBtnElement();

    this.createBtnElement();
  }

  openContactChat(typeChat: "normal" | "proactive" = "normal", email: string = "") {
    if (this.chatOpen) {
      return;
    }
    if (this.isMinimizeIframe) {
      this.maximice();
      return;
    }
    this.isReadySubs$ && this.isReadySubs$.unsubscribe();
    this.isReadySubs$ = this.isChatReadyToOpen$.pipe().subscribe((ready) => {
      if (!ready) return;
      if (this.globalVars.user.logged) {
        this.emailAux = email;
        window["Microsoft"].Omnichannel.LiveChatWidget.SDK.setContextProvider(() => ({
          EsCliente: "Si",
          Email: email,
        })); //'Email'
      }
      if (typeChat === "normal") {
        window["Microsoft"].Omnichannel.LiveChatWidget.SDK.startChat();
      } else {
        window["Microsoft"].Omnichannel.LiveChatWidget.SDK.startProactiveChat();
      }
    });
  }
  unloadContactChatNew() {
    const chatData: NewChatData = JSON.parse(this.globalVars.FEATURES.newChatData);

    if (!chatData.unloadAll) {
      if (this.chatOpen) {
        this.minimice();
      }
    }

    if (chatData.unloadAll) {
      if (this.chatOpen || this.isMinimizeIframe || this.isMinimizeSDK) {
        this.closeChat();
      }
      const exist: any = document.querySelectorAll(`[id^=${chatData.id}]`);
      window.removeEventListener("lcw:ready", this.chatReadyHandle);
      window.removeEventListener("lcw:startChat", this.chatOpenHandle);
      window.removeEventListener("lcw:onClose", this.chatCloseHandle);
      window.removeEventListener("lcw:onMinimize", this.chatCloseHandle);
      window.removeEventListener("lcw:chatQueued", this.chatQueuedHandle);
      window.removeEventListener("lcw:chatRetrieved", this.chatQueuedHandle);
      window.removeEventListener("lcw:onMinimize", this.chatMinimizeHandle);
      window["Microsoft"].Omnichannel && delete window["Microsoft"].Omnichannel;
      for (const element of exist) {
        element.remove();
      }
      this.setisChatReadyToOpen(false);
      if (this.isBtnAdded) {
        this.toggleBtnChat(true);
      }
      this.isMinimizeSDK = false;
      this.isMinimizeIframe = false;
      this.isChatMessagesOpen = false;
    }
  }

  loadContactChatNew(
    hidden: boolean = false,
    openOnLoad: boolean = false,
    typeChat: "normal" | "proactive" = "normal",
  ) {
    const chatData: NewChatData = JSON.parse(this.globalVars.FEATURES.newChatData);
    const exist = document.querySelector(`[id^=${chatData.id}]`);
    if (this.hiddenBtnChat !== hidden && !this.isMinimizeSDK) {
      this.toggleBtnChat(hidden);
    }
    if (exist && this.isChatReadyToOpen) {
      if (openOnLoad) {
        this.openContactChat(typeChat);
      }
      return;
    }
    const se: HTMLScriptElement = document.createElement("script");
    se.type = "text/javascript";
    if (chatData.V2) {
      se.setAttribute("v2", "");
    }
    se.src = chatData.src;
    se.id = chatData.id;
    se.setAttribute("data-app-id", chatData.data_app_id);
    se.setAttribute("data-lcw-version", chatData.data_lcw_version);
    se.setAttribute("data-org-id", chatData.data_org_id);
    se.setAttribute("data-org-url", chatData.data_org_url);
    se.setAttribute("data-hide-chat-button", "true");
    se.setAttribute("data-color-override", "#79C000");
    se.setAttribute("data-font-family-override", "Arial");
    se.setAttribute("data-customization-callback", chatData.data_customization_callback);

    se.onload = () => {
      window.addEventListener("lcw:ready", this.chatReadyHandle(openOnLoad, typeChat));
      window.addEventListener("lcw:startChat", this.chatOpenHandle);
      window.addEventListener("lcw:onClose", this.chatCloseHandle);
      window.addEventListener("lcw:onMinimize", this.chatMinimizeHandle);
      window.addEventListener("lcw:chatQueued", this.chatQueuedHandle);
      window.addEventListener("lcw:chatRetrieved", this.chatQueuedHandle);
    };

    // const containerChat = document.createElement('div');
    // containerChat.classList.add('contact_chat');
    // containerChat.appendChild(se);
    // const s = document.getElementsByTagName('script')[0];
    // s.parentNode.insertBefore(containerChat, s);
    const cuerpo = document.querySelector("body");
    cuerpo.appendChild(se);
  }

  private closeChat() {
    window["Microsoft"].Omnichannel.LiveChatWidget.SDK.closeChat();
  }
  private minimice() {
    document.querySelector("#Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window")["style"].display = "none";
    this.isMinimizeIframe = true;
    this.chatOpen = false;
    this.toggleBtnChat(!this.isChatMessagesOpen);
  }

  private maximice() {
    document.querySelector("#Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window")["style"].display = "block";
    this.isMinimizeIframe = false;
    this.chatOpen = true;
    this.toggleBtnChat(true);
  }

  private toggleBtnChat(hidden: boolean) {
    this.hiddenBtnChat = hidden;
    !this.hiddenBtnChat ? this.showBtnElement() : this.hiddeBtnElement();
  }

  private createBtnElement() {
    this.btnElement = document.createElement("div");
    this.btnElement.innerHTML = `<div id="oclw-chat-perso" class="chat-button" style="position: fixed; bottom: 25px; cursor: pointer; left: 25px;">
            <div class="oclcw-chatbutton" id="oclcw-chatButton" role="button" tabindex="0">
                <div class="oclcw-chaticon-container" style="background-color: #79c000;"><img
                        class="oclcw-chaticon" alt="Chat"
                        src="https://oc-cdn-ocprod.azureedge.net/livechatwidget/images/chatIcon.svg">                    
                </div>                
            </div>
        </div>`;
    this.btnElement.addEventListener("click", () => {
      this.openContactChat("normal", this.emailAux);
    });
  }

  private showBtnElement() {
    if (this.isBtnAdded) {
      return;
    }
    document.querySelector("body").appendChild(this.btnElement);
    this.isBtnAdded = true;
  }

  private hiddeBtnElement() {
    if (!this.isBtnAdded) {
      return;
    }
    this.btnElement.remove();
    this.isBtnAdded = false;
  }
}
