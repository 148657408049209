import { CommonModule, Location } from "@angular/common";
import { Component, OnInit, ViewChild, inject } from "@angular/core";
import { CodereNavbar } from "@components/common/codere-navbar/codere-navbar";
import { CasinoFilterGroup } from "@interfaces/casinoMoreFiltersInterfaces";
import { IonContent, IonicModule } from "@ionic/angular";
import { ModalController } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule } from "@ngx-translate/core";
import { NewBaseService } from "@providers/newBase.service";
import { CasinoService } from "@services/casino/casino.service";
import { DeviceService } from "@services/device.service";
import { CardFilterComponent } from "./card-filter/card-filter.component";
import { CheckboxFiltersComponent } from "./checkbox-filter/checkbox-filters.component";
import { ImageFilterComponent } from "./image-filter/image-filter.component";
import { LayoutFilterComponent } from "./layout-filter/layout-filter.component";
import { MinBetFiltersComponent } from "./min-bet-filter/min-bet-filters.component";
import { OptionsFiltersComponent } from "./options-filter/options-filters.component";
import { ReelsFiltersComponent } from "./reels-filter/reels-filters.component";

@Component({
  selector: "page-more-filters",
  imports: [
    IonicModule,
    CommonModule,
    CodereNavbar,
    OptionsFiltersComponent,
    MinBetFiltersComponent,
    CheckboxFiltersComponent,
    CardFilterComponent,
    ReelsFiltersComponent,
    LayoutFilterComponent,
    TranslateModule,
    ImageFilterComponent,
  ],
  standalone: true,
  providers: [],
  templateUrl: "./more-filters.component.html",
  styleUrls: ["./more-filters.component.scss"],
  host: {
    class: "page-more-filters",
  },
})
export class MoreFiltersPage implements OnInit {
  // #region Inject Services
  modalController = inject(ModalController);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  location = inject(Location);
  casinoService = inject(CasinoService);
  // #endregion

  @ViewChild("content") content: IonContent;
  globalVars!: GlobalVarsModel;
  isDesktop = false;
  filterGroups: CasinoFilterGroup[];

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.isDesktop = this.deviceService.isDesktop();
    this.location.onUrlChange(() => this.closeModal());
  }

  ngOnInit(): void {
    this.filterGroups = this.casinoService.getMoreFilters();
    this.filterGroups.sort((a, b) => a.order - b.order);
  }

  closeModal() {
    const data = {
      deleteFilters: false,
    };
    if (this.modalController) {
      this.modalController.dismiss(data);
    }
  }
  unapplyMoreFilters() {
    const data = {
      deleteFilters: true,
    };
    if (this.modalController) {
      this.modalController.dismiss(data);
    }
  }

  applyFilters() {
    const data = {
      deleteFilters: false,
      filters: this.filterGroups,
    };
    if (this.modalController) {
      this.modalController.dismiss(data);
    }
  }
}
