import { BonusTypes, StateKeys, TriggerTypes } from "./bonus-enums";
/** Maps server side GamesProviderBonuses class */
export class Bonus {
  ProviderId: string;
  BonusId: string;
  RemoteBonusId?: any;
  BonusInstanceCode?: any;
  BonusName: string;
  MarketingName: string;
  Declinable: boolean;
  BonusType: BonusTypes;
  PlayType: string;
  TriggerType: TriggerTypes;
  TriggerTypeName: string;
  RemovalReasonType: number;
  RemovalReasonName?: any;
  StateKey: StateKeys;
  State: string;
  BonusCurrentAmount: number;
  BonusAmount: number;
  BonusInitialAmount: number;
  PendingWinning: number;
  WageringRequirement: number;
  WageringRemain: number;
  FreeSpinCount: number;
  FreeSpinRemain: number;
  GoldenChipsCount: number;
  GoldenChipsRemain: number;
  Description: string;
  SectionType: string;
  StartDate: Date;
  ExpirationDate: Date;
  LastUpdate: Date;
  WageringStartDate?: any;
  IssueDate?: any;
  Accepted?: any;
  CompletionDate?: any;
  BonusRedeemed: number;
  SelectedGames: string[];
  RemoveUrl: string;
}
