import { BreakpointObserver } from "@angular/cdk/layout";
import { InjectionToken, inject } from "@angular/core";
import { Observable, distinctUntilChanged, map } from "rxjs";

export const SMALL_VIEWPORT = new InjectionToken<Observable<boolean>>("SMALL_VIEWPORT", {
  providedIn: "root",
  factory: () =>
    inject(BreakpointObserver)
      .observe(["(max-width: 768px)"])
      .pipe(
        map(({ matches }) => matches),
        distinctUntilChanged(),
      ),
});
