import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { IonicModule, ModalController, NavParams } from "@ionic/angular";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "app-version-popup",
  templateUrl: "./app-version-popup.html",
  standalone: true,
  styleUrls: ["./app-version-popup.scss"],
  imports: [IonicModule, CommonModule],
})
export class AppVersionPopup {
  modalConfig: any;
  params = inject(NavParams);
  modalControl = inject(ModalController);

  constructor() {
    if (this.params.data) {
      this.modalConfig = this.params.data;
    }
  }

  goToAppLink() {
    window.open(this.modalConfig.storeUrl, "_blank");
    this.modalControl.dismiss();
  }

  closeModal() {
    this.modalControl.dismiss();
  }
}
