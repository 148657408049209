import { CommonModule } from "@angular/common";
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/component-selector */
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Input,
  type OnDestroy,
  type OnInit,
  ViewChild,
  inject,
} from "@angular/core";
import { FormBuilder, type FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, type NavigationExtras, Router } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { Buttons, RegistroData } from "@interfaces/interfaces";
import { AlertController, IonTabs, IonicModule, ModalController, NavController, Platform } from "@ionic/angular";
import { CookieNames } from "@models/CookieNames";
import { IdentificationDocumentTypes, ValidationPatterns } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { EventTypes, TRAKERSTYPECODEREID } from "@models/TrackingEvents";
import { TranslateService } from "@ngx-translate/core";
import { AppsFlyerTrackingService } from "@providers/AppsFlyerTrackingService";
import { CookiesService } from "@providers/CookiesService";
import { ErrorService } from "@providers/ErrorService";
import { EventsService } from "@providers/EventsService";
import { GTMDataManagerService, TrackStatus } from "@providers/GTMDataManager.service";
import { NativeService } from "@providers/NativeService";
import { TrackingService } from "@providers/TrackingService";
import { UserService } from "@providers/UserService";
import { UserServiceMSO } from "@providers/UserServiceMSO";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { DecouplingUserServiceMSO } from "@providers/DecouplingUserServiceMSO.service";
import { MSO_PATHS } from "@shared-constants/routes";
import { CapacitorGetPlatform } from "@shared-utils/index";
import { Utils } from "@utils/Utils";
import { BehaviorSubject, Subject, Subscription, takeUntil } from "rxjs";
import { FsConfirmarComponent } from "../../../components/registro/fs-confirmar/fs-confirmar.component";
import { FsContactoComponent } from "../../../components/registro/fs-contacto/fs-contacto.component";
import { FsPersonalComponent } from "../../../components/registro/fs-personal/fs-personal.component";
import { FsUsuarioComponent } from "../../../components/registro/fs-usuario/fs-usuario.component";
import { InfoTopComponent } from "../../../components/registro/info-top/info-top.component";
import { RegistroValidators } from "../../../registro.validators";
import { LegalTermsServiceService } from "../../../services/registro/legalTerms.service";
import { MaestroDatos } from "../../../services/registro/maestros.service";
import { ValidationService } from "../../../services/registro/validation.service";
import { SelectPromotion } from "../../select-promotion/select-promotion";

@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: "cdr-registro",
  templateUrl: "./registro.html",
  standalone: true,
  styleUrls: ["./registro.scss"],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    InfoTopComponent,
    FsPersonalComponent,
    FsUsuarioComponent,
    FsContactoComponent,
    FsConfirmarComponent,
  ],
  providers: [],
})
export class RegistroNewPage implements OnInit, OnDestroy {
  @Input() codepromotion: string;
  @ViewChild(IonTabs) tabs;
  buttons: Buttons;
  formReg: FormGroup;
  formPers: FormGroup;
  formCont: FormGroup;
  formUsr: FormGroup;
  formConf: FormGroup;
  jobs: Array<{ id: number; name: string }> = [];
  globalVars!: GlobalVarsModel;
  viewCtrl: ModalController;

  isResident$: Subject<boolean>;
  canShowSuportNumber: BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectedTab: string;

  //#region Inject Services
  errorService = inject(ErrorService);
  fb = inject(FormBuilder);
  modalCtrl = inject(ModalController);
  vals = inject(ValidationService);
  legalTS = inject(LegalTermsServiceService);
  alertController = inject(AlertController);
  newBaseService = inject(NewBaseService);
  trackingService = inject(TrackingService);
  translate = inject(TranslateService);
  router = inject(Router);
  userService = inject(UserService);
  userServiceMSO = inject(UserServiceMSO);
  decouplingUSerServiceMSO = inject(DecouplingUserServiceMSO);
  utils = inject(Utils);
  cookiesService = inject(CookiesService);
  events = inject(EventsService);
  navCtrl = inject(NavController);
  deviceService = inject(DeviceService);
  platform = inject(Platform);
  appsflyerTrackingService = inject(AppsFlyerTrackingService);
  private nativeService = inject(NativeService);
  private GTMDataManagerService = inject(GTMDataManagerService);
  private paramsSubscription$: Subscription;
  //#endregion

  isDesktop = this.deviceService.isDesktop();
  isMobile = this.deviceService.isMobile();
  isIos = this.deviceService.isMobile() && this.platform.is("ios");

  payload: string;
  private route = inject(ActivatedRoute);
  private readonly unsubscribe$ = new Subject();

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isResident$ = new Subject();
  }

  ngOnDestroy(): void {
    this.utils.unloadContactChat();
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
    this.paramsSubscription$?.unsubscribe();
  }

  ngOnInit(): void {
    this.buttons = {
      next: "",
      back: "",
      endRegister: "Finalizar Registro",
    };

    this.formPers = this.fb.group({
      gender: [
        { id: 1, name: "Hombre" },
        {
          validators: [Validators.required],
        },
      ],
      name: [
        "",
        {
          validators: [
            Validators.required,
            RegistroValidators.spaceSuffixNames(),
            Validators.minLength(2),
            Validators.maxLength(50),
            Validators.pattern(ValidationPatterns.NameSurname),
          ],
          updateOn: "blur",
        },
      ],
      name2: [
        "",
        {
          // surname
          validators: [
            Validators.required,
            RegistroValidators.spaceSuffixNames(),
            Validators.minLength(2),
            Validators.maxLength(50),
            Validators.pattern(ValidationPatterns.NameSurname),
          ],
          updateOn: "blur",
        },
      ],
      name3: [
        "",
        {
          // surname2
          validators: [
            RegistroValidators.spaceSuffixNames(),
            Validators.minLength(2),
            Validators.maxLength(50),
            Validators.pattern(ValidationPatterns.NameSurname),
          ],
          updateOn: "blur",
        },
      ],
      birthDay: [
        null,
        {
          validators: [Validators.required],
        },
      ],
      birthMonth: [
        null,
        {
          validators: [Validators.required],
        },
      ],
      birthYear: [
        null,
        {
          // Validators definidos despues de crear el formcontrol
          updateOn: "blur",
        },
      ],
      resident: [
        null,
        {
          validators: [Validators.required],
        },
      ], // fromSpain
      nationality: [
        null,
        {
          validators: [Validators.required],
        },
      ],
      // Solo para residentes
      dni: [
        "",
        {
          validators: [Validators.required],
          updateOn: "blur",
        },
      ], // asyncValidator definido despues de crear el formcontrol
      // Solo para no residentes: Los validadores se añaden dinámicamente en fs-personal
      country: [
        "",
        {
          validators: [Validators.required],
        },
      ], //
      documentType: [""], // regNotResidDocumentType
      documentNumber: [
        "",
        {
          // regNotResidDocumentNumber
          updateOn: "blur",
        },
      ],
      supportNumber: [
        "",
        {
          updateOn: "blur",
        },
      ],
      profesion: [null, Validators.required],
    });

    this.formPers
      .get("birthYear")
      .setValidators([
        Validators.required,
        RegistroValidators.edad(
          this.formPers.get("birthDay"),
          this.formPers.get("birthMonth"),
          this.formPers.get("birthYear"),
        ),
      ]);
    this.formPers
      .get("birthDay")
      .setValidators([
        Validators.required,
        RegistroValidators.edad(
          this.formPers.get("birthDay"),
          this.formPers.get("birthMonth"),
          this.formPers.get("birthYear"),
        ),
      ]);
    this.formPers
      .get("birthMonth")
      .setValidators([
        Validators.required,
        RegistroValidators.edad(
          this.formPers.get("birthDay"),
          this.formPers.get("birthMonth"),
          this.formPers.get("birthYear"),
        ),
      ]);

    this.formPers
      .get("dni")
      .setAsyncValidators(RegistroValidators.dniCDR(this.formPers.get("nationality"), this.userServiceMSO));

    this.formCont = this.fb.group({
      address: [
        "",
        {
          validators: [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50),
            RegistroValidators.noWhitespaceValidator,
          ],
          updateOn: "blur",
        },
      ],
      postalCode: [
        "",
        {
          validators: [Validators.required, RegistroValidators.zipCode(this.formPers.get("resident"))],
          updateOn: "blur",
        },
      ],
      mobile: [
        "",
        {
          validators: [Validators.required],
          asyncValidators: RegistroValidators.mobileCDR(this.formPers.get("resident"), this.userServiceMSO),
          updateOn: "blur",
        },
      ],
      email: [
        "",
        {
          validators: [Validators.required],
          asyncValidators: RegistroValidators.emailCDR(this.userServiceMSO),
          updateOn: "blur",
        },
      ],
    });

    this.formUsr = this.fb.group({
      user: [
        "",
        {
          validators: [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(15),
            Validators.pattern("[A-Za-z0-9]*"),
          ],
          asyncValidators: RegistroValidators.userCDR(this.userServiceMSO),
          updateOn: "blur",
        },
      ],
      password: [
        "",
        {
          // Validators definidos despues de crear el formcontrol
          updateOn: "blur",
        },
      ],
      promoCode: [
        "",
        {
          asyncValidators: RegistroValidators.promoCDR(this.userServiceMSO),
          updateOn: "blur",
        },
      ],
    });

    this.formUsr
      .get("password")
      .setValidators([Validators.required, RegistroValidators.passwd(this.formPers, this.formUsr)]);

    this.formConf = this.fb.group({
      confirm: [
        false,
        {
          validators: [Validators.requiredTrue],
          updateOn: "blur",
        },
      ], // 'edad'
      comunica: [
        false,
        {
          updateOn: "blur",
        },
      ],
      PRP: [false, [Validators.required]],
    });

    this.formReg = this.fb.group(
      {
        personal: this.formPers,
        contacto: this.formCont,
        usuario: this.formUsr,
        confirmacion: this.formConf,
      },
      { updateOn: "blur" },
    );

    //si hay codepromotion se prerrellena
    this.paramsSubscription$ = this.route.queryParams.subscribe((params) => {
      if (params["codepromotion"]) {
        this.codepromotion = params["codepromotion"];
        this.formUsr.get("promoCode").setValue(this.codepromotion);
        this.formUsr.get("promoCode").markAsTouched();
        this.formUsr.get("promoCode").markAsDirty();
      }
    });

    if (this.codepromotion) {
      this.formUsr.get("promoCode").setValue(this.codepromotion);
      this.formUsr.get("promoCode").markAsTouched();
      this.formUsr.get("promoCode").markAsDirty();
    }

    this.defineChangeValidations();
    // desde ngOnInit se actualizan los términos y condiciones legales
    this.legalTS.updateConditions();

    if (this.isMobile) {
      this.navigateToPaso("paso1");
    }
  } // Fin de ngOnInit

  ionViewDidEnter() {
    if (this.isMobile && document.querySelector('[aria-label="ps2"]')) {
      document.querySelector('[aria-label="ps2"]').outerHTML = "<p></p>";
    }
  }

  // Se completa la configuración inicial

  /**
   * Define validaciones extras que afectan a determinados controles
   * en respuesta a cambios en algún otro
   */
  defineChangeValidations(): void {
    this.formPers
      .get("birthDay")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        const idItems = ["birthMonth", "birthYear"];
        idItems.forEach((item) => {
          const control = this.formPers.get(item);
          control.updateValueAndValidity({ emitEvent: false });
          this.vals.adjustValidIonItem(item, control);
        });
      });
    this.formPers
      .get("birthMonth")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        const idItems = ["birthDay", "birthYear"];
        idItems.forEach((item) => {
          const control = this.formPers.get(item);
          control.updateValueAndValidity({ emitEvent: false });
          this.vals.adjustValidIonItem(item, control);
        });
      });
    this.formPers
      .get("birthYear")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        const idItems = ["birthDay", "birthMonth"];
        idItems.forEach((item) => {
          const control = this.formPers.get(item);
          control.updateValueAndValidity({ emitEvent: false });
          this.vals.adjustValidIonItem(item, control);
        });
      });
    this.formPers
      .get("nationality")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data?.id == 71 || (data?.id != 71 && this.formPers.get("resident")?.value?.id == 0)) {
          this.formPers
            .get("dni")
            .setAsyncValidators(RegistroValidators.dniCDR(this.formPers.get("nationality"), this.userServiceMSO));
          this.formPers.get("dni").setValidators(Validators.required);
          this.formPers.get("documentNumber").clearValidators();
        } else {
          this.formPers.get("dni").clearAsyncValidators();
          this.formPers.get("dni").clearValidators();
          this.formPers.get("documentType").setValidators(Validators.required);
        }
      });
    this.formPers.get("resident").valueChanges.subscribe(
      //fromSpain
      (value: MaestroDatos) => {
        //update validations
        //zip validation
        //phone validation
        this.formCont.get("postalCode").updateValueAndValidity();
        this.formCont.get("mobile").updateValueAndValidity();

        this.canShowSuportNumber.next(false);
        this.formPers.get("supportNumber").clearValidators();
        this.formPers.get("supportNumber").reset("");

        this.isResident$.next(value.id == 0);
        if (value?.id == 0 || this.formPers.get("nationality").value?.id == 71) {
          //residente
          this.formPers
            .get("dni")
            .setAsyncValidators(RegistroValidators.dniCDR(this.formPers.get("nationality"), this.userServiceMSO));
          this.formPers.get("dni").setValidators(Validators.required);
          this.formPers.get("country").clearValidators();
          this.formPers.get("documentType").clearValidators();
          this.formPers.get("documentType").reset("");
          this.formPers.get("documentNumber").clearValidators();
          this.formPers.get("documentNumber").clearAsyncValidators();
        } else {
          // no residente
          this.formPers.get("dni").clearValidators();
          this.formPers.get("dni").clearAsyncValidators();
          this.formPers.get("country").setValidators(Validators.required);
          this.formPers.get("documentType").setValidators(Validators.required);
          this.formPers.get("documentNumber").setValidators([Validators.required, Validators.minLength(6)]);
          this.formPers
            .get("documentNumber")
            .setAsyncValidators(RegistroValidators.otherDocuments(this.userServiceMSO));
          const int = setInterval(() => {
            const elCountry = document.querySelector("#country");
            if (elCountry) {
              this.vals.adjustTouchedIonItem("country", this.formPers.get("country"));
              this.vals.adjustTouchedIonItem("documentType", this.formPers.get("documentType"));
              clearInterval(int);
            }
          }, 100);
        }

        // cuando cambian valores se actualizan los términos y condiciones legales
        this.legalTS.updateConditions();
      },
    );

    this.formPers
      .get("documentType")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (value) {
          if (this.formPers.get("documentType").value.id == IdentificationDocumentTypes.NIE) {
            this.formPers.get("documentNumber").clearValidators();
            this.formPers.get("documentNumber").clearAsyncValidators();
            this.formPers
              .get("documentNumber")
              .setAsyncValidators(
                RegistroValidators.dniCDR(this.formPers.get("nationality"), this.userServiceMSO),
              );
            this.formPers.get("documentNumber").updateValueAndValidity();
          } else {
            this.formPers.get("documentNumber").clearValidators();
            this.formPers.get("documentNumber").clearAsyncValidators();
            this.formPers
              .get("documentNumber")
              .setValidators(Validators.compose([Validators.required, Validators.minLength(6)]));
            this.formPers
              .get("documentNumber")
              .setAsyncValidators(RegistroValidators.otherDocuments(this.userServiceMSO));
            this.formPers.get("documentNumber").updateValueAndValidity();
          }
        }
      });

    const asyncValidatedControls = [
      { controlName: "dni", fgroup: this.formPers },
      { controlName: "mobile", fgroup: this.formCont },
      { controlName: "email", fgroup: this.formCont },
      { controlName: "user", fgroup: this.formUsr },
      { controlName: "promoCode", fgroup: this.formUsr },
    ];
    asyncValidatedControls.forEach((item) => {
      const itemId = item.controlName;
      const control = item.fgroup.get(item.controlName);
      control.statusChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((newStatus) => {
        console.info(itemId, "nuevo estado: ", newStatus);
        this.vals.adjustValidIonItem(itemId, control);
      });
    });

    // TODO: utilizado status changes en todos los controles
    // se podría corregir de forma generalizada
    // los bugs de ionic
  }

  // Manejadores de eventos

  /**
   * Manejador del evento submit del formulario completo
   */
  async enviar() {
    //  antes fullRegister()
    console.info(this.formReg);
    this.formConf.controls["confirm"].markAsDirty();
    this.formPers.get("profesion").markAsDirty();
    if (this.formReg.valid) {
      const formData = this.formatData();
      console.info("Enviando: respuesta al evento submit");
      console.info(formData);
      //no termina el registro
      // if (DEV) {
      //   return
      // }
      await this.utils.loader();
      this.userServiceMSO
        .register(formData)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (data) => this.doRegister(formData, data),
          error: async (err) => {
            this.trackingService.trackEvent(["FinishRegister", "KO", "ERR", "Fin Registro KO", "event"]);
            await this.utils.closeLoader();
            this.utils.showError(err);
          },
          complete: async () => {
            await this.utils.closeLoader();
          },
        });
    } else {
      // error de validación
      console.error("Formulario inválido");
      this.vals.validateAllFormFields(this.formReg);
    }
  }

  /**
   * Da formato a los datos deacuerdo con los requerimientos del
   * proceso de registro en el backend (Interface RegistroData)
   */
  formatData() {
    const isResident = !this.formPers.get("resident").value.id;
    const birthDay: string =
      this.formPers.get("birthYear").value.toString() +
      "-" +
      this.formPers.get("birthMonth").value.id +
      "-" +
      this.formPers.get("birthDay").value;
    const genero: string = this.formPers.get("gender").value.name;
    const dni: string = this.formPers.get("dni").value ? this.formPers.get("dni").value.toUpperCase() : "";

    const data: RegistroData = {
      Genero_Hombre_Mujer: genero,
      // Solo para residentes
      NIF: isResident ? dni : undefined,
      //
      AceptaEmailPromo_Y_N: this.formConf.get("comunica").value,
      AceptaEmailPromo_Y_NSpecified: false,
      AceptaSMSPromo_Y_N: this.formConf.get("comunica").value,
      AceptaSMSPromo_Y_NSpecified: false,
      Ciudad: null,
      CodigoPostalExt: null,
      CodigoPostal: this.formCont.get("postalCode").value,
      Direccion: this.formCont.get("address").value,
      EstadoCta: null,
      FechaNacimiento_YYYYMMDD: birthDay,
      FechaNacimiento_YYYYMMDDSpecified: false,
      NombreUsuario: this.formUsr.get("user").value,
      // Variable solo para no residentes
      Pais: isResident ? "ESPA\u00D1A" : this.formPers.get("country").value.name,
      //
      Password: this.formUsr.get("password").value,
      Provincia: null,
      SegundoApellido: this.formPers.get("name3").value,
      VerifiDocumental: "Pendiente",
      NumMovilExt: null,
      NumMovil: this.formCont.get("mobile").value,
      Nacionalidad: this.formPers.get("nationality").value.name,
      Profesion: this.formPers.get("profesion").value.name,
      Prp: this.formConf.get("PRP").value,
      // Variable solo para no residentes
      TipoDocumento: isResident ? undefined : this.setDocType(),
      NumeroDocumento: isResident ? undefined : this.setDocNumber(),
      //
      Direccion_Correo: this.formCont.get("email").value,
      Nombre: this.formPers.get("name").value,
      PrimerApellido: this.formPers.get("name2").value,
      Afiliado: null,
      VersionTyCAceptada: this.legalTS.getLegalTerms().code,
      DispositivoRegistro: this.deviceService.getDevicePlatform(),
      CodigoAgente: null,
      CodigoPartner: null,
      OrigenWebLocal: "Web",
      TipoDocumento_NIF_NIE: isResident ? "NIF/NIE" : "Otros",
      CodigoBono: this.formUsr.get("promoCode").value ? this.formUsr.get("promoCode").value : "",
      NodoSalaId: null,
      NifSupportNumber: this.formPers.get("supportNumber").value,
    };

    return data;
  }

  /**
   * Seteo para añadir DNI si es Español no residente
   */
  setDocType() {
    if (this.formPers.get("nationality").value.id == 71) {
      return IdentificationDocumentTypes.DNI;
    } else {
      return this.formPers.get("documentType").value.id;
    }
  }
  setDocNumber() {
    if (
      this.formPers.get("nationality").value.id == 71 ||
      this.formPers.get("documentType").value.id == IdentificationDocumentTypes.NIE
    ) {
      return this.formPers.get("dni").value.toUpperCase();
    } else {
      return this.formPers.get("documentNumber").value;
    }
  }

  /**
   * Proceso final de registro,
   * @param data
   */
  async doRegister(formData, data) {
    if (data.status.toUpperCase() == "OK") {
      if (Capacitor.getPlatform() !== CapacitorGetPlatform.WEB) {
        this.appsflyerTrackingService.trackEvent("af_complete_registration", {
          userId: formData.NombreUsuario,
        });

        // Xtreme Registration event
        this.nativeService.xtremeHitEvent("Registration", "Registration Event");
      }

      const trackerReg = { value: "RegistroESPages_New" };
      const observer = this.globalVars.FEATURES.MSO_DecouplingTrackersCodereId
        ? this.decouplingUSerServiceMSO.createTrackersCodereID({
            code: trackerReg.value,
            trackerType: TRAKERSTYPECODEREID.croTag2,
          })
        : this.userService.createTrackersCodereId([{ ...trackerReg, trackType: TRAKERSTYPECODEREID.croTag2 }]);

      observer.pipe(takeUntil(this.unsubscribe$)).subscribe();
      const htmltaboola =
        "<img src='https://trc.taboola.com/1319873/log/3/unip?en=complete_registration' width='0' height='0' style='display:none'>";
      document.querySelector("#taboola").innerHTML += htmltaboola;
      if (this.globalVars.FEATURES.Prom10x30Enabled) {
        this.selectPromotionModal();
      } else {
        this.checkUserState(formData);
      }
    } else {
      //error: data.status != 'OK'
      this.controlRegisterErrors(data);
      //iniciar chat si esta activado chatEnabedToFailures en CRM
      if (this.globalVars.FEATURES.chatEnabedToFailureRegister && data.errCode != "709" && data.errCode != "100") {
        this.utils.loadContactChat();
        if (!this.checkErrorCodesCheckIdentity(data.errCode)) {
          setTimeout(
            () => {
              this.utils.openContactChat();
            },
            this.globalVars.FEATURES.newChatEnabled ? 1 : 800,
          );
        }
      }
    }
  } // final doRegister()

  async selectPromotionModal() {
    const modal = await this.modalCtrl.create({
      component: SelectPromotion,
      cssClass: "is-modal",
    });
    await modal.present();
  }
  /**
   * checks if there is a current user connected
   * En caso de ser posible, continua en loginAndGoto
   */
  checkUserState(form) {
    // TODO probar correcto funcionamiento cuando se pueda realizar un registro
    this.utils.loginAndGoto("/", null, true).then((isOk) => {
      if (!isOk) {
        this.linkOutOfModalPage(this.router.url || "/", {});
      } else {
        this.addTrackerRegisterOK(form);
      }
    });
    // codigo original
    // this.utils.loginAndGoto(this.globalVars.afterRegisterGoBackToPage, null).then((isOk) => {
    //   if (isOk) {
    //     this.closeModal();
    //   } else {
    //     this.linkOutOfModalPage(
    //       this.globalVars.afterRegisterGoBackToPage
    //         ? this.globalVars.afterRegisterGoBackToPage
    //         : this.globalVars.mainPageComponent,
    //       {}
    //     );
    //   }
    // });
  }

  /**
   * Login y navegación después del registro correcto
   * @param dest
   */
  loginAndGoto(dest: any, data) {}

  /**
   * Gestiona los distintos errores en el registro
   * @param data
   */
  controlRegisterErrors(data) {
    this.trackingService.trackEvent(["FinishRegister", "KO", data.errCode, "Fin Registro KO", "event"]);
    const registerErrorMsg: string = this.translate.instant("m_reg_regKo");
    if (data.errCode != undefined) {
      // existe errCode
      if (data.errCode == "0") {
        this.utils.confirm(
          false,
          this.translate.instant("m_reg_regKoT"),
          this.translate.instant("m_reg_regKoIdent") +
            "<br>" +
            this.translate.instant("tHelpTittle") +
            ": " +
            this.translate.instant("tHelpTittletxt"),
          "Chat",
          () => {
            this.trackingService.trackEvent(["runChat", "", "RegKO", "runChat desde (RegKO-error100)", "event"]);
            this.utils.loadContactChat();
            setTimeout(
              () => {
                this.utils.openContactChat();
              },
              this.globalVars.FEATURES.newChatEnabled ? 1 : 800,
            );
          },
          this.translate.instant("nothanks"),
        );
      } else if (this.checkErrorCodesCheckIdentity(data.errCode)) {
        this.showAlertErrorCodesCheckIdentity(data.errCode);
      } else {
        // otros errCode != 0
        this.errorService
          .searchErrorCode(data.errCode)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: (logError) => {
              let title: string;
              if (data.errCode == "709") {
                //m_reg_regKoT709
                (title = this.translate.instant("m_reg_regKoT709")),
                  this.utils.confirmcustom(
                    true,
                    title.toUpperCase(),
                    this.translate.instant("m_reg_regKoT709tx"),
                    "Chat",
                    () => {
                      this.trackingService.trackEvent([
                        "runChat",
                        "",
                        "RegKO",
                        "runChat desde (RegKO-error709)",
                        "event",
                      ]);
                      this.utils.loadContactChat();
                      setTimeout(
                        () => {
                          this.utils.openContactChat();
                        },
                        this.globalVars.FEATURES.newChatEnabled ? 1 : 800,
                      );
                    },
                    this.translate.instant("nothanks"),
                    null,
                    false,
                    "alertcenso",
                  );
              } else if (data.errCode == "100") {
                this.utils.confirm(
                  false,
                  this.translate.instant("m_reg_regKoT"),
                  this.translate.instant("m_reg_regKoIdent") +
                    "<br>" +
                    this.translate.instant("tHelpTittle") +
                    ": " +
                    this.translate.instant("tHelpTittletxt"),
                  "Chat",
                  () => {
                    this.trackingService.trackEvent([
                      "runChat",
                      "",
                      "RegKO",
                      "runChat desde (RegKO-error100)",
                      "event",
                    ]);
                    this.utils.loadContactChat();
                    setTimeout(
                      () => {
                        this.utils.openContactChat();
                      },
                      this.globalVars.FEATURES.newChatEnabled ? 1 : 800,
                    );
                  },
                  this.translate.instant("nothanks"),
                );
              } else {
                title = this.translate.instant("m_reg_regKoT");
                //show error
                this.utils.alert(true, title, logError, "OK");
              }
            },
            error: (err) => {
              // error del servicio que recupera los errores
              this.utils.confirm(
                false,
                this.translate.instant("m_reg_regKoT"),
                this.translate.instant("m_reg_regKoIdent") +
                  "<br>" +
                  this.translate.instant("tHelpTittle") +
                  ": " +
                  this.translate.instant("tHelpTittletxt"),
                "Chat",
                () => {
                  this.trackingService.trackEvent([
                    "runChat",
                    "",
                    "RegKO",
                    "runChat desde (RegKO-error100)",
                    "event",
                  ]);
                  this.utils.loadContactChat();
                  setTimeout(
                    () => {
                      this.utils.openContactChat();
                    },
                    this.globalVars.FEATURES.newChatEnabled ? 1 : 800,
                  );
                },
                this.translate.instant("nothanks"),
                null,
                false,
                "btnalertrow",
              );
            },
          });
      }
    } else {
      // no existe errCode
      let msgCode: string = this.translate.instant("m_menu_noSport");
      if (data.errCode != undefined) {
        this.errorService.searchErrorCode(data.status + data.errCode).subscribe({
          next: (data2) => {
            msgCode = data2;
            this.utils.confirm(
              false,
              this.translate.instant("m_reg_regKoT"),
              this.translate.instant("m_reg_regKoIdent") +
                "<br>" +
                this.translate.instant("tHelpTittle") +
                ": " +
                this.translate.instant("tHelpTittletxt"),
              "Chat",
              () => {
                this.trackingService.trackEvent([
                  "runChat",
                  "",
                  "RegKO",
                  "runChat desde (RegKO-error100)",
                  "event",
                ]);
                this.utils.loadContactChat();
                setTimeout(
                  () => {
                    this.utils.openContactChat();
                  },
                  this.globalVars.FEATURES.newChatEnabled ? 1 : 800,
                );
              },
              this.translate.instant("nothanks"),
            );
          },
          error: (err) => {
            this.utils.showError(data.errCode);
          },
        });
      } else {
        this.utils.showError(msgCode);
      }
    }
  }

  /**
   *
   * @param link
   * @param paymentArguments
   */
  linkOutOfModalPage(link: any, paymentArguments: any) {
    this.router.navigate([link, paymentArguments]), { animate: !this.isDesktop };
  }

  /**
   * Cierra la modal
   */
  closeModal() {
    if (this.viewCtrl) {
      this.viewCtrl.dismiss();
    }
  }

  get step1Valid() {
    return this.formPers.valid && this.formCont.valid;
  }

  checkErrorCodesCheckIdentity(errCode: string) {
    let includes = false;

    const arrayCodes: string[] = [
      "COD004-1",
      "COD004-2",
      "COD004-3",
      "COD004-4",
      "COD004-5",
      "COD004-6",
      "COD905-1",
    ];

    if (errCode && errCode != "") {
      includes = !!arrayCodes.find((e) => e === errCode);
    }

    return includes;
  }

  private errorCodesCheckIdentityFormatAlert(errCode: string) {
    interface AlertMSg {
      msg: string;
      acceptAction: () => void;
    }

    let formatAlert: AlertMSg;

    switch (errCode) {
      case "COD004-1":
        formatAlert = {
          msg: this.translate.instant("COD0041"),
          acceptAction: () => {
            if (this.isMobile) {
              this.tabs.select(0);
            }
            if (this.formPers.get("resident").value.id === 0 || this.formPers.get("nacionality").value.id === 71) {
              this.formPers.get("dni").setErrors({ checkIdentity: true });
              this.vals.adjustValidIonItem("dni", this.formPers.get("dni"));
            } else {
              this.formPers.get("documentNumber").setErrors({ checkIdentity: true });
              this.vals.adjustValidIonItem("documentNumber", this.formPers.get("documentNumber"));
            }
          },
        };
        break;
      case "COD004-2":
        formatAlert = {
          msg: this.translate.instant("COD0042"),
          acceptAction: () => {
            if (this.isMobile) {
              this.tabs.select(0);
            }
            this.formPers.get("name2").setErrors({ checkIdentity: true });
            this.vals.adjustValidIonItem("name2", this.formPers.get("name2"));
          },
        };
        break;
      case "COD004-3":
        formatAlert = {
          msg: this.translate.instant("COD0043"),
          acceptAction: () => {
            if (this.isMobile) {
              this.tabs.select(0);
            }
            this.formPers.get("name3").setErrors({ checkIdentity: true });
            this.vals.adjustValidIonItem("name3", this.formPers.get("name3"));
          },
        };
        break;
      case "COD004-4":
        formatAlert = {
          msg: this.translate.instant("COD0044"),
          acceptAction: () => {
            if (this.isMobile) {
              this.tabs.select(0);
            }
            this.formPers.get("birthYear").setErrors({ checkIdentity: true });
            this.vals.adjustValidIonItem("birthYear", this.formPers.get("birthYear"));
            setTimeout(() => {
              this.events.publish("doScrollToTop");
            }, 300);
          },
        };
        break;
      case "COD004-5":
        formatAlert = {
          msg: this.translate.instant("COD0045"),
          acceptAction: () => {
            if (this.isMobile) {
              this.tabs.select(0);
            }
            this.formPers.get("name").setErrors({ checkIdentity: true });
            this.vals.adjustValidIonItem("name", this.formPers.get("name"));
            setTimeout(() => {
              this.events.publish("doScrollToTop");
            }, 300);
          },
        };
        break;
      case "COD004-6":
        formatAlert = {
          msg: this.translate.instant("COD0046"),
          acceptAction: () => {
            if (this.isMobile) {
              this.tabs.select(0);
            }
            this.formPers
              .get("supportNumber")
              .setValidators([Validators.required, RegistroValidators?.supportNumberValidator as any]);
            this.canShowSuportNumber.next(true);
          },
        };
        break;
      case "COD905-1":
        formatAlert = {
          msg: this.translate.instant("COD905"),
          acceptAction: () => {
            if (this.isMobile) {
              this.tabs.select(0);
            }
            this.formPers
              .get("supportNumber")
              .setValidators([Validators.required, RegistroValidators?.supportNumberValidator as any]);
            this.formPers.get("supportNumber").setErrors({ backError: "notValid" });
            this.canShowSuportNumber.next(true);
          },
        };
        break;
    }
    return formatAlert;
  }

  async showAlertErrorCodesCheckIdentity(errCode: string) {
    const formatAlert = this.errorCodesCheckIdentityFormatAlert(errCode);
    const alertErr = await this.alertController.create({
      header: this.translate.instant("m_reg_regKoT"),
      message: "<span class='alertMessageCustom'>" + formatAlert.msg + "</span>",
      cssClass: "",
      backdropDismiss: false,
      buttons: [
        {
          text: "OK",
          handler: () => {
            formatAlert.acceptAction();
            alertErr.dismiss();
          },
        },
      ],
    });
    await alertErr.present();
  }
  onTabChange(event) {
    this.selectedTab = event.tab;
  }
  navigateToPaso(paso) {
    const navigationExtras: NavigationExtras = {
      skipLocationChange: true,
      queryParams: {
        formReg: this.formReg,
        isResident: this.isResident$.asObservable(),
        canShowSuportNumber: this.canShowSuportNumber,
      },
    };
    const pathPaso1 = `/${MSO_PATHS.BASE}/${MSO_PATHS.RegistroNewPage}/${MSO_PATHS.Paso1Page}`;
    const pathPaso2 = `/${MSO_PATHS.BASE}/${MSO_PATHS.RegistroNewPage}/${MSO_PATHS.Paso2Page}`;

    const path = paso === "paso1" ? pathPaso1 : pathPaso2;

    this.navCtrl.navigateForward([path], navigationExtras);
  }

  addTrackerRegisterOK(form) {
    this.trackingService.track({
      eventType: EventTypes.FinishRegister,
      additionalData: {
        contactId: this.globalVars.user.idBGT,
      },
      id: "OK",
      description: form.Direccion_Correo,
      secondParameter: form.NombreUsuario,
    });
    let MexosBannerClick;
    const bannerClickCookie = this.cookiesService.getCookie(CookieNames.MexosBannerClick);
    if (bannerClickCookie) {
      MexosBannerClick = this.utils.getMexosCookieInfoFromCookieValue(bannerClickCookie);
    }
    this.GTMDataManagerService.addItem(
      {
        status: TrackStatus.OK,
        userId: this.globalVars.user.idBGT,
        advertiser: MexosBannerClick?.advertiser || "",
        profile: MexosBannerClick?.profileId || "",
        acqSource: MexosBannerClick?.adquisitionSource || "",
        ID2: MexosBannerClick?.btagInfo?.ID2 || "",
        ID3: MexosBannerClick?.btagInfo?.ID3 || "",
      },
      "Signup",
    );
  }
} // Fin de la clase
