import { LocalizedValuesList } from "./localized-valued.model";

export class LiveHistory {
  ActionTOs: Array<Action>;
  CurrentScore: CurrentScore;
  EventNodeId: string;
  MatchStatus: number;
  Scores: Array<Score>;
}

export class Action {
  ActionId: number;
  ActionPeriod: number;
  ActionTimeM: number;
  ActionTimestamp: string;
  ActionType: number;
  AdditionalInfos: any;
  AffectedParticipantName: string;
  IsHomeTeam: boolean;
  ParticipantName: string;
  Score: Score;
}

export class CurrentScore {
  MatchTimeM: number;
  PeriodStartTimestamp: string;
  TimeStamp: string;
  AdditionalInfos: any;
  Period: number;
  ScoreParts: Array<ScorePart>;
}

export class Score {
  AdditionalInfos: any;
  Period: number;
  ScoreParts: Array<ScorePart>;
}

export class ScorePart {
  IsActor: boolean;
  LocalizedName: LocalizedValuesList;
  ParticipantId: number;
  ScoreType: number;
  ScoreValue: number;
}
