import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { LicenseTypes } from "../models/MasterData";
import { GlobalVarsModel } from "../models/ModelGlobalsVar";
import { NewBaseService } from "../providers/newBase.service";

export const LicenseGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  try {
    let globalVars!: GlobalVarsModel;
    const newBaseService = inject(NewBaseService);
    const router = inject(Router);
    newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      globalVars = data;
    });
    const requiredLicenses: LicenseTypes[] = route.data["requiredLicenseType"] || [];

    if (requiredLicenses.some(() => requiredLicenses.includes(globalVars.licenseType))) {
      return true;
    } else {
      router.navigate(["/"]);
      return false;
    }
  } catch (e) {
    console.error(e);
    return false;
  }
};
