import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TitleCasePipe } from "@pipes/titlecase/title-case.pipe";

@Component({
  selector: "sb-grid-content-single",
  templateUrl: "./sb-grid-content-single.html",
  styleUrls: ["./sb-grid-content-single.scss"],
  standalone: true,
  imports: [TitleCasePipe, CommonModule, FormsModule],
})
export class SbGridContentSingleComponent {
  @Input() title;
  @Input() subtitle;
  @Input() countryCode: string = null;
  titleBet: string;
}
