import { Injectable, inject } from "@angular/core";
import { SportBookService } from "@sports-services/index";
import { SportLiveMarketService } from "@sports-services/sports.live-market.service";

@Injectable({ providedIn: "root" })
export class SportsbookUtils {
  sportBookService = inject(SportBookService);
  sportLiveMarketService = inject(SportLiveMarketService);

  updateBetsenseData(updatedOddNodes, betsenseData) {
    let updatedBetsenseData = betsenseData;
    if (updatedOddNodes?.length && betsenseData?.length) {
      updatedBetsenseData = updatedBetsenseData.map((betsenseDataItem) => {
        const nodeId = betsenseDataItem.outcomes.find(
          (item) => item.label === betsenseDataItem.delivery.es.log.cta_target,
        ).id;
        const updatedOdd = updatedOddNodes.find((updatedOddNodesItem) => updatedOddNodesItem.Id == nodeId);
        if (updatedOdd) {
          betsenseDataItem.data.offer.outcomes = betsenseDataItem.data.offer.outcomes.map((item) => {
            if (item.id == nodeId) {
              return {
                ...item,
                oddsDecimal: updatedOdd.Odds,
              };
            } else return item;
          });
        }
        return betsenseDataItem;
      });
    }
    return updatedBetsenseData;
  }

  getNodeIdList(betsenseData) {
    const nodeIdList = [];
    betsenseData?.length &&
      betsenseData.forEach((betsenseDataItem) => {
        const nodeId = betsenseDataItem.outcomes.find(
          (item) => item.label === betsenseDataItem.delivery.es.log.cta_target,
        ).id;
        if (nodeId) nodeIdList.push(nodeId);
      });
    return [...new Set(nodeIdList)];
  }

  notEmptyLiveData(event) {
    const newEvent = { ...event };
    newEvent.liveData = newEvent.liveData || {};
    newEvent.liveData.SportHandle = newEvent.SportHandle;
    return newEvent;
  }

  processLiveMarketDataResponse(currentLiveMarketData, prevLiveMarketData, fetchLiveParams, liveMarketPageData) {
    const processedCurrentLiveMarketData = { ...currentLiveMarketData };
    if (!processedCurrentLiveMarketData) return;
    processedCurrentLiveMarketData.Event.Games.forEach((game) => {
      if (!prevLiveMarketData) return;
      const prevGame = prevLiveMarketData.Event.Games.find((pg) => pg.NodeId === game.NodeId);
      if (!prevGame) return;
      this.sportLiveMarketService.updateOddChanges(prevGame, game, "GroupResults");
    });
    processedCurrentLiveMarketData.Event.Games = this.sportLiveMarketService.getCombinedMarkets(
      processedCurrentLiveMarketData.Event.Games,
      fetchLiveParams.market,
    );
    processedCurrentLiveMarketData.Event.Games = this.sportLiveMarketService.getMarketGoalScorer(
      processedCurrentLiveMarketData.Event.Games,
      fetchLiveParams.market,
      true,
    );
    let selectedCategory = liveMarketPageData?.selectedCategory;
    if (!selectedCategory) {
      const selectedCategoryRAW = processedCurrentLiveMarketData.CategoryInfos.find(
        (cat) => cat.CategoryId == fetchLiveParams.categoryInfoId,
      );
      selectedCategory = {
        ...selectedCategoryRAW,
        name: selectedCategoryRAW.CategoryName,
      };
    }
    processedCurrentLiveMarketData.Event = this.sportLiveMarketService.setEvents(
      processedCurrentLiveMarketData.Event,
      selectedCategory,
      fetchLiveParams.market,
    );
    processedCurrentLiveMarketData.Event = this.notEmptyLiveData(processedCurrentLiveMarketData.Event);
    const categories = this.sportLiveMarketService.setLiveMarketCategories(
      processedCurrentLiveMarketData,
      liveMarketPageData.market,
    );
    return { categories, selectedCategory, processedCurrentLiveMarketData };
  }

  transformGameDataForBet(gameData) {
    return {
      betItem: {
        Odd: gameData.Odd,
        SortOrder: gameData.SortOrder,
        IsLive: false,
        upOdd: false,
        downOdd: false,
        IsNonRunner: false,
        SportId: gameData.Parent.Parent.Parent.Parent.Parent.NodeId,
        LocationId: gameData.Parent.Parent.Parent.Parent.NodeId,
        LeagueId: gameData.Parent.Parent.Parent.NodeId,
        EventId: gameData.Parent.Parent.NodeId,
        EventHasHandicap: false,
        GameId: gameData.Parent.NodeId,
        GameTypeId: gameData.Parent.GameTypeId,
        GameSpecialOddsValue: "",
        GameBetTypeAvailability: 0,
        GameNumberOfStarters: 0,
        Name: gameData.NodeName,
        NodeId: gameData.NodeId,
        ParentNodeId: gameData.Parent.NodeId,
        Priority: 0,
        SportHandle: gameData.SportHandle,
        Locked: gameData.Locked,
        Spov: "",
      },
      gameGroup: {
        DisplayTypeName: gameData.Parent.DisplayTypeName,
        Name: gameData.Parent.NodeName,
        NodeId: gameData.Parent.NodeId,
        ParentNodeId: gameData.Parent.Parent.NodeId,
        GameType: gameData.Parent.GameTypeId,
        Priority: gameData.Parent.Priority,
        SportHandle: gameData.Parent.SportHandle,
        GroupResults: [],
        CategoryInfo: gameData.Parent.CategoryInfo,
        CategoryInfos: gameData.Parent.CategoryInfos,
        teamHome: gameData.Parent.Parent.ParticipantHome,
        teamAway: gameData.Parent.Parent.ParticipantAway,
        Spov: "",
        AllSpov: [""],
        SmartMarketAvailable: gameData.Parent.SmartMarketAvailable,
        nameLeague: gameData.Parent.Parent.Parent.NodeName,
        Locked: gameData.Locked,
        isCombined: gameData.IsCombinable,
      },
      name: gameData.Parent.NodeName,
    };
  }
}
