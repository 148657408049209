import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import { GlobalVarsModel } from "@models/ModelGlobalsVar";
// Models
import { I_MyBetsModelTicket } from "@sports-models/index";

// Services
import { NewBaseService } from "@providers/newBase.service";

import { FormatOdd } from "@pipes/formatOdd.pipe";
// Pipes
import { ParseMoney } from "@pipes/parseMoney";
import { CodereFormatDatePipe } from "../../pipes/sports.format-date.pipe";

@Component({
  selector: "my-bets-tile",
  templateUrl: "./my-bets-tile.component.html",
  styleUrls: ["./my-bets-tile.component.scss"],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
  imports: [CommonModule, FormatOdd, ParseMoney, CodereFormatDatePipe, TranslateModule],
})
export class MyBetsTileComponent {
  @Input() ticket: I_MyBetsModelTicket;

  @Output() selectBet: EventEmitter<any> = new EventEmitter();
  @Output() cashout: EventEmitter<any> = new EventEmitter();
  @Output() getCashoutByTicket: EventEmitter<any> = new EventEmitter();
  @Output() goToEvent: EventEmitter<any> = new EventEmitter();

  globalVars!: GlobalVarsModel;
  translate = inject(TranslateService);
  newBaseService = inject(NewBaseService);
  expanded = false;

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  getTicketStatusColor(statusNumber: number) {
    switch (statusNumber) {
      case 0:
        return "color-progress";
      case 1:
        return "color-primary";
      case 2:
        return "color-lost";
      case 3:
        return "color-lost";
      case 4:
        return "color-secondary";
      case 5:
        return "color-progress";
    }
    return "color-primary";
  }

  showTicketStatus(statusNumber: string) {
    return !statusNumber.includes("1");
  }

  hasBetbuilder(BetTypeNumber: string) {
    return +BetTypeNumber < 5;
  }

  payOutTicket(ticket) {
    const params = {
      Ticket: ticket,
      TicketNumber: ticket.TicketNumber,
      Pin: ticket.Pin,
    };
    this.cashout.emit(params);
  }

  getTruncateValue(accumulatorOpened) {
    return accumulatorOpened ? 200 : 37;
  }

  getCashout(ticket: I_MyBetsModelTicket) {
    this.getCashoutByTicket.emit({
      Ticket: ticket,
      TicketNumber: ticket.TicketNumber,
      Pin: ticket.Pin,
    });
  }

  showExpandButton(ticket) {
    return (
      ticket?.BetEvent?.length > 1 ||
      (ticket?.HasBetbuilder && +ticket?.BetTypeNumber != 14) ||
      (ticket?.HasBetbuilder && +ticket?.BetTypeNumber == 14 && ticket?.BetEvent[0]?.BetBuilderItems?.length > 2)
    );
  }

  expandTicket() {
    this.expanded = !this.expanded;
  }

  onClickGoToEvent(ticket) {
    this.goToEvent.emit(ticket);
  }

  public getQuota(event) {
    return event.PotentialWinnings / event.Stake;
  }
}
