/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import { CommonModule } from "@angular/common";
import { Component, ElementRef, EventEmitter, Input, Output, inject } from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "custom-select",
  templateUrl: "./custom-select.html",
  styleUrls: ["./custom-select.scss"],
  standalone: true,
  imports: [CommonModule],
  host: {
    "(document:click)": "onClick($event)",
  },
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CustomSelect {
  @Output() onSelected = new EventEmitter();

  @Input() values;
  @Input() disabled?: boolean = false;
  @Input() placeholder?: boolean = false;

  @Input() selected;

  active = false;
  selectedValue = null;

  _eref = inject(ElementRef);
  constructor() {}

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.desactivateSelect();
    }
  }

  toggleSelect() {
    this.active = !this.active;
  }

  desactivateSelect() {
    this.active = false;
  }

  changeSelectedValue(value) {
    this.selectedValue = value;
    this.selected = value.id;
    this.onSelected.emit(value);
  }
}
