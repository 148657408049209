/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable eqeqeq */
import { CommonModule } from "@angular/common";
import { Component, type ElementRef, ViewChild, inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import {
  IonContent,
  IonicModule,
  MenuController,
  ModalController,
  NavController,
  NavParams,
} from "@ionic/angular";
import { C_UserColombia } from "@models/C_UserColombia";
import { CookieNames } from "@models/CookieNames";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { EventTypes } from "@models/TrackingEvents";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { AppsFlyerTrackingService } from "@providers/AppsFlyerTrackingService";
import { CookiesService } from "@providers/CookiesService";
import { DecouplingUserServiceMSO, ErrorDecoupling } from "@providers/DecouplingUserServiceMSO.service";
import { EventsService } from "@providers/EventsService";
import { I_SignupCoForm } from "@providers/I_SignupCODecoupling";
import { NativeService } from "@providers/NativeService";
import { SlotsBonusesService } from "@providers/SlotsBonusesService";
import { TrackingService } from "@providers/TrackingService";
import { UserService } from "@providers/UserService";
import { UserServiceMSO } from "@providers/UserServiceMSO";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { BackButton } from "@shared-common-components/back-button/back-button";
import { CASINO_PATHS, MSO_PATHS, PAYMENTS_PATHS } from "@shared-constants/routes";
import { CapacitorGetPlatform, SourcesCDR } from "@shared-utils/constants";
import { Utils } from "@utils/Utils";
import { map, tap, zip } from "rxjs";

@Component({
  templateUrl: "./registroCONew.html",
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "registro-page-new-co",
  styleUrls: ["./registroCONew.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, BackButton],
  providers: [NavParams],
})
export class RegistroCONewPage {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild("iframeReg") iframeReg: ElementRef;
  codepromotion: string;
  scrReg: any;
  regListener: any;
  cardNumber: any;
  parameters: any;
  renderIFrame: boolean;
  payMethod: { name: string };
  globalVars!: GlobalVarsModel;
  nameRetry: boolean = false;

  nav = inject(NavController);
  utils = inject(Utils);
  userService = inject(UserService);
  userServiceMSO = inject(UserServiceMSO);
  menu = inject(MenuController);
  translate = inject(TranslateService);
  trackingService = inject(TrackingService);
  navParams = inject(NavParams);
  cookiesService = inject(CookiesService);
  events = inject(EventsService);
  slotsBonusesService = inject(SlotsBonusesService);
  sanitizer = inject(DomSanitizer);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  router = inject(Router);
  viewCtrl = inject(ModalController);
  appsflyerTrackingService = inject(AppsFlyerTrackingService);
  private nativeService = inject(NativeService);

  decouplingServiceMSO = inject(DecouplingUserServiceMSO);

  isMobile = this.deviceService.isMobile();
  isDesktop = this.deviceService.isDesktop();

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    if (this.isMobile) {
      const codereNavBarElement = document.querySelector("codere-navbar") as HTMLElement;
      if (codereNavBarElement) {
        codereNavBarElement.style.display = "none";
      }
    }
    this.renderIFrame = false;
    this.setCardNumber();
    this.payMethod = { name: "Visa" };
    this.parameters = this.utils.getParamStore();

    if (this.parameters && this.parameters.id != null) {
      this.userServiceMSO.getPreRegisterUser(this.parameters.id).subscribe(this.processUserData.bind(this));
    } else {
      // Si no hay parametros se define el contenido del iframe
      this.defineIFrame();
    }

    if (this.parameters && this.parameters.dmt != null) {
      this.parameters.dmt = decodeURIComponent(this.parameters.dmt);
      const payMethod: any = { name: this.parameters.dmt };
      if (this.parameters.amount) {
        payMethod.amount = this.parameters.amount;
      }
      sessionStorage.setItem("payMethod", JSON.stringify(payMethod));
    }

    this.codepromotion = "";

    const tempCode: string = this.cookiesService.getCookie(CookieNames.CoderePromotionCode);
    if (tempCode != "") {
      this.codepromotion = tempCode;
    }
    if (this.globalVars.codePromotion) {
      this.codepromotion = this.globalVars.codePromotion;
      if (this.codepromotion != tempCode) {
        this.cookiesService.setCookie(
          CookieNames.CoderePromotionCode,
          this.codepromotion,
          3 * 24 * 60 * 60 * 1000,
          true,
        );
      }
    }
    if (this.navParams.get("item")) {
      if (this.navParams.get("item").codepromotion) {
        this.codepromotion = this.navParams.get("item").codepromotion;
        if (this.codepromotion != tempCode) {
          this.cookiesService.setCookie(
            CookieNames.CoderePromotionCode,
            this.codepromotion,
            3 * 24 * 60 * 60 * 1000,
            true,
          );
        }
      }
    }
    if (this.navParams.get("codepromotion")) {
      this.codepromotion = this.navParams.get("codepromotion");
      if (this.codepromotion != tempCode) {
        this.cookiesService.setCookie(
          CookieNames.CoderePromotionCode,
          this.codepromotion,
          3 * 24 * 60 * 60 * 1000,
          true,
        );
      }
    }
    this.regListener = this.listenEvents.bind(this);
  } // Fin del constructor

  defineIFrame() {
    this.renderIFrame = true;
    this.scrReg = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.cardNumberParams(this.promoParams(this.setUrl())),
    );
  }
  /**
   * Procesamiento de la respuesta del API con los datos corespondientes
   * al usuario preregistrado (si existían parámetros)
   * En la respuesta a getPreregisterUser
   * comprueba si hay datos repetidos de usuarios para los campos
   * TELEFONO y CORREO
   * @param res
   */
  processUserData(data) {
    const consultas = [];
    console.info("-------------------------------------");
    console.info("Respuesta del servicio Angular");
    console.info(data);

    if (data.email) {
      consultas.push(this.userServiceMSO.checkDataAvailability("CORREO", data.email));
    }
    if (data.mobile) {
      consultas.push(this.userServiceMSO.checkDataAvailability("TELEFONO", data.mobile));
    }

    if (!consultas.length) {
      return;
    }

    zip(...consultas)
      .pipe(
        tap((results) => console.info(results)),
        map((results) => {
          const result = results.some((item: any) => item);
          console.info(result);
          if (!result) {
            // no repetido
            this.defineIFrame();
            localStorage.setItem("Preregistro", JSON.stringify(data));
          } else {
            // Si hay datos repetidos, no cargar nada en el iframe y cerrarlo
            // Hacer una llamada a Acceder
            this.utils.openAccder$.next(true);
            this.closeModal();
          }
        }),
      )
      .subscribe();
  }

  ionViewDidEnter() {
    window.addEventListener("message", this.regListener);
  }

  ionViewWillLeave(event) {
    this.utils.unloadContactChat();
    window.removeEventListener("message", this.regListener);
    this.utils.hideFormAccessoryBar(true);
  }

  backToHome() {
    // this.nav.setRoot(this.globalVars.mainPageComponent);
    this.router.navigate(["/"]);
  }

  ionViewWillEnter() {
    this.utils.hideFormAccessoryBar(false);
    // window.addEventListener('message', this.regListener);
    this.trackingService.trackEvent(["AccessToRegisterFromButton", "", "", "Registro desde boton", "event"]);

    // New tracking services
    this.trackingService.track({ eventType: EventTypes.Lead });

    if (this.isMobile) {
      this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.RegistroCONewPage}`]);
    }
  }

  listenEvents(e: any) {
    switch (e.data.action) {
      case "RegOK":
        this.registroOKEvent(e.data.dest, e.data.param);
        window.removeEventListener("message", this.regListener);
        break;
      case "closeModal":
        this.closeModal();
        window.removeEventListener("message", this.regListener);
        break;
      case "trackEvent":
        this.trackingService.trackEvent(e.data["event"]);
        break;
      case "refresh":
        const iframe: any = document.getElementsByClassName("iframeFullScreen").item(0);
        iframe.style = "display:none";
        iframe.style = "display:block";
        break;
      case "goTop":
        window.scrollTo(0, 0);
        break;
      case "stratIFrame":
        if (this.parameters && this.parameters.dmt) {
          this.payMethod = { name: this.parameters.dmt };
          this.iframeReg.nativeElement.contentWindow.postMessage(this.payMethod, "*");
          // console.dir(this.iframeReg.nativeElement)
        }
        break;
      case "changePage":
        window.removeEventListener("message", this.regListener);
        this.changePageEvent(e.data.page, e.data.link);
        break;
      case "doRegister":
        if (this.globalVars.FEATURES.MSO_NewDecouplingServiceSignup) {
          this.doSignupDecoupling(e.data.user);
        } else {
          this.doRegister(e.data.user);
        }
        break;
      case "nameRetry":
        this.nameRetry = true;
        break;
    }
  }

  doRegister(dataForm: C_UserColombia) {
    dataForm.nameRetry = this.nameRetry;
    this.utils.loader();
    try {
      this.userServiceMSO.registerColombia(dataForm).subscribe((response) => {
        this.utils.closeLoader();
        if (response.status == 0 || response.status == "OK" || response.status == "ok") {
          this.trackingService.trackEvent(["FinishRegister", "OK", dataForm.login, dataForm.email, "event"]);

          if (Capacitor.getPlatform() !== CapacitorGetPlatform.WEB) {
            this.appsflyerTrackingService.trackEvent("af_complete_registration", { userId: dataForm.login });

            // Xtreme Registration event
            this.nativeService.xtremeHitEvent("Registration", "Registration Event");
          }

          this.slotsBonusesService.taboolaTrack("complete_registration");
          this.iframeReg.nativeElement.contentWindow.postMessage({ status: "ok", user: { ...dataForm } }, "*");
          setTimeout(() => {
            const e = {
              data: {
                action: "RegOK",
                dest: "Cashier",
                param: "",
              },
            };
            this.listenEvents(e);
          }, 1500);
        } else {
          let errorMsg: string = "";
          if (response.Message) {
            errorMsg = response.Message || response.errorDescription;
          }
          this.trackingService.trackEvent(["FinishRegister", "KO", response.Message, "Fin Registro KO", "event"]);
          this.iframeReg.nativeElement.contentWindow.postMessage(
            { status: "ko", user: { ...dataForm }, message: errorMsg, errorCode: response.errorCode },
            "*",
          );
        }
      });
    } catch (error) {
      this.utils.closeLoader();
      this.trackingService.trackEvent(["FinishRegister", "KO", "ERR", "Fin Registro KO", "event"]);
      this.iframeReg.nativeElement.contentWindow.postMessage(
        { status: "ko", user: { ...dataForm }, message: "" },
        "*",
      );
    }
  }

  doSignupDecoupling(user: I_SignupCoForm) {
    const iframeElem = this.iframeReg.nativeElement;
    user.source = this.globalVars.isNative ? SourcesCDR.NativeApp : SourcesCDR.WebApp;
    user.nameRetry = this.nameRetry;
    this.decouplingServiceMSO.signupCo(user).subscribe({
      next: ({ bgtUserId }) => {
        this.trackingService.trackEvent(["FinishRegister", "OK", user.playerUserName, user.email, "event"]);

        if (Capacitor.getPlatform() !== CapacitorGetPlatform.WEB) {
          this.appsflyerTrackingService.trackEvent("af_complete_registration", { userId: user.playerUserName });
          // Xtreme Registration event
          this.nativeService.xtremeHitEvent("Registration", "Registration Event");
        }
        this.slotsBonusesService.taboolaTrack("complete_registration");
        iframeElem.contentWindow.postMessage({ status: "signupDecouplingOk", data: { bgtUserId } }, "*");
        setTimeout(() => {
          const e = {
            data: {
              action: "RegOK",
              dest: "Cashier",
              param: "",
            },
          };
          this.listenEvents(e);
        }, 1500);
      },
      error: (err) => {
        const errResponse = err as ErrorDecoupling;

        this.trackingService.trackEvent([
          "FinishRegister",
          "KO",
          errResponse ? errResponse?.code || "ERR" : "ERR",
          "Fin Registro KO",
          "event",
        ]);

        iframeElem.contentWindow.postMessage(
          {
            action: "signupDecouplingKo",
            errorCode: errResponse ? errResponse?.type || -1 : -1,
            message: errResponse ? errResponse?.description || "" : "",
          },
          "*",
        );
      },
    });
  }

  changePageEvent(page: string = "", link: string = "") {
    this.closeModal();
    if (page.length > 0) {
      switch (page) {
        case "HomePage":
        case "Deportes":
          this.router.navigate["/"];
          break;
        case "Login":
          this.events.publish("user:openLogin");
          break;
        case "Locales":
          // this.globalVars.gNav.setRoot(NearestLocalPage, {}, { animate: false });
          this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.NearestLocalPage}`], {
            queryParams: { animate: false },
          });

          break;
        case "Crown":
          const lobby = this.globalVars.FEATURES.Lobbies.find(
            (lb) => lb.LobbyName.toLowerCase().indexOf("playtech") < 0,
          ).LobbyName;
          this.router.navigate([`/${CASINO_PATHS.BASE}/${CASINO_PATHS.CasinoPage}`], { queryParams: lobby });
          break;
        case "CasinoPage":
          this.router.navigate([`/${CASINO_PATHS.BASE}/${CASINO_PATHS.CasinoPage}`]);
          break;
        case "Externo":
          if (this.globalVars.isNative) {
            this.openExternalLinkNative(link);
          } else {
            window.open(link, "_self");
          }
          break;
        default:
          this.globalVars.rootScope.tryToGoPage(page);
      }
    }
  }

  registroOKEvent(dest: string = "", param?) {
    if (this.globalVars.FEATURES.MSO_EnabledComplianceRegistroCO) {
      this.events.publish("callRequestComplianceCO");
    }
    switch (dest) {
      case "Home":
        this.utils.loginAndGoto("/", param, false).then((isOk) => {
          if (isOk) {
            this.closeModal();
          } else {
            this.linkOutOfModalPage(this.router.url || "/", {});
          }
        });
        break;
      case "Cashier":
        this.utils
          .loginAndGoto(`/${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CashierPage}`, param, false)
          .then((isOk) => {
            if (isOk) {
              this.closeModal();
            } else {
              this.linkOutOfModalPage(this.router.url || "/", {});
            }
          });
        break;
      default:
        this.utils
          .loginAndGoto(`/${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CashierPage}`, param, false)
          .then((isOk) => {
            if (isOk) {
              this.closeModal();
            } else {
              this.linkOutOfModalPage(this.router.url || "/", {});
            }
          });
    }
  }

  async linkOutOfModalPage(link: any, paymentArguments: any) {
    if (this.isMobile) {
      this.navigateToLink(link, paymentArguments);
    } else {
      if (this.viewCtrl) {
        this.viewCtrl.dismiss(null, null, { animate: false } as any).then(() => {
          this.navigateToLink(link, paymentArguments);
        });
        this.viewCtrl = null;
      } else {
        this.navigateToLink(link, paymentArguments);
      }
    }
  }
  private navigateToLink(link: any, paymentArguments: any) {
    this.router.navigate([link], {
      queryParams: paymentArguments,
      queryParamsHandling: "merge",
      skipLocationChange: false,
      replaceUrl: false,
      state: { animate: !this.isDesktop },
    });
  }

  loginAndGoto(dest: any, param = null) {
    //checks if there is a current user connected
    this.userService.getCurrentUser().subscribe({
      next: (data) => {
        if (data.sessionOut) {
          this.linkOutOfModalPage(this.globalVars.mainPageComponent, {});
        } else {
          if (data.success) {
            if (data.idBGT) {
              this.globalVars.user.idBGT = data.idBGT;
            }
            //connected
            this.globalVars.user.username = data.username;
            this.globalVars.user.balance = data.balance;
            this.globalVars.user.logged = true;
            this.globalVars.user.userRegion = LicenseTypes.Colombia;
            this.globalVars.licenseType = LicenseTypes.Colombia;
            if (this.globalVars.FEATURES.csrfControlFront) {
              this.globalVars.extraUserData.csrfControl = data["csrfControl"];
            }
            if (this.isMobile) {
              this.globalVars.afterLoginUrl = dest;
            }

            if (this.cookiesService.getCookie(CookieNames.IforiumPromo) == null) {
              this.globalVars.afterLoginParams = param ? param : { name: "VisaBancomer" };
              this.globalVars.afterLoginParamsUrl = this.globalVars.afterLoginParams;
            }

            this.cookiesService.setCookie(CookieNames.CodereUser, data.username, 365 * 24 * 60 * 60 * 1000);

            this.closeModal();
            this.events.publish("user:login");
            if (this.isDesktop) {
              this.events.publish("cashier:open");
            }
            this.events.publish("modalBonusWcCo");
            //
          } else {
            //not connected
            this.linkOutOfModalPage(this.router.url || "/", {});
          }
        }
      },
      error: (err) => {
        //no action
        this.linkOutOfModalPage(this.router.url || "/", {});
      },
    });
  }

  closeModal() {
    this.events.publish("cashier:endEmpty");
    if (this.isDesktop) {
      if (this.viewCtrl) {
        this.viewCtrl.dismiss();
      }
    } else {
      this.router.navigate(["/"]);
    }
  }

  openChat() {
    this.utils.loadContactChat();
    setTimeout(
      () => {
        this.utils.openContactChat();
      },
      this.globalVars.FEATURES.newChatEnabled ? 1 : 800,
    );
  }

  private setCardNumber() {
    const item = this.navParams.get("item") || this.navParams.get("data");
    const cardNumber = this.navParams.get("cardNumber");
    this.cardNumber = item && item.cardNumber ? item.cardNumber : cardNumber ? cardNumber : "";
  }

  private promoParams(url: string): string {
    return this.codepromotion ? url + `&codepromotion=${this.codepromotion}` : url;
  }

  private cardNumberParams(url: string): string {
    return this.cardNumber ? url + `&numbercard=${this.cardNumber}` : url;
  }

  private setUrl(): string {
    return (
      this.globalVars.URLBASE_REGCONEW + "/reg.html?iswebapppc=" + this.isDesktop + "&v=" + new Date().getTime()
    );
  }

  async openExternalLinkNative(link = "") {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const options: any = {
      location: "yes",
      hidden: "no",
      closebuttoncaption: "Cerrar",
      closebuttoncolor: "#ffffff",
      footer: "no",
      footercolor: "#000000",
      hardwareback: "yes",
      hidenavigationbuttons: "no",
      hideurlbar: "yes",
      navigationbuttoncolor: "#ffffff",
      toolbarcolor: "#000000",
      zoom: "yes",
      mediaPlaybackRequiresUserAction: "no",
      shouldPauseOnSuspend: "yes",
      useWideViewPort: "yes",

      toolbar: "yes",
      toolbartranslucent: "yes",
      enableViewportScale: "yes",
      allowInlineMediaPlayback: "yes",
      keyboardDisplayRequiresUserAction: "no",
      suppressesIncrementalRendering: "no",
      presentationstyle: "fullscreen", //pagesheet, formsheet, fullscreen
      transitionstyle: "coververtical", //fliphorizontal, crossdissolve, coververtical
      toolbarposition: "bottom", //top, bottom
      hidespinner: "no",
    };
    await Browser.open({ url: encodeURI(link), windowName: "_system", ...options });
  }
}
