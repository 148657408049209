import { CommonModule } from "@angular/common";
/* eslint-disable */
import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

// MODELS
import { C_TicketApuestasNew, TicketMethods } from "@sports-models/index";
import { NewTicketFacade } from "@sports-stores/ticket";

@Component({
  selector: "betslip-overask",
  templateUrl: "./betslip-overask.component.html",
  styleUrls: ["./betslip-overask.component.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule, FormsModule, TranslateModule],
})
export class BetslipOveraskComponent {
  @Input() ticket: C_TicketApuestasNew;
  @Input() disabled;
  @Input() btnBetText;
  @Output() clickButton = new EventEmitter();

  newTicketFacade = inject(NewTicketFacade);

  betButton = "";

  constructor() {}

  ngOnChanges() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  keepSelections() {
    console.log("keepSelections");
  }
  closeResume() {
    console.log("closeResume");
  }

  clearTicket() {
    console.log("clearTicket");
  }

  placeBet() {
    console.log("placeBet");
  }

  getTicketIsConfirmed(ticket: any): boolean {
    if (ticket != undefined && ticket != null) return TicketMethods.getIsConfirmed(ticket);
    else return false;
  }
}
