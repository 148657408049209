/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * The main component that renders single TabComponent
 * instances.
 */

import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TabComponent } from "./tab.component";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "my-tabs",
  template: `
    <ul class="nav nav-tabs">
      <li *ngFor="let tab of tabs" (click)="selectTab(tab)" [class.active]="tab.active">
        <a>{{ tab.title }}</a>
      </li>
      <li *ngIf="addIcon" class="icongrey">
        <ion-icon name="close-outline" (click)="this.closeModal.emit()"></ion-icon>
      </li>
    </ul>
    <ng-content></ng-content>
  `,
  standalone: true,
  styles: [
    `
      .tab-close {
        color: gray;
        text-align: right;
        cursor: pointer;
      }
    `,
  ],
  styleUrls: ["./tabs.scss"],
  imports: [CommonModule, IonicModule, TabComponent],
})
export class TabsComponent implements AfterViewInit {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @Input() addIcon = false;
  @Output() closeModal: EventEmitter<void>;

  constructor() {
    this.closeModal = new EventEmitter();
  }
  // contentChildren are set
  ngAfterViewInit() {
    // get all active tabs
    const activeTabs = this.tabs.filter((tab) => tab.active);

    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      // this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: any) {
    // deactivate all tabs
    this.tabs.toArray().forEach((t) => (t.active = false));

    // activate the tab the user has clicked on.
    tab.active = true;
  }
}
