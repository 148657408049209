<div class="containerP">
  <div class="texto page1">
    <div class="txtcenter">
      <span>{{'selfExclusionF1tx01' | translate}}</span>
    </div>
    <div class="margint20 txtcenter is-bold">{{'selfExclusionF1tx02' | translate}}</div>
    <div class="formuser margint20">
      <user-form (dataEmitter)="onDataEmitter($event)"> </user-form>
    </div>
    <div class="formtoogle borderdotted">
      <div class="is-bold"><span>{{'selfExclusionF1tx03' | translate}}</span></div>
      <div class="margint20">
        {{'selfExclusionF1tx0401' | translate}} {{city}}, {{'selfExclusionF1tx0402' | translate}}
        {{datetime|date:'HH:mm:ss'}} {{'selfExclusionF1tx0403' | translate}}, {{'selfExclusionF1tx0404' | translate}}
        {{datetime|date:'dd/MM/yyyy'}}, {{'selfExclusionF1tx0405' | translate}}
      </div>
      <div>
        <form [formGroup]="myFormbtn" class="toggle formbtn">
          <ion-toggle
            class="button"
            [formControl]="$any(myFormbtn).controls['toggleGamesInternet']"
            labelPlacement="end"
            justify="start"
          >
            <span class="toggle-text">{{'selfExclusionF1tx05' | translate}}</span></ion-toggle
          >
        </form>
      </div>
    </div>
    <div class="btnContainer">
      <ion-button (click)="goToStep(2)" class="btnorange" [disabled]="!myFormbtn.valid" shape="round">
        {{'selfExclusionP1btn' | translate}} <ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
      </ion-button>
      <ion-button
        (click)="backToHome()"
        class="btnwhite paddingStartEnd77"
        [class.padding2070]="isDesktop"
        shape="round"
      >
        {{'tCancel' | translate }}
      </ion-button>
    </div>
  </div>
</div>
