import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ParseMoney } from "@pipes/parseMoney";
import { UserService } from "@providers/UserService";

@Component({
  selector: "cs-tpaga-info",
  templateUrl: "./cs-tpaga-info.html",
  styleUrls: ["./cs-tpaga-info.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, ParseMoney],
})
export class CsTPagaInfoComponent implements OnInit {
  maxAmount: number = 0;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.userService.getWithdrawalsMethods().subscribe((payMethods) => {
      const tpaga = payMethods.filter((payMethod) => payMethod?.className === "TPagaWithDrawal")[0];
      if (tpaga) {
        this.maxAmount = tpaga?.maxDepositAmount;
      }
    });
  }
}
