import { LineItem } from "./line-item.model";

export class systemBetSlip {
  systemId: number;
  lineItems: LineItem[];
  overallStake: number;
  systemOptions: systemOptions[];
  totalPotentialWinning: number;
  errorMessage: string;

  constructor() {
    this.systemId = null;
    this.lineItems = [];
    this.overallStake = 0;
    this.systemOptions = [];
    this.totalPotentialWinning = 0;
    this.errorMessage = null;
  }
}

export interface systemOptions {
  fractionData: string;
  numberBets: string;
  systemType: number;
  systemSelected: boolean;
}
