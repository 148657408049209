import { CommonModule } from "@angular/common";
import { Component, Input, type OnInit, Renderer2, inject } from "@angular/core";
import { type FormGroup, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import { DomSanitizer, type SafeHtml } from "@angular/platform-browser";
import { ErrorsControls, LabelConfirmControls, Mensajes } from "@interfaces/interfaces";
import { DeviceService } from "@services/device.service";
import { type LegalTerms, LegalTermsServiceService } from "../../../services/registro/legalTerms.service";
import { ControlErrorComponent } from "../control-error/control-error.component";
import { InfoComponent } from "../info/info.component";

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, ControlErrorComponent, InfoComponent, TranslateModule, ReactiveFormsModule],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "cdr-fs-confirmar",
  templateUrl: "./fs-confirmar.component.html",
  styleUrls: ["./fs-confirmar.component.scss"],
})
export class FsConfirmarComponent implements OnInit {
  // Objetos del UI
  errors: ErrorsControls;
  labels: LabelConfirmControls;
  titleSection: string;

  mensajes: Mensajes;
  refCondiciones: string;

  legalTerms: LegalTerms;

  // Arrays de datos
  promoImges: Array<string>;

  // FormControl
  @Input() formGrp: FormGroup;
  formGrpName: string;

  translate = inject(TranslateService);
  legalTS = inject(LegalTermsServiceService);
  sanitizer = inject(DomSanitizer);
  deviceService = inject(DeviceService);
  render2 = inject(Renderer2);

  isMobile: boolean = this.deviceService.isMobile();

  constructor() {}

  ngOnInit(): void {
    this.legalTerms = { url: "", hash: "", code: "" };
    this.legalTS.$legalTerms.subscribe((data) => {
      this.legalTerms = data;
      this.refCondiciones = this.legalTerms.url;
      const aMensajes = [
        this.translate.instant("reg_tyc"),
        ` <a external-link href=${this.refCondiciones} target="_blank">
          ${this.translate.instant("rCond1b")}`,
        this.translate.instant("reg_tyc2"),
        `${this.translate.instant("rCond2b")}</a>`,
      ];

      this.mensajes = {
        confirmText: aMensajes.join(" "),
        comunicsText: this.translate.instant("reg_tycemailNew"),

        /*  infoPromos : `* Al finalizar el registro podrás disfrutar del mejor deporte
            y de nuestros juegos de casino`,
           infoCasino : "* Y además..." */
      };
    });
    this.initializeForm();
    this.formGrpName = "confirmacion";
  } // Fin de ngOnItit

  // ngAfterViewChecked() {
  //   const ionCol = document.querySelector('ion-col');
  //   const ionColA = ionCol.querySelectorAll('a');
  //   ionColA.forEach((a) => {
  //     this.render2.setStyle(a, 'color', '#0056fe');
  //   });
  // }

  private initializeForm(): void {
    this.titleSection = "Confirmación";
    this.labels = this.setLabels();
    this.errors = this.setErrors();
    this.promoImges = [
      "https://www.codere.es/resources/reglite/img/Triple-Bono-codere.png",
      "https://www.codere.es/resources/img/bono10+30.png",
      "https://www.codere.es/resources/img/bonocasino.png",
    ];
  } // Fin de initializeForm

  private setLabels(): LabelConfirmControls {
    return {
      confirm: "....",
    };
  }

  private setErrors(): ErrorsControls {
    return {
      confirm: {
        required: this.translate.instant("error_form_check_ct"),
        requiredTrue: this.translate.instant("error_form_check_ct"),
      },
    };
  }

  public onChangePrp($event) {
    this.formGrp.get("PRP").setValue(!$event.target.checked);
  }
  get safeConfirmText(): SafeHtml {
    return this.mensajes.confirmText;
  }

  get safeComunicsText(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.mensajes.comunicsText);
  }
}
