import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { LanguagePage } from "@shared-mso-pages/language/language";
import { MyCardPage } from "@shared-mso-pages/my-card/my-card";
import { PersonalInfoPage } from "@shared-mso-pages/personal-info/personal-info";
import { HelpPage } from "../pages/ayuda/ayuda";
import { ContactPage } from "../pages/contact/contact/contact";
import { CookiesConsentPage } from "../pages/cookies-consent/cookies-consent";
import { RegistroARPage } from "../pages/registroAR/registroAR";
import { RegistroCONewPage } from "../pages/registroCONew/registroCONew";
import { RegistroESNewPages } from "../pages/registroESNew";
import { RegistroPAPage } from "../pages/registroPA/registroPA";
import { SelfExclusionPage } from "../pages/selfExclusion/selfExclusion";

@Component({
  selector: "codere-ionic-mso",
  standalone: true,
  imports: [
    CommonModule,
    RegistroESNewPages,
    RegistroCONewPage,
    RegistroPAPage,
    RegistroARPage,
    SelfExclusionPage,
    ContactPage,
    HelpPage,
    PersonalInfoPage,
    LanguagePage,
    MyCardPage,
    CookiesConsentPage,
  ],
  templateUrl: "./mso.component.html",
  styleUrls: ["./mso.component.scss"],
})
export class MsoComponent {}
