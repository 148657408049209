import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class IonLoaderService {
  isLoading = false;

  constructor(public loadingController: LoadingController) {}

  async showHideAutoLoader() {
    this.isLoading = true;
    return this.loadingController
      .create({
        spinner: "crescent",
        backdropDismiss: false,
        cssClass: "is-loader",
        translucent: true,
        duration: 2000,
      })
      .then((a) => {
        a.present().then(() => {
          if (!this.isLoading) {
            a.dismiss().then(() => console.log("abort presenting"));
          }
        });
      });
  }

  async hideLoader() {
    this.isLoading = false;
    return this.loadingController.dismiss().then(() => console.log("dismissed"));
  }
}
