/* eslint-disable eqeqeq */
import { Injectable, inject } from "@angular/core";

import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";

import { BaseService } from "./BaseService";
import { LogService } from "./LogService";
import { ScriptService } from "./ScriptService";

import { CookieNames } from "../models/CookieNames";
import { LicenseTypes } from "../models/MasterData";

import { EventTypes } from "../models";

declare const initCustomInsightsTrackers: any;
declare const mainCommonJS: any;
declare let DY: any;
declare let DYO: any;
/**
 * Cookies service, provides methods to manage app cookies
 */

@Injectable({ providedIn: "root" })
export class CookiesService extends BaseService {
  public readonly YEAR_MILLISECONDS: number = 31536000000; //365 * 24 * 60 * 60 * 1000

  //#region Inject Services
  logService = inject(LogService);
  scriptService = inject(ScriptService);
  translateService = inject(TranslateService);
  //#endregion
  constructor() {
    super();
  }

  /**
   * Sets the value of a cookie
   * @param cookieName Cookie name (from the enum)
   * @param value Cookie value
   * @param expiresInMs Time to expire in millisecond
   * @param setDomain Sets if the domain must be set or not
   */
  setCookie(cookieName: CookieNames, value: string, expiresInMs: number, setDomain = false): void {
    this.setCookieValue(cookieName, value, expiresInMs, setDomain);
  }

  /**
   * Sets the value of a cookie for a specific user (the current logged user)
   * @param cookieName Cookie name (from the enum)
   * @param value Cookie value
   * @param expiresInMs Time to expire in millisecond
   * @param setDomain Sets if the domain must be set or not
   */
  setCookiePerUser(cookieName: CookieNames, value: string, expiresInMs: number, setDomain = false): void {
    if (this.globalVars.user.logged)
      this.setCookieValue(this.getCookieNamePerUser(cookieName), value, expiresInMs, setDomain);
    else this.logService.error("setCookiePerUser: user not logged");
  }

  /**
   * Gets the value of a cookie
   * @param cookieName Cookie name (from the enum)
   */
  getCookie(cookieName: CookieNames | string): string {
    return this.getCookieValue(cookieName);
  }

  /**
   * Gets the value of a cookie for a specific user (the current logged user)
   * @param cookieName Cookie name (from the enum)
   */
  getCookiePerUser(cookieName: CookieNames): string {
    if (this.globalVars.user.logged) return this.getCookieValue(this.getCookieNamePerUser(cookieName));
    else {
      this.logService.error("getCookiePerUser: user not logged");
      return "";
    }
  }

  /**
   * Removes a cookie
   * @param cookieName Cookie name (from the enum)
   */
  removeCookie(cookieName: CookieNames, setDomain = false) {
    this.removeCookieValue(cookieName, setDomain);
  }

  /**
   * Removes a cookie for a specific user (the current logged user)
   * @param cookieName Cookie name (from the enum)
   */
  removeCookiePerUser(cookieName: CookieNames) {
    if (this.globalVars.user.logged) this.removeCookieValue(this.getCookieNamePerUser(cookieName));
    else this.logService.error("setCookiePerUser: user not logged");
  }

  async translateString(key: string): Promise<string> {
    return new Promise((res) => {
      this.translateService.get(key).subscribe((value) => res(value));
    });
  }

  /**
   * Returns the cookie information message, which includes the URL for the cookie information page
   * for the given license
   * @param licenseType
   */
  async getCookieInfoMessage(licenseType: LicenseTypes): Promise<string> {
    if (licenseType == 0) {
      const html =
        '<b class="boldwhite">' +
        (await this.translateString("uso_cookiesT")) +
        "</b>" +
        "<br>" +
        (await this.translateString("uso_cookiesES")) +
        "&nbsp;" +
        (await this.translateString("uso_cookiesES03")) +
        '&nbsp;<a href="' +
        this.getCookieInfoUrl(licenseType) +
        '" target="_blank">' +
        (await this.translateString("ftCookies")) +
        "</a>. " +
        (await this.translateString("uso_cookiesEStxtchrome"));
      return html;
    } else {
      return (
        (await this.translateString("uso_cookies")) +
        '&nbsp;<a href="' +
        this.getCookieInfoUrl(licenseType) +
        '" target="_blank">' +
        (await this.translateString("ftCookies")).toUpperCase() +
        "</a>"
      );
    }
  }

  /**
   * Returns the title for the cookie information message
   */
  getCookieInfoTitle(): Promise<string> {
    return this.translateString("uso_cookiesT");
  }

  /**
   * Returns the url of the cookie information page for the given license
   */
  getCookieInfoUrl(licenseType: LicenseTypes): string {
    switch (licenseType) {
      case LicenseTypes.Mexico:
        return "https://www.codere.mx/informaci%C3%B3n-legal/aviso-de-privacidad";
      case LicenseTypes.Colombia:
        return "https://www.codere.com.co/Paginas/Pol%C3%ADtica-de-cookies.aspx";
      case LicenseTypes.Panama:
        return "https://www.codere.pa/Paginas/Pol%C3%ADtica-de-cookies.aspx";
      case LicenseTypes.ArgentinaCaba:
        return "https://www.codere.bet.ar/informaci%C3%B3n-legal/politica-de-cookies";
      case LicenseTypes.ArgentinaPBA:
        return "https://www.codere.bet.ar/informaci%C3%B3n-legal/politica-de-cookies";
      default:
        return "https://www.codere.es/informaci%C3%B3n-legal/politica-de-cookies";
    }
  }

  /**
   *
   * @param consentOptions : {
   *              customOK : boolean,
   *              analyticsOK : boolean,
   *              advertismentOK : boolean}
   */
  setCookieConsent(consentOptions: { customOK: boolean; analyticsOK: boolean; advertismentOK: boolean }) {
    this.globalVars.isCookiesAccept = true;
    if (this.globalVars.FEATURES.MSO_alertCookiesNoBlock) {
      if (this.globalVars.actionAfterCookiesAccept) {
        switch (this.globalVars.actionAfterCookiesAccept) {
          case "login":
            this.globalVars.rootScope.openLogin(this.globalVars.callbackAfterLoginAfterCookiesAccep);
            break;
          case "reg":
            this.globalVars.rootScope.openRegistro();
            break;
        }
        this.globalVars.actionAfterCookiesAccept = null;
      }
    }

    localStorage.setItem("cookieConsent", JSON.stringify(consentOptions));
    const trackingDataCookies = {
      necessary: true,
      marketing: consentOptions.advertismentOK,
      analytics: consentOptions.analyticsOK,
      preferences: consentOptions.customOK,
    };
    this.globalVars.rootScope.trackingService.track({
      eventType: EventTypes.CookieConsentGoogle,
      additionalData: trackingDataCookies,
    });
    this.setCookie(
      CookieNames.ConsentStatusCookies,
      JSON.stringify(consentOptions),
      365 * 24 * 60 * 60 * 1000,
      true,
    );

    //UPDATE SPAIN DY COOKIES
    if (this.globalVars.licenseType == LicenseTypes.Nacional) {
      this.activateCookies("DY", true, consentOptions.analyticsOK);
    }

    this.setCookie(CookieNames.CodereCookie, "accepted", 365 * 24 * 60 * 60 * 1000, true);
    if (consentOptions.customOK || consentOptions.analyticsOK || consentOptions.advertismentOK) {
      // Si se ha aceptado algún bloque de cookies,
      // se ejecutan las funciones principales
      // de los scripts invocados desde index.html
      initCustomInsightsTrackers(consentOptions);
      mainCommonJS(consentOptions);
    }
  }

  /* Private methods */

  private setCookieValue = (cookieName: string, value: string, expiresInMs: number, setDomain = false): void => {
    const customCookies = ["idioma", "cuota", "defaultDepositOptionSelected", "defaultChargeOptionSelected"];
    const advertismentCookies = ["banner_click", "ifr_bns"];
    if (!this.globalVars.COOKIES.customOK && customCookies.find((item) => item == cookieName)) {
      console.error("No se puede crear", cookieName);
      return;
    }

    if (!this.globalVars.COOKIES.advertismentOK && advertismentCookies.find((item) => item == cookieName)) {
      console.error("No se puede crear", cookieName);
      return;
    }

    let dominio = "";
    if (setDomain) {
      if (this.globalVars.DEVICE_TYPE != "cordova") {
        dominio = this.getDomainSuffix();
      }
    }
    const r: Date = new Date();
    r.setTime(r.getTime() + expiresInMs);

    if (dominio && !location.host.includes("localhost"))
      document.cookie =
        cookieName +
        " = " +
        encodeURI(value) +
        (expiresInMs == null ? "" : "; expires = " + r.toUTCString()) +
        "; domain=" +
        dominio +
        "; path=/";
    else
      document.cookie =
        cookieName +
        " = " +
        encodeURI(value) +
        (expiresInMs == null ? "" : "; expires = " + r.toUTCString()) +
        "; path=/";
  };

  private getCookieValue = (cookieName: string): string => {
    let r: string,
      u: string,
      f: string = null;
    const t: Array<string> = document.cookie.split(";");
    for (let i = 0; i < t.length; i++)
      (u = t[i].substr(t[i].indexOf("=") + 1)),
        (r = t[i].substr(0, t[i].indexOf("=")).replace(/^\s+|\s+$/g, "")),
        r == cookieName && (f = decodeURI(u));

    return f;
  };

  /** Gets the suffix (last two parts) of the hostname, to set the cookie domain. Or the whole name, in case of localhost.
   * For example:
   * for "d.m.codere.com" returns ".codere.com"
   * for "localhost", "localhost"
   */
  private getDomainSuffix = (): string => {
    let host = document.location.host;
    let suffix = host; // For the case without dots, like localhost
    // Takes the text from the last point, if it exists
    let i = host.lastIndexOf(".");
    if (i > 0) {
      suffix = host.substr(i);
      host = host.substring(0, i); // removes the last part from the host
    }
    i = host.lastIndexOf(".");
    if (i > 0) {
      suffix = host.substr(i) + suffix;
      host = host.substring(0, i);
    }
    if (suffix == ".com.co" || suffix == ".com.ar" || suffix == ".bet.ar") {
      i = host.lastIndexOf(".");
      if (i > 0) {
        suffix = host.substr(i) + suffix;
      }
    }
    return suffix;
  };

  private removeCookieValue = (cookieName: string, setDomain = false): void => {
    let dominio = "";
    if (setDomain) {
      if (this.globalVars.DEVICE_TYPE != "cordova") {
        dominio = "domain=" + this.getDomainSuffix() + ";";
      }
    }
    document.cookie = cookieName + "=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;" + dominio;
  };

  private getCookieNamePerUser = (cookieName: CookieNames): string =>
    `${cookieName}_${this.globalVars.user.username}`;

  /**
   * Response to the Apple Intelligent Tracking Prevention (ITP).
   * Solicita al backend la creación de una copia de la cookie _dyid
   * renombrada como _dyid_server
   */
  public appleITP() {
    // http://localhost/Codere.SBGOnline.LoginServices.WebApi/cookie/renamekey
    // this.globalVars.UrlBase = "http://dm.apuestas.codere.es/csbgonline/
    // this.globalVars.DomainBase = http://dm.apuestas.codere.es
    // this.globalVars.UrlBaseLoginServices = http://dm.apuestas.codere.es/LoginServicesApi
    // API Base /Codere.SBGOnline.LoginServices.WebApi
    // API URL '/LoginServicesEsApi/cookie/renamekey
    // API request for RenameKey:
    // string regExKey, "_dyid"
    // string endRenameKey, "_server"
    // bool replace, false
    // string addValue, ";maxage=31556951"

    if (!this.globalVars.COOKIES.analyticsOK) {
      // Analytic Cookies not allowed by user
      return;
    }

    if (this.getCookie("_dyid_server")) {
      // console.log('AppleITP previous', true)
      return;
    }

    const api_url = "/cookie/renamekey";
    const url = this.globalVars.URLBASE_LOGINSERVICES + api_url;
    this.myPost(
      url,
      {
        regExKey: "_dyid",
        endRenameKey: "_server",
        oneYear: true,
        replace: false,
        addValue: ";maxage=31556951",
      },
      null,
      false,
      (err: any, caught: Observable<any>): any => {},
    ).subscribe(
      // response => console.log('AppleITP new', response)
    );
  }

  activateCookies(provider: string, firstData: boolean, data?: any) {
    switch (provider) {
      case "DY":
        //todo: sin activar las cookies
        DY.userActiveConsent = { accepted: data };
        localStorage.setItem("userActiveConsent", data?.toString());

        Promise.all([
          this.scriptService.loadScript("DYApiDynamic"),
          this.scriptService.loadScript("DYApiStatic"),
        ]).then(() => {
          if (firstData) {
            DYO?.ActiveConsent.updateConsentAcceptedStatus(data);
          } else if (
            !localStorage.getItem("userActiveConsent") ||
            localStorage.getItem("userActiveConsent") == "false" ||
            (!this.globalVars.FEATURES.MSO_NewCheckCookieConsent && !this.getCookie(CookieNames.CodereCookie)) ||
            (this.globalVars.FEATURES.MSO_NewCheckCookieConsent &&
              !this.getCookie(CookieNames.ConsentStatusCookies))
          ) {
            DYO?.ActiveConsent.updateConsentAcceptedStatus(false);
          }
        });
        break;
      default:
        break;
    }
  }
}
