/* eslint-disable */
import { OxxoDepositActionResult } from "@models/C_Transactions";
import { ErrorService } from "@providers/ErrorService";
import { UserService } from "@providers/UserService";
import { Observable, map } from "rxjs";
import { PaymentMethodBehaviour, RedirectMethodBehaviourResult, type SkipAmountResult } from "./PaymentMethodBase";

export class PSEOxxoBankPaymentMethodBehaviour extends PaymentMethodBehaviour {
  name: string;
  image: string;
  track: string;
  isBank: boolean;
  minDepositAmount: number;
  maxDepositAmount: number;
  amountButtons: number[];
  type: string;

  constructor(
    public userService: UserService,
    type: string,
    public errorService: ErrorService,
  ) {
    super(false, userService, errorService);
    this.type = type;
    this.documentNumberRequired = false;
    this.isBank = false;
    this.amountButtons = [300, 400, 800, 1000];
    this.minDepositAmount = 100;
    this.maxDepositAmount = 999999999;
    this.track = "AccessToOxxoPSE";
    this.amount = 400;
    this.name = "OxxoBank-PSE";
    this.image = "Spe";
  }

  skipAmountInput(): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  paymentMetodExecution(): Observable<RedirectMethodBehaviourResult> {
    return this.userService.validateDepositOxxoBank("", this.amount, this.documentNumber, this.type).pipe(
      map((obj) => {
        return this.map(obj);
      }),
    );
  }

  private map(input: OxxoDepositActionResult): RedirectMethodBehaviourResult {
    const result = new RedirectMethodBehaviourResult();
    if (input.success) {
      result.success = true;
      result.url = input.url;
      result.success = true;
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams(
        "error" + input.errCode,
        input.errorParameters,
      );
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode == 174 || input.errCode == 2212;
    }
    return result;
  }
}
