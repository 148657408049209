import { CommonModule } from "@angular/common";
/* eslint-disable */
import { Component, EventEmitter, OnInit, Output, inject } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { UserService } from "@providers/UserService";
import { UserServiceMSO } from "@providers/UserServiceMSO";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { UserFormComponent } from "../user-form/user-form";

@Component({
  selector: "cdr-step-form-se",
  templateUrl: "./step-form-se.html",
  styleUrls: ["./step-form-se.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, ReactiveFormsModule, UserFormComponent],
})
export class StepFormSeComponent implements OnInit {
  @Output() changeStep: EventEmitter<number>;
  @Output() cityEmit: EventEmitter<string>;
  text: string;
  textButton0: string;
  textButton1: string;
  myFormbtn: FormGroup;
  city: string = "";
  resType: Array<{ label: string; value: number }>;
  datetime: Date;
  globalVars!: GlobalVarsModel;
  isDesktop: boolean = false;

  userService = inject(UserService);
  userServiceMSO = inject(UserServiceMSO);
  formbuilder = inject(FormBuilder);
  translate = inject(TranslateService);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  router = inject(Router);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.isDesktop = this.deviceService.isDesktop();
    this.datetime = new Date();
    this.text = "AutoExclusión - Step1";
    this.textButton0 = "Anterior";
    this.textButton1 = "Siguiente";

    this.myFormbtn = this.formbuilder.group({
      toggleGamesInternet: [false, Validators.compose([Validators.required, Validators.requiredTrue])],
    });
    this.resType = [
      { label: this.translate.instant("rCoDocumentTypeCC"), value: 0 },
      { label: this.translate.instant("rCoDocumentTypeCE"), value: 1 },
    ];
    this.changeStep = new EventEmitter();
    this.cityEmit = new EventEmitter();
  }

  ngOnInit(): void {
    this.myFormbtn.get("toggleGamesInternet").valueChanges.subscribe((value) => {});
  }
  onDataEmitter(data: { city: string }) {
    this.city = data.city;
    this.cityEmit.emit(this.city);
  }

  goToStep(n) {
    this.changeStep.next(n);
  }
  backToHome() {
    this.router.navigate(["/"], { queryParams: { animate: false } });
  }
}
