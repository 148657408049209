import { createReducer, on } from "@ngrx/store";
import { I_MyBetsModel } from "@sports-models/index";
import * as MyBetsActions from "./my-bets.actions";

export interface MyBetsState {
  myBetsTickets: I_MyBetsModel[];
  initialStatus: boolean;
}

export const initialState: MyBetsState = {
  myBetsTickets: [],
  initialStatus: true,
};

export const myBetsReducer = createReducer(
  initialState,
  on(MyBetsActions.SetMyBets, (state, myBetsTickets) => ({
    ...state,
    initialStatus: false,
    myBetsTickets: myBetsTickets.myBetsTickets,
  })),
);
