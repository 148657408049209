import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ElementRef, Input, OnChanges, Renderer2, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ExtractDatePipe } from "@pipes/extractDate.pipe";

@Component({
  selector: "sb-grid-header",
  templateUrl: "./sb-grid-header.html",
  standalone: true,
  imports: [CommonModule, FormsModule, ExtractDatePipe],
  styleUrls: ["./sb-grid-header.scss"],
})
export class SbGridHeaderComponent implements OnChanges, AfterViewInit {
  @ViewChild("header") header: ElementRef;
  @Input() mode: string;
  @Input() market: any;
  @Input() headerTitles: string[];
  @Input() titleLeft: string;
  @Input() titleRight: string;
  @Input() titleCenter: string;
  @Input() currentPage: string;
  public hasCenterTitle: boolean = false;
  constructor(
    private _element: ElementRef,
    private _renderer: Renderer2,
  ) {}

  ngOnChanges() {
    if (Array.isArray(this.market)) {
      this.setMarketHeaders();
      this.mode = "market-names";
      return;
    }

    if (!this.titleCenter) {
      return;
    }
    this.getIsVisibleCenterTitle(this.market);
  }

  ngAfterViewInit() {
    if (this.currentPage) this._renderer.addClass(this._element.nativeElement, this.currentPage);
    const mode = this.market && this.market.headerClass ? this.market.headerClass : `sb-grid-header--${this.mode}`;
    if (!mode) {
      return;
    }
    this._renderer.addClass(this.header.nativeElement, mode);
  }

  private getIsVisibleCenterTitle(market) {
    if (!market) {
      return;
    }

    this.hasCenterTitle =
      market.DisplayTypeName === "3way" ||
      market.DisplayTypeName === "3buttonlist" ||
      market.DisplayTypeName === "6buttonlist" ||
      this.market.isCombined;
  }

  //remove
  private setMarketHeaders() {
    const names = [];
    this.market.forEach((mkt) => {
      mkt.Games.forEach((game) => names.push(game.Name));
    });
    this.headerTitles = Array.from(new Set(names)).filter(Boolean);
  }
}
