import { CommonModule, Location } from "@angular/common";
import { Component, inject } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { NewBaseService } from "@providers/newBase.service";

@Component({
  selector: "back-button",
  imports: [CommonModule],
  standalone: true,
  templateUrl: "./back-button.html",
})
export class BackButton {
  oldSizePool: number = 0;
  isPush: boolean = false;
  isPop: boolean = false;
  globalVars!: GlobalVarsModel;

  newBaseService = inject(NewBaseService);
  location = inject(Location);
  router = inject(Router);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  ngOnInit() {
    this.oldSizePool = this.router.config.length;
  }

  goBack() {
    this.location.back();
  }

  checkButtonActive(): boolean {
    // pdoriamos modificarlo por includes ->  if (this.router.url.includes('/SlotsPage') || this.router.url.includes('/CasinoPage'))
    // o por match: buscara si la URL actual contiene /SlotsPage o /CasinoPage en cualquier parte de la cadena
    if (this.router.url.match(/\/(SlotsPage|CasinoPage)/)) {
      return false;
    } else {
      if (this.oldSizePool === 2) {
        return false;
      } else {
        return true;
      }
    }
  }
}
