import { Injectable, inject } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from "@ngrx/effects";
import { Subject, catchError, map, of, switchMap, withLatestFrom } from "rxjs";

// Store
import { Store } from "@ngrx/store";
import * as TicketActions from "./ticket.actions";
import { ticketState } from "./ticket.reducers";
import * as ticketSelector from "./ticket.selectors";

// Models
import { GlobalVarsModel } from "@models/index";
import {
  C_OveraskDataBetPlacement,
  C_TicketApuestasNew,
  CombinationMulticastInput,
  FilterItem,
  LineItem,
  SingleItemInput,
  SmartMarketInput,
  StakeGroup,
  StakeModel,
  StraightMulticastInput,
  TicketMethods,
  mapSingleResult,
  removeTicket,
  systemData,
} from "@sports-models/index";

import { BalancesService } from "@shared-providers/BalancesService";
// Service
import { EventsService } from "@shared-providers/EventsService";
import { TrackingService } from "@shared-providers/TrackingService";
import { UserService } from "@shared-providers/UserService";
import { NewBaseService } from "@shared-providers/newBase.service";
import { DeviceService } from "@shared-services/device.service";
import { BetSlipService, MobileBehaviorService, TicketService, XStateService } from "@sports-services/index";

// Utils
import { Utils } from "@shared-utils/Utils";
import { BetslipLineItem, BetslipState, BetslipStateInitial } from "@sports-models/index";
import { NewTicketFacade } from "@sports-stores/ticket/index";
import { BetslipStoreUtils } from "../../utils/betslip.utils";

@Injectable({ providedIn: "root" })
export class TicketEffects {
  auxCloseBet: any;
  auxCloseBetTicket: C_TicketApuestasNew;
  auxGroupKey: any = null;
  auxLineItem: SingleItemInput;
  auxRacingLineItem: StraightMulticastInput | CombinationMulticastInput;
  auxRemovedItem: any = null;
  auxRemovedItems: any = null;
  auxSmartLineItem: SmartMarketInput;
  canBeEachWayItems: any = null;
  defaultAmount: number[] = [];
  multiSingleCounter: number = 0;
  multiSingleStake: StakeModel;
  noEachWayItems: any = null;
  overaskData: C_OveraskDataBetPlacement = new C_OveraskDataBetPlacement();
  overaskStatus$: Subject<boolean> = new Subject<boolean>();
  singlesStaked: boolean = false;
  stopOveraskPolling: boolean = false;
  systemSetStake: systemData = null;
  systemStaking: boolean = false;
  globalVars!: GlobalVarsModel;

  actions$ = inject(Actions);
  betSlipService = inject(BetSlipService);
  xStateService = inject(XStateService);
  newBaseService = inject(NewBaseService);
  userService = inject(UserService);
  ticketService = inject(TicketService);
  store = inject(Store<ticketState>);
  utils = inject(Utils);
  events = inject(EventsService);
  mobileBehavior = inject(MobileBehaviorService);
  _trackingService = inject(TrackingService);
  _balanceService = inject(BalancesService);
  deviceService = inject(DeviceService);
  alertController = inject(AlertController);
  betslipUtils = inject(BetslipStoreUtils);
  newTicketFacade = inject(NewTicketFacade);

  betslipState: BetslipState = BetslipStateInitial;

  mappedLineItem: BetslipLineItem;

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  /**
   * initial Effect
   * triggers on Effects initialization
   */
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.NoAction | TicketActions.RecoverTicket>(ROOT_EFFECTS_INIT),
      map((action) => {
        sessionStorage.setItem("licenseType", this.globalVars.licenseType.toString());
        this.xStateService.startBetslip();

        const XTicketState = localStorage.getItem("X-Ticket-State");
        let ticketBetslip: ticketState;
        if (XTicketState) {
          ticketBetslip = JSON.parse(XTicketState);

          if (TicketMethods.getHasFreebet(ticketBetslip.ticket))
            return new TicketActions.RemoveTicket(new removeTicket());
          const ticketItemsAndAmount = {
            lineItems: [
              ...ticketBetslip.ticket.LineItems,
              ...ticketBetslip.ticket.SmartLineItems,
              ...ticketBetslip.ticket.StraightMulticast,
              ...ticketBetslip.ticket.CombinationMulticast,
            ],
            amount: this.betslipUtils.defaultOverallStake / 100,
          };
          if (ticketItemsAndAmount.lineItems.length > 0)
            return new TicketActions.RecoverTicket(ticketBetslip, ticketItemsAndAmount);
        }
        return new TicketActions.NoAction();
      }),
    ),
  );

  /**
   * Add a single Item to ticket
   * @returns Stake | Error
   */
  addSingleResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.ADD_SINGLE_RESULT),
      switchMap((data: TicketActions.AddSingleResult) => {
        this.auxLineItem = mapSingleResult(data.game);

        this.mappedLineItem = this.betslipUtils.BetslipMapSingleResult(data.game);
        this.betslipState = {
          ...this.betslipState,
          ProviderResponse: null,
          Processing: {
            From: "addSingleResult",
            Item: this.auxLineItem,
          },
          LineItems: [...this.betslipState.LineItems, this.mappedLineItem],
        };

        console.log("this.betslipState", this.betslipState);

        return this.betSlipService.addSingleResult(this.auxLineItem).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get("X-State"));
            const autoStake: StakeModel = this.betslipUtils.autoSingleStake(response.body, this.auxLineItem);

            // this.mappedLineItem = this.betslipUtils.addItemNumberToLineItem(this.mappedLineItem, response.body);

            // this.betslipState = {
            //   ...this.betslipState,
            //   ProviderResponse: response.body,
            //   LineItems: this.betslipUtils.addItemNumbersToLineItems(this.betslipState.LineItems, response.body);
            // };

            this._trackingService.trackEvent([
              "", // todo this.auxLineItem.Game.IsInPlay ? 'addBetLive' : 'addBet',
              "", // todo this.auxLineItem.ResultId.toString(),
              window.location.hash,
              "", // todo this.auxLineItem.Game.IsInPlay ? 'A\u00F1adir apuesta en directo' : 'A\u00F1adir apuesta anticipada',
              "event",
            ]);

            return new TicketActions.SetStake(autoStake);
          }),
          catchError((_) => of(new TicketActions.Error("Error Ocurred addSingleResult BetSlipService"))),
        );
      }),
    ),
  );

  /**
   * Add a single Item to ticket By Id
   * @returns Stake | Error
   */

  addSingleResultById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.ADD_SINGLE_RESULT_BY_ID),
      switchMap((data) => {
        if (this.newTicketFacade.checkIfBetExistsInTicket(data.betId) === -1) {
          return this.ticketService.getEventByNodeV2(data.betId).pipe(
            map((response: any) => {
              if (response.ResultsNr) {
                let lineItem: any = null;
                if (response.length != undefined && response.length > 0) {
                  lineItem = LineItem.mapItemFromIdService(response[0].Value);
                } else {
                  lineItem = LineItem.mapItemFromIdServiceV2(response);
                }
                this.auxLineItem = mapSingleResult(lineItem);
                return new TicketActions.AddSingleResult(lineItem);
              } else {
                return new TicketActions.Error("Error, addSingleResultById discarted");
              }
            }),
            catchError((err) => {
              return of(new TicketActions.Error("Error Ocurred addSingleResultById ticketService"));
            }),
          );
        } else return of(new TicketActions.Error("Error, addSingleResultById discarted"));
      }),
    ),
  );

  /**
   * Add a SmartMarket Item to ticket
   * @returns Stake | Error
   */
  addSmartMarketResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.ADD_SMARTMARKET_ITEM),
      switchMap((data: TicketActions.AddSmartMarketItem) => {
        this.auxSmartLineItem = data.smartMarketBet;
        return this.betSlipService.addSmartMarketItem(data.smartMarketRequest).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get("X-State"));
            const autoStake: StakeModel = this.betslipUtils.autoSmartSingleStake(response.body);

            this._trackingService.trackEvent([
              // ! COMENTADO EN REPO CODERE
              "addSmartMarket", // todo this.auxLineItem.Game.IsInPlay ? 'addBetLive' : 'addBet',
              "", // todo this.auxLineItem.ResultId.toString(),
              window.location.hash,
              "", // todo this.auxLineItem.Game.IsInPlay ? 'A\u00F1adir apuesta en directo' : 'A\u00F1adir apuesta anticipada',
              "event",
            ]);

            return new TicketActions.SetStake(autoStake);
          }),
          catchError((_) => of(new TicketActions.Error("Error Ocurred addSmartMarketResult BetSlipService"))),
        );
      }),
    ),
  );

  /**
   * Add a CombinationMulticast Item to ticket
   * @returns Stake | Error
   */
  addStraightMulticast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.ADD_STRAIGHT_MULTICAST),
      switchMap((data: TicketActions.AddStraightMulticast) => {
        this.auxRacingLineItem = data.straightMulticastInput;
        return this.betSlipService.addStraightMulticast(data.straightMulticastInput).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get("X-State"));
            const autoStake: StakeModel = this.betslipUtils.autoStraightMulticastStake(response.body);

            this._trackingService.trackEvent([
              "addStraightMulticast", // todo this.auxLineItem.Game.IsInPlay ? 'addBetLive' : 'addBet',
              "", // todo this.auxLineItem.ResultId.toString(),
              window.location.hash,
              this.auxRacingLineItem.Game.IsInPlay
                ? "A\u00F1adir apuesta en directo"
                : "A\u00F1adir apuesta anticipada",
              "event",
            ]);

            return new TicketActions.SetStake(autoStake);
          }),
          catchError((_) => of(new TicketActions.Error("Error Ocurred addStraightMulticast BetSlipService"))),
        );
      }),
    ),
  );

  /**
   * Add a StraightMulticast Item to ticket
   * @returns Stake | Error
   */
  addCombinationMulticast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.ADD_COMBINATION_MULTICAST),
      switchMap((data: TicketActions.AddCombinationMulticast) => {
        this.auxRacingLineItem = data.combinationMulticastInput;
        return this.betSlipService.addCombinationMulticast(data.combinationMulticastInput).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get("X-State"));
            const autoStake: StakeModel = this.betslipUtils.autoCombinationMulticastStake(response.body);

            this._trackingService.trackEvent([
              "addCombinationMulticast", // todo this.auxLineItem.Game.IsInPlay ? 'addBetLive' : 'addBet',
              "", // todo this.auxLineItem.ResultId.toString(),
              window.location.hash,
              "", // this.auxLineItem.Game.IsInPlay ? 'A\u00F1adir apuesta en directo' : 'A\u00F1adir apuesta anticipada',
              "event",
            ]);

            return new TicketActions.SetStake(autoStake);
          }),
          catchError((_) => of(new TicketActions.Error("Error Ocurred addCombinationMulticast BetSlipService"))),
        );
      }),
    ),
  );

  /**
   * Set Stake and Check Possible Systems and Change Filters
   * @returns TicketActions.SaveBetSlipAndEvaluateFlow | TicketActions.Error
   */
  setStake$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.SaveBetSlipAndEvaluateFlow | TicketActions.Error>(TicketActions.SET_STAKE),
      withLatestFrom(this.store.select("ticket")),
      switchMap(([data, ticketS]) => {
        const dataStake = data["stake"];
        const stake: StakeModel = dataStake;
        this.systemStaking = dataStake.Group !== StakeGroup.SINGLES;
        return this.betSlipService.stake(stake).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get("X-State"));

            this.betslipState = {
              ...this.betslipState,
              ProviderResponse: response.body,
              Processing: {
                From: "setStake",
                Item: stake,
              },
              LineItems: this.betslipUtils.BetslipUpdateLineItem(
                response.body,
                this.betslipState.LineItems,
                stake,
              ),
            };

            this._trackingService.trackEvent([
              "SetStake",
              "",
              "", // todo stake.OverallStake.toString(),
              "Cambia importe del ticket",
              "event",
            ]);
            return new TicketActions.SaveBetSlipAndEvaluateFlow(response.body, true);
          }),
          catchError((errors) => this.handleError(errors)),
        );
      }),
    ),
  );

  updateStake$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.SaveBetSlip | TicketActions.Error>(TicketActions.UPDATE_STAKE),
      withLatestFrom(this.store.select("ticket")),
      switchMap(([data, ticketSt]) => {
        const dataStake = data["stake"];
        const stake: StakeModel = dataStake;
        this.systemStaking = dataStake.Group !== StakeGroup.SINGLES;
        return this.betSlipService.stake(stake).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get("X-State"));
            this._trackingService.trackEvent([
              "ChangeAmountTicket",
              "",
              "", // todo stake.OverallStake.toString(),
              "Cambia importe del ticket",
              "event",
            ]);
            return new TicketActions.SaveBetSlip(response.body);
          }),
          catchError((errors) => this.handleError(errors)),
        );
      }),
    ),
  );

  setMultiStake$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.SaveBetSlip | TicketActions.SetMultiStake | TicketActions.Error>(
        TicketActions.SET_MULTI_STAKE,
      ),
      withLatestFrom(this.store.select("ticket")),
      switchMap(([data, ticketSt]) => {
        const multiStakeData = data["multiStake"];
        this.splitCanBeEachWay(multiStakeData, ticketSt);
        if (this.noEachWayItems !== null && this.noEachWayItems.ItemIds.length > 0) {
          return this.betSlipService.stake(this.noEachWayItems).pipe(
            map((response: Response) => {
              if (response != undefined && response != null)
                this.xStateService.saveXState(response.headers.get("X-State"));
              this.noEachWayItems = null;
              if (this.canBeEachWayItems.ItemIds.length > 0) {
                return new TicketActions.SetMultiStake(this.canBeEachWayItems);
              }
              if (response != undefined && response != null) return new TicketActions.SaveBetSlip(response.body);
              else return new TicketActions.NoAction();
            }),
            catchError((errors) => this.handleError(errors)),
          );
        } else {
          return this.betSlipService.stake(this.canBeEachWayItems).pipe(
            map((response: Response) => {
              this.xStateService.saveXState(response.headers.get("X-State"));
              this.canBeEachWayItems = null;
              this.noEachWayItems = null;
              return new TicketActions.SaveBetSlip(response.body);
            }),
            catchError((errors) => this.handleError(errors)),
          );
        }
      }),
    ),
  );

  /**
   * Set Stake and Check Possible Systems and Change Filters
   * @returns TicketActions.ChangeFilter | TicketActions.UpdateTicket | TicketActions.SetSystemBetType | TicketActions.UpdateAndChangeToSingles | TicketActions.NoAction
   */
  saveBetSlipAndEvaluateFlow$ = createEffect(() =>
    this.actions$.pipe(
      ofType<
        | TicketActions.ChangeFilter
        | TicketActions.UpdateTicket
        | TicketActions.SetSystemBetType
        | TicketActions.UpdateAndChangeToSingles
        | TicketActions.UpdateAndCheckForErrors
        | TicketActions.CheckForErrors
        | TicketActions.NoAction
      >(TicketActions.SAVE_BETSLIP_AND_EVALUATE_FLOW),
      withLatestFrom(this.store.select("ticket")),
      map(([data, tState]) => {
        const betSlip = data["betSlip"];
        const checkFilterChange = data["checkFilterChange"];
        const possibleSystems = betSlip["possibleSystems"];
        const filterSelected: FilterItem = tState["filterSelected"];
        const numberOfSelections = TicketMethods.getNumberOfSelections(tState["ticket"]);
        const existAccumulator = possibleSystems.find((system) => system.systemType === StakeGroup.ACCUMULATOR);
        const existMultiples = possibleSystems.find(
          (system) => system.systemType === StakeGroup.SYSTEM || system.systemType === StakeGroup.MULTI_WAY_SYSTEM,
        );
        const globalsData = {
          cuotaAccept: this.globalVars.cuotaAccept,
          userLogged: this.globalVars.user.logged,
        };

        this.ticketService.saveLocalStoregeTicket(tState);

        //? FilterSelected?
        if (filterSelected != undefined && filterSelected != null) {
          switch (filterSelected.betType) {
            case StakeGroup.SINGLES:
              if (numberOfSelections == 2 && existAccumulator && checkFilterChange) {
                return new TicketActions.ChangeFilter(1);
              } else {
                return new TicketActions.UpdateAndCheckForErrors(globalsData);
              }
            case StakeGroup.ACCUMULATOR:
              if (existAccumulator) {
                const stake: StakeModel = this.betslipUtils.checkSystemsAmount(tState, filterSelected.value);
                return new TicketActions.SetSystemBetType(tState, stake);
              } else {
                if (numberOfSelections < 2) return new TicketActions.UpdateAndChangeToSingles(globalsData);
                return new TicketActions.UpdateAndCheckForErrors(globalsData);
              }
            case StakeGroup.SYSTEM:
            case StakeGroup.MULTI_WAY_SYSTEM:
              if (existMultiples) {
                const stake: StakeModel = this.betslipUtils.checkSystemsAmount(tState, filterSelected.value);
                return new TicketActions.SetSystemBetType(tState, stake);
              } else {
                return new TicketActions.UpdateAndChangeToSingles(globalsData);
              }
            default:
              return new TicketActions.UpdateTicket(globalsData);
          }
        } else {
          return new TicketActions.ChangeFilter(1);
        }
      }),
    ),
  );

  /**
   * Set Multiple Selection to Ticket
   * @returns Stake | Error
   */
  setSystemBetType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.SET_SYSTEM_BET_TYPE),
      switchMap((data: TicketActions.SetSystemBetType) =>
        this.betSlipService.stake(data.systemStake).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get("X-State"));
            this._trackingService.trackEvent([
              "ChangeCombiTicket",
              "",
              "", // todo newBetSlip: numCombinations.toString(), ! (COMENTAOD POR REPO CODERE)
              "Cambia numero de combinaciones del ticket",
              "event",
            ]);
            return new TicketActions.SaveBetSlip(response.body);
          }),
          catchError((errors) => this.handleError(errors)),
        ),
      ),
    ),
  );

  updateOdds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.UPDATE_ODDS),
      switchMap((data: TicketActions.UpdateOdds) =>
        this.betSlipService.updateOdds(data.odds).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get("X-State"));
            return new TicketActions.SaveBetSlip(response.body);
          }),
          catchError((_) => of(new TicketActions.Error("Error Ocurred updateOdds BetSlipService"))),
        ),
      ),
    ),
  );

  marketLocked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.MARKET_LOCKED),
      map((data: TicketActions.MarketLocked) => {
        if (data.oddItems.length > 0) return new TicketActions.UpdateOdds(data.oddItems);
        return new TicketActions.NoAction();
      }),
    ),
  );

  keepSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.KEEP_SELECTION),
      map((data: TicketActions.KeepSelection) => new TicketActions.UpdateTicket(data.globalsData)),
    ),
  );

  removeStake$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.SaveBetSlip | TicketActions.SetStake | TicketActions.Error>(TicketActions.REMOVE_STAKE),
      withLatestFrom(this.store.select("ticket")),
      switchMap(([data, tkState]) => {
        const systemSetStake = data["systemSetStake"];
        this.systemSetStake = systemSetStake;
        return this.betSlipService.removeStake(systemSetStake).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get("X-State"));
            if (this.systemSetStake) {
              return new TicketActions.SetStake(this.systemSetStake);
            } else {
              return new TicketActions.SaveBetSlip(response.body);
            }
          }),
          catchError((_) => of(new TicketActions.Error("Error Ocurred removeStake BetSlipService"))),
        );
      }),
    ),
  );

  SaveAndCloseBet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.SAVE_AND_CLOSE_BET),
      map((data: TicketActions.SaveAndCloseBet) => new TicketActions.CloseBet(data.betSlip)),
    ),
  );

  recoverTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.RECOVER_TICKET),
      map((data: TicketActions.RecoverTicket) => {
        const stake = this.betslipUtils.generateMultiStake(data.ticketItemsAndAmount);
        if (!this.deviceService.isDesktop) this.mobileBehavior.openSmallFooter();
        return new TicketActions.SetMultiStake(stake);
      }),
    ),
  );

  removeItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.SaveBetSlipAndEvaluateFlow | TicketActions.Error>(TicketActions.REMOVE_ITEM),
      withLatestFrom(this.store.select("ticket")),
      switchMap(([data, tckState]) => {
        const itemId = data["id"];
        this.auxRemovedItem = TicketMethods.getItemByPBSId(tckState["ticket"], itemId);
        return this.betSlipService.removeItem(itemId).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get("X-State"));

            this._trackingService.trackEvent([
              // ! comentado en su repositorio
              "removeItem", // todo this.auxRemovedItem.IsLive ? 'removeAddBetLive' : 'removeAddBet',
              "", // todo this.auxRemovedItem.ItemId,
              window.location.hash,
              "", // todo this.auxRemovedItem.IsLive ? 'Quitar apuesta en directo' : 'Quitar apuesta anticipada',
              "event",
            ]);

            return new TicketActions.SaveBetSlipAndEvaluateFlow(response.body, false);
          }),
          catchError((_) => of(new TicketActions.Error("Error Ocurred removeItem BetSlipService"))),
        );
      }),
    ),
  );

  removeItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.SaveBetSlipAndEvaluateFlow | TicketActions.Error>(TicketActions.REMOVE_ITEMS),
      withLatestFrom(this.store.select("ticket")),
      switchMap(([data, ticktState]) => {
        const itemIds = data["ids"];
        this.auxRemovedItems = TicketMethods.getItemByPBSIds(ticktState["ticket"], itemIds);
        return this.betSlipService.removeItem(itemIds).pipe(
          map((response: Response) => {
            this._trackingService.trackEvent([
              // ! Comentado en su repositorio
              "removeItems", // todo this.auxRemovedItem.IsLive ? 'removeAddBetLive' : 'removeAddBet',
              "", // todo this.auxRemovedItem.ResultsNr,
              window.location.hash,
              "", // todo this.auxRemovedItem.IsLive ? 'Quitar apuesta en directo' : 'Quitar apuesta anticipada',
              "event",
            ]);

            this.xStateService.saveXState(response.headers.get("X-State"));
            return new TicketActions.SaveBetSlipAndEvaluateFlow(response.body, false);
          }),
          catchError((_) => of(new TicketActions.Error("Error Ocurred removeItem BetSlipService"))),
        );
      }),
    ),
  );

  removeMulticastItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.REMOVE_MULTICAST_ITEM),
      switchMap((data: TicketActions.RemoveMulticastItem) =>
        this.betSlipService.removeItem(data.id).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get("X-State"));
            return new TicketActions.SaveBetSlipAndEvaluateFlow(response.body, false);
          }),
          catchError((_) => of(new TicketActions.Error("Error Ocurred removeMulticastItem BetSlipService"))),
        ),
      ),
    ),
  );

  removeCombination$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.REMOVE_COMBINATION_ITEM),
      switchMap((data: TicketActions.RemoveCombinationItem) =>
        this.betSlipService.removeItem(data["id"]).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get("X-State"));
            return new TicketActions.SaveBetSlipAndEvaluateFlow(response.body, false);
          }),
          catchError((_) => of(new TicketActions.Error("Error Ocurred removeCombination BetSlipService"))),
        ),
      ),
    ),
  );

  removeSmartItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.REMOVE_SMARTMARKET_ITEM),
      switchMap((data: TicketActions.RemoveSmartMarketItem) =>
        this.betSlipService.removeItem(data["id"]).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get("X-State"));
            return new TicketActions.SaveBetSlipAndEvaluateFlow(response.body, false);
          }),
          catchError((_) => of(new TicketActions.Error("Error Ocurred removeCombination BetSlipService"))),
        ),
      ),
    ),
  );

  placeBet$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.CloseBet | TicketActions.Error>(TicketActions.PLACE_BET),
      withLatestFrom(this.store.select("ticket")),
      switchMap(([data, ticketStat]) => {
        const groupKey = data["groupKey"];
        const hasFreeBets = ticketStat["ticket"].FreeBetVoucherId;
        this.auxCloseBetTicket = ticketStat["ticket"];
        this.auxGroupKey = groupKey;
        this.utils.loader(null, "is-loader transparent-backdrop");
        return of(new TicketActions.CloseBet(groupKey));
      }),
    ),
  );

  closeBet$ = createEffect(() =>
    this.actions$.pipe(
      ofType<
        | TicketActions.ClosedTicketResult
        | TicketActions.CloseTicketResultAndOddsUpdate
        | TicketActions.OveraskStart
        | TicketActions.BetbuilderOddsChange
        | TicketActions.NoAction
        | TicketActions.Error
      >(TicketActions.CLOSE_BET),
      withLatestFrom(this.store.select("ticket")),
      switchMap(([data, ticketSte]) => {
        this.auxCloseBet = data["placeBet"];
        return this.betSlipService.closeBet(this.auxCloseBet).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get("X-State"));
            const ticketResponse = response.body;
            const singleTicketItem = ticketResponse["SingleTicketItem"];
            const ticket = ticketSte["ticket"];
            let betbuilderError = false;
            this.events.publish("loading:finish");

            if (ticketResponse["GeneralError"]) {
              return new TicketActions.Error("Error Ocurred closeBet BetSlipService", { ticketResponse });
            }

            const responseData: any = {
              isSingleItem: !response.body["TicketItems"] ? false : true,
              data: !response.body["TicketItems"]
                ? response.body["SingleTicketItem"]
                : response.body["TicketItems"],
            };

            if (responseData.data)
              this.betslipUtils.processTrackEventCloseTicket(this.auxCloseBetTicket, responseData);

            if (singleTicketItem) {
              if (singleTicketItem.OveraskRejectId != null) {
                this.utils.closeLoader();
                return new TicketActions.OveraskStart(singleTicketItem, singleTicketItem["Stake"]);
              }

              if (singleTicketItem.ErrorCode.includes("ErrorOddsChange")) {
                const newOdds = [];
                TicketMethods.getAllItems(ticket).map((lineItem, index) => {
                  if (lineItem.SmartLineItems && lineItem.SmartLineItems.length > 0) {
                    betbuilderError = true;
                  } else {
                    singleTicketItem["NewOdds"].map((newOddsItem) => {
                      if (newOddsItem.ResultId === lineItem.ResultsNr) {
                        newOdds.push({
                          ItemId: lineItem.ItemId,
                          Odds: newOddsItem.Odd,
                        });
                      }
                    });
                  }
                });
                if (newOdds.length < 1 && this.globalVars.FEATURES.SPO_LiveBetbuilderEnabled && betbuilderError) {
                  // ?? to test with the PO
                  return new TicketActions.BetbuilderOddsChange();
                }
                this.utils.closeLoader();
                return new TicketActions.CloseTicketResultAndOddsUpdate(singleTicketItem, newOdds);
              }

              if (singleTicketItem.ErrorCode.includes("InvalidOdd")) {
                return new TicketActions.Error("Error: InvalidOdd");
              }

              if (singleTicketItem.ErrorCode.includes("EventNodeNotActive")) {
                this.betslipUtils.someMarketIsClosedOnBBL();
              }
            }

            this.auxCloseBet = null;

            this._balanceService.refreshBalance();
            this.utils.closeLoader();
            return new TicketActions.ClosedTicketResult(ticketResponse, []);
          }),
          catchError((error) => {
            this.betslipUtils.trackEventCloseTicket(this.auxCloseBetTicket, error);
            return of(new TicketActions.Error("Error Ocurred closeBet BetSlipService"));
          }),
        );
      }),
    ),
  );

  closeTicketResultAndOddsUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.CLOSED_TICKET_RESULT_AND_ODDS_UPDATE),
      map((data: TicketActions.CloseTicketResultAndOddsUpdate) => new TicketActions.UpdateOdds(data.newOdds)),
    ),
  );

  overaskMaxStake$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.SetStake>(TicketActions.OVERASK_MAX_STAKE),
      map((data: TicketActions.SetStake) => new TicketActions.SetStake(data.stake)),
    ),
  );

  overaskCloseBet$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.CloseBet>(TicketActions.OVERASK_CLOSE_BET),
      map(() => {
        this.utils.closeLoader();
        return new TicketActions.CloseBet(this.auxCloseBet);
      }),
    ),
  );

  // FREEBETS

  freeBetsUpdateVouchers$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.FreeBetsSetVouchers | TicketActions.Error>(TicketActions.FREEBETS_UPDATE_VOUCHERS),
      switchMap(() =>
        this.ticketService.GetUserActiveVouchers(true).pipe(
          map((response) => {
            const data = response["data"];
            let lstFreebets = [];
            if (data) {
              lstFreebets = data.filter((item) => item.IsTerminalVoucher === false);
            } else {
              lstFreebets = [];
            }
            return new TicketActions.FreeBetsSetVouchers(lstFreebets);
          }),
          catchError((_) => of(new TicketActions.Error("Error Ocurred freeBetsUpdateVouchers TicketService"))),
        ),
      ),
    ),
  );

  freeBetsAdd$ = createEffect(() =>
    this.actions$.pipe(
      ofType<
        | TicketActions.FreeBetsUpdateValidations
        | TicketActions.FreeBetsCancel
        | TicketActions.FreeBetsUpdateStakeAndPlaceFB
        | TicketActions.Error
      >(TicketActions.FREEBETS_ADD),
      withLatestFrom(this.store.select(ticketSelector.getFilters)),
      switchMap(([data, filter]) =>
        this.betSlipService.freebetAdd(data["voucher"]).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get("X-State"));
            const freebetStake: StakeModel = this.betslipUtils.autoFreeBetsStake(response.body);
            const betSlip: any = response.body;
            let validationCode = "";

            switch (filter.value) {
              case 0:
                validationCode =
                  betSlip.stakeGroups.singles.validation.bonus.singles[0].vouchers[0].validationCode;
                if (validationCode !== "Validated" && validationCode !== "GeneralConditionCheckFailed") {
                  this.utils.showError("Voucher No Compatible. <br>Por favor, selecione otro.");
                  return new TicketActions.FreeBetsCancel(freebetStake.VoucherCode);
                }
                if (validationCode !== "Validated" && validationCode == "GeneralConditionCheckFailed") {
                  freebetStake.VoucherCode = "";
                  freebetStake.OverallStake = betSlip.bonus.freeBetVouchers[0].freeBetStake;
                  return new TicketActions.FreeBetsUpdateStakeAndPlaceFB(freebetStake);
                }
                break;
              case 1:
                validationCode =
                  betSlip.stakeGroups.accumulator.validation.bonus.systems[
                    betSlip.stakeGroups.accumulator.validation.bonus.systems.length - 1
                  ].vouchers[0].validationCode;
                if (validationCode !== "Validated" && validationCode !== "GeneralConditionCheckFailed") {
                  this.utils.showError("Voucher No Compatible. <br>Por favor, selecione otro.");
                  return new TicketActions.FreeBetsCancel(freebetStake.VoucherCode);
                }
                if (validationCode !== "Validated" && validationCode == "GeneralConditionCheckFailed") {
                  freebetStake.VoucherCode = "";
                  freebetStake.OverallStake = betSlip.bonus.freeBetVouchers[0].freeBetStake;
                  return new TicketActions.FreeBetsUpdateStakeAndPlaceFB(freebetStake);
                }
                break;
              default:
                break;
            }

            this._trackingService.trackEvent([
              "freeBetsAddsTicket",
              "",
              "",
              "Ticket Freebet a\u00F1adido",
              "event",
            ]);

            return new TicketActions.FreeBetsUpdateValidations("stake", betSlip, freebetStake);
          }),
          catchError((_) => of(new TicketActions.Error("Error Ocurred freeBetsAdd BetSlipService"))),
        ),
      ),
    ),
  );

  freeBetsUpdateStakeAndPlaceFB$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.FreeBetsUpdateValidations | TicketActions.Error>(
        TicketActions.FREEBETS_UPDATE_STAKE_AND_PLACE_FB,
      ),
      withLatestFrom(this.store.select("ticket")),
      switchMap(([data, tiktState]) => {
        const dataStake = data["stake"];
        const stake: StakeModel = dataStake;
        return this.betSlipService.stake(stake).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get("X-State"));
            const freebetStake: StakeModel = this.betslipUtils.autoFreeBetsStake(response.body);
            const betSlip: any = response.body;
            this._trackingService.trackEvent([
              "ChangeAmountTicket",
              "",
              "", // todo stake.OverallStake.toString(),
              "Cambia importe del ticket",
              "event",
            ]);
            return new TicketActions.FreeBetsUpdateValidations("stake", betSlip, freebetStake);
          }),
          catchError((errors) => this.handleError(errors)),
        );
      }),
    ),
  );

  freeBetsCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.FreeBetsUpdateValidations | TicketActions.Error>(TicketActions.FREEBETS_CANCEL),
      switchMap((data: any) =>
        this.betSlipService.freebetCancel(data.voucherCode).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get("X-State"));
            const betSlip: any = response.body;

            this._trackingService.trackEvent([
              "cancelFreeBetsTicket",
              "",
              "",
              "Ticket Freebet cancelado",
              "event",
            ]);

            return new TicketActions.FreeBetsUpdateValidations("SaveBetSlip", betSlip);
          }),
          catchError((_) => of(new TicketActions.Error("Error Ocurred freeBetsCancel BetSlipService"))),
        ),
      ),
    ),
  );

  freeBetsUpdateValidations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.FREEBETS_UPDATE_VALIDATIONS),
      map((data: TicketActions.FreeBetsUpdateValidations) => {
        switch (data.nextStep) {
          case "SaveBetSlip":
            return new TicketActions.SaveBetSlip(data.betSlip);
          default: // Stake
            return new TicketActions.SetStake(data.freebetStake);
        }
      }),
    ),
  );

  //INTERNAL EFFECTS
  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.ERROR),
      map((state: TicketActions.Error) => {
        this.utils.closeLoader();
        const globalsData = {
          cuotaAccept: this.globalVars.cuotaAccept,
          userLogged: this.globalVars.user.logged,
        };
        return new TicketActions.UpdateTicket(globalsData);
      }),
    ),
  );

  /**
   * Add a single Item to ticket
   * @returns Stake | Error
   */
  SetXStateAndAuthorization$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.NoAction>(TicketActions.SET_X_STATE_AND_AUTHORIZATION),
      switchMap(() => {
        this.xStateService.loadInitialXState();
        this.xStateService.loadAuthorization();
        return of(new TicketActions.NoAction());
      }),
    ),
  );

  updateAndChangeToSingles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TicketActions.UPDATE_AND_CHANGE_TO_SINGLES),
      map((data: TicketActions.UpdateAndChangeToSingles) => new TicketActions.ChangeFilter(0)),
    ),
  );

  changeFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType<
        | TicketActions.SetSystemBetType
        | TicketActions.UpdateAndChangeToSingles
        | TicketActions.UpdateTicket
        | TicketActions.CheckForErrors
        | TicketActions.NoAction
      >(TicketActions.CHANGE_FILTER),
      withLatestFrom(this.store.select("ticket")),
      map(([data, state]) => {
        const betSlip = state["betSlip"];
        const possibleSystems = betSlip["possibleSystems"];
        const filterValue = data["filter"];
        const existAccumulator = possibleSystems.find((system) => system.systemType === StakeGroup.ACCUMULATOR);
        const existMultiples = possibleSystems.find(
          (system) => system.systemType === StakeGroup.SYSTEM || system.systemType === StakeGroup.MULTI_WAY_SYSTEM,
        );
        let stake: StakeModel;
        const updateTicket = {
          cuotaAccept: this.globalVars.cuotaAccept,
          userLogged: this.globalVars.user.logged,
        };

        switch (filterValue) {
          case 1:
            if (!existAccumulator) return new TicketActions.CheckForErrors();
            stake = this.betslipUtils.checkSystemsAmount(state, filterValue);
            return new TicketActions.SetSystemBetType(state, stake);
          case 2:
            if (!existMultiples) return new TicketActions.UpdateAndChangeToSingles(updateTicket);
            stake = this.betslipUtils.checkSystemsAmount(state, filterValue);
            return new TicketActions.SetSystemBetType(state, stake);
          default:
            return new TicketActions.CheckForErrors();
        }
      }),
    ),
  );

  saveBetslip$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.UpdateTicket | TicketActions.Error>(TicketActions.SAVE_BETSLIP),
      withLatestFrom(this.store.select("ticket")),
      map(([data, ticket]) => {
        const globalsData = {
          cuotaAccept: this.globalVars.cuotaAccept,
          userLogged: this.globalVars.user.logged,
        };
        this.ticketService.saveLocalStoregeTicket(ticket);
        return new TicketActions.UpdateTicket(globalsData);
      }),
    ),
  );

  updateTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.SetFilters | TicketActions.Error>(TicketActions.UPDATE_TICKET),
      map((state: any) => new TicketActions.SetFilters(state)),
    ),
  );

  setFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.SetFilterSelected | TicketActions.Error>(TicketActions.SET_FILTERS),
      map((state: any) => new TicketActions.SetFilterSelected(state)),
    ),
  );

  removeTicket$ = createEffect(() =>
    // todo ofType<TicketActions.SaveBetSlip | TicketActions.Error>
    this.actions$.pipe(
      ofType(TicketActions.REMOVE_TICKET),
      switchMap((data: TicketActions.RemoveTicket) => {
        this.stopOveraskPolling = true;

        this.betslipState = BetslipStateInitial;

        return this.betSlipService.removeTicket(data.removeTckt).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get("X-State"));
            this.ticketService.removeLocalStoregeTicket();
            return new TicketActions.SaveBetSlip(response.body);
          }),
          catchError((_) => of(new TicketActions.Error("Error Ocurred removeTicket BetSlipService"))),
        );
      }),
    ),
  );

  setBetOddAcceptance$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.NoAction | TicketActions.Error>(TicketActions.SET_BET_ODDS_ACCEPTANCE),
      switchMap(() =>
        this.userService.updateCustomizations(this.globalVars.user.customization).pipe(
          map((response: Response) => new TicketActions.NoAction()),
          catchError((_) => of(new TicketActions.Error("Error Ocurred setBetOddAcceptance setBetOddAcceptance"))),
        ),
      ),
    ),
  );

  restartBetslip$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.NoAction>(TicketActions.RESTART_BETSLIP),
      switchMap(() => {
        this.xStateService.restartAll();

        return of(new TicketActions.RemoveTicket(new removeTicket()));
      }),
    ),
  );

  getOveraskStatus(overaskData: C_OveraskDataBetPlacement) {
    let status: number | string = 0;
    const StakeCt = this.auxCloseBet.placeBetSlipRequest.BetTickets[0].Bets[0].StakeCt;

    if (overaskData.Finished) {
      if (overaskData.Rejected) {
        status = 3;
      } else {
        if (overaskData.Approved) {
          status = overaskData.MaxStake > 0 && overaskData.MaxStake < StakeCt ? 1 : "OK";
        }
      }
    }

    this.stopOveraskPolling ? this.overaskStatus$.next(true) : this.overaskStatus$.next(overaskData.Finished);
    this.overaskData = overaskData;
    return status;
  }

  overaskNextStep(overaskData: C_OveraskDataBetPlacement) {
    const status = this.getOveraskStatus(overaskData);

    switch (status) {
      case 0: // Aprobación Requerida
        return new TicketActions.OveraskStartPolling(overaskData, status);
      case 1: // Apuesta Máxima Posible
        const stake = {
          IsEachWay: false,
          Group: this.auxGroupKey,
          ItemId: 1,
          OverallStake: overaskData.MaxStake,
        };
        return new TicketActions.OveraskMaxStake(overaskData, status, stake);
      case 3: // No se ha podido realizar
        return new TicketActions.OveraskRejected(overaskData, status);
      default:
        return new TicketActions.CloseBet(this.auxCloseBet);
    }
  }

  splitCanBeEachWay(data: any, ticket: any) {
    const lineItems = [
      ...ticket.ticket.LineItems,
      ...ticket.ticket.StraightMulticast,
      ...ticket.ticket.CombinationMulticast,
      ...ticket.ticket.SmartLineItems,
    ];
    this.canBeEachWayItems = {
      IsEachWay: true,
      Group: data.Group,
      OverallStake: data.OverallStake,
      VoucherCode: "",
      SystemId: data.SystemId,
      ItemIds: [],
    };
    this.noEachWayItems = {
      IsEachWay: false,
      Group: data.Group,
      OverallStake: data.OverallStake,
      VoucherCode: "",
      SystemId: data.SystemId,
      ItemIds: [],
    };
    this.canBeEachWayItems.IsEachWay = true;
    this.canBeEachWayItems.ItemIds = [];
    this.noEachWayItems.ItemIds = [];
    data.ItemIds.forEach((itemId) => {
      const item = lineItems.find((j) => j.ItemId == itemId);
      if (item.CanBeEachWay) {
        this.canBeEachWayItems.ItemIds.push(item.ItemId);
      } else {
        this.noEachWayItems.ItemIds.push(item.ItemId);
      }
    });
  }

  handleError(errors) {
    return of(new TicketActions.Error(errors.error));
  }

  // TICKET PROXY
  /**
   * Add a single Item to ticket
   * @returns Stake | Error
   */
  // addSingleResult_TICKET_PROXY$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(TicketActions.ADD_SINGLE_RESULT_TICKET_PROXY),
  //     withLatestFrom(this.store.select('ticket')),
  //     switchMap(([data, ticket]) => {
  //       this.auxLineItem = mapSingleResult(data.game);
  //       return this.betSlipService.addSingleResult_TICKET_PROXY(this.auxLineItem).pipe(
  //         map((response: Response) => {
  //           this.xStateService.saveXState(response.headers.get('X-State'));
  //           const autoStake: StakeModel = this.betslipUtils.autoSingleStake(response.body, this.auxLineItem);

  //           this._trackingService.trackEvent([
  //             '', // todo this.auxLineItem.Game.IsInPlay ? 'addBetLive' : 'addBet',
  //             '', // todo this.auxLineItem.ResultId.toString(),
  //             window.location.hash,
  //             '', // todo this.auxLineItem.Game.IsInPlay ? 'A\u00F1adir apuesta en directo' : 'A\u00F1adir apuesta anticipada',
  //             'event'
  //           ]);

  //           return new TicketActions.SetStake_TICKET_PROXY(autoStake);
  //         })
  //       );
  //     })
  //   )
  // );

  setStakeTICKET_PROXY$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TicketActions.Error>(TicketActions.SET_STAKE_TICKET_PROXY),
      withLatestFrom(this.store.select("ticket")),
      switchMap(([data, ticket]) => {
        this.betslipState = ticket;
        const dataStake = data["stake"];
        const stake: StakeModel = dataStake;
        this.systemStaking = dataStake.Group !== StakeGroup.SINGLES;
        return this.betSlipService.stake(stake).pipe(
          map((response: Response) => {
            this.xStateService.saveXState(response.headers.get("X-State"));
            // this._trackingService.trackEvent([
            //   'SetStake',
            //   '',
            //   '', // todo stake.OverallStake.toString(),
            //   'Cambia importe del ticket',
            //   'event'
            // ]);
            return new TicketActions.SaveBetSlipAndEvaluateFlow(response.body, true);
          }),
          catchError((errors) => this.handleError(errors)),
        );
      }),
    ),
  );
}
