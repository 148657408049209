<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<ng-container *ngIf="!isMobile; else mobileView">
  <div class="cs-documents background-color-dark w-100">
    <div class="cs-documents--container background-color-regular w-100"
      [style.height.px]="utils.getModalHeight('ion-modal--height', 8)">
      <div *ngIf="utils.isSpain() || utils.isColombia()">
        <div class="text-center verification-info-header">
          <p>
            La verificación es necesaria para proteger al jugador, poder retirar dinero y beneficiarse de las
            promociones.
          </p>
        </div>
        <ng-container *ngIf="documentVerificationAvailable">
          <div [hidden]="isVerificationCompleted"
            *ngIf="(isSpain && showAutoVerification) || (globalVars?.licenseType === 21 && isVeridasCOActive)">
            <div [hidden]="isManualUploadEnabled()" class="color-secondary p-1 verification-info-link">
              <a class="color-secondary" tappable (click)="showInstructionsToVerify()">
                <span>Recomendaciones para hacer la verificación de la manera correcta</span>
              </a>
            </div>
            <div class="cs-documents--info red-txt p-1"
              *ngIf="globalVars?.licenseType == 22 && globalVars?.openFromRegister">
              <a class="red-txt"><span>{{ 'registropacashierdocuments' | translate }}</span></a>
            </div>
            <div class="cs-document-item--container p-1 justify-center" *ngIf="!isVerificationCompleted">
              <button [hidden]="isManualUploadEnabled()" class="cs-document-item--btn verification-button"
                [disabled]="isVerificationPending()" (click)="initVerification($event)">
                <span class="color-light">Iniciar Verificación</span>
              </button>
            </div>
          </div>

          <!-- TO-DO: definir la clase para spain -->

          <div class="cs-document-item--container p-1 justify-center manual-upload-title"
            *ngIf="isManualUploadEnabled()">
            <span>Subida Manual de Documentos</span>
          </div>

          <div [hidden]="isManualUploadEnabled()" class="cs-documents--verification-requests">
            <ng-container>
              <cs-verification-item *ngFor="let doc of getVerificationRequests" [doc]="doc"></cs-verification-item>
            </ng-container>
          </div>

          <div class="cs-documents--list" *ngIf="isManualUploadEnabled()">
            <ng-container *ngIf="!isMaxFailuresNumberReached()">
              <cs-document-item [document]="titleIDCard('front')" (navigate)="triggerClick(inputOne)">
                <input id="input1" #inputOne type="file" accept="image/*" />
              </cs-document-item>
              <cs-verification-item *ngFor="let doc of getDocumentUploadRequests['idFront']"
                [doc]="doc"></cs-verification-item>

              <cs-document-item [document]="titleIDCard('reverse')" (navigate)="triggerClick(inputTwo)">
                <input id="input2" #inputTwo type="file" accept="image/*" />
              </cs-document-item>
              <cs-verification-item *ngFor="let doc of getDocumentUploadRequests['idReverse']"
                [doc]="doc"></cs-verification-item>

              <cs-document-item [document]="setSelfieLiteral()" (navigate)="triggerClick(inputFive)">
                <input id="input5" #inputFive type="file" accept="image/*" />
              </cs-document-item>
              <cs-verification-item *ngFor="let doc of getDocumentUploadRequests['selfie']"
                [doc]="doc"></cs-verification-item>
            </ng-container>

            <cs-document-item [document]="bankDataInfo()" (navigate)="triggerClick(inputThree)">
              <input id="input3" #inputThree type="file" accept="image/*" />
            </cs-document-item>
            <cs-verification-item *ngFor="let doc of getDocumentUploadRequests['bank']"
              [doc]="doc"></cs-verification-item>

            <cs-document-item [document]="otherDocument()" (navigate)="triggerClick(inputSeven)">
              <input id="input7" #inputSeven type="file" accept="image/*" />
            </cs-document-item>
            <cs-verification-item *ngFor="let doc of getDocumentUploadRequests['other']"
              [doc]="doc"></cs-verification-item>

            <cs-document-item *ngIf="showOriginFunds()" [document]="originFundsCheck()" [isOriginFunds]="true"
              [originFunds]="originFunds" (navigate)="openModalSworm()">
            </cs-document-item>
          </div>
          <div class="cs-document-item--container justify-center" *ngIf="isManualUploadEnabled()">
            <div class="divider"></div>
          </div>
          <div class="" *ngIf="isManualUploadEnabled()">
            <ng-container>
              <cs-verification-item *ngFor="let doc of getVerificationRequests" [doc]="doc"></cs-verification-item>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <!-- Other Regions -->
      <div *ngIf="!utils.isSpain() && !utils.isColombia()">
        <div *ngIf="!isArgentina" class="cs-documents--info color-secondary p-1" tappable
          (click)="showInfoAlert($event)">
          <a class="color-secondary"><span>{{ 'veridoc_info' | translate }}</span></a>
        </div>
        <ng-container *ngIf="isArgentina">
          <div class="center-contennt-info">
            <p>{{ 'tresIntentosTxtCABA' | translate }}</p>
            <div class="cs-documents--info color-secondary p-1" tappable (click)="showInfoAlert($event)">
              <a class="color-secondary underline"><span>{{ 'saberMasTxtCABA' | translate }}</span></a>
            </div>
          </div>
        </ng-container>
        <div class="cs-documents--info red-txt p-1"
          *ngIf="globalVars?.licenseType == 22 && globalVars?.openFromRegister">
          <a class="red-txt"><span>{{ 'registropacashierdocuments' | translate }}</span></a>
        </div>
        <div class="cs-documents--list">
          <ng-container *ngIf="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)">
            <cs-document-item [document]="titleIDCard()" [verificationType]="true" tappable (click)="showInfoAlert()">
            </cs-document-item>
            <cs-verification-item *ngFor="let doc of veridasData$ | async" [doc]="doc"></cs-verification-item>
          </ng-container>

          <cs-document-item *ngIf="isArgentina" [hidden]="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)"
            [document]="titleIDCard('front')" (openInstructions)="openInstructions(1)" [openInstrucionsNumber]="1">
          </cs-document-item>
          <cs-document-item *ngIf="!isArgentina" [hidden]="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)"
            [document]="titleIDCard('front')" (navigate)="triggerClick(inputOne)">
            <input id="input1" #inputOne type="file" accept="image/*" />
          </cs-document-item>

          <cs-verification-item [hidden]="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)"
            *ngFor="let doc of getDocumentUploadRequests['idFront']" [doc]="doc">
          </cs-verification-item>

          <cs-document-item *ngIf="isArgentina" [hidden]="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)"
            [document]="titleIDCard('reverse')" (openInstructions)="openInstructions(2)" [openInstrucionsNumber]="2">
          </cs-document-item>

          <cs-document-item *ngIf="!isArgentina" [hidden]="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)"
            [document]="titleIDCard('reverse')" (navigate)="triggerClick(inputTwo)">
            <input id="input2" #inputTwo type="file" accept="image/*" />
          </cs-document-item>

          <cs-verification-item [hidden]="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)"
            *ngFor="let doc of getDocumentUploadRequests['idReverse']" [doc]="doc"></cs-verification-item>

          <cs-document-item *ngIf="!isArgentina" [hidden]="!globalVars?.FEATURES?.selfieDocEnabled"
            [document]="setSelfieLiteral()" (navigate)="triggerClick(inputFive)">
            <input id="input5" #inputFive type="file" accept="image/*" />
          </cs-document-item>

          <cs-document-item *ngIf="isArgentina" [hidden]="!globalVars?.FEATURES?.selfieDocEnabled"
            [document]="setSelfieLiteral()" (openInstructions)="openInstructions(3)" [openInstrucionsNumber]="3">
          </cs-document-item>

          <cs-verification-item [hidden]="!globalVars?.FEATURES?.selfieDocEnabled"
            *ngFor="let doc of getDocumentUploadRequests['selfie']" [doc]="doc"></cs-verification-item>

          <cs-document-item *ngIf="!isArgentina" [document]="bankDataInfo()" (navigate)="triggerClick(inputThree)">
            <input id="input3" #inputThree type="file" accept="image/*" />
          </cs-document-item>

          <cs-document-item *ngIf="isArgentina" [document]="bankDataInfo()" (navigate)="triggerClick(inputThree)">
            <input id="input3" #inputThree type="file" accept="image/*" />
          </cs-document-item>

          <cs-verification-item *ngFor="let doc of getDocumentUploadRequests['bank']"
            [doc]="doc"></cs-verification-item>

          <cs-document-item [document]="otherDocument()" (navigate)="triggerClick(inputSeven)">
            <input id="input7" #inputSeven type="file" accept="image/*" />
          </cs-document-item>

          <cs-verification-item *ngFor="let doc of getDocumentUploadRequests['other']"
            [doc]="doc"></cs-verification-item>

          <cs-document-item *ngIf="showOriginFunds()" [document]="originFundsCheck()" [isOriginFunds]="true"
            [originFunds]="originFunds" (navigate)="openModalSworm()">
          </cs-document-item>

          <div class="inputTriggers" *ngIf="isArgentina">
            <input id="input1" #inputOne type="file" accept="image/*" />
            <input id="input2" #inputTwo type="file" accept="image/*" />
            <input id="input5" #inputFive type="file" accept="image/*" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #mobileView>
  <div class="cs-documents background-color-dark w-100">
    <div class="cs-documents--container background-color-regular w-100">
      <div *ngIf="utils.isSpain() || utils.isColombia()">
        <div [hidden]="!isManualUploadEnabled()" class="text-center verification-info-header">
          <p>
            La verificación es necesaria para proteger al jugador, poder retirar dinero y beneficiarse de las
            promociones.
          </p>
        </div>
        <ng-container *ngIf="documentVerificationAvailable">
          <div [hidden]="isVerificationCompleted"
            *ngIf="(isSpain && showAutoVerification) || (globalVars?.licenseType === 21 && isVeridasCOActive)">
            <div *ngIf="!isVerificationCompleted" [hidden]="isManualUploadEnabled()"
              class="color-secondary p-1 verification-info-link">
              <a class="color-secondary" tappable (click)="showInstructionsToVerify()">
                <span>Recomendaciones para hacer la verificación de la manera correcta</span>
              </a>
            </div>
            <div class="cs-documents--info red-txt p-1"
              *ngIf="globalVars?.licenseType == 22 && globalVars?.openFromRegister">
              <a class="red-txt"><span>{{ 'registropacashierdocuments' | translate }}</span></a>
            </div>
            <div class="cs-document-item--container p-1 justify-center" *ngIf="!isVerificationCompleted">
              <button [hidden]="isManualUploadEnabled()" class="cs-document-item--btn verification-button"
                [disabled]="isVerificationPending()" (click)="initVerification($event)">
                <span class="color-light">Iniciar Verificación</span>
              </button>
            </div>
          </div>
          <!-- TO-DO: definir la clase para spain -->
          <div class="cs-document-item--container p-1 justify-center manual-upload-title"
            *ngIf="isManualUploadEnabled() && !utils.isColombia()">
            <span>Subida Manual de Documentos</span>
          </div>

          <div [hidden]="isManualUploadEnabled()" class="cs-documents--verification-requests">
            <ng-container>
              <cs-verification-item *ngFor="let doc of getVerificationRequests" [doc]="doc"></cs-verification-item>
            </ng-container>
          </div>

          <!-- TO-DO: definir la clase para spain -->
          <div class="cs-documents--list" *ngIf="isManualUploadEnabled()">
            <ng-container *ngIf="!isMaxFailuresNumberReached()">
              <cs-document-item [document]="titleIDCard('front')" (navigate)="triggerClick(inputOne)">
                <input id="input1" #inputOne type="file" accept="image/*" />
              </cs-document-item>
              <cs-verification-item *ngFor="let doc of getDocumentUploadRequests['idFront']"
                [doc]="doc"></cs-verification-item>

              <cs-document-item [document]="titleIDCard('reverse')" (navigate)="triggerClick(inputTwo)">
                <input id="input2" #inputTwo type="file" accept="image/*" />
              </cs-document-item>
              <cs-verification-item *ngFor="let doc of getDocumentUploadRequests['idReverse']"
                [doc]="doc"></cs-verification-item>

              <cs-document-item [document]="setSelfieLiteral()" (navigate)="triggerClick(inputFive)">
                <input id="input5" #inputFive type="file" accept="image/*" />
              </cs-document-item>
              <cs-verification-item *ngFor="let doc of getDocumentUploadRequests['selfie']"
                [doc]="doc"></cs-verification-item>
            </ng-container>

            <!-- <div class="cs-documents--complementary-header" *ngIf="utils.isColombia()">
              Documentación complementaria
            </div> -->
            <cs-document-item [document]="bankDataInfo()" (navigate)="triggerClick(inputThree)">
              <input id="input3" #inputThree type="file" accept="image/*" />
            </cs-document-item>
            <cs-verification-item *ngFor="let doc of getDocumentUploadRequests['bank']"
              [doc]="doc"></cs-verification-item>

            <cs-document-item [document]="otherDocument()" (navigate)="triggerClick(inputSeven)">
              <input id="input7" #inputSeven type="file" accept="image/*" />
            </cs-document-item>
            <cs-verification-item *ngFor="let doc of getDocumentUploadRequests['other']"
              [doc]="doc"></cs-verification-item>

            <cs-document-item *ngIf="showOriginFunds()" [document]="originFundsCheck()" [isOriginFunds]="true"
              [originFunds]="originFunds" (navigate)="openModalSworm()">
            </cs-document-item>
          </div>
          <div class="cs-document-item--container justify-center" *ngIf="isManualUploadEnabled()">
            <div class="divider"></div>
          </div>

          <!-- TO-DO: definir la clase para spain -->
          <div class="" *ngIf="isManualUploadEnabled()">
            <ng-container>
              <cs-verification-item *ngFor="let doc of getVerificationRequests" [doc]="doc"></cs-verification-item>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <!-- Other Regions -->
      <div *ngIf="!utils.isSpain() && !utils.isColombia()">
        <div *ngIf="!isArgentina" class="cs-documents--info color-secondary p-1" tappable
          (click)="showInfoAlert($event)">
          <a class="color-secondary"><span>{{ 'veridoc_info' | translate }}</span></a>
        </div>
        <ng-container *ngIf="isArgentina">
          <div class="center-contennt-info">
            <p>{{ 'tresIntentosTxtCABA' | translate }}</p>
            <div class="cs-documents--info color-secondary p-1" tappable (click)="showInfoAlert($event)">
              <a class="color-secondary underline"><span>{{ 'saberMasTxtCABA' | translate }}</span></a>
            </div>
          </div>
        </ng-container>
        <div class="cs-documents--info red-txt p-1"
          *ngIf="globalVars?.licenseType == 22 && globalVars?.openFromRegister">
          <a class="red-txt"><span>{{ 'registropacashierdocuments' | translate }}</span></a>
        </div>
        <div class="cs-documents--list">
          <ng-container *ngIf="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)">
            <cs-document-item [document]="titleIDCard()" [verificationType]="true" tappable (click)="showInfoAlert()">
            </cs-document-item>
            <cs-verification-item *ngFor="let doc of veridasData$ | async" [doc]="doc"></cs-verification-item>
          </ng-container>

          <cs-document-item *ngIf="isArgentina" [hidden]="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)"
            [document]="titleIDCard('front')" (openInstructions)="openInstructions(1)" [openInstrucionsNumber]="1">
          </cs-document-item>
          <cs-document-item *ngIf="!isArgentina" [hidden]="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)"
            [document]="titleIDCard('front')" (navigate)="triggerClick(inputOne)">
            <input id="input1" #inputOne type="file" accept="image/*" />
          </cs-document-item>

          <cs-verification-item [hidden]="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)"
            *ngFor="let doc of getDocumentUploadRequests['idFront']" [doc]="doc">
          </cs-verification-item>

          <cs-document-item *ngIf="isArgentina" [hidden]="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)"
            [document]="titleIDCard('reverse')" (openInstructions)="openInstructions(2)" [openInstrucionsNumber]="2">
          </cs-document-item>

          <cs-document-item *ngIf="!isArgentina" [hidden]="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)"
            [document]="titleIDCard('reverse')" (navigate)="triggerClick(inputTwo)">
            <input id="input2" #inputTwo type="file" accept="image/*" />
          </cs-document-item>

          <cs-verification-item [hidden]="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)"
            *ngFor="let doc of getDocumentUploadRequests['idReverse']" [doc]="doc"></cs-verification-item>

          <cs-document-item *ngIf="!isArgentina" [hidden]="!globalVars?.FEATURES?.selfieDocEnabled"
            [document]="setSelfieLiteral()" (navigate)="triggerClick(inputFive)">
            <input id="input5" #inputFive type="file" accept="image/*" />
          </cs-document-item>

          <cs-document-item *ngIf="isArgentina" [hidden]="!globalVars?.FEATURES?.selfieDocEnabled"
            [document]="setSelfieLiteral()" (openInstructions)="openInstructions(3)" [openInstrucionsNumber]="3">
          </cs-document-item>

          <cs-verification-item [hidden]="!globalVars?.FEATURES?.selfieDocEnabled"
            *ngFor="let doc of getDocumentUploadRequests['selfie']" [doc]="doc"></cs-verification-item>

          <cs-document-item *ngIf="!isArgentina" [document]="bankDataInfo()" (navigate)="triggerClick(inputThree)">
            <input id="input3" #inputThree type="file" accept="image/*" />
          </cs-document-item>

          <cs-document-item *ngIf="isArgentina" [document]="bankDataInfo()" (navigate)="triggerClick(inputThree)">
            <input id="input3" #inputThree type="file" accept="image/*" />
          </cs-document-item>

          <cs-verification-item *ngFor="let doc of getDocumentUploadRequests['bank']"
            [doc]="doc"></cs-verification-item>

          <cs-document-item [document]="otherDocument()" (navigate)="triggerClick(inputSeven)">
            <input id="input7" #inputSeven type="file" accept="image/*" />
          </cs-document-item>

          <cs-verification-item *ngFor="let doc of getDocumentUploadRequests['other']"
            [doc]="doc"></cs-verification-item>

          <cs-document-item *ngIf="showOriginFunds()" [document]="originFundsCheck()" [isOriginFunds]="true"
            [originFunds]="originFunds" (navigate)="openModalSworm()">
          </cs-document-item>

          <div class="inputTriggers" *ngIf="isArgentina">
            <input id="input1" #inputOne type="file" accept="image/*" />
            <input id="input2" #inputTwo type="file" accept="image/*" />
            <input id="input5" #inputFive type="file" accept="image/*" />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>