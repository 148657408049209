import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject, Subscription } from "rxjs";

@Injectable({ providedIn: "root" })
export class EventsService {
  private channels: { [key: string]: Subject<any> } = {};
  private canGoBack = new BehaviorSubject<boolean>(true);
  currentCanGoBack = this.canGoBack.asObservable();

  sendCanGoBack(canGoBack: boolean) {
    this.canGoBack.next(canGoBack);
  }

  subscribe(topic: string, observer: (_: any) => void): Subscription {
    if (!this.channels[topic]) {
      this.channels[topic] = new Subject<any>();
    }
    return this.channels[topic].subscribe(observer);
  }

  publish(topic: string, data?: any): void {
    const subject = this.channels[topic];
    if (!subject) {
      return;
    }
    subject.next(data);
  }
  publishEvents(...arrayEvents: any[]): void {
    for (let index = 0; index < arrayEvents.length; index++) {
      const topic: any = null;
      let subject: Subject<any> = null;
      if (index % 2 === 0) {
        arrayEvents;
        subject = this.channels[arrayEvents[index]];
      } else {
        if (!subject) {
          return;
        }
        subject.next(arrayEvents[index]);
      }
    }
  }

  destroy(topic: string): void {
    const subject = this.channels[topic];
    if (!subject) {
      return;
    }
    subject.complete();
    delete this.channels[topic];
  }
}

@Injectable({ providedIn: "root" })
export class NewEventService {
  private channels: { [key: string]: BehaviorSubject<any> } = {};

  subscribe(topic: string, observer: (_: any) => void): Subscription {
    if (!this.channels[topic]) {
      this.channels[topic] = new BehaviorSubject<any>(null);
    }
    return this.channels[topic].asObservable().subscribe(observer);
  }

  publish(topic: string, data?: any): void {
    if (!this.channels[topic]) {
      this.channels[topic] = new BehaviorSubject<any>(null);
    }
    this.channels[topic].next(data);
  }

  destroy(topic: string): void {
    const subject = this.channels[topic];
    if (!subject) {
      return;
    }
    subject.complete();
    delete this.channels[topic];
  }
}
