import { Directive, HostListener, inject } from "@angular/core";
import { NgControl } from "@angular/forms";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { NewBaseService } from "@providers/newBase.service";

@Directive({
  selector: "[codereOnlyNumbers]",
})
export class OnlyNumbersDirective {
  globalVars!: GlobalVarsModel;

  newBaseService = inject(NewBaseService);

  constructor(private control: NgControl) {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  @HostListener("input", ["$event"]) onInputChange(event: any) {
    const initialValue = this.control.control?.value;

    let formattedValue = initialValue.replace(/[^0-9.,]/g, "");

    if (this.isSpainOrColombiaOrArgentina()) {
      if (event.data === ".") {
        const position = event.target.selectionStart;
        (formattedValue = initialValue.slice(0, position - 1) + initialValue.slice(position)),
          {
            emitEvent: false,
          };
      }
      const commaCount = formattedValue.match(/,/g);
      if (commaCount?.length > 1) {
        formattedValue = formattedValue.replace(/,/g, (match, offset, string) =>
          string.indexOf(",") === offset ? "," : "",
        );
      }
    } else {
      if (event.data === ",") {
        const position = event.target.selectionStart;
        (formattedValue = initialValue.slice(0, position - 1) + initialValue.slice(position)),
          {
            emitEvent: false,
          };
      }
      const dotCount = formattedValue.match(/\./g);
      if (dotCount?.length > 1) {
        formattedValue = formattedValue.replace(/\./g, (match, offset, string) =>
          string.indexOf(".") === offset ? "." : "",
        );
      }
    }

    if (initialValue !== formattedValue) {
      this.control.control?.setValue(formattedValue);
    }
  }

  isSpainOrColombiaOrArgentina(): boolean {
    return (
      this.globalVars?.licenseType === LicenseTypes?.PaisVasco ||
      this.globalVars?.licenseType === LicenseTypes?.Nacional ||
      this.globalVars?.licenseType === LicenseTypes?.Madrid ||
      this.globalVars?.licenseType === LicenseTypes?.Colombia ||
      this.globalVars?.licenseType === LicenseTypes?.ArgentinaCaba ||
      this.globalVars?.licenseType === LicenseTypes?.ArgentinaCordoba ||
      this.globalVars?.licenseType === LicenseTypes?.ArgentinaMendoza ||
      this.globalVars?.licenseType === LicenseTypes?.ArgentinaPBA
    );
  }
}
