import { CommonModule } from "@angular/common";
import { Component, Input, OnChanges } from "@angular/core";
import { FormsModule } from "@angular/forms";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "sb-overview-score-regular",
  templateUrl: "./sb-overview-score-regular.html",
  standalone: true,
  imports: [CommonModule, FormsModule],
  styleUrls: ["./sb-overview-score-regular.scss"],
})
export class SbOverviewScoreRegularComponent implements OnChanges {
  @Input() scoreboard: any;
  sport: string;
  resultHome: number;
  resultAway: number;

  constructor() {}

  ngOnChanges() {
    this.sport = this.scoreboard.SportHandle;
    this.setResults();
  }

  setResults() {
    if (this.sport === "darts") {
      this.resultHome = this.scoreboard.Legs.reduce((total, leg) => (total += leg[0]), 0);
      this.resultAway = this.scoreboard.Legs.reduce((total, leg) => (total += leg[1]), 0);
    } else {
      this.resultHome = this.scoreboard.ResultHome;
      this.resultAway = this.scoreboard.ResultAway;
    }
  }
}
