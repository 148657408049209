import { ErrorService } from "@providers/ErrorService";
import { UserService } from "@providers/UserService";
import { Observable } from "rxjs";
import { PaymentMethodBehaviour, PaymentMethodBehaviourResult, SkipAmountResult } from "./PaymentMethodBase";

export class LocalCodereMethodBehaviour extends PaymentMethodBehaviour {
  minDepositAmount: number;
  maxDepositAmount: number;
  amountButtons: number[];
  name = "Local Codere";
  image = "iconChargeLocal";
  track = "AccessToLocalDep";
  isBank = false;
  declare amount: number;

  constructor(userService: UserService, errorService: ErrorService) {
    super(false, userService, errorService);
  }

  skipAmountInput(): Observable<SkipAmountResult> {
    throw new Error("Method not implemented.");
  }

  paymentMetodExecution(): Observable<PaymentMethodBehaviourResult> {
    throw new Error("Method not implemented.");
  }
}
