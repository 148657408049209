export class C_LiveData {
  ParticipantHome: string;
  ParticipantAway: string;
  Period: number;
  PeriodName: string;
  Actions: Array<C_LiveAction>;
  ResultHome: number;
  ResultAway: number;
  Time: string;
  MatchTime: number;
  RemainingPeriodTime: string;

  constructor(
    actions: Array<any>,
    ParticipantAway: string,
    participantHome: string,
    period: number,
    periodName: string,
    resultAway: number,
    resultHome: number,
    time: string,
  ) {
    this.Actions = actions;
    this.ParticipantAway = ParticipantAway;
    this.ParticipantHome = participantHome;
    this.Period = period;
    this.PeriodName = periodName;
    this.ResultAway = resultAway;
    this.ResultHome = resultHome;
    this.Time = time;
  }
}

export class C_LiveAction {
  Period: number;
  PeriodName: string;
  Time: number;
  ActionType: number;
  ActionTypeName: string;
  Participant: string;
  AffectedParticipant: string;
  IsHomeTeam: boolean;
}
