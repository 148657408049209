export class LocalizedValuesList {
  LocalizedValues: Array<LocalizedValue>;
  ReferenceId: number;
}

export class LocalizedValue {
  CountryCode: string;
  LanguageCode: string;
  Value: string;
}
