import { CommonModule } from "@angular/common";
import { Component, ElementRef, ViewChild } from "@angular/core";
import { FormsModule, NgForm } from "@angular/forms";
import { Router, RouterModule } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { IAuthenticationService } from "@interfaces/IAuthenticationService";
import { AlertController, IonInput, IonicModule, ModalController, NavParams, Platform } from "@ionic/angular";
import { CookieNames } from "@models/CookieNames";
import { LoginResponse } from "@models/LoginServices/LoginResponse";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { EventTypes } from "@models/TrackingEvents";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ExtractDatePipe } from "@pipes/extractDate.pipe";
import { ParseMoney } from "@pipes/parseMoney";
import { PipesModule } from "@pipes/pipes.module";
import { AppsFlyerTrackingService } from "@providers/AppsFlyerTrackingService";
import { AuthenticationService } from "@providers/AuthenticationService";
import { BiometricService } from "@providers/BiometricService";
import { CookiesService } from "@providers/CookiesService";
import { DecouplingUserServiceMSO } from "@providers/DecouplingUserServiceMSO.service";
import { ErrorService } from "@providers/ErrorService";
import { EventsService } from "@providers/EventsService";
import { GTMDataManagerService, TrackStatus } from "@providers/GTMDataManager.service";
import { GeolocationService } from "@providers/GeolocationService";
import { LegacyAuthenticationService } from "@providers/LegacyAuthenticationService";
import { LogService } from "@providers/LogService";
import { MessageService } from "@providers/MessageService";
import { NativeService } from "@providers/NativeService";
import { SlotsBonusesService } from "@providers/SlotsBonusesService";
import { TrackingService } from "@providers/TrackingService";
import { UserMigrationService } from "@providers/UserMigrationService";
import { UserService } from "@providers/UserService";
import { UserServiceMSO } from "@providers/UserServiceMSO";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { MSO_PATHS } from "@shared-constants/routes";
import { CapacitorGetPlatform } from "@shared-utils/constants";
import { Utils } from "@utils/Utils";
import { Observable, firstValueFrom, from, map } from "rxjs";
import { InformationModal } from "../information-modal/information-modal";

// eslint-disable-next-line no-var
declare var _tfa: any;
// eslint-disable-next-line no-var
declare var fbq;
@Component({
  templateUrl: "./login.html",
  selector: "login-page",
  styleUrls: ["./login.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule, RouterModule, PipesModule, TranslateModule, FormsModule],
  providers: [ExtractDatePipe],
})
export class Login {
  @ViewChild("userInput") userInput: any;
  login: { username?: string; password?: string; rememberMe?: boolean } = {};
  submitted = false;
  formData: { user: string; captcha: string };
  captchaUrl: string;
  activeSection: number;
  footerLastState: boolean;
  authenticationService: IAuthenticationService;
  lastLogin: Date;
  modifDateRegulatorFeature = "";
  IsProhibitedPA = false;
  showPassword = false;
  typePassword = "password";
  @ViewChild("viewPass") viewPass: ElementRef;
  @ViewChild("noviewPass") noviewPass: ElementRef;
  @ViewChild("userInput", { static: false }) inputElement: IonInput;
  globalVars!: GlobalVarsModel;
  isMobile = false;

  /* TODO INJECT */
  /**
   * NOTA Importante!!!,
   * no añadir mas servicios en CDI , ya que el app componente lo inicaliza fuera de CDI
   * con NEW en flujos distintos del login de la web
   */
  constructor(
    private trackingService: TrackingService,
    private translate: TranslateService,
    private extractDatePipe: ExtractDatePipe,
    private parseMoney: ParseMoney,
    private geolocationService: GeolocationService,
    private messageService: MessageService,
    private slotsBonusesService: SlotsBonusesService,
    private logService: LogService,
    private userMigrationService: UserMigrationService,
    private cookiesService: CookiesService,
    private userService: UserService,
    private userServiceMSO: UserServiceMSO,
    private errorService: ErrorService,
    private utils: Utils,
    private nativeService: NativeService,
    private newAuthenticationService: AuthenticationService,
    private legacyAuthenticationService: LegacyAuthenticationService,
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private platform: Platform,
    private viewCtrl: ModalController,
    private events: EventsService,
    private router: Router,
    private params: NavParams,
    private newBaseService: NewBaseService,
    private biometricService: BiometricService,
    private appsflyerTrackingService: AppsFlyerTrackingService,
    private deviceService: DeviceService,
    private decouplingService: DecouplingUserServiceMSO,
    private GTMService: GTMDataManagerService,
  ) {
    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
    this.isMobile = this.deviceService.isMobile();
    /*
     * solución tmporal para params.
     * se debe injectar con CDI y eliminar las incializacion del Login con un NEW
     */
    this.activeSection =
      this.params != null && this.params.get("activeSection") ? this.params.get("activeSection") : 1;
    this.formData = { user: "", captcha: "" };
    let tempname: string | null = null;
    try {
      tempname = localStorage.getItem("userNative");
    } catch (err) {
      this.logService.error("localStorage error");
    }
    if (tempname == null) {
      tempname = "";
    }

    this.login = {
      username: tempname,
      password: "",
      rememberMe: !this.globalVars.FEATURES.MSO_SkipAutologin,
    };
    this.globalVars.loginOpen = true;

    // Si existen parametros data.activeSection === 2, es un usuario
    // que ha intentado registrarse a partir de un preregistro
    // cuando ya estaba previamente registrado
    if (this.params && this.params.data && this.params.data["activeSection"] === 2) {
      const title = "INFO";
      const text = `
            <p>Hemos detectado que usted ya tiene una cuenta online</p>
            <p>Si lo desea le enviaremos un Email/SMS
            con los sencillos pasos que debe seguir para acceder a esa cuenta</p>`;
      this.utils.alert(true, title, text, "OK");
    }

    this.authenticationService = this.globalVars.FEATURES.NewLoginServices
      ? this.newAuthenticationService
      : this.legacyAuthenticationService;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.inputElement.setFocus();
    }, 150);
  }

  ionViewWillEnter() {
    this.footerLastState = this.globalVars.hideBetFooterBar;
    this.globalVars.hideBetFooterBar = true;
  }

  ionViewDidEnter() {
    if (this.globalVars.FEATURES.MSO_alertCookiesNoBlock) {
      if (!this.globalVars.isCookiesAccept) {
        if (this.globalVars.licenseType === LicenseTypes.Nacional) {
          this.globalVars.rootScope.alertCookies.dismiss();
          this.globalVars.rootScope.openCookiesConsent();
        } else {
          this.globalVars.rootScope.openAlertCookies();
        }

        this.closeLogin();
      }
    }
    if (this.globalVars.gotorecovery) {
      this.gotoSection(2);
      this.globalVars.gotorecovery = false;
    } else {
      setTimeout(() => {
        this.userInput.setFocus();
      }, 500);
    }
  }

  ionViewDidLeave() {
    this.globalVars.hideBetFooterBar = this.footerLastState;
    this.globalVars.loginOpen = false;
  }

  closeLogin(selfExlusion = false) {
    if (this.viewCtrl) {
      this.modalCtrl.dismiss();
      this.globalVars.loginOpen = false;
      if (selfExlusion) {
        this.msgLogin(this.lastLogin);
      }
    }
  }
  async showAcceptTermsArg(urlTyc: string, urlPrivacity: string, buttonHandler: any) {
    const msg = `${this.translate.instant(
      "tycarg01",
    )} <a external-link id="linktyc" target="_blank" href="${urlTyc}">${this.translate.instant(
      "tycarg02",
    )}</a> ${this.translate.instant(
      "and",
    )} <a external-link id="linktyc" target="_blank" href="${urlPrivacity}">${this.translate.instant(
      "tycarg03",
    )}</a>  ${this.translate.instant("tycarg04")}`;
    const alert = await this.alertController.create({
      header: this.translate.instant("important"),
      cssClass: "msgtycco",
      message: msg,
      buttons: [
        {
          text: this.translate.instant("tCancel"),
          role: "cancel",
        },
        {
          text: this.translate.instant("CodereCardconfir"),
          handler: buttonHandler,
        },
      ],
    });
    await alert.present();
  }
  async showAcceptTerms(url: string, hash: string, buttonHandler: any, isTerms: boolean, extraUrl?: any) {
    let msg: string = this.translate.instant("tfailcondiclegal") + "<br/>" + "<span><br/><br/>";

    if (this.globalVars.licenseType == LicenseTypes.Colombia) {
      const urlqa = this.userService.urlQA() ? "/csbgonlineco/" : "/csbgonline/";
      msg =
        this.translate.instant("tfailcondiclegalco") +
        '&nbsp;<span><a external-link id="linkCondicionesReg" target="_blank" href="' +
        url +
        '" class="ui-link">' +
        this.translate.instant("rCond1b") +
        "</a></span>" +
        this.translate.instant("the") +
        '&nbsp;<span><a external-link id="linkpolitictrata" target="_blank" href="' +
        extraUrl.urlPrivacePolitics +
        '" class="ui-link">' +
        this.translate.instant("poltratadatospersonal") +
        "</a></span>" +
        this.translate.instant("reg_tyc2") +
        '&nbsp;<span><a external-link id="linkautodatospers" target="_blank" href="' +
        extraUrl.urlautotratadatospersonal +
        '" class="ui-link">' +
        this.translate.instant("autotratadatospersonal") +
        "</a>.</span><br/><br/>";
    }

    if (!isTerms && this.globalVars.licenseType == LicenseTypes.Colombia)
      msg = this.translate.instant("tfailprivacepol") + "<br/>" + "<span><br/><br/>";

    let msgCheckbox: string =
      "<span>" +
      this.translate.instant("reg_tyc") +
      '</span>&nbsp;<a external-link id="linkCondicionesReg" target="_blank" href="' +
      url +
      '" class="ui-link"><span>' +
      this.translate.instant("rCond1b") +
      "</span>&nbsp;<span>" +
      this.translate.instant("reg_tyc2") +
      "</span><span>" +
      this.translate.instant("rCond2b") +
      "</span></a>";
    if (this.globalVars.licenseType == LicenseTypes.Colombia) {
      const url3 = "https://www.codere.com.co/informaci%C3%B3n-legal/Notificaci%C3%B3n-Cesi%C3%B3n"; //cambiar por buena
      msgCheckbox =
        "<span>" +
        this.translate.instant("reg_tyc_co") +
        "</span><br/><br/>" +
        "<span>" +
        this.translate.instant("notcontratoconcesion") +
        "</span>&nbsp;" +
        "<span>" +
        '<a external-link id="linkcesiontratamientodatosco" target="_blank" href="' +
        url3 +
        '" class="ui-link">' +
        ">>&nbsp;" +
        this.translate.instant("seeMore") +
        "</a>";
    }
    const alert = await this.alertController.create({
      header:
        this.globalVars.licenseType == LicenseTypes.Colombia
          ? this.translate.instant("titlemsgTyC")
          : "Importante",
      cssClass: this.globalVars.licenseType == LicenseTypes.Colombia ? "msgtycco" : "",
      message: msg + msgCheckbox,
      buttons: [
        {
          text: this.translate.instant("tCancel"),
          role: "cancel",
        },
        {
          text:
            this.globalVars.licenseType == LicenseTypes.Colombia
              ? this.translate.instant("tAccept")
              : this.translate.instant("CodereCardconfir"),
          handler: buttonHandler,
        },
      ],
    });
    await alert.present();
  }

  private loginNativeNew = (pwd: string): void => {
    try {
      localStorage.setItem("passNative", pwd);
    } catch (err) {
      console.error(err);
    }
  };

  canDoAutologinNative(): boolean {
    const user: string = localStorage.getItem("userNative");
    const encryptedPassword: string = localStorage.getItem("passNative");
    return Boolean(user) && Boolean(encryptedPassword);
  }

  doAutoLoginNative(): Observable<any> {
    const user: string = localStorage.getItem("userNative");
    const encryptedPassword: string = localStorage.getItem("passNative");
    const q = new Promise((resolve, reject) => {
      this.authenticationService.nativeAutoLogin(user, encryptedPassword).subscribe(
        (data) => {
          this.finishLogin(data, null, true, false);

          this.trackingService.trackEvent(["AutoLoginOk", "", user, "AutoLoginOk", "event"]);
          resolve(data.status);
        },
        (err) => {
          this.trackingService.trackEvent(["AutoLoginKO", "", user, "AutoLoginKO", "event"]);
          reject(err.status);
        },
      );
    });
    return from(q);
  }

  async loginTouchId(user: string, pass: string) {
    console.info("user", user);
    console.info("password", pass);

    this.submitted = true;
    await this.utils.loader();
    //checks if the app is running on IOS like standalone webapp
    const isIOSWebApp = Boolean(window.navigator["standalone"]);
    this.authenticationService.login(user.toLowerCase(), pass, isIOSWebApp).subscribe(
      (data) => {
        console.info("INGRESOOOO");

        this.loginNativeNew(data.encryptedPassword);
        this.finishLogin(data, null, false, true);
        this.utils.closeLoader();
      },
      (err) => {
        this.logService.error(err);
        this.events.publish("loading:finish");
        localStorage.removeItem("passNative");
        this.loginKo(err, null, true);
        this.biometricService.delete();
        this.utils.closeLoader();
      },
      () => {
        this.logService.log("fin query login");
      },
    );
  }

  /**
   * Manejador del evento click del boton Acceptar
   * del formulario pasado como parámetro
   * @param form
   */
  async doLogin(form) {
    this.submitted = true;
    if (form.valid) {
      //checks if the app is running on IOS like standalone webapp
      const isIOSWebApp = Boolean(window.navigator["standalone"]);
      await this.utils.loader();
      this.events.publish("loading:start");
      //si es nativo almaceno la pass encriptada si no viene autologin
      this.authenticationService
        .login(this.login.username.toLowerCase(), this.login.password, isIOSWebApp)
        .subscribe({
          next: async (data) => {
            try {
              if (this.biometricService.isNative) {
                await this.biometricService.setupBiometricLogin(this.login.username, this.login.password);
              }
            } catch (error: any) {
              this.logService.error(error);
            }
            if (this.globalVars.DEVICE_TYPE !== "web" && this.login.rememberMe) {
              if (data.encryptedPassword) {
                this.loginNativeNew(data.encryptedPassword);
              }
            } else if (this.globalVars.DEVICE_TYPE !== "web" && !this.login.rememberMe) {
              try {
                localStorage.removeItem("passNative");
              } catch (e) {
                this.logService.error(String(e));
              }
            }
            this.finishLogin(data, form, false, false);
          },
          error: async (err) => {
            this.logService.error(err);
            await this.utils.closeLoader();
            this.events.publish("loading:finish");
            this.loginKo(err, form, false);
          },
          complete: async () => {
            await this.utils.closeLoader();
            this.events.publish("loading:finish");
            this.logService.log("fin query logihjhjhjhjn");
          },
        });
    } else {
      this.utils.alert(true, "Login", this.translate.instant("checkField"), "OK", null, "", false);
    }
  }

  finishLogin(data: LoginResponse, form = null, autologin = false, isTouch = false) {
    this.lastLogin = data.lastLogin;
    if (data.status == "ok") {
      this.finishLoginOk(data, autologin, isTouch);
    } else {
      this.loginKo(data, form, isTouch);
    }
  }

  finishLoginOk(data: LoginResponse, autologin = false, isTouch = false) {
    this.modifDateRegulatorFeature = data.modifDateRegulatorFeature ? data.modifDateRegulatorFeature : "";
    this.globalVars.extraUserData.documentationVerification = data.documentationVerification;
    if (this.globalVars.FEATURES.MSO_GraceTimeLogicEnabled) {
      if (data.documentationVerification) {
        this.globalVars.extraUserData.graceTime = false;
      } else {
        if (this.utils.isArgentina()) {
          if (data.firstDepositDate) {
            this.utils.setGraceTime(data.firstDepositDate);
          } else {
            this.globalVars.extraUserData.graceTime = true;
          }
        } else {
          this.utils.setGraceTime(data.registratedDate);
        }
      }
    }
    if (this.globalVars.licenseType == LicenseTypes.Nacional) {
      this.globalVars.extraUserData.tycSelfie = !!data.policyPrivicyVerification;
    }
    if (this.globalVars.FEATURES.csrfControlFront) {
      this.globalVars.extraUserData.csrfControl = data.csrfControl;
    }
    this.finishLoginSuccess(data, autologin, isTouch);
  }

  loginKo(data: LoginResponse, form = null, isTouch = false) {
    if (this.globalVars.licenseType === LicenseTypes.Nacional && data.status === "Ex-Prohibited") {
      this.isExProhibited(data.balance, data.userName);
    } else if (data.status == "newtermsandconditions") {
      this.newTermsAndConditions(data, form, isTouch);
    } else if (data.status == "Newprivacepolitics") {
      this.newPrivacePoliticsAlert(data, form, isTouch);
    } else if (data["isTill"] && this.globalVars.licenseType == LicenseTypes.Panama) {
      this.dataTillPanama(data);
    } else if (data.status == "Bloqueado") {
      this.userBlock();
    } else if (data.errorCode == "302") {
      this.argentinaRedirectLicense(data);
    } else if (data.errorCode == "NOT_GEOLOCATED") {
      this.utils.alertMensozaGeolocationError();
    } else if (data.errorCode === "PROHIBITED") {
      this.utils.selfExclusionModal(2);
    } else {
      if (this.isInErrorCodes(data.errorCode)) {
        if (data.errorCode == "183") {
          this.changePasswordAlert(isTouch);
        } else if (data.errorCode == "107") {
          this.utils.confirm(
            false,
            this.translate.instant("fail_login_title"),
            this.translate.instant("usernameIncorrect"),
            this.translate.instant("tCancel"),
            () => {
              if (isTouch) {
                this.openLoginpostFail();
              }
            },
            "Recupérala",
            () => {
              setTimeout(() => {
                this.gotoSection(2);
              }, 300);
            },
          );
        } else {
          this.userServiceErrorAlert(data.errorCode, isTouch);
        }
      } else if (data.errorCode?.indexOf("ExitQuick") >= 0) {
        this.exitQuick(data);
      } else {
        // TODO: Revisar resto de los errores
        const funcAfterAlert = isTouch ? this.openLoginpostFail() : null;
        // this.utils.alert(true, this.translate.instant("fail_login_title"), this.translate.instant("m_reg_loginKo"), "OKey", funcAfterAlert);

        this.utils.alertLoginError(
          true,
          this.translate.instant("fail_login_title"),
          this.translate.instant("m_reg_loginKo"),
          [
            {
              text: "¿Olvidó su contraseña?",
              role: null,
              cssClass: "orange",
              handler: () => {
                console.info("Restaurar contraseña");
                this.gotoSection(2);
              },
            },
            { text: "OK", cssClass: "", role: null, handler: null },
          ],
          false,
          false,
        );
      }
    }

    //reloads the token
    this.events.publish("user:refreshToken");

    if (isTouch) {
      this.trackingService.trackEvent([
        "LoginKOTouchId",
        "",
        this.login.username.toLowerCase(),
        "LoginKOTouchId",
        "event",
      ]);
      this.biometricService.delete();
    } else {
      this.trackingService.trackEvent(["LoginKO", "", this.login.username.toLowerCase(), "LoginKO", "event"]);
    }
  }

  newTermsAndConditions(data: LoginResponse, form = null, isTouch = false) {
    if (
      this.globalVars.licenseType === LicenseTypes.Nacional ||
      this.globalVars.licenseType === LicenseTypes.Madrid
    ) {
      this.newTermsAndConditionsNacional(form, isTouch);
    } else {
      this.newTermsAndConditionsOthers(data, form, isTouch);
    }

    if (isTouch) {
      this.trackingService.trackEvent([
        "LoginKObynewtermsandconditionsTouchId",
        "",
        this.globalVars.user.username,
        "LoginKObynewtermsandconditionsTouchId",
        "event",
      ]);
    } else {
      if (this.globalVars.DEVICE_TYPE !== "web") {
        this.trackingService.trackEvent([
          "AutoLoginKObynewtermsandconditions",
          "",
          localStorage.getItem("userNative"),
          "AutoLoginKObynewtermsandconditions",
          "event",
        ]);
      } else {
        this.trackingService.trackEvent([
          "LoginKObynewtermsandconditions",
          "",
          this.globalVars.user.username,
          "LoginKObynewtermsandconditions",
          "event",
        ]);
      }
    }
  }

  newPrivacePoliticsAlert(data: LoginResponse, form = null, isTouch = false) {
    this.userServiceMSO.getLastPrivacyPoliticsUrlbyRegion(data.license).subscribe(
      async (data3) => {
        let urlTyc: string = data3.Url;
        let extraUrl = null;
        if (
          this.globalVars.licenseType === LicenseTypes.Colombia ||
          this.globalVars.licenseType === LicenseTypes.ArgentinaCaba
        ) {
          extraUrl = { urlPrivacePolitics: "", urlautotratadatospersonal: "" };
          const responseTyc = await this.userServiceMSO
            .getLastTermsAndConditionsUrlbyRegion(data.license)
            .toPromise();
          urlTyc = responseTyc.Url;
          extraUrl.urlPrivacePolitics = data3.Url;
          if (this.globalVars.licenseType === LicenseTypes.Colombia) {
            const responseautotratadatospersonal = await this.userServiceMSO
              .getLastAutoTrataDatosPersonalUrl(data.license)
              .toPromise();
            extraUrl.urlautotratadatospersonal = responseautotratadatospersonal.Url;
          }
        }
        if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
          this.showAcceptTermsArg(urlTyc, data3.Url, async () => {
            try {
              const request = await Promise.all([
                this.userServiceMSO.updateTermsAndConditions(this.login.username.toLowerCase()).toPromise(),
                this.userServiceMSO.updatePrivacePolitics(this.login.username.toLowerCase()).toPromise(),
              ]);
              if (request[0].status === "ok" && request[1].status === "ok") {
                if (isTouch) {
                  this.events.publish("user:openLogin");
                } else {
                  //ok
                  if (this.globalVars.DEVICE_TYPE !== "web" && this.canDoAutologinNative()) {
                    this.doAutoLoginNative();
                  } else {
                    this.doLogin(form);
                  }
                }
              } else {
                this.utils.showError(this.translate.instant("tfailactualiz"));
              }
            } catch (err_) {
              this.utils.showError(this.translate.instant("tfailactualiz"));
            }
          });
        } else {
          this.showAcceptTerms(
            urlTyc,
            data3.Hash,
            () => {
              const username: string = this.login.username.toLowerCase();
              this.logService.log("update politics:", username);
              this.userServiceMSO.updatePrivacePolitics(username).subscribe(
                (data2) => {
                  if (data2.status === "ok") {
                    if (this.globalVars.licenseType === LicenseTypes.Colombia) {
                      this.userServiceMSO.updateAuthorizationPersonalData(username).subscribe(
                        (apdResponse) => {
                          if (apdResponse.status === "ok") {
                            this.userServiceMSO.updateTermsAndConditions(username).subscribe(
                              (data4) => {
                                if (data4.status === "ok") {
                                  if (isTouch) {
                                    this.events.publish("user:openLogin");
                                  } else {
                                    //ok
                                    if (this.globalVars.DEVICE_TYPE !== "web" && this.canDoAutologinNative()) {
                                      this.doAutoLoginNative();
                                    } else {
                                      this.doLogin(form);
                                    }
                                  }
                                } else {
                                  this.utils.showError(this.translate.instant("tfailactualiz"));
                                }
                              },
                              (err) => {
                                this.utils.showError(this.translate.instant("tfailactualiz"));
                              },
                            );
                          } else {
                            this.utils.showError(this.translate.instant("tfailactualiz"));
                          }
                        },
                        (err) => {
                          this.utils.showError(this.translate.instant("tfailactualiz"));
                        },
                      );
                    } else {
                      if (isTouch) {
                        this.events.publish("user:openLogin");
                      } else {
                        //ok
                        if (this.globalVars.DEVICE_TYPE != "web" && this.canDoAutologinNative()) {
                          this.doAutoLoginNative();
                        } else {
                          this.doLogin(form);
                        }
                      }
                    }
                  } else {
                    this.utils.showError(this.translate.instant("tfailactualiz"));
                  }
                },
                (err) => {
                  this.utils.showError(this.translate.instant("tfailactualiz"));
                },
              );
            },
            false,
            extraUrl,
          );
        }
      },
      () => {
        this.utils.showError(this.translate.instant("tfailactualiz"));
      },
    );

    if (isTouch) {
      this.trackingService.trackEvent([
        "LoginKObynewprivacepoliticsTouchId",
        "",
        this.globalVars.user.username,
        "LoginKObynewprivacepoliticsTouchId",
        "event",
      ]);
    } else {
      if (this.globalVars.DEVICE_TYPE != "web") {
        this.trackingService.trackEvent([
          "AutoLoginKObynewprivacepolitics",
          "",
          localStorage.getItem("userNative"),
          "AutoLoginKObynewprivacepolitics",
          "event",
        ]);
      } else {
        this.trackingService.trackEvent([
          "LoginKObynewprivacepolitics",
          "",
          this.globalVars.user.username,
          "LoginKObynewprivacepolitics",
          "event",
        ]);
      }
    }
  }

  async newTermsAndConditionsNacional(form = null, isTouch = false) {
    this.closeLogin();
    let url = this.globalVars.DOMAIN + "/csbgonline/condicionesgenerales/pdfjs/";
    url += this.isMobile ? "mobile/index.html" : "index.html";
    const modal = await this.modalCtrl.create({
      component: InformationModal,
      cssClass: "is-modal",
      componentProps: { url: url },
    });
    await modal.present();
    await modal.onDidDismiss().then((acceptedConditions) => {
      if (acceptedConditions?.data === true) {
        const username: string = this.login.username.toLowerCase();
        this.logService.log("update terms:", username);
        this.userServiceMSO.updateTermsAndConditions(username).subscribe(
          (data2) => {
            if (data2.status === "ok") {
              if (isTouch) {
                this.events.publish("user:openLogin");
              } else {
                if (this.globalVars.DEVICE_TYPE !== "web" && this.canDoAutologinNative()) {
                  this.doAutoLoginNative();
                } else {
                  this.doLogin(form);
                }
              }
            } else {
              this.utils.showError(this.translate.instant("tfailactualiz"));
            }
          },
          (err) => {
            this.utils.showError(this.translate.instant("tfailactualiz"));
          },
        );
      }
    });
  }

  newTermsAndConditionsOthers(data: LoginResponse, form = null, isTouch = false) {
    this.userServiceMSO.getLastTermsAndConditionsUrlbyRegion(data.license).subscribe(
      async (data3) => {
        let extraUrl = null;
        if (
          this.globalVars.licenseType === LicenseTypes.Colombia ||
          this.globalVars.licenseType === LicenseTypes.ArgentinaCaba
        ) {
          extraUrl = { urlPrivacePolitics: "", urlautotratadatospersonal: "" };
          const responseprivacitypolitics = await this.userServiceMSO
            .getLastPrivacyPoliticsUrlbyRegion(data.license)
            .toPromise();
          extraUrl.urlPrivacePolitics = responseprivacitypolitics.Url;
          if (this.globalVars.licenseType === LicenseTypes.Colombia) {
            const responseautotratadatospersonal = await this.userServiceMSO
              .getLastAutoTrataDatosPersonalUrl(data.license)
              .toPromise();
            extraUrl.urlautotratadatospersonal = responseautotratadatospersonal.Url;
          }
        }
        if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
          this.showAcceptTermsArg(data3.Url, extraUrl.urlPrivacePolitics, async () => {
            try {
              const request = await Promise.all([
                this.userServiceMSO.updateTermsAndConditions(this.login.username.toLowerCase()).toPromise(),
                this.userServiceMSO.updatePrivacePolitics(this.login.username.toLowerCase()).toPromise(),
              ]);
              if (request[0].status === "ok" && request[1].status === "ok") {
                if (isTouch) {
                  this.events.publish("user:openLogin");
                } else {
                  //ok
                  if (this.globalVars.DEVICE_TYPE != "web" && this.canDoAutologinNative()) {
                    this.doAutoLoginNative();
                  } else {
                    this.doLogin(form);
                  }
                }
              } else {
                this.utils.showError(this.translate.instant("tfailactualiz"));
              }
            } catch (err_) {
              this.utils.showError(this.translate.instant("tfailactualiz"));
            }
          });
        } else {
          this.showAcceptTerms(
            data3.Url,
            data3.Hash,
            () => {
              const username: string = this.login.username.toLowerCase();
              this.logService.log("update terms:", username);
              this.userServiceMSO.updateTermsAndConditions(username).subscribe(
                (data2) => {
                  if (data2.status === "ok") {
                    if (this.globalVars.licenseType === LicenseTypes.Colombia) {
                      this.userServiceMSO.updateAuthorizationPersonalData(username).subscribe(
                        (apdResponse) => {
                          if (apdResponse.status === "ok") {
                            this.userServiceMSO.updatePrivacePolitics(username).subscribe(
                              (data4) => {
                                if (data4.status === "ok") {
                                  if (isTouch) {
                                    this.events.publish("user:openLogin");
                                  } else {
                                    //ok
                                    if (this.globalVars.DEVICE_TYPE !== "web" && this.canDoAutologinNative()) {
                                      this.doAutoLoginNative();
                                    } else {
                                      this.doLogin(form);
                                    }
                                  }
                                } else {
                                  this.utils.showError(this.translate.instant("tfailactualiz"));
                                }
                              },
                              (err) => {
                                this.utils.showError(this.translate.instant("tfailactualiz"));
                              },
                            );
                          } else {
                            this.utils.showError(this.translate.instant("tfailactualiz"));
                          }
                        },
                        (err) => {
                          this.utils.showError(this.translate.instant("tfailactualiz"));
                        },
                      );
                    } else {
                      if (isTouch) {
                        this.events.publish("user:openLogin");
                      } else {
                        //ok
                        if (this.globalVars.DEVICE_TYPE !== "web" && this.canDoAutologinNative()) {
                          this.doAutoLoginNative();
                        } else {
                          this.doLogin(form);
                        }
                      }
                    }
                  } else {
                    this.utils.showError(this.translate.instant("tfailactualiz"));
                  }
                },
                (err) => {
                  this.utils.showError(this.translate.instant("tfailactualiz"));
                },
              );
            },
            true,
            extraUrl,
          );
        }
      },
      (err) => {
        this.utils.showError(this.translate.instant("tfailactualiz"));
      },
    );
  }

  argentinaRedirectLicense(data: LoginResponse) {
    if (!this.globalVars.isNative && this.globalVars.licenseType != data["redirect"]) {
      window.location.replace(`${this.getURLToRedirect(data["redirect"])}?JWT=${data["jwt"]}`);
    }
  }

  userBlock() {
    this.utils.showError(
      this.translate.instant("alertbloquedactive")
        ? this.translate.instant("alertbloquedactive")
        : "Actualmente su cuenta está bloqueada y no se le permite el acceso a la plataforma",
    );
  }

  dataTillPanama(data: LoginResponse) {
    this.closeLogin();
    const modaltill = this.utils.alert(
      false,
      this.translate.instant("modalregtilltitle"),
      this.translate.instant("modalregtilltxt"),
      this.translate.instant("tAccept"),
      () => {
        const obj = {
          dataTill: data.playerInTill,
          documentationVerification: data.documentationVerification,
        };
        // TODO: revisar
        // this.globalVars.rootScope.openModalOrPage(RegistroPAPage, obj);
      },
    );
    ("modalregtill");
    // TODO: revisar
    // modaltill.setCssClass('modalregtill')
  }

  exitQuick(data: LoginResponse) {
    const fullTime = data.errorCode.slice(data.errorCode.indexOf("ExitQuick") + 16);
    const aTime = fullTime.split(" ");
    const literal = this.translate.instant("exitrapidtxt"); //Activaste la Salida Rápida y no podrás logarte hasta el
    const msg = `${literal} ${aTime[0]} ${this.translate.instant("lasatconexhora")} ${aTime[1]}`;
    this.utils.showMsg(msg);
  }

  getErrorCodes() {
    let errorCodes: string[] = [];
    errorCodes = this.globalVars.FEATURES.loginerrorCode ? this.globalVars.FEATURES.loginerrorCode.split(";") : [];
    return errorCodes;
  }

  isInErrorCodes(errorCode: string) {
    const errorCodes = this.getErrorCodes();
    return errorCode != undefined && errorCodes.length > 0 && errorCodes.find((e) => e === errorCode);
  }

  userServiceErrorAlert(errorCode: string, isTouch = false) {
    const funcAfterAlert = isTouch ? this.openLoginpostFail() : null;

    this.errorService.searchErrorCode("error" + errorCode).subscribe(
      (data) => {
        const msgCode: string = data;
        this.utils.alert(true, this.translate.instant("fail_login_title"), msgCode, "OK", funcAfterAlert);
      },
      (err) => {
        this.utils.alert(
          true,
          this.translate.instant("fail_login_title"),
          this.translate.instant("m_reg_loginKo"),
          "OK",
          funcAfterAlert,
        );
      },
    );
  }

  private finishLoginSuccess(data: LoginResponse, autologin: boolean, isTouch: boolean) {
    //TODO: !!!REvisar estos dos condicionales - son necesarios?
    if (data.userName.toLowerCase().indexOf("exc") >= 0) {
      //necesitamos hasta que esté el back poner esto para hacer las pruebas
      data.alertSelfExclusion = 1;
    } else if (data.userName.toLowerCase().indexOf("total") >= 0) {
      data.alertSelfExclusion = 2;
    }
    if ((this.globalVars.DEVICE_TYPE === "IOS" || this.globalVars.DEVICE_TYPE === "ANDROID") && data.idBGT) {
      this.nativeService.setLoggedUser(data.userName.toLowerCase(), this.login.password, data.idBGT);
    }
    if (this.globalVars.licenseType === LicenseTypes.Panama) {
      this.globalVars.isProhibited = data.isProhibited;
    }
    if (this.globalVars.FEATURES.FiveLoginPopup) {
      this.globalVars.fiveLoginOK = !!data.fiveLoginOK;
    }
    this.globalVars.extraUserData.limitsExpired = !!data.limitsExpired;

    this.globalVars.extraUserData.acceptanceComunication = !!data.acceptanceComunication;

    this.globalVars.comeFromLogin = true;
    this.globalVars.extraUserData.isRevocationPage = !!data.canRevocate;
    this.globalVars.user.idBGT = data.idBGT;
    this.globalVars.user.username = data.userName.toLowerCase();
    this.globalVars.user.balance = data.balance;
    this.globalVars.user.logged = true;
    this.globalVars.user.pendingSMSEmail = data.activeSmsEmail;
    this.globalVars.user.lastPassword = data.lastPassword;
    this.globalVars.sessionInitDate = new Date();
    this.globalVars.user.id = data.id;
    this.globalVars.user.email = data.email;
    if (data.userCardNumber) {
      this.globalVars.user.codereCard.cardNumber = data.userCardNumber;
      this.globalVars.user.codereCard.active = true;
      this.globalVars.user.codereCard.region = data.userCardNumber.substring(2, 2);
    } else {
      this.globalVars.user.codereCard.active = false;
    }
    this.events.publish("setUserInfoCookie");
    if (this.globalVars.licenseType === 0) {
      this.globalVars.extraUserData.professionOrPrpMissing = data.professionOrPrpMissing || "";
    }
    if (this.globalVars.licenseType === LicenseTypes.Nacional) {
      this.globalVars.user.lastLoginDate = new Date(data.lastLogin);
      this.globalVars.user.firstDeposit = !!data.firstDeposit;
      this.globalVars.user.registratedDate = new Date(data.registratedDate);
    }
    if (this.globalVars.FEATURES.MSO_TerminationCodereDecision) {
      this.globalVars.user.terminationCodereDecision = data.terminationCodereDecision;
    }
    if (this.utils.isArgentina()) {
      this.globalVars.user.sessionLimitsGasto = data.sessionLimitsGasto;
      this.globalVars.user.sessionLimitsPerdida = data.sessionLimitsPerdida;
      this.globalVars.user.sessionLimitsTiempo = data.sessionLimitsTiempo;
      this.globalVars.user.registratedDate = new Date(data.registratedDate);
      this.globalVars.user.verificacionDocumental = data.documentationVerification;
      if (this.globalVars.FEATURES.MSO_DocumentationVerificationStatus) {
        this.globalVars.extraUserData.documentationVerificationStatus = data.documentationVerificationStatus;
      }
    }
    if (this.globalVars.licenseType === LicenseTypes.ArgentinaMendoza) {
      this.globalVars.extraUserData.isForbiddenInMendoza = !!data.isForbiddenInMendoza;
    }

    if (this.globalVars.licenseType === LicenseTypes.Panama) {
      this.IsProhibitedPA = data.isProhibited;
    }
    this.userService.getCustomizations().subscribe((datax) => {
      this.utils.getCuotaAccept(datax.customizations);
    });
    try {
      localStorage.setItem("userNative", this.globalVars.user.username);
    } catch (err) {
      console.error(err);
    }

    if (data.userRegionAux !== undefined) {
      this.globalVars.user.userRegion = data.userRegionAux;
      this.globalVars.licenseType = data.userRegionAux;
    }
    this.cookiesService.setCookie(CookieNames.CodereUser, data.userName, 365 * 24 * 60 * 60 * 1000);
    if (this.userMigrationService.isMadridUserMigrationRequired()) {
      this.userMigrationService.migrateMadridUser(() => {
        this.afterLoginAdditionalChecks(data, autologin);
      });
    } else {
      this.afterLoginAdditionalChecks(data, autologin);
    }
    const labelID = isTouch ? "LoginOKTouchId" : "LoginOKNoTouchId";
    this.trackingService.trackEvent([labelID, "", this.login.username.toLowerCase(), labelID, "event"]);

    const loginEvent: any = {
      eventType: EventTypes.Login,
      id: this.globalVars.user.username,
      additionalData: {
        af_content_id: this.globalVars.user.username,
        userId: this.globalVars.user.username,
      },
    };
    if (this.globalVars.licenseType === LicenseTypes.Nacional) {
      this.utils
        .setGTMTrackers()
        .then((_) => {
          const auxTrackers = {
            signupDate: data.registratedDate,
            lastLoginDate: data.lastLogin?.toISOString(),
            balance_amt: data.balance,
            contactId: this.globalVars.user.idBGT,
            codereIdTrackers: this.globalVars.user.codereIdTracker,
            firstDeposit: this.globalVars.user.firstDeposit,
          };
          loginEvent.additionalData = {
            ...loginEvent.additionalData,
            ...auxTrackers,
          };
          // New tracking services
          this.trackingService.track(loginEvent);
          this.GTMService.addItem(
            {
              status: TrackStatus.OK,
              userId: auxTrackers.contactId,
              signupDate: new Date(auxTrackers.signupDate).toISOString(),
              lastLoginDate: auxTrackers.lastLoginDate,
              balance_amt: auxTrackers.balance_amt,
              advertiser: auxTrackers.codereIdTrackers.Mexos_Advertiser || "",
              profile: auxTrackers.codereIdTrackers.Mexos_ProfileID || "",
              acqSource: auxTrackers.codereIdTrackers.Mexos_Aquisition_Source || "",
              ID2: auxTrackers.codereIdTrackers.Mexos_ID2 || "",
              ID3: auxTrackers.codereIdTrackers.Mexos_ID3 || "",
            },
            "Login",
          );
        })
        .catch((err) => {
          this.errorService.logService.error("eventLoginTrakingError", err);
        });
    } else {
      // New tracking services
      this.trackingService.track(loginEvent);
    }

    if (Capacitor.getPlatform() !== CapacitorGetPlatform.WEB) {
      this.appsflyerTrackingService.trackEvent("af_login", loginEvent.additionalData);

      // Xtreme Login event
      this.nativeService.xtremeHitEvent("Login", "Login Event");
    }

    if (this.globalVars.licenseType == LicenseTypes.Colombia) {
      this.slotsBonusesService.taboolaTrack("Login");
      this.globalVars.extraUserData.pptNotVerification = !!data.isPPT;
    }

    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      // <!-- Facebook Pixel Code -->
      this.facebookPixelCode(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js",
        null,
        null,
        null,
      );
      fbq("init", "670176583366501");
      fbq("track", "Login", { content_ids: this.globalVars.user.idBGT });
    }

    this.globalVars.loginOpen = false;
    //si es Argentina, pedir geolocalizacion
    //browser

    if (this.globalVars.FEATURES.MSO_EnableGeolocation) {
      if (this.globalVars.FEATURES.MSO_TimerGeolocation) {
        this.geolocationService.setLoginGeolocation();
      } else {
        this.geolocationService.showGeolocation();
      }
    }

    // ***+*** 16/08/23 ToDo: Revisar ESTOS COMANDOS
    // if (this.globalVars.currentPage == PromotionsPage) {
    //   this.globalVars.gNav.push(PromotionsPage, {}, { animate: false });
    // }
  }

  // <!-- Facebook Pixel Code -->
  private facebookPixelCode(f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      // eslint-disable-next-line prefer-spread, prefer-rest-params
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = "2.0";
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  }

  private async afterLoginAdditionalChecks(data: LoginResponse, autologin: boolean) {
    if (data.selfExclusion && this.globalVars.licenseType !== LicenseTypes.Colombia) {
      const msg: string = "Ha finalizado tu periodo de Autoexclusion. <br/>¿Desea prorrogarlo?" + "<br/><br/>";
      const alert = await this.alertController.create({
        header: this.translate.instant("important"),
        message: msg,
        buttons: [
          {
            text: "NO",
            handler: () => {
              this.removeAutoExclusion();
              this.msgLogin(data.lastLogin);
            },
            role: "cancel",
          },
          {
            text: "Sí, deseo prorrogralo",
            handler: () => {
              this.newAutoexclusion();
            },
          },
        ],
      });
      await alert.present();
    } else if (!autologin) {
      this.msgLogin(data.lastLogin, data.alertSelfExclusion);
    } else {
      setTimeout(() => {
        this.events.publish("user:login");
      }, 500);
    }
  }

  private async msgLogin(lastLogin: Date, alertSelfExclusion: string | number = 0) {
    let msgBienvenida: string = this.translate.instant("m_reg_loginOk");

    const inicioRenaper = new Date("2021/08/19"); //Modificar la fecha 2030 cuando tengamos Renaper año-mes-dia
    if (
      (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba ||
        this.globalVars.licenseType === LicenseTypes.ArgentinaPBA) &&
      !this.globalVars.user.verificacionDocumental &&
      this.globalVars.user.registratedDate < inicioRenaper
    ) {
      const msgNoRenaper = this.translate.instant("norenaperverifdocum");
      msgBienvenida += `<p>${msgNoRenaper}</p>`;
      console.error("Logged User", { ...this.globalVars.user });
    }
    if (lastLogin) {
      if (this.globalVars.licenseType === LicenseTypes.Colombia)
        msgBienvenida +=
          "<br />" + this.translate.instant("lastconex") + " " + this.extractDatePipe.transform(lastLogin, ["3"]);
      else
        msgBienvenida +=
          "<br />" + this.translate.instant("lastconex") + " " + this.extractDatePipe.transform(lastLogin);
    }
    if (this.modifDateRegulatorFeature != "") {
      const dateRegulatorFeature = new Date(this.modifDateRegulatorFeature);
      const dateLastLogin = new Date(
        lastLogin.getFullYear(),
        lastLogin.getMonth(),
        lastLogin.getDate(),
        lastLogin.getHours(),
        lastLogin.getMinutes(),
        lastLogin.getSeconds(),
        lastLogin.getMilliseconds(),
      );
      if (dateRegulatorFeature.getTime() > lastLogin.getTime()) {
        try {
          msgBienvenida += await this.getRegulatorLimitsString();
        } catch (error) {
          console.error(error);
        }
      }
    }
    if (this.globalVars.licenseType == LicenseTypes.Panama && this.IsProhibitedPA) {
      msgBienvenida += "<br>";
      msgBienvenida += this.translate.instant("txtprohibidospa");
    }

    const title: string = this.translate.instant("m_reg_regOkT");
    if (this.globalVars.licenseType !== LicenseTypes.Mexico) {
      await this.utils.alert(
        false,
        title,
        msgBienvenida,
        this.utils.isArgentina() ? this.translate.instant("tAccept") : "OK",
        () => {
          setTimeout(async () => {
            this.events.publish("user:login");
            this.checkPasswordLogTime();

            if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba && Number(alertSelfExclusion) > 0) {
              this.utils.selfExclusionModal(alertSelfExclusion);
            } else if (this.globalVars.licenseType === LicenseTypes.ArgentinaPBA && alertSelfExclusion == 2) {
              const alert = await this.alertController.create({
                header: this.translate.instant("tInf"),
                message:
                  "Actualmente tu cuenta se encuentra bloqueada para realizar depósitos y apuestas por encontrarte dentro del registro de personas autoexcluidas del IPLyC.",
                backdropDismiss: false,
                cssClass: "info-autoexclusion",
                buttons: [
                  {
                    text: "OK",
                    handler: () => {
                      alert.dismiss();
                    },
                  },
                ],
              });
              alert.present();
            }
          }, 500);
          return true;
        },
        "welcome-modal",
        true,
      );
    } else {
      this.events.publish("user:login");
      this.checkPasswordLogTime();
    }
    this.closeLogin();
  }

  newAutoexclusion() {
    if (this.viewCtrl) {
      this.modalCtrl.dismiss();
    }
    this.globalVars.renewSelfExclusion = true;
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.SelfExclusionPage}`], {
      queryParams: { animate: false },
    });
  }

  async checkPasswordLogTime() {
    try {
      const region = this.globalVars.user.userRegion;
      const FechaDePassword = this.globalVars.user.lastPassword;
      if (
        (region === LicenseTypes.Nacional ||
          region === LicenseTypes.Colombia ||
          region === LicenseTypes.ArgentinaCaba) &&
        this.cookiesService.getCookie(CookieNames.WarningCoderePassword) == null
      ) {
        let limitDays = 0;
        switch (region) {
          case LicenseTypes.Nacional:
            limitDays = 180;
            break;
          case LicenseTypes.Colombia:
            limitDays = 250;
            break;
          case LicenseTypes.ArgentinaCaba:
            limitDays = 365;
            break;
        }
        if (this.checkDates(FechaDePassword) > limitDays) {
          await this.utils.alert(false, "", this.translate.instant("tfailpassrenov"), "OK");
          this.cookiesService.setCookie(CookieNames.WarningCoderePassword, "secure", 180 * 24 * 60 * 60 * 1000);
        } else {
          this.cookiesService.setCookie(CookieNames.WarningCoderePassword, "secure", 180 * 24 * 60 * 60 * 1000);
        }
      }
    } catch (e) {
      this.logService.error("Fail checkPasswordLogTime");
    }
  }

  checkDates(previusDate: any) {
    previusDate = this.extractTimeMaintenace(previusDate);
    const actualDate: any = new Date();
    return (actualDate - previusDate) / 1000 / 3600 / 24;
  }

  extractTimeMaintenace(time: string | Date) {
    return time instanceof Date ? time : new Date(parseInt(time.slice(6, time.length - 2)));
  }

  removeAutoExclusion() {
    this.userServiceMSO.removeAutoExclusion().subscribe((data) => {
      if (data.success) this.logService.log("Autoexclusion eliminada");
      else this.logService.error("Eliminacion de Autoexclusion fallida");
    });
  }
  newCaptcha() {
    this.captchaUrl = this.globalVars.URLBASE + "/ViewTicket/CaptchaImage?_=" + Math.floor(Math.random() * 99999);
  }

  openLoginpostFail() {
    this.nativeService.reOpenLogin();
  }

  openRegistro() {
    this.logService.log(`openRegistro: ${this.globalVars.user}`);

    this.viewCtrl.dismiss(null, null).then(() => {
      this.globalVars.rootScope.openRegisterByLicense();
    });
  }

  /**
   * Función responsable de la navegación entre las secciones
   *      - 1: Login
   *      - 2: Recuperar contraseña
   * En el caso 2 se solicita una nueva captcha
   * @param numSection: número de la seccion activa (1,2)
   */
  gotoSection(numSection: number) {
    this.activeSection = numSection;
    if (numSection == 2) {
      this.newCaptcha();
    }
  }

  /**
   * Función responsble de solicitar una nueva contraseña
   * Primero comprueba si exixte una cookie que indique
   * que ya se ha solicitado una nueva contraseña.
   * Si es asi regresa a la seccion 1 (Pantalla de Loguin)
   * Si no hay cookie, utiliza el servicio userService.forgotPassword.
   * Con la respuesta genera la cookie correspondiente
   * @param form - Formulario de recuperación de contraseña
   */
  doGetPassword(form: NgForm) {
    if (form.valid) {
      if (!this.cookiesService.getCookie(CookieNames.ForgotPassword)) {
        this.userService.forgotPassword(this.formData.user, this.formData.captcha).subscribe((data) => {
          if (data.status == "ok") {
            this.utils.showMsg(this.translate.instant("m_reg_sendPassOk"), () => {
              this.cookiesService.setCookie(CookieNames.ForgotPassword, "send", 1000 * 60 * 10);
              this.activeSection = 1;
              this.formData.user = "";
              this.formData.captcha = "";
            });
          } else {
            if (data.errCode == "CaptchaFailure") {
              this.utils.showError(this.translate.instant("captchaFail"));
            } else {
              if (data.errCode != undefined) {
                if (isNaN(data.errCode)) {
                  this.utils.showError(data.errCode);
                } else {
                  this.errorService.searchErrorCode(data.errCode).subscribe(
                    (data3) => {
                      this.utils.showError(data3);
                    },
                    (err) => {
                      this.utils.showError(this.translate.instant("m_reg_sendPassKo"));
                    },
                  );
                }
              } else {
                this.utils.showError(this.translate.instant("m_reg_sendPassKo"));
              }
            }
          }
        });
      } else {
        this.utils.showMsg(this.translate.instant("m_reg_sendPassOk"), () => {
          this.activeSection = 1;
          this.formData.user = "";
          this.formData.captcha = "";
        });
      }
    } else {
      if (!!this.formData.user && this.formData.user.toLocaleLowerCase() == "eventstrackingtesttool") {
        const component = this.platform.is("capacitor")
          ? MSO_PATHS.InformationPageMobile
          : MSO_PATHS.InformationPage;

        this.router.navigate([`/${MSO_PATHS.BASE}/${component}`], {
          queryParams: { type: "eventstrackingtesttool", animate: this.isMobile },
          queryParamsHandling: "merge",
        });

        this.trackingService.trackEvent([
          "AccessToEventsTrackingTestToolMobile",
          "",
          "hidelink",
          "Pagina de EventsTrackingTest",
          "event",
        ]);
        this.closeLogin();
        return;
      } else {
        if (!!this.formData.user && this.formData.user.toLocaleLowerCase() == "eventstrackingtesttool") {
          this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.InformationPage}`], {
            queryParams: { type: "eventstrackingtesttool", animate: this.isMobile },
            queryParamsHandling: "merge",
          });

          this.trackingService.trackEvent([
            "AccessToEventsTrackingTestTool",
            "",
            "hidelink",
            "Pagina de EventsTrackingTest",
            "event",
          ]);
          this.closeLogin();
        } else {
          this.utils.alert(
            true,
            this.translate.instant("tRemPass"),
            this.translate.instant("checkField"),
            "OK",
            null,
          );
        }
      }
    }
  }

  public onlyNumbers() {
    this.formData.captcha = this.formData.captcha.replace(/[^0-9]/g, "");
  }

  async getRegulatorLimitsString() {
    let msgdepositlimits = "";
    if (
      this.globalVars.FEATURES.depositLimitRegulatorStartDate &&
      this.globalVars.FEATURES.depositLimitRegulatorStartDate !== ""
    ) {
      const startdate = new Date(this.globalVars.FEATURES.depositLimitRegulatorStartDate);
      startdate.setHours(0, 0, 0, 0);
      const enddate =
        this.globalVars.FEATURES.depositLimitRegulatorEndDate &&
        this.globalVars.FEATURES.depositLimitRegulatorEndDate != ""
          ? new Date(this.globalVars.FEATURES.depositLimitRegulatorEndDate)
          : null;
      const datenow = new Date();
      if (enddate) {
        enddate.setHours(0, 0, 0, 0);
      }
      datenow.setHours(0, 0, 0, 0);
      if ((enddate && enddate.getTime() >= datenow.getTime()) || !enddate) {
        const data = await firstValueFrom(
          this.globalVars.FEATURES.MSO_DecouplingLimits
            ? this.decouplingService.decouplingGetLimits().pipe(
                map((response) => ({
                  day: `${response.depositsDay}`,
                  week: `${response.depositsWeek}`,
                  month: `${response.depositsMonth}`,
                })),
              )
            : this.userServiceMSO.getLimits(),
        );
        let intDay: number = parseInt(data.day);
        let intWeek: number = parseInt(data.week);
        let intMonth: number = parseInt(data.month);

        if (intDay == -1 || intDay == 0) intDay = parseInt(this.globalVars.FEATURES.depositDayLimit);

        if (intWeek == -1 || intWeek == 0) intWeek = parseInt(this.globalVars.FEATURES.depositWeekLimit);

        if (intMonth == -1 || intMonth == 0) intMonth = parseInt(this.globalVars.FEATURES.depositMonthLimit);
        if (
          (this.globalVars.FEATURES.depositDayLimitRegulator &&
            parseInt(this.globalVars.FEATURES.depositDayLimitRegulator) > 0) ||
          (this.globalVars.FEATURES.depositWeekLimitRegulator &&
            parseInt(this.globalVars.FEATURES.depositWeekLimitRegulator) > 0) ||
          (this.globalVars.FEATURES.depositMonthLimitRegulator &&
            parseInt(this.globalVars.FEATURES.depositMonthLimitRegulator) > 0)
        ) {
          if (
            intDay > parseInt(this.globalVars.FEATURES.depositDayLimitRegulator) ||
            intWeek > parseInt(this.globalVars.FEATURES.depositWeekLimitRegulator) ||
            intMonth > parseInt(this.globalVars.FEATURES.depositMonthLimitRegulator)
          ) {
            msgdepositlimits += `<br><br> ${this.translate.instant("limitsregulator")} ${startdate.getDate()}/${
              startdate.getMonth() + 1
            }/${startdate.getFullYear()}`;
          }
        }
      }
    }
    return msgdepositlimits;
  }

  isExProhibited(balance = 0, userName = "") {
    this.utils.confirm(
      false,
      "",
      this.translate.instant("selfexclusionperiod"),
      "No",
      async () => {
        this.utils.loader();
        try {
          await this.userService.reactivateAccount(userName, false).toPromise();
        } catch (error) {
          console.error(error);
        }
        this.utils.closeLoader();
        const alertNoAccept = await this.utils.alert(
          false,
          "",
          `${this.translate.instant("accountclosedselfbanning01")} ${this.parseMoney.transform(
            balance.toString(),
            [1],
          )} ${this.translate.instant("accountclosedselfbanning02")}`,
          "Aceptar",
          () => {
            this.closeLogin();
          },
        );

        const wrapperAlert = alertNoAccept.querySelector(".alert-wrapper");
        const link = wrapperAlert.querySelector("a");
        link.style.cursor = "pointer";
        link.addEventListener("click", ($event) => {
          $event.preventDefault();
          alertNoAccept.dismiss();
          this.router.navigateByUrl(`${MSO_PATHS.BASE}/${MSO_PATHS.ContactPage}`);
          this.closeLogin();
        });
      },
      "Sí",
      () => {
        //Sí acepta
        this.utils.loader();
        this.userService.reactivateAccount(userName).subscribe(
          (data) => {
            this.utils.closeLoader();
            if (data.errCode && data.errCode === "190") {
              this.errorService.searchErrorCode("error" + data.errCode).subscribe(
                async (datax) => {
                  const msgCode: string = datax;
                  const alert = await this.utils.alert(true, "", msgCode, "OK");

                  const wrapperAlert = alert.querySelector(".alert-wrapper");
                  const link = wrapperAlert.querySelector("a");
                  link.style.cursor = "pointer";
                  link.addEventListener("click", ($event) => {
                    $event.preventDefault();
                    alert.dismiss();
                    this.router.navigateByUrl(`${MSO_PATHS.BASE}/${MSO_PATHS.ContactPage}`);
                    this.closeLogin();
                  });
                },
                (err) => {
                  console.error(err);
                },
              );
            } else {
              const btn: HTMLElement = document.querySelector("#btnaccess") as HTMLElement;
              btn.click();
            }
          },
          (err) => {
            this.utils.closeLoader();
          },
        );
      },
      false,
    );
  }

  doLogOut() {
    this.userServiceMSO.doLogout().subscribe(
      () => {
        this.events.publish("user:logout");
      },
      (err) => {
        this.events.publish("user:logout");
      },
    );
  }

  onClickShowPassword() {
    this.showPassword = !this.showPassword;
    if (this.showPassword) {
      this.typePassword = "text";
    } else {
      this.typePassword = "password";
    }
  }

  getURLToRedirect(license: number) {
    const urls = JSON.parse(this.globalVars.FEATURES.loginURLredirect) || [];
    return urls.find((u) => u[license])[license];
  }

  changePasswordAlert(isTouch: boolean) {
    if (this.globalVars.licenseType === LicenseTypes.Nacional) {
      const confirmAlert = this.utils
        .confirmcustom(
          false,
          this.translate.instant("tChangePass"),
          this.translate.instant("changePasstxt"),
          this.translate.instant("tCancel"),
          () => {
            if (isTouch) {
              this.openLoginpostFail();
              localStorage.removeItem("passNative");
              this.biometricService.delete();
            }
          },
          this.translate.instant("tChangePass"),
          () => {
            setTimeout(() => {
              this.gotoSection(2);
            }, 300);
          },
          false,
          "changePasswordAlert",
        )
        .then((confirmAlertI) => {
          confirmAlertI.present().then(() => {
            const nodeIonAlert = confirmAlertI.querySelector(".alert-wrapper");
            const nodeTitle = nodeIonAlert.querySelector(".alert-title");
            const nodeHeader = nodeIonAlert.querySelector(".alert-head");
            const nodeIcon = document.createElement("ion-icon");
            nodeIcon.setAttribute("name", "lock-closed-outline");
            nodeIcon.setAttribute("role", "img");
            nodeIcon.classList.add("icon", "icon-md", "ion-lock-outline");
            nodeIcon.setAttribute("aria-label", "lock-outline");
            nodeHeader.insertBefore(nodeIcon, nodeTitle);
          });
        });
    } else {
      this.utils.confirm(
        false,
        this.translate.instant("fail_login_title"),
        this.translate.instant("accountBlocked"),
        this.translate.instant("tCancel"),
        () => {
          if (isTouch) {
            this.openLoginpostFail();
            localStorage.removeItem("passNative");
            this.biometricService.delete();
          }
        },
        this.translate.instant("retrieveit"),
        () => {
          setTimeout(() => {
            this.gotoSection(2);
          }, 300);
        },
      );
    }
  }
}
