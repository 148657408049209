import { Component, Input, inject } from "@angular/core";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { NewBaseService } from "@providers/newBase.service";
import { of, switchMap } from "rxjs";

@Component({
  selector: "codere-style-money",
  template: '<span [innerHTML]="valor"></span>',
  styleUrls: ["./codere-style-money.scss"],
  standalone: true,
})
export class CodereStyleMoneyComponet {
  @Input() amount: string;
  @Input() type: any;

  valor: string;
  globalVars: GlobalVarsModel;
  newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars
      .pipe(
        switchMap((data: GlobalVarsModel) => {
          this.globalVars = data;
          return of(null);
        }),
      )
      .subscribe();
  }

  ngOnChanges() {
    switch (this.type) {
      case typeMoney.freeBet:
        this.valor = this.freebetMoney();
        break;
      case typeMoney.keyboard:
        this.valor = this.betkeyboardMoney();
        break;
      default:
        this.valor = this.freebetMoney();
    }
  }

  freebetMoney(): string {
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Mexico:
        return "<sub>$</sub>" + this.amount;
      case LicenseTypes.Colombia:
        return this.amount + "<sub>$</sub>";
      case LicenseTypes.Panama:
        return this.amount + "<sub>$</sub>";
      case LicenseTypes.ArgentinaCaba:
        return this.amount + "<sub>$</sub>";
      case LicenseTypes.ArgentinaPBA:
        return this.amount + "<sub>$</sub>";
      case LicenseTypes.ArgentinaCordoba:
        return this.amount + "<sub>$</sub>";
      case LicenseTypes.ArgentinaMendoza:
        return this.amount + "<sub>$</sub>";
      default:
        return this.amount + "<sub>&euro;</sub>";
    }
  }

  betkeyboardMoney(): string {
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Mexico:
        return "<b>$</b>" + this.amount;
      case LicenseTypes.Colombia:
        return this.amount + "<sub>$</sub>";
      case LicenseTypes.Panama:
        return this.amount + "<sub>$</sub>";
      case LicenseTypes.ArgentinaCaba:
        return this.amount + "<sub>$</sub>";
      case LicenseTypes.ArgentinaPBA:
        return this.amount + "<sub>$</sub>";
      case LicenseTypes.ArgentinaCordoba:
        return this.amount + "<sub>$</sub>";
      case LicenseTypes.ArgentinaMendoza:
        return this.amount + "<sub>$</sub>";
      default:
        return this.amount + "<b>&euro;</b>";
    }
  }
}

//eslint-disable-next-line
enum typeMoney {
  freeBet = 0,
  keyboard,
}
