import { Component, OnDestroy, ViewChild, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonContent, IonicModule, NavController, NavParams } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { CookiesService } from "@providers/CookiesService";
import { EventsService } from "@providers/EventsService";
import { TrackingService } from "@providers/TrackingService";
import { UserService } from "@providers/UserService";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { MSO_PATHS } from "@shared-constants/routes";
import { CodereFooterComponent } from "@shared-mso-components/footer/codere-footer/codere-footer";
import { UserSectionTitle } from "@shared-mso-components/user-section-title/user-section-title";
import { Utils } from "@utils/Utils";
import { of, switchMap } from "rxjs";

import { CommonModule } from "@angular/common";
import { Router } from "@angular/router";

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "comunication-page",
  templateUrl: "./comunication.html",
  styleUrls: ["./comunication.scss"],
  imports: [IonicModule, FormsModule, TranslateModule, CodereFooterComponent, UserSectionTitle, CommonModule],
})
export class ComunicationPage implements OnDestroy {
  @ViewChild(IonContent) content: IonContent;
  selectedOddType!: string;
  selectedCuotaChange: any;
  firstEnter = true;
  opciones: { phone: boolean; sms: boolean; mail: boolean; alerts: boolean } = {
    phone: false,
    sms: false,
    mail: false,
    alerts: false,
  };
  anyOption: boolean;
  allOptions!: boolean;
  sectionTitle: string;
  texts: any;
  comeFrontAlert = false;
  isUpdate = false;
  globalVars!: GlobalVarsModel;
  isMobile: boolean;

  nav = inject(NavController);
  navParams = inject(NavParams);
  trackingService = inject(TrackingService);
  newBaseService = inject(NewBaseService);
  utils = inject(Utils);
  translate = inject(TranslateService);
  events = inject(EventsService);
  userService = inject(UserService);
  cookiesService = inject(CookiesService);
  route = inject(Router);
  device = inject(DeviceService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.sectionTitle = this.translate.instant("communicationoptions").toUpperCase(); //'Opciones de Comunicación'
    this.setTexts();
    this.opciones = {
      phone: null,
      sms: null,
      mail: null,
      alerts: null,
    };
    this.anyOption = this.opciones["phone"];
    if (!!this.navParams.get("item") && !!this.navParams.get("item").comeFromAlert) {
      this.comeFrontAlert = this.navParams.get("item").comeFromAlert == "true";
    }
    this.userService.getComunications().subscribe((resp: any) => {
      if (resp.status == 0) {
        if (!this.comeFrontAlert) {
          this.opciones = {
            phone: resp.result.allowSendPhoneCall,
            sms: resp.result.allowSendSMS,
            mail: resp.result.allowSendEmail,
            alerts: resp.result.allowSendFax,
          };
        } else {
          this.opciones = {
            phone: false,
            sms: false,
            mail: false,
            alerts: false,
          };
        }

        this.anyOption =
          this.opciones["phone"] || this.opciones["sms"] || this.opciones["mail"] || this.opciones["alerts"];
        this.allOptions =
          this.opciones["phone"] && this.opciones["sms"] && this.opciones["mail"] && this.opciones["alerts"];
      }
    });

    this.isMobile = this.device.isMobile();
  }

  ngOnDestroy(): void {
    if (this.comeFrontAlert && !this.isUpdate && this.globalVars.user.logged) {
      this.globalVars.rootScope.updateshowAlertComunicationsPrefences();
    }
  }

  setTexts() {
    this.texts = {};
    this.texts.canales =
      this.translate.instant("communicationcanaltx01") !== "communicationcanaltx01"
        ? this.translate.instant("communicationcanaltx01")
        : `Selecciona los canales a través de los cuales quieres recibir información
        sobre noticias, bonos ofertas y promociones.`;
    this.texts.momento =
      this.translate.instant("communicationcanaltx02") !== "communicationcanaltx02"
        ? this.translate.instant("communicationcanaltx02")
        : `Puedes actualizar tus preferencias en cualquier momento`;
    this.texts.selectAll =
      this.translate.instant("communicationacept ") !== "communicationacept "
        ? this.translate.instant("communicationacept ")
        : `Seleccionar todos`;
    this.texts.phonecall =
      this.translate.instant("phonecall") !== "phonecall"
        ? this.translate.instant("phonecall")
        : "Llamada telefónica";
    this.texts.applicationalerts =
      this.translate.instant("applicationalerts") !== "applicationalerts"
        ? this.translate.instant("applicationalerts")
        : "Alertas de Aplicación";
    this.texts.elige =
      this.translate.instant("communicationoptionsselect") !== "communicationoptionsselect"
        ? this.translate.instant("communicationoptionsselect")
        : "Elige las opciones de comunicacion";
    this.texts.cambios =
      this.translate.instant("communicationtime") !== "communicationtime"
        ? this.translate.instant("communicationtime")
        : `Si realizas algun cambio, puede tardar entre 24 y 48 horas en ser efectivo`;
    this.texts.legal =
      this.translate.instant("communicationlegal") !== "communicationlegal"
        ? this.translate.instant("communicationlegal")
        : `Te informamos que con el objeto de proteger tus datos
        y dar cumplimiento a nuestra relación contractual,
        podrás recibir comunicaciones de carácter no comercial, entre otras:
        comunicaciones relativas a la recuperación de contraseñas y/o verificación de tu identidad,
        notificaciones acerca de la actividad de tu cuenta,
        y avisos y comunicaciones que estemos obligados a remitirte por imperativo legal.
        Estas comunicaciones no comerciales tienen como base legitimadora
        la correcta ejecución del contrato de juego
        y el cumplimiento de las obligaciones legales correspondientes`;
  }

  ionViewWillEnter() {
    this.route.navigate([MSO_PATHS.BASE + "/" + MSO_PATHS.ComunicationPage]);
    this.trackingService.trackEvent(["AccessToComunication", "", "", "Acceder a comunicacion", "event"]);
    this.events.publish("footer:refresh", this.content);
  }

  /**
   * Manejador de eventos del boton actualizar
   */
  update() {
    this.userService
      .updateComunications(this.opciones)
      .pipe(
        switchMap((resp) => {
          if (resp.status === 0) {
            this.isUpdate = true;
            if (this.comeFrontAlert) {
              return this.userService.SetAcceptanceComunication();
            }
          }
          return of(null);
        }),
      )
      .subscribe({
        next: (_) => {
          if (this.comeFrontAlert) {
            this.globalVars.extraUserData.acceptanceComunication = false;
          }
          this.route.navigateByUrl(this.globalVars.mainPageComponent);
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  /**
   * Manejador de eventos del checkbox "Acepto recibir comunicadiones" (todas)
   */
  setOptions() {
    if (this.allOptions) {
      for (const key in this.opciones) {
        this.opciones[key] = true;
      }
    }
  }

  /**
   * Manejador de eventos de los checkbox de cada
   */
  setAllOptions() {
    this.anyOption =
      this.opciones["phone"] || this.opciones["sms"] || this.opciones["mail"] || this.opciones["alerts"];
    this.allOptions =
      this.opciones["phone"] && this.opciones["sms"] && this.opciones["mail"] && this.opciones["alerts"];
  }
}
