import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, delay, finalize, tap } from "rxjs/operators";

import { DomSanitizer } from "@angular/platform-browser";
import { AlertController } from "@ionic/angular";
import { AlertOptions } from "@ionic/core";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateService } from "@ngx-translate/core";
import { NewBaseService } from "@providers/newBase.service";
import { Utils } from "@utils/Utils";

const ticketInterceptorUrlListToProcess: string[] = ["SmartMarket"];

@Injectable({ providedIn: "root" })
export class TicketInterceptor implements HttpInterceptor {
  globalVars!: GlobalVarsModel;

  //#region Inject Services
  newBaseService = inject(NewBaseService);
  utils = inject(Utils);
  sanitizer = inject(DomSanitizer);
  translate = inject(TranslateService);
  alertCtrl = inject(AlertController);
  //#endregion
  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const processUrl = this.checkUrl(req.url);

    if (processUrl) {
      // Bloque de código con la funcionalidad antes de realizar la llamada
      // Do nothing.
    }

    return next.handle(req).pipe(
      delay(0),
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Si la llamada finaliza correctamente
          this.onEnd(event);
        }
      }),
      catchError((error) => {
        // Si la llamada ha finalizado con error
        this.onEnd(error, true);
        return this.handleError(error);
      }),
      finalize(() => {
        // TO DO
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  private onEnd(event: any, isError: boolean = false): void {
    if (isError) {
      // La request ha finalizado con error
      if (event.url) {
        // Se verifica que la url está en la lista de url's a procesar
        const processUrl = this.checkUrl(event.url);
        // procesar el error.
        if (processUrl) {
          // Si existe, se procesa el error devuelto en la respuesta.
          this.openPopUp(event);
        }
      } else {
        // Si el evento no tiene url
        // do nothing
      }
    }
  }

  private checkUrl(url: string): boolean {
    // Verifica si la url está en la lista de url's
    return ticketInterceptorUrlListToProcess.some((element) => url.toLowerCase().includes(element.toLowerCase()));
  }

  private handleError(error: any): Observable<never> {
    // Devuelve el error
    return throwError(() => error);
  }

  async openPopUp(data: any): Promise<void> {
    const msgAux: string = this.getText(data.error.statusCode, data.error.statusMessage);
    const msg: any = this.sanitizer.bypassSecurityTrustHtml(msgAux);
    const alertOpt: AlertOptions = {
      header: this.translate.instant("PBS_Error_Header"),
      message: this.translate.instant(msg.changingThisBreaksApplicationSecurity),
      buttons: [
        {
          text: this.translate.instant("entender"),
          role: "cancel",
        },
      ],
    };
    const alert = await this.alertCtrl.create(alertOpt);
    // const imgHeader = document.createElement('img');
    // imgHeader.classList.add('icon-circle-info');
    // imgHeader.src = 'assets/global/img/icon-circle-info.svg';
    const child = alert.getElementsByClassName("alert-button-group")[0];
    child.classList.add("sports-alert-button");
    await alert.present();
  }

  getText(literalKey: string, defaultMsg: string): string {
    if (!literalKey) return this.translate.instant("error01");
    let message = `PBS_Error_${literalKey}`;
    message = message.replace("\\n", "<br>");
    return message;
  }
}
