import { CommonModule } from "@angular/common";
import { Component, Input, inject } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ParseMoney } from "@pipes/parseMoney";
import { PayValidaCashPaymentMethodBehaviour } from "@providers/PaymentMethodService/PayValida";

@Component({
  selector: "cs-payvalida-ok",
  templateUrl: "./cs-payvalida-ok.html",
  styleUrls: ["./cs-payvalida-ok.scss"],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, ParseMoney],
})
export class CsPayvalidaOkComponent {
  @Input() payvalidaResult: any;
  @Input() payValidaCashPaymentMethodBehaviour: PayValidaCashPaymentMethodBehaviour;

  translate = inject(TranslateService);

  public get getLogo() {
    if (this.payValidaCashPaymentMethodBehaviour.type == "efecty") {
      return `assets/global/img/${this.payValidaCashPaymentMethodBehaviour.name.toLowerCase()}.png`;
    } else {
      return `assets/global/img/${this.payValidaCashPaymentMethodBehaviour.type}.png`;
    }
  }
}
