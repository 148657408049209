import { TransactionActionResult } from "@models/C_Transactions";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EMPTY } from "rxjs";
import { map } from "rxjs/operators";
import { WithDrawalBase } from "./withdrawal-base";

export class CoinspaidWithDrawal extends WithDrawalBase {
  tlf: string;
  pincode: string;

  constructor(args: any) {
    super(args);
  }

  checkPayment() {
    return this.checkPaymentRequest().pipe(map((response: TransactionActionResult) => response));
  }

  executePayment() {
    return this.executeRequest().pipe(map((response: TransactionActionResult) => response));
  }

  showErrorCallback() {
    return EMPTY;
  }

  executeOkCallback() {
    return EMPTY;
  }
}
