import { C_LobbyGame } from "@models/lobby";
/* eslint-disable eqeqeq */
import { Observable } from "rxjs";

export class C_JackpotInfo {
  readonly id: string;
  name: string;
  description: string;
  games: C_LobbyGame[];
  ids: any[];
  potId: string;
  currentAmount: Observable<number>;
  currentAmounts: [Observable<number>, Observable<number>];
  userJoined?: boolean;
  representativeIdGame?: string;
  jackpotId?: string;
  jackpotProvider?: string;
  constructor() {}

  static parse(obj): C_JackpotInfo {
    if (!obj) return;
    const ids = obj.games ? obj.games.reduce((acc, el) => [...acc, el.gameId], []) : [];
    const jackpot = {
      id: obj.jackpotId,
      name: obj.jackpotName,
      description: obj.jackpotDescription,
      ids: Array.from(new Set(ids)),
      games: [],
      currentAmount: obj.currentAmount,
      potId: obj.potId,
      jackpotId: obj.jackpotId,
      jackpotProvider: obj.jackpotProvider,
    };
    return Object.assign(new C_JackpotInfo(), jackpot);
  }

  public setGames(games: C_LobbyGame[]) {
    this.games = games.filter((game) => this.ids.indexOf(game.GameId) !== -1);
  }

  public setJackPotsGames(games: C_LobbyGame[], isDesktop: boolean) {
    const selectedGames: C_LobbyGame[] = [];
    const jackpotsId: string[] = [];
    games.map((game) => {
      if (this.ids.indexOf(game.GameId) !== -1 && jackpotsId.indexOf(game.GameId) === -1) {
        if (isDesktop) {
          jackpotsId.push(game.GameId);
          selectedGames.push(game);
        } else if (game.GameTileSize === ("Smallsquare" || "Bigsquare")) {
          jackpotsId.push(game.GameId);
          selectedGames.push(game);
        }
      }
    });
    this.games = selectedGames;
  }

  public getGames() {
    return this.ids.reduce((acc, id) => [...acc, { gameId: id }], []);
  }

  public get representativeGameId() {
    this.representativeIdGame = this.ids[0];
    return { gameId: this.ids[0] };
  }

  public userHasJoined(jackpots: string[]) {
    this.userJoined = jackpots.indexOf(this.id) !== -1;
  }

  public potHasChanged(pot: any) {
    if (!pot || this.potId !== pot.potId) return;
    this.currentAmount = pot.progressive;
  }
  public potProviderHasChanged(potChanged: any) {
    if (
      !!potChanged &&
      this.jackpotId == potChanged.JackpotId &&
      this.jackpotProvider == potChanged.JackpotProvider
    ) {
      this.currentAmount = potChanged.CurrentAmount;
    } else {
      return;
    }
  }
}
