import { Component, inject } from "@angular/core";
import { IonicModule } from "@ionic/angular";

import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { DeviceService } from "@services/device.service";

import { BackButton } from "@shared-common-components/back-button/back-button";
import { CodereFooterComponent } from "@shared-mso-components/footer/codere-footer/codere-footer";
import { UserSectionTitle } from "@shared-mso-components/user-section-title/user-section-title";

import { CommonModule } from "@angular/common";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { AppsFlyerTrackingService } from "@providers/AppsFlyerTrackingService";
import { Utils } from "@utils/Utils";
import { take } from "rxjs";
import { CurrencyList, DefaultLogData, EventNames, IAppsFlyerEventLog } from "./const";
import { FirebaseCrashlyticsService } from "@providers/firebase-crashlytics-service/firebase-crashlytics.service";

@Component({
  standalone: true,
  selector: "information-page-mobile",
  templateUrl: "./information-page-mobile.html",
  styleUrls: ["./information-page-mobile.scss"],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    IonicModule,
    TranslateModule,
    CodereFooterComponent,
    BackButton,
    UserSectionTitle,
  ],
})
export class InformationPageMobile {
  type: string;
  texto = "";
  globalVars!: GlobalVarsModel;
  isMobile: boolean;
  resultText: string[] = [];
  defaultLogData = DefaultLogData;
  currencyList = CurrencyList;
  eventNames = EventNames;
  selectedContent: string = this.eventNames.LOGIN;
  customOptions = {
    subHeader: "Select your Currency",
    translucent: true,
  };
  form: FormGroup;
  public enabled: boolean | undefined;

  private utils = inject(Utils);
  private device = inject(DeviceService);
  private router = inject(Router);
  private activatedRouter = inject(ActivatedRoute);
  private fb = inject(FormBuilder);
  private appsFlyertrackingService = inject(AppsFlyerTrackingService);
  private crashLytics = inject(FirebaseCrashlyticsService);

  constructor() {
    this.isMobile = this.device.isMobile();

    this.activatedRouter.queryParams.pipe(take(1)).subscribe((params) => {
      if (params["type"]) {
        this.type = params["type"];
      }
      this.init();
    });

    this.form = this.fb.group({
      af_login: this.fb.group({
        username: [null],
      }),
      af_complete_registration: this.fb.group({
        username: [null],
        email: [null],
      }),
      af_purchase: this.fb.group({
        username: [null],
        currency: [null],
        amount: [null],
      }),
      sdeposit: this.fb.group({
        username: [null],
        currency: [null],
        amount: [null],
      }),
    });
  }

  ionViewWillEnter() {
    this.utils.hideFormAccessoryBar(false);
  }

  ionViewDidEnter() {
    this.init();
  }

  init() {
    switch (this.type) {
      case "eventstrackingtesttool":
        this.texto = "Welcome Events Tracking Test Mobile";
        break;
      default:
        this.backToHome();
        break;
    }
  }

  buttonOnClick(id: string) {
    this.selectedContent = id;
  }

  submit(form: any, id: string) {
    try {
      const value = form.value[id];
      let postMessage: IAppsFlyerEventLog;
      let eventName: string;
      switch (id) {
        case this.eventNames.LOGIN:
          eventName = this.eventNames.LOGIN;
          postMessage = { af_content_id: value.username };
          break;
        case this.eventNames.REGISTER:
          eventName = this.eventNames.REGISTER;
          postMessage = { af_content_id: value.username };
          break;
        case this.eventNames.FIRST_DEPOSIT:
          this.cleanIsFirstDepositCookie();
          eventName = this.eventNames.FIRST_DEPOSIT;
          postMessage = {
            af_content_id: value.username,
            af_revenue: value.amount,
            af_currency: value.currency,
          };
          break;
        case this.eventNames.SUB_DEPOSIT:
          eventName = this.eventNames.SUB_DEPOSIT;
          postMessage = {
            af_content_id: value.username,
            af_revenue: value.amount,
            af_currency: value.currency,
          };
          break;
        default:
          break;
      }
      return this.postEvent(eventName, postMessage);
    } catch (error: any) {
      return { error: error.message };
    }
  }

  postEvent(eventName: string, postMessage: IAppsFlyerEventLog) {
    const restult = `${eventName} succeed`;
    this.resultText.push(restult);
    this.appsFlyertrackingService.trackEvent(eventName, postMessage);
  }

  cleanIsFirstDepositCookie() {
    if (document.location.href.toLowerCase().startsWith("file://")) {
      document.cookie = "isFirstDeposit=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
    } else {
      document.cookie =
        "isFirstDeposit=; expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=" + this.getCookieDomain() + ";path=/";
    }
  }

  getCookieDomain() {
    var host = document.location.host.toLowerCase();
    switch (host.substr(host.length - 3, 3)) {
      case ".mx":
        return ".codere.mx";
      case ".co":
        return ".codere.com.co";
      case ".pa":
        return ".codere.pa";
      default:
        return ".codere.es";
    }
  }

  public async enable(): Promise<void> {
    this.enabled = true;
    this.crashLytics.setEnabled(true);
  }

  public async disable(): Promise<void> {
    this.enabled = false;
    this.crashLytics.setEnabled(false);
  }

  public async isEnabled(): Promise<void> {
    const result = await this.crashLytics.isEnabled();
    this.enabled = result;
  }

  public async logTest(): Promise<void> {
    const message = "test log";
    await this.crashLytics.addLogMessage(message);
  }

  public async crash(): Promise<void> {
    this.crashLytics.crash("Test Crash From Information Page");
  }
  backToHome() {
    this.router.navigate(["/"], { queryParams: { animate: false } });
  }

  // on destroy
  ionViewDidLeave() {
    this.utils.hideFormAccessoryBar(true);
  }
}
