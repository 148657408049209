/* eslint-disable eqeqeq */
import { Injectable } from "@angular/core";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { ErrorMsgs } from "@interfaces/interfaces";

@Injectable({ providedIn: "root" })
export class ValidationService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  selectError(errors: ErrorMsgs, formControl: FormControl) {
    for (const key in errors) {
      if (Object.prototype.hasOwnProperty.call(errors, key)) {
        const errorMsg = errors[key];
        if (formControl.hasError(key)) {
          return errorMsg;
        }
      }
    }
    return "Error no definido";
  }

  /**
   * Aplica markAsTouched a todos controles
   * incluso si est´na en form<Grups de segundo nivel
   * @param formGroup
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        this.adjustTouchedIonItem(field, control);
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  adjustTouchedIonItem(ionItemId: string, control: AbstractControl) {
    ionItemId = ionItemId[0] == "#" ? ionItemId : "#" + ionItemId;
    const ionItem = document.querySelector(ionItemId) ? document.querySelector(ionItemId) : "";
    if (ionItem) {
      ionItem.classList.add("ng-touched");
      ionItem.classList.remove("ng-untouched");
      if (ionItem.classList.contains("item-select") && control.invalid) {
        ionItem.classList.add("ng-invalid");
      }
    }
  }

  adjustValidIonItem(ionItemId: string, control: AbstractControl) {
    ionItemId = ionItemId[0] == "#" ? ionItemId : "#" + ionItemId;
    const ionItem = document.querySelector(ionItemId) ? document.querySelector(ionItemId) : "";
    if (ionItem) {
      if (control.valid) {
        ionItem.classList.add("ng-valid");
        ionItem.classList.remove("ng-invalid");
      } else {
        ionItem.classList.add("ng-invalid");
        ionItem.classList.remove("ng-valid");
      }
    }
  }
}
