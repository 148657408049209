import { OxxoDepositActionResult } from "@models/C_Transactions";
import { ErrorService } from "@providers/ErrorService";
import { UserService } from "@providers/UserService";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
/* eslint-disable */
import {
  IFramePaymentMethodBehaviourResult,
  PaymentMethodBehaviour,
  type SkipAmountResult,
} from "./PaymentMethodBase";

export abstract class OxxoBankPaymentMethodBehaviour extends PaymentMethodBehaviour {
  type: string;

  constructor(
    public userService: UserService,
    type: string,
    public errorService: ErrorService,
  ) {
    super(false, userService, errorService);
    this.type = type;
    this.documentNumberRequired = true;
  }

  isBank = true;
  amountButtons: number[] = [300, 400, 800, 1000];
  minDepositAmount = 100;
  maxDepositAmount = 999999999;
  track = "AccessToOxxoBank";
  amount = 400;

  skipAmountInput(): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  paymentMetodExecution(): Observable<IFramePaymentMethodBehaviourResult> {
    return this.userService.validateDepositOxxoBank("", this.amount, this.documentNumber, this.type).pipe(
      map((obj) => {
        return this.map(obj);
      }),
    );
  }

  private map(input: OxxoDepositActionResult): IFramePaymentMethodBehaviourResult {
    const result = new IFramePaymentMethodBehaviourResult();
    if (input.success) {
      result.success = true;
      result.iframeType = "oxxo";
      result.iframeArgs = {
        type: "oxxo",
        url: encodeURI(input.url),
        msg: false,
      };
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams(
        "error" + input.errCode,
        input.errorParameters,
      );
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode == 174 || input.errCode == 2212;
    }
    return result;
  }
}

export class BancomerOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public userService: UserService,
    public errorService: ErrorService,
  ) {
    super(userService, "BV", errorService);
  }

  name = "Bancomer";
  image = "Bancomer";
}

export class BanamexOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public userService: UserService,
    public errorService: ErrorService,
  ) {
    super(userService, "BM", errorService);
  }

  name = "Banamex";
  image = "Banamex";
}

export class SantanderOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public userService: UserService,
    public errorService: ErrorService,
  ) {
    super(userService, "SM", errorService);
  }

  name = "Santander";
  image = "Santander";
}

export class AmericanExpressOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public userService: UserService,
    public errorService: ErrorService,
  ) {
    super(userService, "AE", errorService);
  }
  isBank = false;
  name = "OxxoBank-AmericanExpress";
  image = "AmericanExpress";
  track = "AccessToOxxoBankAmericanExpress";
}

export class AztecaBankOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public userService: UserService,
    public errorService: ErrorService,
  ) {
    super(userService, "AZ", errorService);
  }
  name = "OxxoBank-AztecaBank";
  image = "BancoAzteca";
  track = "AccessToOxxoAztecaBank";
}

export class BanorteBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public userService: UserService,
    public errorService: ErrorService,
  ) {
    super(userService, "BQ", errorService);
  }

  name = "Banorte";
  image = "Banorte";
}

export class SpeiBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public userService: UserService,
    public errorService: ErrorService,
  ) {
    super(userService, "SE", errorService);
  }
  isBank = false;
  name = "ASTROSPI";
  image = "logo-spei";
  track = "AccessToSpei";
}
export class PaynetOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public userService: UserService,
    public errorService: ErrorService,
  ) {
    super(userService, "PN", errorService);
  }
  isBank = false;
  name = "PayNetOxxo";
  image = "PayNetOxxo";
}

export class ToditoOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public userService: UserService,
    public errorService: ErrorService,
  ) {
    super(userService, "TC", errorService);
  }
  isBank = false;
  name = "Todito";
  image = "Todito";
}

export class CarullaOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public userService: UserService,
    public errorService: ErrorService,
  ) {
    super(userService, "CR", errorService);
  }
  isBank = false;
  name = "Carulla";
  image = "logo_carulla";
  documentNumberRequired = false;
}
export class GanaBankOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public userService: UserService,
    public errorService: ErrorService,
  ) {
    super(userService, "GG", errorService);
  }
  name = "OxxoBank-GanaBank";
  image = "ganagana";
  track = "AccessToOxxoBankGana";
  documentNumberRequired = false;
}

export class AlmacenesExitoOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public userService: UserService,
    public errorService: ErrorService,
  ) {
    super(userService, "EX", errorService);
  }
  name = "OxxoBank-AlamacenesExitoBank";
  image = "almacenesExito";
  track = "AccessToOxxoBankAlmacenesExito";
  documentNumberRequired = false;
}
export class PagoTodoOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public userService: UserService,
    public errorService: ErrorService,
  ) {
    super(userService, "TP", errorService);
  }
  isBank = false;
  name = "PagoTodo";
  image = "logo_pagoTodo";
  documentNumberRequired = false;
}
export class CucutaOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public userService: UserService,
    public errorService: ErrorService,
  ) {
    super(userService, "TA", errorService);
  }
  isBank = false;
  name = "Cucuta";
  image = "logoto_cucuta";
  documentNumberRequired = false;
}
export class GanaCercaBankOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public userService: UserService,
    public errorService: ErrorService,
  ) {
    super(userService, "GC", errorService);
  }
  name = "OxxoBank-GanaCercaBank";
  image = "ganaCerca";
  track = "AccessToOxxoBankGanaCerca";
  documentNumberRequired = false;
}
export class BancolombiaBankOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public userService: UserService,
    public errorService: ErrorService,
  ) {
    super(userService, "GG", errorService);
  }
  name = "OxxoBank-BanColombia";
  image = "banColombia";
  track = "AccessToOxxoBankColombia";
  documentNumberRequired = false;
}
export class VisaOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public userService: UserService,
    public errorService: ErrorService,
  ) {
    super(userService, "VI", errorService);
  }
  name = "OxxoBank-Visa";
  image = "Visa_epg";
  track = "AccessToOxxoVisa";
  documentNumberRequired = true;
}
export class MasterCardOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public userService: UserService,
    public errorService: ErrorService,
  ) {
    super(userService, "MC", errorService);
  }
  name = "OxxoBank-MasterCard";
  image = "Logo_MasterCard";
  track = "AccessToOxxoMasterCard";
  documentNumberRequired = true;
}
export class BalotoOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public userService: UserService,
    public errorService: ErrorService,
  ) {
    super(userService, "BU", errorService);
  }
  name = "OxxoBank-Baloto";
  image = "logo-viabaloto";
  track = "AccessToOxxoBaloto";
  documentNumberRequired = false;
}
/*export class PSEOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour{
    constructor (public userService: UserService){
        super(userService,"PC")
    }
    name: string = "OxxoBank-PSE";
    image: string = "Spe";
    track:string ="AccessToOxxoPSE";
    documentNumberRequired=false;
}^*/
