import { CommonModule } from "@angular/common";
/* eslint-disable eqeqeq */
import { Component, HostListener, Input, type OnInit, Renderer2, inject } from "@angular/core";
import { type FormControl, type FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ErrorMsgs } from "@interfaces/interfaces";
import { IonicModule } from "@ionic/angular";
import { ControlErrorComponent } from "../control-error/control-error.component";
import { InfoComponent } from "../info/info.component";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "cdr-input",
  templateUrl: "./input.component.html",
  standalone: true,
  imports: [CommonModule, IonicModule, ReactiveFormsModule, ControlErrorComponent, InfoComponent],
  styleUrls: ["./input.component.scss"],
})
export class InputComponent implements OnInit {
  static counter = 0;
  @Input() type: string;
  @Input() length: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() errorsCol: ErrorMsgs;
  @Input() modal: string;
  @Input() fcName: string;
  @Input() fgrp: FormGroup;
  @Input() preImg: string;
  @Input() mat: boolean;
  fControl: FormControl;
  isPasswd: boolean;
  showPassword = false;
  isFocused = false;
  hasLabel = false;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  renderer = inject(Renderer2);

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.changeLabelFlagPosition();
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  ngOnInit() {
    this.type = this.type ? this.type : "text";
    this.isPasswd = this.type === "password";
    this.placeholder = this.placeholder ? this.placeholder : "";
    this.fControl = this.fgrp?.get(this.fcName) as FormControl;
    this.hasLabel = this.label?.length > 0;
    this.changeLabelFlagPosition();
  }

  onBlur() {
    this.isFocused = false;
  }

  onFocus() {
    this.isFocused = true;
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  reValidate() {
    this.fControl.updateValueAndValidity();
  }

  changeLabelFlagPosition(): void {
    const labelElement = document.querySelector(".label-flag") as HTMLElement;
    if (labelElement) {
      const divFlagElement = document.querySelector(".div-flag") as HTMLElement;
      this.renderer.setStyle(labelElement, "left", `-${divFlagElement.offsetWidth + 22}px`);
    }
  }
}
