<div class="containerP">
  <div>
    <div class="marginb15 texto"><span [innerHTML]="'revocationtxt01' | translate" class="txtspan"></span></div>
    <div class="btncenter">
      <div class="userform">
        <div class="txtblack">{{'revocationtxt02' | translate }}</div>
        <user-form> </user-form>
      </div>
      <ion-row class="w100">
        <ion-col size="8" size-md="3">
          <ion-button (click)="doRevocation()" class="btnwhite" [class.padding2070]="isDesktop" expand="full">
            {{'revocation_se_btn' | translate }}
          </ion-button>
        </ion-col>
        <ion-col size="8" size-md="3">
          <ion-button (click)="sendEmailRevocation()" class="btnemail" [class.padding2070]="isDesktop" expand="full">
            {{'modifydata' | translate }}
          </ion-button>
        </ion-col>
      </ion-row>
      <div class="txtbtn">{{'revocationtxt03' | translate }}</div>
    </div>
  </div>
</div>
