import { PagueloFacilDepositActionResult } from "@models/C_Transactions";
import { ErrorService } from "@providers/ErrorService";
import { UserService } from "@providers/UserService";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
/* eslint-disable */
import {
  PaymentMethodBehaviour,
  PaymentMethodBehaviourResult,
  RedirectMethodBehaviourResult,
  SkipAmountResult,
} from "./PaymentMethodBase";

export class PagueloFacilMethodBehaviourResult extends PaymentMethodBehaviourResult {
  transactionCode: string;
  code: string;
  amount: number;
  date: string;
}

export class PagueloFacilSkipAmountResult extends SkipAmountResult {
  transactionCode: string;
  code: string;
  amount: number;
  date: string;

  constructor() {
    super(true);
  }
}

export class PagueloFacilPaymentMethodBehaviour extends PaymentMethodBehaviour {
  name = "PAGUELOFACIL";
  image = "logo-paguelofacil";
  track = "AccessToPagueloFacil";
  isBank = false;
  minDepositAmount: number;
  maxDepositAmount: number;
  amountButtons: number[] = [20, 30, 50, 100, 150, 200];
  amount = 100;

  constructor(userService: UserService, errorService: ErrorService) {
    super(false, userService, errorService);
  }

  skipAmountInput(): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  paymentMetodExecution(): Observable<RedirectMethodBehaviourResult> {
    return this.userService.createDepositByPagueloFacil(this.amount, this.name.toUpperCase()).pipe(
      map((obj) => {
        return this.map(obj);
      }),
    );
  }

  private map(input: PagueloFacilDepositActionResult): RedirectMethodBehaviourResult {
    const result = new RedirectMethodBehaviourResult();
    if (input.success) {
      result.url = input.url;
      result.success = true;
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams(
        "error" + input.errCode,
        input.errorParameters,
      );
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode == 174 || input.errCode == 2212;
      result.sendToProfesion = input.errCode == 2412;
    }
    return result;
  }
}
