import * as SidebarActions from "./sidebar.actions";
import { SidebarEffects } from "./sidebar.effects";
import { SidebarFacade } from "./sidebar.facade";
import { sidebarReducer } from "./sidebar.reducers";

export { SidebarEffects } from "./sidebar.effects";
export { SidebarFacade } from "./sidebar.facade";
export { sidebarReducer } from "./sidebar.reducers";
export * as SidebarActions from "./sidebar.actions";

export const SidebarStoreIndex = [SidebarActions, SidebarEffects, SidebarFacade, sidebarReducer];
