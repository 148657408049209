import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable eqeqeq */
import { AlertController, LoadingController, ModalController, ToastController } from "@ionic/angular";
import * as Forge from "node-forge";
import { Subject } from "rxjs";
import { Barcode } from "../providers/Barcode";

import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { InfoAutoexclusion } from "@components/mso/info-autoexclusion/info-autoexclusion";
import { Constants } from "@constants";
import { C_Features } from "@models/C_Features";
import { DecouplingInfoPlayer } from "@models/I_DecouplingInfoPlayer";
import { TypeLogout } from "@models/I_DecouplingLogout";
import { I_UserInfo } from "@models/I_UserInfo";
import { TRAKERSTYPECODEREID, TrackersCodereId } from "@models/TrackingEvents";
import { IabService } from "@providers/iabService";
import { CASINO_PATHS, SPORTS_PATHS } from "@shared-constants/routes";
import { BarcodeScanner } from "capacitor-barcode-scanner";
import { CApplicationState } from "../models/CApplicationState";
import { C_Customization } from "../models/C_Customization";
// todo: revisar cuando este migrado y corregido
/* import { ModalTyCSelfiePage } from '../pages/index'; */
import { CookieNames } from "../models/CookieNames";
import { I_NewCarousel } from "../models/I_NewCarousel";
import { LicenseTypes } from "../models/MasterData";
import { GlobalVarsModel } from "../models/ModelGlobalsVar";
import { ParseMoney } from "../pipes/parseMoney";
import { EventsService } from "../providers/EventsService";
import { NewChatService } from "../providers/NewChat.service";
import { NewBaseService } from "../providers/newBase.service";
import { DeviceService } from "../services/device.service";

interface NewChatData {
  id: string;
  src: string;
  data_app_id: string;
  data_lcw_version: string;
  data_org_id: string;
  data_org_url: string;
}

@Injectable({ providedIn: "root" })
export class Utils {
  barCodeGenerator: Barcode;
  alertOpened = false;
  paramStore: object = {};
  openAccder$: Subject<boolean>;
  modalBonusCOOpen = false;
  colombiaBonusModal = false;
  globalVars!: GlobalVarsModel;
  loading: any;
  isLoading = false;
  isMobile: boolean;
  //#region Inject Services
  // public parseMoney: ParseMoney,
  http = inject(HttpClient);
  alertController = inject(AlertController);
  loadingCtrl = inject(LoadingController);
  events = inject(EventsService);
  toastCtrl = inject(ToastController);
  modalCtrl = inject(ModalController);
  newChatService = inject(NewChatService);
  newBaseService = inject(NewBaseService);
  router = inject(Router);
  location = inject(Location);
  device = inject(DeviceService);
  parseMoney = inject(ParseMoney);
  iab = inject(IabService);

  partner: string;
  currentPage: any;
  isAlreadyCheckBonusHome = false;
  private currentPageHashValue = "";
  hasTicket = true;
  ApplicationState: CApplicationState = new CApplicationState();
  hideBetFooterBar: boolean;
  //#endregion
  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.barCodeGenerator = new Barcode();
    this.openAccder$ = new Subject();
    this.isMobile = this.device.isMobile();
  }

  public handleError(error: Response) {
    //TODO
    console.error(error);
  }

  SetPartners(currentPage: any) {
    return (
      currentPage.name !== "HomePage" &&
      currentPage.name !== "MvpCoderePage" &&
      currentPage.name !== "RegistroCOPage" &&
      currentPage.name !== "RegistroCONewPage" &&
      currentPage.name !== "CashierPage" &&
      this.partner !== ""
    );
  }

  navigateBack() {
    this.location.back();
  }

  //TODO: No funciona bien en algunas paginas
  changePage(path: string, currentPage: any, hashValue: string | null = null) {
    // console.log('changePage',currentPage);
    if (this.SetPartners(currentPage)) {
      this.partner = "";
      sessionStorage.removeItem("partnercodere");
    }

    if (/*this.currentPage?.name === 'LobbyCasinoPage' &&*/ currentPage.name !== "LobbyCasinoPage") {
      this.events.publish("LobbyCasinoPage:true");
    }

    if (
      /*this.currentPage.name !== 'LoadingPage' &&*/
      (currentPage.name === "HomePage" && !this.isAlreadyCheckBonusHome) ||
      currentPage.name === "PromotionsPage" ||
      (currentPage.name === "LobbyCasinoPage" && this.currentPageHashValue !== hashValue)
    ) {
      this.events.publish("changePage:finish");
      if (currentPage.name === "HomePage") {
        this.isAlreadyCheckBonusHome = true;
      }
    }

    if (currentPage.name !== "SlotsGamePage") {
      this.events.publish("finishGameSlots");
    }

    if (!!hashValue || (currentPage !== this.currentPage && this.currentPageHashValue !== hashValue)) {
      this.currentPage = currentPage;

      if (hashValue) {
        this.currentPageHashValue = hashValue;
        this.router.navigateByUrl(path + "/" + hashValue);
      } else {
        this.router.navigateByUrl(path + "/" + currentPage.name);
      }

      const pagesWithFooterBar = [
        "HomePage",
        "DirectosPage",
        "EventoPage",
        "EventoMejoraPage",
        "HorsesPage",
        "HorsesMarketPage",
        "MercadosPage",
        "NowLiveDetailPage",
        "Crea-Tu-Apuesta",
        "SportCountriesPage",
        "RealMadridPage",
        "RiverPlatePage",
        "SpecialTeamPage",
        "TodayPage",
        "SportEventsPage",
        "EventoSpecialPage",
      ];

      this.hideBetFooterBar = !pagesWithFooterBar.includes(currentPage.name);
      this.hasTicket =
        pagesWithFooterBar.includes(currentPage.name) && this.globalVars.ApplicationState.StreamingOn;

      if (this.globalVars.ApplicationState.StreamingOn) {
        if (!this.isMobile) {
          this.events.publish(
            pagesWithFooterBar.includes(currentPage.name) ? "videoplayer:toticket" : "videoplayer:free",
          );
        } else {
          this.events.publish("videoplayer:close");
        }
      }
    }
  }

  async sportsAlert(
    iconoWarning: boolean,
    title: string,
    message: string,
    buttonText: string,
    buttonHandler: any = null,
    cssClass = "",
  ) {
    if (!this.alertOpened) {
      const alert = await this.alertController.create({
        header: title,
        message: message,
        cssClass: cssClass,
        buttons: [
          {
            text: buttonText,
            handler: () => {
              if (buttonHandler) {
                buttonHandler();
                // Codigo añadido para evitar el bug 77958
                // REVISAR
                alert.dismiss();
                this.alertOpened = false;
                return false;
              }
              return null;
            },
          },
        ],
        backdropDismiss: false,
      });

      alert.onWillDismiss().then(() => {
        this.alertOpened = false;
      });

      // Presentar la alerta
      await alert.present();
      this.alertOpened = true;

      if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
        const footer = await this.createARFooter();
        // Agregar el pie de página después de que la alerta se haya presentado
        if (alert) {
          const alertElement = alert.querySelector(".alert-wrapper");
          if (alertElement && !alertElement.querySelector("#footer-salida")) {
            alertElement.appendChild(footer);
          }
        }
      }
      return alert;
    }
    return null;
  }

  async alert(
    iconoWarning: boolean,
    title: string,
    message: string,
    buttonText: string,
    buttonHandler: any = null,
    cssClass = "",
    includeFooter = true,
  ) {
    if (!this.alertOpened) {
      const alert = await this.alertController.create({
        header: title,
        message: message,
        cssClass: cssClass,
        buttons: [
          {
            text: buttonText,
            handler: () => {
              if (buttonHandler) {
                buttonHandler();
                // Codigo añadido para evitar el bug 77958
                // REVISAR
                alert.dismiss();
                this.alertOpened = false;
                return false;
              }
              return null;
            },
          },
        ],
        backdropDismiss: false,
      });

      alert.onWillDismiss().then(() => {
        this.alertOpened = false;
      });

      // Presentar la alerta
      await alert.present();

      if (includeFooter && this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
        const footer = includeFooter ? await this.createARFooter() : null;
        // Agregar el pie de página después de que la alerta se haya presentado
        if (alert) {
          const alertElement = alert.querySelector(".alert-wrapper");
          if (alertElement && !alertElement.querySelector("#footer-salida")) {
            alertElement.appendChild(footer);
          }
        }
      }
      return alert;
    }
    return null;
  }

  /**
   * En Argentina añada a todos los alerts
   * un footercon los logos
   * @param alert
   */

  async createARFooter(isGreen = false, isAutoClose = false): Promise<HTMLElement> {
    const salidaOculta = !this.globalVars.user.logged ? "oculto" : "";
    const footer = document.createElement("div");
    footer.classList.add("alert-footer");
    if (isGreen) {
      footer.classList.add("alertgreen");
    }

    footer.innerHTML = `
      <a external-link href="#" id="footer-salida" class="${salidaOculta}">
        <img src="assets/global/img/argentina/logosalida.png" alt="Logo Salida">
      </a>
      <a external-link target="_blank" href="https://www.saberjugar.gob.ar/">
        <img src="assets/global/img/argentina/logoLotba.svg" alt="Logo Loteria de la Ciudad">
      </a>
      <a external-link target="_blank" href="https://saberjugar.gob.ar/">
        <img src="assets/global/img/argentina/logoSJ.svg" alt="Logo Saber Jugar" class="bordery">
      </a>
    `;

    footer.querySelector("#footer-salida").addEventListener("click", (ev) => {
      ev.preventDefault();
      this.events.publish("user:logout", TypeLogout.MANUAL);
      const alert = document.querySelector("ion-alert");
      if (alert) {
        alert.dismiss();
      }
    });
    const enlaces: any = footer.querySelectorAll("[target]");
    enlaces.forEach((item) =>
      item.addEventListener("click", () => {
        if (!isAutoClose) {
          const alert = document.querySelector("ion-alert");
          if (alert) {
            alert.dismiss();
          }
        } else {
          // Al usar un enlace externo, no se cierra el alert
        }
      }),
    );

    return footer;
  }
  async alertSession(
    iconoWarning: boolean,
    title: string,
    message: string,
    buttonText: string,
    buttonHandler: any = null,
  ) {
    if (!this.alertOpened) {
      const alert = await this.alertController.create({
        header: title,
        message: message,
        translucent: true,
        buttons: [
          {
            text: buttonText,
            handler: () => {
              if (buttonHandler) {
                buttonHandler();
                // Codigo añadido para evitar el bug 77958
                // REVISAR
                alert.dismiss();
                this.alertOpened = false;
                return false;
              }
              return null;
            },
          },
        ],
        backdropDismiss: false,
      });

      alert.onWillDismiss().then(() => {
        this.alertOpened = false;
      });

      await alert.present();
      this.alertOpened = true;
      // todo olga
      if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
        // Agregar el pie de página después de que la alerta se haya presentado
        const footer = await this.createARFooter();
        if (alert) {
          const alertElement = alert.querySelector(".alert-wrapper");
          if (alertElement) {
            alertElement.appendChild(footer);
          }
        }
      }
      return alert;
    }
    return null;
  }

  async confirm(
    iconoWarning: boolean,
    title: string,
    message: string,
    cancelText: string,
    cancelHandler: any = null,
    acceptText: string,
    acceptHandler: any = null,
    backdropDismiss = false,
    cssClass = "",
  ) {
    const alert = await this.alertController.create({
      header: title,
      message: "<span class='alertMessageCustom'>" + message + "</span>",
      cssClass: "sb-alert " + cssClass,
      //enableBackdropDismiss: para minimizar la alerta pulsando fuera, por defecto se minimiza.
      backdropDismiss: backdropDismiss,
      buttons: [
        {
          text: cancelText,
          role: "cancel",
          handler: cancelHandler,
        },
        {
          text: acceptText,
          handler: acceptHandler,
        },
      ],
    });

    alert.onWillDismiss().then(() => {
      this.alertOpened = false;
    });

    await alert.present();

    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      // Agregar el pie de página después de que la alerta se haya presentado
      const footer = await this.createARFooter();
      if (alert) {
        const alertElement = alert.querySelector(".alert-wrapper");
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }
  }
  async confirmcustom(
    iconoWarning: boolean,
    title: string,
    message: string,
    cancelText: string,
    cancelHandler: any = null,
    acceptText: string,
    acceptHandler: any = null,
    backdropDismiss = false,
    cssClass = "",
  ) {
    const alert = await this.alertController.create({
      header: title,
      message: "<span class='alertMessageCustom'>" + message + "</span>",
      cssClass: "sb-alert" + " " + cssClass,
      //enableBackdropDismiss: para minimizar la alerta pulsando fuera, por defecto se minimiza.
      backdropDismiss: backdropDismiss,
      buttons: [
        {
          text: cancelText,
          role: "cancel",
          handler: cancelHandler,
        },
        {
          text: acceptText,
          handler: acceptHandler,
        },
      ],
    });

    alert.onWillDismiss().then(() => {
      this.alertOpened = false;
    });

    //let nav = this.app.getComponent('nav');
    await alert.present();
    // this.alertOpened = alert;

    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      // Agregar el pie de página después de que la alerta se haya presentado
      const footer = await this.createARFooter();
      if (alert) {
        const alertElement = alert.querySelector(".alert-wrapper");
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }
    return alert;
  }

  /**
   * El mismo comportamiento que confirm
   * Escepto que el botón cancel no tiene el rol 'cancel'
   * de modo que no se ejecuta la opción cancelar si se cierra el modal
   * @param iconoWarning
   * @param title
   * @param message
   * @param cancelText
   * @param cancelHandler
   * @param acceptText
   * @param acceptHandler
   * @param backdropDismiss
   */
  async confirmPersistent(
    iconoWarning: boolean,
    title: string,
    message: string,
    cancelText: string,
    cancelHandler: any = null,
    acceptText: string,
    acceptHandler: any = null,
    backdropDismiss = false,
  ) {
    const alert = await this.alertController.create({
      header: title,
      message: "<span class='alertMessageCustom'>" + message + "</span>",
      cssClass: "sb-alert",
      //enableBackdropDismiss: para minimizar la alerta pulsando fuera, por defecto se minimiza.
      backdropDismiss: backdropDismiss,
      buttons: [
        {
          text: cancelText,
          handler: cancelHandler,
        },
        {
          text: acceptText,
          handler: acceptHandler,
        },
      ],
    });

    alert.onWillDismiss().then(() => {
      this.alertOpened = false;
    });

    alert.present();
  }

  async alertHistory(title: string, message: string, buttonText: string, addClass: string) {
    if (!this.alertOpened) {
      const alert = await this.alertController.create({
        header: title,
        message: message,
        buttons: [
          {
            text: buttonText,
            handler: () => {},
          },
        ],
        cssClass: addClass,
        backdropDismiss: false,
      });

      alert.onWillDismiss().then(() => {
        this.alertOpened = false;
      });
      //
      alert.present();

      this.alertOpened = true;
      await alert.present();
      if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
        // Agregar el pie de página después de que la alerta se haya presentado
        const footer = await this.createARFooter();
        if (alert) {
          const alertElement = alert.querySelector(".alert-wrapper");
          if (alertElement) {
            alertElement.appendChild(footer);
          }
        }
      }
      return alert;
    }
    return null;
  }

  async confirmPromotions(
    iconoWarning: boolean,
    title: string,
    message: string,
    cancelText: string,
    cancelHandler: any = null,
    acceptText: string,
    acceptHandler: any = null,
    backdropDismiss = false,
    classAlert = "",
  ) {
    const body: string =
      "<span class='alertMessageCustom'> " +
      message +
      " </span>" +
      "<div><input type='checkbox' name='bono200' value='bono200'> <img src='http://localhost/v3/assets/global/img/bonos/bono200_200.png' style='vertical-align: middle;'></div>" +
      "<div class='myWrapLegal'><ion-item >" +
      "<input type='checkbox' [tabindex]='19' (click)='globalVars.defaultPromotion=true'>" +
      "<ion-label><img src='assets/global/img/bonos/bono200_200.png' style='margin-top: 10px;'></ion-label>" +
      "</ion-item></div>" +
      "<div class='myWrapLegal'>" +
      "<ion-item>" +
      "<ion-checkbox [tabindex]='19' (click)='globalVars.defaultPromotion=false'></ion-checkbox>" +
      "<ion-label><img src='assets/global/img/bonos/bono10_30.png' style='margin-top: 10px;'></ion-label></ion-item></div>" +
      "<div class='alert-message'>¡En cualquiera de los casos tu dinero siempre estará disponible!</div>" +
      "<div class='alert-message' style='margin-top: 10px;'><a external-link href='https://m.apuestas.codere.es/deportes/#/PromotionsPage'>+ Info sobre las promociones</a></div>";

    const alert = await this.alertController.create({
      header: title,
      message: body,
      //enableBackdropDismiss: para minimizar la alerta pulsando fuera, por defecto se minimiza.
      backdropDismiss: backdropDismiss,
      buttons: [
        {
          text: cancelText,
          role: "cancel",
          handler: cancelHandler,
        },
        {
          text: acceptText,
          handler: acceptHandler,
        },
      ],
      cssClass: classAlert,
    });
    await alert.present();
    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      // Agregar el pie de página después de que la alerta se haya presentado
      const footer = await this.createARFooter();
      if (alert) {
        const alertElement = alert.querySelector(".alert-wrapper");
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }
  }

  /**
   * Muestra errores llamando al método alert
   * @param msg
   * @param callback
   */
  public async showError(msg: string, callback?: any) {
    //show the error msg and callback if any
    return this.alert(true, "Error", msg, "OK", callback);
  }
  /**
   * Muestra errores llamando al método alert para módulo de sports
   * @param msg
   * @param callback
   */
  public async showSportsError(msg: string, callback?: any) {
    //show the error msg and callback if any
    return this.sportsAlert(true, "Error", msg, "OK", callback);
  }
  /**
   *
   * @param msg Muestra mensajes llamando al método alert
   * @param callback
   * @param title
   */
  public showMsg(msg: string, callback?: any, title = "Info", classAlert?: string) {
    //show the error msg and callback if any
    return this.alert(true, title, msg, "OK", callback, classAlert);
  }

  public stringFormat(str: string, obj: Array<string>): string {
    return str.replace(/\{\s*([^}\s]+)\s*\}/g, (substring: string, replaceValue: any[]) => obj[replaceValue[0]]);
  }

  //Usage: msg can include placeholders like {0},{1}... that will be replaced by the equivalent item in the obj array
  public showErrorWithParams(msg: string, obj: Array<string>, callback?: any) {
    //show the error msg and callback if any
    return this.alert(true, "Error", this.stringFormat(msg, obj), "OK", callback);
  }
  public showMsgWithParams(msg: string, obj: Array<string>, callback?: any, title = "Info") {
    //show the error msg and callback if any
    return this.alert(true, title, this.stringFormat(msg, obj), "OK", callback);
  }

  public showPopUp(tipo: string, callback?: any) {
    //shows the pop-up
    let msg = "";
    switch (tipo) {
      default:
        msg = "FALTA MENSAJE TIPO:" + tipo;
        break;
    }

    this.showMsg(msg, callback);
  }

  /** @method alertLoginError
   * @description: show login error with recovery password options
   * @param iconoWarning
   * @param title
   * @param message
   * @param buttonText
   * @param buttonHandler
   * @param backdropDismiss
   */
  async alertLoginError(
    iconoWarning: boolean,
    title: string,
    message: string,
    buttons: Array<{ text: string; cssClass: string; role: string; handler: any }>,
    backdropDismiss = false,
    includeFooter = true,
  ) {
    const footer = includeFooter ? await this.createARFooter() : null;
    const alert = await this.alertController.create({
      header: title,
      message: "<span class='alertMessageCustom'>" + message + "</span>",
      //enableBackdropDismiss: para minimizar la alerta pulsando fuera, por defecto se minimiza.
      backdropDismiss: backdropDismiss,
      buttons: buttons,
    });

    alert.onWillDismiss().then(() => {
      this.alertOpened = false;
    });

    await alert.present();
    this.alertOpened = true;

    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      // Agregar el pie de página después de que la alerta se haya presentado
      if (alert) {
        const alertElement = alert.querySelector(".alert-wrapper");
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }
    return alert;
  }

  //LOADER GLOBAL
  public async loader(content?: any, cssClass = "is-loader", translucent = true) {
    if (!this.isLoading) {
      this.isLoading = true;

      this.loading = await this.loadingCtrl.create({
        spinner: "crescent",
        message: content,
        backdropDismiss: false,
        cssClass: cssClass,
        translucent: translucent,
      });

      await this.loading.present();
    }
  }

  public async closeLoader() {
    if (this.isLoading) {
      const loader = await this.loadingCtrl.getTop();
      if (loader) {
        await loader.dismiss();
        this.isLoading = false;
        this.events.publish("loader:dismiss", null);
      } else {
        this.events.publish("loader:dismiss", null);
      }
    }
  }

  public getValueParamNoCache() {
    return new Date().getTime().toString();
  }

  /* begin Alerts y confirm functions */
  async showAlertCancelDeposit(buttonHandler: any = null) {
    const alert = await this.alertController.create({
      header: "Para realizar un depósito online cancela tu solicitud de cobro pendiente.",
      message: "Solicitud de cobro del 11 Sep 2014<br>Importe 20€ - Transferencia bancaria",
      buttons: [
        {
          text: "Cancelar solicitud",
          handler: buttonHandler,
        },
      ],
    });
    await alert.present();
    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      // Agregar el pie de página después de que la alerta se haya presentado
      const footer = await this.createARFooter();
      if (alert) {
        const alertElement = alert.querySelector(".alert-wrapper");
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }
  }
  async showAlertCancelPayment(amountCancel: string, type = "Cobro", buttonHandler: any = null) {
    const title = type === "Deposito" ? "DEPOSITO CANCELADO CORRECTAMENTE" : "COBRO CANCELADO CORRECTAMENTE";
    const messageType =
      type === "Deposito"
        ? '<p> SALDO ACTUAL <b class="greenlbl">' +
          this.parseMoney.transform(this.globalVars.user.balance.toString()) +
          "</b></p>"
        : "Se ha ingresado el importe de " +
          this.parseMoney.transform(amountCancel) +
          ' en tu balance.<p> SALDO ACTUAL <b class="greenlbl">' +
          this.parseMoney.transform(this.globalVars.user.balance.toString()) +
          "</b></p>";
    const alert = await this.alertController.create({
      header: title,
      message: messageType,
      cssClass: "sb-alert--documentation",
      buttons: [
        {
          text: "Continuar",
          role: "cancel",
          cssClass: "buttoncss",
        },
      ],
    });
    await alert.present();
    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      // Agregar el pie de página después de que la alerta se haya presentado
      const footer = await this.createARFooter();
      if (alert) {
        const alertElement = alert.querySelector(".alert-wrapper");
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }
  }

  async showAlertCancelCharge(amountCancel: string, buttonHandler: any = null) {
    const alert = await this.alertController.create({
      header: "COBRO CANCELADO CORRECTAMENTE",
      message:
        "Se ha ingresado el importe de " +
        this.parseMoney.transform(amountCancel) +
        ' en tu balance.<p> SALDO ACTUAL <b class="greenlbl">' +
        this.parseMoney.transform(this.globalVars.user.balance.toString()) +
        "</b></p>",
      buttons: [
        {
          text: "Continuar",
          role: "cancel",
        },
      ],
    });

    await alert.present();
    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      // Agregar el pie de página después de que la alerta se haya presentado
      const footer = await this.createARFooter();
      if (alert) {
        const alertElement = alert.querySelector(".alert-wrapper");
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }
  }

  async showAlertChargeOK(
    amount: number,
    idTransaccion: string,
    txtTitle: string,
    texto1: string,
    texto2: string,
    texto3: string,
    textoBoton: string,
  ) {
    const alert = await this.alertController.create({
      header: txtTitle,
      message:
        "<p>" +
        texto1 +
        "<b class='greenlbl'>" +
        this.parseMoney.transform(amount.toString()) +
        "</b></p>" +
        texto2 +
        idTransaccion +
        "</br></br>" +
        texto3,
      buttons: [
        {
          text: textoBoton,
          role: "cancel",
        },
      ],
    });
    await alert.present();
    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      // Agregar el pie de página después de que la alerta se haya presentado
      const footer = await this.createARFooter();
      if (alert) {
        const alertElement = alert.querySelector(".alert-wrapper");
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }
  }

  async showAlertChargeWithTaxes(
    netTitle: string,
    netamount: number,
    taxTitle: string,
    taxamount: number,
    alertConfirmationQuestion: string,
    buttonOkHandler: any = null,
    txtTitle: string,
    textoOKBoton: string,
    textoCancelBoton: string,
  ) {
    const alert = await this.alertController.create({
      header: txtTitle,
      cssClass: "sb-alert payments",
      message: this.compileTextMessage(
        netTitle,
        this.parseMoney.transform(netamount.toString()),
        taxTitle,
        this.parseMoney.transform(taxamount.toString()),
        alertConfirmationQuestion,
      ),
      buttons: [
        {
          text: textoCancelBoton,
          role: "cancel",
        },
        {
          text: textoOKBoton,
          handler: buttonOkHandler,
        },
      ],
    });
    await alert.present();
    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      // Agregar el pie de página después de que la alerta se haya presentado
      const footer = await this.createARFooter();
      if (alert) {
        const alertElement = alert.querySelector(".alert-wrapper");
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }
  }

  async showAlertTransferWithTaxes(
    netTitle: string,
    netamount: number,
    taxTitle: string,
    taxamount: number,
    alertConfirmationQuestion: string,
    buttonOkHandler: any = null,
    txtTitle: string,
    textoOKBoton: string,
    textoCancelBoton: string,
  ) {
    const alert = await this.alertController.create({
      header: txtTitle,
      message: this.compileTextMessage(
        netTitle,
        this.parseMoney.transform(netamount.toString()),
        taxTitle,
        this.parseMoney.transform(taxamount.toString()),
        alertConfirmationQuestion,
      ),
      buttons: [
        {
          text: textoCancelBoton,
          role: "cancel",
        },
        {
          text: textoOKBoton,
          handler: buttonOkHandler,
        },
      ],
    });
    await alert.present();
    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      // Agregar el pie de página después de que la alerta se haya presentado
      const footer = await this.createARFooter();
      if (alert) {
        const alertElement = alert.querySelector(".alert-wrapper");
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }
  }

  compileTextMessage(
    netTitle: string,
    netamountString: string,
    taxTitle: string,
    taxamountString: string,
    alertConfirmationQuestion,
  ) {
    let messageText = `<p>${netTitle} <b class='greenlbl'>${netamountString}</b></p>`;
    messageText += `<p>${taxTitle} <b class='greenlbl'>${taxamountString}</b></p>`;
    messageText += `</br>`;
    messageText += `<span>${alertConfirmationQuestion}</span>`;
    return messageText;
  }

  async showFinalSummarySlots(buttonHandler: any = null) {
    const alert = await this.alertController.create({
      header: "RESUMEN",
      message: "APOSTADO: 10€ <BR>PREMIOS: 40 €",
      buttons: [
        {
          text: "OK",
          role: "cancel",
          handler: buttonHandler,
        },
      ],
    });
    await alert.present();
    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      // Agregar el pie de página después de que la alerta se haya presentado
      const footer = await this.createARFooter();
      if (alert) {
        const alertElement = alert.querySelector(".alert-wrapper");
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }
  }

  async showHelpNumTicket(buttonHandler: any = null) {
    const alert = await this.alertController.create({
      message: '<img src="assets/global/img/helpnumticket.png">',
      cssClass: "alertImg",
      buttons: [
        {
          text: "OK",
          role: "cancel",
          handler: buttonHandler,
        },
      ],
    });
    await alert.present();
    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      // Agregar el pie de página después de que la alerta se haya presentado
      const footer = await this.createARFooter();
      if (alert) {
        const alertElement = alert.querySelector(".alert-wrapper");
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }
  }

  async showCodereCardBarCode(barCode: string, buttonHandler: any = null) {
    const barcode = "<ul class='barcodePopUp'>" + this.generateBarcode(barCode) + "</ul>";
    const alert = await this.alertController.create({
      message: barcode,
      cssClass: "alertImg barcode-alert",
      backdropDismiss: false,
      buttons: [
        {
          text: "Cerrar",
          role: "cancel",
          handler: buttonHandler,
        },
      ],
    });
    await alert.present();
    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      // Agregar el pie de página después de que la alerta se haya presentado
      const footer = await this.createARFooter();
      if (alert) {
        const alertElement = alert.querySelector(".alert-wrapper");
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }
  }

  setCookie(n: string, t: string, i: number, setDomain = false) {
    let dominio = "";
    if (setDomain) {
      if (this.globalVars.DEVICE_TYPE !== "cordova") {
        switch (this.globalVars.licenseType) {
          case 0:
          case 1:
            dominio = ".codere.es";
            break;
          case 4:
            dominio = ".codere.mx";
            break;
          case 21:
            dominio = ".codere.com.co";
            break;
          case 22:
            dominio = ".codere.com.pa";
            break;
        }
      }
    }
    const r: Date = new Date();
    r.setTime(r.getTime() + i);
    document.cookie =
      n +
      " = " +
      encodeURI(t) +
      (i === null ? "" : "; expires = " + r.toUTCString()) +
      "; domain=" +
      dominio +
      "; path=/";
  }

  getCookie(n: string): string {
    let r: string,
      u: string,
      f: string | null = null;
    const t: Array<string> = document.cookie.split(";");
    for (let i = 0; i < t.length; i++)
      (u = t[i].substr(t[i].indexOf("=") + 1)),
        (r = t[i].substr(0, t[i].indexOf("=")).replace(/^\s+|\s+$/g, "")),
        r === n && (f = decodeURI(u));
    return f;
  }

  eraseCookie(n: string) {
    document.cookie = n + "=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  public extractBarCode(value: any): string {
    if (typeof value === "string") {
      return value;
    } else {
      if (typeof value === "object") {
        if (typeof value.transactionId === "string") return value.transactionId;
        else return typeof value.code === "string" ? value.code : "";
      }
    }
    return "";
  }

  generateBarcode(texto: string): string {
    return this.barCodeGenerator.getBarCode(texto);
  }

  /*END BARCODE GENERATOR*/

  /*XML 2 JSON PARSER*/

  public xml2json(xmlStr: string): any {
    const tab = "  ";
    let xml;

    console.log("init parseXML", new Date().getTime() / 1000);
    if (typeof window["DOMParser"] !== "undefined") {
      //parseXml = function (xmlStr) {
      //return (new window['DOMParser']()).parseFromString(xmlStr, "text/xml");
      xml = new window["DOMParser"]().parseFromString(xmlStr, "text/xml");
      //};
    } else if (typeof window["ActiveXObject"] !== "undefined" && new window["ActiveXObject"]("Microsoft.XMLDOM")) {
      const xmlDoc = new window["ActiveXObject"]("Microsoft.XMLDOM");
      xmlDoc.async = "false";
      xmlDoc.loadXML(xmlStr);
      xml = xmlDoc;
    } else {
      throw new Error("No XML parser found");
    }
    const X = {
      // eslint-disable-next-line no-shadow
      toObj: function (xml: any) {
        let o: any = {};
        if (xml.nodeType === 1) {
          // element node ..
          if (xml.attributes.length)
            // element with attributes  ..
            for (let i = 0; i < xml.attributes.length; i++)
              o["@" + xml.attributes[i].nodeName] = (xml.attributes[i].nodeValue || "").toString();
          if (xml.firstChild) {
            // element has child nodes ..
            let textChild = 0,
              cdataChild = 0,
              hasElementChild = false;
            for (let n = xml.firstChild; n; n = n.nextSibling) {
              if (n.nodeType === 1) hasElementChild = true;
              else if (n.nodeType === 3 && n.nodeValue.match(/[^ \f\n\r\t\v]/)) textChild++;
              // non-whitespace text
              else if (n.nodeType === 4) cdataChild++; // cdata section node
            }
            if (hasElementChild) {
              if (textChild < 2 && cdataChild < 2) {
                // structured element with evtl. a single text or/and cdata node ..
                X.removeWhite(xml);
                for (let n = xml.firstChild; n; n = n.nextSibling) {
                  if (n.nodeType === 3)
                    // text node
                    o["#text"] = X.escape(n.nodeValue);
                  else if (n.nodeType === 4)
                    // cdata node
                    o["#cdata"] = X.escape(n.nodeValue);
                  else if (o[n.nodeName]) {
                    // multiple occurence of element ..
                    if (o[n.nodeName] instanceof Array) o[n.nodeName][o[n.nodeName].length] = X.toObj(n);
                    else o[n.nodeName] = [o[n.nodeName], X.toObj(n)];
                  } // first occurence of element..
                  else o[n.nodeName] = X.toObj(n);
                }
              } else {
                // mixed content
                if (!xml.attributes.length) o = X.escape(X.innerXml(xml));
                else o["#text"] = X.escape(X.innerXml(xml));
              }
            } else if (textChild) {
              // pure text
              if (!xml.attributes.length) o = X.escape(X.innerXml(xml));
              else o["#text"] = X.escape(X.innerXml(xml));
            } else if (cdataChild) {
              // cdata
              if (cdataChild > 1) o = X.escape(X.innerXml(xml));
              else for (let n = xml.firstChild; n; n = n.nextSibling) o["#cdata"] = X.escape(n.nodeValue);
            }
          }
          if (!xml.attributes.length && !xml.firstChild) o = null;
        } else if (xml.nodeType === 9) {
          // document.node
          o = X.toObj(xml.documentElement);
        } else alert("unhandled node type: " + xml.nodeType);
        return o;
      },
      toJson: function (o: any, name: string, ind: any) {
        let json = name ? '"' + name + '"' : "";
        if (o instanceof Array) {
          for (let i = 0, n = o.length; i < n; i++) o[i] = X.toJson(o[i], "", ind + "\t");
          json +=
            (name ? ":[" : "[") +
            (o.length > 1 ? "\n" + ind + "\t" + o.join(",\n" + ind + "\t") + "\n" + ind : o.join("")) +
            "]";
        } else if (o === null) json += (name && ":") + "null";
        else if (typeof o === "object") {
          const arr = [];
          for (const m in o) arr[arr.length] = X.toJson(o[m], m, ind + "\t");
          json +=
            (name ? ":{" : "{") +
            (arr.length > 1 ? "\n" + ind + "\t" + arr.join(",\n" + ind + "\t") + "\n" + ind : arr.join("")) +
            "}";
        } else if (typeof o === "string") json += (name && ":") + '"' + o.toString() + '"';
        else json += (name && ":") + o.toString();
        return json;
      },
      innerXml: function (node: any) {
        let s = "";
        if ("innerHTML" in node) s = node.innerHTML;
        else {
          const asXml = function (n: any) {
            if (n.nodeType === 1) {
              s += "<" + n.nodeName;
              for (let i = 0; i < n.attributes.length; i++)
                s += " " + n.attributes[i].nodeName + '="' + (n.attributes[i].nodeValue || "").toString() + '"';
              if (n.firstChild) {
                s += ">";
                for (let c = n.firstChild; c; c = c.nextSibling) s += asXml(c);
                s += "</" + n.nodeName + ">";
              } else s += "/>";
            } else if (n.nodeType === 3) s += n.nodeValue;
            else if (n.nodeType === 4) s += "<![CDATA[" + n.nodeValue + "]]>";
            return s;
          };
          for (let c = node.firstChild; c; c = c.nextSibling) s += asXml(c);
        }
        return s;
      },
      escape: function (txt: any) {
        return txt
          .replace(/[\\]/g, "\\\\")
          .replace(/[\\"]/g, '\\"')
          .replace(/[\n]/g, "\\n")
          .replace(/[\r]/g, "\\r");
      },
      removeWhite: function (e: any) {
        e.normalize();
        for (let n = e.firstChild; n; ) {
          if (n.nodeType === 3) {
            // text node
            if (!n.nodeValue.match(/[^ \f\n\r\t\v]/)) {
              // pure whitespace text node
              const nxt = n.nextSibling;
              e.removeChild(n);
              n = nxt;
            } else n = n.nextSibling;
          } else if (n.nodeType === 1) {
            // element node
            X.removeWhite(n);
            n = n.nextSibling;
          } // any other node
          else n = n.nextSibling;
        }
        return e;
      },
    };
    //xml = parseXml(xml);
    if (xml.nodeType === 9)
      // document node
      xml = xml.documentElement;
    const json = X.toJson(X.toObj(X.removeWhite(xml)), xml.nodeName, "\t");

    return JSON.parse("{\n" + tab + (tab ? json.replace(/\t/g, tab) : json.replace(/\t|\n/g, "")) + "\n}");
  }

  openContactChat(typeChat: "normal" | "proactive" = "normal", email = "") {
    if (this.globalVars.FEATURES.newChatEnabled) {
      this.newChatService.openContactChat(typeChat, email);
    } else {
      if (email !== "") {
        window["SnapEngage"].setUserEmail(email, true);
      }
      window["SnapEngage"].startLink();
    }
  }

  unloadContactChat() {
    if (this.globalVars.FEATURES.newChatEnabled) {
      this.newChatService.unloadContactChatNew();
    } else {
      this.unloadContactChatOld();
    }
  }

  private unloadContactChatOld() {
    const designStudio: any = document.querySelectorAll('[id^="designstudio"]');
    const exist = document.querySelector('[src^="https://storage.googleapis.com/code.snapengage.com/js"]');
    if (exist) {
      exist.remove();
    }
    for (const element of designStudio) {
      element.remove();
    }
  }

  loadContactChat(hidden = false, openOnLoad = false, typeChat: "normal" | "proactive" = "normal") {
    if (this.globalVars.FEATURES.newChatEnabled) {
      //this.loadContactChatNew(hidden,openOnLoad,typeChat);
      this.newChatService.loadContactChatNew(hidden, openOnLoad, typeChat);
    } else {
      this.loadContactChatOld(hidden);
    }
  }

  loadContactChatOld(hidden?: boolean) {
    const exist = document.querySelector('[src^="https://storage.googleapis.com/code.snapengage.com/js"]');
    if (exist) {
      return;
    }
    let channel = "";
    if (this.globalVars.FEATURES.snapengageChannel === null) {
      channel = "90f968b9-4ac9-4a9f-862f-4a0c67ba0b55";
    } else {
      channel = this.globalVars.FEATURES.snapengageChannel;
    }
    const se: HTMLScriptElement = document.createElement("script");
    se.type = "text/javascript";
    se.async = true;
    se.src = "https://storage.googleapis.com/code.snapengage.com/js/" + channel + ".js";
    //  'https://storage.googleapis.com/code.snapengage.com/js/90f968b9-4ac9-4a9f-862f-4a0c67ba0b55.js';
    //var done = false;
    se.onload = function () {
      /* Error
                        if (!done && (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete')) {
                            done = true;
                            this.chatSrc = "https://www.snapengage.com/statusImage?w=90f968b9-4ac9-4a9f-862f-4a0c67ba0b55"
                        }
            */
    };
    //var done = false;
    if (hidden) {
      se.onload = function () {
        window["SnapEngage"].hideButton();
        window["SnapEngage"].setCallback("Close", function (type: any, status: any) {
          window["SnapEngage"].hideButton();
        });
        /* Error
                          if (!done && (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete')) {
                              done = true;
                              this.chatSrc = "https://www.snapengage.com/statusImage?w=90f968b9-4ac9-4a9f-862f-4a0c67ba0b55"
                          }
              */
      };
    }

    const containerChat = document.createElement("div");
    containerChat.classList.add("contact_chat");
    containerChat.appendChild(se);
    const s = document.getElementsByTagName("script")[0];
    s.parentNode?.insertBefore(containerChat, s);
  }

  public getSportNameBySportHandle(sportHandle: any) {
    // todo: actualizar C_Features falta SPO_SportHandlerNames cambio realizdo el 18/07/23
    /* let sports = JSON.parse(this.globalVars.FEATURES.SPO_SportHandlerNames);
    if(sports===undefined){
      sports = {
        billard: 'Billar',
        esports: 'eSports',
        golf: 'Golf',
        handball: 'Balonmano',
        motor: 'Motor',
        snooker: 'Snooker',
        ice_hockey: 'Hockey sobre hielo',
        field_hockey: 'Hockey hierba',
        Field_hockey: 'Hockey hierba',
        darts: 'Dardos',
        rugby: 'Rugby',
        baseball: 'Béisbol',
        soccer_indoor: 'Futbol Sala',
        soccer: 'Fútbol',
        basketball: 'Baloncesto',
        american_football: 'Fútbol Americano',
        badminton: 'Bádminton',
        beach_soccer: 'Fútbol Playa',
        beach_volleyball: 'Voley Playa',
        tennis: 'Tenis',
        table_tennis: 'Tenis de mesa',
        tennis_clay: sportHandle,
        tennis_grass: sportHandle,
        tennis_indoor: sportHandle,
        volleyball: 'Voleibol'
      };
    }
    if (!sports[sportHandle]) {
      return sportHandle;
    }
    return sports[sportHandle];
  }*/
    const sports: { [key: string]: string } = {
      billard: "Billar",
      esports: "eSports",
      golf: "Golf",
      handball: "Balonmano",
      motor: "Motor",
      snooker: "Snooker",
      ice_hockey: "Hockey sobre hielo",
      darts: "Dardos",
      rugby: "Rugby",
      baseball: "Béisbol",
      soccer_indoor: "Futbol Sala",
      soccer: "Fútbol",
      basketball: "Baloncesto",
      american_football: "Fútbol Americano",
      badminton: "Bádminton",
      beach_soccer: "Fútbol Playa",
      beach_volleyball: "Voley Playa",
      tennis: "Tenis",
      table_tennis: "Tenis de mesa",
      tennis_clay: sportHandle,
      tennis_grass: sportHandle,
      tennis_indoor: sportHandle,
      volleyball: "Voleibol",
    };
    if (!sports[sportHandle]) {
      return sportHandle;
    }
    return sports[sportHandle];
  }

  /////////
  trackBy(index: any, item: any) {
    if (!item) {
      return;
    }
    return item.NodeId;
  }

  setParamStore(param: any): void {
    this.paramStore = param;
  }

  getParamStore(): any {
    return this.paramStore;
  }
  geturlJResponsable(): string {
    let urlJRes = "";
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Mexico:
        urlJRes = "https://www.codere.mx/Documents/AMH-Pol%C3%ADticas%20de%20Apuesta.pdf";
        break;
      case LicenseTypes.Colombia:
        urlJRes = "https://www.codere.com.co/Paginas/juego-responsable.aspx";
        break;
      case LicenseTypes.Panama:
        urlJRes = "https://condiciones.apuestas.codere.es/Panama/juego_responsable.html";
        break;
      case LicenseTypes.ArgentinaCaba:
        urlJRes = "https://www.codere.bet.ar/juego-responsable";
        break;
      default:
        urlJRes =
          "http://condiciones.apuestas.codere.es/nacional/juego_responsable.html?_ga=1.48326114.512593250.1464854868";
    }
    return urlJRes;
  }

  /**
   * Estados civilesválidos en Argentina
   */
  getCivilStatesAR(): Array<{ id: number; name: string }> {
    return [
      { id: 1, name: "Casado/a" },
      { id: 2, name: "Divorciado/a" },
      { id: 3, name: "Soltero/a" },
      { id: 4, name: "Unión Civil" },
      { id: 5, name: "Unión de Hecho" },
      { id: 6, name: "Viudo/a" },
    ];
  }

  getRandom() {
    return Math.random();
  }
  /**
   * Set the page to go to after registration process
   * @param backToPage Page
   */
  setGoBackToAfterRegister(backToPage: string): void {
    switch (backToPage) {
      case "HomePage":
      case "DirectosPage":
        // TODO
        /* this.globalVars.afterRegisterGoBackToPage = HomePage; */
        this.globalVars.onRegisterProductType = "sports";
        break;
      case "LobbyCasinoPage":
      case "PromotionsPage":
        // TODO
        /* this.globalVars.afterRegisterGoBackToPage = LobbyCasinoPage; */
        this.globalVars.onRegisterProductType = "casino";
        break;
      case "NearestLocalPage":
      default:
        //**+** this.globalVars.afterRegisterGoBackToPage = this.globalVars.mainPageComponent;
        this.globalVars.onRegisterProductType = "sports";
        break;
    }
  }

  public formatAlertSportsGeolocalization(alert: HTMLIonAlertElement, link?: string, linkTitle?: string) {
    if (alert) {
      const nodeIonAlert = alert;
      const nodeAlert = nodeIonAlert.querySelector(".alert-wrapper");
      nodeAlert.classList.add("alertsportsgeocaba");
      if (link) {
        const divContainer = document.createElement("div");
        divContainer.classList.add("d-flex");
        divContainer.classList.add("justify-center");

        const a = document.createElement("a");
        a.href = link;
        a.innerHTML = linkTitle;
        a.target = "_blank";

        const img = document.createElement("img");
        img.classList.add("align-self-center");
        img.src = "assets/global/img/whats.png";
        img.alt = "Codere whatsapp";
        img.width = 20;
        img.height = 20;

        divContainer.appendChild(img);
        divContainer.appendChild(a);
        nodeAlert.querySelector(".alert-message").appendChild(divContainer);
      }
      const btn = document.createElement("button");
      btn.classList.add("cta-close-btn");
      btn.innerHTML = '<i class="codere-icon icon-close cta-close-icon"></i>';
      btn.setAttribute("tappable", "");
      btn.setAttribute("ion-button", "");
      btn.addEventListener("click", () => {
        alert.dismiss();
      });
      nodeAlert.querySelector(".alert-head").appendChild(btn);
    }
  }

  public async alertMensozaGeolocationError() {
    const translate = this.globalVars.rootScope.translate;
    const txtliterals: string[] = [
      translate.instant("geolocation_sports_mza_txt02"),
      translate.instant("geolocation_mza_txt05"),
      translate.instant("geolocation_anyquestion"),
    ];

    let msg = "";
    msg += `<div class="subtitlealert">${"Información importante"}</div>`;
    txtliterals.forEach((value) => {
      msg += `<div>${value}</div>`;
    });

    const errorAlert = await this.alert(
      false,
      translate.instant("geolocation_sports_caba_title"),
      msg,
      translate.instant("geolocation_sports_caba_btn"),
      () => {},
    );
    this.formatAlertSportsGeolocalization(
      errorAlert,
      "https://wa.me/5491140457247",
      translate.instant("tlfmzawhatsapp"),
    );
  }

  async showAlertProfessionLogin(title: string, message: string, accepttex: string, acceptHandler: any) {
    const alert = await this.alertController.create({
      header: title,
      message: "<span class='alertMessageCustom'>" + message + "</span>",
      cssClass: "sb-alert alertprof",
      backdropDismiss: false,
      buttons: [
        {
          text: accepttex,
          handler: acceptHandler,
        },
      ],
    });
    alert.onWillDismiss().then(() => {
      this.alertOpened = false;
    });

    alert.present();
  }

  // TODO
  async showModalTyCSelfie(termsData: any) {
    /* const modal = this.modalCtrl.create(ModalTyCSelfiePage, { code: termsData.Code, url: termsData.Url, hash: termsData.Hash }, { enableBackdropDismiss: false, cssClass: 'modal-tycSelfie' }); */
    /* let modal = await this.modalCtrl.create(
      {
        component: ModalTyCSelfiePage,
        componentProps: {
          code: termsData.Code,
          url: termsData.Url,
          hash: termsData.Hash
        },
        backdropDismiss: false,
        cssClass: 'modal-tycSelfie'
      }
    );
    modal.onDidDismiss();
    modal.present(); */
  }

  mapResponseBanners(banners: any[]): I_NewCarousel[] {
    return banners.map<I_NewCarousel>((b) => {
      if (b.typeOfTemplate) {
        return b;
      } else {
        //Casino y slots
        const newBanner: I_NewCarousel = {
          typeOfTemplate: b.pageType,
          priority: b.pageType === "Slots" ? b.prioritySlots : b.priorityCasino,
          bannerDesktopImage: b.bannerDesktopImage,
          bannerMobileImage: b.bannerMobileImage,
          startDate: b.startDate,
          endDate: b.endDate,
          fromHour: b.fromHour,
          toHour: b.toHour,
          simpleImageTemplateOrTextLink: b.bannerLink,
          showLogged: b.showLogged,
          vipLevel: b.vipLevel,
        };
        return newBanner;
      }
    });
  }
  getGameType(typeOfTemplate: string) {
    let type: number | null = null;
    switch (typeOfTemplate) {
      case "Two teams with draw":
        type = 1;
        break;
      case "Two teams without draw":
      case "Tennis Match":
        type = 97;
        break;
    }
    return type;
  }

  canRequestForOddAmount(slider: I_NewCarousel) {
    let eventId: number | undefined;
    if (
      slider.typeOfTemplate === "Two teams without draw" ||
      slider.typeOfTemplate === "Two teams with draw" ||
      slider.typeOfTemplate === "Tennis Match" ||
      slider.typeOfTemplate === "One Odd"
    ) {
      eventId = slider.matchKey;
    }
    return eventId;
  }

  getBannerTypeByLobby(lobby: string): string {
    const lobbyName: string = lobby.toLowerCase();
    if (lobbyName.includes("casino")) {
      return "Casino";
    }
    if (lobbyName.includes("slots")) {
      return "Slots";
    }
    if (lobbyName.includes("omnichannel")) {
      return "Omnichannel";
    }
    return "Sports";
  }
  /* async showAlertAcceptComunications(message: string, acceptBtn: string, cancelBtn: string) { */
  // todo: en el Utils del nuevo repo, ahora sólo recibe dos argumentos visto el 28/07/23
  async showAlertAcceptComunications(message: string, acceptBtn: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<boolean>(async (resolve, reyect) => {
      const alert = await this.alertController.create({
        message: "<span class='alertMessageCustom'>" + message + "</span>",
        cssClass: "sb-alert alertprof",
        backdropDismiss: false,
        buttons: [
          {
            /* text: cancelBtn, */
            text: acceptBtn,
            role: "cancel",
            // handler: cancelHandler
          },
          {
            text: acceptBtn,
            role: "confirm",
            // handler: acceptHandler
          },
        ],
      });

      // todo: revisar alert
      /* alert.didEnter.subscribe(()=>{
        const nodeIonAlert = alert.pageRef().nativeElement;
        const nodeAlert = nodeIonAlert.querySelector('.alert-wrapper');
        const link = nodeAlert.querySelector('a');
        link.addEventListener('click', ()=>{
          alert.dismiss();
          this.globalVars.rootScope.tryToGoPage('ComunicationPage?comeFromAlert=true');
        });
      }); */
      alert.present();

      alert.onDidDismiss().then((r: any) => {
        const comunications = r === "confirm";

        resolve(comunications);
      });
    });
  }
  async showAlertAcceptComunicationsSecond(message: string, acceptBtn: string) {
    const alert = await this.alertController.create({
      message: "<span class='alertMessageCustom'>" + message + "</span>",
      cssClass: "sb-alert alertprof editpref",
      backdropDismiss: false,
      buttons: [
        {
          text: acceptBtn,
          role: "confirm",
          handler: () => {
            //**+** if (this.globalVars.currentPage.name!=='ComunicationPage') {
            //   this.globalVars.rootScope.tryToGoPage('ComunicationPage?comeFromAlert=true');
            // }
          },
        },
      ],
    });
    alert.present();
  }

  loginAndGoto(dest: any = null, params: any = null, doSetRoot = true) {
    return new Promise<boolean>((resolve, reyect) => {
      this.globalVars.rootScope.userService.getCurrentUser().subscribe(
        async (data: any) => {
          if (data.sessionOut) {
            resolve(false);
          } else {
            if (data.success) {
              if (data.idBGT) {
                this.globalVars.user.idBGT = data.idBGT;
              }
              this.globalVars.user.username = data.username;
              this.globalVars.user.balance = data.balance;
              this.globalVars.sessionInitDate = new Date();
              this.globalVars.user.logged = true;
              this.globalVars.rootScope.userService.userLogged = true;
              let auxRegion: number;
              if (data.license) {
                switch (data.license) {
                  case "Colombia":
                    auxRegion = LicenseTypes.Colombia;
                    break;
                  case "Panama":
                    auxRegion = LicenseTypes.Panama;
                    break;
                  case "Nacional":
                    auxRegion = LicenseTypes.Nacional;
                    break;
                  case "ArgentinaCaba":
                    auxRegion = LicenseTypes.ArgentinaCaba;
                    break;
                  case "ArgentinaPBA":
                    auxRegion = LicenseTypes.ArgentinaPBA;
                    break;

                  default:
                    auxRegion = this.globalVars.licenseType;
                }
              } else {
                auxRegion = this.globalVars.licenseType;
              }
              this.globalVars.user.lastLoginDate = new Date();
              this.globalVars.user.registratedDate = new Date();
              this.globalVars.user.userRegion = auxRegion;
              this.globalVars.licenseType = auxRegion;
              this.globalVars.rootScope.cookiesService.setCookie(
                CookieNames.CodereUser,
                data.username,
                this.globalVars.rootScope.cookiesService.YEAR_MILLISECONDS,
              );
              this.globalVars.user.id = data.id;
              if (this.globalVars.licenseType === LicenseTypes.Nacional) {
                this.globalVars.rootScope.cookiesService.setCookiePerUser(
                  CookieNames.MigrationCompleted,
                  data.username,
                  this.globalVars.rootScope.cookiesService.YEAR_MILLISECONDS,
                );
                try {
                  await this.setGTMTrackers();
                } catch (error) {
                  console.error(error);
                }
              }
              if (this.globalVars.FEATURES.MSO_GraceTimeLogicEnabled) {
                this.globalVars.extraUserData.graceTime = true;
                this.globalVars.extraUserData.documentationVerification = false;
                if (this.globalVars.licenseType === LicenseTypes.Panama) {
                  localStorage.setItem("paramsPaAfterReg", JSON.stringify({ comeFromReg: true }));
                }
              }
              if (this.globalVars.licenseType == LicenseTypes.Colombia) {
                this.globalVars.extraUserData.pptNotVerification = !!data["isPPT"];
                if (this.globalVars.partner === "mvp") {
                  this.globalVars.mvpData.login = "1";
                  sessionStorage.setItem(
                    "partnercodere",
                    JSON.stringify({ partner: this.globalVars.partner, mvpData: this.globalVars.mvpData }),
                  );
                }
              }
              if (doSetRoot) {
                await this.router.navigate([dest]);
              }
              this.events.publish("user:login");
              if (!this.globalVars.FEATURES.ModalBonusSportCasino) {
                this.events.publish(
                  "cashier:open",
                  params
                    ? { cashierEmpty: false, section: "deposit", ...params }
                    : { cashierEmpty: false, section: "deposit" },
                );
              }
              resolve(true);
            } else {
              resolve(false);
            }
          }
        },
        (err) => {
          resolve(false);
        },
      );
    });
  }

  base64ToArrayBuffer(base64: string): Uint8Array {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  saveByteArray(reportName: string, byte: Uint8Array, extension: string) {
    const blob = new Blob([byte], { type: "application/" + extension });
    const urlFile = window.URL.createObjectURL(blob);
    if (this.globalVars.isNative) {
      this.openLinkInInAppBrowser(urlFile);
    } else {
      const link = document.createElement("a");
      link.href = urlFile;
      const fileName = reportName;
      link.download = fileName;
      link.click();
    }
  }

  saveBlob(blob: Blob, name) {
    const urlFile = window.URL.createObjectURL(blob);
    const extension = blob.type.split("/")[1];
    const link = document.createElement("a");
    link.href = urlFile;
    const fileName = name + "." + extension;
    link.download = fileName;
    link.click();
  }

  downloadFile(base64: string, name: string, extension: string) {
    this.saveByteArray(name, this.base64ToArrayBuffer(base64), extension);
  }

  openLinkInInAppBrowser(link: string) {
    this.iab.create(link);
  }

  async sportAlertMendoza() {
    const msg = `Bienvenido a CODERE; gracias por registrarte, en los próximos días recibirás un Freebet para que puedas disfrutar de la experiencia.`;
    const icon = `<span class="closeIcon"><i class="color-dark codere-icon icon-close" item-right=""></i></span>`;
    const finalMSG: string = icon + msg;

    const alertSport = await this.alertController.create({
      message: finalMSG,
      buttons: [],
      cssClass: "mendozaSportAlertReg",
      backdropDismiss: false,
    });

    alertSport.present().then((_) => {
      const nodeIonAlert = alertSport;
      const iconW = nodeIonAlert.querySelector(".closeIcon");
      iconW.addEventListener("click", () => {
        alertSport.dismiss();
      });
    });
  }

  isColombia() {
    return this.globalVars.licenseType === LicenseTypes.Colombia;
  }

  isArgentina() {
    return (
      this.globalVars.licenseType === LicenseTypes.ArgentinaPBA ||
      this.globalVars.licenseType === LicenseTypes.ArgentinaCaba ||
      this.globalVars.licenseType === LicenseTypes.ArgentinaMendoza
    );
  }

  isCABAorMendoza() {
    return (
      this.globalVars.licenseType === LicenseTypes.ArgentinaCaba ||
      this.globalVars.licenseType === LicenseTypes.ArgentinaMendoza
    );
  }

  isNacional() {
    return this.globalVars.licenseType === LicenseTypes.Nacional;
  }

  isArgentinaCABA() {
    return this.globalVars.licenseType === LicenseTypes.ArgentinaCaba;
  }

  isMendoza() {
    return this.globalVars.licenseType === LicenseTypes.ArgentinaMendoza;
  }

  isMadrid() {
    return this.globalVars.licenseType === LicenseTypes.Madrid;
  }

  isPaisVasco() {
    return this.globalVars.licenseType === LicenseTypes.PaisVasco;
  }

  isSpain() {
    return (
      this.globalVars.licenseType === LicenseTypes.Nacional ||
      this.globalVars.licenseType === LicenseTypes.Madrid ||
      this.globalVars.licenseType === LicenseTypes.PaisVasco
    );
  }

  isMexico() {
    return this.globalVars.licenseType === LicenseTypes.Mexico;
  }

  isPanama() {
    return this.globalVars.licenseType === LicenseTypes.Panama;
  }

  LicenseTypeString(license: number): string {
    switch (license) {
      case LicenseTypes.Nacional:
        return "Nacional";
      case LicenseTypes.Madrid:
        return "Madrid";
      case LicenseTypes.Mexico:
        return "Mexico";
      case LicenseTypes.PaisVasco:
        return "PV";
      case LicenseTypes.Colombia:
        return "Colombia";
      case LicenseTypes.Panama:
        return "Panama";
      case LicenseTypes.ArgentinaCaba:
        return "ArgentinaCaba";
      case LicenseTypes.ArgentinaPBA:
        return "ArgentinaPBA";
      case LicenseTypes.ArgentinaCordoba:
        return "Cordoba";
      case LicenseTypes.ArgentinaMendoza:
        return "ArgentinaMendoza";
      default:
        return "";
    }
  }

  LicenseTypeBackendString(license: number): string {
    switch (license) {
      case LicenseTypes.ArgentinaCaba:
        return "ArgentinaCaba";
      case LicenseTypes.ArgentinaMendoza:
        return "ArgentinaMendoza";
      case LicenseTypes.ArgentinaPBA:
        return "ArgentinaPba";
      case LicenseTypes.Colombia:
        return "Colombia";
      case LicenseTypes.Mexico:
        return "Mexico";
      case LicenseTypes.Panama:
        return "Panama";
      case LicenseTypes.Nacional:
        return "Spain";
      default:
        return "Unknown";
    }
  }

  getCuotaAccept(value: any) {
    this.globalVars.user.customization = new C_Customization(
      value ? value.AutoAcceptOddUpChanges : false,
      value ? value.AutoAcceptOddDownChanges : false,
    );
    if (
      this.globalVars.user.customization.AutoAcceptOddUpChanges &&
      this.globalVars.user.customization.AutoAcceptOddDownChanges
    ) {
      this.globalVars.cuotaAccept = "0";
    } else if (
      this.globalVars.user.customization.AutoAcceptOddUpChanges &&
      !this.globalVars.user.customization.AutoAcceptOddDownChanges
    ) {
      this.globalVars.cuotaAccept = "1";
    } else if (
      !this.globalVars.user.customization.AutoAcceptOddUpChanges &&
      !this.globalVars.user.customization.AutoAcceptOddDownChanges
    ) {
      this.globalVars.cuotaAccept = "2";
    }
    this.newBaseService.setVar = this.globalVars;
  }

  setCuotaAccept(value: string) {
    // TODO: revisar por que no hace uso del modelo actualizado
    this.globalVars.cuotaAccept = value;
    switch (value) {
      case "0":
        //this.user.customization = new C_Customization(true, true);
        this.globalVars.user.customization = new C_Customization(true, true);
        break;
      case "1":
        //this.user.customization = new C_Customization(true, false);
        this.globalVars.user.customization = new C_Customization(true, false);
        break;
      case "2":
        //this.user.customization = new C_Customization(false, false);
        this.globalVars.user.customization = new C_Customization(false, false);
        break;
    }
    this.newBaseService.setVar = this.globalVars;
  }

  async startBarcodescanner(): Promise<any> {
    try {
      const result = await BarcodeScanner.scan();
      if (result.result) {
        return result.code;
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Sets the accessory bar visible/invisible, only supported on iPhone devices
   * @param visible whether the accessory bar should be visible
   */
  hideFormAccessoryBar(visible: boolean) {
    if (this.isMobile && this.device.getDevicePlatform() === "Mobile" && Capacitor.getPlatform() === "ios") {
      Keyboard.setAccessoryBarVisible({ isVisible: visible });
    }
  }

  async modalFiveLoginOrAutoexclusionAr(fiveLogins = false) {
    const modal = await this.modalCtrl.create({
      component: InfoAutoexclusion,
      componentProps: {
        type: 1,
        fiveLogins,
      },
      backdropDismiss: false,
      cssClass: "is-modal",
    });

    await modal.present();
  }

  async selfExclusionModal(type: number | string, modalController: ModalController = null) {
    const modal = await this.modalCtrl.create({
      component: InfoAutoexclusion,
      componentProps: {
        type: type,
        parent: modalController,
      },
      showBackdrop: false,
      backdropDismiss: false,
      cssClass: "is-modal",
    });

    await modal.present();
  }

  encryptWithPublicKey(valueToEncrypt: string, feature: keyof C_Features): string {
    const rsa = Forge.pki.publicKeyFromPem(this.formatFeaturePublicKey(feature));
    return window.btoa(rsa.encrypt(valueToEncrypt.toString()));
  }

  getLobbyFromUrl(): string {
    const pathSegments = this.router.url.split("?")[0].split("/");
    for (const segment of pathSegments) {
      for (const key in CASINO_PATHS) {
        if (CASINO_PATHS[key] === segment && segment !== CASINO_PATHS.BASE) {
          return CASINO_PATHS[key];
        }
      }
    }
    return CASINO_PATHS.SlotsPage;
  }

  private formatFeaturePublicKey(feature: keyof C_Features) {
    const arrayKey = this.globalVars.FEATURES[feature].split(";");
    return arrayKey.join("\n");
  }

  showAlertGraceTime(msg = ""): Promise<void> {
    const title = "¡CODERISTA!";
    const btnTitle = "OK";
    return new Promise<void>((resolve) => {
      this.alert(false, title, msg, btnTitle).then((alert) => {
        alert.onDidDismiss().then((_) => {
          resolve();
        });
      });
    });
  }

  differenceInDays(dateStart: Date, dateEnd: Date) {
    if (dateStart.getTime() > dateEnd.getTime()) {
      return 0;
    }

    const differenceMs: number = dateEnd.getTime() - dateStart.getTime();

    return Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  }

  setGTMTrackers(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const observer = this.globalVars.FEATURES.MSO_DecouplingTrackersCodereId
        ? this.globalVars.rootScope.decouplingUserServiceMSO.getTrackersCodereID()
        : this.globalVars.rootScope.userService.getTrackersCurrentUser();
      observer.subscribe(
        (trackers: TrackersCodereId[]) => {
          const GTMTrackers = {
            Mexos_Advertiser: "",
            Mexos_Aquisition_Source: "",
            Mexos_ID2: "",
            Mexos_ID3: "",
            Mexos_ProfileID: "",
          };
          trackers.forEach((track) => {
            switch (true) {
              case track.trackType === TRAKERSTYPECODEREID.mexosAquisitionSource:
                GTMTrackers.Mexos_Aquisition_Source = track.value;
                break;
              case track.trackType === TRAKERSTYPECODEREID.mexosAdvertiser:
                GTMTrackers.Mexos_Advertiser = track.value;
                break;
              case track.trackType === TRAKERSTYPECODEREID.mexosID2:
                GTMTrackers.Mexos_ID2 = track.value;
                break;
              case track.trackType === TRAKERSTYPECODEREID.mexosID3:
                GTMTrackers.Mexos_ID3 = track.value;
                break;
              case track.trackType === TRAKERSTYPECODEREID.mexosProfileID:
                GTMTrackers.Mexos_ProfileID = track.value;
                break;
            }
          });
          this.globalVars.user.codereIdTracker = GTMTrackers;
          resolve(true);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }
  getLocalTime(hour12 = true): string {
    let timeZone = "";

    switch (this.globalVars.licenseType) {
      case LicenseTypes.ArgentinaCaba:
        timeZone = "America/Argentina/Buenos_Aires";
        break;
    }

    return new Date().toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12,
      timeZone,
    });
  }

  changeFavIcon(url: string) {
    const iconLink: HTMLLinkElement = document.querySelector('link[rel="icon"]');
    const appleTouchIcon: HTMLLinkElement = document.querySelector('link[rel="apple-touch-icon"]');
    const appleMaskIcon: HTMLLinkElement = document.querySelector('link[rel="mask-icon"]');
    const shortCutIcon: HTMLLinkElement = document.querySelector('link[rel="shortcut icon"]');

    let iconHref = "";

    if (url.includes(CASINO_PATHS.BASE)) {
      if (!iconLink?.href.includes(Constants.MSO_Page_Icons.casino)) {
        iconHref = Constants.MSO_Page_Icons.casino;
      }
    } else {
      if (!iconLink?.href.includes(Constants.MSO_Page_Icons.sport)) {
        iconHref = Constants.MSO_Page_Icons.sport;
      }
    }

    if (iconHref) {
      iconLink?.remove();
      appleTouchIcon?.remove();
      appleMaskIcon?.remove();
      shortCutIcon?.remove();

      const timeStamp = `?v=${new Date().getTime()}`;

      const relIcons = ["icon", "apple-touch-icon", "shortcut icon"];

      relIcons.forEach((rel) => {
        const newIcon: HTMLLinkElement = document.createElement("link");
        const domain = location.origin + location.pathname;
        newIcon.setAttribute("href", domain + iconHref + timeStamp);
        newIcon.setAttribute("type", "image/png");
        newIcon.setAttribute("rel", rel);
        newIcon.setAttribute("sizes", "120x120");
        document.head.appendChild(newIcon);
      });
    }
  }
  setGraceTime(date) {
    const timenow = new Date();
    const graceTime = new Date(date);
    graceTime.setDate(graceTime.getDate() + Constants.Max_GraceTime);
    this.globalVars.extraUserData.graceTime = timenow.getTime() <= graceTime.getTime();
  }

  canResetContedCookies(url: string) {
    let canReset = false;
    const urls = [SPORTS_PATHS.DirectosPage, SPORTS_PATHS.NowLiveDetailPage, CASINO_PATHS.CasinoEnVivoPage];
    urls.forEach((urltocheck) => {
      if (url.includes(urltocheck)) {
        canReset = true;
      }
    });
    if (!canReset) {
      canReset = this.globalVars.user.playingSlots || this.globalVars.user.playingCasino;
    }
    return canReset;
  }

  isValidDate(dateObject) {
    return new Date(dateObject).toString() !== "Invalid Date";
  }

  getModalHeight(id: string, sustractPx?: number) {
    if (!this.isMobile) {
      //if isMobile, no need Modal, scroll is mobile scroll.
      let height = 750;
      height = document.getElementById(id)?.clientHeight;
      return sustractPx ? height - sustractPx : height;
    }
  }

  getMexosCookieInfoFromCookieValue(cookieValue: string) {
    if (cookieValue) {
      // Cookie shouldn't be encoded, just recheck
      cookieValue = decodeURIComponent(cookieValue);
      const cookieValues = cookieValue.split(",");
      // Validate length
      if (cookieValues.length < 5 || (!cookieValues[4].startsWith("BTAG") && cookieValues.length < 6)) return null;
      const result = {
        advertiser: cookieValues[0],
        adquisitionSource: cookieValues[1],
        profileId: cookieValues[2],
        bannerId: cookieValues[3],
        btagInfo: [],
        product: "",
      };
      if (cookieValues[4].startsWith("BTAG")) {
        result.btagInfo = this.convertStringToDictionary(cookieValues[4], ";", ":");
      } else {
        result.product = cookieValues[4];
        result.btagInfo = this.convertStringToDictionary(cookieValues[5], ";", ":");
      }
      return result;
    }
    return null;
  }

  private convertStringToDictionary(value: string, pairSeparator: string, keyValueSeparator: string) {
    const result = [];
    try {
      const keyValueStringPairs = value.split(pairSeparator);
      keyValueStringPairs.forEach((keyValueStringPair) => {
        const keyValueArray = keyValueStringPair.split(keyValueSeparator);
        result[keyValueArray[0]] = keyValueArray[1];
      });
    } catch (err) {
      console.log(err);
    }
    return result;
  }

  mapUserInfo({ userInfo }: DecouplingInfoPlayer): I_UserInfo {
    const userinfodata: I_UserInfo = {
      ispromotable: userInfo.isPromotable,
      ispromotablevip: userInfo.isPromotableVip,
      vipLevel: userInfo.vipLevel,
      vipstats: userInfo.vipStatus,
      registrationDate: userInfo.registrationDate,
      userCodere: userInfo.playerUserName,
      eligibilityStatus: userInfo.docVerification,
      verifiDocumental: userInfo.docVerification ? "Completed" : "Pending",
      isUserMadeFTD: userInfo.isFirstDeposit,
      canWithdraws: userInfo.canWithdraws || null,
      balance: userInfo.balance,
      selfExcluded: userInfo.selfExcluded,
      segmentedClosed: userInfo.segmentedClosed,
      vipEstado: userInfo.vipEstado,
    };
    if (this.globalVars.licenseType === LicenseTypes.Nacional) {
      userinfodata.userOptinPossibility = userInfo.userOptinPossibility;
      userinfodata.birthdate = userInfo.birthdate;
      userinfodata.commercialCommunications = userInfo.commercialCommunications;
      userinfodata.notverified30days = userInfo.notVerifiedOver30days;
    }
    return userinfodata;
  }

  get isQA(): boolean {
    return (
      this.globalVars.ENVIRONMENT === "qa" ||
      this.globalVars.ENVIRONMENT === "dev" ||
      Constants.qaServers.includes(new URL(this.globalVars.DOMAIN).host)
    );
  }
}
