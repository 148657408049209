/* eslint-disable */
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "formatNameResult" })
export class FormatNameResultPipe implements PipeTransform {
  transform(value: string): string {
    if (value == null) {
      return "";
    }
    let auxName = "";
    const arrName: Array<string> = value.split(" ");
    arrName.forEach((element) => {
      if (element.length > 2) {
        element = element.charAt(0) + element.slice(1).toLowerCase();
        auxName += element + " ";
      } else {
        auxName += element + " ";
      }
    });
    return value;
  }
}
