import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Subscription, fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "lb-search",
  templateUrl: "./lobby-search.html",
  imports: [CommonModule, FormsModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./lobby-search.scss"],
})
export class LobbySearchComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() active: any;
  @Input() theme: string;
  @Output() searchTerm: EventEmitter<string> = new EventEmitter();
  @ViewChild("searchInput") searchInput: ElementRef;
  term = "";
  isSearching: boolean;
  isDesktop = false;
  currentFilterIndex = false;

  private subscription$: Subscription = new Subscription();

  ngOnChanges() {
    if (this.active >= 0) {
      this.term = "";
      this.isSearching = false;
    }
  }

  ngAfterViewInit(): void {
    const focus$ = fromEvent(this.searchInput.nativeElement, "focus", () => {
      this.isSearching = true;
    })
      .pipe(debounceTime(0))
      .subscribe();

    const keyup$ = fromEvent(this.searchInput.nativeElement, "keyup", () => {})
      .pipe(debounceTime(350))
      .subscribe(() => {
        this.searchTerm.emit(this.term);
      });

    const blur$ = fromEvent(this.searchInput.nativeElement, "blur", () => {
      this.isSearching = Boolean(this.term);
    }).subscribe();

    this.subscription$.add(focus$);
    this.subscription$.add(keyup$);
    this.subscription$.add(blur$);
  }

  ngOnDestroy(): void {
    if (!this.subscription$) return;
    this.subscription$.unsubscribe();
  }

  @HostBinding("class.active")
  get isActive() {
    return this.isSearching;
  }
}
