// models/C_Game.ts
export interface c_FreeBet {
  DepositAmount: number;
  FreebetsAmount: number;
  MinOdds: number;
  DateLimit: string;
  StartDate: string;
  ConversionRate: number;
  VoucherMultiplier: number;
  VoucherValue: number;
  LastGrossWinUpdate: string;
  PromoName: string;
  ValidBets: number;
  MaxBets: number;
  PromoType: string;
  MaxBonusMultiplier: number;
  GrossWin: number;
  ConditionsUrl: string;
}

export interface c_FreeBetMapped {
  Name: string;
  Type: string;
  GrossWin: number;
  AmountFb: number;
  TotalBets: number;
  minOdd: number;
  AmountBets: number;
  pendingAmount: number;
  date: Date;
  dateStart: string;
  dateGW: number;
  active: string;
  PercentDev: number;
  Url: string;
}

//export = c_FreeBet
