<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<ng-container *ngIf="!isMobile; else mobileView">
  <ion-content [class.license-co]="globalVars.licenseType == 21">
    <ion-row class="toolbar-content contact-help" *ngIf="globalVars.licenseType == 0 || globalVars.licenseType == 1">
      <ion-col>
        <span (click)="openContact()">{{ 'v2_horario' | translate }}</span>
      </ion-col>
    </ion-row>
    <ng-container *ngIf="globalVars.FEATURES.contactServiceEnabled">
      <div class="contact-form-container" [ngClass]="{'hcontact': globalVars.licenseType == 0}">
        <ng-container *ngIf="errService">
          <h1>{{ 'tError' | translate }}</h1>
        </ng-container>

        <div class="contact-form-wrapper">
          <ng-container *ngIf="globalVars.licenseType == 21 && !errService">
            <div class="qtitle">{{ 'contact_frequentlyquestions' | translate }}</div>
            <div class="qtitletxt">{{ 'contact_frequentlyquestionstxt' | translate }}</div>
          </ng-container>
          <ng-container *ngIf="!showLastPage">
            <ng-container>
              <custom-select
                *ngIf=" !errService"
                [values]="categories"
                [placeholder]="('tSelectCategory' | translate)"
                (onSelected)="handleCategoryChanged($event)"
              ></custom-select>

              <custom-select
                *ngIf=" !errService"
                #selectSubjects
                [values]="subjects"
                [placeholder]="('tSelectSubject' | translate)"
                [disabled]="!subjects || subjects.length===0"
                (onSelected)="handleSubjectSelected($event)"
              >
              </custom-select>
            </ng-container>
            <ng-container *ngIf="!loadingService">
              <ng-container *ngIf=" !errService">
                <div class="contact-response-wrapper">
                  <div *ngIf="messageDescription" [innerHtml]="messageDescription" class="contact-response"></div>
                </div>

                <contact-autoresponse
                  *ngIf="isAutoResponse"
                  [logged]="showFormCredentials"
                  [response]="messageDescription"
                  [subject]="subjectSelected"
                  [hasBeenUseful]="responseUseful"
                  (onResponseUseful)="handleResponseUseful($event)"
                >
                </contact-autoresponse>

                <!-- <contact-form-credentials [hasBeenUseful]="responseUseful" *ngIf="showFormCredentials && responseUseful"
                  [logged]="globalVars.user.logged" (onSubmitWithCredentials)="handleSubmitCredentials($event)">
                </contact-form-credentials> -->
                <p
                  *ngIf="(isAutoResponse===false || responseUseful === false ) && !showLastPage && globalVars.licenseType !=21"
                >
                  <b
                    >Por favor, para más información contacta con el servicio de atención al cliente por alguno de estos
                    canales:</b
                  >
                </p>
                <p
                  *ngIf="(isAutoResponse===false || responseUseful === false ) && !showLastPage && globalVars.licenseType ==21"
                >
                  <b> {{ 'contact_notxt' | translate }}</b>
                  <!--cuando el user dice no -->
                </p>
                <p *ngIf="responseUseful===true && globalVars.licenseType ==21">
                  <b> {{ 'contact_yestxt' | translate }}</b>
                  <!--cuando el user dice yes -->
                </p>
              </ng-container>
              <div class="chat-phone-help" *ngIf="globalVars.licenseType != 21">
                <ion-list class="open-chat-wrapper user-list has-shadow is-rounded list list-ios">
                  <button class="btn-chat-online" (click)="openChat()">
                    {{ 'tChatOnline' | translate }}
                    <img [src]="chatSrc" class="chat-online" />
                  </button>
                  <form action="" novalidate="" class="ng-untouched ng-pristine ng-valid">
                    <!---->
                    <div
                      *ngIf="globalVars.licenseType === 0 || globalVars.licenseType === 33 || globalVars.licenseType === 34"
                    >
                      <div class="contactTitle">{{ 'callFree' | translate }}</div>
                      <div class="whereContact">
                        {{ globalVars.licenseType === 34 ? 'tHelpcallfreepba' : 'tHelpcallfree'| translate }}
                      </div>
                    </div>
                    <!---->
                    <!---->
                  </form>
                </ion-list>
              </div>
              <div *ngIf="globalVars.licenseType == 0">
                {{ 'contactpoliticaar' | translate }}
                <br />
                {{ 'customersupport' | translate }}:
                <a href="mailto:apuestas@codere.com">{{ 'Mail' | translate }}</a>
              </div>
              <contact-form
                *ngIf="(showForm||isAutoResponse===false) && globalVars.licenseType != 21 && !errService"
                [placeholder]="((isAutoResponse?'tContactTextAreaAnother':'tContactTextAreaAsk')| translate)"
                (onSubmit)="handleSubmit($event)"
                (onSubmitWithCredentials)="handleSubmitWithCredentials($event)"
              >
              </contact-form>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="showLastPage && !errService">
            <contact-resume [response]="lastPage.response" [subject]="lastPage.subject" [question]="lastPage.question">
            </contact-resume>
          </ng-container>
        </div>
        <p class="contact-paragraph a" *ngIf="showLastPage && !errService" (click)="resetAll()">
          {{'tContacAnotherquestion' | translate }}
        </p>
      </div>
    </ng-container>
    <ng-container *ngIf="!globalVars.FEATURES.contactServiceEnabled && globalVars.licenseType !=21">
      <ion-list class="open-chat-wrapper is-narrow-content list list-md list-flex marginb100">
        <button class="btn-chat-online" (click)="openChat()">
          {{ 'tChatOnline' | translate }}
          <img [src]="chatSrc" class="chat-online" />
        </button>
        <form action="" novalidate="" class="ng-untouched ng-pristine ng-valid">
          <div>
            <div class="contactTitle">{{ 'callFree' | translate }}</div>
            <div class="whereContact" *ngIf="globalVars.licenseType != 21">{{ 'tHelpcallfree' | translate }}</div>
          </div>
          <!---->
          <div class="whereContact" *ngIf="globalVars.licenseType == 21">{{ 'tHelpcallfree' | translate }}</div>
          <div class="whereContact">
            <a href="mailto:apuestas@codere.com">Escríbenos a apuestas@codere.com</a>
          </div>

          <!---->
        </form>
      </ion-list>
      <div *ngIf="globalVars.licenseType == 22" class="contactpa">
        {{ 'contactapa' | translate }}
        <a href="https://m.codere.pa/csbgonline/condicionesgenerales/politicaprivacidadPanama.pdf" target="_blank"
          >{{ 'contactpoliticaar3' | translate }}
        </a>
      </div>
    </ng-container>
    <ng-container *ngIf="globalVars.licenseType == 21">
      <contact-with-us (clickChatOnline)="openChat()"> </contact-with-us>
      <div class="contacttxt">
        <div class="contacttxtlegal">
          {{ 'contactocotxtlegal' | translate }}
          <a href="mailto:dataprotection@codereonline.com" external-link>
            {{ 'contactpoliticaaremail' | translate }}
          </a>
          {{ 'contactpoliticaar2' | translate }}
          <a
            href="https://m.codere.com.co/csbgonline/condicionesgenerales/politicaPrivacidadColombia.pdf"
            target="_blank"
            external-link
          >
            {{ 'poltratadatospersonal' | translate}}
          </a>
        </div>
      </div>
    </ng-container>
    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<!-- Mobile -->
<ng-template #mobileView>
  <ion-content [class.license-co]="globalVars.licenseType == 21">
    <div class="mobile-content">
      <ion-row class="toolbar-content contact-help" *ngIf="globalVars.licenseType == 0 || globalVars.licenseType == 1">
        <ion-col>
          <span (click)="openContact()">{{ 'v2_horario' | translate }}</span>
        </ion-col>
      </ion-row>
      <ng-container *ngIf="globalVars.FEATURES.contactServiceEnabled">
        <div class="contact-form-container" [ngClass]="{'hcontact': globalVars.licenseType == 0}">
          <ng-container *ngIf="errService">
            <h1>{{ 'tError' | translate }}</h1>
          </ng-container>
          <div class="contact-form-wrapper">
            <ng-container *ngIf="globalVars.licenseType == 21">
              <div class="qtitle">{{ 'contact_frequentlyquestions' | translate }}</div>
            </ng-container>
            <ng-container *ngIf="!showLastPage">
              <ng-container>
                <custom-select
                  *ngIf="!errService"
                  [values]="categories"
                  [placeholder]="('tSelectCategory' | translate)"
                  (onSelected)="handleCategoryChanged($event)"
                ></custom-select>

                <custom-select
                  *ngIf="!errService"
                  #selectSubjects
                  [values]="subjects"
                  [placeholder]="('tSelectSubject' | translate)"
                  [disabled]="!subjects || subjects.length===0"
                  (onSelected)="handleSubjectSelected($event)"
                >
                </custom-select>
              </ng-container>
              <ng-container *ngIf="!loadingService">
                <ng-container *ngIf="!errService">
                  <div class="contact-response-wrapper">
                    <div *ngIf="messageDescription" [innerHtml]="messageDescription" class="contact-response"></div>
                  </div>

                  <contact-autoresponse
                    *ngIf="isAutoResponse"
                    [logged]="showFormCredentials"
                    [response]="messageDescription"
                    [subject]="subjectSelected"
                    [hasBeenUseful]="responseUseful"
                    (onResponseUseful)="handleResponseUseful($event)"
                  >
                  </contact-autoresponse>

                  <!-- <contact-form-credentials [hasBeenUseful]="responseUseful" *ngIf="showFormCredentials && responseUseful"
                  [logged]="globalVars.user.logged" (onSubmitWithCredentials)="handleSubmitCredentials($event)">
                </contact-form-credentials> -->
                  <p
                    *ngIf="(isAutoResponse===false || responseUseful === false ) && !showLastPage && globalVars.licenseType !=21"
                  >
                    <b
                      >Por favor, para más información contacta con el servicio de atención al cliente por alguno de
                      estos canales:</b
                    >
                  </p>
                  <p
                    *ngIf="(isAutoResponse===false || responseUseful === false ) && !showLastPage && globalVars.licenseType ==21"
                  >
                    <b> {{ 'contact_notxt' | translate }}</b>
                    <!--cuando el user dice no -->
                  </p>
                  <p *ngIf="responseUseful===true && globalVars.licenseType ==21">
                    <b> {{ 'contact_yestxt' | translate }}</b>
                    <!--cuando el user dice yes -->
                  </p>
                </ng-container>
                <div class="chat-phone-help" *ngIf="globalVars.licenseType != 21">
                  <ion-list class="open-chat-wrapper user-list has-shadow is-rounded list list-ios">
                    <button class="btn-chat-online" (click)="openChat()">
                      {{ 'tChatOnline' | translate }}
                      <img [src]="chatSrc" class="chat-online" />
                    </button>

                    <form action="" novalidate="" class="ng-untouched ng-pristine ng-valid">
                      <!---->
                      <div
                        *ngIf="globalVars.licenseType === 0 || globalVars.licenseType === 33 || globalVars.licenseType === 34"
                      >
                        <div class="contactTitle">{{ 'callFree' | translate }}</div>
                        <div
                          class="whereContact"
                          *ngIf="globalVars.licenseType === 33 || globalVars.licenseType === 34"
                        >
                          {{ globalVars.licenseType === 34 ? 'tHelpcallfreepba' : 'tHelpcallfree'| translate }}
                        </div>
                        <div class="whereContact" *ngIf="globalVars?.licenseType === 0">
                          <a href="callto:913432950">{{ 'tHelpcallfree' | translate }}</a>
                        </div>
                      </div>
                      <!---->
                      <!---->
                    </form>
                  </ion-list>
                </div>
                <div *ngIf="globalVars.licenseType == 0">
                  {{ 'contactpoliticaar' | translate }}
                  <br />
                  {{ 'customersupport' | translate }}:
                  <a href="mailto:apuestas@codere.com">{{ 'Mail' | translate }}</a>
                </div>
                <contact-form
                  *ngIf="(showForm||isAutoResponse===false) && globalVars.licenseType != 21 && !errService"
                  [placeholder]="((isAutoResponse?'tContactTextAreaAnother':'tContactTextAreaAsk')| translate)"
                  (onSubmit)="handleSubmit($event)"
                  (onSubmitWithCredentials)="handleSubmitWithCredentials($event)"
                >
                </contact-form>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="showLastPage && !errService">
              <contact-resume
                [response]="lastPage.response"
                [subject]="lastPage.subject"
                [question]="lastPage.question"
              >
              </contact-resume>
            </ng-container>
          </div>
          <p class="contact-paragraph a" *ngIf="showLastPage && !errService" (click)="resetAll()">
            {{'tContacAnotherquestion' | translate }}
          </p>
        </div>
      </ng-container>
      <ng-container *ngIf="!globalVars.FEATURES.contactServiceEnabled && globalVars.licenseType !=21">
        <ion-list class="open-chat-wrapper list list-md list-flex marginb100">
          <button class="btn-chat-online" (click)="openChat()">
            {{ 'tChatOnline' | translate }}
            <img [src]="chatSrc" class="chat-online" />
          </button>
          <form action="" novalidate="" class="ng-untouched ng-pristine ng-valid">
            <div>
              <div class="contactTitle">{{ 'callFree' | translate }}</div>
              <div class="whereContact" *ngIf="globalVars.licenseType != 21">{{ 'tHelpcallfree' | translate }}</div>
            </div>
            <!---->
            <div class="whereContact" *ngIf="globalVars.licenseType == 21">{{ 'tHelpcallfree' | translate }}</div>
            <div class="whereContact">
              <a href="mailto:apuestas@codere.com">Escríbenos a apuestas@codere.com</a>
            </div>

            <!---->
          </form>
        </ion-list>
        <div *ngIf="globalVars.licenseType == 22" class="contactpa">
          {{ 'contactapa' | translate }}
          <a href="https://m.codere.pa/csbgonline/condicionesgenerales/politicaprivacidadPanama.pdf" target="_blank"
            >{{ 'contactpoliticaar3' | translate }}
          </a>
        </div>
      </ng-container>
      <ng-container *ngIf="globalVars.licenseType == 21">
        <contact-with-us (clickChatOnline)="openChat()"> </contact-with-us>
        <div class="contacttxt">
          <div class="contacttxtlegal">
            {{ 'contactocotxtlegal' | translate }}
            <a href="mailto:dataprotection@codereonline.com" external-link>
              {{ 'contactpoliticaaremail' | translate }}
            </a>
            {{ 'contactpoliticaar2' | translate }}
            <a
              href="https://m.codere.com.co/csbgonline/condicionesgenerales/politicaPrivacidadColombia.pdf"
              target="_blank"
              external-link
            >
              {{ 'poltratadatospersonal' | translate}}
            </a>
          </div>
        </div>
      </ng-container>
    </div>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
