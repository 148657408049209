export interface C_OveraskData {
  ExpiryDate: string;
  MaxStake: number;
  RejectId: number;
  Status: number | string;
  StatusString: string;
}

export class OveraskDataMethods {
  public static newOveraskData = (): C_OveraskData => ({
    ExpiryDate: "",
    MaxStake: 0,
    RejectId: 0,
    Status: "OK",
    StatusString: "",
  });
}

export class C_OveraskDataBetPlacement {
  RejectId: number;
  MaxStake: number;
  Approved: boolean;
  Rejected: boolean;
  Finished: boolean;
  constructor() {}
}

// eslint-disable-next-line no-shadow
export enum C_OveraskStatus {
  OVERASK_OK = "[Overask] Ok",
  OVERASK_APPROVAL_WAITING = "[Overask] Approval Waiting",
  OVERASK_MAX_STAKE = "[Overask] Max Stake",
  OVERASK_COUNT_DOWN = "[Overask] CountDown",
  OVERASK_TIMEOUT = "[Overask] TimeOut",
  OVERASK_REJECTD = "[Overask] Rejected",
}
