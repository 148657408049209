export interface closeTicketResult {
  GeneralError: boolean;
  SingleTicketItem?: closeTicketItem;
  TicketItems?: closeTicketItem[];
  TotalPotentialWin?: number;
  TotalStake?: number;
  PlayerError?: boolean;
  PlayerErrorMessage?: string;
}

export interface closeTicketItem {
  NewOdds: newOdds[];
  Stake: number;
  PotentialWin: number;
  TicketId: string;
  ErrorCode: string;
  ErrorDescription?: string;
  ResultId: string;
  OveraskRejectId?: number;
  ItemId?: number;
}

export interface newOdds {
  Odd: number;
  ResultId: string;
}
