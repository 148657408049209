import { CommonModule, TitleCasePipe } from "@angular/common";
/* eslint-disable */
import { Component, Input, type OnInit, inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { Item } from "@models/footer/item";
import { TranslateModule } from "@ngx-translate/core";
import { NewBaseService } from "@providers/newBase.service";

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule],
  providers: [TitleCasePipe],
  selector: "codere-footer-new-imgs",
  templateUrl: "./codere-footer-new-imgs.html",
  styleUrls: ["./codere-footer-new-imgs.scss"],
})
export class CodereFooterNewImgs implements OnInit {
  @Input() items!: Item[];
  @Input() additionalClass!: string;
  globalVars!: GlobalVarsModel;

  sanitizer = inject(DomSanitizer);
  titleCasePipe = inject(TitleCasePipe);
  newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
  }

  sanitize(url: string | undefined) {
    if (!url) return;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  ngOnInit(): void {}

  openLink($event: any, item: Item) {
    if (!item.extrernal) {
      $event.preventDefault();
      // **+**
      // if (this.globalVars.isNative) {
      //   window.open(item.href, '_system', 'location=yes');
      // } else {
      //   window.open(item.href, '_blank')
      // }
      // **+**
    }
  }
}
