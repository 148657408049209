import { C_Game } from "./game.model";

export class C_SearchResult {
  SearchPriority: number;
  League: string;
  Country: string;
  ResultInfo: any;
  PaddockId: string;
  LeagueName: any;
  StartDate: string;
  Games: C_Game[];
  isLive: boolean;
  StatisticsId: any;
  StreamingEnabled: any;
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  EventNodeTypeId: number;
  Priority: number;
  SportHandle: string;
  ChildrenCount: number;
  StartDateFormatted: any;

  constructor(
    searchPriority: number,
    league: string,
    country: string,
    resultInfo: any,
    paddockId: string,
    leagueName: string,
    startDate: string,
    games: C_Game[],
    isLive: boolean,
    statisticsId: any,
    streamingEnabled: any,
    nombre: string,
    nId: string,
    parentNodeId: string,
    eventNodeTypeId: number,
    priority: number,
    sporthandle: string,
    childrenCount: number,
  ) {
    this.SearchPriority = searchPriority;
    this.League = league;
    this.Country = country;
    this.ResultInfo = resultInfo;
    this.PaddockId = paddockId;
    this.LeagueName = leagueName;
    this.StartDate = startDate;
    this.Games = games;
    this.isLive = isLive;
    this.StatisticsId = statisticsId;
    this.StreamingEnabled = streamingEnabled;
    this.Name = nombre;
    this.NodeId = nId;
    this.ParentNodeId = parentNodeId;
    this.EventNodeTypeId = eventNodeTypeId;
    this.Priority = priority;
    this.SportHandle = sporthandle;
    this.ChildrenCount = childrenCount;
  }
}
