/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from "@angular/core";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { NewBaseService } from "@providers/newBase.service";

@Injectable({ providedIn: "root" })
export class DataStorageService {
  private dataStorage: any = {};
  globalVars!: GlobalVarsModel;
  newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  addData(key: string, data: any) {
    this.dataStorage[key] = data;
    // if (this.globalVars.debugMode) {
    //    console.log(this.dataStorage);
    // }
  }

  getData(key: string) {
    return this.dataStorage[key];
  }

  clearData(key: string): void {
    this.dataStorage[key] = undefined;
    // eslint-disable-next-line no-shadow
    const newObject: any = Object.keys(this.dataStorage).reduce((acc, key) => {
      const _acc = acc;
      if (this.dataStorage[key] !== undefined) _acc[key] = this.dataStorage[key];
      return _acc;
    }, {});
    this.dataStorage = newObject;
  }
}
