import { LineItem } from "./line-item.model";

export class LineItemAdapter {
  constructor(private obj: any) {}

  adapt(): LineItem {
    const lineItem: LineItem = {
      // betslip
      Amount: this.obj.Amount,
      AmountLocal: this.obj.AmountLocal,

      BetType: this.obj.BetType,
      Date: this.obj.Date,
      downOdd: false,
      EventDate: this.obj.Date,
      EventId: "0",
      EventName: this.obj.Date + " " + this.obj.Paddock, // must
      GameId: this.obj.GameId,
      GameName: this.gameName, // must be Gemela / Trío, / Etc.
      GameSpecialOddsValue: this.obj.GameSpecialOddsValue,
      GreyhoundHorseBetType: this.obj.GreyhoundHorseBetType,
      GreyhoundHorses: true,
      Handicap: this.obj.Handicap,
      Id: this.obj.Id,
      Index: this.obj.Index,
      IsBanked: false,
      IsBet: this.obj.IsBet,
      IsSelected: this.obj.IsSelected,
      LineErrorMessages: this.obj.LineErrorMessages,
      LineErrors: this.obj.LineErrors,
      LineItemStatus: this.obj.Status,
      LiveStatus: false,
      Name: "Name",
      Odd: this.obj.Odd,
      Ordered: this.obj.Ordered,
      Paddock: this.obj.Paddock,
      Participants: this.obj.Participants,
      PendingCheck: this.obj.PendingCheck,
      PipelineExecutionResult: this.obj.PipelineExecutionResult,
      Place: this.obj.Place,
      Price: 0,
      ResultName: this.resultName.trim(),
      ResultsNr: "",
      Selected: this.obj.Selected,
      SpecialOddsValue: this.obj.SpecialOddsValue,
      SportHandle: this.obj.SportHandle,
      Status: this.obj.Status,
      Tax: this.obj.Tax,
      TicketNumber: this.obj.TicketNumber,
      TotalPotentialWin: this.obj.TotalPotentialWin,
      Type: this.obj.BetType,
      upOdd: false,
    };

    return lineItem;
  }

  private get gameName(): string {
    let comb = false;
    const gameName = "";
    this.obj.Participants.reduce((acc, current) => (comb = current.Place === -1));

    return comb
      ? this.obj.BetType === 13004
        ? "tTwinC"
        : "tTrioC"
      : this.obj.BetType === 13004
        ? "tTwin"
        : "tTrio";
  }

  private get resultName(): string {
    let sum = "";
    this.obj.Participants.map(
      (item, i) => (sum += (i ? ", " : "") + (item.Place === -1 ? item.Name : item.Place + "º " + item.Name)),
    );

    return sum;
  }
}
