import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "slotName", standalone: true })
export class SlotName implements PipeTransform {
  transform(slot?: string): string {
    if (slot) {
      return slot
        .replace("PT ", "")
        .replace("MGA ", "")
        .replace("Isb ", "")
        .replace("ISB ", "")
        .replace("EVG ", "")
        .replace("SG ", "")
        .replace("G1 ", "")
        .replace("HAB ", "")
        .replace("MGS ", "")
        .replace("IFO ", "")
        .replace("PGO ", "")
        .replace("PGP ", "")
        .replace("PRP ", "")
        .replace("SKY ", "")
        .replace("UAT ", "")
        .replace("SGD ", "")
        .replace("EGT ", "")
        .replace("ZIT ", "");
    }
    return;
  }
}
