export class C_SpecialSports {
  countryCode: string;
  icon: string;
  idLeagues: string;
  sport: string;
  sportHandle: string;
  sportName: string;

  constructor(
    CountryCode: string, // countryCode
    IconUrl: string, // icon
    IdLeagues: string, //idLeagues
    LeagueName: string, // sport
    SportHandle: string, // sportHandle
    SportName: string, // sportName
  ) {
    this.countryCode = CountryCode; // countryCode
    this.icon = IconUrl; // icon
    this.idLeagues = IdLeagues; // idLeagues
    this.sport = LeagueName; // sport
    this.sportHandle = SportHandle; // sportHandle
    this.sportName = SportName; // sportName
  }
}
