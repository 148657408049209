import { AstropayWithdrawal } from "./AstropayWithdrawal";
import { CardSelectionWithdrawal } from "./CardSelection-withdrawal";
import { ClabeWithDrawal } from "./ClabeWithDrawal";
import { CrownCasinoWithdrawal } from "./CrownCasinoWithdrawal";
import { DaviPlataWithdrawal } from "./DaviPlataWithdrawal";
import { EPGMuchBetterWithDrawal } from "./EPGMuchBetterWithDrawal";
import { LotbaWithdrawal } from "./LotbaWithdrawal";
import { NequiWithdrawal } from "./NequiWithdrawal";
import { PSEWithDrawal } from "./PSEWithDrawal";
import { TrueLayerWithdrawal } from "./TrueLayerWithdrawal";
import { BankTransferWithDrawal } from "./banktransfer-withdrawal";
import { CoinspaidWithDrawal } from "./coinspaid-withdrawal";
import { EfectyWithDrawal } from "./efecty-withdrawal";
import { HalCashWithDrawal } from "./halcash-withdrawal";
import { ItauWithDrawal } from "./itaubanktransfer-withdrawal";
import { LocalWithDrawal } from "./local-withdrawal";
import { PayPalWithDrawal } from "./paypal-withdrawal";
import { TPagaWithDrawal } from "./tpaga-withdrawal";

export class WithDrawalFactory {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  types: Map<string, any>;
  constructor() {
    this.types = new Map();
    this.mapAllowedTypes();
  }

  getType(type: string) {
    if (!this.hasType(type)) {
      // throw Error('This payment has not been implemented yet');
      return null;
    }
    return this.types.get(type);
  }

  hasType(type: string): boolean {
    return this.types.has(type);
  }

  // @type: is a className and a key of this.types Map
  // @args: are all properties of the class defined in value this.types Map;
  create(type: string, args: AnalyserNode) {
    const WithDrawal = this.getType(type);
    if (WithDrawal) return new WithDrawal(args);
  }

  protected mapAllowedTypes() {
    const allowed = [
      { key: "BankTransferWithDrawal", value: BankTransferWithDrawal },
      { key: "ItauWithDrawal", value: ItauWithDrawal },
      { key: "HalCashWithDrawal", value: HalCashWithDrawal },
      { key: "PayPalWithDrawal", value: PayPalWithDrawal },
      { key: "LocalWithDrawal", value: LocalWithDrawal },
      { key: "TPagaWithDrawal", value: TPagaWithDrawal },
      { key: "ClabeWithDrawal", value: ClabeWithDrawal },
      { key: "EPGMuchBetterWithDrawal", value: EPGMuchBetterWithDrawal },
      { key: "CoinspaidWithDrawal", value: CoinspaidWithDrawal },
      { key: "NequiWithdrawal", value: NequiWithdrawal },
      { key: "DaviPlataWithdrawal", value: DaviPlataWithdrawal },
      { key: "PSEWithDrawal", value: PSEWithDrawal },
      { key: "CardSelectionWithdrawal", value: CardSelectionWithdrawal },
      { key: "TrueLayerWithdrawal", value: TrueLayerWithdrawal },
      { key: "AstropayWithdrawal", value: AstropayWithdrawal },
      { key: "CrownCasinoWithdrawal", value: CrownCasinoWithdrawal },
      { key: "LotbaWithdrawal", value: LotbaWithdrawal },
      { key: "EfectyWithDrawal", value: EfectyWithDrawal },
    ];
    allowed.forEach((payment) => this.types.set(payment.key, payment.value));
  }
}
