import { Component } from "@angular/core";
import { IonicModule, ModalController } from "@ionic/angular";

@Component({
  selector: "cs-verification-alert-modal",
  templateUrl: "./cs-verification-alert-modal.html",
  styleUrls: ["./cs-verification-alert-modal.scss"],
  standalone: true,
  imports: [IonicModule],
})
export class CsVerificationAlertModal {
  title: string;
  constructor(public modalCtrl: ModalController) {
    this.title = "Verificación Necesaria";
  }

  async verifyNow() {
    // Sends data onDismiss to parent
    await this.modalCtrl.dismiss({ verificationAccepted: true });
  }

  async cancel() {
    // Sends data onDismiss to parent
    await this.modalCtrl.dismiss({ verificationAccepted: false });
  }
}
