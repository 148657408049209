/* eslint-disable */
import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import {
  IonicModule,
  ModalController,
  /* App, */
  NavController,
  /* Content, */
  /* Events, */
  /* ViewController, */
} from "@ionic/angular";
import { TransactionActionResult } from "@models/C_Transactions";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ParseMoney } from "@pipes/parseMoney";
import { BalancesService } from "@providers/BalancesService";
import { ErrorService } from "@providers/ErrorService";
import { EventsService } from "@providers/EventsService";
import { TrackingService } from "@providers/TrackingService";
import { UserService } from "@providers/UserService";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { CodereNavbar } from "@shared-common-components/codere-navbar/codere-navbar";
import { CodereFooterComponent } from "@shared-mso-components/footer/codere-footer/codere-footer";
import { UserSectionTitle } from "@shared-mso-components/user-section-title/user-section-title";
import { Utils } from "@utils/Utils";
import { depositsAmountButtonsComponent } from "../../components/deposits-amount-buttons/deposits-amount-buttons";
import { depositsInputsComponent } from "../../components/deposits-amount-input/deposits-amount-input";

@Component({
  selector: "charge-codere-page",
  templateUrl: "./charge-codere.html",
  styleUrls: ["./charge-codere.scss"],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    depositsInputsComponent,
    ParseMoney,
    depositsAmountButtonsComponent,
    UserSectionTitle,
    CodereNavbar,
    CodereFooterComponent,
  ],
})
export class ChargeCoderePage {
  /* @ViewChild(Content) content: Content; */
  formCharge: FormGroup;
  modelCharge: any = { depositAmount: "" };
  errorMessage: string = "";
  hasChargePend: number = 0;
  amountCharge: string = "0";
  amountTaxes: string = "";
  nameCharge: string = "";
  code: string = "----";
  barcode: string = "";
  importesBotones = [];
  defaultImport: number;
  allpayments: Array<any>;
  alldepositsImputs: Array<any>;
  isDesktop: boolean;
  isMobile: boolean;
  platform: any;
  globalVars!: GlobalVarsModel;

  modalCtrl = inject(ModalController);
  router = inject(Router);
  device = inject(DeviceService);

  private trackingService = inject(TrackingService);
  /* public app: App, */
  public translate = inject(TranslateService);
  public utils = inject(Utils);
  public userService = inject(UserService);
  public nav = inject(NavController);
  public fb = inject(FormBuilder);
  public events = inject(EventsService);
  public parseMoney = inject(ParseMoney);
  /* private viewCtrl: ViewController, */
  private balancesService = inject(BalancesService);
  public errorService = inject(ErrorService);
  public newBaseService = inject(NewBaseService);

  constructor() {
    this.isMobile = this.device.isMobile();
    this.isDesktop = this.device.isDesktop();

    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
      this.nameCharge = data.user.username;
    });

    switch (this.globalVars.licenseType) {
      case LicenseTypes.Mexico: {
        this.defaultImport = 400;
        break;
      }
      case LicenseTypes.Colombia: {
        this.defaultImport = 60000;
        break;
      }
      default: {
        this.defaultImport = 20;
        break;
      }
    }

    this.formCharge = this.fb.group({
      depositAmount: [this.defaultImport, this.amountValidator],
    });
    if (this.globalVars.FEATURES.CobLocalEnabled) {
      this.checkChargePend();
    }
    if (this.globalVars.FEATURES.MexCobLocalEnabled) {
      this.bindBarcode();
    }
    this.fillAmountButtons();
    this.alldepositsImputs = [
      {
        number: 1,
        text: this.translate.instant("tImp"),
        model: this.modelCharge,
        fcontrol: this.formCharge.controls["depositAmount"],
      },
    ];
    const self = this;
    this.events.subscribe("user:sessionTimeout", function () {
      self.modalCtrl.dismiss();
    });
  }

  linkOutConditions() {
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Mexico:
        window.open("https://www.codere.mx/Documents/AMH-Pol%C3%ADticas%20de%20Apuesta.pdf", "_blank");
        break;
      case LicenseTypes.Colombia:
        window.open("https://m.codere.com.co/csbgonline/condicionesgenerales/ContratoColombia.pdf", "_blank");
        break;
      case LicenseTypes.Panama:
        window.open("https://condiciones.apuestas.codere.es/Panama/condiciones_panama.html", "_blank");
        break;
      case LicenseTypes.ArgentinaCaba:
        window.open(
          "https://m.codere.com.ar/csbgonline/condicionesgenerales/condicionespoliticaargentina.pdf",
          "_blank",
        );
        break;
      case LicenseTypes.ArgentinaPBA:
        window.open(
          "https://m.codere.com.ar/csbgonline/condicionesgenerales/condicionespoliticaargentina.pdf",
          "_blank",
        );
        break;
      default:
        window.open(
          "https://m.apuestas.codere.es/csbgonline/condicionesgenerales/condicionesgeneralesOnlineNacional.pdf",
          "_blank",
        );
    }
  }

  ionViewWillEnter() {
    if (this.isMobile) {
      // todo: no exite changePage
      /* this.globalVars.changePage(ChargeCoderePage); */
      this.events.publish("footer:refresh" /* , this.content */);
    }
    this.trackingService.trackEvent(["AccessToLocalPayments", "", "", "Acceder a cobros en local", "event"]);
    this.cobAmount_click(this.defaultImport);
  }

  fillAmountButtons() {
    this.importesBotones = this.globalVars.FEATURES.amountChargeValues.split(";");
  }

  generalDepAmount_Click(item: any) {
    this.cobAmount_click(item.amount);
  }
  cobAmount_click(amount: number) {
    this.modelCharge.depositAmount = amount;
  }

  checkChargePend() {
    this.userService.checkPayment(this.globalVars.user.username).subscribe(
      (data) => {
        let logError: string = "";
        if (data.sessionOut) {
          this.events.publish("user:serverfail");
        } else {
          if (!data.success) {
            logError = this.translate.instant("m_menu_noLater");
            if (data.errMessage != undefined || data.errMessage != "") {
              if (!isNaN(data.errMessage)) {
                this.errorService.searchErrorCode(data.errMessage).subscribe(
                  (data2) => {
                    this.utils.showError(data2, null);
                  },
                  (err) => {
                    this.utils.showError(logError, null);
                  },
                );
              } else {
                this.utils.showError(data.errMessage, null);
              }
            } else {
              this.utils.showError(logError, null);
            }
            this.hasChargePend = 1;
          } else {
            if (data.success && data.transactionId == null) {
              this.hasChargePend = 1;
            } else {
              this.hasChargePend = 2;
              if (data.amount != data.netAmount) {
                this.amountTaxes = " (Retención de " + this.parseMoney.transform(data.taxAmount, [1]) + ")";
                this.amountCharge = data.netAmount.toString();
              } else {
                this.amountCharge = data.amount.toString();
                this.amountTaxes = "";
              }
              this.code = data.transactionId;
              this.barcode = "<ul id='barcode'>" + this.utils.generateBarcode(this.code) + "</ul>";
            }
          }
          this.events.publish("footer:refresh" /* , this.content */);
        }
      },
      (err) => {
        this.events.publish("user:serverfail");
      },
    );
  }

  amountValidator = (control: FormControl) => {
    var cantidadMaxima, cantidadMinima, errorMin, errorMax;
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Mexico: {
        cantidadMaxima = 99999999;
        cantidadMinima = 10;
        errorMin = this.translate.instant("minimumAmount") + " $10MXN";
        errorMax = this.translate.instant("maximumAmount") + " $99.999.999MXN";
        break;
      }
      case LicenseTypes.Colombia: {
        cantidadMaxima = 99999999;
        cantidadMinima = 30000;
        errorMin = this.translate.instant("minimumAmount") + " $30000COL";
        errorMax = this.translate.instant("maximumAmount") + " $99.999.999COL";
        break;
      }
      case LicenseTypes.Panama: {
        cantidadMaxima = 10000;
        cantidadMinima = 1;
        errorMin = this.translate.instant("minimumAmount") + " 1$";
        errorMax = this.translate.instant("maximumAmount") + " 10000$";
        break;
      }
      default: {
        cantidadMaxima = 10000;
        cantidadMinima = 1;
        errorMin = this.translate.instant("error_dep_cant_min1");
        errorMax = this.translate.instant("error_dep_cant_maxmoney");
      }
    }
    if (control?.value?.length === 0) {
      this.errorMessage = this.translate.instant("error_tar_cant_r");
      return { valid: false };
    }
    if (isNaN(control.value)) {
      this.errorMessage = this.translate.instant("error_tar_cant_n");
      return { valid: false };
    }
    if (control.value < cantidadMinima) {
      this.errorMessage = errorMin;
      return { valid: false };
    }
    if (control.value > cantidadMaxima) {
      this.errorMessage = errorMax;
      return { valid: false };
    }
    if (parseFloat(control.value) > this.globalVars.user.balance) {
      this.errorMessage = this.translate.instant("chargeCodere01");
      return { valid: false };
    }
    this.errorMessage = "";
    return null;
  };

  charge() {
    this.userService
      .createChargeByLocalCodere(this.globalVars.user.username, this.modelCharge.depositAmount.toString())
      .subscribe(
        (data) => {
          var transactionResult = <TransactionActionResult>data; // using <>
          if (transactionResult.success) {
            if (transactionResult.amount != transactionResult.netAmount) {
              this.amountTaxes = " (Retención de " + this.parseMoney.transform(data.taxAmount, [1]) + ")";
              this.amountCharge = transactionResult.netAmount.toString();
            } else {
              this.amountCharge = transactionResult.amount.toString();
              this.amountTaxes = "";
            }
            this.code = transactionResult.transactionId;
            this.barcode =
              "<ul id='barcode'>" + this.utils.generateBarcode(transactionResult.transactionId) + "</ul>";
            this.events.publish("badges:refresh");
            this.balancesService.refreshBalance(true);
            this.hasChargePend = 2;
          } else {
            this.errorService
              .searchErrorCodeWithParams(transactionResult.errCode.toString(), transactionResult.errorParameters)
              .subscribe(
                (data2) => {
                  if (transactionResult.errCode == 2405) {
                    this.utils.confirm(
                      false,
                      this.translate.instant("tFail"),
                      data2,
                      this.translate.instant("tCancel"),
                      () => {},
                      "Términos y Condiciones",
                      () => {
                        setTimeout(() => {
                          this.linkOutConditions();
                        }, 800);
                      },
                    );
                  } else {
                    this.showError(transactionResult.errCode, data2);
                  }
                },
                () => {
                  this.utils.showError(transactionResult.errMessage, null);
                },
              );
          }
          this.events.publish("footer:refresh" /* , this.content */);
        },
        (err) => {
          this.events.publish("user:serverfail");
        },
      );
    this.trackingService.trackEvent([
      "InsertAmountAutLocal",
      "",
      this.modelCharge.depositAmount,
      "Insertar cantidad automatica cobros local",
      "event",
    ]);
  }

  btCharge_Click() {
    if (this.formCharge.valid) {
      if (
        this.globalVars.licenseType == LicenseTypes.Colombia ||
        this.globalVars.licenseType == LicenseTypes.Mexico
      ) {
        this.userService
          .checkCreateChargeByLocalCodere(this.globalVars.user.username, this.modelCharge.depositAmount.toString())
          .subscribe(
            (data) => {
              var transactionResult = <TransactionActionResult>data;
              if (transactionResult.success) {
                if (transactionResult.amount != transactionResult.netAmount) {
                  this.utils.showAlertChargeWithTaxes(
                    this.translate.instant("tCantCobro"),
                    transactionResult.netAmount,
                    this.translate.instant("tRetXImp"),
                    transactionResult.taxAmount,
                    this.translate.instant("tPreguntaCobro"),
                    () => {
                      this.charge();
                    },
                    this.translate.instant("impCobro"),
                    this.translate.instant("tAccept"),
                    this.translate.instant("tCancel"),
                  );
                } else {
                  this.charge();
                }
              } else {
                this.errorService
                  .searchErrorCodeWithParams(
                    transactionResult.errCode.toString(),
                    transactionResult.errorParameters,
                  )
                  .subscribe(
                    (data) => {
                      this.showError(transactionResult.errCode, data);
                    },
                    () => {
                      this.utils.showError(transactionResult.errMessage, null);
                    },
                  );
              }
            },
            (error) => {
              this.events.publish("user:serverfail:soft");
            },
            () => {},
          );
      } else {
        this.charge();
      }
    } else {
      this.formCharge.controls["cobAmount"].markAsTouched();
    }
  }

  btCancelCharge_Click() {
    this.userService.cancelTransaction(this.code, this.amountCharge).subscribe(
      (data) => {
        if (data.success) {
          this.hasChargePend = 1;
          this.code = "";
          this.amountCharge = "";
          this.barcode = "";
          this.balancesService.refreshBalance(true);
          this.utils.alert(
            false,
            this.translate.instant("tInf"),
            this.translate.instant("chargeCodere02"),
            this.translate.instant("tAccept"),
            null,
          );
          this.events.publish("badges:refresh");
        } else {
          this.utils.showError(this.translate.instant("m_menu_noLater"));
        }
        this.events.publish("footer:refresh" /* , this.content */);
      },
      (error) => {
        this.events.publish("user:serverfail");
      },
    );
    this.trackingService.trackEvent([
      "DeleteLocalPayment",
      this.code,
      "",
      "Borrar cobro en local cantidad",
      "event",
    ]);
  }

  linkOutOfModalPage(link: any, paymentArguments: any) {
    // todo: revisar funcionamiento
    /* if (this.isMobile) {
      this.nav.push(link, paymentArguments, {
        animate: !this.globalVars.isDesktop,
      });
    } else {
      this.viewCtrl.dismiss(null, null, { animate: false }).then(() => {
        this.globalVars.gNav.setRoot(link, paymentArguments, {
          animate: !this.globalVars.isDesktop,
        });
      });
    } */
    if (this.isMobile) {
      this.router.navigate([link, paymentArguments], {
        queryParams: { animate: !this.isDesktop },
      });
    } else {
      this.modalCtrl.dismiss(["null", "null", { animate: false }]).then(() => {
        this.router.navigate([link, paymentArguments], {
          queryParams: { animate: !this.isDesktop },
        });
      });
    }
  }

  openCodereLocals() {
    // ToDo: pendiente de revisar enlace. Ojo que sería una dependencia circular tal y como estaba
    // this.linkOutOfModalPage(NearestLocalPage, null);
  }

  bindBarcode() {
    this.userService.createLocalChargeMex().subscribe(
      (data) => {
        var transactionResult = <TransactionActionResult>data;
        if (transactionResult.success) {
          this.code = transactionResult.transactionId;
          this.barcode =
            "<ul id='barcode'>" + this.utils.generateBarcode(transactionResult.transactionId) + "</ul>";
          this.events.publish("footer:refresh" /* , this.content */);
        } else {
          this.errorService
            .searchErrorCodeWithParams(transactionResult.errCode.toString(), transactionResult.errorParameters)
            .subscribe(
              (data2) => {
                this.showError(transactionResult.errCode, data2, () => {
                  this.closeModal();
                  // todo: revisar funcionamiento
                  /* this.globalVars.gNav.setRoot(this.globalVars.mainPageComponent, null, {
                    animate: false,
                  }); */
                  this.router.navigate([this.globalVars.mainPageComponent, null], {
                    queryParams: { animate: false },
                  });
                });
              },
              () => {
                this.utils.showError(transactionResult.errMessage, () => {
                  this.closeModal();
                  // todo: revisar funcionamiento
                  /* this.globalVars.gNav.setRoot(this.globalVars.mainPageComponent, null, {
                    animate: false,
                  }); */
                  this.router.navigate([this.globalVars.mainPageComponent, null], {
                    queryParams: { animate: false },
                  });
                });
              },
            );
        }
      },
      (err) => {
        this.utils.showError(err, () => {
          this.closeModal();
          // todo: revisar funcionamiento
          /* this.globalVars.gNav.setRoot(HomePage, null, { animate: false }); */
          this.router.navigate(["/HomePage", null], {
            queryParams: { animate: false },
          });
        });
      },
    );
  }

  closeModal() {
    if (!!this.modalCtrl) {
      this.modalCtrl.dismiss();
    }
  }

  private showError(transactionErrorCode: number, message: string, closeErrorCallback?: any) {
    if (transactionErrorCode == 174 || transactionErrorCode == 2212) {
      this.utils.confirm(
        false,
        this.translate.instant("tFail"),
        message,
        this.translate.instant("tCancel"),
        !closeErrorCallback ? () => {} : closeErrorCallback,
        this.translate.instant("check"),
      );
    } else {
      this.utils.showError(message, null);
    }
  }
}
