/* eslint-disable */

import { HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { ObservableInput } from "rxjs";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { finalize } from "rxjs/operators";
import { BaseService } from "../providers/BaseService";

@Injectable({ providedIn: "root" })
export class BaseServiceExt extends BaseService {
  public myGet(
    url: string,
    hideLoader?: boolean,
    args?: any,
    errorHandler?: (err: any, caught: Observable<any>) => ObservableInput<{}>,
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders(),
      params: new HttpParams(),
    };
    if (args) {
      const headersArray = args.headers ? Object.getOwnPropertyNames(args.headers) : [];
      headersArray.forEach((header) => {
        options.params.append(header, args.headers[header]);
      });
      const paramsArray = args.params ? Object.getOwnPropertyNames(args.params) : [];
      paramsArray.forEach((par) => {
        options.params.append(par, args.params[par]);
      });
      if (paramsArray.length > 0) {
        url = url + "?";
        for (let index = 0; index < paramsArray.length; index++) {
          const element = paramsArray[index];
          url = url + element + "=" + args.params[element];
          if (index < paramsArray.length - 1) {
            url = url + "&";
          }
        }
      }
    }
    return this.httpClient.get(url, options).pipe(
      map((response) => response),
      catchError(errorHandler ? errorHandler : this.handleError),
      finalize(() => {}),
    );
  }

  protected myGet2(
    url: string,
    options?: any,
    hideLoader?: boolean,
    errorHandler?: (err: any, caught: Observable<any>) => ObservableInput<{}>,
  ): Observable<any> {
    if (options) {
      /*
      !todo
      if (this.globalVars.isNative && !!localStorage.getItem('reese84')) {
        // options.headers['X-D-Token']= JSON.parse(localStorage.getItem('reese84')).token;
      }
      if (this.globalVars.features.csrfControlFront && this.globalVars.user.logged && this.globalVars.extraUserData.csrfControl) {
        // options.headers[CookieNames.CsrfControl]= this.globalVars.extraUserData.csrfControl;
        options.headers = options.headers.append(CookieNames.CsrfControl, this.globalVars.extraUserData.csrfControl);
      }
      */
    } else {
      options = this.setImpCookieClient();
    }

    return this.httpClient.get(url, options).pipe(
      map((response) => response),
      catchError(errorHandler ? errorHandler : this.handleError),
      finalize(() => {}),
    );
  }

  protected myPost2(
    url: string,
    data: any,
    options?: any,
    hideLoader?: boolean,
    errorHandler?: (err: any, caught: Observable<any>) => ObservableInput<{}>,
  ): Observable<any> {
    if (options) {
      /*
      !todo
      if (this.globalVars.isNative && !!localStorage.getItem('reese84')) {
        // options.headers['X-D-Token']= JSON.parse(localStorage.getItem('reese84')).token;
      }
      if (this.globalVars.features.csrfControlFront && this.globalVars.user.logged && this.globalVars.extraUserData.csrfControl) {
        // options.headers[CookieNames.CsrfControl]= this.globalVars.extraUserData.csrfControl;
        options.headers = options.headers.append(CookieNames.CsrfControl, this.globalVars.extraUserData.csrfControl);
      }
      */
    } else {
      options = this.setImpCookieClient();
    }

    return this.httpClient
      .post(url, data, options)
      .pipe(catchError((err) => of(console.error(err))))
      .pipe(
        catchError(errorHandler ? errorHandler : this.handleError),
        finalize(() => {}),
      );
  }

  protected myPatch2(
    url: string,
    data: any,
    options?: any,
    hideLoader?: boolean,
    errorHandler?: (err: any, caught: Observable<any>) => ObservableInput<{}>,
  ): Observable<any> {
    if (options) {
      /*
      !todo
      if (this.globalVars.isNative && !!localStorage.getItem('reese84')) {
        // options.headers['X-D-Token']= JSON.parse(localStorage.getItem('reese84')).token;
      }
      if (this.globalVars.features.csrfControlFront && this.globalVars.user.logged && this.globalVars.extraUserData.csrfControl) {
        // options.headers[CookieNames.CsrfControl]= this.globalVars.extraUserData.csrfControl;
        options.headers = options.headers.append(CookieNames.CsrfControl, this.globalVars.extraUserData.csrfControl);
      }
      */
    } else {
      options = this.setImpCookieClient();
    }

    return this.httpClient.patch(url, data, options).pipe(
      catchError(errorHandler ? errorHandler : this.handleError),
      finalize(() => {}),
    );
  }

  protected myDelete2(
    url: string,
    options?: any,
    hideLoader?: boolean,
    errorHandler?: (err: any, caught: Observable<any>) => ObservableInput<{}>,
  ): Observable<any> {
    if (options) {
      /*
      !todo
      if (this.globalVars.isNative && !!localStorage.getItem('reese84')) {
        // options.headers['X-D-Token']= JSON.parse(localStorage.getItem('reese84')).token;
      }
      if (this.globalVars.features.csrfControlFront && this.globalVars.user.logged && this.globalVars.extraUserData.csrfControl) {
        // options.headers[CookieNames.CsrfControl]= this.globalVars.extraUserData.csrfControl;
        options.headers = options.headers.append(CookieNames.CsrfControl, this.globalVars.extraUserData.csrfControl);
      }
      */
    } else {
      options = this.setImpCookieClient();
    }

    return this.httpClient.delete(url, options).pipe(
      catchError(errorHandler ? errorHandler : this.handleError),
      finalize(() => {}),
    );
  }

  getHeaders(args: any) {
    const headers = new HttpHeaders();
    if (args) {
      for (const el in args) {
        headers.append(el, args[el]);
      }
    }

    if (this.globalVars.isNative && !!localStorage.getItem("reese84")) {
      headers.append("X-D-Token", JSON.parse(localStorage.getItem("reese84")).token);
    }
    return headers;
  }

  extErrorHandler() {
    return;
  }
}
