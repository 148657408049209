import { CommonModule } from "@angular/common";
/* eslint-disable eqeqeq */
import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ErrorMsgs } from "@interfaces/interfaces";
import { IonicModule } from "@ionic/angular";
import { ControlErrorComponent } from "../control-error/control-error.component";

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, ReactiveFormsModule, ControlErrorComponent],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "cdr-radio",
  templateUrl: "./radio.component.html",
  styleUrls: ["./radio.component.scss"],
})
export class RadioComponent implements OnInit {
  @Input() options: Array<any>;
  @Input() fcName: string;
  @Input() fgrp: FormGroup;
  @Input() errorsCol: ErrorMsgs;
  fControl: FormControl;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  ngOnInit() {
    this.fControl = this.fgrp?.get(this.fcName) as FormControl;
    this.fgrp = new FormGroup({
      gender: new FormControl(this.options[0]),
    });
  }

  public radioSelected(event) {
    this.fControl.setValue(event.detail.value);
  }
}
