/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-input-rename */
import { Component, ElementRef, Input, Renderer2, ViewChild, inject } from "@angular/core";

@Component({
  selector: "accordion-list",
  templateUrl: "./accordion-list.html",
  standalone: true,
  styleUrls: ["./accordion-list.scss"],
})
export class AccordionList {
  @ViewChild("expandWrapper", { read: ElementRef }) expandWrapper: ElementRef;
  @Input("expanded") expanded = false;
  @Input("expandHeight") expandHeight = "150px";

  renderer = inject(Renderer2);
  constructor() {}
}
