import { Component, ElementRef, ViewChild, inject } from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { StatusBar } from "@capacitor/status-bar";
import { AlertController, IonContent, IonicModule, ModalController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { TrackingService } from "@providers/TrackingService";
import { UrlsService } from "@providers/UrlsService";
import { UserService } from "@providers/UserService";
import { NewBaseService } from "@providers/newBase.service";
import { GlobalVarsModel } from "../../../models/ModelGlobalsVar";
import { NativeService } from "../../../providers/NativeService";
import { BufferFacade } from "../../../providers/facades/buffer.facade";
import { DeviceService } from "../../../services/device.service";
import { Utils } from "../../../utils/Utils";

import { EventTypes } from "../../../models/TrackingEvents";
//Models
import { CsVeridasIframe } from "../../../models/payments/cs-veridas-iframe";

import { CommonModule } from "@angular/common";
import { Capacitor } from "@capacitor/core";
import { EventsService } from "@providers/EventsService";
//Pipes
import { ExtractDatePipe } from "../../../pipes/extractDate.pipe";

//eslint-disable-next-line
export enum PostMessageActionsVeridas {
  Iframe_Loaded = "Iframe_Loaded",
  ProcessStarted = "ProcessStarted",
  ProcessCompleted = "ProcessCompleted",
  FlowNotAllowed = "FlowNotAllowed",
  VideocallNotAllowed = "VideocallNotAllowed",
  InvalidInputParameter = "InvalidInputParameter",
  BrowserNotSupported = "BrowserNotSupported",
  ProcessError = "ProcessError",
  QRProcessCaptured = "QRProcessCaptured",
  QRClosedModel = "QRClosedModel",
  QRProcessOnboardingError = "QRProcessOnboardingError",
  QRProcessCancelled = "QRProcessCancelled",
  QRProcessCompleted = "QRProcessCompleted",
}

@Component({
  selector: "cashier-veridas-iframe",
  templateUrl: "./cashier-veridas-iframe.html",
  styleUrls: ["./cashier-veridas-iframe.scss"],
  standalone: true,
  imports: [IonicModule, CommonModule],
})
export class CashierVeridasIframe {
  @ViewChild("content") content: IonContent;
  @ViewChild("veridasIframe") veridasIframe: ElementRef;

  previusPage: any;
  showIframe: boolean;
  sourceIframe: SafeResourceUrl;
  configIframe: any;
  setConfig: boolean;
  processOpen: boolean;
  validation_id: string;

  lastListenedEvent = {
    timestamp: 0,
    type: null,
  };

  iframePostedMessagesListener: (event: any) => void;

  public alertController = inject(AlertController);
  public viewCtrl = inject(ModalController); //**ViewController deprecado */
  public extractDate = inject(ExtractDatePipe);
  public utils = inject(Utils);
  public globalVars: GlobalVarsModel;
  public newBaseService = inject(NewBaseService);
  public events = inject(EventsService);
  public userService = inject(UserService);
  public translate = inject(TranslateService);
  public platform = inject(Platform);
  public urlsService = inject(UrlsService);
  public deviceService = inject(DeviceService);
  public bufferFacade = inject(BufferFacade);
  public nativeService = inject(NativeService);
  public trackingService = inject(TrackingService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.showIframe = false;
    this.setConfig = false;
    this.processOpen = true;
    this.iframePostedMessagesListener = this.listenEvents.bind(this);
  }

  ionViewWillEnter() {
    StatusBar.hide();

    window.addEventListener("message", this.iframePostedMessagesListener, false);
  }

  ionViewWillLeave() {
    window.removeEventListener("message", this.iframePostedMessagesListener, false);
  }

  ionViewDidEnter() {
    this.utils.loader();
    this.startVeridas();
  }

  ionViewDidLoad() {
    this.events.publish("footer:refresh", this.content);
  }

  ionViewDidLeave() {
    if (Capacitor.getPlatform() !== "web") {
      try {
        ScreenOrientation.lock({ orientation: "portrait" });
        StatusBar.show();
      } catch (err) {
        console.error(err);
      }
    }

    if (!this.platform.is("cordova") && this.platform.is("android")) {
      setTimeout(() => {
        document.exitFullscreen();
      }, 0);
    }
    this.trackingService.track({
      eventType: EventTypes.VeridasXpressId,
      secondParameter: "Exit_veridas",
      description: "",
    });
  }

  public startVeridas() {
    this.trackingService.track({
      eventType: EventTypes.PaymentsVeridasGetToken,
      secondParameter: "veridas_request_token",
      description: "Requesting a token for the veridas iframe from the backend",
    });

    this.userService.getTokenVeridas().subscribe((data: CsVeridasIframe) => {
      const { success } = data;
      if (success) {
        const { xpressidUrl, accessToken, validationId } = data;
        this.sourceIframe = this.urlsService.getVeridasIframeUrl(xpressidUrl, accessToken);
        this.showIframe = true;
        this.validation_id = validationId;
        setTimeout(() => {
          if (this.showIframe) {
            this.utils.closeLoader();
          }
        }, 10000);
      } else {
        this.trackingService.track({
          eventType: EventTypes.PaymentsVeridasGetTokenFailure,
          secondParameter: "veridas_request_token_failure",
          description: "Failure in requesting token from the backdn for the veridas iframe",
          additionalData: data,
        });
        const defaultErrorMessage = "Error accediendo al servicio de verificación";
        const { ErrMessage } = data;
        this.utils.closeLoader();
        this.viewCtrl.dismiss();
        this.utils.showError(ErrMessage || defaultErrorMessage);
      }
    });
  }

  private parseVeridasEventName(event: any) {
    const second_param_name = event.data.code
      .replace(/([A-Z])/g, "_$1")
      .toLowerCase()
      .replace(/^_/, "");
    return second_param_name;
  }

  /**
   * Event listener method for Veridas Iframe page post messages
   * Should be binded to a enum PostMessageActions object
   * 07/24/2024 - BAUPAY-3783: Refactor to better listen events
   * VERIDAS REFERENCE: https://docs.veridas.com/xpressid/web/v3.4/integration/
   * THIS TO TEST PR COMPLETION
   */
  private parseEventToTrackingLog(event: any): any {
    const returnData = <any>{};
    returnData.additionalData = <any>{};
    // THIS IS THE VALUE USED TO GROUP BY THE SEARCHES. IF CORRECT SQL, SHOULD RETURN ENTIRE USER TRACE ORDERED BY DATE.
    returnData.id = this.validation_id;
    returnData.eventType = EventTypes.VeridasXpressId;
    returnData.description = event?.data?.message;
    returnData.additionalData.validationId = this.validation_id;
    switch (event.data.type) {
      case "info":
      case "warning":
        returnData.secondParameter = "iframe_" + this.parseVeridasEventName(event);
        returnData.additionalData.code = event?.data?.infoEvent?.code || event.data.code; // NO SIEMPRE VIENEN EN EL MISMO CAMPO
        returnData.additionalData.detail = event?.data?.infoEvent?.detail;
        break;

      case "error":
        returnData.secondParameter = "iframe_error_" + this.parseVeridasEventName(event);
        returnData.additionalData.errorCode = event?.data?.code;
        returnData.additionalData.errorMessage = event?.data?.message;
        returnData.additionalData.flow = event?.data?.stage;
        break;
    }
    return returnData;
  }

  listenEvents(event: any) {
    console.log(event);
    const trackingLogData = this.parseEventToTrackingLog(event);
    console.log(trackingLogData);
    this.trackingService.track(trackingLogData);

    const code = event.data.infoEvent?.code || event.data.code;

    switch (code) {
      case PostMessageActionsVeridas.QRProcessCaptured: {
        const msgShow = this.translate.instant("veridas_qr_process_captured");
        this.utils.showMsg(msgShow);
        break;
      }

      case PostMessageActionsVeridas.QRProcessOnboardingError: {
        const msgShow = this.translate.instant("veridas_qr_process_onboarding_error");
        this.utils.showMsg(msgShow);
        break;
      }

      case PostMessageActionsVeridas.QRProcessCancelled: {
        const msgShow = this.translate.instant("veridas_qr_process_cancelled");
        this.utils.showMsg(msgShow);
        break;
      }

      case PostMessageActionsVeridas.QRProcessCompleted: {
        const msgShow = this.translate.instant("veridas_qr_process_completed");
        this.utils.showMsg(msgShow);
        event.VALIDATION_ID = this.validation_id;
        this.closeModal(event);
        this.processOpen = false;
        break;
      }

      case PostMessageActionsVeridas.ProcessStarted: {
        this.utils.closeLoader();
        break;
      }

      // ACORDE A LOS SPECS DE LA 3.7, DEBERÍA ESTAR EN ESTE SWITCH
      case PostMessageActionsVeridas.ProcessCompleted: {
        event.VALIDATION_ID = this.validation_id;
        this.closeModal(event);
        this.processOpen = false;
        break;
      }

      case PostMessageActionsVeridas.ProcessError: {
        this.utils.showError("Ha habido un error de verificación en el proceso. Inténtalo de nuevo", () => {
          this.closeModal();
        });
        this.processOpen = false;
        break;
      }
    }
  }

  fullScreen() {
    document.body.requestFullscreen();
  }

  async createAlert() {
    const confirmAlert = await this.alertController.create({
      header: "¿Está seguro?",
      message: "Si sales se cancelará el proceso de verificación",
      cssClass: "sb-alert",
      backdropDismiss: true,
      buttons: [
        {
          text: this.translate.instant("tCancel"),
          role: "cancel",
        },
        {
          text: this.translate.instant("leave"),
          handler: (_) => {
            this.closeModal();
          },
          role: "acept",
        },
      ],
    });
    confirmAlert.present();
  }

  closeModal(data?: any) {
    if (this.viewCtrl) {
      this.viewCtrl.dismiss(data);
    }
  }
}
