import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/index";
import { TranslateService } from "@ngx-translate/core";
import { NewBaseService } from "../../providers/newBase.service";
import { ErrorAlertSportsCasinoGeolocation, ILicenseFilter } from "./ILicenseFilter";

export class ArgentinaFilter implements ILicenseFilter {
  globalVars: GlobalVarsModel;
  translate: TranslateService;
  newBaseService: NewBaseService;

  constructor(translate: TranslateService, newBaseService: NewBaseService) {
    this.translate = translate;
    this.newBaseService = newBaseService;

    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  getLicense(): number {
    return this.globalVars.licenseType;
  }

  getCountry(): string {
    switch (this.globalVars.licenseType) {
      case LicenseTypes.ArgentinaCaba:
        return "Argentina";
      case LicenseTypes.ArgentinaMendoza:
        return "ArgentinaMendoza";
    }
  }

  //isCaba flag is set after geolocalization on login
  public canPlayCasino(): boolean {
    if (this.globalVars.FEATURES.MSO_EnableGeolocation) {
      return this.globalVars.isCaba;
    } else {
      return true;
    }
  }

  public getCanPlayCasinoError(): string {
    return this.translate.instant(
      this.globalVars.licenseType == LicenseTypes.ArgentinaMendoza
        ? "geolocation_casino_mza"
        : "geolocation_casino_caba",
    );
  }
  public wait(): string {
    return this.translate.instant("geolocation_server_ko");
  }
  public canBetSports(): boolean {
    if (this.globalVars.FEATURES.MSO_EnableGeolocation) {
      return this.globalVars.isCaba;
    } else {
      return true;
    }
  }

  public getCanBetSportsError() {
    return this.translate.instant(
      this.globalVars.licenseType == LicenseTypes.ArgentinaMendoza
        ? "geolocation_sports_mza"
        : "geolocation_sports_caba",
    );
  }
  //alert geolocalización ArgentinaCaba
  public geolocationAlertCabaError(): ErrorAlertSportsCasinoGeolocation {
    const txtliterals: string[] = [
      this.globalVars.licenseType == LicenseTypes.ArgentinaMendoza
        ? "geolocation_sports_mza_txt02"
        : "geolocation_sports_caba_txt02",
      this.globalVars.licenseType == LicenseTypes.ArgentinaMendoza
        ? "geolocation_sports_mza_txt03"
        : "geolocation_sports_caba_txt03",
      "geolocation_sports_caba_txt04",
    ];
    return {
      title: this.translate.instant("geolocation_sports_caba_title"),
      subtitle: "Información importante",
      btn: this.translate.instant("geolocation_sports_caba_btn"),
      txt: txtliterals.map((l) => this.translate.instant(l)),
      linkwhatsapp: this.translate.instant(
        this.globalVars.licenseType == LicenseTypes.ArgentinaMendoza ? "tlfmzawhatsapp" : "tlfcabawhatsapp",
      ),
    };
  }
  public geolocationAlertCabaErrorCasino(): ErrorAlertSportsCasinoGeolocation {
    const txtliterals: string[] = [
      this.globalVars.licenseType == LicenseTypes.ArgentinaMendoza
        ? "geolocation_casino_mza_txt02"
        : "geolocation_casino_caba_txt02",
      this.globalVars.licenseType == LicenseTypes.ArgentinaMendoza
        ? "geolocation_casino_mza_txt03"
        : "geolocation_casino_caba_txt03",
      "geolocation_sports_caba_txt04",
    ];
    return {
      title: this.translate.instant("geolocation_sports_caba_title"),
      subtitle: "Información importante",
      btn: this.translate.instant("geolocation_sports_caba_btn"),
      txt: txtliterals.map((l) => this.translate.instant(l)),
      linkwhatsapp: this.translate.instant(
        this.globalVars.licenseType == LicenseTypes.ArgentinaMendoza ? "tlfmzawhatsapp" : "tlfcabawhatsapp",
      ),
    };
  }

  public isOrdenCongelamientoFondos(): boolean {
    return this.globalVars.isOrdenCongelamientoFondos;
  }
}
