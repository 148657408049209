import { CommonModule } from "@angular/common";
import { Component, Input, OnInit, inject } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { ErrorMsgs, ErrorsControls } from "@interfaces/interfaces";
import { IonicModule } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, ReactiveFormsModule],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "cdr-control-error",
  templateUrl: "./control-error.component.html",
  styleUrls: ["./control-error.component.scss"],
})
export class ControlErrorComponent implements OnInit {
  @Input() fControl: FormControl; // -> fgrp.get(fcName)
  @Input() errorsCol: ErrorMsgs | ErrorsControls;
  listErrors: Array<string>;
  passwErrorsTexts: Array<string>;

  translate = inject(TranslateService);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  ngOnInit(): void {
    this.listErrors = Object.keys(this.errorsCol);
    this.passwErrorsTexts = [
      this.translate.instant("v2_errorContra4"), //incUser
      this.translate.instant("error_pass_no_date"), //incBirthday
      this.translate.instant("error_pass_no_surname2"), // incSurname2
      this.translate.instant("error_pass_no_surname1"), // incSurname
      "La contraseña no puede incluir el nombre", // incName
      this.translate.instant("error_form_max20"), //'Debe tener máximo 20 caracteres', // tooLong
      this.translate.instant("v2_errorContra3"), // tooShort
      this.translate.instant("v2_errorContra1"), // noNum
      this.translate.instant("v2_errorContra"), // noMay
      this.translate.instant("v2_errorContra6"), // noMin
    ];
  }
}
