/* eslint-disable @angular-eslint/no-output-on-prefix */

import { CommonModule } from "@angular/common";
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { IonicModule } from "@ionic/angular";

@Component({
  selector: "cs-document-indications-camera",
  templateUrl: "./cs-document-indications-camera.html",
  styleUrls: ["./cs-document-indications-camera.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule],
  host: {
    class: "cs-document-indications-camera background-color-white color-dark",
  },
})
export class CsDocumentIndicationsCameraComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    this.initVideoCapture().then();
  }

  //ref to pictureImage image tag viewchild
  @ViewChild("cameraFeed") cameraComponent: ElementRef;
  @Output() onPhotoPicked = new EventEmitter<File>();
  @Output() onWebCamPickerDismissed = new EventEmitter<void>();

  public pictureImage: string = null;
  public pictureFile: File;
  private mediaStream: MediaStream;
  public takePicture() {
    if (!this.mediaStream) {
      return;
    }
    const canvas = document.createElement("canvas");
    canvas.width = this.cameraComponent.nativeElement.videoWidth;
    canvas.height = this.cameraComponent.nativeElement.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(this.cameraComponent.nativeElement, 0, 0, canvas.width, canvas.height);
    //canvas to blob (png format by default) image
    canvas.toBlob((blob) => {
      //create File object from blob
      const file = new File([blob], "image.png", { type: "image/png" });
      const reader = new FileReader();
      this.pictureFile = file;

      reader.onload = (event: any) => {
        this.pictureImage = event.target.result;
        this.closeCamera();
      };

      reader.readAsDataURL(file);
    });
  }

  public async retakePic() {
    this.pictureImage = null;
    await this.captureVideo();
  }

  public dismiss() {
    this.onWebCamPickerDismissed.emit();
    this.closeCamera();
  }

  private closeCamera() {
    this.mediaStream.getTracks().forEach((track: any) => track.stop());
  }

  public confirmPic() {
    this.onPhotoPicked.emit(this.pictureFile);
    this.dismiss();
  }

  private captureVideo = async () => {
    try {
      this.mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
      this.cameraComponent.nativeElement.srcObject = this.mediaStream;
    } catch (error) {
      console.error(error);
    }
  };

  private initVideoCapture = async () => {
    await this.captureVideo();
  };
}
