import { Injectable } from "@angular/core";
import { ApplicationInsights, IApplicationInsights } from "@microsoft/applicationinsights-web";
import { environment } from "../../../../environments/environment.config";

@Injectable({
  providedIn: "root",
})
export class InsightsService {
  appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.GLOBALS.APPINSIGHTS.instrumentationKey,
      },
    });

    this.init();
  }

  init(): void {
    const res: IApplicationInsights = this.appInsights.loadAppInsights();
    console.log(InsightsService.name + " Initialized");
    console.log(res);
    this.appInsights.trackPageView();
  }

  trackPageView(
    name?: string,
    url?: string,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number },
  ): void {
    // option to call manually
    console.info("Appinsights: trackPageView");
    this.appInsights.trackPageView({
      name: name,
      uri: url,
      properties,
      measurements,
    });
  }

  trackEvent(name: string, properties?: { [key: string]: any }): void {
    console.info("Appinsights: trackEvent");
    console.info(name, properties);
    this.appInsights.trackEvent({ name: name }, properties);
  }

  trackMetric(name: string, average: number, properties?: { [key: string]: any }): void {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  trackException(exception: Error, severityLevel?: number): void {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  trackTrace(message: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackTrace({ message: message }, properties);
  }

  setAuthenticatedUserContext(value: string) {
    this.appInsights.setAuthenticatedUserContext(value);
  }
}
