import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  inject,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DisplayType, DropdownMode } from "@sports-models/index";

@Component({
  selector: "sb-dropdown",
  templateUrl: "./sb-dropdown.html",
  standalone: true,
  imports: [CommonModule, FormsModule],
  styleUrls: ["./sb-dropdown.scss"],
})
export class SbDropdownComponent implements AfterViewInit, OnChanges, OnInit {
  @ViewChild("content") content: ElementRef;
  @HostBinding("class.is-open") open: boolean = false;
  @Input() index: number;
  @Input() title: string;
  @Input() image: string;
  @Input() collapsable: boolean = true;
  @Input() contentType: string;
  @Input() mode: DropdownMode;
  @Input() hasStreaming: string = null;
  @Output() navigate: EventEmitter<any> = new EventEmitter();
  @Output() toggle: EventEmitter<any> = new EventEmitter();
  @Input() numItemsOpened: number = 0;
  @Input() currentPage: string;
  private _content: HTMLElement;

  private _element = inject(ElementRef);
  private _renderer = inject(Renderer2);
  private cd = inject(ChangeDetectorRef);

  ngOnInit() {
    if (this.collapsable && this.index <= this.numItemsOpened) {
      this.open = !this.open;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    switch (this.mode) {
      case DropdownMode.MARKET_DESKTOP:
        this._addClasses(this._element.nativeElement, ["sb-two-columns-desktop"]);
        break;
      default:
        this._renderer.removeClass(this._element.nativeElement, "sb-two-columns-desktop");
        return;
    }
    this.cd.detectChanges();
  }

  ngAfterViewInit() {
    this._content = this.content.nativeElement;
    const css = ["sb-dropdown", !this.collapsable ? "is-static" : "is-collapsable"];
    css.forEach((c) => this._renderer.addClass(this._element.nativeElement, c));
    if (this.currentPage) {
      this._renderer.addClass(this._element.nativeElement, this.currentPage);
    }
    this.setContentType();
  }

  _toggle() {
    if (!this.collapsable) {
      this.navigate.emit();
      return;
    }
    this.open = !this.open;
    this.toggle.emit(this.open);
  }

  private setContentType() {
    let css: string[] = [];

    //is-wrap -> button 0 1 50% container-> wrap;
    // is-strech -> inline-flex no-wrap;
    switch (this.contentType) {
      case DisplayType.OUTRIGHTLIST:
      case DisplayType.SCORE:
      case DisplayType.TWOWAY:
      case DisplayType.TWOBUTTONLINE:
        css = ["is-wrap", "has-two-buttons", "border-color-regular"];
        break;
      case DisplayType.THREEWAY:
      case DisplayType.THREEBUTTONLIST:
      case DisplayType.THREEBUTTONLINE:
        css = ["is-wrap", "has-three-buttons", "border-color-regular"];
        break;
      default:
        css = ["is-wrap", "has-three-buttons", "border-color-regular"];
        return;
    }
    this._addClasses(this._content, css);
    this.cd.detectChanges();
  }

  private _addClasses(node: HTMLElement, classes: string[]) {
    classes.forEach((c) => this._renderer.addClass(node, c));
    this.cd.detectChanges();
  }
}
