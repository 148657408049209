import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Output, inject } from "@angular/core";
import { Router } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule } from "@ngx-translate/core";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "cdr-step1-se",
  templateUrl: "./step1-se.html",
  styleUrls: ["./step1-se.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule],
})
export class Step1SeComponent {
  @Output() changeStep: EventEmitter<number>;
  text: string;
  textButton0: string;
  textButton1: string;
  isDesktop: boolean = false;

  globalVars!: GlobalVarsModel;

  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  router = inject(Router);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isDesktop = this.deviceService.isDesktop();

    this.text = "AutoExclusión - Step1";
    this.textButton0 = "Anterior";
    this.textButton1 = "Siguiente";
    this.changeStep = new EventEmitter();
  }

  goToStep(n) {
    this.changeStep.next(n);
  }
  backToHome() {
    this.router.navigate(["/"], { queryParams: { animate: false } });
  }
}
