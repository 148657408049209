import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { Router } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { DecouplingUserServiceMSO } from "@providers/DecouplingUserServiceMSO.service";
import { UserServiceMSO } from "@providers/UserServiceMSO";
import { IabService } from "@providers/iabService";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { Utils } from "@utils/Utils";
import { UserFormComponent } from "../user-form/user-form";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "cdr-step-revocation-se",
  templateUrl: "./step-revocation-se.html",
  styleUrls: ["./step-revocation-se.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, UserFormComponent],
})
export class StepRevocationSeComponent {
  globalVars!: GlobalVarsModel;
  isDesktop: boolean = false;

  utils = inject(Utils);
  userserviceMSO = inject(UserServiceMSO);
  translate = inject(TranslateService);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  router = inject(Router);
  iabService = inject(IabService);
  decouplingService = inject(DecouplingUserServiceMSO);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isDesktop = this.deviceService.isDesktop();
  }

  doRevocation() {
    const request = this.globalVars.FEATURES.MSO_NewDecouplingSelfExclusion
      ? this.decouplingService.selfExclusionDecoupling({
          exclusionDateEnd: this.getDateRevocation(),
          renewAutoExclusion: false,
          revokeAutoExclusion: true,
        })
      : this.userserviceMSO.createAutoExclusion(this.getDateRevocation(), false, true);
    request.subscribe({
      next: (data) => {
        if (this.globalVars.FEATURES.MSO_NewDecouplingSelfExclusion || data) {
          this.globalVars.extraUserData.isRevocationPage = false;
          this.globalVars.rootScope.navService.initRightMenu(true);
          this.showAlertRevocation();
        } else {
          this.utils.alert(true, "", this.translate.instant("revocationko"), "OK");
        }
      },
      error: () => {
        this.utils.alert(true, "", this.translate.instant("revocationko"), "OK");
      },
    });
  }
  sendEmailRevocation() {
    const link = "mailto:apuestas@codere.com?subject=Revocación Autoexclusión - Solicitud de edición de datos";
    if (this.globalVars.isNative) {
      this.iabService.create(link);
    } else {
      window.open(link);
    }
  }

  getDateRevocation() {
    const datenow = new Date();
    datenow.setHours(datenow.getHours() + 72);
    let day = "";
    let revocationDate = "";
    let month = "";

    if (datenow.getDate() < 10) {
      day = "0" + datenow.getDate();
    } else {
      day = datenow.getDate().toString();
    }
    if (datenow.getMonth() + 1 < 10) {
      month = "0" + (datenow.getMonth() + 1);
    } else {
      month = (datenow.getMonth() + 1).toString();
    }
    revocationDate = datenow.getFullYear() + "-" + month + "-" + day + " " + "23:59";
    return revocationDate;
  }

  async showAlertRevocation() {
    const alert = await this.utils.alert(
      false,
      "",
      this.translate.instant("revocation_se_OK"),
      this.translate.instant("confirm").toUpperCase(),
      () => {
        this.router.navigate(["/"], { queryParams: { animate: false } });
      },
    );

    alert.querySelector(".alert-wrapper").classList.add("alertrevocationse");
  }
}
