import { CommonModule } from "@angular/common";
/* eslint-disable eqeqeq */
import { Component, Input, type OnDestroy, type OnInit, inject } from "@angular/core";
import { type FormGroup, ReactiveFormsModule } from "@angular/forms";
import { ErrorsControls, LabelPersonalControls } from "@interfaces/interfaces";
import { IdentificationDocumentTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateService } from "@ngx-translate/core";
import { UserServiceMSO } from "@providers/UserServiceMSO";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { BehaviorSubject, Subscription } from "rxjs";
import { type MaestroDatos, MaestrosService } from "../../../services/registro/maestros.service";
import { Countries, Nacionalities } from "../../../services/registro/paises.service";
import { InputComponent } from "../input/input.component";
import { RadioComponent } from "../radio/radio.component";
import { SelectInfoComponent } from "../select-info/select-info.component";
import { SelectComponent } from "../select/select.component";
import { TimeComponent } from "../time/time";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RadioComponent,
    InputComponent,
    TimeComponent,
    SelectComponent,
    SelectInfoComponent,
  ],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "cdr-fs-personal",
  templateUrl: "./fs-personal.component.html",
  styleUrls: ["./fs-personal.component.scss"],
})
export class FsPersonalComponent implements OnInit, OnDestroy {
  // Objetos del UI
  errors: ErrorsControls;
  labels: LabelPersonalControls;
  titleSection: string;
  titleBirthDate: string;

  // Arrays de datos
  genders: Array<MaestroDatos>;
  nationalities: Array<Nacionalities> = [];
  jobs: Array<{ id: number; name: string }> = [];
  countries: Array<Countries> = [];
  residentTypes: Array<MaestroDatos>;
  documentTypes: Array<MaestroDatos>;

  // FormControl
  @Input() formGrp: FormGroup;
  @Input() canShowSuportNumber: BehaviorSubject<boolean>;
  $canShowSuportNumber: Subscription;
  formGrpName: string;
  globalVars!: GlobalVarsModel;

  showSupportNumber = false;

  modalDocumenNumber = "modalDocNumnber";
  modalDocumenNumberDNI = "modalDNI";

  maestros = inject(MaestrosService);
  userServiceMSO = inject(UserServiceMSO);
  translate = inject(TranslateService);
  newBaseService = inject(NewBaseService);
  device = inject(DeviceService);

  isDesktop = this.device.isDesktop();
  isMobile = this.device.isMobile();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  ngOnDestroy(): void {
    if (this.$canShowSuportNumber) {
      this.$canShowSuportNumber.unsubscribe();
    }
  }

  async ngOnInit() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.formGrpName = "personal";
    this.genders = this.maestros.getGender();
    this.labels = this.setLabels();
    this.errors = this.setErrors();
    this.residentTypes = this.maestros.getResidentTypes();
    this.documentTypes = this.maestros.getDocumentTypes();
    this.$canShowSuportNumber = this.canShowSuportNumber.subscribe((data) => {
      this.showSupportNumber = data;
    });

    await this.loadCountries();
    await this.loadNacionalities();

    this.formGrp.get("nationality").setValue(this.nationalities.find((item) => item.id === 71)); // español
    this.formGrp.get("nationality").updateValueAndValidity();
    this.formGrp.get("resident").setValue(this.residentTypes[0]);
    this.formGrp.get("resident").updateValueAndValidity();

    this.formGrp.get("resident").valueChanges.subscribe((value) => {
      if (value?.id === 0) {
        this.formGrp.get("country").setValue("");
      } else {
        this.formGrp.get("country").setValue(this.countries[0]);
      }
    });

    this.formGrp.get("nationality").valueChanges.subscribe((value) => {
      if (value.id === 71) {
        this.labels.dni = "DNI";
        this.modalDocumenNumberDNI = "modalDNI";
      } else {
        this.labels.dni = "NIE";
        this.modalDocumenNumberDNI = "modalNIE";
      }
      if (!(value.id === 71) && this.formGrp.get("resident").value.id !== 0) {
        this.formGrp.get("documentType").setValue(this.documentTypes[0]);
      } else {
        this.formGrp.get("documentType").setValue("");
      }
    });
    this.formGrp.get("documentType").valueChanges.subscribe((value) => {
      if (value.id === IdentificationDocumentTypes.NIE) {
        this.modalDocumenNumber = "modalNIE";
      } else {
        this.modalDocumenNumber = "modalDocNumnber";
      }
    });

    this.titleSection = this.translate.instant("regPersonalData"); //Datos personales"
    this.titleBirthDate = this.translate.instant("rBDate"); //"Fecha de nacimiento"
    this.loadJobs();
  } // Fin de ngOnInit

  private setLabels(): LabelPersonalControls {
    return {
      gender: { man: "Hombre", woman: "Mujer" },
      name: this.translate.instant("rNom"), //'Nombre'
      name2: this.translate.instant("rPreN1"), //'Primer Apellido'
      name3: this.translate.instant("rPreN2"), //'Segundo Apellido'
      birthDate: {
        day: this.translate.instant("rDay"),
        month: this.translate.instant("rMonth"),
        year: this.translate.instant("rYear"),
      },
      dni: "DNI",
      resident: "Residencia",
      nationality: this.translate.instant("rNaci"), //'Nacionalidad'
      country: this.translate.instant("rPais"), //'País'
      documentType: this.translate.instant("rCoDocumentType"), //'Tipo de documento'
      documentNumber: this.translate.instant("NotResidDocumentTypeNum"), //'Número de documento'
      profesion: this.translate.instant("v2_profesion"),
      supportNumber: this.translate.instant("supportNumber"),
    };
  }

  private setErrors(): ErrorsControls {
    return {
      gender: {
        required: "Debe seleccionar un género",
      },
      name: {
        required: this.translate.instant("v2_nameObl"), //'El nombre es obligatorio'
        minlength: this.translate.instant("error_form_min2"), //	Tu nombre debe tener mínimo 2 caracteres.
        pattern: "Tu Nombre debe contener sólo letras (A-Z) o guiones (-)",
        checkIdentity: "El nombre no coincide con tu Documento",
      },
      name2: {
        required: this.translate.instant("v2_apeObl"), //'El primer a`pellido es obligatorio'
        minlength: this.translate.instant("error_form_ape_min2"), //'Tu apellido debe tener mínimo 2 caracteres'
        pattern: "Tu Apellido debe contener sólo letras (A-Z) o guiones (-)",
        checkIdentity: "El apellido no coincide con tu Documento",
      },
      name3: {
        required: "",
        minlength: this.translate.instant("error_form_ape_min2"), //'Tu apellido debe tener mínimo 2 caracteres'
        pattern: "Tu Apellido debe contener sólo letras (A-Z) o guiones (-)",
        checkIdentity: "El apellido no coincide con tu Documento",
      },
      birthDate: {
        required: "La edad es obligatoria",
        notValidDate: this.translate.instant("date_valid"), //'Introduce una fecha válida'
        validFormat: "Eres menor de edad",
        checkIdentity: "La fecha no coincide con tu Documento",
      },
      dni: {
        required: "El DNI es obligatirio",
        validFormat: this.translate.instant("error_dniRule"), //Debe introducir un DNI/NIE válido
        validUnique: this.translate.instant("error_checkDNI"), //Ya existe un usuario con ese DNI
        checkIdentity: "El número no coincide con tu Documento",
      },
      resident: {
        required: "", // Sin Errores - select con valor inicial
      },
      nationality: {
        required: "La nacionalidad es obligatoria",
      },
      country: {
        required: this.translate.instant("error_form_requiered"), //'El país es obligatorio'
      },
      documentType: {
        required: this.translate.instant("ErrorNotResidDocumentTypeRequired"), //'El tipo de documento es obligatorio'
      },
      documentNumber: {
        required: this.translate.instant("ErrorNotResidDocumentNumberRequired"), //'Debes introducir un número de documento'
        validFormat: this.translate.instant("v2_errorNIE"), //Debe introducir un DNI/NIE válido
        minlength: "El número de documento debe tener mínimo 6 caracteres",
        validUnique: this.translate.instant("error_checkDocument"), //Ya existe un usuario con ese número de documento
      },
      profesion: {
        required: this.translate.instant("obligatoryField"),
      },
      supportNumber: {
        required: this.translate.instant("obligatoryField"),
        validFormat: this.translate.instant("supportNumberFormat"),
        backError: this.translate.instant("supportNumberKO"),
      },
    };
  }

  get canShowDniInput() {
    if (this.formGrp.get("resident").value && this.formGrp.get("nationality").value) {
      return this.formGrp.get("resident").value.id == 0 || this.formGrp.get("nationality").value.id == 71;
    } else {
      return false;
    }
  }
  async loadNacionalities() {
    const dataresponse = await this.userServiceMSO.getTable("CDR_OL_NACIONALIDAD").toPromise();

    const aux = [];
    for (const key in dataresponse) {
      aux.push(dataresponse[key]);
    }
    aux.sort();
    this.nationalities = [];
    aux.forEach((element, index) => {
      const cantidad = 72 + index;
      const o = {
        id: cantidad,
        name: element,
      };
      if (element === "Espa\u00f1ol") {
        o.id = 71;
      }
      this.nationalities.push(o);
    });
  }
  async loadCountries() {
    const dataresponse = await this.userServiceMSO.getTable("CDR_OL_PAIS").toPromise();
    const aux = [];
    for (const key in dataresponse) {
      aux.push(dataresponse[key]);
    }
    aux.sort();
    this.countries = [];
    aux.forEach((element, index) => {
      if (element != "España") {
        this.countries.push({
          id: index + 1,
          name: element,
        });
      }
    });
  }

  loadJobs() {
    if (this.globalVars?.FEATURES?.ListOfProfesions) {
      this.globalVars?.FEATURES?.ListOfProfesions?.split(";", 200).forEach((value, index) => {
        this.jobs.push({ id: index, name: value });
      });
    }
  }
}
