import {
  SmartMarketItem,
  StraightMulticastItem,
  accumulatorGroup,
  singleResultItem,
  singlesGroup,
  systemGroup,
} from "../index";

export interface ticketBetSlip {
  items: {
    singleResultItems: singleResultItem[];
    smartMarketItems: SmartMarketItem[];
    combinationMulticastItems: any[];
    straightMulticastItems: StraightMulticastItem[];
    validations: any[];
    relatedItems: any[];
  };
  stakeGroups: {
    accumulator?: accumulatorGroup;
    singles?: singlesGroup;
    doubles?: systemGroup;
    triples?: systemGroup;
    cuadruples?: systemGroup;
    system?: systemGroup;
    _minimumStakes: {
      staking: {
        singles: any[];
        systems: [
          {
            systemId: number;
            overallStake: number;
            isEachWay: boolean;
          },
        ];
        overallStake: number;
      };
      calculation: {
        singles: any[];
        systems: [
          {
            systemId: number;
            maxReturn: number;
            totalOdds: number;
            maxReturnNet: number;
            maxSettlementTax: number;
            maxSettlementTaxIncludingBonus: number;
            maxReturnIncludingBonus: number;
            bonus: any[];
          },
        ];
        betslip: {
          maxReturn: number;
          maxReturnNet: number;
          overallStakeNet: number;
          placementTax: number;
          placementTaxRate: number;
          maxSettlementTax: number;
          maxSettlementTaxIncludingBonus: number;
          nonRedeemableStakes: number;
          maxBonusReturn: number;
          maxReturnIncludingBonus: number;
        };
      };
      validation: {
        stake: {
          betslipStakes: any[];
          systemStakes: any[];
          singleStakes: any[];
        };
        returns: {
          betslipStakes: any[];
          systemStakes: any[];
          singleStakes: any[];
        };
        bonus: {
          systems: any[];
          singles: any[];
        };
      };
    };
  };
  possibleSystems: PossibleSystemsItem[];
  stakeLimits: {
    systemStakeLimits: [
      {
        eachWayIncrement: number;
        increment: number;
        maximumEachWayStake: number;
        maximumEachWayStakePerBetWay: number;
        maximumStake: number;
        maximumStakePerBetWay: number;
        minimumEachWayStake: number;
        minimumEachWayStakePerBetWay: number;
        minimumStake: number;
        minimumStakePerBetWay: number;
        systemId: number;
      },
    ];
    singleStakeLimits: [
      {
        itemId: number;
        minimumStake: number;
        maximumStake: number;
        minimumEachWayStake: number;
        maximumEachWayStake: number;
        increment: number;
        eachWayIncrement: number;
        maximumEachWayStakePerBetWay: number;
        maximumStakePerBetWay: number;
        minimumEachWayStakePerBetWay: number;
        minimumStakePerBetWay: number;
      },
    ];
  };
  bonus: BSfreeBetVouchers;
}

export interface PossibleSystemsItem {
  systemId: number;
  numberOfBetWays: number;
  numberOfEachWayBetWays: number;
  canBeEachWay: false;
  systemType: string;
  text: string;
}
export interface BSfreeBetVouchers {
  bonusOfferInfo: {
    bonusOfferIds: any[];
    nodeId: number;
    channelNodeId: number;
  };
  freeBetVouchers: any[];
  validation: {
    messages: any[];
    systems: {
      systemId: number;
      offers: any[];
      vouchers: {
        validationCode: string;
        voucherCode: string;
      }[];
    }[];
    singles: {
      systemId: number;
      offers: any[];
      vouchers: {
        validationCode: string;
        voucherCode: string;
      }[];
    }[];
  };
}
