// Interfaces and abstracts
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, inject } from "@angular/core";
import { type IonImg, IonicModule } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
// Services and providers
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { SlotName } from "@pipes/casino/lobby/slot-name.pipe";
import { ParseMoney } from "@pipes/parseMoney";
import { NewBaseService } from "@providers/newBase.service";
import { LobbyFacadeService } from "@services/casino/loobyFacade.service";
import { DeviceService } from "@services/device.service";
import { AbstractLoobyFacadeService } from "src/app/modules/shared/abstracts/abstract-looby-facade-service";
// Components
import { CasinoCardNewComponent } from "../../casino-card-new/casino-card-new.component";

export enum gameTileSizeType {
  rectangle = "Rectangle",
  square = "Square",
}

@Component({
  selector: "lb-calendar-section",
  templateUrl: "./calendar-section.html",
  styleUrls: ["./calendar-section.scss"],
  imports: [CommonModule, CasinoCardNewComponent, IonicModule, SlotName, ParseMoney, TranslateModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: AbstractLoobyFacadeService,
      useClass: LobbyFacadeService,
    },
  ],
})
export class CalendarSectionComponent implements OnInit {
  @Input() section: any;
  @Output() turnPlay: EventEmitter<any> = new EventEmitter();
  isDesktop = false;
  globalVars!: GlobalVarsModel;
  baseImage: string;
  //#region Inject Services
  lobbyFacadeService = inject(AbstractLoobyFacadeService);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  translate = inject(TranslateService);
  //#endregion

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    if (this.globalVars) {
      this.baseImage = this.globalVars.LOBBY_IMAGES;
    }
  }

  ngOnInit(): void {
    this.orderGames();
  }

  getImage(game) {
    const shape = game.gameTileSize.toLowerCase().includes("square") ? "Square" : "Rectangle";
    const imageType = game.imageType || "jpg";
    const image = game.Name.replace(/ /g, "") + "_" + shape + `.${imageType}`;
    game.Image = this.baseImage + image;
    game.defaultImg = this.baseImage + "default_" + shape + `.${imageType}`;
    return game.Image;
  }
  errorImageHandler(ionImgElement: IonImg, game) {
    ionImgElement.src = game.defaultImg;
  }

  playGame(game) {
    if (game.active) {
      this.turnPlay.emit(game);
      this.lobbyFacadeService.playGameOutCasino(game);
    }
  }

  private orderGames(): void {
    this.section.games = this.section.games.sort((a, b) => {
      if (a.gameTileSize === gameTileSizeType.rectangle && b.gameTileSize !== gameTileSizeType.rectangle) {
        return -1;
      }
      if (a.gameTileSize !== gameTileSizeType.rectangle && b.gameTileSize === gameTileSizeType.rectangle) {
        return 1;
      }
      return 0;
    });
  }
}
