import { CommonModule } from "@angular/common";
import { Component, EventEmitter, OnInit, Output, inject } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { UserService } from "@providers/UserService";
import { UserServiceMSO } from "@providers/UserServiceMSO";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { Utils } from "@utils/Utils";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "user-form",
  templateUrl: "./user-form.html",
  styleUrls: ["./user-form.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, ReactiveFormsModule],
})
export class UserFormComponent implements OnInit {
  globalVars!: GlobalVarsModel;
  isDesktop: boolean = false;
  myForm: FormGroup;
  @Output() dataEmitter: EventEmitter<{ city: string }>;

  utils = inject(Utils);
  userserviceMSO = inject(UserServiceMSO);
  translate = inject(TranslateService);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  formbuilder = inject(FormBuilder);
  userService = inject(UserService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.myForm = this.formbuilder.group({
      name: [{ value: "", disabled: true }, Validators.required],
      surname: [{ value: "", disabled: true }, Validators.required],
      documentTypeCO: [{ value: null, disabled: true }, Validators.required],
      NumberDocument: [{ value: "", disabled: true }, Validators.required],
      address: [{ value: "", disabled: true }, Validators.required],
      Municipality: [{ value: null, disabled: true }],
      phone: [{ value: "", disabled: true }, Validators.required],
      email: [{ value: "", disabled: true }, Validators.required],
      Province: [{ value: null, disabled: true }],
    });
    if (this.globalVars.licenseType === LicenseTypes.Colombia) {
      this.myForm.get("Municipality").setValidators([Validators.required]);
    }
    if (this.globalVars.licenseType === LicenseTypes.Panama) {
      this.myForm.get("Province").setValidators([Validators.required]);
    }
    this.isDesktop = this.deviceService.isDesktop();
    this.dataEmitter = new EventEmitter();
  }
  ngOnInit(): void {
    this.userService.getUserData().subscribe((data) => {
      this.myForm.get("name").setValue(data.Nombre);
      this.myForm.get("surname").setValue(data.PrimerApellido + " " + data.SegundoApellido || "");
      this.myForm
        .get("documentTypeCO")
        .setValue(
          data.TipoDocumento == "ResidenteDNI"
            ? this.translate.instant("rCoDocumentTypeCC")
            : this.translate.instant("rCoDocumentTypeCE"),
        );
      this.myForm.get("NumberDocument").setValue(data.NIF);
      this.myForm.get("address").setValue(data.Direccion);
      this.myForm.get("phone").setValue(data.NumMovil);
      this.myForm.get("email").setValue(data.Direccion_Correo);
      if (this.globalVars.licenseType === LicenseTypes.Colombia) {
        this.dataEmitter.emit({ city: data.Municipio });
        this.myForm.get("Municipality").setValue(data.Municipio);
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        this.dataEmitter.emit({ city: data.Provincia });
        this.myForm.get("Province").setValue(data.Provincia);
      }
    });
  }
}
