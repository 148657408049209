import { Injectable } from "@angular/core";
import { BaseService } from "@providers/BaseService";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
/**
 * This service is intended to retrieve different confguration values, parameters, flags, etc.
 * from PartnerConfigurations in CodereId (MS Dynamics)
 */
export class PartnerConfigurationsService extends BaseService {
  private transactionApiUrl = this.globalVars.UrlTransactionsApiBase;

  /**
   *
   * @returns Number Observable for a WithdrawalLimitsDossier
   */
  GetWithdrawalLimitsDossier(): Observable<number> {
    return this.myGet(`${this.transactionApiUrl}partnerconfigurations/limiteRetiroExpediente`);
  }
}
