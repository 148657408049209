import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root", // Esto asegura que el servicio sea singleton en toda la aplicación
})
export class StaticStoreService {
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public callPolling: boolean = true; // Variable estática
  public params: any = null; // store
}
