import { C_Country } from "./country.model";
export interface SidebarState {
  collapsed: boolean;
}

export class SidebarSportItem {
  countries: C_Country[];
  highlight: any[];

  constructor(countries, highlight) {
    this.countries = countries;
    this.highlight = highlight;
  }
}
