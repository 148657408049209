import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { AlertController, IonicModule } from "@ionic/angular";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

@Component({
  selector: "cs-halcash-form",
  templateUrl: "./cs-halcash-form.html",
  styleUrls: ["./cs-halcash-form.scss"],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, ReactiveFormsModule],
})
export class CsHalcashFormComponent {
  @Input() phoneNumber: any;
  @Output() formHalcashValue: EventEmitter<any> = new EventEmitter();
  @Output() inputInfo: EventEmitter<any> = new EventEmitter();

  formHalcash: FormGroup;

  fb = inject(FormBuilder);
  translate = inject(TranslateService);
  alertCtrl = inject(AlertController);

  constructor() {
    this.formHalcash = this.fb.group({
      pincode: ["", this.ValidateKeyHalcash],
      tlf: ["", this.ValidateTlfHalcash],
    });
  }

  ngOnChanges() {
    if (this.phoneNumber) {
      this.formHalcash.get("tlf").patchValue(this.phoneNumber);
      this.formHalcash.get("tlf").disable();
    }
  }

  showInputInfo(option) {
    this.inputInfo.emit(option);
  }

  emitWithdrawal() {
    this.formHalcash.get("tlf").enable();
    this.formHalcashValue.emit(this.formHalcash.value);
  }

  ValidateKeyHalcash = (control: FormControl) => {
    const pincodeRegEsp: RegExp = /^[0-9]{4}$/;
    return control.value == "" || !pincodeRegEsp.test(control.value) || isNaN(control.value)
      ? { valid: false }
      : null;
  };

  ValidateTlfHalcash = (control: FormControl) => {
    const movilRegEsp: RegExp = /^(6|7)[0-9]{8}$/;
    if (control.value.length == 0) {
      return null;
    }
    if (!movilRegEsp.test(control.value)) {
      return { valid: false };
    }
    return null;
  };
}
