/* eslint-disable */
import { Pipe, PipeTransform, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({ name: "filterTournament" })
export class FilterTournamentPipe implements PipeTransform {
  public translate = inject(TranslateService);

  transform(value: any[], args: any[]): any {
    if (value.length != 0) {
      let filter;
      switch (args[0]) {
        case "canJoin":
          return value.filter((t) => t.userCanJoin).sort((a, b) => a.tournamentFinished - b.tournamentFinished);
        case "noToday":
          filter = this.noToday(value, args);
          break;
        case "now":
          filter = this.now(value);
          break;
        case "finished":
          filter = this.filter(value, args[0]).reverse();
          break;
        default:
          filter = this.filter(value);
      }
      return filter.filter((t, index) => index <= args[1] - 1);
    } else {
      return value;
    }
  }

  private now(value: any[]) {
    const filtered = value.filter((tournament) => tournament.userCanJoin && !tournament.tournamentFinished);
    return filtered;
  }

  private filter(value: any[], args?: any[]): any[] | any {
    const filtered = value.filter((tournament) => {
      const now = new Date();
      const endDate = new Date(parseInt(tournament.endDate.slice(6, tournament.endDate.length - 2)));
      if (!args) {
        const startDate = new Date(parseInt(tournament.startDate.slice(6, tournament.startDate.length - 2)));
        return startDate > now && !tournament.tournamentFinished;
      }
      return endDate < now;
    });
    return filtered;
  }

  private noToday(value: any, args: any) {
    const today = new Date();
    const filtered = value.filter((tournament) => {
      const tournamentStart = new Date(
        parseInt(tournament.leaderboardStartDate.slice(6, tournament.leaderboardStartDate.length - 2)),
      );
      if (args[0] === "noToday") {
        return tournamentStart.toLocaleDateString() > today.toLocaleDateString();
      } else {
        return tournamentStart.toLocaleDateString() <= today.toLocaleDateString();
      }
    });
    return filtered;
  }
}
