import { FreeBetCondition, PBSVoucherInfo } from "@sports-models/index";

const SINGLES = "Singles";
const DOUBLES = "Doubles";
const TRIPLES = "Triples";
const CUADRUPLES = "Cuadruples";
const SYSTEM = "System";
const MULTI_WAY_SYSTEM = "MultiWaySystem";
const ACCUMULATOR = "Accumulator";

// INTERFACES
export interface BetslipState {
  Betbuilder: BetslipBetbuilder;
  Errors: BetslipErrors;
  Filters: BetslipFilters;
  Freebets: BetslipFreebets;
  LineItems: BetslipLineItem[];
  Systems: BetslipSystems;
  Overask: BetslipOverask;
  Processing: BetslipProcessing;
  ProviderResponse: any;
  Totals: BetslipTotals;
}

export interface BetslipProcessing {
  From: string;
  Item: any;
}

export interface BetslipSystems {
  PossibleSystems: BetslipSystem[];
  Selected: BetslipSystem;
}

export interface BetslipSystem {
  canBeEachWay: boolean;
  numberOfBetWays: number;
  numberOfEachWayBetWays: number;
  systemId: number;
  SystemType: string;
}

export interface BetslipBetbuilder {
  Selection: any[];
}

export interface BetslipFilters {
  Items: BetslipFilter[];
  Selected: BetslipFilter;
}

export interface BetslipErrors {
  Message: string;
  Code: number;
  HasError: boolean;
}

export interface BetslipTotals {
  Stake: number;
  PotentialWin: number;
  PotentialWinWithBoost: number;
  Odds: number;
  TicketNumber: number;
  SingleStakeLimits?: number;
  SystemStakeLimits?: number;
  IsConfirmed?: boolean;
}

export interface BetslipFreebets {
  Conditions?: FreeBetCondition[];
  BonusOfferId?: number;
  VoucherCode?: string;
  Amount?: number;
  ExpirationDate?: string;
  IsRedeemable?: boolean;
  Name?: string;
  IsTerminalVoucher?: boolean;
  PBSVoucherInfo?: PBSVoucherInfo;
  HasFreeBet?: boolean;
}

export interface BetslipOverask {
  Overasked?: boolean;
  Info?: any;
  CloseBet?: boolean;
  ExpiryDate?: string;
  Status?: number | string;
  StatusString?: string;
  RejectId?: number;
  MaxStake?: number;
  Approved?: boolean;
  Rejected?: boolean;
  Finished?: boolean;
}

export interface BetslipFilter {
  name: string;
  value: number;
  betType: string;
  selected: boolean;
  hidden: boolean;
  disabled: boolean;
}

export interface BetslipLineItem {
  Amount: any;
  AmountLocal: any;
  CanBeEachWay: boolean;
  EventDate: string;
  EventHasHandicap: any;
  EventId: string;
  EventName: string;
  GameId: string;
  GameLocked: boolean;
  GameName: string;
  GameNotAvailable: boolean;
  GameSpecialOddsValue: string;
  GameTypeId: number;
  GameBetTypeAvailability: number;
  GameIncludedPlaces: number;
  GameIsLive: boolean;
  GamePlaceOddsDivisor: string;
  GameIsBetbuilder: boolean;
  GameIsRace: boolean;
  ItemId: number;
  LeagueId: string;
  LocationId: string;
  NodeId: string;
  Odd: number;
  OddDown: boolean;
  OddUp: boolean;
  RaceData: BetslipRaceData;
  BetbuilderData: BetslipBetbuilderData;
  BetBuilderId: string;
  ResultName: string;
  ResultsNr: string;
  SportHandle: string;
  SportId: string;
  TicketNumber: number;
  Selected: boolean;
  TotalPotentialWin: number;
}

export interface BetslipRaceParticipant {
  OpenPrice: boolean;
  Id: string;
  Place: number; //es un enum
  Name: string;
}

export interface BetslipRaceData {
  GreyhoundHorseBetType: number; //es un enum bettype
  Handicap: boolean;
  Paddock: string;
  Participants: BetslipRaceParticipant[];
  Place: number;
  IsRace: boolean;
}

export interface BetslipBetbuilderData {
  BetbuilderId: string;
  Items: {
    Name: string;
    GameName: string;
  }[];
  IsBetbuilder: boolean;
}

// INITIAL STATES
export const BetslipFilterInitial: BetslipFilters = {
  Items: [
    {
      name: "tSen",
      value: 0,
      betType: SINGLES,
      selected: true,
      hidden: false,
      disabled: false,
    },
    {
      name: "tAcu",
      value: 1,
      betType: ACCUMULATOR,
      selected: false,
      hidden: false,
      disabled: true,
    },
    {
      name: "tMul",
      value: 2,
      betType: SYSTEM,
      selected: false,
      hidden: false,
      disabled: true,
    },
  ],
  Selected: {
    name: "tSen",
    value: 0,
    betType: SINGLES,
    selected: true,
    hidden: false,
    disabled: false,
  },
};

export const BetslipProcessingInitial: BetslipProcessing = {
  From: "",
  Item: {},
};

export const BetslipBetbuilderInitial: BetslipBetbuilder = {
  Selection: [],
};

export const BetslipFreebetsInitial: BetslipFreebets = {
  Conditions: [],
  BonusOfferId: 0,
  VoucherCode: "",
  Amount: 0,
  ExpirationDate: "",
  IsRedeemable: false,
  Name: "",
  IsTerminalVoucher: false,
  PBSVoucherInfo: {
    AdditionalInfos: {},
    Amount: 0,
    BettingRestrictions: {
      RestrictionsByCutPoint: {},
    },
    BonusOfferId: 0,
    CurrencyISOCode: "",
    GeneralConditions: {},
    IsPrinted: false,
    Status: null,
    UserNodesNr: 0,
    VoucherCode: "",
    VoucherHistory: [],
    VoucherStatus: null,
  },
  HasFreeBet: false,
};

export const BetslipErrorsInitial: BetslipErrors = {
  Message: "",
  Code: 0,
  HasError: false,
};

export const BetslipTotalsInitial: BetslipTotals = {
  Stake: 0,
  PotentialWin: 0,
  PotentialWinWithBoost: 0,
  Odds: 0,
  TicketNumber: 0,
  SingleStakeLimits: 2000000000,
  SystemStakeLimits: 2000000000,
  IsConfirmed: false,
};

export const BetslipOveraskInitial: BetslipOverask = {
  Overasked: false,
  Info: {},
  CloseBet: false,
  ExpiryDate: "",
  Status: "",
  StatusString: "",
  RejectId: 0,
  MaxStake: 0,
  Approved: false,
  Rejected: false,
  Finished: false,
};

export const BetslipSystemsInitial: BetslipSystems = {
  PossibleSystems: [],
  Selected: null,
};

// GLOBAL INITIAL STATE
export const BetslipStateInitial: BetslipState = {
  ProviderResponse: null,
  Filters: BetslipFilterInitial,
  Processing: BetslipProcessingInitial,
  Betbuilder: BetslipBetbuilderInitial,
  Freebets: BetslipFreebetsInitial,
  Systems: BetslipSystemsInitial,
  LineItems: [],
  Errors: BetslipErrorsInitial,
  Totals: BetslipTotalsInitial,
  Overask: BetslipOveraskInitial,
};
