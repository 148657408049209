/**
 * This is a utility interface for all countries filters
 * Country filters apply subjetive restrictions to different
 * actions, returning true/false flags. Restrictions vary
 * a lot for different countries and are tied to regulations.
 * In order to keep restrictions on the same place, filters
 * by country (sometimes regions) will be exist on this folder.
 * The goal is to move if(condition) logic from the main corpse
 * of code to a specific class.
 */

export interface ErrorAlertSportsCasinoGeolocation {
  title: string;
  subtitle: string;
  txt: string[];
  btn: string;
  linkwhatsapp: string;
}
export interface ILicenseFilter {
  getLicense(): number;
  getCountry(): string;
  canPlayCasino(): boolean;
  getCanPlayCasinoError(): string;
  canBetSports(): boolean;
  getCanBetSportsError(): string;
  geolocationAlertCabaError(): ErrorAlertSportsCasinoGeolocation;
  geolocationAlertCabaErrorCasino(): ErrorAlertSportsCasinoGeolocation;
  isOrdenCongelamientoFondos(): boolean;
}
