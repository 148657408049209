/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Output, inject } from "@angular/core";
import { Router } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { FooterService } from "@providers/FooterService";
import { TrackingService } from "@providers/TrackingService";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { MSO_PATHS } from "@shared-constants/routes";
import { Utils } from "@utils/Utils";
interface Card {
  title: string;
  img: string;
  txt: string;
  href?: string;
  type?: "external" | "event";
  canclick: boolean;
  txtmobile: boolean;
  arrow: boolean;
  disabled: boolean;
}
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "contact-with-us",
  templateUrl: "./contact-with-us.html",
  styleUrls: ["./contact-with-us.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule],
})
export class contactWithUs {
  cards: Array<Card> = [];
  licenseVars: any;
  isMobile = false;
  isDesktop = false;
  @Output() clickChatOnline = new EventEmitter();

  newBaseService = inject(NewBaseService);
  translate = inject(TranslateService);
  footerservice = inject(FooterService);
  utils = inject(Utils);
  trackingService = inject(TrackingService);
  device = inject(DeviceService);
  router = inject(Router);

  constructor() {
    this.isDesktop = this.device.isDesktop();
    this.isMobile = this.device.isMobile();
    this.footerservice.loadFooter().then((data) => {
      this.licenseVars = data.licenseVars;
      this.cards = [
        {
          title: "WhatsApp",
          img: "assets/global/img/icon-whatsapp-contact.svg",
          txt: this.translate.instant("contact_whatsapptxt"),
          href: this.licenseVars.URL_COLOMBIA_WHATSAPP,
          type: "external",
          canclick: true,
          txtmobile: false,
          arrow: true,
          disabled: false,
        },
        {
          title: "Telegram",
          img: "assets/global/img/icon-info-telegram.svg",
          txt: this.translate.instant("contact_telegramtxt"),
          href: this.licenseVars.URL_COLOMBIA_TELEGRAM,
          type: "external",
          canclick: true,
          txtmobile: false,
          arrow: true,
          disabled: true,
        },
        {
          title: "Chat Online",
          img: "assets/global/img/icon-chat-contact.svg",
          txt: this.translate.instant("contact_chatonlinetxt"),
          href: "openchat",
          type: "event",
          canclick: true,
          txtmobile: false,
          arrow: true,
          disabled: false,
        },
        {
          title: this.translate.instant("contact_usefulinformation"),
          img: "assets/global/img/icon-info-contact.svg",
          txt: this.translate.instant("contact_usefulinformationtxt"),
          href: "openhelp",
          type: "event",
          canclick: true,
          txtmobile: false,
          arrow: true,
          disabled: false,
        },
        {
          title: this.translate.instant("contact_tlf"),
          img: "assets/global/img/icon-tlf-contact.svg",
          txt: this.translate.instant("contact_tlftxt"),
          href: this.licenseVars.URL_COLOMBIA_TLF,
          type: "external",
          canclick: this.isMobile,
          txtmobile: false,
          arrow: true,
          disabled: false,
        },
        {
          title: this.translate.instant("contact_email"),
          img: "assets/global/img/icon-mail-contact.svg",
          txt: this.translate.instant("contact_emailtxt"),
          href: this.licenseVars.URL_MAIL_CO,
          type: "external",
          canclick: true,
          txtmobile: false,
          arrow: true,
          disabled: false,
        },
        {
          title: this.translate.instant("contact_casino"),
          img: "assets/global/img/icon-retail.svg",
          txt:
            this.translate.instant("contact_casinotlf") + "<br>" + this.translate.instant("contact_casinoemail"),
          canclick: false,
          txtmobile: true,
          arrow: false,
          disabled: false,
        },
      ];
    });
  }
  isImgCustom(img: string) {
    return img.includes("assets");
  }

  onclickCard(c: Card) {
    if (!c.canclick) {
      return;
    }
    if (c.type) {
      if (c.type == "event") {
        switch (c.href) {
          case "openhelp":
            this.router.navigate([MSO_PATHS.BASE + "/" + MSO_PATHS.HelpPage]),
              { animate: !this.device.isDesktop() };
            break;
          case "openchat":
            this.clickChatOnline.emit("openChat");

            break;
        }
      } else {
        const newLink = document.createElement("a");
        newLink.setAttribute("target", "_blank");
        newLink.setAttribute("href", c.href);
        newLink.click();
      }
    }
  }

  capitalizeFirstLetterOfEachSentence(str: string) {
    return str.replace(/(^\w{1})|(\.\s+\w{1})/gi, function (toReplace) {
      return toReplace.toUpperCase();
    });
  }
}
