<sb-tabs
  [categories]="filtersType"
  [color]="'regular'"
  [selectedTab]="selectedFilter"
  (selectTab)="changeTicketType($event.index)"
></sb-tabs>

<div class="my-bets-betslip--empty-state background-color-dark color-grey-400" *ngIf="emptyState">
  {{ emptyStateMessage }}
</div>

<div class="my-bets-betslip--container">
  <ion-list
    class="my-bets--list-content background-color-dark"
    [hidden]="myBetsTickets.length == 0"
    *ngFor="let ticket of myBetsTickets"
    no-lines
  >
    <ion-grid>
      <div class="my-bets--date color-light sticky-item">{{ ticket.TicketsDatetime | CodereFormatDate: [1] }}</div>
      <ion-row>
        <ion-col size="12" *ngFor="let bet of ticket?.Tickets">
          <my-bets-tile
            [ticket]="bet"
            (goToEvent)="goToEvent($event)"
            (getCashoutByTicket)="getCashoutByTicket($event, ticket)"
            (cashout)="payOutTicket($event)"
          >
          </my-bets-tile>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-list>
  <div *ngIf="showMoreActive" class="my-bets-betslip--show-more background-color-dark">
    <ion-button class="sports-btn btn-transparent with-border sportsbook" (click)="showMore()" fill="solid">
      <ion-label>
        <span class="sb-ticket--button-title text-uppercase">Ver más</span>
      </ion-label>
    </ion-button>
  </div>
</div>
