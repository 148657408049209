export interface singleResultItem {
  sport: {
    id: number;
    sportHandle: string;
  };
  location: {
    id: number;
  };
  league: {
    id: number;
  };
  event: {
    id: number;
    hasHandicap: boolean;
  };
  placementSource: string;
  clientData: string;
  maxSystem: number;
  taxSettings: string;
  game: {
    id: number;
    gameTypeId: number;
    specialOddsValue: string;
    isInPlay: boolean;
    betTypeAvailability: number;
    placeOddsDivisor: number;
    includedPlaces: number;
  };
  odds: number;
  resultId: number;
  betType: string;
  itemId: number;
  isBanker: boolean;
  canBeBanker: boolean;
  canBeEachWay: boolean;
}
