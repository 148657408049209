import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
  withJsonpSupport,
} from "@angular/common/http";
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
import { type ApplicationConfig, importProvidersFrom } from "@angular/core";
import { LoaderInterceptor } from "@interceptors/loader.interceptor";
import { IonicModule } from "@ionic/angular";
import { TicketInterceptor } from "@sports-interceptors/ticket.interceptor";

import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter, withHashLocation, withRouterConfig } from "@angular/router";
import { CapacitorHttpProvider } from "@interceptors/http.interceptor";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { LanguageService } from "@providers/LanguageService";
import { appRoutes } from "./app-routes";

import { type RootStoreConfig, StoreModule } from "@ngrx/store";
import * as fromApp from "@sports-stores/app.reducer";

import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { MyBetsEffects } from "@sports-stores/my-bets";
import { SidebarEffects } from "@sports-stores/sidebar/index";
import { SportsbookEffects } from "@sports-stores/sportsbook/index";
import { TicketEffects } from "@sports-stores/ticket/index";

const reduxConfig: RootStoreConfig<any> = {
  runtimeChecks: {
    strictStateImmutability: true,
    strictActionImmutability: true,
    strictActionSerializability: false,
    strictStateSerializability: false,
  },
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(appRoutes, withRouterConfig({ onSameUrlNavigation: "reload" }), withHashLocation()),
    provideHttpClient(withJsonpSupport()),
    importProvidersFrom(
      IonicModule.forRoot({
        menuType: "reveal",
        menuIcon: "chevron-forward",
        backButtonText: "",
        innerHTMLTemplatesEnabled: true,
        swipeBackEnabled: false,
      }),
    ),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: LanguageService,
        },
      }),
    ),
    importProvidersFrom(
      StoreModule.forRoot(fromApp.appReducer, reduxConfig),
      EffectsModule.forRoot([TicketEffects, SidebarEffects, SportsbookEffects, MyBetsEffects]),
      StoreDevtoolsModule.instrument(),
    ),
    // Se han eliminado los interceptores porque se requiere que el loader
    // se muestre manualmente activándolo y desactivándolo por código
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    // Se añade un nuevo interceptor que maneja las comunicaciones entre el ticket
    // de frontend y backend de apuestas(PBS)
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TicketInterceptor,
      multi: true,
    },
    CapacitorHttpProvider,
  ],
};
