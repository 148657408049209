import { CommonModule } from "@angular/common";
import { Component, Input, OnChanges, OnInit, Renderer2, inject } from "@angular/core";
import { CasinoCardNewComponent } from "@components/casino/casino-card-new/casino-card-new.component";
import { CodereSliderCsvComponent } from "@components/mso/slider/codere-slider-csv/codere-slider-csv";
import { CodereSliderComponent } from "@components/mso/slider/codere-slider/codere-slider";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { EventTypes } from "@models/TrackingEvents";
import { EventsService } from "@providers/EventsService";
import { NewBaseService } from "@providers/newBase.service";
import { LobbyFacadeService } from "@services/casino/loobyFacade.service";
import { DeviceService } from "@services/device.service";
import { Observable } from "rxjs";
import { AbstractLoobyFacadeService } from "src/app/modules/shared/abstracts/abstract-looby-facade-service";
import { CasinoCardSlotsComponent } from "../casino-card-slots/casino-card-slots.component";
import { CasinoLiveCardNewComponent } from "../casino-live-card-new/casino-live-card-new";

@Component({
  selector: "lb-omnichanel",
  templateUrl: "./omnichanel-lobby.html",
  styleUrls: ["./omnichanel-lobby.scss"],
  standalone: true,
  imports: [
    CommonModule,
    CasinoCardSlotsComponent,
    CasinoCardNewComponent,
    CodereSliderComponent,
    CodereSliderCsvComponent,
    CasinoLiveCardNewComponent,
  ],
  providers: [
    {
      provide: AbstractLoobyFacadeService,
      useClass: LobbyFacadeService,
    },
  ],
})
export class omnichanelLobbyComponent implements OnInit, OnChanges {
  @Input() omnichannellobby;
  category;
  searchResult;
  activeFilterIndex = 0;
  globalVars!: GlobalVarsModel;
  isDesktop = false;
  lobbyName: string;
  lobby$: Observable<any>;

  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  facade = inject(AbstractLoobyFacadeService);

  constructor(
    private events: EventsService,
    private renderer: Renderer2,
  ) {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isDesktop = this.deviceService.isDesktop();
  }

  ngOnInit() {
    this.category = this.omnichannellobby[0];
    this.lobbyName = this.category;
  }

  ngOnChanges() {}

  selectSearch(word: string) {
    if (word !== "") {
      this.facade.search(word);
      this.activeFilterIndex = -1;
      this.facade.track(word, { event: EventTypes.CasinoLobbySearch });
    } else {
      setTimeout(() => {
        this.activeFilterIndex = 0;
        this.omnichannellobby = this.category;
      }, 50);
    }
  }

  clear() {
    this.selectSearch("");
  }

  playGame(game) {
    game.game.lobby = this.facade.getCurrentLobby();
    game.game.product = "Casino";
    this.facade.playGame(game);
  }

  ngOnDestroy() {}
}
