/* eslint-disable */
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dictionary",
  standalone: true,
})
export class DictionaryPipe implements PipeTransform {
  transform(dict: Object): any[] {
    if (!dict) {
      return [];
    }
    const a = [];
    for (const key in dict) {
      if (dict.hasOwnProperty(key)) {
        a.push({ key: key, val: dict[key] });
      }
    }
    return a;
  }
}
