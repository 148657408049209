/* eslint-disable */
import {
  type AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  type OnInit,
  Output,
  Renderer2,
  inject,
} from "@angular/core";

import { CommonModule } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import { type IonImg, IonicModule } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { Game } from "@models/lobby/casinoDataModels";
import { SlotName } from "@pipes/casino/lobby/slot-name.pipe";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";

@Component({
  selector: "casino-card-slots",
  templateUrl: "./casino-card-slots.component.html",
  styleUrls: ["./casino-card-slots.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule, SlotName],
})
export class CasinoCardSlotsComponent implements OnInit, AfterViewInit {
  image: string;
  defaultImg: string;
  defaultImgSlots: string;
  ImageSlots: string;
  baseImage: string;
  loaded = false;
  inited = false;
  globalVars!: GlobalVarsModel;
  isDesktop = false;

  @Input() game: Game;
  @Input() outstanding = false;
  @Input() grid: boolean;
  @Output() onPlay: EventEmitter<any> = new EventEmitter();
  @Output() height: EventEmitter<number> = new EventEmitter();

  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  element = inject(ElementRef);
  renderer = inject(Renderer2);
  cd = inject(ChangeDetectorRef);
  sanitizer = inject(DomSanitizer);

  private ref: HTMLElement;

  constructor() {
    // * GlovalVars
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    if (this.globalVars) {
      this.baseImage = this.globalVars.LOBBY_IMAGES;
      this.isDesktop = this.deviceService.isDesktop();
    }
  }

  ngOnInit() {
    if (this.game && !this.inited) {
      this.setImage();
      this.inited = true;
    }
  }

  ngAfterViewInit() {
    if (!this.game) return;
    this.ref = this.element.nativeElement;
    this.setSize();
  }

  @HostBinding("attr.priority")
  get priority() {
    return;
  }

  @HostListener("mouseover")
  onMouseOver() {
    if (!this.ref) return;
    this.renderer.addClass(this.ref, "hovered");
  }

  @HostListener("mouseleave")
  onMouseLeave() {
    if (!this.ref) return;
    this.renderer.removeClass(this.ref, "hovered");
  }

  setSize() {
    if (this.outstanding) return;

    const sizeClass = this.grid ? "in-grid" : this.game.gameTileSize.toLowerCase();

    this.renderer.addClass(this.ref, sizeClass);
  }

  setImage() {
    //set regular image
    const shape = "Square";
    const imageType = this.game.imageType || "jpg";
    const image = this.game.Name.replace(/ /g, "") + "_" + shape + `.${imageType}`;
    this.game.Image = this.baseImage + image;
    this.defaultImg = this.baseImage + "default_" + shape + `.${imageType}`;
    //set slots image
    const imagetypeslots = this.game.OmnichannelImageType || "png";
    const baseImgSlots = "https://www.codere.es/IconsCasino/";
    this.defaultImgSlots = "https://www.codere.es/IconsCasino/Omnichannel_Default.png";
    this.ImageSlots = baseImgSlots + this.game.Name.replace(/ /g, "") + "_" + "Slots" + `.${imagetypeslots}`;
  }

  playGame(demo?: boolean) {
    this.onPlay.emit({ demo: demo, game: this.game });
  }

  isLoaded(loaded: boolean) {
    this.loaded = true;
    this.cd.detectChanges();
  }

  getsafeUrl(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  errorImageHandler(element: IonImg) {
    element.src = this.defaultImgSlots;
  }
}
