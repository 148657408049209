/* eslint-disable @angular-eslint/no-output-on-prefix*/
import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { ParseMoney } from "@pipes/parseMoney";
import { NewBaseService } from "@providers/newBase.service";

@Component({
  selector: "deposits-amount-buttons",
  templateUrl: "./deposits-amount-buttons.html",
  styleUrls: ["./deposits-amount-buttons.scss"],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, ParseMoney],
})
export class depositsAmountButtonsComponent {
  @Input()
  opt: number;
  @Input()
  amouts: Array<number>;
  @Input()
  track: string;
  @Output() onPush: EventEmitter<any> = new EventEmitter();

  newBaseService = inject(NewBaseService);

  globalVars!: GlobalVarsModel;

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  click_btAmountDeposit(amount) {
    this.globalVars.isDeposit = true;
    this.onPush.emit({ opt: this.opt, amount: amount, track: this.track });
  }
}
