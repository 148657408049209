<form [formGroup]="myForm">
  <ion-row>
    <ion-col [size]="isDesktop ? 6 : 12">
      <div>
        <ion-input
          type="text"
          formControlName="name"
          [attr.disabled]="true"
          [label]="'rNom' | translate"
          labelPlacement="floating"
        >
        </ion-input>
      </div>
    </ion-col>
    <ion-col [size]="isDesktop ? 6 : 12">
      <div>
        <ion-input
          type="text"
          formControlName="surname"
          [attr.disabled]="true"
          [label]="'pIApe' | translate"
          labelPlacement="floating"
        ></ion-input>
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col [size]="isDesktop ? 6 : 12">
      <div>
        <ion-input
          type="text"
          formControlName="documentTypeCO"
          [attr.disabled]="true"
          [label]="'rCoDocumentType' | translate"
          labelPlacement="floating"
        >
        </ion-input>
      </div>
    </ion-col>
    <ion-col [size]="isDesktop ? 6 : 12">
      <!--Número de documento-->
      <div>
        <ion-input
          type="text"
          formControlName="NumberDocument"
          [attr.disabled]="true"
          [label]="'rCoNumberDocument' | translate"
          labelPlacement="floating"
        ></ion-input>
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col [size]="isDesktop ? 6 : 12">
      <div>
        <ion-input
          type="text"
          formControlName="address"
          [attr.disabled]="true"
          [label]="'pIAddress' | translate"
          labelPlacement="floating"
        ></ion-input>
      </div>
    </ion-col>
    <ion-col [size]="isDesktop ? 6 : 12">
      <div *ngIf="globalVars.licenseType === 21">
        <ion-input
          type="text"
          formControlName="Municipality"
          [attr.disabled]="true"
          [label]="'GetCardcity' | translate"
          labelPlacement="floating"
        >
        </ion-input>
      </div>
      <div *ngIf="globalVars.licenseType === 22">
        <ion-input
          type="text"
          formControlName="Province"
          [attr.disabled]="true"
          [label]="'pIState' | translate"
          labelPlacement="floating"
        >
        </ion-input>
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col [size]="isDesktop ? 6 : 12">
      <div>
        <ion-input
          type="tel"
          formControlName="phone"
          [attr.disabled]="true"
          [label]="'pIMvl2' | translate"
          labelPlacement="floating"
        ></ion-input>
      </div>
    </ion-col>
    <ion-col [size]="isDesktop ? 6 : 12">
      <div>
        <ion-input
          type="email"
          formControlName="email"
          [attr.disabled]="true"
          [label]="'pIMail' | translate"
          labelPlacement="floating"
        ></ion-input>
      </div>
    </ion-col>
  </ion-row>
</form>
