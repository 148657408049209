export class RaceMarketPage {
  dropListEvents: any;
  participants: any;

  constructor(participants, dropListEvents) {
    this.dropListEvents = dropListEvents;
    this.participants = participants;
  }
}

export interface I_SportsHorses {
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  EventNodeTypeId: number;
  GameTypeId: number;
  Priority: number;
  SportHandle: string;
  ChildrenCount: number;
  CountryCode: string;
  IsActive: boolean;
  OddsAvailability: number;
  PaddockId: string;
  StartDate: string;
  Races: any[];
  Selected: boolean;
  Locked: boolean;
  StreamingEnabled: string;
  StatisticsId: string;
  StreamingId: string;
  PlaceDisabled: any;
  WinDisabled: any;
}

export class C_SportsHorses {
  Name?: string;
  NodeId?: string;
  ParentNodeId?: string;
  EventNodeTypeId?: number;
  GameTypeId?: number;
  Priority?: number;
  SportHandle?: string;
  ChildrenCount?: number;
  CountryCode?: string;
  IsActive?: boolean;
  OddsAvailability?: number;
  PaddockId?: string;
  StartDate?: string;
  Races?: any[];
  Selected = false;
  Locked?: boolean;
  StreamingEnabled?: string;
  StatisticsId?: string;
  StreamingId?: string;
  PlaceDisabled?: any;
  WinDisabled?: any;

  constructor(
    name?: string,
    nodeId?: string,
    parentNodeId?: string,
    eventNodeTypeId?: number,
    priority?: number,
    sporthandle?: string,
    childrenCount?: number,
    countryCode?: string,
    active?: boolean,
    oddsAvailability?: number,
    paddockId?: string,
    startDate?: string,
    GameTypeId?: number,
    races?: any[],
    locked?: boolean,
    streamingEnabled?: string,
    statisticsId?: string,
    streamingId?: string,
  ) {
    this.Name = name;
    this.NodeId = nodeId;
    this.ParentNodeId = parentNodeId;
    this.EventNodeTypeId = eventNodeTypeId;
    this.GameTypeId = GameTypeId;
    this.Priority = priority;
    this.SportHandle = sporthandle;
    this.ChildrenCount = childrenCount;

    this.CountryCode = countryCode;
    this.IsActive = active;
    this.OddsAvailability = oddsAvailability;
    this.PaddockId = paddockId;
    this.StartDate = startDate;
    this.Races = races;
    this.Locked = locked;
    this.StreamingEnabled = streamingEnabled;
    this.StatisticsId = statisticsId;
    this.StreamingId = streamingId;
  }

  get hourName() {
    return this.hour + " " + this.Name;
  }

  get hour() {
    return this.extractHour(this.StartDate);
  }

  //NEEDS REFACTOR
  private extractHour(date: string) {
    const current = new Date(parseInt(date.slice(6, date.length - 2)));

    return this.formatoNumber(current.getHours(), 2) + ":" + this.formatoNumber(current.getMinutes(), 2);
  }

  private formatoNumber(numero: any, digitos: number): string {
    //devuelve el string del número con n dígitos
    let numberStr: string = numero.toString();
    while (numberStr.length < digitos) {
      numberStr = "0" + numberStr;
    }
    return numberStr;
  }

  static races = {
    type: {
      horse: "horse_racing",
      greyhound: "greyhound_racing",
    },
    name: {
      horse: "Carrera de caballos",
      greyhound: "Carrera de galgos",
    },
  };
}

export class C_SportsRacer {
  IsNonRunner: boolean;
  EventNodeTypeId: number;
  Priority: number;
  Trap: string;
  Odd: number;
  JockeyColours: string;
  Name: string;
  SportHandle: string;
  JockeyName: string;
  Cloth: string;
  NodeId: string;
  Place: boolean;
  ParentNodeId: string;
  ChildrenCount: number;
  Locked?: boolean;
  Tax?: number;
}

export class C_SportsRace {
  PreviousRace: string;
  NextRace: C_SportsHorses;
  Participants: Array<C_SportsRacer>;
  PaddockName: string;
  StartDate: string;
  MeetingId: string;
  GameId: string;
  HasHandicap: boolean;
  TricastDisabled: boolean;
  WinDisabled: boolean;
  ForecastDisabled: boolean;
  PlaceDisabled: boolean;
  CountryCode: string;
  PaddockId: string;
  IsActive: boolean;
  OddsAvailability: number;
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  EventNodeTypeId: number;
  Priority: number;
  SportHandle: string;
  ChildrenCount: number;
  StreamingEnabled: string;
  StatisticsId: string;
  StreamingId: string;
  EventHasHandicap?: boolean;
  GameBetTypeAvailability?: number;
  GameNumberOfStarters?: number;
  GameSpecialOddsValue?: string;
  GameTypeId?: number;
  LocationId?: number;
  Locked?: boolean;
  ShowDisabled?: boolean;
  SportId?: string;
  SuperFectaDisabled?: boolean;
}
