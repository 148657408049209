/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from "@angular/core";

interface Scripts {
  name: string;
  src: string;
  id?: string;
}

export class scriptServiceUrls {
  static paypal = "https://www.paypalobjects.com/api/checkout.js";
  static customInsights = "assets/js/customInsights.js";
  static common = "assets/js/common.js";
  static betradarliveplayer = "assets/global/liveplayer/avvpl-player.js";
  // static betradarliveplayer2 = 'https://avplayer-cdn.akamaized.net/dist/latest/avvpl-player.js';
  static sportradar = "https://widgets.sir.sportradar.com/79ba5dd67143eb353e2ff50396308743/widgetloader";
  static sportradarEuro = "https://widgets.sir.sportradar.com/70d81400b30b54d5e9beaa4081894885/widgetloader";
  static sportradarF1 = "https://widgets.sir.sportradar.com/79ba5dd67143eb353e2ff50396308743/widgetloader";
  static trackingUETRegisterCO = "assets/js/trackingUETRegisterCO.js";
  static qrcode = "https://cdn.jsdelivr.net/npm/qr-creator/dist/qr-creator.min.js";
  static DYApiDynamic = "https://cdn-eu.dynamicyield.com/api/9877471/api_dynamic.js";
  static DYApiStatic = "https://cdn-eu.dynamicyield.com/api/9877471/api_static.js";
}

export const ScriptStore: Scripts[] = [
  { name: "paypal", src: scriptServiceUrls.paypal },
  { name: "customInsights", src: scriptServiceUrls.customInsights },
  { name: "common", src: scriptServiceUrls.common },
  {
    name: "betradarliveplayer",
    src: scriptServiceUrls.betradarliveplayer,
    // src: scriptServiceUrls.betradarliveplayer2
  },
  { name: "sportradar", src: scriptServiceUrls.sportradar },
  {
    name: "sportradar-euro",
    src: scriptServiceUrls.sportradarEuro,
    id: "sportradar-euro",
  },
  { name: "trackingUETRegisterCO", src: scriptServiceUrls.trackingUETRegisterCO },
  { name: "qrcode", src: scriptServiceUrls.qrcode },
  { name: "redsys", src: "" },
  { name: "DYApiDynamic", src: scriptServiceUrls.DYApiDynamic },
  { name: "DYApiStatic", src: scriptServiceUrls.DYApiStatic },
  { name: "sportradarF1", src: scriptServiceUrls.sportradarF1 },
];

declare const document: any;

@Injectable({ providedIn: "root" })
export class ScriptService {
  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }
  loadScript(name: string, src?: string) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: "Already Loaded" });
      } else {
        //load script
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = this.scripts[name].src;
        if (name == "sportradar") {
          script.setAttribute("data-sr-language", "es");
        }
        if (name === "redsys") script.src = src;
        if (name === "sportradar-euro") script.id = "sportradar-euro";
        if (script.readyState) {
          //IE
          script.onreadystatechange = () => {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: "Loaded" });
            }
          };
        } else {
          //Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: "Loaded" });
          };
        }
        script.onerror = (error: any) => reject({ script: name, loaded: false, status: "Loaded" });
        document.getElementsByTagName("head")[0].appendChild(script);
      }
    });
  }

  removeScript(itemsId: string[]) {
    itemsId.map((item) => {
      const element = document.getElementById(item);
      if (element) {
        element.parentNode.removeChild(element);
      }
    });
  }
}
