import { HttpHeaders } from "@angular/common/http";
/* eslint-disable eqeqeq */
import { Injectable, inject } from "@angular/core";
import { TypeLogout } from "@models/I_DecouplingLogout";
import { Observable, throwError } from "rxjs";
import { map, tap } from "rxjs/operators";
import { IAuthenticationService } from "../interfaces/IAuthenticationService";
import { LoginResponse } from "../models/LoginServices/LoginResponse";
import { LogoutResponse } from "../models/LoginServices/LogoutResponse";
import { LicenseTypes } from "../models/MasterData";
import { BaseService } from "./BaseService";
import { DecouplingUserServiceMSO } from "./DecouplingUserServiceMSO.service";
import { UserService } from "./UserService";

@Injectable({ providedIn: "root" })
export class LegacyAuthenticationService extends BaseService implements IAuthenticationService {
  userService = inject(UserService);
  decouplingService = inject(DecouplingUserServiceMSO);

  constructor() {
    super();
  }

  login(username: string, password: string, persistCookie?: boolean): Observable<LoginResponse> {
    const afToken: string = this.getRFToken();
    if (!persistCookie) {
      persistCookie = false;
    }
    const data = {
      loginName: username,
      password: password,
      persistCookie: persistCookie,
      deviceType: this.globalVars.PLATFORM,
      __RequestVerificationToken: afToken,
    };
    let url: string = "";
    if (this.globalVars.FEATURES.doLoginwithLoginServices) {
      url = `${this.globalVars.URLBASE_LOGINSERVICES}/login/web`;
    } else {
      url = this.globalVars.URLBASE + "account/loginjson";
    }
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
    const result = this.myPost(url, data, { headers: headers }, false, this.handleError).pipe(
      map((user: any) => {
        if (this.globalVars.FEATURES.doLoginwithLoginServices) {
          // Convert string to dates
          user.lastLogin = user.lastLogin ? new Date(user.lastLogin) : user.lastLogin;
          user.lastPassword = user.lastPassword ? new Date(user.lastPassword) : user.lastPassword;
        } else {
          // Map properties which signature changes in new type
          user.userName = user.username;
          user.selfExclusion = user.selfexclusion;
          user.limitsExpired = user.limitsexpired;
        }
        return user;
      }),
      tap((user) => {
        if (user.success) {
          this.userService.getCustomizations().subscribe(
            (custom) =>
              // **+** this.globalVars.getcuotaAccept(custom.customizations);
              user,
          );
        } else return user;
      }),
    );
    return result;
  }

  nativeLogin(username: string, password: string): Observable<LoginResponse> {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
    const data: string = "user=" + username + "&password=" + password;
    const url: string = this.globalVars.URLBASE + "NativeApps/NewLogin";
    return this.myPost(url, data, { headers: headers });
  }

  nativeAutoLogin(username: string, encryptedPassword: string): Observable<LoginResponse> {
    const url: string =
      this.globalVars.URLBASE +
      "NativeApps/NewAutoLogin?user=" +
      username +
      "&encryptedPassword=" +
      encryptedPassword;
    return this.myGet(url, true, (err: any, caught: Observable<any>): any => {}).pipe(
      map((data: any) => {
        if (data.success) {
          const setData: LoginResponse = {
            activeSmsEmail: data.activeSmsEmail,
            balance: data.balance,
            encryptedPassword: data.encryptedPassword,
            idBGT: data.idBGT,
            lastLogin: data.lastLogin,
            lastPassword: data.lastPassword,
            limitsExpired: data.limitsexpired,
            selfExclusion: data.selfexclusion,
            status: data.status,
            success: data.success,
            userRegionAux: data.userRegionAux,
            userName: data.username,
            webUserUrl: data.webUserUrl,
          };
          if (
            this.globalVars.licenseType == LicenseTypes.ArgentinaCaba ||
            this.globalVars.licenseType == LicenseTypes.ArgentinaPBA
          ) {
            setData.sessionLimitsGasto = data.sessionLimitsGasto ? data.sessionLimitsGasto : "";
            setData.sessionLimitsPerdida = data.sessionLimitsPerdida ? data.sessionLimitsPerdida : "";
            setData.sessionLimitsTiempo = data.sessionLimitsTiempo ? data.sessionLimitsTiempo : "";
          }
          return setData;
        } else {
          const setData: LoginResponse = {
            status: data.status,
            success: data.success,
            errorCode: data.errorCode,
          };
          return setData;
        }
      }),
    );
  }

  logout(userID: string, type?: TypeLogout): Observable<LogoutResponse | null> {
    if (this.globalVars.FEATURES.MSO_NewLogoutDecoupling) {
      return this.decouplingService.logoutDecoupling(userID, type);
    }
    const url: string = this.globalVars.URLBASE + "account/logOff";
    // return this.myGet(url);
    return this.myGet(url, true, (err: any, caught: Observable<any>): any => {});
  }

  private getRFToken = (): string => {
    // Gets the requestverificationtoken from DOM
    try {
      const token: HTMLInputElement = <HTMLInputElement>(
        document.getElementsByName("__RequestVerificationToken")[0]
      );
      return token.value;
    } catch (err) {
      return "";
    }
  };
  handleErrorLogin(err: any, caught: Observable<any>): Observable<any> {
    return throwError(() => err.error);
  }
}
