import { CommonModule } from "@angular/common";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  type ElementRef,
  NgZone,
  type OnDestroy,
  Renderer2,
  ViewChild,
  inject,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NavigationEnd, NavigationSkipped, Router } from "@angular/router";
import { IonContent, IonicModule } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, type Observable, type Subscription, of } from "rxjs";
import { switchMap } from "rxjs/operators";

import { EventsService } from "@providers/EventsService";
import { ScriptService } from "@providers/ScriptService";
import { StyleService } from "@providers/StyleService";
import { UrlsService } from "@providers/UrlsService";
import { SportBookService } from "@sports-services/index";
//Services
import { SportService } from "@sports-services/index";
import { TicketService } from "@sports-services/index";

import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { MobileBehaviorService } from "@sports-services/mobile-behavior.service";

import { SportsbookFacade } from "@sports-stores/sportsbook/sportsbook.facade";
import * as sportsbookSelector from "@sports-stores/sportsbook/sportsbook.selectors";
// Stores
import * as ticketSelector from "@sports-stores/ticket/ticket.selectors";

// Static Store Service
import { StaticStoreService } from "@providers/static-store.service";

//Models
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { BetSenseData } from "@sports-models/betsense-data.model.deprecated";
import { SmartLineItem } from "@sports-models/index";
import { DropdownMode } from "@sports-models/index";
import { ScoreBoard } from "@sports-models/index";
import { SmartMarketInput } from "@sports-models/index";
import { I_BetSenseModel } from "@sports-models/index";
import { NewTicketFacade } from "@sports-stores/ticket/index";

// Pipes
import { ExtractDatePipe } from "@pipes/extractDate.pipe";

// Directives
import { BetDisplayDirective } from "@directives/bet-display/bet-display";

import { SbNavbarComponent } from "@components/common/sb-navbar/sb-navbar";
import { SbButtonComponent } from "@components/sports/sb-button/sb-button";
// Components
import { SbDropdownComponent } from "@components/sports/sb-dropdown/sb-dropdown";
import { SbGridContentSingleComponent } from "@components/sports/sb-grid-content-single/sb-grid-content-single";
import { SbGridHeaderComponent } from "@components/sports/sb-grid-header/sb-grid-header";
import { SbGridItemComponent } from "@components/sports/sb-grid-item/sb-grid-item";
import { SbPrematchScoreboardComponent } from "@components/sports/sb-prematch-scoreboard/sb-prematch-scoreboard";

import { CodereFooterComponent } from "@components/mso/footer/codere-footer/codere-footer";
import { IonLoaderService } from "@services/loader-feature/ion-loader.service";
import { SPORTS_PATHS } from "@shared-constants/routes";
import {
  BetbuilderComponent,
  SbMarqueeBetSenseComponent,
  SbTabsCategoriesComponent,
} from "@sports-components/index";
import { SportsbookState } from "@sports-stores/sportsbook/index";
import { ticketState } from "@sports-stores/ticket/ticket.reducers";
import { SportsConstants } from "@sports-utils/index";
import { SportHandleList } from "@sports-utils/index";
//Others
import { Utils } from "@utils/Utils";
import { BasePage, type TrackingInfo } from "../../base/BasePage";

declare const SIR;

@Component({
  selector: "page-market",
  templateUrl: "./market.html",
  styleUrls: ["./market.scss"],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    SbNavbarComponent,
    SbPrematchScoreboardComponent,
    SbDropdownComponent,
    SbGridHeaderComponent,
    SbGridItemComponent,
    SbButtonComponent,
    SbGridContentSingleComponent,
    CodereFooterComponent,
    BetbuilderComponent,
    BetDisplayDirective,
    SbMarqueeBetSenseComponent,
    SbTabsCategoriesComponent,
  ],
})
export class MercadosPage extends BasePage implements OnDestroy {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild("standardWidgetSIR") standardWidgetSIR: ElementRef;
  @ViewChild("stickyWidgetSIR") stickyWidgetSIR: ElementRef;
  @ViewChild("standardWidgetSIR2") standardWidgetSIR2: ElementRef;
  @ViewChild("stickyWidgetSIR2") stickyWidgetSIR2: ElementRef;
  market: any;
  marketBets$ = [];
  currentCategory$ = new BehaviorSubject(null);
  categories: any[] = [];
  categories$: Observable<any>;
  scoreboard: ScoreBoard;
  offset = 0;
  nameLeague: string;
  eventListener: any;
  widgetActive: string;
  //GameType property used for identify "Resultados Finales"
  public gametypes: number[] = [284, 114, 37, 82, 153, 192];

  betbuilderSelection$: Observable<SmartLineItem[]>;
  betbuilderSelection: any[] = [];
  smartMarketRequest: SmartMarketInput;
  betbuilderCuotaText = "";
  betbuilderMessageText = "Añadir al menos X selecciones.";
  betbuilderErrorMessage = "";

  isDesktop = false;
  isMobile = false;

  globalVars: GlobalVarsModel;
  extractDate = inject(ExtractDatePipe);
  sportBookService = inject(SportBookService);
  newTicketFacade = inject(NewTicketFacade);
  sportService = inject(SportService);
  ticketService = inject(TicketService);
  translateService = inject(TranslateService);

  urlsService = inject(UrlsService);
  styleService = inject(StyleService);
  scriptService = inject(ScriptService);
  renderer = inject(Renderer2);
  ngZone = inject(NgZone);
  utils = inject(Utils);
  newBaseService = inject(NewBaseService);
  ionLoaderService = inject(IonLoaderService);
  staticStoreService = inject(StaticStoreService);
  sportsbookFacade = inject(SportsbookFacade);

  dropdownMode = DropdownMode;
  deviceService = inject(DeviceService);
  eventService = inject(EventsService);
  mobileBehavior = inject(MobileBehaviorService);
  ticketsStore = inject(Store<ticketState>);
  sportsbookStore = inject(Store<SportsbookState>);
  router = inject(Router);
  betbuilderCatId = SportsConstants.betbuilderCatId;

  marketData$: Observable<any>;

  mySubscription$: Subscription;
  betbuilderSelectionSub$: Subscription;
  marketDataSub$: Subscription;

  //* BETSENSE
  betSenseData: BetSenseData = new BetSenseData();
  betsenseData = [];

  constructor() {
    super();

    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();

    this.betbuilderSelection$ = this.ticketsStore.select(ticketSelector.getBetbuilderSelection);
    this.betbuilderSelectionSub$ = this.betbuilderSelection$.subscribe((betbuilderSelection: any) => {
      this.betbuilderSelection = betbuilderSelection;
      this.checkBetbuilderLength();
    });

    this.marketData$ = this.sportsbookStore.select(sportsbookSelector.getMarketDataState);
    this.marketDataSub$ = this.marketData$.subscribe((marketData) => {
      this.categories = marketData.categories;
      this.currentCategory$.next(marketData.selectedCategory);
      this.marketBets$ = marketData.marketBets;
      this.categories$ = of(marketData.categories);
      if (marketData.betsenseData) {
        this.betsenseData = this.setBetSenseData(marketData.betsenseData);
      }
    });
  }

  ngOnInit(): void {}

  ionViewWillEnter() {
    this.initializePage();
  }

  initializePage() {
    this.newBaseService.getVars
      .pipe(
        switchMap((data: GlobalVarsModel) => {
          this.globalVars = data;
          return of(null);
        }),
      )
      .subscribe();

    this.mySubscription$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationSkipped) {
        this.router.navigated = false;
        this.sportService.showSportradar$.next(false);
        if (!this.router.url.includes(SPORTS_PATHS.MercadosPage)) return;
        if (
          !this.sportsbookFacade.selectedMarket?.NodeId ||
          (history?.state?.params &&
            this.sportsbookFacade.selectedMarket?.NodeId != history?.state?.params?.NodeId)
        ) {
          this.sportsbookFacade.setSelectedMarketData(history.state.params);
          this.nameLeague = this.globalVars.SportHandleSelected;
          this.getData();
        }
      }
    });
    if (history?.state?.params && this.sportsbookFacade.selectedMarket?.NodeId != history?.state?.params?.NodeId) {
      this.sportsbookFacade.setSelectedMarketData(history.state.params);
      this.nameLeague = this.globalVars.SportHandleSelected;
    }
    this.getData();

    this.trackingService.trackEvent([
      "SelectMarket",
      this.sportsbookFacade.selectedMarket.NodeId,
      this.sportsbookFacade.selectedMarket.SportHandle,
      this.sportsbookFacade.selectedMarket.Name,
      "pageView",
    ]);
    window.addEventListener("message", this.eventListener);
  }

  private getData() {
    const trackingInfo: TrackingInfo = this.getTrackingInfo();
    if (trackingInfo != null) {
      this.trackPageView(trackingInfo);
    }
    this.scoreboard = new ScoreBoard(
      this.sportsbookFacade.selectedMarket.teamHome,
      this.sportsbookFacade.selectedMarket.teamAway,
      this.sportsbookFacade.selectedMarket.StartDate,
      this.sportsbookFacade.selectedMarket.pitcherHome ? this.sportsbookFacade.selectedMarket.pitcherHome : null,
      this.sportsbookFacade.selectedMarket.pitcherAway ? this.sportsbookFacade.selectedMarket.pitcherAway : null,
    );

    this.getMarketsAndCategories();
  }

  ionViewDidEnter() {
    if (this.showSportRadar()) {
      Promise.all([this.scriptService.load("sportradar"), this.styleService.load("sportradar")]).then(() => {
        this.sportService.showSportradar$.next(true);
        this.sportService.eventStatisticsId$.next(this.sportsbookFacade.selectedMarket.StatisticsId);
        this.sportService.isFormula1$.next(false);
        if (this.isMobile) {
          this.widgetActive = "standard";
          this.setSportRadarWidget();
        }
      });
    }
    if (this.globalVars.FEATURES.SPO_LMTFormula1 && this.showFormula1()) {
      Promise.all([this.scriptService.load("sportradarF1"), this.styleService.load("sportradar")]).then(() => {
        this.sportService.showSportradar$.next(true);
        this.sportService.eventStatisticsId$.next(this.sportsbookFacade.selectedMarket.StatisticsId);
        this.sportService.isFormula1$.next(true);
        if (this.isMobile) {
          this.widgetActive = "standard";
          this.setSportRadarWidgetF1();
        }
      });
    }
    this.content.scrollToTop();
  }

  setBetSenseData(betSenseData) {
    const betsenseItems: I_BetSenseModel[] = [];
    betSenseData.forEach((betSenseDataItem) => {
      const NodeId = betSenseDataItem.outcomes.find(
        (item) => item.label === betSenseDataItem.delivery.es.log.cta_target,
      ).id;
      const ParentNodeId = betSenseDataItem.data.offer.id;
      let odd;
      const marketBetGroup = this.marketBets$.find((bet) => bet.NodeId == ParentNodeId);
      if (marketBetGroup) {
        odd = marketBetGroup.GroupResults.find((gr) => gr.NodeId == NodeId).Odd;
      } else {
        odd = betSenseDataItem.data.offer.outcomes.find((i) => i.id == NodeId).oddsDecimal;
      }
      betsenseItems.push({
        title: betSenseDataItem.delivery?.es?.log?.body,
        Name: betSenseDataItem.delivery?.es?.log?.cta,
        Odd: odd,
        marketName: betSenseDataItem.data?.offer?.name,
        NodeId,
      });
    });
    return betsenseItems.filter((item, index, self) => index === self.findIndex((t) => t.NodeId === item.NodeId));
  }

  onScroll(event) {
    if ((!this.showSportRadar() && !this.showFormula1()) || !event) return;
    if (event.detail.scrollTop > 0) {
      this.widgetActive = "sticky";
      if (this.standardWidgetSIR != undefined)
        this.renderer.addClass(this.standardWidgetSIR.nativeElement, "d-none");
      if (this.stickyWidgetSIR != undefined)
        this.renderer.removeClass(this.stickyWidgetSIR.nativeElement, "d-none");
      if (this.standardWidgetSIR2 != undefined)
        this.renderer.addClass(this.standardWidgetSIR2.nativeElement, "d-none");
      if (this.stickyWidgetSIR2 != undefined)
        this.renderer.removeClass(this.stickyWidgetSIR2.nativeElement, "d-none");
    } else {
      this.widgetActive = "standard";
      if (this.standardWidgetSIR != undefined)
        this.renderer.removeClass(this.standardWidgetSIR.nativeElement, "d-none");
      if (this.stickyWidgetSIR != undefined) this.renderer.addClass(this.stickyWidgetSIR.nativeElement, "d-none");
      if (this.standardWidgetSIR2 != undefined)
        this.renderer.removeClass(this.standardWidgetSIR2.nativeElement, "d-none");
      if (this.stickyWidgetSIR2 != undefined)
        this.renderer.addClass(this.stickyWidgetSIR2.nativeElement, "d-none");
    }
    this.mobileBehavior.scrolling$.next(true);
  }

  setSportRadarWidget() {
    this.renderer.addClass(this.stickyWidgetSIR.nativeElement, "d-none");
    SIR(
      "addWidget",
      ".sr-widget-1",
      "match.lmtSticky",
      {
        layout: "topdown",
        scoreboard: "disable",
        detailedScoreboard: "disable",
        tabsPosition: "top",
        matchId: this.sportsbookFacade.selectedMarket.StatisticsId,
      },
      () =>
        setTimeout(() => {
          this.content.scrollToTop();
        }, 500),
    );
    SIR(
      "addWidget",
      ".sr-widget-2",
      "match.lmtPlus",
      {
        layout: "single",
        scoreboard: "disable",
        detailedScoreboard: "disable",
        tabsPosition: "top",
        pitchLogo: "./assets/global/img/codere-apuestas-mx.svg",
        goalBannerImage: "./assets/global/img/codere_green.svg",
        logo: ["./assets/global/img/codere_grey.svg"],
        matchId: this.sportsbookFacade.selectedMarket.StatisticsId,
      },
      () =>
        setTimeout(() => {
          this.content.scrollToTop();
        }, 500),
    );

    this.renderer.listen(this.stickyWidgetSIR.nativeElement, "click", () => {
      this.widgetActive = "standard";
      this.renderer.addClass(this.stickyWidgetSIR.nativeElement, "d-none");
      this.renderer.removeClass(this.standardWidgetSIR.nativeElement, "d-none");
    });
  }

  setSportRadarWidgetF1() {
    this.renderer.addClass(this.stickyWidgetSIR2.nativeElement, "d-none");
    SIR(
      "addWidget",
      ".sr-widget-1",
      "match.lmtSticky",
      {
        layout: "topdown",
        scoreboard: "disable",
        detailedScoreboard: "disable",
        tabsPosition: "top",
        stageId: this.sportsbookFacade.selectedMarket.StatisticsId,
      },
      () =>
        setTimeout(() => {
          this.content.scrollToTop();
        }, 500),
    );
    SIR(
      "addWidget",
      ".sr-widget-2",
      "match.lmtPlus",
      {
        layout: "single",
        scoreboard: "disable",
        detailedScoreboard: "disable",
        tabsPosition: "top",
        pitchLogo: "./assets/global/img/codere-apuestas-mx.svg",
        goalBannerImage: "./assets/global/img/codere_green.svg",
        logo: ["./assets/global/img/codere_grey.svg"],
        stageId: this.sportsbookFacade.selectedMarket.StatisticsId,
      },
      () =>
        setTimeout(() => {
          this.content.scrollToTop();
        }, 500),
    );
    this.renderer.listen(this.stickyWidgetSIR2.nativeElement, "click", () => {
      this.widgetActive = "standard";
      this.renderer.addClass(this.stickyWidgetSIR2.nativeElement, "d-none");
      this.renderer.removeClass(this.standardWidgetSIR2.nativeElement, "d-none");
    });
  }

  getTrackingInfo(): TrackingInfo {
    return {
      uri: encodeURI(
        `/${this.sportsbookFacade.selectedMarket.SportHandle}/${this.sportsbookFacade.selectedMarket.LeagueName}/nonLive/${this.sportsbookFacade.selectedMarket.Name}`,
      ),
      description: `Mercados page - ${this.sportsbookFacade.selectedMarket.Name}`,
      additionalData: {
        nodeId: this.sportsbookFacade.selectedMarket.NodeId,
        nodeName: this.sportsbookFacade.selectedMarket.Name,
        sportHandle: this.sportsbookFacade.selectedMarket.SportHandle,
        LeagueName: this.sportsbookFacade.selectedMarket.LeagueName,
      },
    };
  }

  getMarketsAndCategories() {
    this.sportsbookFacade.getMarketData();
  }

  public showSportRadar() {
    return (
      (this.sportsbookFacade.selectedMarket?.SportHandle === SportHandleList.soccer ||
        this.sportsbookFacade.selectedMarket?.SportHandle === SportHandleList.tennis ||
        this.sportsbookFacade.selectedMarket?.SportHandle === SportHandleList.basketball) &&
      this.sportsbookFacade.selectedMarket?.LTMEnabled
    );
  }

  changeCategory(category) {
    this.content.scrollToTop();
    this.sportsbookFacade.setMarketSelectedCategory(category);
    // check if selected category is betbuilder
    if (this.isMobile) {
      if (category) {
        if (category.CategoryId == SportsConstants.betbuilderCatId) {
          this.widgetActive = "sticky";
          if (this.stickyWidgetSIR) {
            this.renderer.addClass(this.standardWidgetSIR.nativeElement, "d-none");
            this.renderer.removeClass(this.stickyWidgetSIR.nativeElement, "d-none");
          }
          if (this.stickyWidgetSIR2) {
            this.renderer.addClass(this.standardWidgetSIR2.nativeElement, "d-none");
            this.renderer.removeClass(this.stickyWidgetSIR2.nativeElement, "d-none");
          }
        } else {
          this.widgetActive = "standard";
          if (this.standardWidgetSIR) {
            this.renderer.removeClass(this.standardWidgetSIR.nativeElement, "d-none");
            this.renderer.addClass(this.stickyWidgetSIR.nativeElement, "d-none");
          }
          if (this.standardWidgetSIR2) {
            this.renderer.removeClass(this.standardWidgetSIR2.nativeElement, "d-none");
            this.renderer.addClass(this.stickyWidgetSIR2.nativeElement, "d-none");
          }
        }
      }
    }
  }

  transformGameDataForBet(gameData) {
    return {
      betItem: {
        Odd: gameData.Odd,
        SortOrder: gameData.SortOrder,
        IsLive: false,
        upOdd: false,
        downOdd: false,
        IsNonRunner: false,
        SportId: gameData.Parent.Parent.Parent.Parent.Parent.NodeId,
        LocationId: gameData.Parent.Parent.Parent.Parent.NodeId,
        LeagueId: gameData.Parent.Parent.Parent.NodeId,
        EventId: gameData.Parent.Parent.NodeId,
        EventHasHandicap: false,
        GameId: gameData.Parent.NodeId,
        GameTypeId: gameData.Parent.GameTypeId,
        GameSpecialOddsValue: "",
        GameBetTypeAvailability: 0,
        GameNumberOfStarters: 0,
        Name: gameData.NodeName,
        NodeId: gameData.NodeId,
        ParentNodeId: gameData.Parent.NodeId,
        Priority: 0,
        SportHandle: gameData.SportHandle,
        Locked: gameData.Locked,
        Spov: "",
      },
      gameGroup: {
        DisplayTypeName: gameData.Parent.DisplayTypeName,
        Name: gameData.Parent.NodeName,
        NodeId: gameData.Parent.NodeId,
        ParentNodeId: gameData.Parent.Parent.NodeId,
        GameType: gameData.Parent.GameTypeId,
        Priority: gameData.Parent.Priority,
        SportHandle: gameData.Parent.SportHandle,
        GroupResults: [],
        CategoryInfo: gameData.Parent.CategoryInfo,
        CategoryInfos: gameData.Parent.CategoryInfos,
        teamHome: gameData.Parent.Parent.ParticipantHome,
        teamAway: gameData.Parent.Parent.ParticipantAway,
        Spov: "",
        AllSpov: [""],
        SmartMarketAvailable: gameData.Parent.SmartMarketAvailable,
        nameLeague: gameData.Parent.Parent.Parent.NodeName,
        Locked: gameData.Locked,
        isCombined: gameData.IsCombinable,
      },
      name: gameData.Parent.NodeName,
    };
  }

  betSenseBet(NodeId) {
    const gameGroup = this.marketBets$.find((i) => i.GroupResults.find((gr) => gr.NodeId == NodeId));
    const betItem = this.marketBets$.flatMap((i) => i.GroupResults).find((gr) => gr.NodeId === NodeId);
    if (gameGroup && betItem) {
      this.bet(betItem, gameGroup, gameGroup.Name, "betSense");
    } else {
      this.sportService.getGameByNodeId(NodeId).subscribe((data) => {
        if (data) {
          const bet = this.transformGameDataForBet(data);
          this.bet(bet.betItem, bet.gameGroup, bet.name, "betSense");
        } else {
          this.utils.sportsAlert(true, "", "Mercado no disponible", this.translateService.instant("tAccept"));
        }
      });
    }
  }

  public bet(game: any, event: any, gameName: string, type: string, position = "") {
    let data = {
      ...game,
      EventDate: this.extractDate.transform(event.StartDate),
      EventName: this.sportsbookFacade.selectedMarket.Name,
      GameName: gameName,
    };

    data = this.correctData(data, event, gameName, type, position);

    if (this.currentCategory$.value.CategoryId == SportsConstants.betbuilderCatId) {
      this.checkBetbuilderSelection(data, data.NodeId, game.GameTypeId);
    } else {
      this.newTicketFacade.checkSelection(data, data.NodeId);
    }
  }

  checkBetbuilderSelection(data, nodeId, gameTypeId) {
    const id = this.betbuilderSelection.findIndex((item) => item.NodeId == nodeId);
    // remove
    if (id !== -1) {
      this.newTicketFacade.removeSmartMarketSelection(nodeId);
    } else {
      //add
      this.newTicketFacade.sendAddSmartMarketSelection(data);
    }
  }

  checkBetbuilderLength() {
    switch (this.betbuilderSelection.length) {
      case 0:
        this.betbuilderMessageText = "Necesitas al menos 2 selecciones.";
        this.betbuilderCleanValues();
        break;
      case 1:
        this.betbuilderMessageText = "Añadir al menos 1 o más selecciones.";
        this.betbuilderCleanValues();
        break;
      default:
        this.betbuilderMessageText = "Apostar o añadir más selecciones";
        this.betbuilderCheck();
        break;
    }
  }

  betbuilderCleanValues() {
    this.betbuilderCuotaText = "";
    this.betbuilderErrorMessage = "";
  }

  betbuilderCheck() {
    this.ionLoaderService.showHideAutoLoader();
    this.sportService.addSmartMarketItem(this.betbuilderSelection).subscribe((resp) => {
      this.ionLoaderService.hideLoader();
      if (resp.Odds < 0) {
        this.betbuilderCuotaText = "";
        this.betbuilderErrorMessage =
          "Combinación no válida. Algunas de tus selecciones no son combinables. Por favor, cámbialas para continuar.";
        this.smartMarketRequest = null;
      } else {
        this.betbuilderCuotaText = resp.Odds.toFixed(2).toString();
        this.betbuilderErrorMessage = "";
        this.smartMarketRequest = resp;
      }
    });
  }

  sendAddSmartMarketItem() {
    const smartMarketBet = SmartLineItem.createSmartLineItem(
      this.betbuilderSelection,
      this.sportsbookFacade.selectedMarket,
      this.betbuilderCuotaText,
    );
    delete this.smartMarketRequest["MaxSystem"]; // ????
    this.newTicketFacade.sendAddSmartMarketItem(this.smartMarketRequest, smartMarketBet);
  }

  checkSelected(nodeId) {
    let selected = false;
    if (this.currentCategory$.value.CategoryId == SportsConstants.betbuilderCatId) {
      selected = this.betbuilderSelectionCheck(nodeId);
    } else {
      selected = this.newTicketFacade.hasResult(nodeId);
    }
    return selected;
  }

  betbuilderSelectionCheck(nodeId) {
    const nodeSelected = this.betbuilderSelection.filter((item) => item.NodeId === nodeId).length > 0;
    let selected = false;
    selected = this.currentCategory$.value.CategoryId == SportsConstants.betbuilderCatId && nodeSelected;

    return selected;
  }

  //Order Resultados Finales in three colummns: local, tie and visitant
  // private orderThreeColumns(markets) {
  //   markets.forEach((item) => {
  //     const isFinal = this.gametypes.indexOf(item.GameType) != -1;
  //     if (isFinal) {
  //       const local = [];
  //       const tie = [];
  //       const visitant = [];
  //       let a;
  //       let b;
  //       let tmp = '';
  //       item.GroupResults.forEach((it) => {
  //         tmp = it.Name.split(':');
  //         a = parseInt(tmp[0]);
  //         b = parseInt(tmp[1]);
  //         if (a > b) local.push(it);
  //         else if (b > a) visitant.push(it);
  //         else tie.push(it);
  //       });
  //       item.GroupResults = [local, tie, visitant];
  //     }
  //   });
  //   return markets;
  // }

  public showFormula1() {
    return this.sportsbookFacade.selectedMarket.SportHandle == SportHandleList.motorsport;
  }

  getName(index: number, evalName: string) {
    let name = "";
    if (evalName == "Goleadores") {
      switch (index) {
        case 0:
          name = "Primer Goleador";
          break;
        case 1:
          name = "Marca Gol Durante el partido";
          break;
        case 2:
          name = "Último Goleador";
          break;
      }
    }
    return name;
  }

  correctData(data: any, event: any, gameName: string, type: string, position: string) {
    switch (true) {
      case (gameName.includes("Handicap") || gameName.includes("Hándicap")) && type == "1" && position == "1":
        data.Name = `${this.sportsbookFacade.selectedMarket.teamHome} ${data.Name}`;
        break;
      case (gameName.includes("Handicap") || gameName.includes("Hándicap")) && type == "1" && position == "X":
        data.Name = `X (${this.sportsbookFacade.selectedMarket.teamAway} ${data.Name})`;
        break;
      case (gameName.includes("Handicap") || gameName.includes("Hándicap")) && type == "1" && position == "2":
        data.Name = `${this.sportsbookFacade.selectedMarket.teamAway} ${data.Name}`;
        break;
      case position.includes("Primer"):
        data.GameName = position;
        break;
      case position.includes("Durante"):
        data.GameName = position;
        break;
      case position.includes("Último"):
        data.GameName = position;
        break;
    }
    return data;
  }

  getPosition(length: number, index: number) {
    let position = "";
    if (length % 3 == 0) {
      switch (true) {
        case index == 1:
        case index == 4:
        case index == 7:
          position = "X";
          break;
        case index == 2:
        case index == 5:
        case index == 8:
          position = "2";
          break;
        default:
          position = "1";
          break;
      }
    } else {
      switch (true) {
        case index == 1:
        case index == 3:
        case index == 5:
          position = "2";
          break;
        default:
          position = "1";
          break;
      }
    }
    return position;
  }

  removeSmartMarketSelections() {
    this.newTicketFacade.clearBetbuilderSelections();
  }

  public navigate(market): void {
    const component = market.isLive ? SPORTS_PATHS.NowLiveDetailPage : SPORTS_PATHS.MercadosPage;
    const eventType = market.isLive ? "pushEventLive" : "pushEvent";
    this.router.navigate([component], {
      state: { params: market },
      queryParams: { animate: !this.isDesktop },
    });

    this.trackingService.trackEvent([eventType, market.NodeId, window.location.hash, market.Name, "event"]);
  }

  ionViewDidLeave() {
    window.removeEventListener("message", this.eventListener);
    if (this.showSportRadar() && this.isMobile) {
      SIR("removeWidget", document.querySelector(".sr-widget-1"));
      SIR("removeWidget", document.querySelector(".sr-widget-2"));
      SIR("removeWidget", document.querySelector(".sr-widget-22"));
    } else if (this.showSportRadar() && this.isDesktop) {
      this.sportService.showSportradar$.next(false);
      this.sportService.eventStatisticsId$.next("");
      this.sportService.isFormula1$.next(false);
    }
    this.removeSmartMarketSelections();
    this.mySubscription$?.unsubscribe();
    this.newTicketFacade.clearBetbuilderSelections();
    this.sportsbookFacade.clearMarketData();
  }

  ngOnDestroy(): void {
    this.betbuilderSelectionSub$?.unsubscribe();
    this.marketDataSub$?.unsubscribe();
  }
}
