import { C_Game } from "./game.model";

export class C_Fastbet {
  Name: string;
  NodeId: string;
  SportHandle: string;
  SportId: string;
  Games: C_Game[];

  constructor(Name: string, NodeId: string, SportHandle: string, SportId: string, Games?: C_Game[]) {
    this.Name = Name;
    this.NodeId = NodeId;
    this.SportHandle = SportHandle;
    this.SportId = SportId;
    this.Games = Games;
  }
}
