import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
} from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { Game } from "@models/lobby/casinoDataModels";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { Swiper, SwiperOptions } from "swiper";
import { CasinoBannerCard } from "../lobby/casino-banner-card/casino-banner-card.component";

@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "games-banner-slider",
  templateUrl: "./games-banner-slider.html",
  standalone: true,
  styleUrls: ["./games-banner-slider.scss"],
  imports: [CommonModule, IonicModule, CasinoBannerCard],
})
export class GamesBannerSlider implements AfterViewInit {
  @Input() games: Game[];
  @Output() turnPlay = new EventEmitter<any>();

  globalVars!: GlobalVarsModel;

  @ViewChild("swiperCSV") swiperRef:
    | ElementRef<HTMLElement & { swiper?: Swiper } & { initialize: () => void }>
    | undefined;
  @ViewChild("bullets") bullets: ElementRef<HTMLElement>;
  @ViewChild("btnprev") btnprev: ElementRef<HTMLElement>;
  @ViewChild("btnext") btnext: ElementRef<HTMLElement>;

  swiper?: Swiper;

  config: SwiperOptions;
  newBaseService = inject(NewBaseService);
  isDesktop: boolean;
  deviceService = inject(DeviceService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.isDesktop = this.deviceService.isDesktop();
  }

  ngAfterViewInit(): void {
    this.config = {
      loop: true,
      slidesPerView: "auto",
      autoplay: {
        delay: 5000,
      },
      navigation: {
        nextEl: this.isDesktop && this.games?.length > 1 ? this.btnext?.nativeElement : "swiper-button-next-csv",
        prevEl: this.isDesktop && this.games?.length > 1 ? this.btnprev?.nativeElement : "swiper-button-prev-csv",
        enabled: this.isDesktop && this.games?.length > 1,
      },
      pagination: {
        el: this.bullets?.nativeElement,
        type: "bullets",
        clickable: true,
      },
      observer: true,
    };
    if (this.swiperRef) {
      const swiperEl = Object.assign(this.swiperRef?.nativeElement, this.config);
      swiperEl.initialize();
      this.swiper = this.swiperRef?.nativeElement.swiper;
    }
  }

  playGame(event) {
    this.turnPlay.emit(event);
  }
}
