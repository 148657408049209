import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "cs-punto-pago",
  templateUrl: "./cs-punto-pago.html",
  styleUrls: ["./cs-punto-pago.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class CsPuntoPagoComponent {}
