/* eslint-disable */
import { TrueLayerActionResult } from "@models/C_Transactions";
import { ErrorService } from "@providers/ErrorService";
import { UserService } from "@providers/UserService";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PaymentMethodBehaviour, SkipAmountResult, TrueLayerMethodBehaviourResult } from "./PaymentMethodBase";

export class TrueLayerMethodBehaviour extends PaymentMethodBehaviour {
  name = "TrueLayer";
  image = "logo_truelayer";
  track = "AccessToTrueLayer";
  isBank = false;
  minDepositAmount = 10;
  maxDepositAmount = 100000;
  amountButtons: number[] = [10, 20, 40, 50];
  amount = 20;
  account = "";

  constructor(userService: UserService, errorService: ErrorService) {
    super(false, userService, errorService);
  }

  skipAmountInput(): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  paymentMetodExecution(): Observable<TrueLayerMethodBehaviourResult> {
    return this.userService
      .validateDepositTrueLayer({
        amount: this.amount,
        account_number: this.account,
      })
      .pipe(
        map((obj) => {
          return this.map(obj);
        }),
      );
  }

  private map(input: TrueLayerActionResult): TrueLayerMethodBehaviourResult {
    const result = new TrueLayerMethodBehaviourResult();
    if (input.success) {
      result.success = input.success;
      result.url = input.url;
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams(
        "error" + input.errCode,
        input.errorParameters,
      );
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode == 174 || input.errCode == 2212;
    }
    return result;
  }
}
