import { HttpHeaders } from "@angular/common/http";
/* eslint-disable eqeqeq */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { first, map, tap } from "rxjs/operators";

// Services
import { BaseService } from "../providers/BaseService";
import { CookiesService } from "../providers/CookiesService";
import { LogService } from "../providers/LogService";

// Models
import { CookieNames } from "../models/CookieNames";
import { LicenseTypes } from "../models/MasterData";
import { MexosCookieInfo } from "../models/MexosCookieInfo";
import { EventTypes } from "../models/TrackingEvents";
import { CasinoBalanceDetails } from "../models/csbgonlinecasinobonuses/Balance";
import { Bonus } from "../models/csbgonlinecasinobonuses/Bonus";
import { RequestSignInUser } from "../models/csbgonlinecasinobonuses/RequestSignInUser";
import { SignupIFOModel } from "../models/csbgonlinecasinobonuses/RequestSignInUser";
import { StateKeys, TriggerTypes } from "../models/csbgonlinecasinobonuses/bonus-enums";

import { Capacitor } from "@capacitor/core";
import { AlertController, AlertOptions, ModalController } from "@ionic/angular";
import { ExternalJS } from "../declarations/customInsights";
import { IWaitingBonusInfo } from "../pages/playtech-iframe/playtech-iframe";
import { PlaytechIframePage } from "../pages/playtech-iframe/playtech-iframe";
import { TrackingService } from "./TrackingService";

declare var _tfa: any;
declare var codereSharedJS: ExternalJS.codereSharedJS;
declare var _tfa: any;

// NOTE: the comment " // ` " at the end of lines solves a bug in Chrome Developer tools sources View

@Injectable({ providedIn: "root" })
export class SlotsBonusesService extends BaseService {
  baseUri: string;
  isMobile: boolean = Capacitor.getPlatform() !== "web";

  //#region
  logService = inject(LogService);
  cookiesService = inject(CookiesService);
  sanitizer = inject(DomSanitizer);
  translate = inject(TranslateService);
  trackingService = inject(TrackingService);
  modalController = inject(ModalController);
  alertCtrl = inject(AlertController);

  //#endregion

  constructor() {
    super();
    this.newBaseService.getVars.subscribe((_) => {
      this.baseUri = this.globalVars.URLBASE_CASINO_BONUSES;
    });
  }

  /**
   * If there is a "BonusSignupRequired" cookie, the user is signed up in the necessary game provider system,
   * for example, Nacional: IMS, Mexico: Iforium.
   * This cookie exists both if the user registered in this app, or in registro lite (Nacional, Mexico, check
   * the other registroLite from the other countries if necessary)
   */
  signupIfRequired = (): void => {
    this.logService.log("Enter SlotsBonusesService.signupIfRequired");
    if (!!this.cookiesService.getCookie(CookieNames.BonusSignupRequired) && !!this.globalVars.user.username) {
      this.trackingService.track({
        eventType: EventTypes.SignUpRequiredByCookie,
        id: this.globalVars.user.username,
      });
      this.signUp(true).subscribe(() => {
        this.cookiesService.removeCookie(CookieNames.BonusSignupRequired, true);
        this.trackingService.track({
          eventType: EventTypes.SignUpCookieRemoved,
          description: "Playtech IMS Signup succesful: cookie removed",
          id: this.globalVars.user.username,
        });
      });
    }
  };

  /** Depending on the license, notifies the bonus service that there is a deposit, and, if the user qualifies for
   * getting a deposit bonus, he is given information to accept or decline the bonus
   */
  // notifyDeposit = (transactionCode: string, isFirstDeposit: boolean): void => {
  //   //<!-- Taboola Pixel Code -->
  //   if (
  //     (this.globalVars.licenseType == LicenseTypes.Colombia ||
  //       this.globalVars.licenseType == LicenseTypes.ArgentinaCaba) &&
  //     isFirstDeposit
  //   ) {
  //     //_tfa.push({notify: 'event', name: 'Purchase', id: 1387336});
  //     this.taboolaTrack(this.globalVars.licenseType == LicenseTypes.Colombia ? 'Purchase' : 'Deposit');
  //   } else if (this.globalVars.licenseType == LicenseTypes.Colombia) {
  //     // _tfa.push({notify: 'event', name: 'SDeposit', id: 1387336});
  //     this.taboolaTrack('SDeposit');
  //   }

  //   if (this.globalVars.licenseType == LicenseTypes.Panama && isFirstDeposit) {
  //     this.taboolaTrack('FTD');
  //   }
  //   //<!-- End of Taboola Pixel Code -->

  //   if (this.globalVars.licenseType == LicenseTypes.ArgentinaCaba) {
  //     //<!-- Taboola Pixel Code -->
  //     this.taboolaTrack('Purchase');
  //     //<!-- End of Taboola Pixel Code -->

  //     if (isFirstDeposit && window['_adftrack']) {
  //       // AdForm Pixel Code for first deposit
  //       window['_adftrack'] = Array.isArray(window['_adftrack'])
  //         ? window['_adftrack']
  //         : window['_adftrack']
  //         ? [window['_adftrack']]
  //         : [];
  //       window['_adftrack'].push({
  //         HttpHost: 'track.adform.net',
  //         pm: 2326828,
  //         divider: encodeURIComponent('|'),
  //         pagename: encodeURIComponent('New Deposit'),
  //         order: {
  //           orderid: '<insert order id value here>'
  //         }
  //       });
  //       (function () {
  //         const s = document.createElement('script');
  //         s.type = 'text/javascript';
  //         s.async = true;
  //         s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js';
  //         const x = document.getElementsByTagName('script')[0];
  //         x.parentNode.insertBefore(s, x);
  //       })();
  //       // End of AdForm Pixel Code
  //     } else if (window['_adftrack']) {
  //       // AdForm Pixel Code for second and other deposits:
  //       window['_adftrack'] = Array.isArray(window['_adftrack'])
  //         ? window['_adftrack']
  //         : window['_adftrack']
  //         ? [window['_adftrack']]
  //         : [];
  //       window['_adftrack'].push({
  //         HttpHost: 'track.adform.net',
  //         pm: 2326828,
  //         divider: encodeURIComponent('|'),
  //         pagename: encodeURIComponent('Deposit'),
  //         order: {
  //           orderid: '<insert order id value here>'
  //         }
  //       });
  //       (function () {
  //         const s = document.createElement('script');
  //         s.type = 'text/javascript';
  //         s.async = true;
  //         s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js';
  //         const x = document.getElementsByTagName('script')[0];
  //         x.parentNode.insertBefore(s, x);
  //       })();
  //       // End of AdForm Pixel Code
  //     }
  //   }

  //   if (!this.globalVars.FEATURES.SenToPlaytechTriggerBonus) {
  //     switch (this.globalVars.licenseType) {
  //       case LicenseTypes.Nacional:
  //       case LicenseTypes.Colombia:
  //         // use playtech, and use playtechIframe so that the user can accept or decline
  //         const urlNac: string = `${this.baseUri}/Playtech/TriggerDepositBonus?&userName=${this.globalVars.user.username}&transactionCode=${transactionCode}&firstDeposit=${isFirstDeposit}`; // `
  //         this.myGet(urlNac, true).subscribe((bonuses) => {
  //           if (bonuses.length) {
  //             // This function checks if there are waiting messages, and, if so, opens a modal with the message,
  //             // and repeats a loop until there are no more waiting messages
  //             this.showBonusesWaitingMessages();
  //           }
  //         });
  //         break;

  //       case LicenseTypes.Mexico:
  //         if (isFirstDeposit) {
  //           const trackingData = { transaction: transactionCode, user: this.globalVars.user.username };
  //           this.trackingService.track({
  //             eventType: EventTypes.IforiumFDBCheckUserPromo,
  //             description: `First deposit ${transactionCode}: checking if player has FDB promo in Codere ID`, // `
  //             additionalData: trackingData
  //           });
  //           const htmltaboola =
  //             "<img src='https://trc.taboola.com/1363433/log/3/unip?en=FTD' width='0' height='0' style='display:none' />";
  //           document.querySelector('#taboolaFTD').innerHTML += htmltaboola;
  //           const url = `${this.baseUri}/iforium/GetUserActiveFdbPromo`; // `
  //           this.myGet(url).subscribe((promoCode: string | null) => {
  //             // `
  //             const msg = promoCode
  //               ? `Player has active FDB promo code ${promoCode} for 1st deposit ${transactionCode}` // `
  //               : `Player doesn't have FDB active promo code for 1st deposit ${transactionCode}`; // `
  //             this.trackingService.track({
  //               eventType: EventTypes.IforiumFDBCheckUserPromo,
  //               description: msg,
  //               additionalData: trackingData
  //             });
  //             if (promoCode) {
  //               // If the user has an FDB promotion code ...
  //               this.trackingService.track({
  //                 eventType: EventTypes.IforiumFDBUserHasPromo,
  //                 description: `Player has active FDB promo code ${promoCode} for 1st deposit ${transactionCode}`, // `
  //                 additionalData: trackingData
  //               });
  //               // ... starts the iforium first deposit bonus flow
  //               this.IforiumStartFirstDepositBonusFlow(transactionCode);
  //             } else {
  //               // The user doesn't have a promotion code
  //               this.trackingService.track({
  //                 eventType: EventTypes.IforiumFDBUserDoesntHavePromo,
  //                 description: `Player doesn't have FDB active promo code for 1st deposit ${transactionCode}`, // `
  //                 additionalData: trackingData
  //               });
  //             }
  //           });
  //         }
  //         break;
  //     }
  //   }
  // };

  /** Depending on the license, notifies the bonus service that there is a deposit, and, if the user qualifies for
   * getting a deposit bonus, he is given information to accept or decline the bonus
   */
  notifyDeposit = (transactionCode: string, isFirstDeposit: boolean): void => {
    //<!-- Taboola Pixel Code -->
    if (
      (this.globalVars.licenseType == LicenseTypes.Colombia ||
        this.globalVars.licenseType == LicenseTypes.ArgentinaCaba) &&
      isFirstDeposit
    ) {
      //_tfa.push({notify: 'event', name: 'Purchase', id: 1387336});
      this.taboolaTrack(this.globalVars.licenseType == LicenseTypes.Colombia ? "Purchase" : "Deposit");
    } else if (this.globalVars.licenseType == LicenseTypes.Colombia) {
      // _tfa.push({notify: 'event', name: 'SDeposit', id: 1387336});
      this.taboolaTrack("SDeposit");
    }

    if (this.globalVars.licenseType == LicenseTypes.Panama && isFirstDeposit) {
      this.taboolaTrack("FTD");
    }
    //<!-- End of Taboola Pixel Code -->

    if (this.globalVars.licenseType == LicenseTypes.ArgentinaCaba) {
      //<!-- Taboola Pixel Code -->
      this.taboolaTrack("Purchase");
      //<!-- End of Taboola Pixel Code -->

      if (isFirstDeposit && window["_adftrack"]) {
        // AdForm Pixel Code for first deposit
        window["_adftrack"] = Array.isArray(window["_adftrack"])
          ? window["_adftrack"]
          : window["_adftrack"]
            ? [window["_adftrack"]]
            : [];
        window["_adftrack"].push({
          HttpHost: "track.adform.net",
          pm: 2326828,
          divider: encodeURIComponent("|"),
          pagename: encodeURIComponent("New Deposit"),
          order: {
            orderid: "<insert order id value here>",
          },
        });
        (function () {
          var s = document.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://s2.adform.net/banners/scripts/st/trackpoint-async.js";
          var x = document.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        })();
        // End of AdForm Pixel Code
      } else if (window["_adftrack"]) {
        // AdForm Pixel Code for second and other deposits:
        window["_adftrack"] = Array.isArray(window["_adftrack"])
          ? window["_adftrack"]
          : window["_adftrack"]
            ? [window["_adftrack"]]
            : [];
        window["_adftrack"].push({
          HttpHost: "track.adform.net",
          pm: 2326828,
          divider: encodeURIComponent("|"),
          pagename: encodeURIComponent("Deposit"),
          order: {
            orderid: "<insert order id value here>",
          },
        });
        (function () {
          var s = document.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://s2.adform.net/banners/scripts/st/trackpoint-async.js";
          var x = document.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        })();
        // End of AdForm Pixel Code
      }
    }

    if (!this.globalVars.FEATURES.SenToPlaytechTriggerBonus) {
      switch (this.globalVars.licenseType) {
        case LicenseTypes.Nacional:
        case LicenseTypes.Colombia:
          // use playtech, and use playtechIframe so that the user can accept or decline
          const urlNac = `${this.baseUri}/Playtech/TriggerDepositBonus?&userName=${this.globalVars.user.username}&transactionCode=${transactionCode}&firstDeposit=${isFirstDeposit}`; // `
          this.myGet(urlNac, true).subscribe({
            next: (bonuses) => {
              if (bonuses.length) {
                const msg = this.translate.instant("casinoFirstDepositBonusOk");
                this.openPopUp(msg).then(() => {
                  // This function checks if there are waiting messages, and, if so, opens a modal with the message,
                  // and repeats a loop until there are no more waiting messages
                  this.showBonusesWaitingMessages();
                });
              }
            },
            error: (err) => {
              console.error(err);
              const msg = this.translate.instant("casinoFirstDepositBonusKo");
              this.openPopUp(msg);
            },
          });
          break;

        case LicenseTypes.Mexico:
          if (isFirstDeposit) {
            var trackingData = { transaction: transactionCode, user: this.globalVars.user.username };
            this.trackingService.track({
              eventType: EventTypes.IforiumFDBCheckUserPromo,
              description: `First deposit ${transactionCode}: checking if player has FDB promo in Codere ID`, // `
              additionalData: trackingData,
            });
            const htmltaboola =
              "<img src='https://trc.taboola.com/1363433/log/3/unip?en=FTD' width='0' height='0' style='display:none' />";
            document.querySelector("#taboolaFTD").innerHTML += htmltaboola;
            const url = `${this.baseUri}/iforium/GetUserActiveFdbPromo`; // `
            this.myGet(url).subscribe((promoCode: string | null) => {
              // `
              const msg = promoCode
                ? `Player has active FDB promo code ${promoCode} for 1st deposit ${transactionCode}` // `
                : `Player doesn't have FDB active promo code for 1st deposit ${transactionCode}`; // `
              this.trackingService.track({
                eventType: EventTypes.IforiumFDBCheckUserPromo,
                description: msg,
                additionalData: trackingData,
              });
              if (promoCode) {
                // If the user has an FDB promotion code ...
                this.trackingService.track({
                  eventType: EventTypes.IforiumFDBUserHasPromo,
                  description: `Player has active FDB promo code ${promoCode} for 1st deposit ${transactionCode}`, // `
                  additionalData: trackingData,
                });
                // ... starts the iforium first deposit bonus flow
                this.IforiumStartFirstDepositBonusFlow(transactionCode);
              } else {
                // The user doesn't have a promotion code
                this.trackingService.track({
                  eventType: EventTypes.IforiumFDBUserDoesntHavePromo,
                  description: `Player doesn't have FDB active promo code for 1st deposit ${transactionCode}`, // `
                  additionalData: trackingData,
                });
              }
            });
          }
          break;
      }
    }
  };

  /** If in Mexico, check if there is an Iforium promo cookie. If it's available, tries to store it in CodereID, and
   *  if so, deletes the cookie from the browser.
   *  When it's necessary to check if a user qualifies for an Iforium bonus, the promos are checked in Codere ID
   */
  storeIforiumPromoCookie = (): void => {
    if (this.globalVars.licenseType == LicenseTypes.Mexico) {
      // Starts the iforium bonus flow - only for first deposit bonus
      const iforiumPromoCookie = this.cookiesService.getCookie(CookieNames.IforiumPromo);
      if (iforiumPromoCookie) {
        const request = {
          userName: this.globalVars.user.username,
          cookie: iforiumPromoCookie,
        };

        const url = `${this.baseUri}/iforium/StorePromosFromCookie`; // `
        var headers = new HttpHeaders();
        this.myPost(url, request, headers, false, (err: any, _caught: Observable<any>): any => {
          this.logService.error(err);
        }).subscribe((data: "Handled" | "NotHandled") => {
          // if the cookie is stored, remove it from the browser
          if (data == "Handled") {
            this.cookiesService.removeCookie(CookieNames.IforiumPromo, true);
          }
        });
      }
    }
  };

  // TODO: separate Playtech and iforium services to each corresponding files, and keep here the general behaviors

  // ---------------------------------------------------------------------------------------------------------
  // Iforium FDB bonus flow, as defined in https://codere.visualstudio.com/cSBGOnline/_workitems/edit/66106

  /** Tries to redeem the Iforium Deposit Bonus, and receives a result indicating if the bonus has been given, or there is any problem
   * this is used along the flow.
   */
  private IforiumRedeemDepositPromotion = (
    transactionCode: string,
    isFirstDeposit: boolean,
    amount?: number,
  ): Observable<RedeemDepositPromotionResponse> => {
    let urlMx = `${this.baseUri}/Iforium/RedeemDepositPromotion?&userName=${this.globalVars.user.username}&transactionCode=${transactionCode}&firstDeposit=${isFirstDeposit}`; // `
    if (amount) {
      urlMx += `&amount=${amount}`;
    }
    return this.myGet(urlMx, true);
  };

  /** This is the starting point for Iforium bonuses flow, as defined in 66106 */
  private IforiumStartFirstDepositBonusFlow = (transactionCode: string): void => {
    var isFirstDeposit = true;

    // Recover the translation for UI texts which will be used to show messages to the user
    var fdbTexts = {
      title: this.translate.instant(TranslationKeys.ifrFdbAcceptDeclineTitle),
      message: this.translate.instant(TranslationKeys.ifrFdbAcceptDeclineMsg),
      acceptButton: this.translate.instant(TranslationKeys.ifrFdbAcceptDeclineAcceptBtn),
      declineButton: this.translate.instant(TranslationKeys.ifrFdbAcceptDeclineDeclineBtn),
    };
    var fdbMaxAmountTexts = {
      title: this.translate.instant(TranslationKeys.ifrFdbMaxAcceptDeclineTitle),
      messageTemplate: this.translate.instant(TranslationKeys.ifrFdbMaxAcceptDeclineMsgTemplate),
      acceptButton: this.translate.instant(TranslationKeys.ifrFdbMaxAcceptDeclineAcceptBtn),
      declineButton: this.translate.instant(TranslationKeys.ifrFdbMaxAcceptDeclineDeclineBtn),
    };
    var fdbMinAmountTexts = {
      title: this.translate.instant(TranslationKeys.ifrFdbMinAmountTitle),
      message: this.translate.instant(TranslationKeys.ifrFdbMinAmountMsg),
      acceptButton: this.translate.instant(TranslationKeys.ifrFdbMinAmountAcceptBtn),
    };
    var fdbGenericErrorTexts = {
      title: this.translate.instant(TranslationKeys.ifrFdbGenericErrorTitle),
      message: this.translate.instant(TranslationKeys.ifrFdbGenericErrorMessage),
      acceptButton: this.translate.instant(TranslationKeys.ifrFdbGenericErrorAcceptButton),
    };

    // Additional data to send to the tracking service:
    var trackingData = { transaction: transactionCode, user: this.globalVars.user.username };

    this.trackingService.track({
      eventType: EventTypes.IforiumFDBOffer,
      description: `Show FDB offer to user: ${fdbTexts.message}`, // `
      additionalData: trackingData,
    });

    // Include tabola div, avoid to send to the tracking service.
    const html =
      "<!-- Taboola Pixel Code -->  <img src='https://trc.taboola.com/1319873/log/3/unip?en=make_purchase' width = '0' height= '0' style= 'display:none'>";
    fdbTexts.message = fdbTexts.message + html;

    // Accept decline
    this.utils.confirm(
      false,
      fdbTexts.title,
      fdbTexts.message,
      fdbTexts.declineButton,
      () => {
        // Declined
        this.trackingService.track({
          eventType: EventTypes.IforiumFDBDeclined,
          description: `User declined FDB offering: ${fdbTexts.message}`, // `
          additionalData: trackingData,
        });
      },
      fdbTexts.acceptButton,
      () => {
        // Accepted
        this.trackingService.track({
          eventType: EventTypes.IforiumFDBAccepted,
          description: `User accepted FDB offering: ${fdbTexts.message}`, // `
          additionalData: trackingData,
        });
        this.IforiumRedeemDepositPromotion(transactionCode, isFirstDeposit).subscribe((response) => {
          switch (response.Result) {
            case DepositPromotionsResult.Created:
              // User acccepted and bonus was created
              this.trackingService.track({
                eventType: EventTypes.IforiumFDBRedeemed,
                description: `FDB redeemed to user`, // `
                additionalData: trackingData,
              });
              break;

            case DepositPromotionsResult.NoPromoAvailable:
              // There was no promo available for the user
              this.trackingService.track({
                eventType: EventTypes.IforiumFDBNoPromoAvailable,
                description: `User accepted FDB bonus, but he had no available promotions`, // `
                additionalData: trackingData,
              });
              break;

            case DepositPromotionsResult.ErrorMinAmount:
              // User accepted, but amount didn't reach the minimum
              this.trackingService.track({
                eventType: EventTypes.IforiumFDBLowerThaMinimum,
                description: `User accepted FDB bonus, but the deposit was lower than the minimum required amount`, // `
                additionalData: trackingData,
              });
              this.utils.alert(
                true,
                fdbMinAmountTexts.title,
                fdbMinAmountTexts.message,
                fdbMinAmountTexts.acceptButton,
              );
              break;

            case DepositPromotionsResult.ErrorMaxAmount:
              var maxAmount = response.Amount;
              // user accepted, necessary to redeem the bonus with a lower amount
              const msg = fdbMaxAmountTexts.messageTemplate.replace("{maxamount}", maxAmount.toString());
              this.trackingService.track({
                eventType: EventTypes.IforiumFDBHigherThanMaximum,
                description: `User accepted FDB bonus, but his deposit was higher than maximum bonus amount. Offering: ${msg}`, // `
                additionalData: trackingData,
              });
              // Offer the user to redeem the bonus with lower amount
              this.utils.confirm(
                false,
                fdbMaxAmountTexts.title,
                msg,
                fdbMaxAmountTexts.declineButton,
                () => {
                  this.trackingService.track({
                    eventType: EventTypes.IforiumFDBHigherThanMaximumDeclined,
                    description: `User declined FDB bonus after offering maximum bonus amount ${maxAmount}: ${msg}`, // `
                    additionalData: trackingData,
                  });
                },
                fdbMaxAmountTexts.acceptButton,
                () => {
                  this.trackingService.track({
                    eventType: EventTypes.IforiumFDBHigherThanMaximumAccepted,
                    description: `User accepted FDB bonus after offering maximum bonus amount ${maxAmount}: ${msg}`, // `
                    additionalData: trackingData,
                  });
                  this.IforiumRedeemDepositPromotion(transactionCode, isFirstDeposit, maxAmount).subscribe(
                    (responsex) => {
                      if (responsex.Result == DepositPromotionsResult.Created) {
                        this.trackingService.track({
                          eventType: EventTypes.IforiumFDBRedeemed,
                          description: `FDB redeemed to user after accepting max bonus amount`, // `
                          additionalData: trackingData,
                        });
                      } else {
                        this.trackingService.track({
                          eventType: EventTypes.IforiumFDBFailure,
                          description: `User accepted max maount FDB bonus, but bonus was not redeemed`, // `
                          additionalData: trackingData,
                        });
                      }
                    },
                  );
                },
                false,
              );
              break;

            case DepositPromotionsResult.OtherErrors:
              this.utils.alert(
                true,
                fdbGenericErrorTexts.title,
                fdbGenericErrorTexts.message,
                fdbGenericErrorTexts.acceptButton,
              );
              this.trackingService.track({
                eventType: EventTypes.IforiumFDBFailure,
                description: `User accpeted bonus, but there was an unexpected error trying to redeem it`, // `
                additionalData: trackingData,
              });
              break;
          }
        });
      },
      false,
    );
  };

  // ------------------ FIN DE MEXICO (Iforium bonuses) --------------------

  /**
   * Gets a list of bonuses assigned to the current user
   */
  getPlayerBonuses = (): Observable<Bonus[]> => {
    if (
      this.globalVars.user.logged &&
      this.globalVars.FEATURES.CasinoBonusEnabled &&
      this.globalVars.FEATURES.Casino_EnabledMultiProviderBonuses
    ) {
      const url = `${this.baseUri}/api/bonus/SearchPlayerBonuses?mobile=${this.isMobile}&userName=${this.globalVars.user.username}`;
      return this.myGet(url, true, (err: any, caught: Observable<any>): any => {
        this.logService.error(err);
      });
    } else if (
      this.globalVars.user.logged &&
      this.globalVars.FEATURES.CasinoBonusEnabled &&
      this.globalVars.licenseType !== LicenseTypes.Panama &&
      !this.globalVars.FEATURES.Casino_EnabledMultiProviderBonuses
    ) {
      const url = `${this.baseUri}/playtech/SearchPlayerBonuses?mobile=${this.isMobile}&userName=${this.globalVars.user.username}`;
      return this.myGet(url, true, (err: any, caught: Observable<any>): any => {
        this.logService.error(err);
      });
    } else if (
      this.globalVars.user.logged &&
      this.globalVars.FEATURES.CasinoBonusEnabled &&
      this.globalVars.licenseType === LicenseTypes.Panama &&
      !this.globalVars.FEATURES.Casino_EnabledMultiProviderBonuses
    ) {
      const url = `${this.baseUri}/Habanero/SearchPlayerBonuses?mobile=${this.isMobile}&userName=${this.globalVars.user.username}`;

      return this.myGet(url, true, (err: any, caught: Observable<any>): any => {
        this.logService.error(err);
      });
    } else {
      return of([]);
    }
    //old Mexico
    // else if (this.globalVars.licenseType === LicenseTypes.Mexico) {
    //     let url: string = `${this.baseUri}/iforium/SearchPlayerBonuses?mobile=${this.globalVars.isMobile}&userName=${this.globalVars.user.username}`; // `
    //     return this.myGet(url, true, (err: any, caught: Observable<any>): any => { this.logService.error(err); });
    // }
  };
  /**
   * Gets a list of available bonuses for the current user
   */
  // getPlayerAvailableBonuses = (): Observable<Bonus[]> => {
  //   if (!this.globalVars.user.logged) return of([]);

  //   if (this.globalVars.AvailableBonuses) return of(this.globalVars.AvailableBonuses);

  //   const url: string = `${this.baseUri}/playtech/SearchAvailableBonuses?mobile=${this.isMobile}&userName=${this.globalVars.user.username}`; // `
  //   return this.myGet(url, true, (err: any, caught: Observable<any>): any => {
  //     this.logService.error(err);
  //   }).pipe(
  //     tap((data) => {
  //       this.globalVars.AvailableBonuses = data;
  //     })
  //   );
  // };
  /**
   * Gets a list of available bonuses for the current user
   */
  getPlayerAvailableBonuses = (): Observable<Bonus[]> => {
    if (!this.globalVars.user.logged) return of([]);

    if (this.globalVars.AvailableBonuses) return of(this.globalVars.AvailableBonuses);

    const url = `${this.baseUri}/playtech/SearchAvailableBonuses?mobile=${this.isMobile}&userName=${this.globalVars.user.username}`; // `

    return this.myGet(url, true, (err: any, caught: Observable<any>): any => {
      this.logService.error(err);
    }).pipe(
      tap((data) => {
        this.globalVars.AvailableBonuses = data;
      }),
    );
  };

  getUserBonusCount(user: string, mobile: boolean): Observable<any> {
    const url = `${this.baseUri}/home/GetMBonuses?userId=${user}&mobile=${!mobile}`; // `
    return this.myGet(url, true, (err: any, caught: Observable<any>): any => {
      this.logService.error(err);
    }).pipe(map((count) => parseInt(count)));
  }

  /**
   * Returns the iframe url that shows the bonus info detail for the logged user
   * @param Bonus Bonus fo which details are requested
   */
  getUserBonusDetailIframeUrl(bonus: Bonus): SafeResourceUrl {
    if (!this.globalVars.user.logged) return null;
    let url;
    if (this.globalVars.FEATURES.Casino_EnabledMultiProviderBonuses) {
      url = `${this.baseUri}/bonusdetailiframe?bonusId=${bonus.BonusInstanceCode}&userName=${this.globalVars.user.username}&mobile=${this.isMobile}&providerId=${bonus.ProviderId}`;
    } else {
      url = `${this.baseUri}/bonusdetailiframe?bonusId=${bonus.BonusInstanceCode}&userName=${this.globalVars.user.username}&mobile=${this.isMobile}`;
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  notifyBalanceChange(): Observable<any> {
    if (!this.globalVars.FEATURES.NotifyBalanceChange) return of();

    const url = `${this.baseUri}/home/NotifyBalanceChange`; // `
    return this.myGet(url, false);
  }

  getCasinoBalanceDetails(): Observable<CasinoBalanceDetails> {
    if (
      (this.globalVars.licenseType == LicenseTypes.Nacional ||
        this.globalVars.licenseType === LicenseTypes.Colombia ||
        this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) &&
      this.globalVars.FEATURES.balanceCasinoDetails
    ) {
      const url = `${this.baseUri}/playtech/GetCasinoBalanceDetails?userName=${this.globalVars.user.username}`; // `
      return this.myGet(url, true, (err: any, caught: Observable<any>): any => {
        this.logService.error(err);
      });
    } else if (
      this.globalVars.licenseType == LicenseTypes.Panama &&
      this.globalVars.FEATURES.balanceCasinoDetails &&
      !this.globalVars.FEATURES.Casino_EnabledMultiProviderBonuses
    ) {
      const url = `${this.baseUri}/habanero/GetCasinoBalanceDetails?userName=${this.globalVars.user.username}`; // `
      return this.myGet(url, true, (err: any, caught: Observable<any>): any => {
        this.logService.error(err);
      });
    } else if (
      this.globalVars.licenseType == LicenseTypes.Panama &&
      this.globalVars.FEATURES.balanceCasinoDetails &&
      this.globalVars.FEATURES.Casino_EnabledMultiProviderBonuses
    ) {
      const url = `${this.baseUri}/api/bonus/GetCasinoBalanceDetails?userName=${this.globalVars.user.username}`; // `
      return this.myGet(url, true, (err: any, caught: Observable<any>): any => {
        this.logService.error(err);
      });
    }
  }

  /** This register the user on the game provider backoffice (Nacional: IMS, Mexico: Iforium) */
  private signUp(showLoader?: boolean): Observable<void> {
    this.logService.log("Enter SlotsBonusesService.signUp");
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Nacional:
      case LicenseTypes.Colombia:
        codereSharedJS.mexos.getMexosInternalCookieInfo((mexosInternalCookieInfo: MexosCookieInfo) => {
          this.signInUserNacional(mexosInternalCookieInfo, showLoader).pipe(first()).subscribe();
        });
        return of();
      case LicenseTypes.Mexico:
        return this.signInUserMX(showLoader);
      default:
        return of();
    }
  }

  /** Register the user in IMS (Nacional) */
  // private signInUserNacional = (mexosCookieInfo: MexosCookieInfo, showLoader: boolean): Observable<void> => {
  //   const request: RequestSignInUser = {
  //     userName: this.globalVars.user.username,
  //     mobile: this.isMobile,
  //     advertiser: mexosCookieInfo ? mexosCookieInfo.advertiser : '',
  //     bannerId: mexosCookieInfo ? mexosCookieInfo.bannerId : '',
  //     profileId: mexosCookieInfo ? mexosCookieInfo.profileId : '',
  //     crefererItems: {
  //       BTAG: mexosCookieInfo ? mexosCookieInfo.btagInfo.BTAG : '',
  //       ID2: mexosCookieInfo ? mexosCookieInfo.btagInfo.ID2 : '',
  //       ID3: mexosCookieInfo ? mexosCookieInfo.btagInfo.ID3 : ''
  //     }
  //   };

  //   const url: string = `${this.baseUri}/playtech/SignInUser`; // `
  //   const headers = new HttpHeaders();
  //   return this.myPost(url, request, headers, !showLoader, (err: any, _caught: Observable<any>): any => {
  //     this.logService.error(err);
  //   });
  // };

  /** Register the user in IMS (Nacional) */
  private signInUserNacional = (mexosCookieInfo: MexosCookieInfo, showLoader: boolean): Observable<void> => {
    const request: RequestSignInUser = {
      userName: this.globalVars.user.username,
      mobile: this.isMobile,
      advertiser: mexosCookieInfo ? mexosCookieInfo.advertiser : "",
      bannerId: mexosCookieInfo ? mexosCookieInfo.bannerId : "",
      profileId: mexosCookieInfo ? mexosCookieInfo.profileId : "",
      crefererItems: {
        BTAG: mexosCookieInfo ? mexosCookieInfo.btagInfo.BTAG : "",
        ID2: mexosCookieInfo ? mexosCookieInfo.btagInfo.ID2 : "",
        ID3: mexosCookieInfo ? mexosCookieInfo.btagInfo.ID3 : "",
      },
    };

    const url = `${this.baseUri}/playtech/SignInUser`; // `
    var headers = new HttpHeaders();
    return this.myPost(url, request, headers, !showLoader, (err: any, _caught: Observable<any>): any => {
      this.logService.error(err);
    });
  };

  /** Register the user in Iforium (Mexico) */
  private signInUserMX = (showLoader: boolean): Observable<void> => {
    const request: SignupIFOModel = {
      userName: this.globalVars.user.username,
      mobile: this.isMobile,
    };

    const url = `${this.baseUri}/iforium/SignInUser`; // `
    const headers = new HttpHeaders();
    return this.myPost(url, request, headers, !showLoader, (err: any, _caught: Observable<any>): any => {
      this.logService.error(err);
    });
  };

  /** Fro Nacional (IMS): checks if there are bonus in waiting status, and, if so, shows the PlaytechIFramePage which includes an
   * iframe which uses IMS JavaScript API to show the corresponding waiting messages.
   * The iframe waits until the user accepts or declines the bonus, and then calls this function again, so this function is called
   * recursively until there are no more bonuses in waiting status (this recurrence is broken in this function)
   */
  public showBonusesWaitingMessages(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getPlayerBonuses().subscribe(
        (bonuses: Bonus[]) => {
          const validBonuses: Bonus[] = bonuses.filter(
            (bonus) => !!bonus.ProviderId && bonus.StateKey == StateKeys.Waiting,
          );
          // This condition breaks the recursive call when there are no more bonuses in waiting status
          if (validBonuses.length > 0) {
            // TODO ELIMINAR : descomentar cuando esté diponible IWaitingBonusInfo
            const waitingBonuses: IWaitingBonusInfo[] = validBonuses.map<IWaitingBonusInfo>((vb) => {
              const wbi: IWaitingBonusInfo = {
                marketingName: vb.MarketingName,
                name: vb.BonusName,
                triggerType: vb.TriggerType,
                triggerTypeName: TriggerTypes[vb.TriggerType],
                bonusInstanceCode: vb.BonusInstanceCode,
                RemoveUrl: vb.RemoveUrl,
              };
              return wbi;
            });
            this.trackingService.track({
              eventType: EventTypes.CasinoBonusShowWaitingMessages,
              description: "Show playtechiframe because there are waiting bonuses (see additional data)",
              additionalData: waitingBonuses,
            });
            this.openIframe(waitingBonuses);
            resolve(false);
          } else {
            resolve(true);
          }
        },
        (err) => {
          console.info("migration:" + err);
          reject(err);
        },
      );
    });
  }

  async openIframe(bonus) {
    const modalConfig = await this.modalController.create({
      component: PlaytechIframePage,
      componentProps: {
        waitingBonuses: bonus,
      },
    });
    await modalConfig.present();
  }

  /** This is used to cancel the sport bonus (this is done in Nacional if the user accepts a casino bonus) */
  public cancelSportBonusIfNeeded = (acceptedBonusInstanceCode: number): Observable<any> => {
    const url = `${this.baseUri}/playtech/cancelSportBonusIfNeeded?acceptedBonusInstanceCode=${acceptedBonusInstanceCode}`; // `
    return this.myGet(url, true);
  };

  public taboolaTrack(name: string) {
    let id = 0;
    switch (this.globalVars.licenseType) {
      case 33:
        id = 1379009;
        break;
      case 21:
        id = 1387336;
        break;
      case 22:
        id = 1504070;
        break;
    }
    _tfa.push({ notify: "event", name, id });
  }
  public cancelBonusCasino = (bonusId: string, userName: string, provider: string): Observable<any> => {
    const url = `${this.baseUri}${provider}/removeplayerbonus?bonusInstanceId=${bonusId}&userName=${userName}`;
    return this.myGet(url, true);
  };

  async openPopUp(msg: string): Promise<any> {
    const alertOpt: AlertOptions = {
      message: "",
      buttons: [
        {
          text: this.translate.instant("tCont"),
        },
      ],
    };
    const alert = await this.alertCtrl.create(alertOpt);
    const text = alert.getElementsByClassName("alert-message")[0];
    text.innerHTML = msg;
    await alert.present();
    return alert.onDidDismiss();
  }
}
/** Possible outcomes from attempts to redeem Iforium FDB bonus (as returned by the service API) */
export enum DepositPromotionsResult {
  /** The deposit bonus was created */
  Created,

  /** The deposit amount was lower than the minimum */
  ErrorMinAmount,

  /** The deposit amount was higher than the maximum */
  ErrorMaxAmount,

  /** There was no promotion available for the user in Codere ID */
  NoPromoAvailable,

  /** Other possible errors, which doesn't have a particular treatment */
  OtherErrors,
}

/** Data returned by the redeem bonus web service API call */
export interface RedeemDepositPromotionResponse {
  Result: DepositPromotionsResult;
  Amount: number;
}

/** Keys of messages and texts in DynTemplateGen, for Iforium First Deposit Bonus flow */
enum TranslationKeys {
  // Accept decline message: tile, message, accept btn, decline btn
  ifrFdbAcceptDeclineTitle = "ifrFdbAcceptDeclineTitle",
  ifrFdbAcceptDeclineMsg = "ifrFdbAcceptDeclineMsg",
  ifrFdbAcceptDeclineAcceptBtn = "ifrFdbAcceptDeclineAcceptBtn",
  ifrFdbAcceptDeclineDeclineBtn = "ifrFdbAcceptDeclineDeclineBtn",

  // Min amoun notification
  ifrFdbMinAmountTitle = "ifrFdbMinAmountTitle",
  ifrFdbMinAmountMsg = "ifrFdbMinAmountMsg",
  ifrFdbMinAmountAcceptBtn = "ifrFdbMinAmountAcceptBtn",

  // Accept decline max amount message: tile, message, accept btn, decline btn
  ifrFdbMaxAcceptDeclineTitle = "ifrFdbMaxAcceptDeclineTitle",
  ifrFdbMaxAcceptDeclineMsgTemplate = "ifrFdbMaxAcceptDeclineMsgTemplate",
  ifrFdbMaxAcceptDeclineAcceptBtn = "ifrFdbMaxAcceptDeclineAcceptBtn",
  ifrFdbMaxAcceptDeclineDeclineBtn = "ifrFdbMaxAcceptDeclineDeclineBtn",

  // Generic error message
  ifrFdbGenericErrorTitle = "ifrFdbGenericErrorTitle",
  ifrFdbGenericErrorMessage = "ifrFdbGenericErrorMessage",
  ifrFdbGenericErrorAcceptButton = "ifrFdbGenericErrorAcceptButton",
}
