import { Component, EventEmitter, Input, OnInit, Output, inject } from "@angular/core";
import { AlertController, IonicModule, ModalController, NavController, NavParams } from "@ionic/angular";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, combineLatest, forkJoin, of } from "rxjs";

import { EventsService } from "@providers/EventsService";
import { UserService } from "@providers/UserService";
import { PaymentsCashierService } from "@providers/payments/PaymentsCashierService";
//Services
import { Utils } from "@utils/Utils";

import { CommonModule } from "@angular/common";
import { Capacitor } from "@capacitor/core";
import { CsHistoryItemComponent } from "@components/payments/cs-history-item/cs-history-item";
import { LicenseTypes } from "@models/MasterData";
//Others
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { CsFilterHistory } from "@models/payments/cs-filter-history";
import { CsFilterHistoryOption } from "@models/payments/cs-filter-history-option";
import { HistoryItemFields } from "@models/payments/history-item.model";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { CashierHistoryDetailPage } from "../cashier-history-detail/cashier-history-detail";

@Component({
  selector: "page-cashier-history",
  templateUrl: "./cashier-history.html",
  styleUrls: ["./cashier-history.scss"],
  standalone: true,
  imports: [IonicModule, TranslateModule, CommonModule, CsHistoryItemComponent],
})
export class CashierHistoryPage implements OnInit {
  @Output() filterSelected: EventEmitter<any> = new EventEmitter();
  @Output() trackingEvent: EventEmitter<any[]> = new EventEmitter();
  @Input() myActivityFilter: any = null;

  allHistory$: Observable<any>;
  filterHistory$: Observable<any>;
  deposits$: Observable<any>;
  withdrawals$: Observable<any>;
  historyFields: HistoryItemFields;

  days$: BehaviorSubject<any>;
  type$: BehaviorSubject<any>;
  state$: BehaviorSubject<any>;

  days: any;
  type: any;
  state: any;

  filtersDates: CsFilterHistory;
  filtersTypes: CsFilterHistory;
  filtersStates: CsFilterHistory;
  loaded: boolean = false;

  startDate: Date;
  endDate: Date;
  filterDates: string;
  globalVars!: GlobalVarsModel;
  isDesktop = false;
  isMobile = false;

  public navCtrl = inject(NavController);
  public navParams = inject(NavParams);
  public modalCtrl = inject(ModalController);
  public alertCtrl = inject(AlertController);
  public translate = inject(TranslateService);
  public events = inject(EventsService);
  private paymentsService = inject(PaymentsCashierService);
  public userService = inject(UserService);
  public utils = inject(Utils);
  private newBaseService = inject(NewBaseService);
  public deviceService = inject(DeviceService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isDesktop = Capacitor.getPlatform() === "web";
    this.isMobile = Capacitor.getPlatform() === "mobile";
    if (this.globalVars.FEATURES.myactivity) {
      this.filtersDates = {
        title: this.translate.instant("hDate"),
        options: [
          { label: this.translate.instant("histapu90d"), value: "90", type: "radio", checked: true },
          { label: this.translate.instant("last30days"), value: "30", type: "radio", checked: false },
          { label: this.translate.instant("histapu15d"), value: "15", type: "radio", checked: false },
          { label: this.translate.instant("histapu7d"), value: "7", type: "radio", checked: false },
          { label: this.translate.instant("histapu2d"), value: "2", type: "radio", checked: false },
        ],
      };
    } else {
      this.filtersDates = {
        title: this.translate.instant("hDate"),
        options: [
          {
            label: this.translate.instant("histapu90d"),
            value: "90",
            type: "radio",
            checked: true,
          },
          {
            label: this.translate.instant("last30days"), //TODO CHECK THIS1
            value: "30", //TODO CHECK THIS1
            type: "radio",
            checked: false,
          },
          {
            label: this.translate.instant("histapu15d"),
            value: "15",
            type: "radio",
            checked: false,
          },
          {
            label: this.translate.instant("histapu7d"),
            value: "7",
            type: "radio",
            checked: false,
          },
          {
            label: this.translate.instant("histapu2d"),
            value: "2",
            type: "radio",
            checked: false,
          },
        ],
      };
    }
    this.filtersTypes = {
      title: this.translate.instant("uPPExtractTip"),
      options: [
        { label: this.translate.instant("histaptodas"), value: "all", type: "radio", checked: true },
        { label: this.translate.instant("Depósito"), value: "deposit", type: "radio", checked: false },
        { label: this.translate.instant("Cobro"), value: "withdrawals", type: "radio", checked: false },
      ],
    };
    this.filtersStates = {
      title: this.translate.instant("hStade"),
      options: [
        { label: this.translate.instant("histaptodas"), value: "all", type: "radio", checked: true },
        { label: this.translate.instant("complet"), value: "Completada", type: "radio", checked: false },
        { label: this.translate.instant("pending"), value: "Pendiente", type: "radio", checked: false },
        { label: this.translate.instant("cancel"), value: "Cancelada", type: "radio", checked: false },
      ],
    };
  }

  ngOnInit(): void {
    if (this.myActivityFilter) {
      this.filtersDates.options.forEach((f) => {
        f.checked = this.myActivityFilter.type == f.value;
      });
      this.filtersTypes.options[0].checked = false;
      this.filtersTypes.options[1].checked = true;
      this.filtersStates.options[0].checked = false;
      this.filtersStates.options[1].checked = true;
    }
    this.days$ = new BehaviorSubject(this.filtersDates.options.find((filter) => filter.checked));
    this.type$ = new BehaviorSubject(this.filtersTypes.options.find((filter) => filter.checked));
    this.state$ = new BehaviorSubject(this.filtersStates.options.find((filter) => filter.checked));

    this.historyFields = {
      Fecha: true,
      Cantidad: true,
      Metodo: true,
      Estado: true,
      Tipo: true,
      Referencia: this.deviceService.isDesktop() ? true : false,
      Cuenta: this.deviceService.isDesktop() ? true : false,
      RetainedAmount:
        this.globalVars.licenseType == LicenseTypes.Panama || this.globalVars.licenseType == LicenseTypes.Colombia,
    };

    this.getHistory();
  }

  ngAfterViewInit() {
    this.trackingEvent.emit(["AccessToCashiertHistory", "", "", "Acceder historial depositos", "event"]);
  }

  rangeDate(days) {
    if (days == 0) {
      days = "90";
    }
    days.toString();
    switch (days) {
      case "2":
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 2);
        this.endDate = new Date();
        break;
      case "7":
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 7);
        this.endDate = new Date();
        break;
      case "15":
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 15);
        this.endDate = new Date();
        break;
      case "30":
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 30);
        this.endDate = new Date();
        break;
      case "lastmonth":
        this.startDate = new Date();
        this.startDate.setDate(1);
        this.startDate.setMonth(this.startDate.getMonth() - 1);
        this.endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 1, 0);
        break;
      case "90":
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 90);
        this.endDate = new Date();
        break;
    }
    const formatStart = this.formatDate(this.startDate);
    const formatEnd = this.formatDate(this.endDate);
    this.filterDates = `startdate=${formatStart}&endDate=${formatEnd}`;
  }

  formatDate(datetoFormat: Date) {
    let day = "";
    if (datetoFormat.getDate() < 10) {
      day = "0" + datetoFormat.getDate().toString();
    } else {
      day = datetoFormat.getDate().toString();
    }
    let month = "";
    if (datetoFormat.getMonth() + 1 < 10) {
      month = "0" + (datetoFormat.getMonth() + 1).toString();
    } else {
      month = (datetoFormat.getMonth() + 1).toString();
    }
    return `${datetoFormat.getFullYear()}-${month}-${day}`;
  }

  getHistory() {
    this.allHistory$ = combineLatest(
      this.days$,
      this.state$,
      this.type$,
      (days: CsFilterHistoryOption, state: CsFilterHistoryOption, type: CsFilterHistoryOption) => {
        this.rangeDate(days.value);
        const allHistory = [];
        const getDepositsData =
          type.value !== "withdrawals"
            ? this.userService.getDepositsWallet(state.value, this.filterDates)
            : of([]);
        const getPaymentsData =
          type.value !== "deposit" ? this.userService.getPaymentsWallet(state.value, this.filterDates) : of([]);
        const joinHistory = forkJoin([getDepositsData, getPaymentsData]).subscribe(([deposits, withdrawals]) => {
          if (deposits.length > 0) {
            deposits.map((depo) => {
              depo.DepositPayments.map((deposit: any) => {
                deposit.Tipo = "Deposito";
                if (deposit.Estado != "Pendiente") {
                  allHistory.push(deposit);
                }
              });
            });
          }
          if (withdrawals.length > 0) {
            withdrawals.map((withdwl) => {
              withdwl.DepositPayments.map((withdrawal: any) => {
                withdrawal.Tipo = "Cobro";
                allHistory.push(withdrawal);
              });
            });
          }

          allHistory.sort((a, b) => {
            if (a.Fecha < b.Fecha) {
              return 1;
            }
            if (a.Fecha > b.Fecha) {
              return -1;
            }
            return 0;
          });
          this.loaded = true;
        });
        return allHistory;
      },
    );
  }

  async navigate(historyItem) {
    // todo: revisar funcionamiento
    /* let modal = await this.modalCtrl.create(CashierHistoryDetailPage, historyItem); */
    const modal = await this.modalCtrl.create({
      component: CashierHistoryDetailPage,
      componentProps: {
        historyItem,
      },
      id: "cashier-history-detail-modal",
    });
    modal.present();
    // todo: revisar funcionamiento
    /* modal.onDidDismiss(historyItem => {
      if (!!historyItem) {
        this.showCancelPendingAlert(historyItem);
      }
    }); */
    modal.onDidDismiss().then(() => {
      if (!historyItem) {
        this.showCancelPendingAlert(historyItem);
      }
    });
  }

  async setFilter(filter: CsFilterHistory) {
    const footer = await this.utils.createARFooter();
    this.loaded = false;
    const filterAlert = await this.alertCtrl.create({
      header: filter.title,
      cssClass: "sb-alert payments filterCashierHistory",
      backdropDismiss: true,
      buttons: [
        {
          text: this.translate.instant("tCancel"),
          role: "cancel",
        },
        {
          text: this.translate.instant("ifrFdbMinAmountAcceptBtn"),
          handler: (data) => {
            if (this.filterCtr(filter, data)) return;
            this.updateFilters(filter.title, data);
          },
          role: "",
        },
      ],
      // todo: revisar inputs
      inputs: filter.options,
    });

    await filterAlert.present();

    if (this.utils.isArgentinaCABA()) {
      // Agregar el pie de página después de que la alerta se haya presentado
      const ionAlert = await this.alertCtrl.getTop();
      if (ionAlert) {
        const alertElement = ionAlert.querySelector(".alert-wrapper");
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }
  }

  filterCtr(filterClicked, data) {
    const currentFilter = filterClicked.options.find((filter: CsFilterHistoryOption) => filter.checked);
    return currentFilter.value === data;
  }

  updateFilters(option, data) {
    this.filterSelected.emit();
    switch (option) {
      case this.translate.instant("hDate"):
        this.setCheckedFilter(this.filtersDates.options, data);
        this.days$.next(this.filtersDates.options.find((filter: CsFilterHistoryOption) => filter.checked));
        break;
      case this.translate.instant("uPPExtractTip"):
        this.setCheckedFilter(this.filtersTypes.options, data);
        this.type$.next(this.filtersTypes.options.find((filter: CsFilterHistoryOption) => filter.checked));
        break;
      case this.translate.instant("hStade"):
        this.setCheckedFilter(this.filtersStates.options, data);
        this.state$.next(this.filtersStates.options.find((filter: CsFilterHistoryOption) => filter.checked));
        break;
    }
  }

  setCheckedFilter(filter: CsFilterHistoryOption[], data: string) {
    filter.forEach((filterData) =>
      filterData.value === data ? (filterData.checked = true) : (filterData.checked = false),
    );
  }

  async showCancelPendingAlert(payment: any) {
    const footer = await this.utils.createARFooter();
    const cancelAlert = await this.alertCtrl.create({
      header: this.translate.instant("confirm"),
      message: `¿Estás seguro de cancelar el ${payment.Tipo} pendiente?`,
      cssClass: "sb-alert payments",
      backdropDismiss: true,
      buttons: [
        {
          text: this.translate.instant("rN"),
          role: "cancel",
        },
        {
          text: this.translate.instant("rY"),
          handler: () => {
            this.cancelPendingPayment(payment);
          },
          role: "",
        },
      ],
    });
    await cancelAlert.present();

    if (this.utils.isArgentinaCABA()) {
      // Agregar el pie de página después de que la alerta se haya presentado
      const ionAlert = await this.alertCtrl.getTop();
      if (ionAlert) {
        const alertElement = ionAlert.querySelector(".alert-wrapper");
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }
  }

  cancelPendingPayment(payment) {
    this.loaded = false;
    this.paymentsService.cancelPendingPayment(payment).subscribe(
      (data) => {
        this.updateFilters(this.filtersStates.title, "all");
      },
      (error) => {},
    );
  }
}
