import { LineItem } from "../classes/line-item.model";

export interface StraightMulticastInput {
  Sport: {
    Id: number;
    SportHandle: string;
  };
  Location: {
    Id: number;
  };
  League: {
    Id: number;
  };
  Event: {
    Id: number;
    HasHandicap: boolean;
  };
  PlacementSource: string;
  ClientData: string;
  MaxSystem: number;
  Results: [
    {
      ResultId: number;
      Places: number[];
    },
  ];
  TaxSettings: string;
  Game: {
    Id: number;
    GameTypeId: number;
    SpecialOddsValue: string;
    IsInPlay: boolean;
    BetTypeAvailability: number;
    NumberOfStarters: number;
    PlaceOddsDivisor: number;
    IncludedPlaces: number;
  };
}

export interface StraightMulticastItem {
  sport: {
    id: number;
    sportHandle: string;
  };
  location: {
    id: number;
  };
  league: {
    id: number;
  };
  event: {
    id: number;
    hasHandicap: boolean;
  };
  placementSource: string;
  clientData: string;
  maxSystem: number;
  resultIds: number[];
  game: {
    id: number;
    gameTypeId: number;
    specialOddsValue: string;
    isInPlay: boolean;
    betTypeAvailability: number;
    numberOfStarters: number;
    placeOddsDivisor: number;
    includedPlaces: number;
  };
  odds: number;
  itemId: number;
  isBanker: boolean;
  canBeBanker: boolean;
}

export const mapStraightMulticastResult = (straightData, arrResults): StraightMulticastInput => ({
  Sport: straightData.Sport,
  Location: straightData.Location,
  League: straightData.League,
  Event: straightData.Event,
  PlacementSource: "", // ??
  ClientData: "", // ??
  MaxSystem: straightData.Magnitude == 2 ? 3 : 1, // defaults: Forecast=3, Tricast=1, Others=No Limit
  Results: arrResults,
  TaxSettings: "",
  Game: {
    Id: straightData.Game.Id,
    GameTypeId: straightData.Game.GameTypeId,
    SpecialOddsValue: straightData.Game.GameSpecialOddsValue, // ??
    IsInPlay: false, // toCheck
    BetTypeAvailability: straightData.Game.GameBetTypeAvailability, // ??
    NumberOfStarters: straightData.Game.NumberOfStarters,
    PlaceOddsDivisor: 0, // ??
    IncludedPlaces: 0, // ??
  },
});

export const createLineItemFromRace = (raceData, sortedArray): LineItem => {
  const milliseconds = raceData.StartDate.replace("/Date(", "").replace(")/", "");
  const date = new Date(+milliseconds);
  return {
    Amount: raceData.Amount || 0,
    AmountLocal: raceData.AmountLocal || 0,

    BetType: sortedArray.betType,

    Date: raceData.StartDate,
    downOdd: false,
    EventDate: raceData.StartDate,
    EventId: "0",
    EventName: `${date.getHours().toString()}:${(date.getMinutes() < 10 ? "0" : "") + date.getMinutes().toString()} ${
      raceData.PaddockName
    }`, // must
    GameId: raceData.GameId,
    GameName: getGame(sortedArray), // raceData.betType == 'Forecast' ? 'Gemela' : 'Trío', // must be Gemela / Trío, / Etc.
    GameSpecialOddsValue: raceData.GameSpecialOddsValue,
    GreyhoundHorseBetType: raceData.GreyhoundHorseBetType || undefined,
    GreyhoundHorses: true,
    Handicap: raceData.HasHandicap,
    Id: raceData.Id || null,
    Index: raceData.Index,
    ItemId: null,
    IsBanked: false,
    IsBet: !!raceData.IsBet,
    IsSelected: false,
    LineErrorMessages: [],
    LineErrors: null,
    LineItemStatus: 0,
    LiveStatus: false,
    Name: "Name",
    Odd: raceData.Odd,
    Ordered: true,
    Paddock: raceData.PaddockName,
    Participants: sortedArray.arrBet,
    PendingCheck: raceData.PendingCheck,
    PipelineExecutionResult: raceData.PipelineExecutionResult || 0,
    Place: raceData.Place,
    Price: 0,
    ResultName: extractName(sortedArray),
    ResultsNr: "",
    Selected: false,
    SpecialOddsValue: raceData.SpecialOddsValue,
    SportHandle: raceData.SportHandle,
    Status: raceData.Status || 0,
    Tax: raceData.Tax || 0,
    TicketNumber: raceData.TicketNumber || null,
    TotalPotentialWin: raceData.TotalPotentialWin || 0,
    Type: sortedArray.betType,
    upOdd: false,
  };
};

function getGame(data): string {
  const comb = !data.ordered;

  return comb
    ? data.betType === "Forecast"
      ? "tTwinC"
      : "tTrioC"
    : data.betType === "Forecast"
      ? "tTwin"
      : "tTrio";
}

function extractName(data): string {
  let sum = "";
  data.arrBet.map(
    (item, i) => (sum += (i ? ", " : "") + (item.order === -1 ? item.Name : item.order + "º " + item.Name)),
  );
  return sum.trim();
}
