import { CommonModule } from "@angular/common";
import { Component, ViewChild, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { IonContent, IonicModule, NavController, Platform } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { BalancesService } from "@providers/BalancesService";
import { EventsService } from "@providers/EventsService";
import { TrackingService } from "@providers/TrackingService";
import { UserService } from "@providers/UserService";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { CodereNavbar } from "@shared-common-components/codere-navbar/codere-navbar";
import { MSO_PATHS, PAYMENTS_PATHS } from "@shared-constants/routes";
import { CodereFooterComponent } from "@shared-mso-components/footer/codere-footer/codere-footer";
import { UserSectionTitle } from "@shared-mso-components/user-section-title/user-section-title";
import { Utils } from "@utils/Utils";

@Component({
  standalone: true,
  selector: "card-request-page",
  templateUrl: "./card-request.html",
  styleUrls: ["./card-request.scss"],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    CodereFooterComponent,
    TranslateModule,
    UserSectionTitle,
    CodereNavbar,
  ],
})
export class CardRequestPage {
  @ViewChild(IonContent) content: IonContent;
  isDesktop = false;
  globalVars!: GlobalVarsModel;
  isMobile: boolean;

  trackingService = inject(TrackingService);
  nav = inject(NavController);
  userService = inject(UserService);
  utils = inject(Utils);
  translate = inject(TranslateService);
  balancesService = inject(BalancesService);
  events = inject(EventsService);
  router = inject(Router);
  platform = inject(Platform);
  device = inject(DeviceService);
  newBaseService = inject(NewBaseService);

  constructor() {
    this.isMobile = this.device.isMobile();
    this.isDesktop = this.device.isDesktop();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  openMap() {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.NearestLocalPage}`], {
      queryParams: { animate: !this.isDesktop },
    });
  }

  openForm2() {
    if (this.globalVars.FEATURES.MSO_cardCodereCP) {
      this.utils.alert(false, this.translate.instant("warning"), this.translate.instant("coderecardcp"), "OK");
      return;
    }
    // checks if the user can request a card or not
    this.userService.checkCanSendCodere().subscribe({
      next: (data) => {
        if (!data.success) {
          this.userService.cardRequestExists().subscribe({
            next: (data3) => {
              if (!data3.success) {
                // comprueba que el usuario haya realizado algún depósito en la amplicación
                this.userService.hasDeposit().subscribe({
                  next: (data2) => {
                    if (data2.success) {
                      if (this.globalVars.user.pendingSMSEmail) {
                        this.utils.confirm(
                          true,
                          this.translate.instant("Attention"),
                          this.translate.instant("sendcommunicatdisabled"),
                          this.translate.instant("notWantpromotions"),
                          () => {
                            setTimeout(() => {
                              this.router.navigateByUrl(MSO_PATHS.BASE + "/" + MSO_PATHS.CardRequestFormPage);
                            }, 800);
                          },
                          this.translate.instant("acceptContinue"),
                          () => {
                            this.userService.setReceive().subscribe(() => {
                              this.globalVars.user.pendingSMSEmail = false;
                              this.events.publish("badges:refresh");
                              setTimeout(() => {
                                this.router.navigateByUrl(MSO_PATHS.BASE + "/" + MSO_PATHS.CardRequestFormPage);
                              }, 800);
                            });
                          },
                        );
                      } else {
                        this.router.navigateByUrl(MSO_PATHS.BASE + "/" + MSO_PATHS.CardRequestFormPage);
                      }
                    } else {
                      //GetCardnodeposit
                      this.utils.confirm(
                        false,
                        "INFO",
                        this.translate.instant("GetCardnodeposit"),
                        this.translate.instant("tCancel"),
                        null,
                        this.translate.instant("IcoDeposit"),
                        () => {
                          this.router.navigateByUrl(`${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CashierPage}`);
                        },
                      );
                    }
                    this.events.publish("footer:refresh", this.content);
                  },
                  error: (_err) => {
                    this.utils.showError(this.translate.instant("m_menu_serverFail"));
                  },
                });
              } else {
                this.utils.showError(this.translate.instant("coderecardrequested"));
              }
            },
            error: (_err) => {
              this.utils.showError(this.translate.instant("coderecardrequested"));
            },
          });
        } else {
          this.utils.confirm(
            false,
            this.translate.instant("tFail"),
            this.translate.instant("coderecardverifdocum"),
            this.translate.instant("tCancel"),
            null,
            this.translate.instant("check"),
          );
        }
      },
      error: (_err) => {
        this.utils.confirm(
          false,
          this.translate.instant("tFail"),
          this.translate.instant("coderecardverifdocum"),
          this.translate.instant("tCancel"),
          null,
          this.translate.instant("check"),
        );
      },
    });
  }

  ionViewWillEnter() {
    this.trackingService.trackEvent(["accessToGetCard", "", "", "Solicitar Tarjeta Codere", "event"]);
    this.balancesService.refreshBalance();
  }

  ionViewDidLoad() {
    this.events.publish("footer:refresh", this.content);
  }
}
