import { Capacitor } from "@capacitor/core";
import { GlobalVarsModel } from "@models/index";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PaySafeCardDepositActionResult } from "../../models/C_Transactions";
import { ErrorService } from "../../providers/ErrorService";
import { UserService } from "../../providers/UserService";
import { NewBaseService } from "../newBase.service";
/* eslint-disable */
import {
  IFramePaymentMethodBehaviourResult,
  PaymentMethodBehaviour,
  RedirectMethodBehaviourResult,
  SkipAmountResult,
} from "./PaymentMethodBase";

export class NetellerMethodBehaviour extends PaymentMethodBehaviour {
  name = "APMNETELLER";
  image = "logo_netellerl";
  track = "AccessToNeteler";
  isBank = false;
  minDepositAmount = 10;
  maxDepositAmount = 100000;
  amountButtons: number[] = [10, 20, 40, 50];
  amount = 20;
  deviceType: string;
  isDesktop: boolean = Capacitor.getPlatform() === "web";

  globalVars!: GlobalVarsModel;
  newBaseService: NewBaseService;
  //newBaseService = inject(NewBaseService);

  constructor(userService: UserService, errorService: ErrorService, newBaseService: NewBaseService) {
    super(false, userService, errorService);
    this.newBaseService = newBaseService;

    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
  }

  skipAmountInput(): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  paymentMetodExecution(): Observable<RedirectMethodBehaviourResult | IFramePaymentMethodBehaviourResult> {
    return this.userService.validateDepositNeteller("", this.amount).pipe(
      map((obj) => {
        return this.map(obj);
      }),
    );
  }
  private map(
    input: PaySafeCardDepositActionResult,
  ): RedirectMethodBehaviourResult | IFramePaymentMethodBehaviourResult {
    const { FEATURES } = this.globalVars;
    const { netellerIframeActive } = FEATURES;
    let result: any;
    netellerIframeActive
      ? (result = new IFramePaymentMethodBehaviourResult())
      : (result = new RedirectMethodBehaviourResult());

    if (input.success) {
      result.success = true;
      netellerIframeActive
        ? ((result.iframeType = "Neteller"), (result.iframeArgs = { type: "Neteller", url: encodeURI(input.url) }))
        : (result.url = input.url);
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams(
        "error" + input.errCode,
        input.errorParameters,
      );
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode == 174 || input.errCode == 2212;
      result.sendToProfesion = input.errCode == 2412;
    }
    return result;
  }
}
