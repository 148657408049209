<ng-container *ngIf="isDesktop; else mobileView">
  <ion-header>
    <div class="more-filter-header">
      <h4 class="more-filter-title">Filtros</h4>
      <i item-right class="codere-icon icon-close" (click)="closeModal()"></i>
    </div>
  </ion-header>
  <ion-content class="more-filter-content">
    <ion-accordion-group class="more-filter-accordion-group" *ngFor="let filterGroup of filterGroups">
      <ion-accordion class="more-filter-accordion">
        <ion-item slot="header" color="dark" class="more-filter-item">
          <ion-icon
            name="information-circle-outline"
            class="item-icon"
            id="click-trigger{{ filterGroup.title }}"
            (click)="$event.stopPropagation()"
          ></ion-icon>
          <ion-popover trigger="click-trigger{{ filterGroup.title }}" triggerAction="click">
            <ng-template>
              <ion-content class="ion-padding">
                <div class="title">{{ filterGroup.title | translate }}</div>
                <div class="text">{{ filterGroup.popover | translate }}</div>
              </ion-content>
            </ng-template>
          </ion-popover>
          <ion-label class="more-filter-item-title">{{ filterGroup.title | translate }}</ion-label>
        </ion-item>
        <div class="more-filter-accordion-content" slot="content" [ngSwitch]="filterGroup.type">
          <ng-template ngSwitchCase="options">
            <options-filter [filterGroup]="filterGroup"></options-filter>
          </ng-template>
          <ng-template ngSwitchCase="minBet">
            <min-bet-filter [filterGroup]="filterGroup"></min-bet-filter>
          </ng-template>
          <ng-template ngSwitchCase="checkbox">
            <checkbox-filter [filterGroup]="filterGroup"></checkbox-filter>
          </ng-template>
          <ng-template ngSwitchCase="card">
            <card-filter [filterGroup]="filterGroup"></card-filter>
          </ng-template>
          <ng-template ngSwitchCase="reels">
            <reels-filter [filterGroup]="filterGroup"></reels-filter>
          </ng-template>
          <ng-template ngSwitchCase="image">
            <image-filter [filterGroup]="filterGroup"></image-filter>
          </ng-template>
          <ng-template ngSwitchCase="layout">
            <layout-filter [filterGroup]="filterGroup"></layout-filter>
          </ng-template>
        </div>
      </ion-accordion>
    </ion-accordion-group>
  </ion-content>
  <ion-footer class="more-filter-footer">
    <button ion-button class="delete" (click)="unapplyMoreFilters()">Borrar todo</button>
    <button ion-button class="apply" (click)="applyFilters()">Aplicar filtros</button>
  </ion-footer>
</ng-container>

<ng-template #mobileView>
  <ion-content class="more-filter-content">
    <div class="more-filter-mobile-container">
      <div class="more-filter-header">
        <h4 class="more-filter-title">Filtros</h4>
        <i item-right class="codere-icon icon-close" (click)="closeModal()"></i>
      </div>
      <div class="more-filter-accordion-container">
        <ion-accordion-group class="more-filter-accordion-group" *ngFor="let filterGroup of filterGroups">
          <ion-accordion class="more-filter-accordion">
            <ion-item slot="header" color="dark" class="more-filter-item">
              <ion-icon
                name="information-circle-outline"
                class="item-icon"
                id="click-trigger{{ filterGroup.title }}"
                (click)="$event.stopPropagation()"
              ></ion-icon>
              <ion-popover trigger="click-trigger{{ filterGroup.title }}" triggerAction="click">
                <ng-template>
                  <ion-content class="ion-padding">
                    <div class="title">{{ filterGroup.title | translate }}</div>
                    <div class="text">{{ filterGroup.popover | translate }}</div>
                  </ion-content>
                </ng-template>
              </ion-popover>
              <ion-label class="more-filter-item-title">{{ filterGroup.title | translate }}</ion-label>
            </ion-item>
            <div class="more-filter-accordion-content" slot="content" [ngSwitch]="filterGroup.type">
              <ng-template ngSwitchCase="options">
                <options-filter [filterGroup]="filterGroup"></options-filter>
              </ng-template>
              <ng-template ngSwitchCase="minBet">
                <min-bet-filter [filterGroup]="filterGroup"></min-bet-filter>
              </ng-template>
              <ng-template ngSwitchCase="checkbox">
                <checkbox-filter [filterGroup]="filterGroup"></checkbox-filter>
              </ng-template>
              <ng-template ngSwitchCase="card">
                <card-filter [filterGroup]="filterGroup"></card-filter>
              </ng-template>
              <ng-template ngSwitchCase="reels">
                <reels-filter [filterGroup]="filterGroup"></reels-filter>
              </ng-template>
              <ng-template ngSwitchCase="image">
                <image-filter [filterGroup]="filterGroup"></image-filter>
              </ng-template>
              <ng-template ngSwitchCase="layout">
                <layout-filter [filterGroup]="filterGroup"></layout-filter>
              </ng-template>
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </div>
      <div class="more-filter-footer">
        <button ion-button class="delete" (click)="unapplyMoreFilters()">Borrar todo</button>
        <button ion-button class="apply" (click)="applyFilters()">Aplicar filtros</button>
      </div>
    </div>
  </ion-content>
</ng-template>
