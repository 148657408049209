/* eslint-disable no-nested-ternary */
import { Injectable, inject } from "@angular/core";

import { TrackingService } from "@providers/TrackingService";

import { EventTypes } from "@models/TrackingEvents";
import { Game } from "@models/lobby/casinoDataModels";

export interface Params {
  event?: EventTypes;
  fromDetail?: boolean;
  isDemo?: boolean;
}

@Injectable({ providedIn: "root" })
export class LobbyTrackingService {
  //#region
  trackingService = inject(TrackingService);
  //#endregion
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  public track(event: Game | string, params?: Params) {
    let id = "";
    let description = "";
    if (typeof event === "string") {
      id = event;
    } else {
      id = event.GameId;
      description = event.Description;
    }

    const eventType: EventTypes = this.getEventType(event, params);

    this.trackingService.track({
      eventType,
      id,
      secondParameter: this.trackingService.getTrackingDevice(),
      description,
    });
  }

  private getEventType(event, params): EventTypes {
    if (params.event) {
      return params.event;
    } else {
      return event.lobbyType === "Casino"
        ? params.isDemo
          ? EventTypes.AccessToGameCasinoDemo
          : params.fromDetail
            ? EventTypes.AccessToGameCasinoFromDetail
            : EventTypes.AccessToGameCasino
        : params.isDemo
          ? EventTypes.AccessToGameSlotsDemo
          : params.fromDetail
            ? EventTypes.AccessToGameSlotsFromDetail
            : EventTypes.AccessToGameSlots;
    }
  }
}
