import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
/* eslint-disable */
import { ChangeDetectionStrategy, Component, Input, type OnChanges, type OnInit, inject } from "@angular/core";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { BaseService } from "@providers/BaseService";
import { TrackingService } from "@providers/TrackingService";
import { LobbyFacadeService } from "@services/casino/loobyFacade.service";
import { DeviceService } from "@services/device.service";
import { BehaviorSubject } from "rxjs";
import { CasinoCardNewComponent } from "../../casino-card-new/casino-card-new.component";

@Component({
  selector: "lb-play-out",
  templateUrl: "./lobby-out.html",
  standalone: true,
  styleUrls: ["./lobby-out.scss"],
  imports: [CommonModule, CasinoCardNewComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LobbyOutComponent extends BaseService implements OnInit, OnChanges {
  @Input() page: string;
  @Input() hide: boolean;
  allGames: any;
  current$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  title$: BehaviorSubject<any> = new BehaviorSubject(false);
  titleShow = "Recomendados";
  declare globalVars: GlobalVarsModel;
  isDesktop = false;
  isMobile = false;

  lobbyFacadeService = inject(LobbyFacadeService);
  http = inject(HttpClient);
  trackingService = inject(TrackingService);
  deviceService = inject(DeviceService);

  constructor() {
    super();
    // * GlovalVars
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit() {
    if (this.isDesktop && this.globalVars.FEATURES.DynamicYieldSportsBetSlipEnabled) {
      this.startDy();
    } else if (this.isDesktop && this.globalVars.FEATURES.CasinoBetslipGames) {
      this.start();
    }
  }

  ngOnChanges() {}

  async start() {
    try {
      if (!this.allGames) {
        this.allGames = await this.lobbyFacadeService.getGamesFromOutCasino();
      }
      const CasinoBetslipGames = JSON.parse(this.globalVars.FEATURES.CasinoBetslipGames);
      const games = await this.getGames(CasinoBetslipGames.games, "backoffice");
      if (games.length > 0) {
        if (CasinoBetslipGames.title !== "" && CasinoBetslipGames.title !== null) {
          this.titleShow = CasinoBetslipGames.title;
        }
        this.title$.next(true);
        this.current$.next(games);
      }
    } catch (error) {}
  }

  async startDy() {
    this.allGames = await this.lobbyFacadeService.getGamesFromOutCasino();
    const type = this.getTypeList(this.page);
    const url = this.globalVars.URLBASE_REPORTS + "SlotGames/GetDYList?mobile=" + this.isMobile + "&list=" + type;
    const { RecommendedGames } = await this.myGet(url)
      .toPromise()
      .catch(() => {
        console.error("Fail service get games recomended for Dy");
        this.start();
      });
    const games = await this.getGames(RecommendedGames, "dy");
    if (games.length > 0) {
      this.title$.next(true);
      this.current$.next(games);
    } else {
      console.error("Not recomendes games for DY betslip");
      this.start();
    }
  }

  async getGames(games, type) {
    const returnGames = [];
    if (this.allGames) {
      if (games) {
        if (games?.length > 0) {
          games?.map((game) => {
            let newGame;
            if (type === "dy") {
              newGame = Object.assign({}, this.allGames[game.GameId]);
            } else {
              newGame = Object.assign({}, this.allGames[game.gameId]);
            }
            if (newGame.Name) {
              newGame.dyId = game.SlotId ? game.SlotId : null;
              newGame.GameId = type === "dy" ? game.GameId : game.gameId;
              newGame.gameTileSize = "Square";
              newGame.imageType = newGame.DefaultImageType;
              newGame.lobby = this.page === "Sports" ? "Betslip" : "";
              newGame.product = "Sports";
              newGame.additionalData = new BehaviorSubject<any>({});
              returnGames.push(newGame);
            }
          });
        }
      }
    }
    return returnGames;
  }

  getTypeList(page) {
    //only Sports for the moment
    let type = "";
    switch (page) {
      case "Sports":
        type = "SportsBetSlip";
        break;
      default:
        type = "SportsBetSlip";
        break;
    }
    return type;
  }

  playGame(game) {
    this.trackingService.trackEvent([
      "OpenGameFromBetSlip",
      game.game.Name,
      "BetSlip",
      "Juego iniciado desde BetSlip en la pagina Sports",
      "event",
    ]);
    this.lobbyFacadeService.playGameOutCasino(game);
  }
}
