import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule, ModalController } from "@ionic/angular";
import { CardPersonalDataProtectionPolicyFormComponent } from "../card-personal-data-protection-policy-form/card-personal-data-protection-policy-form";

@Component({
  selector: "cs-veridas-policy-accept-modal",
  templateUrl: "./cs-veridas-policy-accept-modal.html",
  styleUrls: ["./cs-veridas-policy-accept-modal.scss"],
  standalone: true,
  imports: [IonicModule, CardPersonalDataProtectionPolicyFormComponent, FormsModule],
})
export class CsVeridasPolicyAcceptModal {
  constructor(public modalCtrl: ModalController) {}

  async handleAcceptedPolicy(event: any = null) {
    const returnData = {
      accepted: true,
    };
    await this.modalCtrl.dismiss(returnData);
  }

  async cancelButton() {
    await this.modalCtrl.dismiss(false);
  }
}
