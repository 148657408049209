export interface C_TodayLeagues {
  Events: any[];
  KlothoPriority: number;
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  Priority: number;
  SportHandle: string;
  Locked: boolean;
  CountryCode?: string;
}
