import { CommonModule } from "@angular/common";
import { Component, Inject, ViewChild, forwardRef, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Router } from "@angular/router";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable eqeqeq */
import { IonContent, IonicModule, ModalController } from "@ionic/angular";
import { CasinoSessionsLimits, DepositsSessionsLimits, LimitsChangeResponse } from "@models/I_DecouplingLimits";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { limitsFormulPage } from "@mso-pages/limits-formul/limits-formul";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { DecouplingUserServiceMSO } from "@providers/DecouplingUserServiceMSO.service";
import { EventsService } from "@providers/EventsService";
import { TrackingService } from "@providers/TrackingService";
import { UserServiceMSO } from "@providers/UserServiceMSO";
import { NewBaseService } from "@providers/newBase.service";
import { map } from "rxjs";
import { CodereFooterComponent } from "../../../components/mso/footer/codere-footer/codere-footer";
import { UserSectionTitle } from "../../../components/mso/user-section-title/user-section-title";
import { MSO_PATHS } from "../../../configuration/constants/routes";
import { DeviceService } from "../../../services/device.service";
import { Utils } from "../../../utils/Utils";
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "limits-page",
  imports: [CommonModule, IonicModule, FormsModule, UserSectionTitle, CodereFooterComponent, TranslateModule],
  standalone: true,
  templateUrl: "./limits.html",
  styleUrls: ["./limits.scss"],
})
export class LimitsPage {
  @ViewChild(IonContent) content: IonContent;
  limits: { day: number; week: number; month: number };
  limitsModel: { day: string; week: string; month: string };
  sesion: { gasto: number; perdida: number; tiempo: number };
  sesionModel: { gasto: string; perdida: string };
  spanishLimits: { day: number; week: number; month: number };
  argentinaLimits: { day: number; week: number; month: number };
  actualLimits: { day: number; week: number; month: number };
  sessionTimes: Array<{ desc: string; value: number }>;
  minimumAmount: number;
  minlength: number;
  maxlength: number;
  globalVars!: GlobalVarsModel;
  isMobile: boolean;

  @Inject(forwardRef(() => TrackingService))
  private trackingService: TrackingService;
  device = inject(DeviceService);
  newBaseService = inject(NewBaseService);
  utils = inject(Utils);
  userServiceMSO = inject(UserServiceMSO);
  decouplingServices = inject(DecouplingUserServiceMSO);
  translate = inject(TranslateService);
  events = inject(EventsService);
  modalCtrl = inject(ModalController);
  router = inject(Router);

  constructor() {
    this.isMobile = this.device.isMobile();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.initComponent();
  } // Fin del constructor

  initComponent() {
    this.limits = {
      day: 0,
      week: 0,
      month: 0,
    };
    this.limitsModel = {
      day: this.limits.day.toLocaleString("de-DE"),
      week: this.limits.week.toLocaleString("de-DE"),
      month: this.limits.month.toLocaleString("de-DE"),
    };
    this.sesion = {
      gasto: 0,
      perdida: 0,
      tiempo: 0,
    };
    this.sesionModel = {
      gasto: this.sesion.gasto.toLocaleString("de-DE"),
      perdida: this.sesion.perdida.toLocaleString("de-DE"),
    };
    this.actualLimits = {
      day: 0,
      week: 0,
      month: 0,
    };
    this.spanishLimits = {
      day: 600,
      week: 1500,
      month: 3000,
    };
    this.argentinaLimits = {
      day: 47000,
      week: 117350,
      month: 234700,
    };
    this.minimumAmount = Number(this.globalVars.FEATURES.LobbyMinimumAmount);

    this.sessionTimes =
      this.globalVars.FEATURES.MSO_limitsSessionCasino !== ""
        ? JSON.parse(this.globalVars.FEATURES.MSO_limitsSessionCasino)
        : JSON.parse("[]");

    if (this.utils.isArgentina() || this.globalVars.licenseType === LicenseTypes.Panama) {
      this.minlength = 3;
      this.maxlength = 11;
    }
  }

  ionViewWillEnter() {
    this.router.navigate([MSO_PATHS.BASE + "/" + MSO_PATHS.LimitsPage]);
    this.trackingService?.trackEvent([
      "AccessToNationalUserLimits",
      "",
      "",
      "Acceder limites licencia nacional",
      "event",
    ]);
    this.events.publish("footer:refresh", this.content);
    this.getLimits();
  }

  getLimits() {
    let usedLimits;
    if (this.utils.isArgentina()) {
      usedLimits = this.argentinaLimits;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      usedLimits = this.spanishLimits;
    }
    const limitsObserver = this.globalVars.FEATURES.MSO_DecouplingLimits
      ? this.decouplingServices.decouplingGetLimits().pipe(
          map((response) => ({
            day: `${response.depositsDay}`,
            week: `${response.depositsWeek}`,
            month: `${response.depositsMonth}`,
            SessionLimitsGasto: `${response.casinoSessionsSpending || 0}`,
            SessionLimitsPerdida: `${response.casinoSessionsLoss || 0}`,
            SessionLimitsTiempo: `${response.casinoSessionsTime || 0}`,
          })),
        )
      : this.userServiceMSO.getLimits();
    limitsObserver.subscribe({
      next: (data) => {
        let intDay: number = Number.parseInt(data.day);
        let intWeek: number = Number.parseInt(data.week);
        let intMonth: number = Number.parseInt(data.month);

        if (intDay == -1 || intDay == 0) intDay = Number.parseInt(this.globalVars.FEATURES.depositDayLimit);

        if (intWeek == -1 || intWeek == 0) intWeek = Number.parseInt(this.globalVars.FEATURES.depositWeekLimit);

        if (intMonth == -1 || intMonth == 0)
          intMonth = Number.parseInt(this.globalVars.FEATURES.depositMonthLimit);

        this.globalVars.limits = {
          day: intDay,
          week: intWeek,
          month: intMonth,
        };
        this.actualLimits = { day: intDay, week: intWeek, month: intMonth };
        this.limits.day = this.globalVars.limits.day;
        this.limits.week = this.globalVars.limits.week;
        this.limits.month = this.globalVars.limits.month;

        if (this.utils.isArgentina()) {
          (this.limitsModel.day = this.limits.day.toLocaleString("de-DE")),
            (this.limitsModel.week = this.limits.week.toLocaleString("de-DE")),
            (this.limitsModel.month = this.limits.month.toLocaleString("de-DE"));
          this.sesion.gasto = data.SessionLimitsGasto
            ? Number(data.SessionLimitsGasto)
            : this.globalVars.user.sessionLimitsGasto;
          this.sesionModel.gasto = this.sesion.gasto.toLocaleString("de-DE");
          this.sesion.perdida = data.SessionLimitsPerdida
            ? Number(data.SessionLimitsPerdida)
            : this.globalVars.user.sessionLimitsPerdida;
          this.sesionModel.perdida = this.sesion.perdida.toLocaleString("de-DE");
          this.sesion.tiempo = data.SessionLimitsTiempo
            ? Number(data.SessionLimitsTiempo)
            : this.globalVars.user.sessionLimitsTiempo;
        } else if (this.globalVars.licenseType === 22) {
          this.sesion.gasto = data.SessionLimitsGasto
            ? Number(data.SessionLimitsGasto)
            : this.globalVars.FEATURES.casinoCostLimit;
          this.sesionModel.gasto = this.sesion.gasto.toLocaleString("de-DE");
          this.sesion.perdida = data.SessionLimitsPerdida
            ? Number(data.SessionLimitsPerdida)
            : this.globalVars.FEATURES.casinoLostLimit;
          this.sesionModel.perdida = this.sesion.perdida.toLocaleString("de-DE");
          this.sesion.tiempo = data.SessionLimitsTiempo
            ? Number(data.SessionLimitsTiempo)
            : this.globalVars.FEATURES.casinoTimeLimit;
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      error: () => {},
    });
  }

  /**
   * Manejador del evento click del boton actualizar
   */
  sendLimits(): void {
    //var zipRegNR: RegExp = /^[0-9]*$/;

    if (
      this.limits.day.toString() == "" ||
      this.limits.week.toString() == "" ||
      this.limits.month.toString() == ""
    ) {
      //error, el usuario deja el campo vacio.
      this.utils.showError(this.translate.instant("fillfields"));
      return;
    }

    if (this.limits.day <= 0 || this.limits.week <= 0 || this.limits.month <= 0) {
      //error, el usuario introduce un valor <= 0
      this.utils.showError(this.translate.instant("tfaillim0"));
      return;
    } else if (
      this.utils.isArgentina() &&
      (this.limits.day < 100 || this.limits.week < 100 || this.limits.month < 100)
    ) {
      // TODO BAU Crear literal
      // let msgError = this.translate.instant(".....")
      const msgError = "Los límites deben ser mayores a 100";
      this.utils.showError(msgError);
      return;
    }

    if (isNaN(this.limits.day) || isNaN(this.limits.week) || isNaN(this.limits.month)) {
      this.utils.showError(this.translate.instant("m_reg_loginKo0"));
      //error
      return;
    }

    const day: number = this.limits.day;
    const week: number = this.limits.week;
    const month: number = this.limits.month;

    if (
      (this.globalVars.licenseType == LicenseTypes.Nacional ||
        this.globalVars.licenseType == LicenseTypes.Madrid) &&
      !this.globalVars.FEATURES.MSO_DecouplingLimits
    ) {
      //llamada a comprobacion encuesta
      this.userServiceMSO
        .limitsChangeRequestStatus({
          newAmountDay: Number(day),
          newAmountWeek: Number(week),
          newAmountMonth: Number(month),
        })
        .subscribe({
          next: async (data) => {
            if (data != null) {
              if (data.limitsChangeRequestStatus == "REQUIRED") {
                //hacer encuesta
                this.globalVars.limits = { day: day, week: week, month: month };

                const modal = await this.modalCtrl.create({
                  component: limitsFormulPage,
                  cssClass: "is-modal limitsFormulModal",
                });

                await modal.present();
              }
              if (data.limitsChangeRequestStatus == "ERRORMORELIMITS") {
                //no se puede pedir aumento de límites al haber suspendido el test, hay q esperar 7 días
                this.utils.showError(this.translate.instant("errorMoreLimits"));
              }
              if (data.limitsChangeRequestStatus == "NOT_CHANGES") {
                //no se puede pedir aumento de límites al haber suspendido el test, hay q esperar 7 días
                this.utils.showMsg(this.translate.instant("limitsnochange"));
              }
              if (data.limitsChangeRequestStatus == "LIMITS_CHANGES_OK") {
                this.utils.showMsg(this.globalVars.user.username + " " + this.translate.instant("tlimitok"));
              }
            } else {
              this.utils.showError(this.translate.instant("errorLimits"));
            }
          },
          error: () => {
            this.utils.showError(this.translate.instant("errorLimits"));
          },
        });
    } else {
      this.calltolimits(day, week, month);
    }
  }

  calltolimits(day: number, week: number, month: number) {
    if (this.globalVars.FEATURES.MSO_DecouplingLimits) {
      this.sendLimitsDecoupling("deposits", {
        newAmountDay: day,
        newAmountMonth: month,
        newAmountWeek: week,
      });
      return;
    }
    this.userServiceMSO.changeLimits(day, week, month).subscribe(
      (data) => {
        if (data != null) {
          if (this.globalVars.licenseType == LicenseTypes.Panama || this.utils.isArgentina()) {
            let msg = this.globalVars.user.username + " ";
            if (data.limitsChangeRequestStatus === "LIMITS_CHANGES_OK") {
              msg += this.translate.instant(this.utils.isArgentina() ? "newlimitsar" : "tlimitok");
            } else if (data.limitsChangeRequestStatus === "PENDING") {
              msg += this.translate.instant("tlimitampliados");
            } else if (data.limitsChangeRequestStatus === "REJECTED") {
              msg += "Ya tiene pendiente una solicitud de aumento de límites";
            } else {
              msg += this.translate.instant("limitsnochange");
            }
            this.utils.showMsg(
              msg,
              null,
              this.utils.isArgentina() &&
                (data.limitsChangeRequestStatus === "LIMITS_CHANGES_OK" ||
                  data.limitsChangeRequestStatus === "PENDING")
                ? this.translate.instant("updatedlimits").toUpperCase()
                : "INFO",
              "limitsAlert",
            );
            this.globalVars.limits = { day: day, week: week, month: month };
          } else {
            if (data.StorageResponseCode == 0) {
              let msg = this.globalVars.user.username + " " + this.translate.instant("tlimitok");

              if (
                day > this.globalVars.limits.day ||
                week > this.globalVars.limits.week ||
                month > this.globalVars.limits.month
              ) {
                msg = this.globalVars.user.username + " " + this.translate.instant("tlimitampliados"); //viewModel.conectadoU() + viewModel.literales().tlimitampliados;
              }

              if (data.StorageResponseDescription != null) msg = data.StorageResponseDescription;

              this.utils.showMsg(msg);

              this.globalVars.limits = { day: day, week: week, month: month };
            } else {
              this.utils.showMsg(this.globalVars.user.username + " " + this.translate.instant("tlimitampliados"));
            }
          }
        } else {
          this.utils.showError(this.globalVars.user.username + " " + this.translate.instant("tfaillimitno"));
        }
      },
      (err) => {
        //
        this.utils.showError(err);
      },
    );
  }

  /**
   * Lanzado desde el manejador del evento click del boton actualizar
   * cuando se trata de límites de sesión (en Argentina)
   */
  sendSessionLimits() {
    // Validar que los campos no estén vacios
    // ni sean menores de 100  (minlen = 3)
    if (this.sesion.gasto <= 100 || this.sesion.perdida <= 0) {
      // TODO Crear literal
      // let msgError = this.translate.instant(".....")
      const msgError = "Los límites deben ser mayores a 100";
      this.utils.showError(msgError);
      return;
    }

    const datauser = {
      amountSpent: Number(this.sesion.gasto),
      amountLost: Number(this.sesion.perdida),
      time: Number(this.sesion.tiempo),
    };
    if (this.globalVars.FEATURES.MSO_DecouplingLimits) {
      this.sendLimitsDecoupling("session", {
        newLoss: datauser.amountLost,
        newSpending: datauser.amountSpent,
        newTime: datauser.time,
      });
      return;
    }
    this.userServiceMSO.changeSessionLimits(datauser).subscribe((data) => {
      if (data != null) {
        if (this.utils.isArgentina()) {
          this.sessionLimitsArgentinaResponse(data, datauser);
        } else {
          this.sessionLimitsPanamaResponse(data, datauser);
        }
      } else {
        this.utils.showError(this.globalVars.user.username + " " + this.translate.instant("tfaillimitno"));
      }
    });
  }

  onInputLimits(key) {
    const numero = Number(this.limitsModel[key].replace(/[^0-9]/g, ""));
    const cadena = numero.toLocaleString("de-DE");
    setTimeout(() => {
      this.limits[key] = numero;
      this.limitsModel[key] = cadena;
    });
  }

  onInputSesion(key) {
    const numero = Number(this.sesionModel[key].replace(/[^0-9]/g, ""));
    const cadena = numero.toLocaleString("de-DE");
    setTimeout(() => {
      this.sesion[key] = numero;
      this.sesionModel[key] = cadena;
    });
  }

  sessionLimitsArgentinaResponse(data: any, datauser: any) {
    if (data.errCode == 0) {
      let msg = this.globalVars.user.username + " " + this.translate.instant("tlimitok");

      if (data.errMessage != null && data.errMessage != "") msg = data.errMessage;
      if (msg == "PENDING") {
        msg = `<strong>${this.globalVars.user.username}</strong>${this.translate.instant("tlimitampliados")}`;
      }
      this.utils.showMsg(msg);
      this.globalVars.user.sessionLimitsGasto = datauser.amountSpent;
      this.globalVars.user.sessionLimitsPerdida = datauser.amountLost;
      this.globalVars.user.sessionLimitsTiempo = datauser.time;
    } else {
      this.utils.showMsg(this.globalVars.user.username + " " + this.translate.instant("tfaillimitno"));
    }
  }
  sessionLimitsPanamaResponse(data: any, datauser: any) {
    if (data.success) {
      let msg = "";
      switch (data.limitsChangeRequestStatus) {
        case "NOT_CHANGES":
          msg = this.translate.instant("limitsnochange");
          break;
        case "LIMITS_CHANGES_OK":
          msg = this.globalVars.user.username + " " + this.translate.instant("tlimitok");
          this.globalVars.user.sessionLimitsGasto = datauser.amountSpent;
          this.globalVars.user.sessionLimitsPerdida = datauser.amountLost;
          this.globalVars.user.sessionLimitsTiempo = datauser.time;
          break;
        case "PENDING":
          msg = `<strong>${this.globalVars.user.username}</strong>${this.translate.instant("tlimitampliados")}`;
          break;
      }
      this.utils.showMsg(msg, null, "INFO", "limitsAlert");
    } else {
      this.utils.showMsg(this.globalVars.user.username + " " + this.translate.instant("tfaillimitno"));
    }
  }

  sendLimitsDecoupling(
    limitsType: "session" | "deposits",
    newLimits: CasinoSessionsLimits | DepositsSessionsLimits,
  ) {
    const observerRequest =
      limitsType === "deposits"
        ? this.decouplingServices.decouplingDepositsRequestChangeLimits(newLimits as DepositsSessionsLimits)
        : this.decouplingServices.decouplingCasinoRequestChangeLimits(newLimits as CasinoSessionsLimits);
    observerRequest.subscribe({
      next: (response) => {
        if (response) {
          this.decouplingLimitsResponse(response, limitsType, newLimits);
        } else {
          this.utils.showError(`${this.globalVars.user.username}${this.translate.instant("tfaillimitno")}`);
        }
      },
      error: () => {
        this.utils.showError(`${this.globalVars.user.username}${this.translate.instant("tfaillimitno")}`);
      },
    });
  }

  async decouplingLimitsResponse(
    response: LimitsChangeResponse,
    limitsType: "session" | "deposits",
    newLimits: CasinoSessionsLimits | DepositsSessionsLimits,
  ) {
    let msg = "";
    switch (response.status) {
      case "APPROVED":
        msg = `${this.globalVars.user.username}${this.translate.instant("tlimitok")}`;
        if (limitsType === "deposits") {
          const { newAmountDay, newAmountMonth, newAmountWeek } = newLimits as DepositsSessionsLimits;
          this.globalVars.limits = {
            day: newAmountDay,
            week: newAmountWeek,
            month: newAmountMonth,
          };
        } else {
          const { newLoss, newSpending, newTime } = newLimits as CasinoSessionsLimits;
          this.globalVars.user.sessionLimitsGasto = newSpending;
          this.globalVars.user.sessionLimitsPerdida = newLoss;
          this.globalVars.user.sessionLimitsTiempo = newTime;
        }
        break;
      case "ERRORMORELIMITS":
        return this.utils.showError(this.translate.instant("errorMoreLimits"));
      case "SURVEY_REJECTED":
        return this.utils.showError(this.translate.instant("gametestko"));
      case "NOT_CHANGES":
        msg = this.translate.instant("limitsnochange");
        break;
      case "PENDING":
        msg = `<strong>${this.globalVars.user.username}</strong>${this.translate.instant("tlimitampliados")}`;
        break;
      case "REJECTED":
        msg = this.translate.instant("rejectedlimits");
        break;
      case "LESS3MONTH":
        return this.utils.showError(this.translate.instant("surveylimitserror"));
      case "REQUIRED":
        const { newAmountDay, newAmountMonth, newAmountWeek } = newLimits as DepositsSessionsLimits;
        this.globalVars.limits = {
          day: newAmountDay,
          week: newAmountWeek,
          month: newAmountMonth,
        };
        const modal = await this.modalCtrl.create({
          component: limitsFormulPage,
          cssClass: "is-modal limitsFormulModal",
        });
        await modal.present();
        modal.onDidDismiss().then((data) => {
          if (data.data) {
            this.decouplingServices
              .decouplingDepositsRequestChangeLimitsWithForm(
                newLimits as DepositsSessionsLimits,
                data.data.approbed,
              )
              .subscribe({
                next: (responseForm) => {
                  if (responseForm) {
                    this.decouplingLimitsResponse(responseForm, limitsType, newLimits);
                  } else {
                    this.utils.showError(
                      `${this.globalVars.user.username}${this.translate.instant("tfaillimitno")}`,
                    );
                  }
                },
                error: () => {
                  this.utils.showError(
                    `${this.globalVars.user.username}${this.translate.instant("tfaillimitno")}`,
                  );
                },
              });
          }
        });
        return;
    }
    this.utils.showMsg(msg, null, "Info", "limitsAlert");
  }
}
