<!--Signup bonus-->
<!-- OLD -->
<ng-container *ngIf="!globalVars.FEATURES.CAS_BonusNewInterface; else newView">
<ion-grid class="margin0">
  <ion-row class="is-row-grid">
    <iframe #iframe [src]="iframeUrl" class="iframe" scrolling="no" (load)="setCancelBtn()"></iframe>
  </ion-row>
  <ion-row class="is-row-grid containerBtns">
    <ion-col col-12 class="ion-text-center">
      <button class="btnPlay" (click)="playBonus()"><span>{{'gocasino' | translate}}</span></button>
    </ion-col>
  </ion-row>
</ion-grid>
</ng-container>
<!-- NEW -->
<ng-template #newView>
  <ion-grid class="margin0">
    <ion-row class="is-row-grid containerBtns">
      <ion-col col-12 class="ion-text-center">
        <div class="bonus-details">
          <div class="bonus-details--title">Progreso de bono {{provider}}</div>
          <div class="bonus-details--progressbar">
            <div class="bonus-details--progressbar_progress-bar" [ngStyle]="{ 'width': + percentage + '%'}">
              <span class="bonus-details--progressbar_text">{{percentage}} %</span>
            </div>
          </div>
          <button class="bonus-details--cancel" (click)="cancelBonus($event)">Cancelar bono</button>
          <div class="bonus-details--details">
            <p>Nombre de bono <i class="bonus-details--details--lines"></i><span>{{bonus.BonusName}}</span></p>
            <p>Tipo <span>{{bonus.TriggerTypeName}}</span></p>
            <ng-container *ngIf="bonus.BonusType === 1">
              <p>Tiradas gratis entregadas <span>{{bonus.FreeSpinCount}}</span></p>
              <p>Tiradas gratis restantes <span>{{bonus.FreeSpinRemain}}</span></p>
            </ng-container>
            <ng-container *ngIf="bonus.BonusType === 6">
              <p>Tiradas gratis entregadas <span>{{bonus.GoldenChipsCount}}</span></p>
              <p>Tiradas gratis restantes <span>{{bonus.GoldenChipsRemain}}</span></p>
            </ng-container>
            <p>
              Importe de bono inicial
              <span
                ><span *ngIf="!isSpain()">{{globalVars.currencySymbol}}</span>{{bonus.BonusInitialAmount}}
                <span *ngIf="isSpain()">{{globalVars.currencySymbol}}</span>
              </span>
            </p>
            <p>
              Importe actual del bono
              <span
                ><span *ngIf="!isSpain()"></span>{{bonus.BonusCurrentAmount}}
                <span *ngIf="isSpain()">{{globalVars.currencySymbol}}</span></span
              >
            </p>
            <p>
              Requisito de bono
              <span
                ><span *ngIf="!isSpain()"></span>{{bonus.WageringRequirement}}
                <span *ngIf="isSpain()">{{globalVars.currencySymbol}}</span></span
              >
            </p>
            <p>
              Importe restante requisito de Bono
              <span
                ><span *ngIf="!isSpain()"></span>{{bonus.WageringRemain}}
                <span *ngIf="isSpain()">{{globalVars.currencySymbol}}</span></span
              >
            </p>
            <p>
              Ganancias del bono
              <span
                ><span *ngIf="!isSpain()"></span>{{bonus.PendingWinning}}
                <span *ngIf="isSpain()">{{globalVars.currencySymbol}}</span></span
              >
            </p>
            <p>Fecha límite del bono <span>{{ bonus.ExpirationDate | extractDate:[1]}}</span></p>
          </div>
        </div>
        <div class="bonus-games">
          <ng-container *ngIf="category$ | async as cat">
            <casino-category-slider-new
              [id]="bonus.RemoteBonusId"
              [category]="cateWithGames"
              (turnPlay)="playGame($event)"
            >
            </casino-category-slider-new>
          </ng-container>
        </div>
        <button class="btnPlay" (click)="playBonus()"><span>{{'gocasino' | translate}}</span></button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-template>
