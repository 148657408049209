import { LineItem } from "./line-item.model";

export class singlesBetSlip {
  systemId: number;
  lineItems: LineItem[];
  overallStake: number;
  totalPotentialWinning: number;
  errorMessage: string;

  constructor() {
    this.systemId = 1;
    this.lineItems = [];
    this.overallStake = 0;
    this.totalPotentialWinning = 0;
    this.errorMessage = null;
  }
}
