export { BetSlipService } from "./betslip.service";
export { MobileBehaviorService } from "./mobile-behavior.service";
export { SportBookService } from "./sportsbook.service";
export { SportLiveMarketService } from "./sports.live-market.service";
export { SportLiveService } from "./sports.live.service";
export { SportMyBetsService } from "./sports.my-bets.service";
export { SportNavigationService } from "./sports.navigation.service";
export { SportRaceService } from "./sports.race.service";
export { SportsCommonService } from "./sports.common.service";
export { SportService } from "./sports.service";
export { TicketService } from "./ticket.service-deprecated";
export { UtilsMyBetsService } from "./sports.utils-my-bets.deprecated.service";
export { XStateService } from "./xstate.service";

import { BetSlipService } from "./betslip.service";
import { MobileBehaviorService } from "./mobile-behavior.service";
import { SportsCommonService } from "./sports.common.service";
import { SportLiveMarketService } from "./sports.live-market.service";
import { SportLiveService } from "./sports.live.service";
import { SportMyBetsService } from "./sports.my-bets.service";
import { SportNavigationService } from "./sports.navigation.service";
import { SportRaceService } from "./sports.race.service";
import { SportService } from "./sports.service";
import { UtilsMyBetsService } from "./sports.utils-my-bets.deprecated.service";
import { SportBookService } from "./sportsbook.service";
import { TicketService } from "./ticket.service-deprecated";
import { XStateService } from "./xstate.service";

export const sportsServices = [
  BetSlipService,
  MobileBehaviorService,
  SportBookService,
  SportLiveMarketService,
  SportLiveService,
  SportMyBetsService,
  SportNavigationService,
  SportRaceService,
  SportsCommonService,
  SportService,
  TicketService,
  UtilsMyBetsService,
  XStateService,
  SportsCommonService,
];
