import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output, inject } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { IonicModule } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { C_NavbarItem } from "@models/index";
import { NewBaseService } from "@providers/newBase.service";
@Component({
  selector: "sb-navbar-item",
  templateUrl: "./sb-navbar-item.html",
  imports: [CommonModule, IonicModule],
  standalone: true,
  styleUrls: ["./sb-navbar-item.scss"],
})
export class SbNavbarItemComponent implements OnInit {
  @Input() item: C_NavbarItem;
  @Input() theme = "sportbook";
  @Input() index: number;
  @Output() selectItem: EventEmitter<C_NavbarItem> = new EventEmitter();
  private _ref: HTMLElement;
  globalVars!: GlobalVarsModel;
  imageSrc: string;
  icon: string;
  name: string;

  newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }
  ngOnInit(): void {
    const { item } = this;
    const icon = item?.icon;
    this.icon = icon ? `icon-${icon.toLowerCase()}` : null;
    const isNative = Capacitor.isNativePlatform();
    const img = item?.img;
    this.imageSrc = isNative ? img?.replace(".svg", ".png") : img;
    this.name = item?.icon;
  }
}
