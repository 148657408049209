// eslint-disable-next-line no-shadow
export enum DisplayType {
  THREEWAY = "3way",
  TWOWAY = "2way",
  TWOBUTTONLINE = "2buttonline",
  THREEBUTTONLINE = "3buttonline",
  TWOBUTTONLIST = "2buttonlist",
  THREEBUTTONLIST = "3buttonlist",
  SIXBUTTONLIST = "6buttonlist",
  FOURBUTTONLIST = "4buttonlist",
  SCORE = "correctscorelistwithslider",
  OUTRIGHTLIST = "outrightlist",
  OUTRIGHTLISTALPHABETICAL = "outrightlistalphabetical",
  GOALSCORER = "goalscorer",
  SCORETWINCAST = "scorecastwincast",
  DESKTOPMARKETS = "desktopmarkets",
}
