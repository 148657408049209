import * as TicketActions from "./ticket.actions";
import { TicketEffects } from "./ticket.effects";
import { NewTicketFacade } from "./ticket.facade";
import { ticketReducer } from "./ticket.reducers";

export { TicketEffects } from "./ticket.effects";
export { NewTicketFacade } from "./ticket.facade";
export { ticketReducer, ticketState } from "./ticket.reducers";
export * as TicketActions from "./ticket.actions";

export const TicketStoreIndex = [TicketActions, TicketEffects, NewTicketFacade, ticketReducer];
