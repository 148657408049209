// Event types

//eslint-disable-next-line
export enum EventTypes {
  PageLoad = 1,
  Login = 2,
  Lead = 3,
  Logout = "Logout",
  Registration = 4, // Deprecated - Don"t use
  FirstDeposit = 5,
  SubsequentDeposit = 6,
  BetCompleted = "betCompleted",
  FastBetCompleted = "FastBetCompleted",
  AccessToRegisterFromButton = "AccessToRegisterFromButton",
  FinishRegister = "FinishRegister",
  AppsFlyer = "AppsFlyer",
  RefreshBalance = "RefreshBalance",
  ShowBalanceDetails = "ShowBalanceDetails",
  AccessOnlineDeposits = "AccessOnlineDeposits",
  AccessPaymentMethod = "AccessPaymentMethod",
  DepositPaymentOK = "DepositPaymentOK",
  OpenUserMenu = "openUserMenu",
  AccessToBetHistory = "AccessToBetHistory",
  OpenBetSlip = "openBetSlip",
  CloseBetSlip = "closeBetSlip",
  ErrorMsgTicket = "errorMsgTicket",
  PushNotificationOpened = "pushNotificationOpened",
  AddBet = "addBet",
  AddBetLive = "addBetLive",
  DepositClickAmount = "DepositClickAmount",
  DepositClickDeposit = "DepositClickDeposit",
  FreeBetToogle = "FreeBetToogle",
  MiniCasinoOpen = "MiniCasinoOpen",
  MiniCasinoClose = "MiniCasinoClose",
  playerNotificationWinig = "PlayerNotificationWinig",
  playerNotificationAcepted = "PlayerNotificationAcepted",

  // Signup
  SignUpRequiredByCookie = "SignUpRequiredByCookie",
  SignUpCookieRemoved = "SignUpCookieRemoved",
  SignUpFailed = "SignUpFailed",

  // Slots/Casino events
  GameDescriptionOpened = "GameDescriptionOpened",
  AccessToBingo = "AccessToBingo",
  AccessToCasino = "AccessToCasino",
  AccessToSlots = "AccessToSlots",
  AccessToJackpots = "AccessToJackpots",
  AccessToGameCasinoDemo = "AccessToGameCasinoDemo",
  AccessToGameSlotsDemo = "AccessToGameSlotsDemo",
  AccessToGameCasino = "AccessToGameCasino",
  AccessToGameSlots = "AccessToGameSlots",
  AccessToGameCasinoFromDetail = "AccessToGameCasinoFromDetail",
  AccessToGameSlotsFromDetail = "AccessToGameSlotsFromDetail",
  InitGameSlots = "InitGameSlots",
  InitGameCasino = "InitGameCasino",
  CasinoLobbyCategoryViewAll = "CasinoLobbyCategoryViewAll",
  CasinoLobbySearch = "CasinoLobbySearch",
  CasinoLobbyFilterSelected = "CasinoLobbyFilterSelected",
  OpenQuickLaunch = "OpenQuickLaunch",
  CloseQuickLaunch = "CloseQuickLaunch",
  CasinoSearch = "CasinoSearch",
  MoreFiltersEvent = "MoreFiltersEvent",
  CasinoLobbyFilterApplied = "CasinoLobbyFilterApplied",
  CasinoLobbyFilterScrollDown = "CasinoLobbyFilterScrollDown",
  CasinoLobbyFilterScrollUp = "CasinoLobbyFilterScrollUp",
  CasinoHomeViewAll = "CasinoHomeViewAll",
  CasinoHomeHidde = "CasinoHomeHidde",
  CasinoHomeScrollDown = "CasinoHomeScrollDown",
  CasinoHomeScrollUp = "CasinoHomeScrollUp",
  GameSessionStarts = "GameSessionStarts",
  GameSessionOptionSelected = "GameSessionOptionSelected",

  // Casino Bonus events
  CasinoBonusAccepted = "CasinoBonus_Accepted",
  CasinoBonusDeclined = "CasinoBonus_Declined",
  CasinoBonusShowWaitingMessages = "CasinoBonus_ShowWaitingMessages",
  CasinoBonusNotAvailableMessages = "CasinoBonus_NotAvailableAcceptDeclineMessages",
  CasinoBonusCancelSportBonusIfNeeded = "CasinoBonus_CancelSportBonusIfNeeded",
  CasinoBonusCancelActiveBonus = "CasinoBonus_CancelActiveBonus",

  // IForium Bonus Events
  IforiumFDBCheckUserPromo = "IforiumFDB_CheckUserPromo",
  IforiumFDBUserHasPromo = "IforiumFDB_UserHasPromo",
  IforiumFDBUserDoesntHavePromo = "IforiumFDB_UserDoesntHavePromo",
  IforiumFDBOffer = "IforiumFDB_Offer",
  IforiumFDBDeclined = "IforiumFDB_Declined",
  IforiumFDBAccepted = "IforiumFDB_Accepted",
  IforiumFDBRedeemed = "IforiumFDB_Redeemed",
  IforiumFDBNoPromoAvailable = "IforiumFDB_NoPromoAvailable",
  IforiumFDBLowerThaMinimum = "IforiumFDB_LowerThanMinumum",
  IforiumFDBHigherThanMaximum = "IforiumFDB_HigherThaMaximum",
  IforiumFDBHigherThanMaximumDeclined = "IforiumFDB_HigherThaMaximumAccepted",
  IforiumFDBHigherThanMaximumAccepted = "IforiumFDB_HigherThaMaximumDeclined",
  IforiumFDBFailure = "IforiumFDB_Failure",
  IforiumSignUpBonus = "IforiumSignUpBonus",

  WatchAndBetServerResponse = "WatchAndBetServerResponse",
  Veridas = "Veridas",
  VeridasXpressId = "VeridasXpressId",

  // Betsense
  BetsenseBetPlaced = "BetsenseBetPlaced",

  //Sports
  HttpError = "BLSHError",
  TimeoutError = "BLSTimeoutError",
  TimeoutTrace = "BLSTimeoutTrace",

  //Cookieconsent Google
  CookieConsentGoogle = "CookieConsentGoogle",

  // Payments Veridas Loggind
  PaymentsVeridasQrCodeRequested = "PaymentsVeridasQrCodeRequested",
  PaymentsVeridasQrCodeRequestedFailure = "PaymentsVeridasQrCodeRequestedFailure",
  PaymentsVeridasVerificationsRequested = "PaymentsVeridasVerificationsRequested",
  PaymentsVeridasVerificationsRequestedFailure = "PaymentsVeridasVerificationsRequestedFailure",
  PaymentsVeridasGetToken = "PaymentsVeridasGetToken",
  PaymentsVeridasGetTokenFailure = "PaymentsVeridasGetTokenFailure",
}

// Base events
export interface TrackingEvent {
  eventType: EventTypes;
  id?: string;
  description?: string;
  secondParameter?: string;
  additionalData?: { [key: string]: any };
}

export interface TrackersCodereId {
  trackType?: (typeof TRAKERSTYPECODEREID)[keyof typeof TRAKERSTYPECODEREID];
  value?: string;
  inplay?: string;
  deporte?: string;
  cantidadapuesta?: number;
  categoriaevento?: string;
  evento?: string;
  fechaapuesta?: Date;
  idapuesta?: string;
  mercado?: string;
  validUntil?: Date;
  cuota?: number;
}

//TrackersType  CodereID
export const TRAKERSTYPECODEREID = {
  googleAnalytics: 754310000,
  facebook: 754310001,
  twitter: 754310002,
  mexosAdvertiser: 754310003,
  mexosProfileID: 754310004,
  mexosAquisitionSource: 754310005,
  mexosBTAG: 754310006,
  mexosID1: 754310007,
  mexosID2: 754310008,
  mexosID3: 754310009,
  googleTagManager: 754310010,
  azureuserID: 754310011,
  mexosID4: 754310012,
  mexosID5: 754310013,
  mexosID6: 754310014,
  mexosID7: 754310015,
  mexosID8: 754310016,
  mexosID9: 754310017,
  deviceType: 754310018,
  appsflyerafstatus: 754310019,
  platform: 754310020,
  appsflyerafmessage: 754310021,
  appsflyermediasource: 754310022,
  appsflyercampaign: 754310023,
  appsflyerclickid: 754310024,
  appsflyerafsiteid: 754310025,
  appsflyerafsub1: 754310026,
  appsflyerafsub2: 754310027,
  aux1: 754310028,
  appsflyerafsub3: 754310029,
  appsflyerafsub4: 754310030,
  appsflyerafsub5: 754310031,
  appsflyerafkeywords: 754310032,
  appsflyerclicktime: 754310033,
  appsflyeragency: 754310034,
  appsflyerisfirstlaunch: 754310035,
  appsflyerisfb: 754310036,
  appsflyeradid: 754310037,
  appsflyercampaignid: 754310038,
  appsflyeradset: 754310039,
  appsflyeradsetid: 754310040,
  appsflyerorigcost: 754310041,
  appsflyercostcentsUSD: 754310042,
  appsflyercustom1: 754310043,
  appsflyercustom2: 754310044,
  appsflyercustom3: 754310045,
  appsflyercustom4: 754310046,
  appsflyercustom5: 754310047,
  appsflyerfulljson: 754310048,
  aux2: 754310049,
  aux3: 754310050,
  aux4: 754310051,
  aux5: 754310052,
  iForiumSignup: 754310053,
  iForiumDeposit: 754310054,
  mexosProduct: 754310055,
  croTag1: 754310056,
  croTag2: 754310057,
  croTag: 754310058,
};
