import { Pipe, PipeTransform, inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: "sanitizerHtml",
  standalone: true,
})
export class SanitizerHtml implements PipeTransform {
  sanitizer = inject(DomSanitizer);
  transform(value = "") {
    if (value) {
      return this.sanitizer.bypassSecurityTrustHtml(value);
    }
    return value;
  }
}
