import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SoliticsModule } from "./solitics/solitics.module";

/**
 * @author a.e.m
 * @version 1.0.0
 * @description el modulo de trackers de la aplicacion, agrupa todos los modulos :
 * - solitics
 */
@NgModule({
  declarations: [],
  imports: [CommonModule, SoliticsModule],
  exports: [SoliticsModule],
})
export class TrackingModule {}
