import { CommonModule } from "@angular/common";
import { Component, Input, type OnInit, inject } from "@angular/core";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { FooterImage } from "@models/footer/imagen";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "codere-footer-deposit",
  templateUrl: "./codere-footer-deposit.html",
  standalone: true,
  imports: [CommonModule],
  styleUrls: ["./../codere-footer/codere-footer.scss"],
})
export class CodereFooterDeposit implements OnInit {
  @Input() imagesDeposits: FooterImage[] = [];
  isDesktop!: boolean;

  globalVars!: GlobalVarsModel;
  deviceService = inject(DeviceService);
  newBaseService = inject(NewBaseService);

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();

    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  ngOnInit(): void {
    const deposit = this.imagesDeposits;
    for (const s of deposit) {
      switch (s.alt) {
        case "Bizum":
          s.src = "assets/global/img/cs-logo_Bizum.png";
          break;
        case "Book Codere":
          s.src = "assets/global/img/cs-logo_LocalCodere.png";
          break;
        case "Visa":
          s.src = "assets/global/img/cs-logo_Visa.png";
          break;
        case "Mastercad":
          s.src = "assets/global/img/cs-logo_Mastercard.png";
          break;
        case "Transferencia":
          s.src = "assets/global/img/cs-logo-TransferenciaBancaria.png";
          break;
        case "Neteller":
          s.src = "assets/global/img/cs-logo_Neteller.png";
          break;
        case "Skrill":
          s.src = "assets/global/img/cs-logo_Skrill.png";
          break;
        case "Paypal":
          s.src = "assets/global/img/cs-logo_PayPal-darkbkg.png";
          break;
        case "Paysafecard":
          s.src = "assets/global/img/cs-logo_PaySafe-darkbkg.png";
          break;
        case "Muchbetter":
          s.src = "assets/global/img/cs-logo_MuchBetter.png";
          break;
        case "Halcash":
          s.src = "assets/global/img/cs-logo_HalCash.png";
          break;
        case "Rapid Transfer":
          s.src = "assets/global/img/cs-logo_RapidTransfer-darkbkg.png";
          break;
        case "Astropay":
          s.src = "assets/global/img/cs-logo_Astropay.png";
          break;
        default:
      }
    }
  }

  canShowDeposit(deposit: FooterImage): boolean {
    return deposit.show === undefined || deposit.show;
  }
}
