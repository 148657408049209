import { Directive, ElementRef, HostListener, inject } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { NewBaseService } from "@providers/newBase.service";

@Directive({
  selector: "[hover-card]", // Attribute selector
})
export class HoverCard {
  globalVars!: GlobalVarsModel;
  isDesktop = false;
  newBaseService = inject(NewBaseService);
  element = inject(ElementRef);
  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isDesktop = Capacitor.getPlatform() === "web";
  }

  @HostListener("mouseenter", ["$event"])
  @HostListener("mouseleave", ["$event"])
  toggleHover(ev: any) {
    if (!this.isDesktop) {
      return;
    }
    const hover = this.element.nativeElement.getElementsByClassName("hover-actions")[0];
    ev.type === "mouseenter" ? hover.classList.add("active") : hover.classList.remove("active");
  }
}
