import { Injectable, inject } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { EMPTY, catchError, map, switchMap, withLatestFrom } from "rxjs";

import * as MyBetsActions from "./my-bets.actions";
import { MyBetsState } from "./my-bets.reducers";

import { NewBaseService } from "@providers/newBase.service";
import { SportMyBetsService } from "@sports-services/sports.my-bets.service";

import { I_CashoutData, I_MyBetsModel } from "@sports-models/index";

@Injectable({ providedIn: "root" })
export class MyBetsEffects {
  newBaseService = inject(NewBaseService);
  myBetsService = inject(SportMyBetsService);
  actions$ = inject(Actions);
  store = inject(Store<MyBetsState>);

  fetchMyBets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MyBetsActions.FetchMyBets),
      switchMap((params) =>
        this.myBetsService.getMyBets(params).pipe(
          map((myBetsTickets: I_MyBetsModel[]) => MyBetsActions.SetMyBets({ myBetsTickets })),
          catchError(() => {
            this.myBetsService.hideSpinner();
            return EMPTY;
          }),
        ),
      ),
    ),
  );

  getCashoutByTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MyBetsActions.GetCashoutByTicket),
      withLatestFrom(this.store.select("myBets")),
      switchMap(([CashoutParams, myBetsState]) =>
        this.myBetsService.getCashoutByTicket(CashoutParams.CashoutParams).pipe(
          map((CashoutTicketData: I_CashoutData) => {
            const myBetsTickets = myBetsState["myBetsTickets"];
            const NewMyBetsTickets = this.myBetsService.updateCahoutByTicket(
              CashoutParams.CashoutParams,
              CashoutTicketData,
              myBetsTickets,
            );
            this.myBetsService.hideSpinner();
            return MyBetsActions.SetMyBets({ myBetsTickets: NewMyBetsTickets });
          }),
          catchError(() => {
            this.myBetsService.hideSpinner();
            return EMPTY;
          }),
        ),
      ),
    ),
  );

  constructor() {}
}
