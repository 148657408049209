import { CommonModule } from "@angular/common";
import {
  type AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  type ElementRef,
  Input,
  type OnInit,
  ViewChild,
  inject,
} from "@angular/core";
import { Router } from "@angular/router";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { IonicModule } from "@ionic/angular";
import { CookieNames } from "@models/CookieNames";
import { I_NewCarousel } from "@models/I_NewCarousel";
import { I_UserInfo } from "@models/I_UserInfo";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { CookiesService } from "@providers/CookiesService";
import { EventsService } from "@providers/EventsService";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { CASINO_PATHS } from "@shared-constants/routes";
import { NewTicketFacade } from "@sports-stores/ticket/index";
import { Swiper, SwiperOptions } from "swiper";
import { CodereSliderContainer } from "../codere-slider-container/codere-slider-container"; // **+** error al importar en el componente @shared por codere-slider-container por swiper

@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "codere-slider-csv",
  templateUrl: "./codere-slider-csv.html",
  standalone: true,
  styleUrls: ["./codere-slider-csv.scss"],
  imports: [CodereSliderContainer, CommonModule, IonicModule],
})
export class CodereSliderCsvComponent implements OnInit, AfterViewInit {
  @Input()
  banners: I_NewCarousel[] = [];
  auxBanners: I_NewCarousel[] = [];
  optionsSlider;
  visible = true;
  globalVars!: GlobalVarsModel;

  @ViewChild("swiperCSV") swiperRef:
    | ElementRef<HTMLElement & { swiper?: Swiper } & { initialize: () => void }>
    | undefined;
  @ViewChild("bullets") bullets: ElementRef<HTMLElement>;
  @ViewChild("btnprev") btnprev: ElementRef<HTMLElement>;
  @ViewChild("btnext") btnext: ElementRef<HTMLElement>;

  swiper?: Swiper;

  config: SwiperOptions;
  newBaseService = inject(NewBaseService);
  cookiesService = inject(CookiesService);
  events = inject(EventsService);
  router = inject(Router);
  isDesktop: boolean;
  deviceService = inject(DeviceService);
  private newTicketFacade = inject(NewTicketFacade);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.isDesktop = this.deviceService.isDesktop();
  }

  ngOnInit(): void {
    this.events.subscribe("refreshBanners", () => {
      this.setAuxbanners();
      const swiperEl = Object.assign(this.swiperRef?.nativeElement, this.config);
      swiperEl.initialize();
      this.swiper = this.swiperRef?.nativeElement.swiper;
    });

    this.setAuxbanners();
  }

  setAuxbanners() {
    this.auxBanners = this.banners.filter((b) => this.canShowSlider(b));
  }

  canShowSlider(slider: I_NewCarousel): boolean {
    let canShow = false;
    let dateStart: string = slider.startDate;
    let dateEnd: string = slider.endDate;
    if (slider.fromHour) {
      dateStart += ` ${slider.fromHour}`;
    }
    if (slider.toHour) {
      dateEnd += ` ${slider.toHour}`;
    }

    if (this.canShowByDates(dateStart, dateEnd)) {
      switch (slider.showLogged) {
        case "ShowForLoggedOut":
          canShow = !this.globalVars.user.logged;
          break;
        case "ShowForLoggedInAndLoggedOut":
          canShow = true;
          break;
        case "ShowForLoggedIn":
          canShow = this.globalVars.user.logged;
          break;
        case "ShowForLoggedInEligibleUser":
          canShow = this.ShowForLoggedInEligibleUser(slider.vipLevel);
          break;
        case "ShowForLoggedInNotEligibleUser":
          canShow = this.ShowForLoggedInNotEligibleUser();
          break;
      }
    }
    return canShow;
  }

  ShowForLoggedInNotEligibleUser(): boolean {
    let canShow = false;

    if (this.globalVars.user.logged) {
      const valueCookie: string = this.cookiesService.getCookie(CookieNames.UserInfo);
      if (valueCookie) {
        const valueCookieObj = JSON.parse(valueCookie);
        canShow = !valueCookieObj.eligibilityStatus;
      }
    }

    return canShow;
  }

  canShowByDates(start: string, end: string): boolean {
    const dateStart: number = new Date(start).getTime();
    const dateEnd: number = new Date(end).getTime();
    const dateNow: number = new Date().getTime();

    return dateStart <= dateNow && dateEnd >= dateNow;
  }

  ShowForLoggedInEligibleUser(vipLevel: string): boolean {
    let canShow = false;
    if (this.globalVars.user.logged) {
      const valueCookie: string = this.cookiesService.getCookie(CookieNames.UserInfo);
      if (valueCookie) {
        const valueCookieObj: I_UserInfo = JSON.parse(valueCookie);
        const isVipEstado = valueCookieObj.vipEstado && valueCookieObj.vipEstado.toLowerCase() === "yes";
        switch (true) {
          case vipLevel == "NO-VIP-LEVEL" &&
            ((!this.globalVars.FEATURES.MSO_checkElegibleBanners &&
              (!valueCookieObj.vipLevel || valueCookieObj.vipLevel == "")) ||
              !isVipEstado):
            canShow = true;
            break;
          case vipLevel == "ALL-VIP-LEVELS" &&
            ((!this.globalVars.FEATURES.MSO_checkElegibleBanners &&
              valueCookieObj.vipLevel &&
              valueCookieObj.vipLevel != "") ||
              isVipEstado):
            canShow = true;
            break;
          case (!this.globalVars.FEATURES.MSO_checkElegibleBanners || isVipEstado) &&
            vipLevel.includes(valueCookieObj.vipLevel.toUpperCase()):
            canShow = true;
            break;
          case vipLevel.toLowerCase() == "under26" &&
            !isVipEstado &&
            valueCookieObj.vipLevel?.toLowerCase() === "under26":
            canShow = true;
            break;
          case (!this.globalVars.FEATURES.MSO_checkElegibleBanners || isVipEstado) &&
            vipLevel.toLowerCase() == "over26" &&
            valueCookieObj.vipLevel?.toLowerCase() !== "under26":
            canShow = true;
            break;
        }
      }
    }
    return canShow;
  }

  clickSlide(slide: I_NewCarousel): void {
    switch (slide.typeOfTemplate) {
      case "Simple image":
      case "Just text":
      case "Casino":
      case "Slots":
        if (slide.simpleImageTemplateOrTextLink.startsWith("http")) {
          if (Capacitor.isNativePlatform()) {
            this.openExternalLinkNative(slide.simpleImageTemplateOrTextLink);
          } else {
            window.open(slide.simpleImageTemplateOrTextLink, "_blank");
          }
        } else {
          if (slide.simpleImageTemplateOrTextLink.toLocaleLowerCase().includes("slotspage")) {
            this.goToSlots(slide.simpleImageTemplateOrTextLink.split("?")[1]);
          } else if (slide.simpleImageTemplateOrTextLink.toLocaleLowerCase().includes("casinopage")) {
            this.goToCasino(slide.simpleImageTemplateOrTextLink.split("?")[1]);
          } else {
            this.globalVars.rootScope.tryToGoPage(slide.simpleImageTemplateOrTextLink);
          }
        }
        break;
      case "One Odd":
        this.newTicketFacade.addBetByLink(slide.eventId);
        break;
    }
  }

  public async openExternalLinkNative(link = "") {
    const options = {
      location: "yes",
      hidden: "no",
      closebuttoncaption: "Cerrar",
      closebuttoncolor: "#ffffff",
      footer: "no",
      footercolor: "#000000",
      hardwareback: "yes",
      hidenavigationbuttons: "no",
      hideurlbar: "yes",
      navigationbuttoncolor: "#ffffff",
      toolbarcolor: "#000000",
      zoom: "yes",
      mediaPlaybackRequiresUserAction: "no",
      shouldPauseOnSuspend: "yes",
      useWideViewPort: "yes",
      toolbar: "yes",
      toolbartranslucent: "yes",
      enableViewportScale: "yes",
      allowInlineMediaPlayback: "yes",
      keyboardDisplayRequiresUserAction: "no",
      suppressesIncrementalRendering: "no",
      presentationstyle: "fullscreen", //pagesheet, formsheet, fullscreen
      transitionstyle: "coververtical", //fliphorizontal, crossdissolve, coververtical
      toolbarposition: "bottom", //top, bottom
      hidespinner: "no",
    };

    try {
      await Browser.open({ url: encodeURI(link), ...options });
    } catch (error) {
      console.error("Error opening browser:", error);
    }
  }

  listenerContainerEvent($event) {
    this.btnClickSlide($event.slide, $event.type);
  }

  btnClickSlide(slide: I_NewCarousel, event = "") {
    switch (event) {
      case "leftTeam":
        this.newTicketFacade.addBetByLink(slide.leftTeamBetKey);
        break;
      case "rightTeam":
        this.newTicketFacade.addBetByLink(slide.rightTeamBetKey);
        break;
      case "draw":
        this.newTicketFacade.addBetByLink(slide.drawBetKey);
        break;
      case "goTo":
        this.globalVars.rootScope.tryToGoPage(
          `MercadosPage?NodeId=${slide.matchKey}&LeagueName=${slide.leagueName}&Name=${slide.leftTeam} - ${
            slide.rightTeamName
          }&Sporthandle=${slide.sportTypeName}&SportName=${this.getSportName(
            slide.sportTypeName,
          )}&StartDate=/Date(${this.getDateFromString(slide.matchHour).getTime()})/'`,
        );
        break;
    }
  }

  getSportName(sportHandle = "") {
    let sportName = "";
    switch (sportHandle) {
      case "billard":
        sportName = "Billar";
        break;
      case "esports":
        sportName = "Esports";
        break;
      case "golf":
        sportName = "Golf";
        break;
      case "handball":
        sportName = "Balonmano";
        break;
      case "motor":
        sportName = "Motor";
        break;
      case "snooker":
        sportName = "Snooker";
        break;
      case "soccer_indoor":
        sportName = "Futbol Sala";
        break;
      case "soccer":
        sportName = "Futbol";
        break;
      case "basketball":
        sportName = "Baloncesto";
        break;
      case "darts":
        sportName = "Dardos";
        break;
      case "rugby":
        sportName = "Rugby Union";
        break;
      case "baseball":
        sportName = "Baseball";
        break;
      case "futsal":
        sportName = "Futsal";
        break;
      case "ice_hockey":
        sportName = "Hockey sobre hielo";
        break;
      case "american_football":
        sportName = "Futbol Americano";
        break;
      case "badminton":
        sportName = "Badminton";
        break;
      case "beach_soccer":
        sportName = "Futbol Playa";
        break;
      case "beach_volleyball":
        sportName = "Voley Playa";
        break;
      case "table_tennis":
        sportName = "Tenis de mesa";
        break;
      case "volleyball":
        sportName = "Voleyball";
        break;
      case "tennis":
        sportName = "Tenis";
        break;
    }
    return sportName;
  }
  getDateFromString(dateString: string) {
    const fecchaString: string = dateString.split(" ")[0];
    const horaString: string = dateString.split(" ")[1];
    const newDate: Date = new Date(
      Number(fecchaString.split("/")[2]),
      Number(fecchaString.split("/")[1]) - 1,
      Number(fecchaString.split("/")[0]),
    );
    newDate.setHours(Number(horaString.split(":")[0]));
    newDate.setMinutes(Number(horaString.split(":")[1]));
    newDate.setSeconds(Number(horaString.split(":")[2]));
    return newDate;
  }

  isTeamMach(slide: I_NewCarousel) {
    return (
      slide.typeOfTemplate === "Two teams without draw" ||
      slide.typeOfTemplate === "Two teams with draw" ||
      slide.typeOfTemplate === "Tennis Match"
    );
  }

  ngAfterViewInit(): void {
    this.config = {
      loop: true,
      slidesPerView: "auto",
      autoplay: {
        delay: 5000,
      },
      navigation: {
        nextEl: this.isDesktop ? this.btnext?.nativeElement : "swiper-button-next-csv",
        prevEl: this.isDesktop ? this.btnprev?.nativeElement : "swiper-button-prev-csv",
        enabled: this.isDesktop,
      },
      pagination: {
        el: this.bullets?.nativeElement,
        type: "bullets",
        clickable: true,
      },
      observer: true,
    };
    if (this.swiperRef) {
      const swiperEl = Object.assign(this.swiperRef?.nativeElement, this.config);
      swiperEl.initialize();
      this.swiper = this.swiperRef?.nativeElement.swiper;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goToSlots(params: string) {
    let lobby = this.globalVars.FEATURES.Lobbies.find((lb) => lb.LobbyName.toLowerCase().indexOf("playtech") >= 0);
    lobby === undefined ? (lobby = this.globalVars.FEATURES.Lobbies[0].LobbyName) : (lobby = lobby.LobbyName);
    this.router.navigate([
      `/${CASINO_PATHS.BASE}/${CASINO_PATHS.CasinoPage}`,
      {
        lobby,
        ...this.formatParams(params),
      },
    ]);
  }

  goToCasino(params: string) {
    this.router.navigate([`/${CASINO_PATHS.BASE}/${CASINO_PATHS.CasinoPage}`, { ...this.formatParams(params) }]);
  }

  formatParams(params: string) {
    const paramObj = {};
    if (params) {
      const paramet: string[] = params.split("&");
      paramet.forEach((value) => {
        const valueParam = value.split("=");
        paramObj[valueParam[0]] = valueParam[1];
      });
    }

    return paramObj;
  }
}
