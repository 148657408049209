/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable eqeqeq */
import { CommonModule } from "@angular/common";
import { Component, type ElementRef, ViewChild, inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { CapacitorGetPlatform, SourcesCDR } from "@constants";
import {
  IonContent,
  IonicModule,
  MenuController,
  ModalController,
  NavController,
  NavParams,
} from "@ionic/angular";
import { CookieNames } from "@models/CookieNames";
import { I_SignupPaForm } from "@models/I_SignupPADecoupling";
import { I_UserPanama } from "@models/I_UserPanama";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { EventTypes } from "@models/TrackingEvents";
import { TranslateService } from "@ngx-translate/core";
import { AppsFlyerTrackingService } from "@providers/AppsFlyerTrackingService";
import { CookiesService } from "@providers/CookiesService";
import { DecouplingUserServiceMSO, type ErrorDecoupling } from "@providers/DecouplingUserServiceMSO.service";
import { EventsService } from "@providers/EventsService";
import { NativeService } from "@providers/NativeService";
import { SlotsBonusesService } from "@providers/SlotsBonusesService";
import { TrackingService } from "@providers/TrackingService";
import { UserService } from "@providers/UserService";
import { UserServiceMSO } from "@providers/UserServiceMSO";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { Utils } from "@utils/Utils";

@Component({
  templateUrl: "./registroPA.html",
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "registroPA-page",
  styleUrls: ["./registroPA.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule],
  providers: [NavParams],
})
export class RegistroPAPage {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild("iframe") iframeNode: ElementRef;

  codepromotion: string;
  scrReg: any;
  regListener: any;
  isMobile = false;
  isDesktop = false;
  globalVars!: GlobalVarsModel;

  nav = inject(NavController);
  utils = inject(Utils);
  userService = inject(UserService);
  userServiceMSO = inject(UserServiceMSO);
  menu = inject(MenuController);
  translate = inject(TranslateService);
  trackingService = inject(TrackingService);
  navParams = inject(NavParams);
  modalCtrl = inject(ModalController);
  cookiesService = inject(CookiesService);
  newBaseService = inject(NewBaseService);
  slotsBonusesService = inject(SlotsBonusesService);
  events = inject(EventsService);
  sanitizer = inject(DomSanitizer);
  deviceService = inject(DeviceService);
  router = inject(Router);
  viewCtrl = inject(ModalController);
  appsflyerTrackingService = inject(AppsFlyerTrackingService);
  private nativeService = inject(NativeService);

  decouplingServiceMSO = inject(DecouplingUserServiceMSO);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.isMobile = this.deviceService.isMobile();
    this.isDesktop = this.deviceService.isDesktop();

    this.codepromotion = "";
    const tempCode: string = this.cookiesService.getCookie(CookieNames.CoderePromotionCode);
    if (tempCode != "") {
      this.codepromotion = tempCode;
    }
    if (this.globalVars.codePromotion) {
      this.codepromotion = this.globalVars.codePromotion;
      if (this.codepromotion != tempCode) {
        this.cookiesService.setCookie(
          CookieNames.CoderePromotionCode,
          this.codepromotion,
          3 * 24 * 60 * 60 * 1000,
          true,
        );
      }
    }
    if (this.navParams.get("item")) {
      if (this.navParams.get("item").codepromotion) {
        this.codepromotion = this.navParams.get("item").codepromotion;
        if (this.codepromotion != tempCode) {
          this.cookiesService.setCookie(
            CookieNames.CoderePromotionCode,
            this.codepromotion,
            3 * 24 * 60 * 60 * 1000,
            true,
          );
        }
      }
    }
    if (this.navParams.get("codepromotion")) {
      this.codepromotion = this.navParams.get("codepromotion");
      if (this.codepromotion != tempCode) {
        this.cookiesService.setCookie(
          CookieNames.CoderePromotionCode,
          this.codepromotion,
          3 * 24 * 60 * 60 * 1000,
          true,
        );
      }
    }

    const urlRegPa = this.globalVars.FEATURES.MSO_NewDecouplingServiceSignup
      ? "RegistroPADecoupling"
      : "registropa";

    if (this.codepromotion) {
      this.scrReg = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.globalVars.URLBASE +
          `${urlRegPa}/reg.html?iswebapppc=` +
          this.isDesktop +
          "&codepromotion=" +
          this.codepromotion +
          "&v=" +
          new Date().getTime(),
      );
    } else {
      //change registropa
      this.scrReg = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.globalVars.URLBASE +
          `${urlRegPa}/reg.html?iswebapppc=` +
          this.isDesktop +
          "&v=" +
          new Date().getTime(),
      );
    }

    this.regListener = this.listenEvents.bind(this);
  }

  ionViewDidLoad() {}

  ionViewWillLeave(event) {
    this.utils.unloadContactChat();
    window.removeEventListener("message", this.regListener);
    this.utils.hideFormAccessoryBar(true);
    if (this.isMobile) {
      const codereNavBarElement = document.querySelector("codere-navbar") as HTMLElement;
      if (codereNavBarElement) {
        codereNavBarElement.style.display = "block";
      }
    }
  }

  backToHome() {
    // this.nav.setRoot(this.globalVars.mainPageComponent);
    this.router.navigate(["/"]);
  }

  ionViewWillEnter() {
    this.utils.hideFormAccessoryBar(false);
    window.addEventListener("message", this.regListener);
    this.trackingService.trackEvent(["AccessToRegisterFromButton", "", "", "Registro desde boton", "event"]);

    // New tracking services
    this.trackingService.track({ eventType: EventTypes.Lead });

    this.slotsBonusesService.taboolaTrack("lead");
  }

  listenEvents(e: any) {
    if (e.data["action"] == "RegOK") {
      let verifDocum = false;
      if (!e.data.verifDocum) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        verifDocum = true;
      }

      this.utils.loginAndGoto("/", null, false).then((isOk) => {
        if (isOk) {
          this.closeModal();
        } else {
          this.linkOutOfModalPage(this.router.url || "/", {});
        }
      });
      window.removeEventListener("message", this.regListener);
    }
    if (e.data["action"] == "closeModal") {
      window.removeEventListener("message", this.regListener);
      if (this.isDesktop) {
        this.closeModal();
      } else {
        this.router.navigateByUrl("/");
      }
    }
    if (e.data["action"] == "trackEvent") {
      this.trackingService.trackEvent(e.data["event"]);
    }
    if (e.data["action"] == "refresh") {
      const iframe: any = document.getElementsByClassName("iframeFullScreen").item(0);
      iframe.style = "display:none";
      iframe.style = "display:block";
    }
    if (e.data["action"] == "startIFrame") {
      const iframeElem = this.iframeNode.nativeElement;
      const obj = JSON.parse(JSON.stringify(this.navParams.data));
      setTimeout(iframeElem.contentWindow.postMessage(obj, "*"), 300, 300);
    }
    if (e.data.action === "doRegister") {
      if (this.globalVars.FEATURES.MSO_NewDecouplingServiceSignup) {
        this.doSignupDecoupling(e.data.user);
      } else {
        this.doRegister(e.data.user);
      }
    }

    if (e.data.action === "taboolatrack") {
      this.slotsBonusesService.taboolaTrack(e.data.track);
    }
    if (e.data.action === "adftrack") {
      window["_adftrack"] = Array.isArray(window["_adftrack"])
        ? window["_adftrack"]
        : window["_adftrack"]
          ? [window["_adftrack"]]
          : [];
      window["_adftrack"].push({
        HttpHost: "track.adform.net",
        pm: 2326828,
        divider: encodeURIComponent("|"),
        pagename: encodeURIComponent("Registro"),
        order: {
          orderid: "<insert order id value here>",
        },
      });
    }
  }

  doRegister(user: I_UserPanama) {
    const iframeElem = this.iframeNode.nativeElement;
    this.utils.loader();
    try {
      this.userServiceMSO.registerPanamaUserService(user).subscribe({
        next: (data) => {
          this.events.publish("loader:dismiss", null);
          if ((typeof data.status === "string" && data.status.toUpperCase() === "OK") || data.status === 0) {
            this.trackingService.trackEvent([
              "FinishRegister",
              "OK",
              user.account.username,
              user.contact.email,
              "event",
            ]);

            if (Capacitor.getPlatform() !== CapacitorGetPlatform.WEB) {
              this.appsflyerTrackingService.trackEvent("af_complete_registration", {
                userId: user.account.username,
              });

              // Xtreme Registration event
              this.nativeService.xtremeHitEvent("Registration", "Registration Event");
            }

            iframeElem.contentWindow.postMessage(
              {
                action: "RegOK",
                data,
              },
              "*",
            );
            this.events.publish("loader:dismiss", null);
          } else {
            iframeElem.contentWindow.postMessage(
              {
                action: "RegKO",
                code: data.status,
                message: data.Message,
                description: data.errorDescription,
              },
              "*",
            );
            this.events.publish("loader:dismiss", null);
          }
        },
        error: (error) => {
          this.events.publish("loader:dismiss", null);
          this.trackingService.trackEvent(["FinishRegister", "KO", "ERR", "Fin Registro KO", "event"]);
          iframeElem.contentWindow.postMessage(
            {
              action: "RegKO",
              code: -1,
            },
            "*",
          );
        },
      });
    } catch (error) {
      this.events.publish("loader:dismiss", null);
      this.trackingService.trackEvent(["FinishRegister", "KO", "ERR", "Fin Registro KO", "event"]);
      iframeElem.contentWindow.postMessage(
        {
          action: "RegKO",
          code: -1,
        },
        "*",
      );
    }
  }

  doSignupDecoupling(user: I_SignupPaForm) {
    const iframeElem = this.iframeNode.nativeElement;
    user.source = this.globalVars.isNative ? SourcesCDR.NativeApp : SourcesCDR.WebApp;
    this.decouplingServiceMSO.signupPa(user).subscribe({
      next: ({ bgtUserId }) => {
        this.trackingService.trackEvent(["FinishRegister", "OK", user.playerUserName, user.email, "event"]);

        if (Capacitor.getPlatform() !== CapacitorGetPlatform.WEB) {
          this.appsflyerTrackingService.trackEvent("af_complete_registration", {
            userId: user.playerUserName,
          });
        }

        // Xtreme Registration event
        this.nativeService.xtremeHitEvent("Registration", "Registration Event");

        iframeElem.contentWindow.postMessage(
          {
            action: "RegOK",
            data: {
              bgtUserId,
              userName: user.playerUserName,
            },
          },
          "*",
        );
      },
      error: (err) => {
        const errResponse = err as ErrorDecoupling;

        this.trackingService.trackEvent([
          "FinishRegister",
          "KO",
          errResponse ? errResponse?.code || "ERR" : "ERR",
          "Fin Registro KO",
          "event",
        ]);

        iframeElem.contentWindow.postMessage(
          {
            action: "RegKO",
            code: errResponse ? errResponse?.type || -1 : -1,
            message: errResponse ? errResponse?.code || "" : "",
            description: errResponse ? errResponse?.description || "" : "",
          },
          "*",
        );
      },
    });
  }

  linkOutOfModalPage(link: any, paymentArguments: any) {
    if (this.isMobile) {
      this.navigateToLink(link, paymentArguments);
    } else {
      this.viewCtrl.dismiss(null, null, { animate: false } as any).then(() => {
        this.navigateToLink(link, paymentArguments);
      });
    }
  }
  private navigateToLink(link: any, paymentArguments: any) {
    this.router.navigate([link], {
      queryParams: paymentArguments,
      queryParamsHandling: "merge",
      skipLocationChange: false,
      replaceUrl: false,
      state: { animate: !this.isDesktop },
    });
  }
  closeModal() {
    if (this.viewCtrl) {
      this.viewCtrl.dismiss();
    }
  }

  openChat() {
    this.utils.loadContactChat();
    setTimeout(
      () => {
        this.utils.openContactChat();
      },
      this.globalVars.FEATURES.newChatEnabled ? 1 : 800,
    );
  }
}
