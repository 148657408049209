import { Route } from "@angular/router";
import { CanDeactivateGuard } from "@guards/canDeactivate.guard";
import { LobbyCasinoPage } from "@shared-casino-pages/lobby-casino/lobby-casino";
import { CASINO_PATHS } from "@shared-constants/routes";
import { SlotsBonusPage } from "./pages/slots-bonus/slots-bonus";
import { SlotsHistoryPage } from "./pages/slots-history/slots-history";

export const CASINO_ROUTES: Route[] = [
  {
    path: "",
    redirectTo: CASINO_PATHS.CasinoPage,
    pathMatch: "full",
  },
  {
    path: CASINO_PATHS.CasinoPage,
    component: LobbyCasinoPage,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: CASINO_PATHS.SlotsPage,
    component: LobbyCasinoPage,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: CASINO_PATHS.AviatorPage,
    component: LobbyCasinoPage,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: CASINO_PATHS.FruitBlastPage,
    component: LobbyCasinoPage,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: CASINO_PATHS.VIPPage,
    component: LobbyCasinoPage,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: CASINO_PATHS.JackpotPage,
    component: LobbyCasinoPage,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: CASINO_PATHS.OmnichannelPage,
    component: LobbyCasinoPage,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: CASINO_PATHS.SlotsdeSalonPage,
    component: LobbyCasinoPage,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: CASINO_PATHS.WonderfulLampPage,
    component: LobbyCasinoPage,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: CASINO_PATHS.CasinoEnVivoPage,
    component: LobbyCasinoPage,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: CASINO_PATHS.SlotsBonusPage,
    component: SlotsBonusPage,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: CASINO_PATHS.BlackjackPage,
    component: LobbyCasinoPage,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: CASINO_PATHS.SlotsHistoryPage,
    component: SlotsHistoryPage,
    data: { section: "user" },
  },
];
