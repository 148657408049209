import { PaymentMethods } from "@models/C_Transactions";

//MSO GTM
export const PAYMENTMETHODSTRACK: { [key in PaymentMethods]: keyof typeof PaymentMethods } = {
  [PaymentMethods.AMEX]: "AMEX",
  [PaymentMethods.AlmacenesExito]: "AlmacenesExito",
  [PaymentMethods.AmericanExpress]: "AmericanExpress",
  [PaymentMethods.ApuestasCucuta]: "ApuestasCucuta",
  [PaymentMethods.AstropayEPG]: "AstropayEPG",
  [PaymentMethods.BalotoDirecta24]: "BalotoDirecta24",
  [PaymentMethods.Banamex]: "Banamex",
  [PaymentMethods.BancoAzteca]: "BancoAzteca",
  [PaymentMethods.BancoColombia]: "BancoColombia",
  [PaymentMethods.Bancomer]: "Bancomer",
  [PaymentMethods.Banorte]: "Banorte",
  [PaymentMethods.BbvaMxSms]: "BbvaMxSms",
  [PaymentMethods.Bizum]: "Bizum",
  [PaymentMethods.Bono]: "Bono",
  [PaymentMethods.BonoSlots]: "BonoSlots",
  [PaymentMethods.Carulla]: "Carulla",
  [PaymentMethods.Clave]: "Clave",
  [PaymentMethods.CoinsPaid]: "CoinsPaid",
  [PaymentMethods.Compensacintransaccinerrnea]: "Compensacintransaccinerrnea",
  [PaymentMethods.Cybersource]: "Cybersource",
  [PaymentMethods.Daviplata]: "Daviplata",
  [PaymentMethods.Decidir]: "Decidir",
  [PaymentMethods.DepositoOxxo]: "DepositoOxxo",
  [PaymentMethods.Depositoticketpremiado]: "Depositoticketpremiado",
  [PaymentMethods.Efectivo]: "Efectivo",
  [PaymentMethods.FREEbonus]: "FREEbonus",
  [PaymentMethods.GanaCercadeTi]: "GanaCercadeTi",
  [PaymentMethods.GanaGana]: "GanaGana",
  [PaymentMethods.Halcash]: "Halcash",
  [PaymentMethods.JER]: "JER",
  [PaymentMethods.MasterCard]: "MasterCard",
  [PaymentMethods.MuchBetter]: "MuchBetter",
  [PaymentMethods.MuchBetterEPG]: "MuchBetterEPG",
  [PaymentMethods.Neteller]: "Neteller",
  [PaymentMethods.OpenPayPaynet]: "OpenPayPaynet",
  [PaymentMethods.PSEDirecta24]: "PSEDirecta24",
  [PaymentMethods.PagaTodo]: "PagaTodo",
  [PaymentMethods.Pagos360]: "Pagos360",
  [PaymentMethods.PagueloFacil]: "PagueloFacil",
  [PaymentMethods.PaySafeCard]: "PaySafeCard",
  [PaymentMethods.Paynet]: "Paynet",
  [PaymentMethods.Paypal]: "Paypal",
  [PaymentMethods.Premioapuestaganadora]: "Premioapuestaganadora",
  [PaymentMethods.PromoMXCodere]: "PromoMXCodere",
  [PaymentMethods.Puntos]: "Puntos",
  [PaymentMethods.Rappi]: "Rappi",
  [PaymentMethods.SPEI]: "SPEI",
  [PaymentMethods.Santander]: "Santander",
  [PaymentMethods.Skrill]: "Skrill",
  [PaymentMethods.SorteoCodere]: "SorteoCodere",
  [PaymentMethods.Surtimax]: "Surtimax",
  [PaymentMethods.Tarjetanominativa]: "Tarjetanominativa",
  [PaymentMethods.Teleingreso]: "Teleingreso",
  [PaymentMethods.ToditoCash]: "ToditoCash",
  [PaymentMethods.Tpaga]: "Tpaga",
  [PaymentMethods.Transaccionenpuntodeventa]: "Transaccionenpuntodeventa",
  [PaymentMethods.Transaccionenweb]: "Transaccionenweb",
  [PaymentMethods.Transferenciainterna]: "Transferenciainterna",
  [PaymentMethods.Transferenciarapida]: "Transferenciarapida",
  [PaymentMethods.Transferenciabancaria]: "Transferenciabancaria",
  [PaymentMethods.Ukash]: "Ukash",
  [PaymentMethods.Visa]: "Visa",
};
