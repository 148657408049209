export enum WithdrawalMethodsClassName {
  BANK_TRANSFER = "BankTransferWithDrawal",
  ITAU = "ItauWithDrawal",
  TRUE_LAYER = "TrueLayerWithdrawal",
  CLABE = "ClabeWithDrawal",
  EPG_MUCH_BETTER = "EPGMuchBetterWithDrawal",
  NEQUI = "NequiWithdrawal",
  DAVIPLATA = "DaviPlataWithdrawal",
  PSE = "PSEWithDrawal",
  CARD_SELECTION = "CardSelectionWithdrawal",
  LOTBA = "LotbaWithdrawal",
  HAL_CASH = "HalCashWithDrawal",
  T_PAGA = "TPagaWithDrawal",
  PAY_PAL = "PayPalWithDrawal",
  LOCAL = "LocalWithDrawal",
  ASTROPAY = "AstropayWithdrawal",
  COINS_PAID = "CoinspaidWithDrawal",
  EFECTY = "EfectyWithDrawal",
  CROWN_CASINO = "CrownCasinoWithdrawal",
}
