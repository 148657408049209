import { Directive, Input, inject } from "@angular/core";
import { LogService } from "../../providers/LogService";
import { TrackingService } from "../../providers/TrackingService";

@Directive({
  selector: "[track-event]",
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    "(click)": "doTrackEvent()",
  },
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class TrackEvent {
  @Input("track-event") trackEventStr: Array<string>;
  trackingService = inject(TrackingService);
  logService = inject(LogService);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  doTrackEvent() {
    this.logService.log("-->track event->>", this.trackEventStr);
    this.trackingService.trackEvent(this.trackEventStr);
  }
}
