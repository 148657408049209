import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output, Renderer2, inject } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { CameraAccessInterface } from "@models/payments/I_VerificationUploadConfig";
import { TranslateService } from "@ngx-translate/core";
import { NativeService } from "@providers/NativeService";
import { UserService } from "@providers/UserService";
import { Utils } from "@utils/Utils";
import { CsDocumentIndicationsCameraComponent } from "../cs-document-indications-camera/cs-document-indications-camera";

@Component({
  selector: "cs-document-indications",
  templateUrl: "./cs-document-indications.html",
  styleUrls: ["./cs-document-indications.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule, CsDocumentIndicationsCameraComponent],
  host: {
    class: "cs-document-indications background-color-white color-dark",
  },
})
export class CsDocumentIndicationsComponent implements OnInit {
  sendStep: string;
  textTitle: string;
  textTitle02: string = "";
  textParagraph: string;
  activeCamera: boolean;
  textParagraph02: string = "";

  @Input() stepInstructions: number;

  @Output() closeSelfieModal: EventEmitter<any> = new EventEmitter();
  @Output() emitAccessCamera: EventEmitter<CameraAccessInterface> = new EventEmitter();
  @Output() emitAccessFiles: EventEmitter<any> = new EventEmitter();

  private _renderer = inject(Renderer2);
  public userService = inject(UserService);
  public utils = inject(Utils);
  public translate = inject(TranslateService);
  public nativeService = inject(NativeService);

  constructor() {
    this.stepInstructions = 1;
    this.activeCamera = false;
  }

  ngOnInit(): void {
    switch (this.stepInstructions) {
      case 1:
        this.sendStep = "inputOne";
        this.textTitle = "RECOMENDACIONES PARA SUBIR EL FRONTAL DEL DNI";
        this.textParagraph =
          "Asegúrate de hacer la foto con el móvil en vertical. Se deberá ver la identificación completa. Los detalles tienen que ser visibles claramente, sin flases o áreas cubiertas.";
        break;
      case 2:
        this.sendStep = "inputTwo";
        this.textTitle = "RECOMENDACIONES PARA SUBIR EL REVERSO DEL DNI";
        this.textParagraph =
          "Asegúrate de hacer la foto con el móvil en vertical. Se deberá ver la identificación completa. Los detalles tienen que ser visibles claramente, sin flases o áreas cubiertas.";
        break;
      case 3:
        this.sendStep = "inputFive";
        if (!this.utils.isArgentina()) {
          this.textTitle = "RECOMENDACIONES PARA SUBIR EL SELFIE CON EL FRONTAL DEL DNI";
          this.textParagraph =
            "Asegúrate de hacer la foto con el móvil en vertical, mirando al frente, de hombros hacia arriba, y mostrando la cara frontal de tu DNI.";
        } else {
          this.textTitle = "Como hacer el ";
          this.textTitle02 = "selfie correctamente";
          this.textParagraph = "Asegúrate de hacer la foto con el móvil en vertical, ";
          this.textParagraph02 = "mirando al frente y sosteniendo tu DNI.";
        }

        break;
      default:
        this.sendStep = "inputOne";
        this.textTitle = "RECOMENDACIONES PARA SUBIR EL FRONTAL DEL DNI";
        this.textParagraph =
          "Asegúrate de hacer la foto con el móvil en vertical. Se deberá ver la identificación completa. Los detalles tienen que ser visibles claramente, sin flases o áreas cubiertas.";
    }
  }

  closeModal() {
    this.closeSelfieModal.emit();
  }

  accessFiles() {
    this.emitAccessFiles.emit(this.sendStep);
    this.closeModal();
  }

  public cameraPhotoPicked(event: File) {
    this.emitAccessCamera.emit({ file: event, sendStep: this.sendStep });
    this.closeModal();
  }

  public webCamPickerDismissed() {
    this.activeCamera = false;
  }

  accessCamera() {
    this.activeCamera = true;
  }
}
