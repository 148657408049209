/* eslint-disable */
import { CommonModule } from "@angular/common";
import { Component, Input, inject } from "@angular/core";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule } from "@ngx-translate/core";
import { EventsService } from "@providers/EventsService";
import { NewBaseService } from "@providers/newBase.service";

@Component({
  selector: "codere-footer-extraImg",
  templateUrl: "./codere-footer-extraImg.html",
  styleUrls: ["./codere-footer-extraImg.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class CodereFooterExtraImg {
  @Input() extraImgs: any[] = [];
  @Input() cssDiv = "footericondeposit";
  globalVars!: GlobalVarsModel;

  eventsService = inject(EventsService);
  newBaseService = inject(NewBaseService);
  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  isImageType(anchors_images: any) {
    const o = new Object(anchors_images);
    return o.hasOwnProperty("href");
  }

  public openEvent(event, typeEvent: string) {
    this.eventsService.publish("footerEvent:open", { event, typeEvent });
  }
}
