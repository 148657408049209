import { CommonModule } from "@angular/common";
import { Component, Input, inject } from "@angular/core";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { Description } from "@models/footer/description";
import { FooterService } from "@providers/FooterService";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { Utils } from "@utils/Utils";
import { CodereFooterInformation } from "../codere-footer-information/codere-footer-information";

@Component({
  standalone: true,
  imports: [CommonModule, CodereFooterInformation],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "codere-footer-body",
  templateUrl: "./codere-footer-body.html",
  styleUrls: ["./codere-footer-body.scss"],
})
export class CodereFooterBody {
  isDesktop: boolean;
  @Input() bodyInformation: Description[] = [];

  globalVars!: GlobalVarsModel;

  footerService = inject(FooterService);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  utils = inject(Utils);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.isDesktop = this.deviceService.isDesktop();
  }
}
