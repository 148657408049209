import { LocalizedValuesList } from "@sports-models/index";

export class Participant {
  AdditionalValues: KeyValueList;
  Id: number;
  IsHome: boolean;
  LocalizedNames: LocalizedValuesList;
  LocalizedShortNames: LocalizedValuesList;
}

export class KeyValueList {
  KeyValues: Array<{ key: string; value: string }>;
  LocalizedValues: Array<[string, string]>;
  ReferenceId: number;
}
