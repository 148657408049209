import { CommonModule } from "@angular/common";
import { AfterContentInit, ChangeDetectionStrategy, Component, Input, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ExtractDatePipe } from "@pipes/extractDate.pipe";
import { TitleCasePipe } from "@pipes/titlecase/title-case.pipe";
import { C_EventLive } from "@sports-models/index";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "sb-grid-content-teams",
  templateUrl: "./sb-grid-content-teams.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TitleCasePipe, CommonModule, FormsModule],
  styleUrls: ["./sb-grid-content-teams.scss"],
})
export class SbGridContentTeamsComponent implements AfterContentInit {
  home: string;
  away: string;
  date: string;
  pitcherHome: string;
  pitcherAway: string;
  isLive = false;
  matchTime: string;
  @Input() event: C_EventLive | any;

  translate = inject(TranslateService);
  constructor(private extractDate: ExtractDatePipe) {}

  ngAfterContentInit() {
    if (this.event) {
      this.home = this.event.teamHome;
      this.away = this.event.teamAway;
      this.pitcherHome = this.event.pitcherHome;
      this.pitcherAway = this.event.pitcherAway;
      this.isLive = !!this.event.StreamingEnabled;

      if (this.event.liveData) {
        if (this.event.liveData.PeriodName && this.eventHasPeriod() && this.eventNotDarts()) {
          this.date = this.event.liveData.PeriodName;
          if (this.event.liveData.PeriodName.toLowerCase().includes("penal")) {
            this.date = this.translate.instant("SPO_Penalties");
            this.matchTime = "";
            return;
          }
        }
        if (this.event.liveData.RemainingPeriodTime) {
          if (
            this.event.liveData.RemainingPeriodTime === "00:00" ||
            this.event.liveData.PeriodName === "Descanso"
          ) {
            return;
          }
          this.matchTime = "< " + this.event.liveData.RemainingPeriodTime;
          return;
        }
        if (this.event.liveData.MatchTime > 0 && this.event.liveData.PeriodName !== "Descanso") {
          this.matchTime = this.event.liveData.MatchTime + "'";
        }
      } else {
        this.date = this.extractDate.transform(this.event.StartDate);
      }
    }
  }

  private eventHasPeriod() {
    return this.event.SportHandle.toLowerCase() !== "esports";
  }

  private eventNotDarts() {
    return this.event.SportHandle !== "darts";
  }
}
