import { Injectable, inject } from "@angular/core";
import { LoadingController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { ParseMoney } from "@pipes/parseMoney";
import { BalancesService } from "@providers/BalancesService";
import { BaseServiceExt } from "@providers/BaseServiceExt";
import { TrackingService } from "@providers/TrackingService";
import { DeviceService } from "@services/device.service";
import { MyBetsFacade } from "@sports-stores/my-bets/my-bets.facade";
import { SportsConstants } from "@sports-utils/sports.constants";
import { EMPTY, Observable, catchError, map, switchMap } from "rxjs";
import { I_CashoutParams, I_MyBetsModel, I_MyBetsModelTicketBetEvent } from "../models";
import { SportsCommonService } from "./sports.common.service";

@Injectable({ providedIn: "root" })
export class SportMyBetsService extends BaseServiceExt {
  deviceService = inject(DeviceService);
  SportsCommonService = inject(SportsCommonService);
  translate = inject(TranslateService);
  myParseMoney = inject(ParseMoney);
  trackingService = inject(TrackingService);
  balancesService = inject(BalancesService);

  myBetsFacade = inject(MyBetsFacade);

  // Loader
  loadingControler = inject(LoadingController);
  loading: HTMLIonLoadingElement;
  spinnerIsOpen = false;

  isDesktop: boolean;
  isMobile: boolean;

  constructor() {
    super();
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
  }

  getMyBetsTickets = "/Home/GetTicketsByDate";
  getCashOutByTicket = "/Home/GetCashOutByTicket";

  getMyBets(params): Observable<I_MyBetsModel[]> {
    this.showHideAutoSpinner();
    return this.SportsCommonService.getAuthToken().pipe(
      switchMap((authData) => this.getTicketsByDate(params.betType, params.date, params.endDate, authData.token)),
    );
  }

  getTicketsByDate(
    betType: string,
    date: string,
    endDate: string,
    authtoken: string,
  ): Observable<I_MyBetsModel[]> {
    const url = `${this.globalVars.URLBASE_NEW_MYBETS}${this.getMyBetsTickets}?language=${this.globalVars.Idioma}&typeBetH=${betType}&startDate=${date}&endDate=${endDate}`;
    const headers = { authtoken };
    return this.myGet2(url, { headers }, true).pipe(
      map((data) => this.setMyBets(data)),
      catchError(() => {
        this.hideSpinner();
        return EMPTY;
      }),
    );
  }

  setMyBets(myBetsTickets: I_MyBetsModel[]): I_MyBetsModel[] {
    const arrangedTickets = myBetsTickets.map((i) => {
      const groupedTickets = i.Tickets.map((ticket) => {
        let updatedGroups: I_MyBetsModelTicketBetEvent[] = [];
        if (ticket.BetTypeNumber.includes("4")) {
          const groupedEvents = {};
          ticket.BetEvent.forEach((event) => {
            if (!groupedEvents[event.EventId]) {
              groupedEvents[event.EventId] = [];
            }
            groupedEvents[event.EventId].push(event);
          });
          const events = Object.keys(groupedEvents);
          events.forEach((e) => {
            if (groupedEvents[e].length > 1) {
              updatedGroups.push({
                IsBetbuilder: true,
                ...groupedEvents[e][0],
                BetBuilderItems: groupedEvents[e],
              });
            } else {
              updatedGroups.push({ IsBetbuilder: false, ...groupedEvents[e][0] });
            }
          });
        } else {
          updatedGroups = [...ticket.BetEvent.map((event) => ({ IsBetbuilder: false, ...event }))];
        }

        if (ticket.TicketStatusNumber == 0) {
          ticket = {
            ...ticket,
            TicketStatus: this.globalVars.licenseType != 0 ? "Anulada" : "Reembolsada",
          };
        }
        return {
          ...ticket,
          HasBetbuilder: +ticket.BetTypeNumber > 3,
          BetEvent: updatedGroups,
        };
      });
      return {
        ...i,
        Tickets: groupedTickets,
      };
    });
    this.hideSpinner();
    return arrangedTickets;
  }

  getCashoutByTicket(params: I_CashoutParams) {
    this.showHideAutoSpinner();
    return this.SportsCommonService.getAuthToken().pipe(
      switchMap((authData) => {
        const url = `${this.globalVars.URLBASE_NEW_MYBETS}${this.getCashOutByTicket}?ticketId=${params.TicketNumber}&pin=${params.Pin}&authtoken=${authData.token}`;
        return this.myGet(url).pipe(
          map((data) => data),
          catchError(() => {
            this.hideSpinner();
            return EMPTY;
          }),
        );
      }),
    );
  }

  updateCahoutByTicket(cashoutParams, cashoutData, myBetsTicket) {
    const updateTickets = (Tickets, ParamsTicketNumber) =>
      Tickets.map((ticket) => {
        if (ticket.TicketNumber != ParamsTicketNumber) return ticket;
        return {
          ...ticket,
          CashOutAmount: cashoutData.CashOutAmount,
          CashOutStatus: cashoutData.CashOutStatus,
        };
      });
    return myBetsTicket.map((betsTicket) => {
      if (betsTicket.DayOrderNr != cashoutParams.DayOrderNr) return betsTicket;
      return {
        ...betsTicket,
        Tickets: updateTickets(betsTicket.Tickets, cashoutParams.TicketNumber),
      };
    });
  }

  payOutTicket(params) {
    const ticketNr = `${params.Pin}${params.TicketNumber}`;
    const amount = params.Ticket.CashOutAmount;
    const msg =
      this.translate.instant("CloseBet1") +
      " " +
      this.myParseMoney.transform((parseInt(amount) / 100).toString()) +
      " " +
      this.translate.instant("CloseBet2") +
      "<br />" +
      this.translate.instant("compapuestaP3") +
      ":" +
      ticketNr;
    this.trackingService.trackEvent([
      "EarlyCashOutCall",
      "",
      "",
      "Llamada a cerrar apuesta (EarlyCashOut)",
      "event",
    ]);
    this.utils.confirm(
      true,
      this.translate.instant("CloseBet"),
      msg,
      this.translate.instant("tCancel"),
      null,
      this.translate.instant("tAccept"),
      async () => {
        await this.utils.loader();
        this.payoutBetTicket(ticketNr, parseInt(amount), false).subscribe(
          async (data) => {
            if (data.success) {
              this.myBetsFacade.refreshMyBets();
              this.globalVars.ticketStatusChanged = true;

              this.balancesService.refreshBalance();
              this.trackingService.trackEvent([
                "EarlyCashOutComplete",
                ticketNr,
                amount,
                "Cerrar apuesta completado  (EarlyCashOut)",
                "event",
              ]);
              await this.utils.closeLoader();
            } else {
              const texto = data.status + " (" + data.statuscode + ")";
              this.utils.showError(texto, () => {
                this.myBetsFacade.refreshMyBets();
              });
              this.trackingService.trackEvent([
                "EarlyCashOutFailure",
                ticketNr,
                amount,
                "Cerrar apuesta fallido (EarlyCashOut)",
                "event",
              ]);
              await this.utils.closeLoader();
            }
          },
          async (err) => {
            await this.utils.closeLoader();
            this.utils.showError("", async () => {
              this.myBetsFacade.refreshMyBets();
            });
          },
        );
      },
      false,
      "cashout-confirmation-alert",
    );
  }

  payoutBetTicket(
    ticketId: string,
    amount: number,
    esPresencial = false,
  ): Observable<{ success: string; status: string; statuscode?: string }> {
    let url: string;
    if (esPresencial) {
      url = `${this.globalVars.SportsMiscUrl}${SportsConstants.OrderFormPayOutBetTicketCard}?ticketid=${ticketId}&amount=${amount}&cardNumber=${this.globalVars.user.codereCard.cardNumber}`;
      if (!this.globalVars.FEATURES.SPO_CodereCardPayout_SportsMisc) {
        url = `${this.globalVars.URLBASE}orderform/payoutbetticketCard?ticketid=${ticketId}&amount=${amount}&cardNumber=${this.globalVars.user.codereCard.cardNumber}`;
      }
      return this.myPost(url, null);
    } else {
      if (this.globalVars.licenseTicketType != "") {
        url = `${this.globalVars.URLBASE}orderform/PayOutBetTicketExternal?ticketid=${ticketId}&amount=${amount}&licenseTikect=${this.globalVars.licenseTicketType}`;
        this.globalVars.licenseTicketType = "";
        return this.myGet(url);
      } else {
        url = `${this.globalVars.SportsMiscUrl}${SportsConstants.OrderFormPayOutBetTicketTicket}?ticketid=${ticketId}&amount=${amount}`;
        return this.myPost(url, null);
      }
    }
  }

  async showHideAutoSpinner() {
    if (!this.spinnerIsOpen) {
      this.loading = await this.loadingControler.create();
      this.loading.present();
      this.spinnerIsOpen = true;
    }
  }

  async hideSpinner() {
    if (this.spinnerIsOpen) {
      await this.loadingControler.dismiss();
      this.spinnerIsOpen = false;
    }
  }
}
