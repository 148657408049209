import { Injectable, inject } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { TranslateService } from "@ngx-translate/core";
import { NativeBiometric } from "capacitor-native-biometric";
import { Observable, from } from "rxjs";
import { EncrypteService } from "./EncrypteServices";
import { LogService } from "./LogService";

@Injectable({ providedIn: "root" })
export class BiometricService {
  logService = inject(LogService);
  encryptService = inject(EncrypteService);
  translate = inject(TranslateService);
  private appID = {
    //Used encryption SHA-1
    web: "ff8e6f6f150d9669507b7a2849fff7e25a79e4ae", // *C0D3R3-WEB-2023*+
    android: "1aa02d376da188c9519d8a1e5f345630464dcb91", // *C0D3R3-ANDROID-2023*+
    ios: "8c4219c8ffa0f699c6380dd0422ddd50aa63847c", // *C0D3R3-IOS-2023*+
  };
  credentialsServer = this.appID[Capacitor.getPlatform()];

  get isNative() {
    return Capacitor.isNativePlatform();
  }

  get isBiometricAvailable(): Promise<boolean> {
    if (this.isNative) {
      return NativeBiometric.isAvailable()
        .then((result) => result.isAvailable)
        .catch((e) => {
          console.error(e);
          return false;
        });
    } else {
      return Promise.resolve(false);
    }
  }

  get isBiometricAvailable$(): Observable<boolean> {
    return from(this.isBiometricAvailable);
  }

  async getCredentials() {
    try {
      return await NativeBiometric.getCredentials({ server: this.credentialsServer });
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async isBiometricLoginSetup(user: string) {
    // const useBiometricLogin = this.userPreferencesService.getPreference('useBiometricLogin') as boolean;
    // if (!useBiometricLogin) return false;
    const credentials = await this.getCredentials();
    return credentials?.username === user;
  }

  async handleBiometricLogin() {
    const encryptedData = await this.getEncryptedData();
    if (!encryptedData) {
      this.logService.log("not data stored!");
      return Promise.reject(new Error("not data stored!"));
    }
    const isVerified = await this.isBiometricVerified();
    if (isVerified) {
      return encryptedData;
    }
    return Promise.reject(new Error("cancelled"));
  }

  private async setEncryptedData(username: string, password: string) {
    return NativeBiometric.setCredentials({
      username,
      password,
      server: this.credentialsServer,
    }).catch(console.error);
  }

  private async getEncryptedData() {
    const data = await this.getCredentials();
    return data || null;
  }

  private async isBiometricVerified() {
    return NativeBiometric.verifyIdentity({ title: "Accede con tu huella dactilar" })
      .then(() => true)
      .catch((e) => {
        console.error(e);
        return false;
      });
  }

  async setupBiometricLogin(user: string, password: string): Promise<any> {
    const isVerified = await this.isBiometricVerified();
    const encryptePassword = this.encryptService.encrypt(password);
    if (isVerified) {
      await NativeBiometric.deleteCredentials({ server: this.credentialsServer });
      return this.setEncryptedData(user, encryptePassword);
    }
    return Promise.resolve();
  }

  async delete() {
    return NativeBiometric.deleteCredentials({ server: this.credentialsServer });
  }
}
