import { EmitUserServiceConfig } from "@config/emit-UserService-config";
import { WithdrawalMethodsClassName } from "./WithdrawalMethodsClassName";
import { CsEmitUserService } from "./cs-emit-userService";

export const emitUserServiceWithdrawals: CsEmitUserService[] = [
  //SetSectionSteps
  {
    className: WithdrawalMethodsClassName.BANK_TRANSFER,
    emitter: {
      setSectionStep: true,
    },
  },
  {
    className: WithdrawalMethodsClassName.HAL_CASH,
    emitter: {
      setSectionStep: true,
    },
  },
  {
    className: WithdrawalMethodsClassName.ITAU,
    emitter: {
      setSectionStep: true,
    },
  },
  {
    className: WithdrawalMethodsClassName.CLABE,
    emitter: {
      setSectionStep: true,
    },
  },
  {
    className: WithdrawalMethodsClassName.CARD_SELECTION,
    emitter: {
      setSectionStep: true,
    },
  },

  //Generic emit -> emitWithdrawal(CsEmitUserService)
  {
    className: WithdrawalMethodsClassName.EFECTY,
    userService: {
      methodName: EmitUserServiceConfig.userService.methodName.GENERIC_WITHDRAWALS,
      url: EmitUserServiceConfig.userService.url.EFECTY.endpoint,
      v2: {
        featureFlag: EmitUserServiceConfig.userService.v2.featureFlags.EFECTY,
        constant: EmitUserServiceConfig.userService.v2.constants.EFECTY,
      },
    },
    options: {
      loader: false,
    },
  },
  {
    className: WithdrawalMethodsClassName.EPG_MUCH_BETTER,
    userService: {
      methodName: EmitUserServiceConfig.userService.methodName.GENERIC_WITHDRAWALS,
      url: EmitUserServiceConfig.userService.url.MUCH_BETTER.endpoint,
    },
    options: {
      loader: true,
    },
  },
  {
    className: WithdrawalMethodsClassName.NEQUI,
    userService: {
      methodName: EmitUserServiceConfig.userService.methodName.GENERIC_WITHDRAWALS,
      url: EmitUserServiceConfig.userService.url.NEQUI.endpoint,
      code: EmitUserServiceConfig.userService.url.NEQUI.params,
      v2: {
        featureFlag: EmitUserServiceConfig.userService.v2.featureFlags.NEQUI,
        constant: EmitUserServiceConfig.userService.v2.constants.NEQUI,
      },
    },
    options: {
      loader: true,
    },
  },
  {
    className: WithdrawalMethodsClassName.DAVIPLATA,
    userService: {
      methodName: EmitUserServiceConfig.userService.methodName.GENERIC_WITHDRAWALS,
      url: EmitUserServiceConfig.userService.url.DAVI_PLATA.endpoint,
      code: EmitUserServiceConfig.userService.url.DAVI_PLATA.params,
      v2: {
        featureFlag: EmitUserServiceConfig.userService.v2.featureFlags.DAVI_PLATA,
        constant: EmitUserServiceConfig.userService.v2.constants.DAVI_PLATA,
      },
    },
    options: {
      loader: true,
    },
  },
  {
    className: WithdrawalMethodsClassName.PSE,
    userService: {
      methodName: EmitUserServiceConfig.userService.methodName.GENERIC_WITHDRAWALS,
      url: EmitUserServiceConfig.userService.url.PSE.endpoint,
      code: EmitUserServiceConfig.userService.url.PSE.params,
      v2: {
        featureFlag: EmitUserServiceConfig.userService.v2.featureFlags.PSE,
        constant: EmitUserServiceConfig.userService.v2.constants.PSE,
      },
    },
    options: {
      loader: true,
    },
  },
  {
    className: WithdrawalMethodsClassName.LOTBA,
    userService: {
      methodName: EmitUserServiceConfig.userService.methodName.GENERIC_WITHDRAWALS,
      url: EmitUserServiceConfig.userService.url.LOBTA.endpoint,
      forceGet: true,
    },
    options: {
      loader: true,
    },
  },
  {
    className: WithdrawalMethodsClassName.PAY_PAL,
    userService: {
      methodName: EmitUserServiceConfig.userService.methodName.GENERIC_WITHDRAWALS,
      url: EmitUserServiceConfig.userService.url.PAYPAL.endpoint,
      forcePost: true,
      v2: {
        featureFlag: EmitUserServiceConfig.userService.v2.featureFlags.PAYPAL,
        constant: EmitUserServiceConfig.userService.v2.constants.PAYPAL,
      },
    },
    options: {
      loader: false,
      setAmount: true,
    },
  },
  {
    className: WithdrawalMethodsClassName.ASTROPAY,
    userService: {
      methodName: EmitUserServiceConfig.userService.methodName.ASTROPAY,
    },
    options: {
      loader: true,
    },
  },

  //Specific emits
  {
    className: WithdrawalMethodsClassName.TRUE_LAYER,
    emitter: {
      methodName: EmitUserServiceConfig.emitter.methodName.TRUELAYER,
    },
  },
  {
    className: WithdrawalMethodsClassName.T_PAGA,
    emitter: {
      methodName: EmitUserServiceConfig.emitter.methodName.TPAGA,
    },
  },
  {
    className: WithdrawalMethodsClassName.LOCAL,
    emitter: {
      methodName: EmitUserServiceConfig.emitter.methodName.LOCAL,
    },
  },
  {
    className: WithdrawalMethodsClassName.COINS_PAID,
    emitter: {
      methodName: EmitUserServiceConfig.emitter.methodName.COINSPAID,
    },
  },
  // CrownCasinoWithdrawal = {emit:''}),
];
