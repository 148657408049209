import { CommonModule, LocationStrategy } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  inject,
} from "@angular/core";
import { Router } from "@angular/router";
import { NavController } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { FilterBar } from "@models/index";
import { NewBaseService } from "@providers/newBase.service";
import { Subject, Subscription, takeUntil } from "rxjs";
import { SbNavbarItemComponent } from "../sb-navbar-item/sb-navbar-item";

@Component({
  selector: "sb-navbar",
  templateUrl: "./sb-navbar.html",
  styleUrls: ["./sb-navbar.scss"],
  standalone: true,
  imports: [CommonModule, SbNavbarItemComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "sb-navbar",
  },
})
export class SbNavbarComponent implements OnChanges, AfterViewInit, OnDestroy {
  @ViewChildren("list") items: QueryList<any>;
  @ViewChild("line") lineContainer: ElementRef;
  @Input() nav: NavController;
  @Input() hasBackButton: boolean;
  @Input() hasLeftSection = true;
  @Input() fakeButton = false;
  @Input() subPage = false;
  @Input() hasActions = false;
  @Input() bar = false;
  @Input() active: number;
  @Input() theme: string;
  @Input() currentPage: string;
  @Output() backSubPage: EventEmitter<number> = new EventEmitter();
  globalVars!: GlobalVarsModel;
  private destroy$ = new Subject<void>();

  locationStrategy = inject(LocationStrategy);
  newBaseService = inject(NewBaseService);

  _renderer = inject(Renderer2);
  _element = inject(ElementRef);
  router = inject(Router);

  filterable: FilterBar;
  private subscriptions$: Subscription = new Subscription();

  constructor() {
    this.newBaseService.getVars.pipe(takeUntil(this.destroy$)).subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  ngOnChanges() {
    if (this.filterable && this.active >= 0) {
      this.filterable.setElements(this.items.first.nativeElement);
      this.filterable.moveTo(this.active);
    }
  }

  ngAfterViewInit() {
    if (this.bar) {
      this.filterable = new FilterBar(this._renderer, this.lineContainer.nativeElement);
      this.filterable.setElements(this.items.first.nativeElement);
      this.filterable.moveTo(this.active);
    }

    if (this.currentPage) {
      this._renderer.addClass(this._element.nativeElement, this.currentPage);
    }
  }

  ngOnDestroy() {
    if (!this.subscriptions$) return;
    this.subscriptions$.unsubscribe();
  }

  navigate() {
    if (this.subPage) {
      this.backSubPage.emit();
      return;
    } else if (this.fakeButton) {
      this.router.navigateByUrl("/");
    } else {
      try {
        this.locationStrategy.historyGo(-1);
      } catch (error) {
        this.router.navigateByUrl("/");
      }
    }
  }
}
