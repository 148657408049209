import { Route } from "@angular/router";
import { CASINO_PATHS, MSO_PATHS, PAGES_PATHS, PAYMENTS_PATHS, SPORTS_PATHS } from "@shared-constants/routes";
import { LoadingPage } from "@shared-pages/loading/loading";

import { BetslipUnlockerPage } from "@sports-pages/betslip-unlocker/betslip-unlocker";
import { environment } from "../environments/environment.config";

const isCasino = () => environment.COUNTRY.includes("casino");

export const appRoutes: Route[] = [
  { path: PAGES_PATHS.LoadingPage, pathMatch: "full", component: LoadingPage },
  {
    path: SPORTS_PATHS.BetslipUnlockerPage,
    pathMatch: "full",
    component: BetslipUnlockerPage,
  },
  {
    path: "",
    loadChildren: () => import("@pages/index").then((c) => c.PAGE_ROUTES),
  },
  {
    path: CASINO_PATHS.BASE,
    loadChildren: () => import("@casino-routes").then((c) => c.CASINO_ROUTES),
  },
  {
    path: MSO_PATHS.BASE,
    loadChildren: () => import("@mso-routes").then((c) => c.MSO_ROUTES),
  },
  {
    path: PAYMENTS_PATHS.BASE,
    loadChildren: () => import("@payments-routes").then((c) => c.PAYMENTS_ROUTES),
  },
  {
    path: SPORTS_PATHS.BASE,
    loadChildren: () => import("@sports-routes").then((c) => c.SPORTS_ROUTES),
  },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: isCasino() ? CASINO_PATHS.BASE : PAGES_PATHS.HomePage,
  },
];
