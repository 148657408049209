import { SafeResourceUrl } from "@angular/platform-browser";

import { TicketService } from "@sports-services/index";
import { EventsService } from "../../shared/providers/EventsService";
import { TrackingService } from "../../shared/providers/TrackingService";

import { GlobalVarsModel } from "@models/index";
import { NewTicketFacade } from "@sports-stores/ticket/index";

export class BetSenseData {
  eventId: string;
  iframeEnabled: boolean;
  iframeVisible: boolean;
  iframeUrl: SafeResourceUrl;
  ticketService: TicketService;
  newTicketFacade: NewTicketFacade;
  globals: GlobalVarsModel;
  events: EventsService;
  trackingService: TrackingService;
}
