import { HttpHeaders } from "@angular/common/http";
/* eslint-disable  */
import { Injectable, inject } from "@angular/core";
import { C_UserColombia } from "@models/C_UserColombia";
import { C_UserPanama } from "@models/C_UserPanama";
import { C_Banners } from "@models/C_banners";
import { CookieNames } from "@models/CookieNames";
import { I_RowExtractTable } from "@models/I_ExtractTable";
import { I_MyActivity, I_ReactivateAccount } from "@models/I_MyActivity";
import { I_NewCarousel } from "@models/I_NewCarousel";
import { Promotion } from "@models/I_Promotion";
import { ArgentinaRegions, I_UserArgentina } from "@models/I_UserArgentina";
import { I_UserInfo } from "@models/I_UserInfo";
import { I_UserPanama } from "@models/I_UserPanama";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { FooterImage } from "@models/footer/imagen";
import { ResponseBono } from "@mso-pages/welcome-bonus/welcome-bonus";
import { BaseService } from "@providers/BaseService";
import { LogService } from "@providers/LogService";
import { IabService } from "@providers/iabService";
import { Constants } from "@shared-utils/index";
import { Observable, from, throwError } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";

class PreRegisterUser {
  constructor(
    public id: string = "",
    public subject: string = "",
    public name: string = "",
    public surname: string = "",
    public bgtCardId: string = "",
    public email: string = "",
    public birthDate: string = "",
    public gender: string = "",
    public city: string = "",
    public addressLine1: string = "",
    public state: string = "",
    public postalCode: string = "",
    public country: string = "",
    public mobile: string = "",
  ) {}
}
declare const codereSharedJS;

interface CodereMillonResult {
  isPromotable: boolean;
  token: string;
  errors: string;
  isAuthenticate: string;
}

@Injectable({ providedIn: "root" })
export class UserServiceMSO extends BaseService {
  logService = inject(LogService);
  iabService = inject(IabService);

  constructor() {
    super();

    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  activateUser(data: any) {
    const url = this.globalVars.URLBASE_USERSERVICE + "AddNickNamePwd";
    return this.myPost(url, data);
  }

  getUserDataByPinCode(token: string) {
    const url = this.globalVars.URLBASE + "account/GetUserDataByPinCode?pincode=" + token;
    return this.myGet(url);
  }

  getUserInfo(): Observable<I_UserInfo> {
    let url: string;
    if (codereSharedJS.debug.isQA()) {
      url = this.globalVars.DOMAIN + "/csbgonlineCodereIdWebApi";
    } else {
      url = this.globalVars.DOMAIN + "/CodereIdApi";
    }
    return this.myGet(url, true, (err: any, caught: Observable<any>): any => {
      this.logService.error(err);
    }).pipe(
      map((data) => {
        const userinfodata: I_UserInfo = {
          ispromotable: data.isPlayerPromotionElegible,
          ispromotablevip: data.isPlayerSpecialVIPPromotionElegible,
          vipLevel: data.vipLevel,
          vipstats: data.vipStatus,
          registrationDate: data.createdOnReg,
          userCodere: data.userName,
          eligibilityStatus: data.docVerification,
          verifiDocumental: data.docVerification ? "Completed" : "Pending",
          isUserMadeFTD: data.isFirstDeposit,
          canWithdraws: data.canWithdraws || null,
          balance: data.balance,
          selfExcluded: data.selfExclusion,
          segmentedClosed: data.segmentedClosed,
          vipEstado: data.vipEstado,
        };
        if (this.globalVars.licenseType === LicenseTypes.Nacional) {
          userinfodata.userOptinPossibility = data.userOptinPossibility;
          userinfodata.birthdate = data.birthdate;
          userinfodata.commercialCommunications = data.commercialCommunications;
          userinfodata.notverified30days = data.notverified30days;
        }
        return userinfodata;
      }),
    );
  }

  getPreRegisterUser(id): Observable<PreRegisterUser> {
    const api: string = "presignup";
    const qs = `?id=${id}`;
    const url: string = this.globalVars.URLBASE_USERSERVICE + api + qs;
    return this.myGet(url, true).pipe(
      map((res) => {
        const data = new PreRegisterUser();
        data.name = res.name ? res.name : "";
        data.surname = res.surname ? res.surname : "";
        data.email = res.email ? res.email : "";
        data.mobile = res.mobile ? res.mobile : "";
        data.birthDate = res.birthDate ? res.birthDate : "";
        return data;
      }),
    );
  }

  getPreRegisterUserAr(id): Observable<PreRegisterUser> {
    const api: string = "presignup";
    const qs = `?id=${id}`;
    const url: string = this.globalVars.URLBASE_USERSERVICE + api + qs;
    return this.myGet(url, true).pipe(
      map((res) => {
        return res;
      }),
    );
  }
  removeAutoExclusion() {
    const url: string = this.globalVars.URLBASE + "Colombia/RemoveSelfExclusion";
    return this.myGet(url);
  }

  updateLimits(accept: boolean) {
    let url: string = this.globalVars.URLBASE;
    if (this.globalVars.licenseType == LicenseTypes.Colombia) {
      url += "Colombia/UpdateLimitsColombia?accept=" + accept;
    } else if (this.globalVars.licenseType == LicenseTypes.Panama) {
      url = this.globalVars.URLBASE_USERSERVICE;
      url += "limits/updatelimits?accept=" + accept;
    } else {
      url = this.globalVars.URLBASE_USERSERVICE;
      url += "panama/UpdateLimits?accept=" + accept;
    }
    if (this.globalVars.licenseType == LicenseTypes.Panama) {
      return this.myPost(url, {});
    } else {
      return this.myGet(url);
    }
  }

  createAutoExclusion(date: string, renew: boolean, revoke = false) {
    if (this.globalVars.licenseType != LicenseTypes.Colombia) {
      const url: string =
        this.globalVars.URLBASE + "Colombia/CreateSelfExclusion?newDate=" + date + "&renew=" + renew;
      return this.myGet(url);
    } else {
      return this.createSelfExclusUserService(date, renew, revoke);
    }
  }

  createSelfExclusUserService(date: string, renew: boolean, revoke = false) {
    const url: string =
      this.globalVars.URLBASE_USERSERVICE +
      "Colombia/CreateSelfExclusion?newDate=" +
      date +
      "&renew=" +
      renew +
      "&revoke=" +
      revoke;
    return this.myPost(url, {});
  }

  createAutoExclusionAR(time: string, state: any) {
    // https://qm.codere.com.ar/UserServiceApi...
    const url: string =
      this.globalVars.URLBASE_USERSERVICE +
      "UpdateTimeOut" +
      "?timeOutSettings.durationType=" +
      "minutos" +
      "&timeOutSettings.duration=" +
      time +
      "&timeOutSettings.slots=" +
      state.slots +
      "&timeOutSettings.casino=" +
      state.casino +
      "&timeOutSettings.bets=" +
      state.sports;
    return this.myPost(url, {});
  }

  createExitQuitAR() {
    // https://qm.codere.com.ar/UserServiceApi...
    const url: string = this.globalVars.URLBASE_USERSERVICE + "ExitQuick";
    return this.myPost(url, {});
  }

  registerColombia(data: C_UserColombia): Observable<any> {
    let url: string = this.globalVars.URLBASE + "colombia/RegisterUser";
    if (this.globalVars.FEATURES.MSO_EnabledComplianceRegistroCO) {
      url = this.globalVars.URLBASE_USERSERVICE + "api/Signup";
    }
    return this.myPost(url, data, {}, true);
  }

  registerArgentina(data: I_UserArgentina, region: ArgentinaRegions = "ArgentinaCaba"): Observable<any> {
    const url: string = this.globalVars.URLBASE_USERSERVICE + `signup/Signup?regionName=${region}`;
    return this.myPost(url, data, {});
  }

  registerPBA(data: I_UserArgentina): Observable<any> {
    return this.registerArgentina(data, "ArgentinaPBA");
  }

  registerPanama(data: C_UserPanama): Observable<any> {
    const url: string = this.globalVars.URLBASE + "panama/RegisterUser";
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json;");

    return this.myPost(url, data, { headers: headers });
  }

  registerPanamaUserService(data: I_UserPanama): Observable<any> {
    const url: string = this.globalVars.URLBASE_USERSERVICE + "api/Signup";
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json;");

    return this.myPost(url, data, { headers: headers });
  }

  getPostalCodeInfo(zipcode: string) {
    const url: string = this.globalVars.URLBASE + "colombia/GetPostalCodeInfo?postalcode=" + zipcode;
    return this.myGet(url, true);
  }

  getDepartamentsColombia() {
    const url: string = this.globalVars.URLBASE + "colombia/GetDepartamets";
    return this.myGet(url, true);
  }
  getMunicipalitiesColombia(name: string) {
    const url: string = this.globalVars.URLBASE + "colombia/GetMunicipalities?name=" + name;
    return this.myGet(url, true);
  }
  getZipcodesColombia(dep: string, mun: string) {
    const url: string = this.globalVars.URLBASE + "colombia/GetZipCodes?dep=" + dep + "&mun=" + mun;
    return this.myGet(url, true);
  }

  loadCodereCard(): Observable<any> {
    const url: string = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardGetUserCardNumber}`;
    return this.myGet(url);
  }

  doLogout(): Observable<any> {
    // Legacy
    // const url: string = this.globalVars.URLBASE + 'account/logOff';
    const url = `${this.globalVars.URLBASE_LOGINSERVICES}/logout`;
    return this.httpClient.get(url);
  }

  register(data: any): Observable<any> {
    const url: string = this.globalVars.URLBASE + "account/registersiebel";
    const headers = new HttpHeaders().append("Content-Type", "application/json;");
    return this.myPost(url, data, { headers: headers });
  }

  checkDataAvailability(field: string, value: string): Observable<any> {
    const url = this.globalVars.URLBASE + "Account/CheckDataAvailability?field=" + field + "&value=" + value;
    console.log("CHECK CRM", url);
    return this.httpClient.get(url).pipe(
      map((data: any) => {
        if (data.status == "ok") {
          return null;
        } else {
          return { validUnique: "notValid" };
        }
      }),
      catchError((err) => null),
    );
  }

  checkPlaceBirthInfo(value: string): Observable<any> {
    const url = this.globalVars.URLBASE + "Colombia/GetPlaceBirthInfo?placeBirth=" + value;
    return this.myGet(url, true);
  }

  //getTerms region

  updateTermsAndConditions(login: string): Observable<{ success: string; status?: string }> {
    const url = this.globalVars.URLBASE + "account/UpdateTermsAndConditions?login=" + login;
    return this.myGet(url, true);
  }

  updateAuthorizationPersonalData(login: string): Observable<{ success: string; status?: string }> {
    const url = this.globalVars.URLBASE + "account/UpdateAuthorizationPersonalData?login=" + login;
    return this.myPost(url, null, null, true);
  }

  updatePrivacePolitics(login: string): Observable<{ success: string; status?: string }> {
    const url = this.globalVars.URLBASE + "account/UpdatePrivacePolitics?login=" + login;
    return this.myGet(url, true);
  }

  getTermsAndConditions(code: string, bono?: string): Observable<any> {
    const url =
      this.globalVars.URLBASE + "Account/GetTermsAndConditionsRegion?postalcode=" + code + "&voucher=" + bono;

    const q = new Promise((resolve, reject) => {
      this.myGet(url, true).subscribe(
        (data) => {
          //posible values: Madrid / Nacional
          //Nuevo metodo de obtencion de TyC
          const license = data;
          this.getLastTermsAndConditionsUrlbyRegion(data).subscribe(
            (data) => {
              const obj = {
                Url: data.Url,
                Hash: data.Hash,
                license: license,
                Code: data.Code,
              };
              resolve(obj);
            },
            (err) => {
              //
              reject(err);
            },
          );
          // Fin
        },
        (err) => {
          //
          reject(err);
        },
      );
    });

    return from(q);
  }

  getLastTermsAndConditionsUrl(code: string): Observable<any> {
    const url = this.globalVars.URLBASE + "home/GetLastTermsAndConditions" + code;
    return this.myGet(url, true);
  }

  getLastAutoTrataDatosPersonalUrl(region: string): Observable<any> {
    const url = this.globalVars.URLBASE + "home/GetLastAuthorizationPersonalData?region=" + region;
    return this.myGet(url, true);
  }

  getLastTermsAndConditionsUrlbyRegion(region: string): Observable<any> {
    const url = this.globalVars.URLBASE + "home/GetLastTermsAndConditions?region=" + region;
    return this.myGet(url, true);
  }
  getLastPrivacyPoliticsUrlbyRegion(region: string): Observable<any> {
    const url = this.globalVars.URLBASE + "home/GetLastPrivacyolicy?region=" + region;
    return this.myGet(url, true);
  }
  // obtiene últimas TyC selfie
  getLastTermsAndConditionsSelfieUrlbyRegion(region: string): Observable<any> {
    const url =
      this.globalVars.URLBASE_USERSERVICE + "player/GetLastPrivacyPoliticsSelfieByRegion?region=" + region;
    return this.myGet(url, true);
  }

  //actualiza si user acepta TyC selfie
  updateTyCSelfie(data): Observable<any> {
    const url = this.globalVars.URLBASE_USERSERVICE + "player/SetAcceptancePrivacyPolicy";
    return this.myPost(url, true);
  }

  phoneCallRegister(phone: string, captcha: string): Observable<any> {
    const url = this.globalVars.URLBASE + "account/PhoneCallRegister?number=" + phone + "&captcha=" + captcha;
    return this.myGet(url, true);
  }

  verifyCode(code: string): Observable<any> {
    const url = this.globalVars.URLBASE + "Account/VerifyCode?code=" + code;
    return this.myGet(url, true);
  }

  getTable(table: string): Observable<any> {
    const url =
      this.globalVars.URLBASE + "masterdata/gettable?languageCode=" + this.globalVars.Idioma + "&table=" + table;
    return this.myGet(url, true);
  }

  getUserActivity(fromDate: string, toDate: string): Observable<I_MyActivity> {
    const url = this.globalVars.DOMAIN + this.globalVars.FEATURES.UrlNewUserMyActivity + fromDate + "/" + toDate;
    return this.myGet(url);
  }

  getReactivateAccountData(): Observable<I_ReactivateAccount> {
    const url =
      this.globalVars.DOMAIN + this.globalVars.FEATURES.UrlNewUserMyActivity + "last90daysbeforeautoexclusion";
    return this.myGet(url).pipe(map((response) => response.data));
  }

  getUserExtractTable(startDate: string, endDate: string): Observable<I_RowExtractTable[]> {
    const url =
      this.globalVars.DOMAIN +
      this.globalVars.FEATURES.UrlNewUserMyActivityExtractable +
      startDate +
      "/" +
      endDate;
    return this.myGet(url).pipe(map((resp) => resp.data));
  }

  resetPassword(data: any): Observable<{ success: string; status: string; errCode?: string }> {
    const url = this.globalVars.URLBASE_USERSERVICE + "player/ResetPassword";
    return this.myPost(url, {
      data,
    });
  }

  changeLimits(amountDay: number, amoutWeek: number, amountMonth: number): Observable<any> {
    let url = "";
    if (this.globalVars.licenseType == LicenseTypes.Panama || this.utils.isArgentina()) {
      url = this.globalVars.URLBASE_USERSERVICE + "limits/check";
      return this.myPost(url, { newAmountDay: amountDay, newAmountWeek: amoutWeek, newAmountMonth: amountMonth });
    } else {
      url =
        this.globalVars.URLBASE +
        "account/ChangeLimits?newAmountDay=" +
        amountDay +
        "&newAmountWeek=" +
        amoutWeek +
        "&newAmountMonth=" +
        amountMonth;
      return this.myGet(url);
    }
  }

  getLimits(): Observable<{
    day: string;
    week: string;
    month: string;
    SessionLimitsGasto: string;
    SessionLimitsPerdida: string;
    SessionLimitsTiempo: string;
  }> {
    const url = this.globalVars.URLBASE + "account/GetLimits";
    return this.myGet(url, true);
  }

  changeLimitswithTest(
    text: string,
    form: boolean,
    amountDay: number,
    amoutWeek: number,
    amountMonth: number,
  ): Observable<any> {
    const url = this.globalVars.URLBASE_USERSERVICE + "Limits/appendlimitssurvey";
    const data = {
      surveyContent: text,
      isApproved: form,
      newAmountDay: Number(amountDay),
      newAmountWeek: amoutWeek,
      newAmountMonth: amountMonth,
    };
    const headers = new HttpHeaders();
    return this.myPost(url, data, { headers: headers });
  }

  limitsChangeRequestStatus(amount = {}): Observable<any> {
    const data = amount;
    const url = this.globalVars.URLBASE_USERSERVICE + "Limits/Check";
    const headers = new HttpHeaders();
    return this.myPost(url, data, { headers: headers });
  }

  changeSessionLimits(data: { amountSpent: number; amountLost: number; time: number }): Observable<any> {
    console.info("Send Session Limits");
    let url = this.globalVars.URLBASE_USERSERVICE + "player/UpdateSessionLimits";
    if (this.globalVars.licenseType == LicenseTypes.Panama) {
      url = this.globalVars.URLBASE_USERSERVICE + "limits/checksessions";
    }
    const headers = new HttpHeaders();
    return this.myPost(url, data, { headers: headers });
  }

  updateData(data: any): Observable<any> {
    if (
      this.globalVars.licenseType != LicenseTypes.Colombia &&
      this.globalVars.licenseType != LicenseTypes.Mexico &&
      this.globalVars.licenseType != LicenseTypes.Panama
    ) {
      const url: string = this.globalVars.URLBASE_USERSERVICE + "player/UpdateData";
      const headers = new HttpHeaders();
      return this.myPost(url, data, { headers: headers });
    } else {
      const url: string = this.globalVars.URLBASE + "account/UpdateData";
      const headers = new HttpHeaders();
      return this.myPost(url, data, { headers: headers });
    }
  }

  updatePrp(isPrp: boolean) {
    //const headers = new HttpHeaders().append('Content-Type', 'application/json;');
    const url: string = this.globalVars.URLBASE_USERSERVICE + "player/SetPrp?prp=" + isPrp;
    return this.myPost(url, null);
  }

  getBanners(bannerswebapppc = ""): Observable<any> {
    // this.globalVars.LicenseTypeString -> movido a utils
    const license: string = this.utils.LicenseTypeString(this.globalVars.licenseType);
    let url = "";

    // jsonp ya añade "&callback=JSONP_CALLBACK" al final
    url = `${this.globalVars.urlBaseBanners}?anonymous=${!this.globalVars.user
      .logged}&license=${license}&sharepointImagesList=${bannerswebapppc}`;

    return this.httpClient.jsonp(url, "callback").pipe(
      map((res: any) => {
        const data = this.utils.xml2json(res);
        if (!data.banners) {
          return;
        }
        data.banners.banner = Array.isArray(data.banners.banner) ? data.banners.banner : [data.banners.banner];
        return data.banners.banner
          .filter((banner) => Boolean(banner["@imagen"]))
          .map((banner) => new C_Banners(banner["@imagen"], banner["@url"], banner["@cid"], banner["@target"]));
      }),
    );
  }

  public getEventMarketsByNode(nodeEvent: number) {
    const url =
      this.globalVars.NAVIGATIONSERVICEBASEURL + this.globalVars.FEATURES.MSO_BannersEndpointURL + nodeEvent;
    return this.myGet(url, false);
  }

  async getNewBanners(bannerswebapppc = "") {
    const bannerType: string = this.utils.getBannerTypeByLobby(bannerswebapppc);
    const url =
      this.globalVars.DOMAIN + this.globalVars.FEATURES.NewCarouselApi + `carousel/templates/${bannerType}`;
    const data: I_NewCarousel[] = await this.myGet(url)
      .pipe(
        map<any, I_NewCarousel[]>((data) =>
          this.utils.mapResponseBanners(data).sort((a, b) => {
            if (a.priority > b.priority) {
              return 1;
            }
            if (a.priority < b.priority) {
              return -1;
            }
            return 0;
          }),
        ),
      )
      .toPromise();
    const bannersErrorCSV: Array<{ templates: string; key: number }> = [];
    await Promise.all(
      data.map(async (r, i) => {
        const canBetRequest: number = this.utils.canRequestForOddAmount(r);
        if (canBetRequest) {
          try {
            if (r.typeOfTemplate === "One Odd") {
              const response = await this.getEventMarketsByNode(canBetRequest).toPromise();
              data[i].oneOddAmount = response.Odd;
            } else {
              const responseLeftTeam = await this.getEventMarketsByNode(r.leftTeamBetKey).toPromise();
              data[i].leftTeamAmount = responseLeftTeam.Odd;
              if (r.typeOfTemplate == "Two teams with draw") {
                const responseDraw = await this.getEventMarketsByNode(r.drawBetKey).toPromise();
                data[i].drawAmount = responseDraw.Odd;
              }
              const responseRightTeam = await this.getEventMarketsByNode(r.rightTeamBetKey).toPromise();
              data[i].rigthTeamAmount = responseRightTeam.Odd;
            }
          } catch (error) {
            bannersErrorCSV.push({ templates: r.typeOfTemplate, key: canBetRequest });
          }
        }
      }),
    );
    const auxdata: I_NewCarousel[] = data.filter((r) => {
      let isBad = false;
      bannersErrorCSV.forEach((b) => {
        if (b.templates === r.typeOfTemplate) {
          switch (r.typeOfTemplate) {
            case "One Odd":
            case "Two teams with draw":
            case "Two teams without draw":
            case "Tennis Match":
              if (!isBad) {
                isBad = b.key === r.matchKey;
              }
              break;
          }
        }
      });
      return !isBad;
    });
    return auxdata;
  }

  checkCardLocked(): Observable<any> {
    const url = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardCheckCardLocked}`;
    return this.myPost(url, null);
  }

  validatePasswordAndCard(creds: { password: string }): Observable<any> {
    const url = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardValidatePasswordAndCard}`;
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
    return this.myPost(url, creds, { headers });
  }

  cardStatus(creds: { cardNumber: string }): Observable<any> {
    const url = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardCardStatus}`;
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
    return this.myPost(url, creds, { headers });
  }

  sendHelpMail(name: string, email: string, phone: string, comments: string, captcha: string) {
    const username = this.globalVars.user.username ? this.globalVars.user.username : "NoJugador";
    const platform = "WebApp";
    const url =
      this.globalVars.URLBASE +
      "account/sendHelpMail?userName=" +
      username +
      "&name=" +
      name +
      "&emailAdress=" +
      email +
      "&phone=" +
      phone +
      "&comment=" +
      comments +
      "&plataform=" +
      platform +
      "&captcha=" +
      captcha;
    return this.myGet(url);
  }

  updateComunications(options: { phone: boolean; sms: boolean; mail: boolean; alerts: boolean }) {
    const url = `${this.globalVars.URLBASE_USERSERVICE}UpdateNotificationsAllowed`;
    const data = {
      AllowSendSMS: options.sms,
      AllowSendEmail: options.mail,
      AllowSendPhoneCall: options.phone,
      AllowSendFax: options.alerts,
    };
    return this.myPost(url, data);
  }

  setSelfExcluded() {
    const url = this.globalVars.URLBASE_USERSERVICE + "SetSelfExcluded";
    return this.myPost(url, {});
  }

  getTimeOutData() {
    const url = this.globalVars.URLBASE_USERSERVICE + "ForbiddenActions";
    return this.myGet(url);
  }

  getPromotionsList() {
    const url: string = this.globalVars.DOMAIN + this.globalVars.FEATURES.MSO_URLPromotionsAPIList;
    return this.myGet(url);
  }

  /**
   * Check if the user "ispromotable" / "ispromotablevip"
   * @returns {boolean}
   */
  getIsFullPromotionable() {
    const url: string = this.globalVars.DOMAIN + this.globalVars.FEATURES.MSO_URLPromotionsAPIisFullPromotable;
    return this.myGet(url);
  }
  /**
   * Check if the user "ispromotable" or the user "ispromotablevip"
   * @param {boolean} vip: Sets the url for ispromotablevip service
   * @returns {boolean}
   */
  getIsPromotionable(vip?: boolean) {
    const promotVip = vip
      ? this.globalVars.FEATURES.MSO_URLPromotionsAPIisPromotableVip
      : this.globalVars.FEATURES.MSO_URLPromotionsAPIisPromotable;
    const url: string = this.globalVars.DOMAIN + promotVip;
    return this.myGet(url);
  }

  getGetWelcomeBousInfoNew(): Observable<ResponseBono> {
    const date = new Date().getTime();
    const license: string = this.utils.LicenseTypeString(this.globalVars.licenseType);
    const url: string = `assets/welcomebonus/welcomebonus${license}.json?v=${date}`;
    return this.myGet(url).pipe(
      map((resp) => {
        return {
          template: this.globalVars.FEATURES[resp.template] ? this.globalVars.FEATURES[resp.template] : "Separate",
          bonosToShow: this.globalVars.FEATURES[resp.bonosToShow] ?? resp.bonosToShow,
          bonos: resp.bonos.map((bono) => {
            return {
              ...bono,
              tyc: this.globalVars.FEATURES[bono.tyc] ?? bono.tyc,
              img: this.globalVars.FEATURES[bono.img] ?? bono.img,
              title: this.globalVars.FEATURES[bono.title] ?? bono.title,
              txt: this.globalVars.FEATURES[bono.txt]?.replace(/'/g, "").split(",,") ?? bono.txt,
              bono: {
                ...bono.bono,
                promoEnd: `${new Date().getFullYear() + 1}-10-21`,
                promoStart: `${new Date().getFullYear() - 1}-10-21`,
                promoId: this.globalVars.FEATURES[bono.bono.promoId] ?? bono.bono.promoId,
              },
            };
          }),
        };
      }),
    );
  }

  setPromotionsOptOutCO(data) {
    const url: string = this.globalVars.DOMAIN + this.globalVars.FEATURES.MSO_URLPromotionsAPIWBOut;
    const headers = new HttpHeaders().append("Content-Type", "application/json");
    return this.myPost(url, data, { headers: headers });
  }

  setPromotionsOptIn(data, createOptin: boolean = true) {
    const url: string =
      this.globalVars.DOMAIN + this.globalVars.FEATURES.MSO_URLPromotionsAPIOptIn + `?createOptin=${createOptin}`;
    const headers = new HttpHeaders().append("Content-Type", "application/json");
    return this.myPost(url, data, { headers: headers });
  }

  setPromotionsOptOut(data) {
    const url: string = this.globalVars.DOMAIN + this.globalVars.FEATURES.MSO_URLPromotionsAPIOptOut;
    const headers = new HttpHeaders().append("Content-Type", "application/json");
    return this.myPost(url, data, { headers: headers });
  }

  checkOptIn(promoData: Promotion) {
    return this.setPromotionsOptIn(promoData, false);
  }

  getCopaAmericaToken(): Observable<CodereMillonResult> {
    const api = this.globalVars.FEATURES.CopaAmericaAPI; ///UserServiceApiCO/ para calidad en producciÃƒÂ³n /UserServiceApi/
    const url: string = this.globalVars.DOMAIN + api;
    return this.myGet(url);
  }

  getCodereMilloToken(): Observable<CodereMillonResult> {
    const api = `coderemillion/authenticate`;
    const url: string = this.globalVars.URLBASE_USERSERVICE + api;
    return this.myGet(url);
  }

  getMvpCodereToken(): Observable<CodereMillonResult> {
    const api = this.globalVars.FEATURES.MVPCodereAPI; ///UserServiceApiCO/ para calidad en producciÃƒÂ³n /UserServiceApi/
    const url: string = this.globalVars.DOMAIN + api;
    return this.myGet(url);
  }

  getUserWelcomeBonus() {
    const url: string = this.globalVars.DOMAIN + this.globalVars.FEATURES.MSO_URLPromotionsAPIWBUser;
    return this.myGet(url);
  }

  getdataTargetOptinPopups() {
    const url = this.globalVars.DOMAIN + this.globalVars.FEATURES.MSO_URLTargetedOptInPopupPage;
    return this.myGet(url, true, (err: any, caught: Observable<any>): any => {
      this.logService.error(err);
      return throwError(() => err.error);
    });
  }

  getFootersConstants(): Observable<any>[] {
    const date = new Date().getTime();
    const license: string = this.utils.LicenseTypeString(this.globalVars.licenseType);
    const url: string = `assets/footerConstants/coderefooterVars${license}.json?v=${date}`;
    const urlgeneral: string = `assets/footerConstants/coderefooterVars.json?v=${date}`;
    return [this.myGet(urlgeneral, true), this.myGet(url, true)];
  }

  getFootersImgDeposits(): Observable<FooterImage[]> {
    const date = new Date().getTime();
    const license: string = this.utils.LicenseTypeString(this.globalVars.licenseType);
    const url: string = `assets/footerConstants/imgDeposits/imgdepositsVars${license}.json?v=${date}`;
    return this.myGet(url, true).pipe(
      map((data: any[]) => {
        return data.map((e) => {
          return {
            ...e,
            divContainerCss: e.divContainerCss || "footericondeposit--container",
            show: e.show && typeof e.show === "string" ? Boolean(this.globalVars.FEATURES[e.show]) : e.show,
          };
        });
      }),
    );
  }

  getLogoHeader(): Observable<any> {
    const date = new Date().getTime();
    const license: string = this.utils.LicenseTypeString(this.globalVars.licenseType);
    const url: string = `assets/logoHeaderApp/logoHeaderApp${license}.json?v=${date}`;
    return this.myGet(url, true);
  }

  checkLimitsChange() {
    const url: string = this.globalVars.URLBASE_USERSERVICE + "limits/applyLimit";
    return this.myGet(url);
  }

  ValidateSummaryPlayerInfo(yearToBeExtracted: string, forceExtract: any): Observable<any> {
    const url =
      this.globalVars.UrlBalanceServiceApiBase +
      "player/ValidateBalanceHistoryHtmlV2/" +
      yearToBeExtracted.trim() +
      "/" +
      forceExtract;
    return this.myGet(url);
  }

  newUserExtract(fromDate: string, toDate: string) {
    let url = `${this.globalVars.DOMAIN}${this.globalVars.FEATURES.UrlNewUserExtract}`;
    url += `${fromDate}/${toDate}`;
    let headers = new HttpHeaders();
    if (
      this.globalVars.FEATURES.csrfControlFront &&
      this.globalVars.user.logged &&
      this.globalVars.extraUserData.csrfControl
    ) {
      headers = headers.append(CookieNames.CsrfControl, this.globalVars.extraUserData.csrfControl);
    }
    this.events.publish("loading:start");
    return this.httpClient
      .get(url, { responseType: "blob", headers })
      .pipe(map((response) => response))
      .pipe(catchError(this.handleError))
      .pipe(
        finalize(() => {
          this.events.publish("loading:finish");
        }),
      );
  }

  newUserExtractNatives(fromDate: string, toDate: string, token: string) {
    let url = `${this.globalVars.DOMAIN}${this.globalVars.FEATURES.MSO_UrlNewUserExtractNative}`;
    url += `${token}/${fromDate}/${toDate}`;
    this.iabService.create(url, "_system");
  }

  newUserSummary(fromDate: string, toDate: string) {
    let url = `${this.globalVars.DOMAIN}${this.globalVars.FEATURES.UrlNewUserSummary}`;
    url += `${fromDate}/${toDate}`;
    let headers = new HttpHeaders();
    if (
      this.globalVars.FEATURES.csrfControlFront &&
      this.globalVars.user.logged &&
      this.globalVars.extraUserData.csrfControl
    ) {
      headers = headers.append(CookieNames.CsrfControl, this.globalVars.extraUserData.csrfControl);
    }
    this.events.publish("loading:start");
    return this.httpClient
      .get(url, { responseType: "blob", headers })
      .pipe(map((response) => response))
      .pipe(
        finalize(() => {
          this.events.publish("loading:finish");
        }),
      );
  }

  newUserSummaryNatives(fromDate: string, toDate: string, token: string) {
    let url = `${this.globalVars.DOMAIN}${this.globalVars.FEATURES.MSO_UrlNewUserSummaryNative}`;
    url += `${token}/${fromDate}/${toDate}`;
    this.iabService.create(url, "_system");
  }

  getTokenFromCookieNatives() {
    const url = `${this.globalVars.DOMAIN}${this.globalVars.FEATURES.MSO_UrlNewUserSummaryAuthorize}`;
    return this.myGet(url);
  }

  SetAcceptanceComunication() {
    const url = this.globalVars.URLBASE_USERSERVICE + "player/SetAcceptanceComunication";
    return this.myPost(url, {});
  }

  reactivateAccount(username: string, accept = true) {
    const headers = new HttpHeaders().append("Codere-Key", "e43cea96-1c4e-4ffe-8a27-180401483a0c");
    const url = this.globalVars.URLBASE_USERSERVICE + "player/ReactivateAccount";
    return this.myPost(
      url,
      {
        accept,
        username,
      },
      { headers: headers },
    );
  }

  checkOrdenCongelamientoFondos(): Observable<any> {
    const url = `${this.globalVars.URLBASE_USERSERVICE}player/CheckOrdenCongelamientoFondos`;
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.myPost(url, {}, { headers: headers });
  }

  registerCOComplianceRecords() {
    const url: string = this.globalVars.URLBASE_USERSERVICE + "colombia/CheckAllUserComplianceRecords";
    return this.myPost(url, {});
  }

  getObservableMockup<T>(getData: T) {
    const data = new Observable<T>((observer) => {
      const response = getData;
      setTimeout(() => {
        observer.next(response);
        observer.complete();
      }, 2000);
    });
    return data;
  }

  getAllAstropayMethods(license: string) {
    const url = this.globalVars.UrlTransactionsApiBase + "Payments/GetAllAstropayMethods?license=" + license;
    return this.myGet(url);
  }
  /*TODO ELIMINAR - Revisar pq está duplicado en UserService*/
  getUserData(): Observable<any> {
    var url = this.globalVars.URLBASE + "account/getuserdata";
    if (this.globalVars.licenseType != LicenseTypes.Mexico) {
      url =
        this.getUrlUserService() +
        (Boolean(this.globalVars.FEATURES.userSErviceStandAlone) ? "userdata" : "player/GetUserData");
    }

    return this.myGet(url);
  }

  getUrlUserService() {
    return this.globalVars.FEATURES.userSErviceStandAlone !== undefined &&
      this.globalVars.FEATURES.userSErviceStandAlone === true
      ? this.globalVars.URLBASE_USERSERICE_STAND_ALONE
      : this.globalVars.URLBASE_USERSERVICE;
  }
  verifyDNIexpiration() {
    const url = this.globalVars.URLBASE_USERSERVICE + "player/VerifyDniExpiration";
    return this.myGet(url);
  }
  verifyProfesion() {
    const url = this.globalVars.URLBASE_USERSERVICE + "player/IsEnabledByProfesion";
    return this.myGet(url);
  }
}
