/* eslint-disable */
import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  inject,
} from "@angular/core";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { NewBaseService } from "@providers/newBase.service";

@Component({
  selector: "cs-verification-item",
  templateUrl: "./cs-verification-item.html",
  styleUrls: ["./cs-verification-item.scss"],
  standalone: true,
  imports: [CommonModule],
  host: {
    class: "cs-verification-item background-color-muted color-dark",
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CsVerificationItemComponent {
  @Input() doc: any;
  @Output() navigate: EventEmitter<any> = new EventEmitter();

  globalVars!: GlobalVarsModel;
  private newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  @HostBinding("class")
  private get hostClass(): string {
    const defaultClass = "cs-verification-item background-color-muted color-dark";
    return defaultClass;
  }

  get iconName() {
    switch (this.doc.status) {
      //rejected
      case 754310000:
        return "icon-wrong color-alert";
      //approved
      case 754310001:
        return "icon-right color-primary";
      //solicited
      case 754310002:
        return "icon-alert color-secondary";
      //processing
      case 1:
        return "icon-Clock color-progress";
      //inactive
      case 2:
        return null;
    }
  }

  get iconSource() {
    switch (this.doc.status) {
      //rejected
      case 754310000:
        return "icon-verification-fail.svg";
      //approved
      case 754310001:
        return "icon-verification-completed.svg";
      //solicited
      case 754310002:
        return "icon-verification-requested.svg";
      //processing
      case 1:
        return "icon-verification-pending.svg";
      //inactive
      case 2:
        return null;
    }
  }

  get statusLabel() {
    const DEFAULT_STATUS_LABEL = "";
    const { status, type } = this.doc;

    if (!status) {
      return DEFAULT_STATUS_LABEL;
    }

    const commonLabels = {
      "754310002": "Documentación solicitada",
      "1": "Se está revisando la documentación recibida el",
      "2": "Inactivo",
    };

    const documentLabels = {
      ...commonLabels,
      "754310000": "Verificación rechazada",
      "754310001": "Documento aprobado",
    };

    const verificationLabels = {
      ...commonLabels,
      "754310000": "Verificación rechazada, solicitó verificación automática (Veridas)",
      "754310001": "Verificación aprobada",
    };
    return type.toString() == "754310003"
      ? verificationLabels[status.toString()]
      : documentLabels[status.toString()];
  }

  get statusLabelStyle() {
    const { status } = this.doc;
    return status == "1" ? "cs-doc-type--text" : "cs-doc-type--title";
  }

  public get isColombia() {
    return this.globalVars.licenseType == LicenseTypes.Colombia;
  }
}
