export interface stakeGroups {
  _minimumStakes: any;
  singles?: singlesGroup;
}

export interface singlesGroup {
  staking: stakingGroup;
  calculation: calculationGroup;
  validation: validationGroup;
}
export interface accumulatorGroup {
  staking: stakingGroup;
  calculation: calculationGroup;
  validation: validationGroup;
}
export interface systemGroup {
  staking: stakingGroup;
  calculation: calculationGroup;
  validation: validationGroup;
}

export interface stakingGroup {
  singles: stakingSinglesGroup[];
  systems: stakingSystemsGroup[];
  doubles: stakingSystemsGroup[];
  triples: stakingSystemsGroup[];
  cuadruples: stakingSystemsGroup[];
  overallStake: number;
}

export interface stakingSinglesGroup {
  itemId?: number;
  systemId?: number;
  overallStake?: number;
  stakePerBetWay: number;
  voucherCode?: string;
  isEachWay: boolean;
  group: string;
}
export interface stakingSystemsGroup {
  isEachWay: boolean;
  overallStake: number;
  systemId: number;
}
export interface calculationGroup {
  singles: calculationSinglesGroup[];
  doubles: calculationSinglesGroup[];
  triples: calculationSinglesGroup[];
  cuadruples: calculationSinglesGroup[];
  systems: calculationSystemGroup[];
  betslip: betslipGroup;
}

export interface calculationSinglesGroup {
  itemId: number;
  maxReturn: number;
  maxReturnNet: number;
  maxSettlementTax: number;
  numberOfBetWays: number;
}
export interface calculationSystemGroup {
  bonus: any[];
  maxReturn: number;
  maxReturnIncludingBonus: number;
  maxReturnNet: number;
  maxReturnNetIncludingBonus: number;
  maxSettlementTax: number;
  maxSettlementTaxIncludingBonus: number;
  systemId: number;
  totalOdds: number;
}

export interface betslipGroup {
  maxReturn: number;
  maxReturnNet: number;
  overallStakeNet: number;
  placementTax: number;
  placementTaxRate: number;
  maxSettlementTax: number;
  maxSettlementTaxIncludingBonus: number;
  nonRedeemableStakes: number;
  maxBonusReturn: number;
  maxBonusReturnNet: number;
  maxReturnIncludingBonus: number;
  maxReturnNetIncludingBonus: number;
}

export interface validationGroup {
  stake: any;
  returns: any;
  bonus: any;
}
