import { Component, inject } from "@angular/core";
import { IonicModule } from "@ionic/angular";

import { GlobalVarsModel } from "@models/ModelGlobalsVar";

import { BackButton } from "@shared-common-components/back-button/back-button";
import { CodereFooterComponent } from "@shared-mso-components/footer/codere-footer/codere-footer";
import { UserSectionTitle } from "@shared-mso-components/user-section-title/user-section-title";

import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { take } from "rxjs";

@Component({
  standalone: true,
  selector: "geolocation-page",
  templateUrl: "./geolocation-page-mobile.html",
  styleUrls: ["./geolocation-page-mobile.scss"],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    IonicModule,
    TranslateModule,
    CodereFooterComponent,
    BackButton,
    UserSectionTitle,
  ],
})
export class GeolocationPage {
  globalVars!: GlobalVarsModel;
  isMobile: boolean;
  type: string;

  private router = inject(Router);
  private activatedRouter = inject(ActivatedRoute);

  constructor() {
    this.activatedRouter.queryParams.pipe(take(1)).subscribe((params) => {
      if (params["type"]) {
        this.type = params["type"];
      }
    });
  }

  backToHome() {
    this.router.navigate(["/"], { queryParams: { animate: false } });
  }
}
