<ion-content
  *ngIf="isDesktop; else mobileView"
  class="playtech-iframe"
  [ngClass]="{ 'platform-core': isDesktop, 'platform-mobile': !isDesktop }"
>
  <button class="closeModal" (click)="close()">
    <ion-icon name="close-outline"></ion-icon>
  </button>
  <div class="playtech-iframe-container">
    <h3>{{'bonuscasino' | translate}}</h3>
    <img [src]="globalVars.FEATURES.CAS_BonusMsgImg" alt="banderin" width="400" height="120" />
    <iframe #iframe [src]="url" frameborder="0"> </iframe>
  </div>
</ion-content>

<ng-template #mobileView>
  <ion-content class="playtech-iframe mobile" [ngClass]="{ 'platform-core': isDesktop, 'platform-mobile': !isDesktop }">
    <div class="fakeModal">
      <button class="closeX" (click)="close()">
        <ion-icon name="close-outline"></ion-icon>
      </button>
      <div class="playtech-iframe-container">
        <h3>{{'bonuscasino' | translate}}</h3>
        <img [src]="globalVars.FEATURES.CAS_BonusMsgImg" alt="banderin" width="260" height="100" />
      </div>
      <iframe #iframe [src]="url" frameborder="0" (load)="setScroll()"></iframe>
    </div>
  </ion-content>
</ng-template>
