import { Component, Input } from "@angular/core";
import { IonicModule, ModalController } from "@ionic/angular";

@Component({
  selector: "cs-confirm-modal",
  templateUrl: "./cs-confirm-modal.html",
  styleUrls: ["./cs-confirm-modal.scss"],
  standalone: true,
  imports: [IonicModule],
})
export class CsConfirmModal {
  @Input() title = "Retención";
  @Input() subtitle = "";
  @Input() acceptButtonText = "Aceptar";
  @Input() cancelButtonText = "Cancelar";

  constructor(public modalCtrl: ModalController) {}

  async accept() {
    await this.modalCtrl.dismiss(true);
  }

  async cancel() {
    await this.modalCtrl.dismiss(false);
  }
}
