<div class="layout-filter-group">
  <div class="layout-selected-text">
    {{ text }}
    <i *ngIf="selectedRows || selectedReels" item-right class="codere-icon icon-close" (click)="remove()"></i>
  </div>
  <div *ngFor="let rows of generateRange(filterGroup.rows)" class="layout-rows">
    <div
      *ngFor="let reels of generateRange(filterGroup.reels)"
      (click)="selectLayout(reels, rows)"
      class="layout-reels"
      [class.selected]="rows === selectedRows - 1 && reels === selectedReels - 1"
      [class.under-selected]="rows <= selectedRows - 1 && reels <= selectedReels - 1"
    ></div>
  </div>
</div>
