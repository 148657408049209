import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ExtractDatePipe } from "@pipes/extractDate.pipe";

@Component({
  selector: "sb-prematch-scoreboard",
  templateUrl: "./sb-prematch-scoreboard.html",
  standalone: true,
  imports: [CommonModule, FormsModule, ExtractDatePipe],
  styleUrls: ["./sb-prematch-scoreboard.scss"],
})
export class SbPrematchScoreboardComponent {
  @Input() scoreboard: any;

  constructor() {}
}
