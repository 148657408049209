import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "sb-tabs",
  templateUrl: "./sb-tabs.component.html",
  styleUrls: ["./sb-tabs.component.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule],
})
export class SbTabsComponent {
  /**
   * scrollable segment
   * @input categories[]
   * @type Array
   * */
  @Input() scrollable: any = false;
  /**
   * categories Array
   * @input categories[]
   * @type Array
   * */
  @Input() categories: any;
  /**
   * Selected filter
   * @input selected
   * @type {category}
   */
  @Input() selectedTab: any;
  /**
   * Event emmited when the user selects a tab
   * @output category
   * @type {category}
   */
  @Output() selectTab: EventEmitter<any> = new EventEmitter();
  /**
   * color mode: 'regular', 'dark'
   * @input color
   * @type string
   */
  @Input() color: string = "regular";
  /**
   * Small font-size for the buttons
   * @input smallButton
   * @type boolean
   */
  @Input() smallButton: boolean = false;

  constructor() {}

  /**
   * Selects an emmit the category selection
   * @param cat
   * @param index
   */
  onClick(cat, index) {
    this.selectTab.emit({ ...cat, index });
  }
}
