import { CommonModule } from "@angular/common";
import { Component, ElementRef, ViewChild, inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { SourcesCDR } from "@constants";
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/component-selector */
import {
  AlertController,
  IonContent,
  IonicModule,
  MenuController,
  ModalController,
  NavController,
  NavParams,
  Platform,
} from "@ionic/angular";
import { CookieNames } from "@models/CookieNames";
import { I_PreValidateSignupArg, I_SignupFormArg } from "@models/I_SignupArgentinaDecoupling";
import { I_UserArgentina } from "@models/I_UserArgentina";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { EventTypes } from "@models/TrackingEvents";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { CookiesService } from "@providers/CookiesService";
import { DecouplingUserServiceMSO, ErrorDecoupling } from "@providers/DecouplingUserServiceMSO.service";
import { EventsService } from "@providers/EventsService";
import { GeolocationService } from "@providers/GeolocationService";
import { RedirectService } from "@providers/RedirectService";
import { SlotsBonusesService } from "@providers/SlotsBonusesService";
import { TrackingService } from "@providers/TrackingService";
import { UserService } from "@providers/UserService";
import { UserServiceMSO } from "@providers/UserServiceMSO";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { CodereNavbar } from "@shared-common-components/codere-navbar/codere-navbar";
import { MSO_PATHS } from "@shared-constants/routes";
import { Utils } from "@utils/Utils";
import { zip } from "rxjs";
import { map, tap } from "rxjs/operators";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const jobsAR = "";
@Component({
  templateUrl: "./registroAR.html",
  styleUrls: ["./registroAR.scss"],
  selector: "registroAR-page",
  standalone: true,
  imports: [CommonModule, IonicModule, CodereNavbar, TranslateModule],
  providers: [NavParams],
})
export class RegistroARPage {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild("iframe") iframeNode: ElementRef;
  codepromotion: string;
  scrReg: any;
  regListener: any;
  cardNumber: any;
  parameters: any;
  renderIFrame: boolean;
  globalVars!: GlobalVarsModel;
  headerElement: HTMLElement;
  //Dependencias

  trackingService = inject(TrackingService);
  utils = inject(Utils);
  userService = inject(UserService);
  userServiceMSO = inject(UserServiceMSO);
  translate = inject(TranslateService);
  events = inject(EventsService);
  cookiesService = inject(CookiesService);
  geolocationService = inject(GeolocationService);
  newBaseService = inject(NewBaseService);
  nav = inject(NavController);
  menu = inject(MenuController);
  navParams = inject(NavParams);
  modalCtrl = inject(ModalController);
  sanitizer = inject(DomSanitizer);
  alertCtrl = inject(AlertController);
  slotsBonusesService = inject(SlotsBonusesService);
  redirectService = inject(RedirectService);
  platform = inject(Platform);
  deviceService = inject(DeviceService);
  router = inject(Router);
  viewCtrl = inject(ModalController);
  decouplingService = inject(DecouplingUserServiceMSO);

  isMobile = this.deviceService.isMobile();
  isDesktop = this.deviceService.isDesktop();

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.renderIFrame = false;
    this.setCardNumber();
    this.parameters = this.utils.getParamStore();
    console.info("Parámetros en registroAR", this.parameters);

    this.defineIFrame();

    this.codepromotion = "";
    window["jobsAR"] = this.globalVars.FEATURES.ListOfProfesions;
    const tempCode: string = this.cookiesService.getCookie(CookieNames.CoderePromotionCode);
    if (tempCode != "") {
      this.codepromotion = tempCode;
    }
    if (this.globalVars.codePromotion) {
      this.codepromotion = this.globalVars.codePromotion;
      if (this.codepromotion != tempCode) {
        this.cookiesService.setCookie(
          CookieNames.CoderePromotionCode,
          this.codepromotion,
          3 * 24 * 60 * 60 * 1000,
          true,
        );
      }
    }
    if (this.navParams.get("item")) {
      if (this.navParams.get("item").codepromotion) {
        this.codepromotion = this.navParams.get("item").codepromotion;
        if (this.codepromotion != tempCode) {
          this.cookiesService.setCookie(
            CookieNames.CoderePromotionCode,
            this.codepromotion,
            3 * 24 * 60 * 60 * 1000,
            true,
          );
        }
      }
    }
    if (this.navParams.get("codepromotion")) {
      this.codepromotion = this.navParams.get("codepromotion");
      if (this.codepromotion != tempCode) {
        this.cookiesService.setCookie(
          CookieNames.CoderePromotionCode,
          this.codepromotion,
          3 * 24 * 60 * 60 * 1000,
          true,
        );
      }
    }
    this.regListener = this.listenEvents.bind(this);
  } // Fin del constructor

  defineIFrame() {
    let url = this.setUrl();
    //añadir parámetros renaper
    url = this.renaperParams(url);
    //añadir parámetros promociones
    url = this.promoParams(url);
    //añadir parámetros de cardNumber
    url = this.cardNumberParams(url);
    //añadir parámetros no login
    url += "&nologin=false";
    this.renderIFrame = true;
    this.scrReg = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    if (this.globalVars.licenseType == 33) {
      this.slotsBonusesService.taboolaTrack("lead");
    }
  }
  /**
   * Procesamiento de la respuesta del API con los datos corespondientes
   * al usuario preregistrado (si existían parámetros)
   * En la respuesta a getPreregisterUser
   * comprueba si hay datos repetidos de usuarios para los campos
   * TELEFONO y CORREO
   * @param res
   */
  processUserData(data) {
    const consultas = [];
    console.info("-------------------------------------");
    console.info("Respuesta del servicio Angular");
    console.info(data);

    if (data.email) {
      consultas.push(this.userServiceMSO.checkDataAvailability("CORREO", data.email));
    }
    if (data.mobile) {
      consultas.push(this.userServiceMSO.checkDataAvailability("TELEFONO", data.mobile));
    }

    if (!consultas.length) {
      return;
    }

    zip(...consultas)
      .pipe(
        tap((results) => console.info(results)),
        map((results) => {
          const result = results.some((item: any) => item);
          console.info(result);
          if (!result) {
            // no repetido
            this.defineIFrame();
            localStorage.setItem("Preregistro", JSON.stringify(data));
          } else {
            // Si hay datos repetidos, no cargar nada en el iframe y cerrarlo
            // Hacer una llamada a Acceder
            this.utils.openAccder$.next(true);
            this.closeModal();
          }
        }),
      )
      .subscribe();
  }

  ionViewWillLeave() {
    this.utils.unloadContactChat();
    window.removeEventListener("message", this.regListener);
    this.utils.hideFormAccessoryBar(true);
  }

  backToHome() {
    this.router.navigate(["/"]);
  }

  ionViewWillEnter() {
    this.utils.hideFormAccessoryBar(false);
    window.addEventListener("message", this.regListener);
    this.trackingService.trackEvent(["AccessToRegisterFromButton", "", "", "Registro desde boton", "event"]);

    // New tracking services
    this.trackingService.track({ eventType: EventTypes.Lead });
    if (this.isMobile) {
      this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.RegistroARPage}`]);
    }
  }

  async listenEvents(e: any) {
    if (e.data["action"] == "RegOK") {
      if (this.globalVars.FEATURES.MSO_EnableGeolocation) {
        this.geolocationService.showGeolocation();
      }
      // En Argentina se conprueba si el usuario acaba de registrarse
      // con el consiguiente login desde el backend
      // y se ha detectado su autoexclusión parcial
      if (this.globalVars.licenseType == 33) {
        const autoExclusion = JSON.parse(localStorage.getItem("alertSelfExclusionLotba"));
        if (autoExclusion) {
          console.info("Usuario autoexcluido en otra plataforma ");
          localStorage.removeItem("alertSelfExclusionLotba");
          this.utils.selfExclusionModal(1, this.viewCtrl);
        }
      }

      this.closeModal();

      if (this.globalVars.FEATURES.MSO_GraceTimeLogicEnabled) {
        await this.utils.showAlertGraceTime(this.translate.instant("regOkArMzGraceTime"));
      }
      //opción desactivada de distintos destinos después del registro tal como está en otros países
      this.doLoginAndGoto(e?.data?.["dest"]);

      window.removeEventListener("message", this.regListener);
    }
    if (e.data["action"] == "closeModal") {
      this.closeModal();
      window.removeEventListener("message", this.regListener);
    }
    if (e.data["action"] == "trackEvent") {
      this.trackingService.trackEvent(e.data["event"]);
    }
    if (e.data["action"] == "refresh") {
      const iframe: any = document.getElementsByClassName("iframeFullScreen").item(0);
      iframe.style = "display:none";
      iframe.style = "display:block";
    }

    if (e.data.action == "startIFrame") {
      if (this.navParams.get("id")) {
        // Pre-Registro desde Retail
        const iframeElem = this.iframeNode.nativeElement;
        const preRegistroID = this.navParams.get("id");
        console.info("Pre-Registro", preRegistroID);
        this.userServiceMSO.getPreRegisterUserAr(preRegistroID).subscribe((res) => {
          const obj = {
            preRegistroAR: true,
            userData: res,
          };
          iframeElem.contentWindow.postMessage(obj);
        });
      } else if (this.navParams.get("JWTRegistro")) {
        this.fillRegFromJWT(this.navParams.get("JWTRegistro"));
      } else if (this.navParams.data && this.navParams.data["item"] && this.navParams.data["item"].JWTRegistro) {
        this.fillRegFromJWT(this.navParams.data["item"].JWTRegistro);
      }
    }

    if (e.data.action === "doRegister") {
      if (!this.globalVars.FEATURES.MSO_NewDecouplingServiceSignup) {
        this.doRegister(e.data.user);
      } else {
        this.signupDecoupling(e.data.user);
      }
    }
    if (e.data.action === "redirect") {
      this.redirectFromRegister(e.data);
    }
    if (e.data.action === "PreValidateSignup") {
      this.doPreValidateSignup(e.data.form);
    }
  }

  doLoginAndGoto(dest: string = "") {
    if (dest == "Home") {
      this.utils.loginAndGoto("/", null, false).then((isOk) => {
        if (isOk) {
          this.partialSelfExclusion();
          this.closeModal();
        } else {
          this.linkOutOfModalPage(this.router.url || "/", {});
        }
      });
    } else if (dest == "Cashier") {
      this.utils.loginAndGoto(this.globalVars.mainPageComponent, { name: "VisaBancomer" }, false).then((isOk) => {
        if (isOk) {
          this.partialSelfExclusion();
          this.closeModal();
        } else {
          this.linkOutOfModalPage(this.router.url || "/", {});
        }
      });
    } else {
      // 'Documents'
      this.utils.loginAndGoto(this.globalVars.mainPageComponent, { section: "documents" }, false).then((isOk) => {
        if (isOk) {
          this.partialSelfExclusion();
          this.closeModal();
        } else {
          this.linkOutOfModalPage(this.router.url || "/", {});
        }
      });
    }
  }

  redirectFromRegister(data) {
    if (!this.globalVars.isNative) {
      this.redirectService.generateJWTFromData(data.param).subscribe({
        next: (rsp) => {
          window.location.replace(`${this.getURLToRedirect(data.dest)}?JWTRegistro=${rsp}`);
        },
        error: (err) => {
          console.info(err);
        },
      });
    }
  }

  fillRegFromJWT(jwt: string) {
    this.redirectService.loadDataFromJWT(jwt).subscribe({
      next: (resp) => {
        const dataReg = JSON.parse(resp);
        const iframeElem = this.iframeNode.nativeElement;
        const obj = {
          regFromRedirect: true,
          userData: { ...dataReg },
        };
        iframeElem.contentWindow.postMessage(obj);
      },
      error: (err) => {
        console.info(err);
      },
    });
  }

  getURLToRedirect(license: number) {
    const urls = JSON.parse(this.globalVars.FEATURES.registroRedirect) || [];
    return urls.find((u) => u[license])[license];
  }
  doRegister(user: I_UserArgentina) {
    const iframeElem = this.iframeNode.nativeElement;
    this.utils.loader();

    try {
      this.userServiceMSO.registerArgentina(user).subscribe({
        next: (data) => {
          this.utils.closeLoader();
          if ((typeof data.status === "string" && data.status.toUpperCase() === "OK") || data.status === 0) {
            //RegistroOK
            this.trackingService.trackEvent(["FinishRegister", "OK", user.login, user.email, "event"]);
            if (this.globalVars.licenseType == 33) {
              this.slotsBonusesService.taboolaTrack("complete_registration");
            }
            iframeElem.contentWindow.postMessage({
              action: "RegOK",
              user,
              data,
            });
          } else {
            iframeElem.contentWindow.postMessage({
              action: "RegKO",
              user,
              errorCode: data.status,
              errorMess: data.Message,
              errorDess: data.errorDescription,
              errorAr: data.errorCode,
            });
          }
        },
        error: (err) => {
          this.utils.closeLoader();
          this.trackingService.trackEvent(["FinishRegister", "KO", "ERR", "Fin Registro KO", "event"]);
          iframeElem.contentWindow.postMessage({
            action: "RegKO",
            user,
            errorCode: -1,
          });
        },
      });
    } catch (error) {
      this.utils.closeLoader();
      this.trackingService.trackEvent(["FinishRegister", "KO", "ERR", "Fin Registro KO", "event"]);
      iframeElem.contentWindow.postMessage({
        action: "RegKO",
        user,
        errorCode: -1,
      });
    }
  }

  async linkOutOfModalPage(link: any, paymentArguments: any) {
    if (this.isMobile) {
      this.navigateToLink(link, paymentArguments);
    } else {
      if (this.viewCtrl) {
        this.viewCtrl.dismiss(null, null, { animate: false } as any).then(() => {
          this.navigateToLink(link, paymentArguments);
        });
        this.viewCtrl = null;
      } else {
        this.navigateToLink(link, paymentArguments);
      }
    }
  }
  private navigateToLink(link: any, paymentArguments: any) {
    this.router.navigate([link], {
      queryParams: paymentArguments,
      queryParamsHandling: "merge",
      skipLocationChange: false,
      replaceUrl: false,
      state: { animate: !this.isDesktop },
    });
  }
  async partialSelfExclusion() {
    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba && this.globalVars.user.username) {
      const autoExclusion = JSON.parse(localStorage.getItem("alertSelfExclusionLotba"));
      if (autoExclusion) {
        console.info("Usuario autoexcluido en otra plataforma ");
        localStorage.removeItem("alertSelfExclusionLotba");
        this.utils.selfExclusionModal(1, this.viewCtrl);
      }
    }
  }

  async nologinAndGoto(dest: any, params = {}) {
    this.linkOutOfModalPage("/", params);
    // Se cierra el modal si exista -> desktop
    this.closeModal();

    this.globalVars.rootScope.doLogout();
    this.utils.setCookie(".CodereApuestas", "", 0);
    const footer = await this.utils.createARFooter();

    const alert = await this.alertCtrl.create({
      header: "BIENVENIDO A CODERE!",
      message: "Te registraste correctamente.<br>Próximamente vas a poder disfrutar de nuestra oferta de juegos.",
      cssClass: "alertnologin",
      buttons: [
        {
          text: "Aceptar",
          role: "cancel",
        },
      ],
    });

    await alert.present();
    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      // Agregar el pie de página después de que la alerta se haya presentado
      const ionAlert = await this.alertCtrl.getTop();
      if (ionAlert) {
        const alertElement = ionAlert.querySelector(".alert-wrapper");
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }
  }

  closeModal() {
    if (this.viewCtrl) {
      this.viewCtrl.dismiss();
      this.viewCtrl = null;
    }
  }

  openChat() {
    this.utils.loadContactChat();
    setTimeout(
      () => {
        this.utils.openContactChat();
      },
      this.globalVars.FEATURES.newChatEnabled ? 1 : 800,
    );
  }

  private setCardNumber() {
    const item = this.navParams.get("item") || this.navParams.get("data");
    const cardNumber = this.navParams.get("cardNumber");
    // eslint-disable-next-line no-nested-ternary
    this.cardNumber = item && item.cardNumber ? item.cardNumber : cardNumber ? cardNumber : "";
  }

  private renaperParams(url: string): string {
    return !this.globalVars.registry || this.globalVars.registry == "noRenaper" ? url : url + `&renaper=true`;
  }

  private promoParams(url: string): string {
    return this.codepromotion ? url + `&codepromotion=${this.codepromotion}` : url;
  }

  private cardNumberParams(url: string): string {
    return this.cardNumber ? url + `&numbercard=${this.cardNumber}` : url;
  }

  private setUrl(): string {
    return this.globalVars.URLBASE_REGAR + "/reg.html?iswebapppc=" + this.isDesktop + "&v=" + new Date().getTime();
  }

  private doPreValidateSignup(data: I_PreValidateSignupArg) {
    this.decouplingService.preValidateSignupArg(data).subscribe({
      next: (response) => {
        this.sendPostMessage({ action: "PreValidateSignupOK", response });
      },
      error: (err) => {
        this.sendPostMessage({ action: "PreValidateSignupKO", err });
      },
    });
  }

  private signupDecoupling(data: I_SignupFormArg) {
    data.source = this.globalVars.isNative ? SourcesCDR.NativeApp : SourcesCDR.WebApp;
    this.decouplingService.signupArg(data).subscribe({
      next: (response) => {
        this.trackingService.trackEvent(["FinishRegister", "OK", data.playerUserName, data.email, "event"]);
        this.sendPostMessage({ action: "SignupDecouplingOK", response });
      },
      error: (err) => {
        const { description } = err as ErrorDecoupling;

        this.trackingService.trackEvent(["FinishRegister", "KO", description, "Fin Registro KO", "event"]);
        this.sendPostMessage({ action: "SignupDecouplingKO", err });
      },
    });
  }

  private sendPostMessage(data: { action: string; [key: string]: any }) {
    const iframeElem = this.iframeNode.nativeElement;
    iframeElem.contentWindow.postMessage(data);
  }
}
