/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { GlobalVarsModel } from "../models/ModelGlobalsVar";
declare global {
  interface Window {
    GLOBALS: any;
  }
}
@Injectable({ providedIn: "root" })
export class NewBaseService {
  private GlobalVar$: BehaviorSubject<GlobalVarsModel> = new BehaviorSubject<GlobalVarsModel>(
    new GlobalVarsModel(window.GLOBALS),
  );
  constructor() {}

  set setVar(vars: GlobalVarsModel) {
    this.GlobalVar$.next(vars);
    window.GLOBALS = vars;
  }

  get getVars() {
    return this.GlobalVar$.asObservable();
  }
}
