/* eslint-disable eqeqeq */
import { C_Slot } from "./C_Slot";

export class C_LobbyGame {
  GameGroupId: string;
  GameGroupName: string;
  GameLobbyGroupType: string;
  GameLobbyGroupHandling: string;
  GameLobbyGroupId: string;
  GameLobbyGroupName: string;
  GameId: string;
  GameName: string;
  GamePriority: number;
  GameTileSize: string;
  LicenseGroupPriority: number;
  Createdon: string;
  TileSizeEnum: number = 0;
  Image: string = "";
  Slot: C_Slot;
  Description?: string;
  Badges?: any;
  ImageType?: string;
  Display: string;
  live: boolean;

  static addBadges(lobby: C_LobbyGame, fullLobby: C_LobbyGame[]) {
    if (lobby.GameLobbyGroupType === "Badge") {
      const lobbyFilter = fullLobby.filter((g) => g.GameId == lobby.GameId);
      if (lobbyFilter.length) {
        lobbyFilter.forEach((g) => {
          if (g.Badges) {
            g.Badges.push(lobby.GameGroupName);
          } else {
            g.Badges = [lobby.GameGroupName];
          }
        });
      }
    }
  }
}
