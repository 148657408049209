import { PayUDepositActionResult } from "@models/C_Transactions";
import { ErrorService } from "@providers/ErrorService";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserService } from "../UserService";
import { PaymentMethodBehaviour, RedirectMethodBehaviourResult, SkipAmountResult } from "./PaymentMethodBase";

export class PayUPaymentMethodBehaviour extends PaymentMethodBehaviour {
  name = "PayU";
  image = "PayU";
  track = "PayU";
  isBank = false;
  minDepositAmount = 100;
  maxDepositAmount = 999999999;
  amountButtons: number[] = [300, 400, 800, 100];
  amount = 400;

  deviceType: string;
  isPSE: boolean;

  constructor(userService: UserService, errorService: ErrorService) {
    super(false, userService, errorService);
    this.isPSE = false;
  }
  skipAmountInput(): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  paymentMetodExecution(): Observable<RedirectMethodBehaviourResult> {
    return this.userService.validatePayUDep(this.amount, this.deviceType, this.isPSE).pipe(
      map((obj) => {
        return this.map(obj);
      }),
    );
  }

  private map(input: PayUDepositActionResult): RedirectMethodBehaviourResult {
    const result = new RedirectMethodBehaviourResult();
    if (input.success) {
      result.success = true;

      result.url =
        "https://m.codere.com.co/transactionsApi/payu/payuform.html" +
        "?" +
        "merchantId=" +
        input.merchantId +
        "&referenceCode=" +
        input.referenceCode +
        "&description=" +
        input.description +
        "&amount=" +
        input.amount +
        "&signature=" +
        input.signature +
        "&accountId=" +
        input.accountId +
        "&currency=" +
        input.currency +
        "&buyerFullName=" +
        input.buyerFullName +
        "&buyerEmail=" +
        input.buyerEmail +
        "&responseUrl=" +
        input.responseUrl +
        "&confirmationUrl=" +
        input.confirmationUrl +
        "&url=" +
        input.url +
        "&tax=" +
        input.tax +
        "&taxReturnBase=" +
        input.taxReturnBase +
        "&paymentMethods=" +
        input.paymentMethods +
        "&phoneNumber=" +
        input.phoneNumber +
        "&payerDocument=" +
        input.payerDocument +
        "&payerDocumentType=" +
        input.payerDocumentType;
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams(
        "error" + input.errCode,
        input.errorParameters,
      );
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode == 174 || input.errCode == 2212;
      result.sendToUserProfileForm = input.errCode == 2213;
    }

    return result;
  }
}

export class PayUPSEPaymentMethodBehaviour extends PayUPaymentMethodBehaviour {
  constructor(userService: UserService, errorService: ErrorService) {
    super(userService, errorService);
    this.isPSE = true;
  }
}
