import { C_JackpotInfo } from "./C_JackpotInfo";
import { C_JackpotUI } from "./C_JackpotUI";

export class C_Jackpot {
  jackpotInfo: C_JackpotInfo;
  uiInfo: C_JackpotUI;

  constructor(jackpot: C_Jackpot) {
    this.jackpotInfo = C_JackpotInfo.parse(jackpot.jackpotInfo);
    this.uiInfo = jackpot.uiInfo;
  }
}
