export class C_ItemMenu {
  label: string;
  link: any;
  section: string;
  isPrivate: boolean;
  badgeCount: number;
  isAlert: boolean;
  params?: any;

  constructor(
    label: string,
    link: any,
    section: string,
    isPrivate: boolean = true,
    badgeCount: number = 0,
    _isAlert: boolean = false,
    params?: any,
  ) {
    this.link = link;
    this.section = section;
    this.label = label;
    this.isPrivate = isPrivate;
    this.badgeCount = badgeCount;
    this.isAlert = _isAlert;
    this.params = params;
  }
}
