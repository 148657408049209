import { Injectable, Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "titleCase",
  standalone: true,
})
@Injectable({ providedIn: "root" })
export class TitleCasePipe implements PipeTransform {
  transform(value: string, CountryCode?: string): string {
    if (!value) {
      return;
    }
    if (CountryCode === "US" || CountryCode === "US2") {
      return value;
    }
    return (
      value
        .split(" ")
        // eslint-disable-next-line arrow-body-style, no-shadow
        .reduce((acc, value) => {
          return (acc += " " + value.charAt(0).toUpperCase() + value.slice(1).toLowerCase());
        }, "")
        .trim()
    );
  }
}
