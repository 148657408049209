/* eslint-disable no-prototype-builtins */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/component-selector */
import { CommonModule } from "@angular/common";
import { Component, ViewChild, inject } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { AlertController, IonContent, IonicModule } from "@ionic/angular";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import { EventsService } from "@providers/EventsService";
import { TrackingService } from "@providers/TrackingService";
import { UserService } from "@providers/UserService";
import { NewBaseService } from "@providers/newBase.service";
import { Utils } from "@utils/Utils";

import { CodereFooterComponent } from "@shared-mso-components/footer/codere-footer/codere-footer";
import { UserSectionTitle } from "@shared-mso-components/user-section-title/user-section-title";

import { Router } from "@angular/router";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { DeviceService } from "@services/device.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "timeout-page",
  templateUrl: "./timeOut.html",
  styleUrls: ["./timeOut.scss"],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    UserSectionTitle,
    CodereFooterComponent,
  ],
})
export class TimeOutPage {
  @ViewChild(IonContent) content: IonContent;
  myForm: FormGroup;
  selfEx: any;
  dayMonth: Array<string>;
  months: Array<{ label: string; data: string }>;
  years: Array<number>;
  validDate: boolean;
  public trackEvent: TrackEvent;
  argButtons: {
    sports: boolean;
    slots: boolean;
    casino: boolean;
    all: boolean;
    sportscrm: boolean;
    slotscrm: boolean;
    casinocrm: boolean;
  };
  argTimes: Array<{ name: string; time: number; selected: boolean; enabled: boolean }>;
  argErrors: any;
  alertConfirm: HTMLIonAlertElement; //^* Alert modificado a IonAlert*
  argSelectProd: string;
  argSelelctTimeIndex: number;
  timeRest: number;
  isDesktop: boolean = false;

  globalVars!: GlobalVarsModel;

  fb = inject(FormBuilder);
  utils = inject(Utils);
  userService = inject(UserService);
  events = inject(EventsService);
  translate = inject(TranslateService);
  trackingService = inject(TrackingService);
  newBaseService = inject(NewBaseService);
  alertController = inject(AlertController);
  deviceService = inject(DeviceService);
  router = inject(Router);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isDesktop = this.deviceService.isDesktop();

    this.selfEx = {
      day: "",
      month: "",
      year: "",
    };
    this.myForm = this.fb.group({
      day: ["", Validators.required],
      month: ["", Validators.required],
      year: ["", Validators.required],
    });

    this.dayMonth = [];
    for (let i = 1; i <= 31; i++) {
      this.dayMonth.push(i < 10 ? "0" + i.toString() : i.toString());
    }
    const arrMeses = [
      this.translate.instant("rMEn"),
      this.translate.instant("rMFe"),
      this.translate.instant("rMMa"),
      this.translate.instant("rMAv"),
      this.translate.instant("rMMy"),
      this.translate.instant("rMJn"),
      this.translate.instant("rMJl"),
      this.translate.instant("rMAg"),
      this.translate.instant("rMSe"),
      this.translate.instant("rMOc"),
      this.translate.instant("rMNo"),
      this.translate.instant("rMDi"),
    ];

    this.months = [];

    for (let i = 0; i < 12; i++) {
      this.months.push({ label: arrMeses[i], data: i < 9 ? "0" + (i + 1).toString() : (i + 1).toString() });
    }

    this.years = [];
    const nowYearDD: number = new Date().getFullYear();
    const maxYearDD = nowYearDD + 100;
    for (let i = nowYearDD; i < maxYearDD; i++) {
      this.years.push(i);
    }

    this.myForm.controls["day"].valueChanges.subscribe((value: string) => {
      this.checkValidDate();
    });
    this.myForm.controls["month"].valueChanges.subscribe((value: string) => {
      this.checkValidDate();
    });
    this.myForm.controls["year"].valueChanges.subscribe((value: string) => {
      this.checkValidDate();
    });

    this.argButtons = {
      sports: false,
      slots: false,
      casino: false,
      all: false,
      sportscrm: false,
      slotscrm: false,
      casinocrm: false,
    };
    this.argTimes = [
      { name: "timeout24h", time: 24 * 60, selected: false, enabled: true },
      { name: "timeout48h", time: 48 * 60, selected: false, enabled: true },
      { name: "timeout7d", time: 7 * 24 * 60, selected: false, enabled: true },
      { name: "timeout30d", time: 30 * 24 * 60, selected: false, enabled: true },
      { name: "timeoutArpersonal", time: null, selected: false, enabled: true },
    ];
    this.argErrors = null;
    this.timeRest = 0;
  }

  /**
   * Crea un alert para confirmar o no el proceso de autoexclusion
   */
  async createAlertConfirm() {
    this.alertConfirm = await this.alertController.create({
      header: this.translate.instant("CodereCardconfir") + " " + this.translate.instant("timeout"),
      message: this.translate.instant("timeoutquestion"),
      buttons: [
        {
          text: "NO",
          handler: () => {
            this.createAlertConfirm();
          },
          role: "cancel",
        },
        {
          text: "Sí",
          handler: () => {
            this.finalExclusionArgentina();
          },
        },
      ],
    });
  }

  ionViewWillEnter() {
    this.trackingService.trackEvent(["AccessToTimeOut", "", "", "", "event"]);
    this.events.publish("footer:refresh", this.content);
    this.getTimeOut();
  }

  selfexclusion_click() {
    let validForm = false;
    if (this.validDate) {
      validForm = true;
    }
    if (validForm) {
      const selfExclusionDate: string =
        this.selfEx.year.toString() + "-" + this.selfEx.month + "-" + this.selfEx.day;
      this.userService
        .createAutoExclusion(selfExclusionDate, this.globalVars.renewSelfExclusion)
        .subscribe((data) => {
          this.onAutoexclusion(data, selfExclusionDate);
        });
    }
  }

  /**
   * Metodo responsable de procesar el resultado de los servicios que
   * hacen las llamadas a los endPoint de autoexclusión en distintos paises.
   * @param data: object con los datos devueltos por el endpoint
   * @param selfExclusionDate : string con la fecha yy-mm-dd
   */
  onAutoexclusion(data, selfExclusionDate: string) {
    if (data.success) {
      this.trackingService.trackEvent(["CreateTimeOutOk", selfExclusionDate, "", "", "event"]);
      this.utils.alert(true, "", this.translate.instant("exclusiontxt01"), "OK", null, "timeOutAlert");
    } else if (data.errMessage.indexOf("exclusión activa") > 0) {
      this.trackingService.trackEvent(["CreateTimeOutKo", selfExclusionDate, "", "", "event"]);
      this.utils.alert(true, "", this.translate.instant("timeoutactive"), "OK");
    } else {
      this.trackingService.trackEvent(["CreateTimeOutKo", selfExclusionDate, "", "", "event"]);
      this.utils.alert(true, "", this.translate.instant("exclusiontxt02"), "OK");
    }
    this.router.navigate(["/"]);
  }

  checkValidDate() {
    //checks if the date is valid

    if (!!this.selfEx.day && !!this.selfEx.month && !!this.selfEx.year) {
      //check the date
      const miDate: Date = new Date(
        parseInt(this.selfEx.year),
        parseInt(this.selfEx.month) - 1,
        parseInt(this.selfEx.day),
        0,
        0,
        0,
        0,
      );

      if (
        miDate.getDate() == parseInt(this.selfEx.day) &&
        miDate.getMonth() == parseInt(this.selfEx.month) - 1 &&
        miDate.getFullYear() == parseInt(this.selfEx.year)
      ) {
        this.validDate = miDate > new Date();
      } else {
        this.validDate = false;
      }
    } else {
      this.validDate = false;
    }
  }

  /**
   * Manejador del evento click de los botones de selección de producto
   * @param item : producto seleccionado
   * @param boolean:
   */
  checkArgButtons(item, mobile = false) {
    if (item === "all") {
      const newState = mobile ? this.argButtons[item] : !this.argButtons[item];
      for (const key in this.argButtons) {
        if (this.argButtons.hasOwnProperty(key) && key.indexOf("crm") < 0) {
          if (mobile) {
            if (!this.argButtons[key + "crm"]) {
              this.argButtons[key] = newState;
            }
          } else {
            this.argButtons[key] = newState;
          }
        }
      }
    } else {
      if (!mobile && !this.argButtons[item + "crm"]) {
        // En Web son botones sin ngModel => el metodo recoge el cambio de valor
        this.argButtons[item] = !this.argButtons[item];
      }
      if (this.argButtons.casino && this.argButtons.slots && this.argButtons.sports) {
        this.argButtons.all = true;
      } else if (this.argButtons.all === true) {
        this.argButtons.casino = this.argButtons.slots = this.argButtons.sports = false;
        this.argButtons.all = false;
      } else {
        this.argButtons.all = false;
      }
    }
    if (this.validateArgButtons() && this.argErrors && this.argErrors.product) {
      delete this.argErrors.product;
    }
  }

  /**
   * Valida que al menos uno de los botones de producto
   * este seleccionado y si es así devuelve un true
   */
  validateArgButtons() {
    for (const key in this.argButtons) {
      if (this.argButtons.hasOwnProperty(key) && this.argButtons[key]) {
        return true;
      }
    }
    return false;
  }

  /**
   * Manejador del evento click de los botones de selección del
   * @param select : objeto con el estado del tiempo seleccionado
   */
  checkArgTimes(select) {
    this.argTimes.forEach((item) => (item.selected = item.name === select.name));
    if (
      (this.validateArgTimes() || select.name === "timeoutArpersonal") &&
      this.argErrors &&
      this.argErrors.time
    ) {
      delete this.argErrors.time;
    }
  }

  /**
   * Manejador de los eventos blur y focus en el input
   * para la opcion de periodo de tiempo personalizado
   * @param ev : Event
   */
  managePersonalizada(ev) {
    const diasrest = Math.ceil(this.timeRest / 24);
    if (ev.type === "input") {
      if (this.argTimes[4].time > 42) {
        this.argTimes[4].time = 42;
      } else if (this.argTimes[4].time < diasrest) {
        this.argTimes[4].time = diasrest;
      }
    }
    if (ev.type === "focus") {
      delete this.argErrors.time;
    } else {
      // blur
      if (this.validateArgTimes()) {
        delete this.argErrors.time;
      } else {
        this.argErrors.time = true;
      }
    }
  }

  /**
   * Valida si alguno de los tiempos esta seleccionado
   * y si es así devuelve un true
   */
  validateArgTimes() {
    if (this.argTimes[4].selected) {
      if (this.argTimes[4].time > 0 && this.argTimes[4].time <= 42) {
        return true;
      } else {
        return false;
      }
    }
    return this.argTimes.some((item) => item.selected);
  }

  /**
   * Manejador del evento click en el boton enviar
   * Valida si hay un producto y un tiempo seleccionado
   * Presenta el alert previamente definido
   */
  async selfExclusionArgentina() {
    console.info("Tiempo Fuera");
    const footer = await this.utils.createARFooter();
    if (!this.validateArgButtons()) {
      this.argErrors = { product: true }; // msg error de validacion
      return;
    }
    if (!this.validateArgTimes()) {
      this.argErrors = { time: true }; // msg error de validacion
      return;
    }
    await this.alertConfirm.present();

    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      // Agregar el pie de página después de que la alerta se haya presentado
      const ionAlert = await this.alertController.getTop();
      if (ionAlert) {
        const alertElement = ionAlert.querySelector(".alert-wrapper");
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }
  }

  /**
   * Manejador del evento click en el alert que permite confirmar la autoexclusión
   * Comprueba de nuevo que estan seleccionador un producto y un tiempo
   * Y hace una llamada al servicio createAutoExclusionAR,
   * responsable de la llamada al EndPoint
   */
  finalExclusionArgentina() {
    if (this.argTimes[4].time) {
      this.argTimes[4].time *= 24 * 60;
    }
    let valid = false;
    delete this.argButtons.all;
    for (const key in this.argButtons) {
      if (this.argButtons.hasOwnProperty(key)) {
        if (this.argButtons[key]) {
          valid = this.argButtons[key];
          break;
        }
      }
    }
    if (!valid) {
      return;
    }
    let time;
    for (const key in this.argTimes) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.argTimes.hasOwnProperty(key)) {
        if (this.argTimes[key].selected) {
          time = this.argTimes[key].time;
          break;
        }
      }
    }
    if (!time) {
      return;
    }
    this.userService.createAutoExclusionAR(time, this.argButtons).subscribe((resp) => {
      console.info(resp);
      this.selfEx = new Date();
      const hoyString =
        this.selfEx.getYear().toString() + "-" + this.selfEx.getMonth() + "-" + this.selfEx.getDay();
      this.onAutoexclusion(resp, hoyString);
    });
  }

  /**
   * Manejador del evento change de los radio butons que permiten
   * seleccionar el periodo de tiempo fuera en el movil
   *
   */
  changeTime() {
    if (this.argSelelctTimeIndex == null) {
      return;
    }
    this.argTimes[this.argSelelctTimeIndex].selected = true;
    this.checkArgTimes(this.argTimes[this.argSelelctTimeIndex]);
  }
  getTimeOut() {
    this.userService.getTimeOutData().subscribe((data) => {
      if (data.info.length) {
        data.info.forEach((element) => {
          if (element.actionName == "Apostar") {
            this.argButtons.sports = true;
            this.argButtons.sportscrm = true;
          }
          if (element.actionName == "Casino") {
            this.argButtons.casino = true;
            this.argButtons.casinocrm = true;
          }
          if (element.actionName == "Slots") {
            this.argButtons.slots = true;
            this.argButtons.slotscrm = true;
          }
        });
        if (data.info.length == 3) {
          this.argButtons.all = true;
        }

        const endTime = new Date(data.info[0].endDate);
        const ahora = new Date();
        let restTime = endTime.getTime() - ahora.getTime();
        restTime = Math.floor(restTime / (1000 * 60 * 60));
        this.timeRest = restTime;
        if (restTime > 24) {
          this.argTimes[0].enabled = false;
        }
        if (restTime > 48) {
          this.argTimes[1].enabled = false;
        }
        if (restTime > 24 * 7) {
          this.argTimes[2].enabled = false;
        }
        if (restTime > 24 * 30) {
          this.argTimes[3].enabled = false;
        }
      } else {
        this.argButtons = {
          sports: true,
          slots: true,
          casino: true,
          all: true,
          sportscrm: false,
          slotscrm: false,
          casinocrm: false,
        };
      }
      this.createAlertConfirm();
    });
  }
}
