import { Injectable } from "@angular/core";
import * as crypto from "crypto-js";

@Injectable({ providedIn: "root" })
export class EncrypteService {
  private readonly sal: string = "*+C0D3R3-2023+*";

  encrypt(text: string): string {
    return crypto.AES.encrypt(text, this.sal).toString();
  }

  decrypt(cipher: string): Promise<string | null> {
    const bytes = crypto.AES.decrypt(cipher, this.sal);
    const result = bytes.toString(crypto.enc.Utf8);
    return Promise.resolve(result === "" ? null : result);
  }
}
