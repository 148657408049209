export const DOCUMENT_TYPES = {
  dni: 754310000,
  bankReceipt: 754310001,
  others: 754310002,
  veridas: 754310003,
  verificationList: 754310004,
  selfie: 754310005,
  bankCard: 754310006,
  paypal: 754310007,
};

export const DOCUMENT_SIDES = {
  front: 754310000,
  back: 754310001,
};

export const VERIFICATION_STATUS = {
  rejected: 754310000,
  approved: 754310001,
  requested: 754310002,
  pending: 1,
  inactive: 2,
};
