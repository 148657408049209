import { CommonModule } from "@angular/common";
import { Component, Input, OnDestroy, OnInit, inject } from "@angular/core";
import { Router } from "@angular/router";
import { CONSTANTS } from "@constants";
import { IonicModule, NavParams } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { CasinoBalanceDetails } from "@models/csbgonlinecasinobonuses/Balance";
import { TranslateModule } from "@ngx-translate/core";
import { ParseMoney } from "@pipes/parseMoney";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { CASINO_PATHS } from "@shared-constants/routes";
import { Observable } from "rxjs";
import { Subscription } from "rxjs/internal/Subscription";

@Component({
  selector: "balance-detail",
  templateUrl: "./balance-detail.html",
  styleUrls: ["./balance-detail.scss"],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, ParseMoney],
})
export class BalanceDetail implements OnInit, OnDestroy {
  private subscription: Subscription;
  private subscriptionDetails: Subscription;
  @Input() details$: Observable<null | CasinoBalanceDetails>;
  details: null | CasinoBalanceDetails;
  isCasino: boolean = false;
  isMobile: boolean = false;
  isPanama: boolean = false;
  isMultiprovider: boolean = false;
  globalVars!: GlobalVarsModel;
  loading = true;
  lobbyConstants = CONSTANTS;
  deviceService = inject(DeviceService);
  newBaseService = inject(NewBaseService);
  router = inject(Router);

  /**
   * This vie is created from BalancesService.showBalanceDetails, which sends casino balance details in navParams
   * @param navParams
   */

  constructor(navParams: NavParams) {
    this.subscription = this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isMobile = this.deviceService.isMobile();
    this.isCasino = navParams.get("isCasino");
    this.isPanama = navParams.get("isPanama");
    this.isMultiprovider = this.globalVars.FEATURES.Casino_EnabledMultiProviderBonuses;
  }

  ngOnInit(): void {
    this.subscriptionDetails = this.details$?.subscribe((details) => {
      this.details = details;
    });
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscriptionDetails?.unsubscribe();
  }

  async goToLobbyAndCat(provider: any) {
    let params;
    if (provider === "") {
      params = this.isMobile
        ? JSON.parse(this.globalVars.FEATURES.CAS_BonusBalanceBDRedirectMOB)
        : JSON.parse(this.globalVars.FEATURES.CAS_BonusBalanceBDRedirectPC);
    } else if (provider === this.lobbyConstants.playtech) {
      params = this.isMobile
        ? JSON.parse(this.globalVars.FEATURES.CAS_BonusPlayPlaytechMob)
        : JSON.parse(this.globalVars.FEATURES.CAS_BonusPlayPlaytechPc);
    } else {
      params = this.isMobile
        ? JSON.parse(this.globalVars.FEATURES.CAS_BonusPlayHabMob)
        : JSON.parse(this.globalVars.FEATURES.CAS_BonusPlayHabPc);
    }

    this.router.navigate([`/${CASINO_PATHS.BASE}/${CASINO_PATHS.CasinoPage}`], { queryParams: params });
  }
}
