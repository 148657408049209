/* eslint-disable eqeqeq */
import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { IonicModule, ModalController, NavController, NavParams } from "@ionic/angular";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TrackingService } from "@providers/TrackingService";
import { UserService } from "@providers/UserService";
import { NewBaseService } from "@providers/newBase.service";
import { Utils } from "@utils/Utils";

import { CommonModule } from "@angular/common";
import { CashierRequestInfoPageKo } from "@shared-pages/payments/cashier-request-info-ko/cashier-request-info-ko";
import { CashierRequestInfoPageOk } from "@shared-pages/payments/cashier-request-info-ok/cashier-request-info-ok";

@Component({
  selector: "cs-payment-iframe",
  templateUrl: "./cs-payment-iframe.html",
  styleUrls: ["./cs-payment-iframe.scss"],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule],
})
export class CsPaymentIframeComponent {
  @Input() payment: any;
  @Input() depositAmount: any;
  @Input() options: any;
  @Output() requestInfo: EventEmitter<any> = new EventEmitter();

  url: any;
  type: string;
  msg: boolean = false;
  oxxo: boolean = true;
  autoEPG: boolean = false;
  depositListener: any;
  acceptColombiaConditions: boolean = false;
  globalVars: GlobalVarsModel;

  optionsRequestKo: any = {
    error: "",
    type: "deposit",
    title: "depositKOtitle",
    subtitle: "depositKO",
  };

  optionsRequestOk: any = {
    idTransaction: "",
    type: "deposit",
    title: "depositOK",
    subtitle: "tDepOk",
    amount: "",
    isFirstDeposit: "",
    paymentMethod: "",
  };

  optionsRequestPending: any = {
    type: "deposit",
    title: "depositPending",
    subtitle: "tDepPending",
  };

  nav = inject(NavController);
  trackingService = inject(TrackingService);
  navParams = inject(NavParams);
  newBaseService = inject(NewBaseService);
  sanitizer = inject(DomSanitizer);
  userService = inject(UserService);
  utils = inject(Utils);
  translate = inject(TranslateService);
  modalCtrl = inject(ModalController);

  constructor() {
    this.newBaseService.getVars.subscribe((vars: GlobalVarsModel) => {
      this.globalVars = vars;
    });
  }

  ngAfterViewInit() {
    if (this.options?.type === "visa") {
      if (this.payment.acceptColombiaConditions)
        this.acceptColombiaConditions = this.payment.acceptColombiaConditions;
      let notSanitizeUrl =
        this.globalVars.URLBASE + "Payments/GetPaymentCard?amount=" + this.depositAmount + "&v2=true";
      if (!!this.globalVars.UrlTransactionsApiBase && this.globalVars.TRANSAPI_ElectronicCardDeposit) {
        notSanitizeUrl =
          this.globalVars.UrlTransactionsApiBase +
          "Payments/GetPaymentCard?amount=" +
          this.depositAmount +
          "&v2=true" +
          "&acceptColombiaConditions=" +
          this.acceptColombiaConditions;
      }
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(notSanitizeUrl);
    } else {
      this.msg = this.options.msg;
      if (this.options.oxxo != undefined) this.oxxo = this.options.oxxo;
      if (this.options.autoEPG != undefined) this.autoEPG = this.options.autoEPG;
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.options.url);
    }

    this.depositListener = this.listenEvents.bind(this);
    switch (this.type) {
      case "oxxo":
        this.trackingService.trackEvent(["AccesToDepositOxxo", "", "", "", "event"]);
        break;
      case "epg":
        this.trackingService.trackEvent(["AccesToDepositEpg", "", "", "", "event"]);
        break;
      case "paysafe":
        this.trackingService.trackEvent(["AccesToDepositPaySafeCard", "", "", "", "event"]);
        break;
      case "ptot":
        this.trackingService.trackEvent(["AccesToDepositPtot", "", "", "", "event"]);
        break;
      case "visa":
        this.trackingService.trackEvent(["AccesToDepositVisa", "", "", "", "event"]);
        break;
      case "payVCC":
        this.trackingService.trackEvent(["AccesToDepositPayValidadCreditCard", "", "", "", "event"]);
        break;
      case "payVC":
        this.trackingService.trackEvent(["AccesToDepositPayValidadCash", "", "", "", "event"]);
        break;
      default:
        this.trackingService.trackEvent(["AccesToDepositIframe", "", "", "", "event"]);
        break;
    }
    window.addEventListener("message", this.depositListener);
  }

  ngOnDestroy() {
    window.removeEventListener("message", this.depositListener);
  }

  private listenEvents(e: any) {
    if (e.data["page"] == "transactionOK") {
      this.optionsRequestOk.idTransaction = e.data["idTransiction"];
      this.optionsRequestOk.amount = e.data["amount"];
      this.optionsRequestOk.isFirstDeposit = e.data["isFirstDeposit"];
      this.optionsRequestOk.paymentMethod = e.data["paymentMethod"];
      this.showRequestOkInfoModal(this.optionsRequestOk);
      window.removeEventListener("message", this.depositListener);
    }
    if (e.data["page"] == "transactionKO") {
      this.optionsRequestKo.error = e.data["error"];
      //eslint-disable-next-line
      if (e.data.hasOwnProperty("paymentMethod")) {
        this.optionsRequestKo.paymentMethod = e.data["paymentMethod"];
      }
      this.showRequestKoInfoModal(this.optionsRequestKo);
      window.removeEventListener("message", this.depositListener);
    }
    if (e.data["page"] === "transactionPending") {
      this.optionsRequestOk = this.optionsRequestPending;
      this.optionsRequestOk.isDebinPending = true;
      this.optionsRequestOk.paymentMethod = e.data["paymentMethod"];
      this.showRequestOkInfoModal(this.optionsRequestOk);
      window.removeEventListener("message", this.depositListener);
    }
  }

  async showRequestOkInfoModal(paymentArguments: any) {
    const modal = await this.modalCtrl.create({
      cssClass: "is-modal",
      showBackdrop: true,
      component: CashierRequestInfoPageOk,
      componentProps: {
        paymentArguments,
      },
    });
    modal.present();
    modal.onDidDismiss().then(() => {
      this.requestInfo.emit(true);
    });
  }

  async showRequestKoInfoModal(paymentArguments: any) {
    const modal = await this.modalCtrl.create({
      component: CashierRequestInfoPageKo,
      componentProps: {
        paymentArguments,
      },
      cssClass: "is-modal",
    });
    modal.present();
    modal.onDidDismiss().then((_docSection) => {
      this.requestInfo.emit(true);
    });
  }
}
