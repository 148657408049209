import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { IonicModule, ModalController, NavController, NavParams } from "@ionic/angular";
import { C_Message } from "@models/C_Message";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "message-modal-page",
  templateUrl: "./message-modal.html",
  styleUrls: ["./message-modal.scss"],
  imports: [CommonModule, IonicModule, TranslateModule],
})
export class MessageModalPage {
  message: C_Message;

  navCtrl = inject(NavController);
  navParams = inject(NavParams);
  viewCtrl = inject(ModalController);

  constructor() {
    this.message = this.navParams.get("message");
  }

  closeModal() {
    this.viewCtrl.dismiss();
  }
}
