import { Component, OnInit, Renderer2, ViewChild, inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { IonicModule, NavParams } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { FooterService } from "@providers/FooterService";
import { TrackingService } from "@providers/TrackingService";

import { CommonModule } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { CodereFooterComponent } from "@shared-mso-components/footer/codere-footer/codere-footer";
import { UserSectionTitle } from "@shared-mso-components/user-section-title/user-section-title";
import { Utils } from "@utils/Utils";

@Component({
  standalone: true,
  selector: "simulator-page",
  templateUrl: "./simulator-page.html",
  styleUrls: ["./simulator-page.scss"],
  imports: [CommonModule, IonicModule, TranslateModule, CodereFooterComponent, UserSectionTitle],
})
export class SimulatorPage implements OnInit {
  type: string;
  texto: string = "";
  private licenseVars: { [key: string]: string } = null;
  public trackEvent: TrackEvent;
  @ViewChild("iframe") iframe;

  utils = inject(Utils);
  newBaseService = inject(NewBaseService);
  navParams = inject(NavParams);
  translate = inject(TranslateService);
  footerService = inject(FooterService);
  sanitizer = inject(DomSanitizer);
  trackingService = inject(TrackingService);
  device = inject(DeviceService);
  router = inject(Router);
  activatedRouter = inject(ActivatedRoute);
  render = inject(Renderer2);
  scrHelp: any = this.sanitizer.bypassSecurityTrustResourceUrl("");

  constructor() {}
  async ngOnInit() {
    await this.loadConstant();
    this.loadIframe();
  }
  async loadConstant() {
    const { generalVars, licenseVars } = await this.footerService.loadFooter();
    this.licenseVars = licenseVars;
  }

  loadIframe() {
    this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(this.licenseVars["URL_SIMULATOR"]);
    this.texto = this.translate.instant("simulator");
    this.texto = "uPBet";
  }

  helpageload(event) {
    event.target.classList.remove("nodisplay");
  }
  ionViewWillEnter(event) {
    this.render.setStyle(this.iframe.nativeElement, "background", "white");
    this.utils.hideFormAccessoryBar(false);
  }
  ionViewDidLeave() {
    this.utils.hideFormAccessoryBar(true);
  }

  backToHome() {
    this.router.navigate(["/"]);
  }
}
