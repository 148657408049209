import { NgModule } from "@angular/core";
import { DeferLoadDirective } from "./defer-load/defer-load.directive";
import { DisableDirective } from "./disabled/disabled.directive";
import { DraggableDirective } from "./draggable.directive/draggable.directive";
import { Draggable } from "./draggable/draggable";
import { ExternalLinkDirective } from "./external-link/external-link";
import { HoverCard } from "./hover-card.directive/hover-card.directive";
import { OnlyNumbersDirective } from "./only-numbers/only-numbers";
import { PanHorizontalDirective } from "./pan-horizontal/pan-horizontal";

@NgModule({
  declarations: [
    Draggable,
    DraggableDirective,
    HoverCard,
    PanHorizontalDirective,
    // SubMenuDirective,
    // TrackEvent,
    ExternalLinkDirective,
    OnlyNumbersDirective,
    DisableDirective,
    // LinkRelDirective
  ],
  imports: [DeferLoadDirective],
  exports: [
    Draggable,
    DraggableDirective,
    HoverCard,
    PanHorizontalDirective,
    // SubMenuDirective,
    // TrackEvent,
    ExternalLinkDirective,
    DeferLoadDirective,
    OnlyNumbersDirective,
    DisableDirective,
    // LinkRelDirective
  ],
})
export class DirectivesModule {}
