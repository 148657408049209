export class SixPack {
  static sports: any = {
    basketball: ["all"],
    ice_hockey: ["us"],
    american_football: ["all"],
    baseball: ["all"],
  };

  static validate(sportHandle: string, countryCode: string): boolean {
    if (!countryCode) return;
    const sport = SixPack.sports[sportHandle];
    if (!sport) return;
    const country = sport.indexOf(countryCode.toLowerCase()) !== -1 || sport[0] === "all";
    return Boolean(sport) && country;
  }
}
