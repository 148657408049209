export class Constants {
  /**
   * SPORT URLS
   */
  // Config
  static getHighlightsConfig = "HighlightsLeftMenu/GetHighlightsConfig";
  static betsConfigData = "Configfile/GetBetsConfigData";
  static getMarquee = "Marquee/GetMarquee";
  static getGetBetBuilderEvents = "Marquee/GetBetBuilderEvents";

  // Home
  static getCategoriesByLeagueUrl = "Home/GetCategoriesByLeague";
  static getCompleteRaceUrl = "Home/GetCompleteRace";
  static getCountriesByDateUrl = "Home/GetCountriesByDate";
  static getCountriesUrl = "Home/GetCountries";
  static getEventsByDateUrl = "Home/GetEventsByDate";
  static getEventsUrl = "Home/GetEvents";
  static getHighlightsEventsUrl = "NoSessionTimeout/GetHighlightsEvents";
  static getHighlightsUrl = "Home/Gethighlights";
  static getHiglightEventsBySportHandleUrl = "Home/GetHiglightEventsBySportHandle";
  static getHomeLiveEvents = "NoSessionTimeout/GetHomeLiveEvents";
  static getLiveEventsBySportHandleUrl = "Home/GetLiveEventsBySportHandle";
  static getLiveEventsAndSportsBySportHandle = "Home/GetLiveEventsAndSportsBySportHandle";
  static getNextRacesUrl = "Home/GetNextRaces";
  static getRacesByPaddockUrl = "Home/GetRacesbyPaddock";
  static getSportBySportHandle = "Home/GetSportBySportHandle";
  static getSportUrl = "Home/GetSports";
  static betbuilderAddItemUrl = "MatchAcca/GetMatchAccaResponse?";
  static getBetbuilderResposeByBetBuilderIdUrl = "MatchAcca/GetMatchaccaResposeByBetBuilderId?betBuilderId=";
  static updateOddsUrl = "Home/Update";
  static getMenuLeftUrl = "LeftMenu/GetMenuLeft";
  static getSubmenuLeftUrl = "LeftMenu/GetSubmenuLeft";

  // PAGES URL
  static getHomeInfo = "Pages/GetHomeInfo";
  static getRaceInfo = "Pages/GetRaceInfo";
  static getCountriesInfo = "Pages/GetCountriesInfo";

  // GAMES URL
  static getCategoryNoLiveInfosUrl = "Game/GetCategoryNoLiveInfos";
  static getGamesNoLiveByCategoryInfoUrl = "Game/GetGamesNoLiveByCategoryInfo";
  static getGamesNoLiveAndCategoryInfosUrl = "Game/GetGamesNoLiveAndCategoryInfos";
  static getLastMinute = "home/GetLastMinute";
  static JGetNode = "home/JGetNode";
  static geHomeMainLeague = "NoSessionTimeout/GeHomeMainLeague";
  static getGamesNoLiveUrl = "Home/GetGamesNoLive";

  static getGamesLiveAndCategoryInfosUrl = "Game/GetGamesLiveAndCategoryInfos";
  static getGamesLiveUrl = "Game/GetGamesLive";
  static getGamesLiveByCategoryInfoUrl = "Game/GetGamesLiveByCategoryInfo";

  // SEARCH URLS
  static getSearchUrl = "Home/FreeTextSearch";
  static getCustomQueryUrl = "Home/CustomQuerySearch";

  // BETSLIP URLS
  static addSingleItemUrl = "item/singleResult";
  static addSmartMarketItemUrl = "item/smartMarket";
  static stakeUrl = "staking/";
  static removeItemUrl = "item/";
  static removeTicket = "betslip/clear";
  static setOdds = "item/singleResult/odds";
  static straightMulticast = "item/straightMulticast";
  static combinationMulticast = "item/combinationMulticast";
  static smartMarketUrl = "item/smartMarket";
  static freeBetAdd = "bonus/voucher";
  static freeBetGetInfo = "FreebetBonusEs/GetVoucherInfo";
  static freeBetCancel = "bonus/voucher";
  static freeBetValidate = "bonus/validate";

  // SPORTS MISC

  // SPORTS MISC TO MONOLITH
  static getTicketInfoMonolith = "orderform/GetTicketInfo";
  static GetCurrentSessionStartMonolith = "/account/GetCurrentSessionStart";
  static CustomizationAddParameter = "Customization/SetAutoAcceptOddConfig";
  // NOTE: In monolith we make two calls to endpoint AddParameter. In SportsMisc, only one call to endpoint will set both parameters
  static CustomizationGetParameters = "Customization/GetParameters";
  //! Not in use
  static AccountCheckUserLogged = "Account/CheckUserLogged";
  //! Not in use
  static AccountGetCurrentSessionStart = "Account/GetCurrentSessionStart";
  //! Not in use
  static AccountGetCurrentUser = "Account/GetCurrentUser";
  static CodereCardAddClientCard = "Card/AddClientCard";
  static CodereCardAddNewCardToClient = "Card/AddNewCardToClient";
  static CodereCardCreateCardRequest = "Card/CreateCardRequest";
  static CodereCardGetClientCardBalance = "Card/GetClientCardBalance";
  static CodereCardCardRequestExists = "Card/CardRequestExists";
  static CodereCardCardStatus = "Card/CardStatus";
  static CodereCardCheckCardLocked = "Card/CheckCardLocked";
  static CodereCardDocAndResidentNotAllowed = "Card/DocAndResidentNotAllowed";
  static CodereCardGetAllowedRegions = "Card/GetAllowedRegions";
  static CodereCardGetCardHistory = "Card/GetCardHistory";
  static CodereCardGetUserCardNumber = "Card/GetUserCardNumber";
  static CodereCardHasCardNif = "Card/HasCardNif";
  static CodereCardReuseCard = "Card/ReuseCard";
  static OrderFormGetTicketInfoCard = "Card/GetTicketInfo";
  static CodereCardSetNewPin = "Card/SetNewPin";
  static CodereCardUnlockedCard = "Card/UnlockedCard";
  //! Not in use
  static CodereCardUpdateCard = "Card/UpdateCard";
  static CodereCardValidatePasswordAndCard = "Card/ValidatePasswordAndCard";

  //? flag SPO_CodereCardPayout_SportsMisc
  static OrderFormPayOutBetTicketCard = "Card/PayOutBetTicket";
  static OrderFormPayOutBetTicketTicket = "Ticket/PayOutBetTicket";

  // HORSE RACE
  static silksURL = "https://silks.termct.com/RASSilks/";
  static getNextRaces = "home/getNextRaces";
  static getRacesbyPaddock = "home/getRacesbyPaddock";
  static getRacesInfo = "Pages/GetRaceInfo";

  // PAIS LIGA PAGE
  static getPaisLigaInfoUrl = "Pages/GetCountriesAndHighlights";

  // STREAMING
  static getStreamingURL = "api/Streaming/GetStreamingURL";
  static getXbNetStreamingURL = "api/Streaming/GetXbNetStreamingURL";
  static getBetRadarStreamingInfo = "api/Streaming/GetBetRadarStreamingInfo";
  static getSMSStreamingURL = "api/Streaming/GetSMSStreamingURL";
  static getIMGStreamingURL = "api/Streaming/GetIMGStreamingURL";
  static getStreamingIdWatchBetToken = "api/Streaming/GetStreamingIdWatchBetToken";
  static getSISStreamingURL = "api/Streaming/GetSISStreamingURL";
  static getGeniusStreamingURL = "api/Streaming/GetGeniusStreamingURL";
  static IMGStreamUrl = "/api/v2/streaming/events/";

  // SportHandle

  // Category Id
  static priorityForCat = "Priority";
  static goleadoresCat = "81";
  static specialBetCat = "90";
  static principalesCat = "99";
  static matchaccaCatId = "111";
  static matchaccaCatName = "Matchacca";
  static betbuilderCatId = "111";
  static betbuilderCatName = "Matchacca";
  static betbuilderName = "betbuilder";

  // Tournaments IDs
  static UEFAEuroNodeId = "8338719747";
  static CopaAmericaNodeId = "8374076327";
  static MundialNodeId = "5108021769";

  // Tournaments literals
  static UEFAEuroGamesLiteral = "Partidos - UEFA Euro 2024";
  static UEFAEuroPageLiteral = "Pagina de eventos de Eurocopa 2024";
  static AccessToUEFAEuroEventPageLiteral = "AccessToEuroCupEventPage";
  static CopaAmerica2021Literal = "Copa América 2021";
  static PaginaDeEventosDeCopaAmericaLiteral = "Pagina de eventos de Copa America";
  static AccessToCopaAmericaEventPageLiteral = "AccessToCopaAmericaEventPage";
  static tournamentLiteral = "tournament";

  // Unique Tournaments IDs
  static EurocopaUniqueTournamentId = 1;
  static CopaAmericaUniqueTournamentId = 133;
  static MundialUniqueTournamentId = 16;

  // New sports navigation
  static CreaTuApuestaPageLink = "Crea-Tu-Apuesta";

  // Sport categories
  static OtrasApuestasCategoryId = 0;
  static FastbetCategoryId = 2;
  static PrincipalesCategoryId = 99;
  static NineHundredNinetyNineCategoryId = 999;
  static BetbuilderCategoryId = 111;
  static CombinadosCategoryId = 83;
  static GolesCategoryId = 82;
  static ResultadoFinalCategoryId = 75;
  static PrimeraYSegundaParteCategoryId = 60;
  static HandicapCategoryId = 50;
  static EquiposCategoryId = 40;
  static ProximosMinutosCategoryId = 35;
  static ApuestasCombinadasCategoryId = 90;
  static PrimerEquipoEnMarcarXPuntosCategoryId = 85;
  static ApuestasPrimeraMitadCategoryId = 80;
  static ApuestasPrimerCuartoCategoryId = 75;
  static GanadorSinEmpateCategoryId = 97;
  static TotalDeAsaltosCategoryId = 448;

  // License Meta tags language
  static licenseEspañaLanguage = "es";
  static licenseMexicoLanguage = "es-mx";
  static licenseColombiaLanguage = "es-co";
  static licensePanamaLanguage = "es-pa";
  static licenseArgentinaCabaLanguage = "es-ar";
  static licenseArgentinaPBALanguage = "es-ar";
  static licenseArgentinaCordobaLanguage = "es-ar";
  static licenseArgentinaMendozaLanguage = "es-ar";

  static getCodereFantasyCode = "/HeroPicks/GetUserCode";
  static getDgaFantasyDev = "https://codere.dgafantasy-dev.com/league?operatorKey=";
  static getDgaFantasy = "https://codere.dgafantasy.com/league?operatorKey=";

  /**
   * Literals
   */
  static today = "today";
  static tomorrow = "tomorrow";
  static SPO_BetbuilderMessage2 = "Necesitas al menos 2 selecciones.";
  static SPO_BetbuilderMessage1 = "Añadir al menos 1 o más selecciones.";
  static SPO_BetbuilderMessage = "Apostar o añadir más selecciones";
  static SPO_BetbuilderMessageError =
    "Combinación no válida. Algunas de tus selecciones no son combinables. Por favor, cámbialas para continuar.";

  /**
   * Component Color modes
   */
  static compoModeGrey = "grey";

  /**
   * MSO
   */

  //Icons new Rebranding
  static MSO_Page_Icons = {
    sport: "assets/icon/icon.png",
    casino: "assets/icon/icon-casino.png",
  };

  static Max_GraceTime = 7;
  static UserLoggedSS = "isUserLogged";
  static PagesActivesLS = "pagesActives";

  static VipEstado = {
    YES: "yes",
    NO: "no",
    HIGHTROLLER: "highroller",
  };

  static qaServers = [
    "qm.codere.com.ar",
    "qm.apuestas.codere.es",
    "dm.apuestas.codere.es",
    "q.mx.bet.cdrsbg.com",
    "qm.codere.pa",
    "dm.apuestas.codere.es",
    "localhost",
    "localhost:4200",
    "qpba.codere.com.ar",
    "qm.mdz.codere.bet.ar",
  ];
}
//eslint-disable-next-line
export enum CapacitorGetPlatform {
  IOS = "ios",
  ANDROID = "android",
  WEB = "web",
}
//eslint-disable-next-line
export enum SourcesCDR {
  Callmeback = 754310003,
  NativeApp = 754310001,
  Till = 754310002,
  WebApp = 754310000,
}
