/* eslint-disable */
import { Injectable, inject } from "@angular/core";
import { UserService } from "@shared-providers/UserService";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class XStateService {
  xState$ = new BehaviorSubject<string>("");
  xStateAuthorizationHeader$ = new BehaviorSubject<string>("");

  userService = inject(UserService);
  constructor() {}

  startBetslip() {
    this.loadAuthorization();
    this.loadInitialXState();
  }

  loadInitialXState() {
    const XState = localStorage.getItem("X-State");
    if (!XState) {
      this.userService.loadInitialXState().subscribe((resp) => {
        this.saveXState(resp);
      });
    } else {
      this.saveXState(XState);
    }
  }

  saveXState(state) {
    if (state !== null && state !== "") {
      this.xState$.next(state);
      localStorage.setItem("X-State", state);
    }
  }

  removeXState() {
    localStorage.removeItem("X-State");
  }

  removeXTicketState() {
    localStorage.removeItem("X-Ticket-State");
  }

  restartXState() {
    this.removeXState();
    this.loadInitialXState();
  }

  restartAll() {
    this.removeXTicketState();
    this.restartXState();
  }

  getXState() {
    return this.xState$.value;
  }

  loadAuthorization() {
    const XStateAuthorization = localStorage.getItem("X-State-Authorization");
    if (!XStateAuthorization) {
      this.userService.loadFreeBetsAuthorization().subscribe((resp) => {
        this.saveAuthorization(resp);
      });
    } else {
      this.saveAuthorization(XStateAuthorization);
    }
  }

  saveAuthorization(state) {
    if (state !== null && state !== "") {
      this.xStateAuthorizationHeader$.next(state);
      localStorage.setItem("X-State-Authorization", state);
    }
  }

  removeAuthorization() {
    localStorage.removeItem("X-State-Authorization");
  }

  getAuthorization() {
    return this.xStateAuthorizationHeader$.value;
  }
}
