// models/C_FreeBetTicket.ts

export class C_FreeBetsActive {
  data: Array<C_FreeBetTicket>;
  availableDepositFbs: any;

  constructor(lstFreebets: Array<C_FreeBetTicket>, AvailableDepositFbs: any) {}
}

export class C_FreeBetTicket {
  Conditions: Array<FreeBetCondition>;
  BonusOfferId: number;
  VoucherCode: string;
  Amount: number;
  ExpirationDate: string;
  IsRedeemable: boolean;
  Name: string;
  IsTerminalVoucher: boolean;
  PBSVoucherInfo: PBSVoucherInfo;
  HasFreeBet?: boolean;
  constructor(
    conditions: Array<FreeBetCondition>,
    bonusOfferId: number,
    voucherCode: string,
    amount: number,
    expirationDate: string,
    isReddemable: boolean,
    name: string,
    isTerminalVoucher: boolean,
  ) {
    this.Conditions = conditions;
    this.BonusOfferId = bonusOfferId;
    this.VoucherCode = voucherCode;
    this.Amount = amount;
    this.ExpirationDate = expirationDate;
    this.IsRedeemable = isReddemable;
    this.Name = name;
    this.IsTerminalVoucher = isTerminalVoucher;
  }
}

export class FreeBetCondition {
  Condition: FreebetConditionType;
  ConditionValueInt: number;
  ConditionValueDecimal: number;

  constructor(condition: FreebetConditionType, conditionValueInt: number, conditionValueDecimal: number) {
    this.Condition = condition;
    this.ConditionValueInt = conditionValueInt;
    this.ConditionValueDecimal = conditionValueDecimal;
  }
}

export class FreebetConditionType {
  MaxOdds: number = 0;
  MinOdds: number = 1;
  MinLegs: number = 2;

  constructor() {}
}

//export { C_FreeBetsActive, C_FreeBetTicket, FreeBetCondition, FreebetConditionType}
export class C_FreeBetVoucher {
  Voucher: FreeBetBettingVoucher;

  constructor() {}

  static mapFBVoucher(voucherInfo) {
    const freeBetVoucher = new C_FreeBetVoucher();
    freeBetVoucher.Voucher = new FreeBetBettingVoucher();
    freeBetVoucher.Voucher.VoucherCode = voucherInfo.VoucherCode;
    freeBetVoucher.Voucher.VoucherAmountCt = voucherInfo.Amount;
    freeBetVoucher.Voucher.CurrencyISOCode = voucherInfo.CurrencyISOCode;
    freeBetVoucher.Voucher.GeneralConditions = voucherInfo.GeneralConditions;
    freeBetVoucher.Voucher.BonusOfferId = voucherInfo.BonusOfferId;
    freeBetVoucher.Voucher.BettingRestrictions = new FreeBetBettingRestrictions();
    freeBetVoucher.Voucher.BettingRestrictions.RestrictionsByCutPoint =
      voucherInfo.BettingRestrictions.RestrictionsByCutPoint;
    return freeBetVoucher;
  }
}

export class FreeBetBettingVoucher {
  VoucherCode: string;
  VoucherAmountCt: number;
  BettingRestrictions: {
    RestrictionsByCutPoint: any;
    // RestrictionsByCutPoint: {}
  };
  CurrencyISOCode: string;
  GeneralConditions: any;
  // GeneralConditions: {};
  BonusOfferId: number;
}

export class FreeBetBettingRestrictions {
  RestrictionsByCutPoint: any;
  // RestrictionsByCutPoint: {};
}

export class FreeBetAdditionalProp {
  Operator: string;
  Restrictions: FreeBetAdditionalPropRestrictions[];
  Children: [null];
}

export class FreeBetAdditionalPropRestrictions {
  Description: string;
  Name: string;
  Key: string;
  Operator: string;
  Value: string;
  ValueType: number;
  GameType: number;
  EventNodeInfoKey: string;
  NodeInfoNodeType: number;
  EventNodeInfoValueType: number;
  ParentEventNodeId: number;
}

export interface PBSVoucherInfo {
  AdditionalInfos: any;
  // AdditionalInfos: {};
  Amount: number;
  BettingRestrictions: FreeBetBettingRestrictions;
  BonusOfferId: number;
  CurrencyISOCode: string;
  GeneralConditions: any;
  // GeneralConditions: {};
  IsPrinted: boolean;
  Status: number;
  UserNodesNr: number;
  VoucherCode: string;
  VoucherHistory: Array<any>;
  VoucherStatus: number;
}
