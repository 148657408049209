import { LicenseTypes } from "@models/MasterData";

const soliticsconfig = [];

soliticsconfig[LicenseTypes.Nacional] = {
  insights: {
    solitics: {
      enabled: true,
      token: "DaT878e6Gy2BqCN8FHtHDrb3",
      lib: {
        src: "https://d2afn796dyftlg.cloudfront.net/oapit.min.js",
        autoLoad: true,
      },
    },
  },
};
soliticsconfig[LicenseTypes.Colombia] = {
  insights: {
    solitics: {
      enabled: true,
      token: "KDaBNK4QCMdMvUhQJB59v9Ld",
      lib: {
        src: "https://d2afn796dyftlg.cloudfront.net/oapit.min.js",
        autoLoad: true,
      },
    },
  },
};
soliticsconfig[LicenseTypes.Panama] = {
  insights: {
    solitics: {
      enabled: true,
      token: "ByYjeArKCgMb7PBhnaCaB49d",
      lib: {
        src: "https://d2afn796dyftlg.cloudfront.net/oapit.min.js",
        autoLoad: true,
      },
    },
  },
};
soliticsconfig[LicenseTypes.ArgentinaCaba] = {
  insights: {
    solitics: {
      enabled: true,
      token: "",
      lib: {
        src: "",
        autoLoad: true,
      },
    },
  },
};
soliticsconfig[LicenseTypes.ArgentinaPBA] = {
  insights: {
    solitics: {
      enabled: true,
      token: "",
      lib: {
        src: "",
        autoLoad: true,
      },
    },
  },
};
soliticsconfig[LicenseTypes.ArgentinaMendoza] = {
  insights: {
    solitics: {
      enabled: true,
      token: "",
      lib: {
        src: "",
        autoLoad: true,
      },
    },
  },
};
soliticsconfig[LicenseTypes.ArgentinaCordoba] = {
  insights: {
    solitics: {
      enabled: true,
      token: "",
      lib: {
        src: "",
        autoLoad: true,
      },
    },
  },
};

export default soliticsconfig;
