<ng-container *ngIf="!isMobile; else mobileView">
  <ion-content>
    <ng-container
      *ngIf="{ cat: eventMarkesCategories$ | async, events: games$ | async, markets: markets$ | async } as obs"
    >
      <div class="sb-sticky" *ngIf="!showTournament() else showWidget">
        <sb-navbar theme="sportbook" class="sb-navbar--sub-page" [hasBackButton]="true" [fakeButton]="!canGoBack">
          <div>
            <h4 class="sb-back--title color-light">
              {{ sportsbookFacade?.selectedEvent?.SportName }} / {{ sportsbookFacade?.selectedEvent?.Name }}
            </h4>
          </div>
        </sb-navbar>
        <sb-tabs
          id="filter-1"
          *ngIf="eventMainCategories?.length > 1"
          [categories]="eventMainCategories"
          [color]="'dark'"
          [selectedTab]="selectedEventMainCategory"
          (selectTab)="changeMainCategory($event)"
        ></sb-tabs>
        <sb-tabs-categories
          id="filter-2"
          *ngIf="obs?.cat?.length > 1"
          [categories]="obs?.cat"
          [color]="'regular'"
          [scrollable]="true"
          [smallButton]="true"
          [isMobile]="isMobile"
          [selectedTab]="selectedMarketsCategory"
          (selectTab)="changeCategory($event)"
        ></sb-tabs-categories>
        <sb-filter
          id="filter-3"
          [hidden]="!showingMoreBets || hideCategories"
          [filters]="betsCategories"
          [selected]="currentAddedCategory$ | async"
          (filter)="changeCategory($event)"
          (resize)="resize()"
        >
        </sb-filter>
      </div>

      <ng-template #showWidget>
        <div class="sb-sticky">
          <sb-navbar theme="sportbook" class="sb-navbar--sub-page" [hasBackButton]="true">
            <h4 class="sb-back--title color-light">
              {{ sportsbookFacade?.selectedEvent?.SportName }} / {{ sportsbookFacade?.selectedEvent?.Name }}
            </h4>
          </sb-navbar>
        </div>
        <div class="widgets" *ngIf="showTournament()">
          <div class="sr-widget sr-widget-22" #standardWidgetSIR></div>
        </div>
        <div class="sb-sticky sb-sticky-widget">
          <sb-filter
            id="filter-4"
            *ngIf="hasMoreBets"
            [mode]="'dark'"
            [selected]="currentBetsFilter$ | async"
            [filters]="betsFilters$ | async"
            [switch]="true"
            [colorsOptions]="{background: 'background-color-dark', line: 'background-color-primary'}"
            (filter)="changeMainCategory($event)"
          >
          </sb-filter>
          <sb-filter
            id="filter-5"
            [hidden]="showingMoreBets"
            [filters]="obs?.cat"
            [selected]="currentCategory$ | async"
            (filter)="changeCategory($event)"
            (resize)="resize()"
          >
          </sb-filter>
          <sb-filter
            id="filter-6"
            [hidden]="!showingMoreBets || hideCategories"
            [filters]="betsCategories"
            [selected]="currentAddedCategory$ | async"
            (filter)="changeCategory($event)"
            (resize)="resize()"
          >
          </sb-filter>
        </div>
      </ng-template>

      <div [hidden]="showingMoreBets" class="sb-grid-container" *ngIf="obs?.cat">
        <ion-list *ngIf="obs?.events as events">
          <div class="sb-empty-state color-grey-900" *ngIf="events?.length <= 0">
            <span>No hay apuestas en este momento, inténtalo más tarde</span>
          </div>
          <sb-grid-item
            id="1"
            *ngFor="let event of events"
            [event]="event"
            [mode]="event?.mode"
            [special]="sportBookService?.isSpecialMarket(event?.LeagueName)"
            (navigate)="navigate(event)"
            [currentPage]="'event-page'"
          >
            <ng-container
              *ngTemplateOutlet="event?.mode === 'teams' ? teams : expanded; context: { event: event }"
              content
            >
            </ng-container>
            <sb-overview-score-sets
              *ngIf="event?.overviewScoreboard === 'sets'"
              [sportHandle]="event?.SportHandle"
              [scoreboard]="event?.liveData"
              scoreboard
            >
            </sb-overview-score-sets>
            <sb-overview-score-regular
              *ngIf="event?.overviewScoreboard === 'regular'"
              [scoreboard]="event?.liveData"
              scoreboard
            >
            </sb-overview-score-regular>
            <div
              class="sb-grid-item--bets-group"
              [ngClass]="'has-' + event?.Games?.length + '-groups'"
              [ngStyle]="{display: event?.SportHandle == 'motorsport' ? 'none': '' }"
              [class.has-callback]="event?.Games?.length % 2 !== 0"
              *ngFor="let game of event?.Games; let index = index"
              bet-display
              [game]="game"
              [desktop]="!isMobile"
              [betType]="game?.DisplayTypeName"
              sb-markets
            >
              <sb-button
                *ngFor="let result of game?.Results;let index = index;trackBy: utils?.trackBy"
                [type]="game?.GameType"
                [typeName]="game?.DisplayTypeName"
                [game]="result"
                [results]="game?.Results"
                [index]="index"
                (bet)="bet($event, event, game?.Name, '1')"
                [selected]="newTicketFacade.hasResult(result.NodeId)"
                [locked]="result?.Locked"
                [home]="event?.teamHome"
                [away]="event?.teamAway"
                [sport]="event?.SportHandle"
                [sportHandler]="league?.SportHandle"
              >
              </sb-button>
            </div>

            <span
              *ngIf="!!event?.ChildrenCount && event?.mode !== 'expanded'"
              class="sb-grid-header--bets-counter color-secondary"
              counter-button
              (click)="navigate(event)"
              tappable
            >
              +{{ event?.ChildrenCount }}
              <span class="codere-icon icon-arrow-right"></span>
            </span>
          </sb-grid-item>
        </ion-list>
      </div>

      <div [hidden]="!showingMoreBets" class="sb-grid-container">
        <div class="sb-empty-state text-left color-grey-900" *ngIf="obs?.markets?.length == 0">
          <span>La oferta no está disponible en este momento</span>
        </div>
        <ion-grid *ngIf="obs?.markets as markets" class="sb-grid-desktop">
          <ion-row>
            <ion-col class="sb-dropdown--container" col-6>
              <ng-container *ngFor="let marketBet of markets; let index = index; let even = even; let odd = odd">
                <sb-dropdown
                  *ngIf="even"
                  [title]="marketBet?.Name"
                  [contentType]="marketBet?.DisplayTypeName"
                  [index]="-1"
                  [mode]="marketDesktopMode"
                >
                  <sb-grid-header
                    *ngIf="marketBet?.isCombined || marketBet?.isHandicap"
                    [titleLeft]="marketBet?.teamHome"
                    [titleCenter]="marketBet?.titleCenter"
                    [titleRight]="marketBet?.teamAway"
                    [market]="marketBet"
                  >
                  </sb-grid-header>

                  <sb-grid-item
                    id="2"
                    *ngIf="!marketBet?.isCombined || marketBet?.isHandicap"
                    [mode]="marketBet?.displayMode"
                    [event]="marketBet"
                    (navigate)="navigate(true, marketBet)"
                    [betType]="marketBet?.DisplayTypeName"
                    [currentPage]="'event-page'"
                  >
                    <div
                      *ngIf="gametypes.indexOf(marketBet?.GameType) == -1"
                      class="sb-grid-item--bets-group"
                      bet-display
                      sb-markets
                      [betType]="marketBet?.DisplayTypeName"
                    >
                      <sb-button
                        *ngFor="let result of marketBet?.GroupResults; let index = index"
                        [type]="marketBet?.GameType"
                        [typeName]="marketBet?.DisplayTypeName"
                        [game]="result"
                        [isCombined]="marketBet?.isCombined"
                        [results]="marketBet?.GroupResults"
                        [locked]="result?.Locked"
                        [index]="index"
                        (bet)="bet($event, marketBet, marketBet?.Name, '2')"
                        [selected]="newTicketFacade?.hasResult(result?.NodeId)"
                        mode="expanded"
                        [sport]="market?.SportHandle"
                        [sportHandler]="league?.SportHandle"
                        bets
                      >
                      </sb-button>
                    </div>

                    <div
                      *ngIf="gametypes.indexOf(marketBet?.GameType) != -1"
                      class="sb-grid-item--bets-group"
                      [betType]="marketBet?.DisplayTypeName"
                      bet-display
                      sb-markets
                    >
                      <div class="sb-grid-item--column is-final-score">
                        <sb-button
                          *ngFor="let result of marketBet?.GroupResults[0]; let index = index"
                          [type]="marketBet?.GameType"
                          [typeName]="marketBet?.DisplayTypeName"
                          [game]="result"
                          [isCombined]="marketBet?.isCombined"
                          [results]="marketBet?.GroupResults"
                          [locked]="result?.Locked"
                          [index]="index"
                          (bet)="bet($event, marketBet, marketBet?.Name, '3')"
                          [selected]="newTicketFacade?.hasResult(result.NodeId)"
                          mode="expanded"
                          [sport]="market?.SportHandle"
                          [sportHandler]="league?.SportHandle"
                          bets
                        >
                        </sb-button>
                      </div>

                      <div class="sb-grid-item--column is-final-score">
                        <sb-button
                          *ngFor="let result of marketBet?.GroupResults[1]; let index = index"
                          [type]="marketBet?.GameType"
                          [typeName]="marketBet?.DisplayTypeName"
                          [game]="result"
                          [isCombined]="marketBet?.isCombined"
                          [results]="marketBet?.GroupResults"
                          [locked]="result?.Locked"
                          [index]="index"
                          (bet)="bet($event, marketBet, marketBet?.Name, '4')"
                          [selected]="newTicketFacade?.hasResult(result.NodeId)"
                          mode="expanded"
                          [sport]="market?.SportHandle"
                          [sportHandler]="league?.SportHandle"
                          bets
                        >
                        </sb-button>
                      </div>

                      <div class="sb-grid-item--column is-final-score">
                        <sb-button
                          *ngFor="let result of marketBet?.GroupResults[2]; let index = index"
                          [type]="marketBet?.GameType"
                          [typeName]="marketBet?.DisplayTypeName"
                          [game]="result"
                          [isCombined]="marketBet?.isCombined"
                          [results]="marketBet?.GroupResults"
                          [locked]="result?.Locked"
                          [index]="index"
                          (bet)="bet($event, marketBet, marketBet?.Name, '5')"
                          [selected]="newTicketFacade?.hasResult(result.NodeId)"
                          mode="expanded"
                          [sport]="market?.SportHandle"
                          [sportHandler]="league?.SportHandle"
                          bets
                        >
                        </sb-button>
                      </div>
                    </div>
                  </sb-grid-item>

                  <!-- COMBINED BETS HARD-->
                  <span *ngFor="let market of marketBet?.combinedResults; let indexCombinedResults = index">
                    <sb-grid-item
                      id="3"
                      *ngIf="marketBet?.isHardCombined"
                      [mode]="marketBet?.displayMode"
                      [event]="marketBet"
                      [betType]="marketBet?.DisplayTypeName"
                      [currentPage]="'event-page'"
                    >
                      <sb-grid-content-single *ngIf="marketBet?.isCombined" [title]="market?.spov[0]" content>
                      </sb-grid-content-single>
                      <div
                        class="sb-grid-item--bets-group"
                        bet-display
                        sb-markets
                        [betType]="marketBet?.DisplayTypeName"
                      >
                        <sb-button
                          *ngFor="let result of (market?.results)[0]"
                          [type]="marketBet?.DisplayTypeName"
                          [game]="result"
                          [isCombined]="marketBet?.isCombined"
                          [results]=""
                          (bet)="bet($event, marketBet, marketBet?.Name, '6')"
                          [selected]="newTicketFacade?.hasResult(result?.NodeId)"
                          [locked]="result?.Locked"
                          mode="expanded"
                          [sport]="market?.SportHandle"
                          [sportHandler]="league?.SportHandle"
                          bets
                        >
                        </sb-button>
                      </div>
                    </sb-grid-item>
                    <sb-grid-item
                      id="4"
                      *ngIf="marketBet?.isHardCombined"
                      [mode]="marketBet?.displayMode"
                      [event]="marketBet"
                      [betType]="marketBet?.DisplayTypeName"
                      [currentPage]="'event-page'"
                    >
                      <sb-grid-content-single *ngIf="marketBet?.isCombined" [title]="market?.spov[1]" content>
                      </sb-grid-content-single>

                      <div
                        class="sb-grid-item--bets-group"
                        bet-display
                        sb-markets
                        [betType]="marketBet?.DisplayTypeName"
                      >
                        <sb-button
                          *ngFor="let result of (market?.results)[1]"
                          [type]="marketBet?.DisplayTypeName"
                          [game]="result"
                          [isCombined]="marketBet?.isCombined"
                          [results]=""
                          [locked]="result?.Locked"
                          (bet)="bet($event, marketBet, marketBet?.Name, '7')"
                          [selected]="newTicketFacade?.hasResult(result?.NodeId)"
                          mode="expanded"
                          [sport]="market?.SportHandle"
                          [sportHandler]="league?.SportHandle"
                          bets
                        >
                        </sb-button>
                      </div>
                    </sb-grid-item>

                    <!-- COMBINED BETS  EASY-->
                    <sb-grid-item
                      id="5"
                      *ngIf="marketBet?.isSoftCombined"
                      [mode]="marketBet?.displayMode"
                      [event]="marketBet"
                      [betType]="marketBet?.DisplayTypeName"
                      [currentPage]="'event-page'"
                    >
                      <sb-grid-content-single [title]="market?.spov" content> </sb-grid-content-single>

                      <div
                        class="sb-grid-item--bets-group"
                        bet-display
                        sb-markets
                        [betType]="marketBet?.DisplayTypeName"
                      >
                        <sb-button
                          *ngFor="let result of market?.results"
                          [type]="marketBet?.DisplayTypeName"
                          [game]="result"
                          [isCombined]="marketBet?.isCombined"
                          [results]=""
                          (bet)="bet($event, marketBet, marketBet?.Name, '8')"
                          [selected]="newTicketFacade?.hasResult(result?.NodeId)"
                          [locked]="result?.Locked"
                          mode="expanded"
                          [sport]="market?.SportHandle"
                          [sportHandler]="league?.SportHandle"
                          bets
                        >
                        </sb-button>
                      </div>
                    </sb-grid-item>
                    <!--SIX PACK GRID Start-->
                    <sb-grid-item
                      id="6"
                      *ngIf="marketBet?.isSixPack"
                      [mode]="marketBet?.displayMode"
                      [event]="marketBet"
                      [betType]="marketBet?.DisplayTypeName"
                      [currentPage]="'event-page'"
                    >
                      <sb-grid-content-single *ngIf="marketBet?.isCombined" [title]="market?.spov" content>
                      </sb-grid-content-single>

                      <sb-button
                        *ngFor="let result of market?.results; let indexCombinedBets = index"
                        [type]="marketBet?.DisplayTypeName"
                        [game]="result"
                        [locked]="result?.Locked"
                        [isCombined]="marketBet?.isCombined"
                        [results]="marketBet?.GroupResults"
                        [index]="indexCombinedBets"
                        (bet)="bet($event, marketBet, marketBet?.Name, '9')"
                        [selected]="newTicketFacade?.hasResult(result?.NodeId)"
                        [sport]="market?.SportHandle"
                        [sportHandler]="league?.SportHandle"
                        bets
                      >
                      </sb-button>
                    </sb-grid-item>
                  </span>
                </sb-dropdown>
              </ng-container>
            </ion-col>
            <ion-col class="sb-dropdown--container" col-6>
              <ng-container *ngFor="let marketBet of markets; let index = index; let even = even; let odd = odd">
                <sb-dropdown
                  *ngIf="odd"
                  [title]="marketBet?.Name"
                  [contentType]="marketBet?.DisplayTypeName"
                  [index]="-1"
                  [mode]="marketDesktopMode"
                >
                  <sb-grid-header
                    *ngIf="marketBet?.isCombined || marketBet?.isHandicap"
                    [titleLeft]="marketBet?.teamHome"
                    [titleCenter]="marketBet?.titleCenter"
                    [titleRight]="marketBet?.teamAway"
                    [market]="marketBet"
                  >
                  </sb-grid-header>

                  <sb-grid-item
                    id="7"
                    *ngIf="!marketBet?.isCombined || marketBet?.isHandicap"
                    [mode]="marketBet?.displayMode"
                    [event]="marketBet"
                    (navigate)="navigate(true, marketBet)"
                    [betType]="marketBet?.DisplayTypeName"
                    [currentPage]="'event-page'"
                  >
                    <div
                      *ngIf="gametypes.indexOf(marketBet?.GameType) == -1"
                      class="sb-grid-item--bets-group"
                      bet-display
                      sb-markets
                      [betType]="marketBet?.DisplayTypeName"
                    >
                      <sb-button
                        *ngFor="let result of marketBet?.GroupResults; let index = index"
                        [type]="marketBet?.GameType"
                        [locked]="result?.Locked"
                        [typeName]="marketBet?.DisplayTypeName"
                        [game]="result"
                        [isCombined]="marketBet?.isCombined"
                        [results]="marketBet?.GroupResults"
                        [index]="index"
                        (bet)="bet($event, marketBet, marketBet?.Name, '10')"
                        [selected]="newTicketFacade?.hasResult(result?.NodeId)"
                        mode="expanded"
                        [sport]="market?.SportHandle"
                        [sportHandler]="league?.SportHandle"
                        bets
                      >
                      </sb-button>
                    </div>

                    <div
                      *ngIf="gametypes.indexOf(marketBet?.GameType) !== -1"
                      class="sb-grid-item--bets-group"
                      [betType]="marketBet?.DisplayTypeName"
                      bet-display
                      sb-markets
                    >
                      <div class="sb-grid-item--column is-final-score">
                        <sb-button
                          *ngFor="let result of marketBet?.GroupResults[0]; let index = index"
                          [type]="marketBet?.GameType"
                          [typeName]="marketBet?.DisplayTypeName"
                          [game]="result"
                          [isCombined]="marketBet?.isCombined"
                          [results]="marketBet?.GroupResults"
                          [locked]="result?.Locked"
                          [index]="index"
                          (bet)="bet($event, marketBet, marketBet?.Name, '11')"
                          [selected]="newTicketFacade?.hasResult(result.NodeId)"
                          mode="expanded"
                          [sport]="market?.SportHandle"
                          [sportHandler]="league?.SportHandle"
                          bets
                        >
                        </sb-button>
                      </div>

                      <div class="sb-grid-item--column is-final-score">
                        <sb-button
                          *ngFor="let result of marketBet?.GroupResults[1]; let index = index"
                          [type]="marketBet?.GameType"
                          [typeName]="marketBet?.DisplayTypeName"
                          [game]="result"
                          [isCombined]="marketBet?.isCombined"
                          [results]="marketBet?.GroupResults"
                          [locked]="result?.Locked"
                          [index]="index"
                          (bet)="bet($event, marketBet, marketBet?.Name, '12')"
                          [selected]="newTicketFacade?.hasResult(result.NodeId)"
                          mode="expanded"
                          [sport]="market?.SportHandle"
                          [sportHandler]="league?.SportHandle"
                          bets
                        >
                        </sb-button>
                      </div>

                      <div class="sb-grid-item--column is-final-score">
                        <sb-button
                          *ngFor="let result of marketBet?.GroupResults[2]; let index = index"
                          [type]="marketBet?.GameType"
                          [typeName]="marketBet?.DisplayTypeName"
                          [game]="result"
                          [isCombined]="marketBet?.isCombined"
                          [results]="marketBet?.GroupResults"
                          [locked]="result?.Locked"
                          [index]="index"
                          (bet)="bet($event, marketBet, marketBet?.Name, '13')"
                          [selected]="newTicketFacade?.hasResult(result.NodeId)"
                          mode="expanded"
                          [sport]="market?.SportHandle"
                          [sportHandler]="league?.SportHandle"
                          bets
                        >
                        </sb-button>
                      </div>
                    </div>
                  </sb-grid-item>

                  <!-- COMBINED BETS HARD-->
                  <span *ngFor="let market of marketBet?.combinedResults; let indexCombinedResults = index">
                    <sb-grid-item
                      id="8"
                      *ngIf="marketBet?.isHardCombined"
                      [mode]="marketBet?.displayMode"
                      [event]="marketBet"
                      [betType]="marketBet?.DisplayTypeName"
                      [currentPage]="'event-page'"
                    >
                      <sb-grid-content-single *ngIf="marketBet?.isCombined" [title]="market?.spov[0]" content>
                      </sb-grid-content-single>
                      <div
                        class="sb-grid-item--bets-group"
                        bet-display
                        sb-markets
                        [betType]="marketBet?.DisplayTypeName"
                      >
                        <sb-button
                          *ngFor="let result of (market?.results)[0]"
                          [type]="marketBet?.DisplayTypeName"
                          [game]="result"
                          [isCombined]="marketBet?.isCombined"
                          [locked]="result?.Locked"
                          [results]=""
                          (bet)="bet($event, marketBet, marketBet?.Name, '14')"
                          [selected]="newTicketFacade?.hasResult(result?.NodeId)"
                          mode="expanded"
                          [sport]="market?.SportHandle"
                          [sportHandler]="league?.SportHandle"
                          bets
                        >
                        </sb-button>
                      </div>
                    </sb-grid-item>
                    <sb-grid-item
                      id="9"
                      *ngIf="marketBet?.isHardCombined"
                      [mode]="marketBet?.displayMode"
                      [event]="marketBet"
                      [betType]="marketBet?.DisplayTypeName"
                      [currentPage]="'event-page'"
                    >
                      <sb-grid-content-single *ngIf="marketBet?.isCombined" [title]="market?.spov[1]" content>
                      </sb-grid-content-single>
                      <div
                        class="sb-grid-item--bets-group"
                        bet-display
                        sb-markets
                        [betType]="marketBet?.DisplayTypeName"
                      >
                        <sb-button
                          *ngFor="let result of (market?.results)[1]"
                          [type]="marketBet?.DisplayTypeName"
                          [game]="result"
                          [isCombined]="marketBet?.isCombined"
                          [locked]="result?.Locked"
                          [results]=""
                          (bet)="bet($event, marketBet, marketBet?.Name, '15')"
                          [selected]="newTicketFacade?.hasResult(result?.NodeId)"
                          mode="expanded"
                          [sport]="market?.SportHandle"
                          [sportHandler]="league?.SportHandle"
                          bets
                        >
                        </sb-button>
                      </div>
                    </sb-grid-item>
                    <!-- COMBINED BETS  EASY-->
                    <sb-grid-item
                      id="10"
                      *ngIf="marketBet?.isSoftCombined"
                      [mode]="marketBet?.displayMode"
                      [event]="marketBet"
                      [betType]="marketBet?.DisplayTypeName"
                      [currentPage]="'event-page'"
                    >
                      <sb-grid-content-single *ngIf="marketBet?.isCombined" [title]="market?.spov" content>
                      </sb-grid-content-single>
                      <div
                        class="sb-grid-item--bets-group"
                        bet-display
                        sb-markets
                        [betType]="marketBet?.DisplayTypeName"
                      >
                        <sb-button
                          *ngFor="let result of market?.results"
                          [type]="marketBet?.DisplayTypeName"
                          [game]="result"
                          [isCombined]="marketBet?.isCombined"
                          [results]=""
                          [locked]="result?.Locked"
                          (bet)="bet($event, marketBet, marketBet?.Name, '16')"
                          [selected]="newTicketFacade?.hasResult(result?.NodeId)"
                          mode="expanded"
                          [sport]="market?.SportHandle"
                          [sportHandler]="league?.SportHandle"
                          bets
                        >
                        </sb-button>
                      </div>
                    </sb-grid-item>
                    <!--SIX PACK GRID end-->
                    <sb-grid-item
                      id="11"
                      *ngIf="marketBet?.isSixPack"
                      [mode]="marketBet?.displayMode"
                      [event]="marketBet"
                      [betType]="marketBet?.DisplayTypeName"
                      [currentPage]="'event-page'"
                    >
                      <sb-grid-content-single *ngIf="marketBet?.isCombined" [title]="market?.spov" content>
                      </sb-grid-content-single>
                      <div
                        class="sb-grid-item--bets-group"
                        bet-display
                        sb-markets
                        [betType]="marketBet?.DisplayTypeName"
                      >
                        <sb-button
                          *ngFor="let result of market?.results; let indexCombinedBets = index"
                          [type]="marketBet?.DisplayTypeName"
                          [game]="result"
                          [isCombined]="marketBet?.isCombined"
                          [results]="marketBet?.GroupResults"
                          [index]="indexCombinedBets"
                          [locked]="result?.Locked"
                          (bet)="bet($event, marketBet, marketBet?.Name, '17')"
                          [selected]="newTicketFacade?.hasResult(result?.NodeId)"
                          [sport]="market?.SportHandle"
                          [sportHandler]="league?.SportHandle"
                          bets
                        >
                        </sb-button>
                      </div>
                    </sb-grid-item>
                  </span>
                </sb-dropdown>
              </ng-container>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

      <codere-footer></codere-footer>
    </ng-container>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <ion-header>
    <sb-navbar theme="sportbook" [hasBackButton]="true" [fakeButton]="!canGoBack">
      <div>
        <h4 class="sb-back--title color-light">
          {{ sportsbookFacade?.selectedEvent?.SportName }} / {{ sportsbookFacade?.selectedEvent?.Name }}
        </h4>
      </div>
    </sb-navbar>
  </ion-header>

  <ion-content
    [scrollEvents]="true"
    (ionScroll)="onScroll($event)"
    [ngClass]="{'no-scroll': globalVars?.iosScrollHide}"
  >
    <ng-container
      *ngIf="{ cat: eventMarkesCategories$ | async, events: games$ | async, markets: markets$ | async } as obs"
    >
      <div class="widgets" *ngIf="showTournament()">
        <div class="sr-widget sr-widget-22" #standardWidgetSIR></div>
      </div>

      <div class="sb-sticky sb-sticky-border-bottom container">
        <sb-tabs
          id="filter-1"
          *ngIf="eventMainCategories?.length > 1"
          [categories]="eventMainCategories"
          [color]="'dark'"
          [selectedTab]="selectedEventMainCategory"
          (selectTab)="changeMainCategory($event)"
        ></sb-tabs>
        <sb-tabs-categories
          id="filter-2"
          *ngIf="obs?.cat?.length > 1"
          [categories]="obs?.cat"
          [color]="'regular'"
          [scrollable]="true"
          [isMobile]="isMobile"
          [selectedTab]="selectedMarketsCategory"
          (selectTab)="changeCategory($event)"
        ></sb-tabs-categories>
        <sb-filter
          id="filter-3"
          [hidden]="!showingMoreBets || hideCategories"
          [filters]="betsCategories"
          [selected]="currentAddedCategory$ | async"
          (filter)="changeCategory($event)"
          (resize)="resize()"
        >
        </sb-filter>
      </div>
      <div class="sb-grid-container" [hidden]="showingMoreBets">
        <ion-list class="m-unset" *ngIf="obs?.events as events">
          <div class="sb-empty-state color-grey-900" *ngIf="events?.length <= 0">
            <span>No hay apuestas en este momento, inténtalo más tarde</span>
          </div>

          <!-- Directos -->
          <ng-container *ngFor="let event of events; trackBy: utils?.trackBy">
            <sb-grid-item
              id="12"
              *ngIf="!event?.isSixPack"
              [event]="event"
              [mode]="event?.mode"
              [hasBets]="!!event?.Games?.length"
              [special]="sportBookService?.isSpecialMarket(event?.LeagueName)"
              (navigate)="navigate(event)"
              [currentPage]="'event-page'"
            >
              <!--template outlet for content-->
              <ng-container
                *ngTemplateOutlet="event?.mode === 'teams' ? teams : expanded; context: {event: event}"
                content
              >
              </ng-container>
              <sb-overview-score-sets
                *ngIf="event?.overviewScoreboard === 'sets'"
                [sportHandle]="event?.SportHandle"
                [scoreboard]="event?.liveData"
                scoreboard
              >
              </sb-overview-score-sets>

              <sb-overview-score-regular
                *ngIf="event?.overviewScoreboard === 'regular'"
                [scoreboard]="event?.liveData"
                scoreboard
              >
              </sb-overview-score-regular>

              <div
                class="sb-grid-item--bets-group"
                [ngClass]="'has-' + event?.Games?.length + '-groups'"
                [ngStyle]="{display: event?.SportHandle == 'motorsport' ? 'none': '' }"
                [game]="game"
                *ngFor="let game of event?.Games; let index = index"
                bet-display
                [betType]="game?.DisplayTypeName"
                sb-markets
              >
                <sb-button
                  *ngFor="let result of game?.Results; let index = index; trackBy: utils?.trackBy"
                  [type]="game?.GameType"
                  [typeName]="game?.DisplayTypeName"
                  [game]="result"
                  [results]="game?.Results"
                  [index]="index"
                  [locked]="result?.Locked"
                  [home]="event?.teamHome"
                  [away]="event?.teamAway"
                  [sport]="event?.SportHandle"
                  (bet)="bet($event, event, game?.Name, '1')"
                  [selected]="newTicketFacade.hasResult(result.NodeId)"
                  [sportHandler]="league?.SportHandle"
                >
                </sb-button>
              </div>
            </sb-grid-item>
            <!-- SIX PACK -->
            <ng-container *ngIf="event?.isSixPack">
              <sb-grid-header
                [titleLeft]="'Ganador'"
                [titleCenter]="'Handicap'"
                [titleRight]="'Totales'"
                [market]="event?.sixPack"
              >
              </sb-grid-header>

              <sb-grid-item
                id="13"
                [mode]="event?.sixPack?.displayMode"
                [event]="event?.sixPack"
                [betType]="event?.sixPack?.DisplayTypeName"
                [special]="sportBookService?.isSpecialMarket(event?.LeagueName)"
                (navigate)="navigate(event)"
                [currentPage]="'event-page'"
              >
                <sb-grid-content-single
                  [title]="event?.sixPack?.teamHome"
                  [subtitle]="event?.sixPack?.pitcherHome"
                  [countryCode]="event?.CountryCode"
                  content
                >
                </sb-grid-content-single>
                <div
                  class="sb-grid-item--bets-group"
                  bet-display
                  [betType]="event?.sixPack?.DisplayTypeName"
                  sb-markets
                >
                  <sb-button
                    *ngFor="let result of (event?.sixPack?.combinedResults)[0]?.results; let indexCombinedBets = index; trackBy: utils?.trackBy"
                    [typeName]="event?.sixPack?.DisplayTypeName"
                    [type]="result?.GameType"
                    [game]="result"
                    [isCombined]="event?.sixPack?.isCombined"
                    [results]="event?.sixPack?.GroupResults"
                    [index]="indexCombinedBets"
                    [selected]="newTicketFacade.hasResult(result.NodeId)"
                    [locked]="result?.Locked"
                    [home]="event?.sixPack?.teamHome"
                    [away]="event?.sixPack?.teamAway"
                    [sport]="event?.SportHandle"
                    (bet)="bet($event, event, event?.Name, '2')"
                    [sportHandler]="league?.SportHandle"
                    bets
                  >
                  </sb-button>
                </div>
              </sb-grid-item>

              <sb-grid-item
                id="14"
                *ngIf="event?.isSixPack"
                class="sb-item-container-margin-bot"
                [mode]="event?.sixPack?.displayMode"
                [event]="event?.sixPack"
                [betType]="event?.sixPack?.DisplayTypeName"
                [special]="sportBookService?.isSpecialMarket(event?.LeagueName)"
                (navigate)="navigate(event)"
                [currentPage]="'event-page'"
              >
                <sb-grid-content-single
                  [title]="event?.sixPack?.teamAway"
                  [subtitle]="event?.sixPack?.pitcherAway"
                  [countryCode]="event?.CountryCode"
                  content
                >
                </sb-grid-content-single>
                <div
                  class="sb-grid-item--bets-group"
                  [betType]="event?.sixPack?.DisplayTypeName"
                  bet-display
                  sb-markets
                >
                  <sb-button
                    *ngFor="let result of (event?.sixPack?.combinedResults)[1]?.results; let indexCombinedBets = index; trackBy: utils?.trackBy"
                    [typeName]="event?.sixPack?.DisplayTypeName"
                    [type]="result?.GameType"
                    [game]="result"
                    [isCombined]="event?.sixPack?.isCombined"
                    [results]="event?.sixPack?.GroupResults"
                    [locked]="result?.Locked"
                    [index]="indexCombinedBets"
                    [selected]="newTicketFacade.hasResult(result.NodeId)"
                    [home]="event?.sixPack?.teamHome"
                    [away]="event?.sixPack?.teamAway"
                    [sport]="event?.SportHandle"
                    (bet)="bet($event, event, event?.Name, '3')"
                    [sportHandler]="league?.SportHandle"
                    bets
                  >
                  </sb-button>
                </div>
              </sb-grid-item>
            </ng-container>
          </ng-container>
        </ion-list>
      </div>
      <div class="sb-grid-container" [hidden]="!showingMoreBets">
        <div class="sb-empty-state text-left color-grey-900" *ngIf="market?.length === 0">
          <span>La oferta no está disponible en este momento</span>
        </div>
        <ion-list class="events-list" *ngIf="obs?.markets as markets">
          <sb-dropdown
            *ngFor="let marketBet of markets; let index = index"
            [index]="-1"
            [title]="marketBet?.Name"
            [contentType]="marketBet?.DisplayTypeName"
            [mode]="marketBet"
          >
            <sb-grid-header
              *ngIf="marketBet?.isCombined || marketBet?.isHandicap"
              [titleLeft]="marketBet?.teamHome"
              [titleCenter]="marketBet?.titleCenter"
              [titleRight]="marketBet?.teamAway"
              [market]="marketBet"
            >
            </sb-grid-header>

            <sb-grid-item
              id="15"
              *ngIf="!marketBet?.isCombined || marketBet?.isHandicap"
              [mode]="marketBet?.displayMode"
              [event]="marketBet"
              (navigate)="navigate(true, marketBet)"
              [currentPage]="'event-page'"
            >
              <div
                *ngIf="gametypes.indexOf(marketBet?.GameType) == -1"
                class="sb-grid-item--bets-group"
                bet-display
                sb-markets
                [betType]="marketBet?.DisplayTypeName"
              >
                <sb-button
                  *ngFor="let result of marketBet?.GroupResults; let index = index"
                  [type]="marketBet?.GameType"
                  [typeName]="marketBet?.DisplayTypeName"
                  [game]="result"
                  [isCombined]="marketBet?.isCombined"
                  [results]="marketBet?.GroupResults"
                  [index]="index"
                  [selected]="newTicketFacade?.hasResult(result.NodeId)"
                  [locked]="result?.Locked"
                  [sport]="market?.SportHandle"
                  (bet)="bet($event, marketBet, marketBet?.Name, '4')"
                  mode="expanded"
                  [sportHandler]="league?.SportHandle"
                  bets
                >
                </sb-button>
              </div>

              <div
                *ngIf="gametypes.indexOf(marketBet?.GameType) !== -1"
                class="sb-grid-item--bets-group"
                [betType]="marketBet?.DisplayTypeName"
                bet-display
                sb-markets
              >
                <div class="sb-grid-item--column is-final-score">
                  <sb-button
                    *ngFor="let result of marketBet?.GroupResults[0]; let index = index"
                    [type]="marketBet?.GameType"
                    [typeName]="marketBet?.DisplayTypeName"
                    [game]="result"
                    [isCombined]="marketBet?.isCombined"
                    [results]="marketBet?.GroupResults"
                    [locked]="result?.Locked"
                    [index]="index"
                    [selected]="newTicketFacade?.hasResult(result.NodeId)"
                    [sport]="market?.SportHandle"
                    (bet)="bet($event, marketBet, marketBet?.Name, '5')"
                    mode="expanded"
                    [sportHandler]="league?.SportHandle"
                    bets
                  >
                  </sb-button>
                </div>

                <div class="sb-grid-item--column is-final-score">
                  <sb-button
                    *ngFor="let result of marketBet?.GroupResults[1]; let index = index"
                    [type]="marketBet?.GameType"
                    [typeName]="marketBet?.DisplayTypeName"
                    [game]="result"
                    [isCombined]="marketBet?.isCombined"
                    [results]="marketBet?.GroupResults"
                    [locked]="result?.Locked"
                    [index]="index"
                    [selected]="newTicketFacade?.hasResult(result.NodeId)"
                    [sport]="market?.SportHandle"
                    (bet)="bet($event, marketBet, marketBet?.Name, '6')"
                    mode="expanded"
                    [sportHandler]="league?.SportHandle"
                    bets
                  >
                  </sb-button>
                </div>

                <div class="sb-grid-item--column is-final-score">
                  <sb-button
                    *ngFor="let result of marketBet?.GroupResults[2]; let index = index"
                    [type]="marketBet?.GameType"
                    [typeName]="marketBet?.DisplayTypeName"
                    [game]="result"
                    [isCombined]="marketBet?.isCombined"
                    [results]="marketBet?.GroupResults"
                    [locked]="result?.Locked"
                    [index]="index"
                    [selected]="newTicketFacade?.hasResult(result.NodeId)"
                    [sport]="market?.SportHandle"
                    (bet)="bet($event, marketBet, marketBet?.Name, '7')"
                    [sportHandler]="league?.SportHandle"
                    mode="expanded"
                    bets
                  >
                  </sb-button>
                </div>
              </div>
            </sb-grid-item>

            <!-- COMBINED BETS-->
            <span *ngFor="let market of marketBet?.combinedResults; let indexCombinedResults = index">
              <!-- COMBINED BETS HARD-->
              <sb-grid-item
                id="16"
                *ngIf="marketBet?.isHardCombined"
                [mode]="marketBet?.displayMode"
                [event]="marketBet"
                [betType]="marketBet?.DisplayTypeName"
                [currentPage]="'event-page'"
              >
                <sb-grid-content-single *ngIf="marketBet?.isCombined" [title]="market?.spov[0]" content>
                </sb-grid-content-single>
                <div class="sb-grid-item--bets-group" bet-display sb-markets [betType]="marketBet?.DisplayTypeName">
                  <sb-button
                    *ngFor="let result of (market?.results)[0]"
                    [type]="marketBet?.DisplayTypeName"
                    [game]="result"
                    [isCombined]="marketBet?.isCombined"
                    [results]=""
                    [selected]="newTicketFacade?.hasResult(result.NodeId)"
                    [locked]="result?.Locked"
                    [sport]="market?.SportHandle"
                    (bet)="bet($event, marketBet, marketBet?.Name, '8')"
                    [sportHandler]="league?.SportHandle"
                    mode="expanded"
                    bets
                  >
                  </sb-button>
                </div>
              </sb-grid-item>
              <sb-grid-item
                id="17"
                *ngIf="marketBet?.isHardCombined"
                [mode]="marketBet?.displayMode"
                [event]="marketBet"
                [betType]="marketBet?.DisplayTypeName"
                [currentPage]="'event-page'"
              >
                <sb-grid-content-single *ngIf="marketBet?.isCombined" [title]="market?.spov[1]" content>
                </sb-grid-content-single>

                <div class="sb-grid-item--bets-group" bet-display sb-markets [betType]="marketBet?.DisplayTypeName">
                  <sb-button
                    *ngFor="let result of (market?.results)[1]"
                    [type]="marketBet?.DisplayTypeName"
                    [game]="result"
                    [isCombined]="marketBet?.isCombined"
                    [results]=""
                    [locked]="result?.Locked"
                    [selected]="newTicketFacade?.hasResult(result.NodeId)"
                    [sport]="market?.SportHandle"
                    (bet)="bet($event, marketBet, marketBet?.Name, '9')"
                    [sportHandler]="league?.SportHandle"
                    mode="expanded"
                    bets
                  >
                  </sb-button>
                </div>
              </sb-grid-item>
              <!-- COMBINED BETS  EASY-->
              <sb-grid-item
                id="18"
                *ngIf="marketBet?.isSoftCombined"
                [mode]="marketBet?.displayMode"
                [event]="marketBet"
                [betType]="marketBet?.DisplayTypeName"
                [currentPage]="'event-page'"
              >
                <sb-grid-content-single *ngIf="marketBet?.isCombined" [title]="market?.spov" contentid-content-single>
                </sb-grid-content-single>

                <div class="sb-grid-item--bets-group" bet-display sb-markets [betType]="marketBet?.DisplayTypeName">
                  <sb-button
                    *ngFor="let result of market?.results"
                    [type]="marketBet?.DisplayTypeName"
                    [game]="result"
                    [isCombined]="marketBet?.isCombined"
                    [results]=""
                    [selected]="newTicketFacade?.hasResult(result.NodeId)"
                    [locked]="result?.Locked"
                    [sport]="market?.SportHandle"
                    (bet)="bet($event, marketBet, marketBet?.Name, '10')"
                    [sportHandler]="league?.SportHandle"
                    mode="expanded"
                    bets
                  >
                  </sb-button>
                </div>
              </sb-grid-item>
            </span>
          </sb-dropdown>
        </ion-list>
      </div>

      <codere-footer></codere-footer>
    </ng-container>
  </ion-content>
</ng-template>
<!--Overview Pages-->
<ng-template #teams let-event="event" content>
  <sb-grid-content-teams [event]="event" content></sb-grid-content-teams>
</ng-template>
<ng-template #expanded let-event="event" content>
  <sb-grid-content-expanded [title]="event?.Name" [date]="event?.StartDate | extractDate" content>
  </sb-grid-content-expanded>
  <span
    *ngIf="!!event?.ChildrenCount && event?.SportHandle == 'motorsport'"
    class="sb-grid-header--bets-counter color-secondary"
    counter-button
    tappable
  >
    +{{ event?.ChildrenCount }}
    <span class="codere-icon icon-arrow-right"></span
  ></span>
</ng-template>
