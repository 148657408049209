import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, Output, ViewChild, inject } from "@angular/core";
import { IonAccordionGroup, IonicModule } from "@ionic/angular";

// Models
import { GlobalVarsModel } from "@models/ModelGlobalsVar";

// Other
import { DeviceService } from "@services/device.service";

@Component({
  selector: "sb-dropdown-menu",
  templateUrl: "./sb-dropdown-menu.component.html",
  standalone: true,
  imports: [CommonModule, IonicModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  styleUrls: ["./sb-dropdown-menu.component.scss"],
})
export class SbDropdownMenuComponent {
  @ViewChild("DropdownMenu", { static: true }) accordionGroup: IonAccordionGroup;
  @Input() dropdownItems: any[];
  @Input() selectedItem: any;
  @Input() isProcessing: boolean;
  @Output() selectItem: EventEmitter<any> = new EventEmitter();

  globalVars!: GlobalVarsModel;

  isMobile: boolean;
  isDesktop: boolean;

  deviceService = inject(DeviceService);

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
  }

  onClick(item) {
    this.colapseDropdown();
    this.selectItem.emit(item);
  }

  colapseDropdown() {
    const nativeEl = this.accordionGroup;
    nativeEl.value = undefined;
  }
}
