import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { Geolocation } from "@capacitor/geolocation";
import { INTERFACE } from "@interfaces/general";
import { AlertController } from "@ionic/angular";
import { LicenseTypes } from "@models/MasterData";
import { TranslateService } from "@ngx-translate/core";
import { MSO_PATHS } from "@shared-constants/routes";
import { type Observable, type Subscription, catchError, interval, map } from "rxjs";
import { environment } from "src/environments/environment.config";
import { BaseService } from "./BaseService";
import { UserService } from "./UserService";
const TYPE = {
  INTERNET_FAIL: "internetFail",
  GEOLOCATION: "geolocation",
};

const COUTRIES = {
  IL: "IL",
  NL: "NL",
  US: "US",
  ES: "ES",
  CO: "CO",
  PA: "PA",
  AR: "AR",
};

@Injectable({ providedIn: "root" })
export class GeolocationService extends BaseService {
  subscription: Subscription;
  private timerInitialize = false;
  //#region Inject Services
  alertCtrl = inject(AlertController);
  translate = inject(TranslateService);
  userService = inject(UserService);
  //#endregion

  mockCookie: INTERFACE.ConsentOptionsData = {
    customOK: true,
    analyticsOK: true,
    advertismentOK: true,
  };

  private router = inject(Router);

  constructor() {
    super();
  }

  initLocationtCheck(): Observable<boolean> {
    return this.myGet(this.globalVars.URL_GEOLOCATION_API).pipe(
      map((response) => {
        console.log("geolocation country >>", response);
        // set local variable,
        // if false, the app does not start and navigates to Geoplocation Page
        // if true, app starts normally
        let geoLocationCheck: boolean;

        const isDebugMode: boolean = environment.GLOBALS.DEBUG_MODE;

        if (isDebugMode) {
          console.log("Geolocation debug mode is >>", isDebugMode);
        }

        if (
          // if debug mode is true, and response is IL, NL and US
          // we pass geolocation
          (isDebugMode && response === COUTRIES.IL) ||
          (isDebugMode && response === COUTRIES.NL) ||
          (isDebugMode && response === COUTRIES.US)
        ) {
          return (geoLocationCheck = true);
        }

        if (
          // if debug mode is false, and response is ES, CO, PA or AR
          // we pass geolocation
          (this.globalVars.LICENSE_TYPE == 0 && response === COUTRIES.ES) ||
          (this.globalVars.LICENSE_TYPE == 21 && response === COUTRIES.CO) ||
          (this.globalVars.LICENSE_TYPE == 22 && response === COUTRIES.PA) ||
          (this.globalVars.LICENSE_TYPE == 33 && response === COUTRIES.AR)
        ) {
          return (geoLocationCheck = true);
        } else {
          this.navigateToGeolocationPage();
          return (geoLocationCheck = false);
        }
      }),
      catchError((error: any) => {
        throw new Error(error);
      }),
    );
  }

  navigateToGeolocationPage() {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.GeolocationPage}`], {
      queryParams: { type: TYPE.GEOLOCATION, animate: true },
      queryParamsHandling: "merge",
    });
  }

  getGeolocationEveryXTime() {
    const time = interval(this.globalVars.FEATURES.GeolocationTimespan * 60000);
    this.getGeolocation();
    this.subscription = time.subscribe(() => this.getGeolocation());
  }

  /**
   * Intenta obtener las coordenadas utilizando
   * 1 - El plugin de cordova de GeoLocalization
   * 2 - La geolocalización nativa del navegador
   */
  // async getGeolocation() {
  //   try {
  //     const location = await Geolocation.getCurrentPosition();
  //     this.globalVars.isGeolocalized = true;
  //     this.geoSuccess(location);
  //   } catch (error: any) {
  //     // User denied Geolocation
  //     this.geoFail(error);
  //     this.geolocationAlert('important', 'geolocation_ko', false);
  //   }
  // }

  getGeolocation() {
    if (this.globalVars.isNative) {
      Geolocation.getCurrentPosition()
        .then((resp) => {
          this.geoSuccess(resp);
          this.globalVars.isGeolocalized = true;
        })
        .catch((error) => {
          console.log("Error getting location", error);
          this.geoFail(error);
        });
    } else if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => this.geoSuccess(position),
        (error_message) => this.geoFail(error_message),
      );
    } else {
      //no soporta geolocation
    }
  }

  /**
   * A partir de las coordenadas recibidas como parámetro,
   * comprueba si corresponden a CABA (Ciudad Autonoma de Buenos Aires),
   * haciendo una llamada al método isInCaba
   * @param position
   */
  geoSuccess(location: GeolocationPosition) {
    const latitude = location.coords.latitude;
    const longitude = location.coords.longitude;

    this.isInCaba(latitude.toString(), longitude.toString()).subscribe({
      next: (result) => {
        this.isInCabaResponse(result);
      },
      error: (_error) => {
        this.globalVars.isCaba = false;
        this.geolocationAlert("important", "geolocation_ko", false);
      },
    });
  }

  geoFail(error: object) {
    //error o no aceptado
    this.globalVars.isGeolocalized = false;
    this.globalVars.isCaba = false;
  }

  /**
   * recibo coordenadas, llamamos al endpoint para ver si esta en
   *  Ciudad Autonoma de Buenos Aires (CABA)
   * @param lat
   * @param long
   * @returns
   */
  isInCaba(lat: string = null, long: string = null): Observable<any> {
    const coords = { lon: long, lat: lat };
    const url = `${this.globalVars.URLBASE_USERSERVICE}api/Geolocation`;
    return this.myPost(url, coords);
  }

  /**
   * Si es necesario, muestra un aviso para que el usuario permita la Geolocalización
   * (Se usa en registro y login de Argentina)
   */
  showGeolocation() {
    // intentar Localización por IP
    this.isInCaba().subscribe(
      (resp) => {
        this.isInCabaResponse(resp);
      },
      (_) => {
        this.globalVars.isCaba = false;
        this.geolocationAlert("important", "geolocation_ko", false);
      },
    );
  }

  isInCabaResponse(resp) {
    switch (resp.data.code) {
      case 1:
        this.globalVars.isGeolocalized = true;
        this.globalVars.isCaba = true;
        break;
      case 2:
        this.globalVars.isGeolocalized = true;
        this.globalVars.isCaba = false;
        break;
      case 3:
        this.geolocationAlert(
          "important",
          this.globalVars.licenseType == LicenseTypes.ArgentinaMendoza
            ? "geolocation_required_mza"
            : "geolocation_required",
          true,
        );
        break;
      case 4:
        this.globalVars.isCaba = false;
        this.geolocationAlert("important", "geolocation_ko", false);
        break;
    }
  }

  /**
   * Manejador del evento click del alert
   * en el que se autoriza el uso de geolocalización
   */
  getGeolocationHandler() {
    const time = this.globalVars.FEATURES.GeolocationTimespan;
    if (time && time > 0) this.getGeolocationEveryXTime();
    else this.getGeolocation();
  }

  async geolocationAlert(title: string, message: string, hasHandler: boolean) {
    const footer = await this.utils.createARFooter();
    const alert = await this.alertCtrl.create({
      header: this.translate.instant(title),
      message: this.translate.instant(message),
      buttons: [
        {
          text: this.translate.instant("geolocation_sports_caba_btn"),
          handler: () => {
            if (hasHandler) {
              this.getGeolocationHandler();
            }
          },
        },
      ],
    });
    await alert.present();
    // Agregar el pie de página después de que la alerta se haya presentado
    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      if (alert) {
        const alertElement = alert.querySelector(".alert-wrapper");
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }

    //no sale popup para geolocalizar
    // const errorAlert = this.utils.alert(false, this.translate.instant(title), this.translate.instant(message), this.translate.instant('geolocation_sports_caba_btn'), () => {
    // });
    alert.onDidDismiss().then(() => {
      if (hasHandler) {
        this.getGeolocationHandler();
      }
    });
    this.utils.formatAlertSportsGeolocalization(
      alert,
      this.globalVars.licenseType === LicenseTypes.ArgentinaCaba
        ? "https://wa.me/5491136822558"
        : "https://wa.me/5491140457247",
      this.translate.instant("comunicationwhatsapp"),
    );
  }

  setLoginGeolocation() {
    this.globalVars.isGeolocalized = true;
    this.globalVars.isCaba = true;
  }

  resetGeolocationTimer() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.timerInitialize = false;
  }

  initTimerGeolocation() {
    if (!this.timerInitialize) {
      this.timerInitialize = true;
      this.getGeolocationEveryXTime();
    }
  }
}
