import { Component, inject } from "@angular/core";
import { IonicModule, ModalController, NavController } from "@ionic/angular";

import { CommonModule } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule } from "@ngx-translate/core";
import { NewBaseService } from "@providers/newBase.service";
import { FundsOrigin } from "@providers/payments/BankService";
import { PaymentsCashierService } from "@providers/payments/PaymentsCashierService";
import { DeviceService } from "@services/device.service";
import { MSO_PATHS } from "@shared-constants/routes";

@Component({
  selector: "cashier-withdrawals-sworm",
  templateUrl: "./cashier-withdrawals-sworm.html",
  styleUrls: ["./cashier-withdrawals-sworm.scss"],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, TranslateModule, ReactiveFormsModule],
})
export class CashierWithdrawalsSworm {
  isAcepted: boolean = false;
  message: string;
  editMessage: string = "close";
  fundsOriginForm: FormGroup;
  params: any;
  fundsOrigin: Array<FundsOrigin>;
  selectOptions = {
    side: "bottom",
    mode: "md",
    alignment: "start",
  };
  originFundsSelected?: string;
  swormTitle?: string;

  globalVars!: GlobalVarsModel;
  isDesktop = false;
  isMobile = false;

  public modalController = inject(ModalController);
  public paymentsCashierService = inject(PaymentsCashierService);
  public navCtrl = inject(NavController);
  public router = inject(Router);
  deviceService = inject(DeviceService);
  formBuilder = inject(FormBuilder);
  newBaseService = inject(NewBaseService);
  public viewCtrl = inject(ModalController);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();

    this.fundsOriginForm = this.formBuilder.group({
      origin: ["", Validators.required],
      legalControl: [false, Validators.requiredTrue],
    });
  }

  ngOnInit() {
    if (this.originFundsSelected) {
      this.fundsOriginForm.controls["origin"].setValue(
        this.fundsOrigin.find((forigin) => forigin.name === this.originFundsSelected),
      );
      //Uncomment line below if autocheck legal conditions needed.
      //this.fundsOriginForm.controls['legalControl'].setValue(true);
    }
    this.paymentsCashierService.customMessage.subscribe((msg) => (this.message = msg));
  }

  displayFieldError(field: string) {
    return !this.fundsOriginForm.controls[field].valid && this.fundsOriginForm.controls[field].touched;
  }

  changeMessage() {
    this.paymentsCashierService.changeMessage(this.editMessage);
  }

  btClose_Click() {
    this.modalController.dismiss();
  }

  acceptClick() {
    this.paymentsCashierService.cavaSwormAcepted();
    const originSelected = this.fundsOriginForm.controls["origin"].value;
    this.globalVars.originFunds = originSelected;
    const swornAccepted = this.isAcepted;
    this.modalController.dismiss({ originSelected, swornAccepted });
  }

  handleSwornClick(ev: any) {
    this.isAcepted = ev.target.checked;
    this.fundsOriginForm.controls["legalControl"].setValue(this.isAcepted);
  }

  goToProfile(params?: any) {
    this.changeMessage();
    if (this.isDesktop) {
      this.modalController.dismiss().then(() => {
        this.viewCtrl.dismiss();
        this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.PersonalInfoPage}`], { queryParams: params });
      });
    } else {
      this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.PersonalInfoPage}`], {});
    }
  }

  get originControl(): FormControl {
    return this.fundsOriginForm.get("origin") as FormControl;
  }

  get legalControl(): FormControl {
    return this.fundsOriginForm.get("legalControl") as FormControl;
  }
}
