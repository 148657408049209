import { Injectable, inject } from "@angular/core";
import { AlertController } from "@ionic/angular";

// Models
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { EventTypes } from "@models/TrackingEvents";
import {
  C_TicketApuestasNew,
  LineItem,
  SingleItemInput,
  StakeGroup,
  StakeModel,
  singleResultItem,
  systemData,
} from "@sports-models/index";

import { TrackingService } from "@providers/TrackingService";
// Services
import { NewBaseService } from "@providers/newBase.service";
import { TicketService } from "@sports-services/ticket.service-deprecated";

import { BetslipLineItem } from "@sports-models/index";
// Utils
import { Utils } from "@utils/Utils";

@Injectable({ providedIn: "root" })
export class BetslipStoreUtils {
  utils = inject(Utils);
  newBaseService = inject(NewBaseService);
  trackingService = inject(TrackingService);
  alertController = inject(AlertController);
  ticketService = inject(TicketService);

  globalVars: GlobalVarsModel;

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  autoSingleStake(betSlip: any, auxLineItem: SingleItemInput): StakeModel {
    const item: singleResultItem = betSlip.items.singleResultItems.find(
      (i) => i.resultId == +auxLineItem.ResultId,
    );

    if (!item.canBeEachWay && item.canBeEachWay !== auxLineItem.CanBeEachWay) {
      console.error("The bet is not possible");
    }

    const tempStake: StakeModel = {
      ItemId: item.itemId,
      OverallStake: this.defaultOverallStake,
      IsEachWay: auxLineItem.CanBeEachWay,
      Group: StakeGroup.SINGLES,
    };
    return tempStake;
  }

  addItemNumberToSingleResultItems(item, betslip) {
    const auxItem = betslip.items.singleResultItems.find((i) => i.resultId == +item.ResultsNr);
    return {
      ...item,
      ItemId: auxItem.itemId,
    };
  }

  addItemNumberToLineItem(lineItems, betslip) {
    // if (stake.ItemId) {
    //   auxStateTicket.LineItems.map((lineItem, index) => {
    //     if (lineItem.ItemId === stake.ItemId) {
    //       lineItem.Amount = stake.OverallStake / 100;
    //       lineItem.AmountLocal = stake.OverallStake / 100;
    //     }
    //   });
    //   auxStateTicket.SmartLineItems.map((smartLineItem, index) => {
    //     if (smartLineItem.ItemId === stake.ItemId) {
    //       smartLineItem.Amount = stake.OverallStake / 100;
    //       smartLineItem.AmountLocal = stake.OverallStake / 100;
    //     }
    //   });
    //   auxStateTicket.StraightMulticast.map((racesLineItem, index) => {
    //     if (racesLineItem.ItemId === stake.ItemId) {
    //       racesLineItem.Amount = stake.OverallStake / 100;
    //       racesLineItem.AmountLocal = stake.OverallStake / 100;
    //     }
    //   });
    //   auxStateTicket.CombinationMulticast.map((racesLineItem, index) => {
    //     if (racesLineItem.ItemId === stake.ItemId) {
    //       racesLineItem.Amount = stake.OverallStake / 100;
    //       racesLineItem.AmountLocal = stake.OverallStake / 100;
    //     }
    //   });
  }

  mapSingleResult = (game): SingleItemInput => ({
    Sport: {
      Id: game.SportId,
      SportHandle: game.SportHandle,
    },
    Location: {
      Id: game.LocationId,
    },
    League: {
      Id: game.LeagueId,
    },
    Event: {
      Id: game.EventId,
      HasHandicap: game.EventHasHandicap,
    },
    PlacementSource: "",
    ClientData: "",
    TaxSettings: "",
    Game: {
      Id: game.GameId,
      GameTypeId: game.GameTypeId,
      SpecialOddsValue: game.GameSpecialOddsValue,
      IsInPlay: game.IsLive,
      BetTypeAvailability: game.GameBetTypeAvailability,
      NumberOfStarters: game.NumberOfStarters,
      PlaceOddsDivisor: 0,
      IncludedPlaces: 0,
    },
    Odds: game.Odd,
    ResultId: game.NodeId,
    BetType: game.BetType ? game.BetType : "Default", // Sports: "Default", GHH: "Winner" or ""
    CanBeEachWay: game.CanBeEachWay ? game.CanBeEachWay : false, // Sports: false, GHH: true
  });

  mapLineItem = (item) => {
    const lineItem = new LineItem();
    lineItem.Amount = 0;
    lineItem.AmountLocal = 0;
    lineItem.CanBeEachWay = item.CanBeEachWay || false;
    lineItem.downOdd = item.downOdd;
    lineItem.EventDate = item.EventDate;
    lineItem.EventId = item.EventId;
    lineItem.EventName = item.EventName;
    lineItem.GameId = item.GameId;
    lineItem.GameName = item.GameName;
    lineItem.IsLive = item.IsLive;
    lineItem.Odd = item.Odd;
    lineItem.Place = item.Place;
    lineItem.ResultName = item.Name;
    lineItem.ResultsNr = item.NodeId;
    lineItem.SpecialOddsValue = item.GameSpecialOddsValue;
    lineItem.SportHandle = item.SportHandle;
    lineItem.TotalPotentialWin = 0;
    lineItem.upOdd = item.upOdd;
    return lineItem;
  };

  mapSystemBet(systemDataStake: systemData, overallStake: number) {
    const tempStake: StakeModel = {
      SystemId: systemDataStake.systemId,
      OverallStake: overallStake * 100,
      IsEachWay: systemDataStake.canBeEachWay,
      Group: StakeGroup.SYSTEM,
    };
    return tempStake;
  }

  autoSmartSingleStake(betSlip: any) {
    const itemId = betSlip.items.smartMarketItems[betSlip.items.smartMarketItems.length - 1].itemId;

    const tempStake: StakeModel = {
      ItemId: itemId,
      OverallStake: this.defaultOverallStake,
      IsEachWay: false,
      Group: StakeGroup.SINGLES,
    };
    return tempStake;
  }

  autoStraightMulticastStake(betSlip: any) {
    const itemId = betSlip.items.straightMulticastItems[betSlip.items.straightMulticastItems.length - 1].itemId;
    const tempStake: StakeModel = {
      ItemId: itemId,
      OverallStake: this.defaultOverallStake,
      IsEachWay: false,
      Group: StakeGroup.SINGLES,
    };
    return tempStake;
  }

  autoCombinationMulticastStake(betSlip: any) {
    const itemId =
      betSlip.items.combinationMulticastItems[betSlip.items.combinationMulticastItems.length - 1].itemId;
    const tempStake: StakeModel = {
      ItemId: itemId,
      OverallStake: this.defaultOverallStake,
      IsEachWay: false,
      Group: StakeGroup.SINGLES,
    };
    return tempStake;
  }

  autoFreeBetsStake(betSlip: any) {
    const possibleSystemsLength = betSlip.possibleSystems.length;
    const voucher = betSlip.bonus.freeBetVouchers[0];

    const tempStake: StakeModel = {
      VoucherCode: voucher.voucherCode,
      OverallStake: 0,
      IsEachWay: false,
      Group: "",
    };

    if (possibleSystemsLength < 2) {
      tempStake.ItemId = 1;
      tempStake.Group = StakeGroup.SINGLES;
    } else {
      tempStake.SystemId = betSlip.possibleSystems.find(
        (system) => system.systemType == StakeGroup.ACCUMULATOR,
      ).systemId;
      tempStake.Group = StakeGroup.ACCUMULATOR;
    }

    return tempStake;
  }

  checkSystemsAmount(ticketStte: any, filter: number): any {
    const freebets = !!ticketStte.ticket.FreeBetVoucherId;
    let OverallStake = this.defaultOverallStake;

    switch (filter) {
      case 2:
        let systemId = 2;
        if (ticketStte.ticket.StakeGroups.System.systemId) {
          const isActualSystemElectable = ticketStte.betSlip.possibleSystems.find(
            (i) =>
              (i.systemId == ticketStte.ticket.StakeGroups.System.systemId && i.systemType == StakeGroup.SYSTEM) ||
              i.systemType == StakeGroup.MULTI_WAY_SYSTEM,
          );
          const possibleSystemSelection =
            isActualSystemElectable && isActualSystemElectable.length > 0
              ? isActualSystemElectable
              : ticketStte.betSlip.possibleSystems.find((i) => i.systemId == systemId);
          const minValue =
            ticketStte.ticket.SystemSelected.numberOfBetWays > possibleSystemSelection.numberOfBetWays
              ? ticketStte.ticket.SystemSelected.numberOfBetWays
              : possibleSystemSelection.numberOfBetWays;
          const totalAmount = ticketStte.ticket.StakeGroups.System.totalAmount;

          systemId = possibleSystemSelection.systemId;
          OverallStake = totalAmount && totalAmount >= minValue ? totalAmount * 100 : minValue * 100;
        } else {
          const system = ticketStte.betSlip.possibleSystems.find(
            (i) => i.systemType == StakeGroup.SYSTEM || i.systemType == StakeGroup.MULTI_WAY_SYSTEM,
          );
          const minValue = system.numberOfBetWays;
          OverallStake = minValue * this.defaultOverallStake;
        }

        const systemStake: StakeModel = {
          SystemId: systemId,
          IsEachWay: false,
          Group: "",
          OverallStake: freebets ? 0 : OverallStake,
          VoucherCode: freebets ? ticketStte.ticket.FreeBetVoucherId : "",
        };

        switch (systemId) {
          case 2:
            systemStake.Group = StakeGroup.DOUBLES;
            break;
          case 3:
            systemStake.Group = StakeGroup.TRIPLES;
            break;
          case 4:
            systemStake.Group = StakeGroup.CUADRUPLES;
            break;
          default:
            systemStake.Group = `${StakeGroup.SYSTEM}${systemId}`;
            break;
        }
        return systemStake;

      default:
        const accumulator = ticketStte.betSlip.possibleSystems.find((i) => i.systemType == StakeGroup.ACCUMULATOR);
        OverallStake = ticketStte.ticket.StakeGroups.Accumulator.totalAmount
          ? ticketStte.ticket.StakeGroups.Accumulator.totalAmount * 100
          : OverallStake;
        const acumuladasStake: StakeModel = {
          SystemId: accumulator ? accumulator.systemId : 2,
          IsEachWay: false,
          Group: StakeGroup.ACCUMULATOR,
          OverallStake: freebets ? 0 : OverallStake,
          VoucherCode: freebets ? ticketStte.ticket.FreeBetVoucherId : "",
        };
        return acumuladasStake;
    }
  }

  betSlipNumberOfItems(betSlip: any): number {
    let selections = 0;

    if (betSlip.items.combinationMulticastItems.length > 0) {
      selections += betSlip.items.combinationMulticastItems.length;
    }
    if (betSlip.items.singleResultItems.length > 0) {
      selections += betSlip.items.singleResultItems.length;
    }
    if (betSlip.items.smartMarketItems.length > 0) {
      selections += betSlip.items.smartMarketItems.length;
    }
    if (betSlip.items.straightMulticastItems.length > 0) {
      selections += betSlip.items.straightMulticastItems.length;
    }
    return selections;
  }

  generateMultiStake(data: any) {
    const itemIds = [];
    const auxLineItems = data.lineItems as LineItem[];

    auxLineItems.forEach((i) => itemIds.push(i.ItemId));

    const multiStake: StakeModel = {
      IsEachWay: false,
      Group: StakeGroup.SINGLES,
      OverallStake: parseFloat(data.amount) * 100,
      VoucherCode: "",
      SystemId: 1,
      ItemIds: itemIds,
    };
    return multiStake;
  }

  get defaultOverallStake() {
    return +this.globalVars.FEATURES.SPO_DefaultOverallStake * 100 || 1000;
  }

  trackEventCloseTicket(ticket: C_TicketApuestasNew, closeTicketResponse: any): void {
    if (ticket.OrderErrorMessages.length == 0 && closeTicketResponse.TicketId) {
      const betIds = ticket.LineItems ? ticket.LineItems.map((item) => item.ResultsNr).join(",") : null;
      this.trackingService.track({
        eventType: EventTypes.BetCompleted,
        id: closeTicketResponse.TicketId,
        secondParameter: ticket.BetType.toString(),
        description: ticket.Odd.toString(),
        additionalData: {
          userId: this.globalVars.user.username,
          currencyIsoCode: this.globalVars.currencyISOCode,
          betId: betIds,
          data: {
            amount: ticket.Total,
          },
        },
      });
      return;
    }
    this.trackingService.trackEvent([
      EventTypes.ErrorMsgTicket as string,
      "",
      closeTicketResponse.ErrorDescription || "General ErrorMsgTicket",
      "Mensaje de error en el ticket",
      "event",
    ]);
    return;
  }

  processTrackEventCloseTicket(ticket: C_TicketApuestasNew, responseData: any): void {
    if (responseData.isSingleItem) {
      responseData.data.forEach((closeTicketResponse) => {
        this.trackEventCloseTicket(ticket, closeTicketResponse);
      });
      return;
    } else {
      this.trackEventCloseTicket(ticket, responseData.data);
    }
  }

  someMarketIsClosedOnBBL() {
    this.utils.alert(
      true,
      "Atención",
      "Algún mercado está suspendido en este momento. Si el partido no ha terminado espere.",
      "Aceptar",
      () => {},
    );
  }

  // NEW BETLIP UTILS
  BetslipMapSingleResult(gameData): BetslipLineItem {
    console.log("gameData", gameData);
    return {
      Amount: 0,
      AmountLocal: 0,
      BetbuilderData: null,
      BetBuilderId: "",
      CanBeEachWay: false,
      EventDate: gameData.EventDate,
      EventHasHandicap: gameData.EventHasHandicap,
      EventId: gameData.EventId,
      EventName: gameData.EventName,
      GameBetTypeAvailability: gameData.GameBetTypeAvailability,
      GameId: gameData.GameId,
      GameIncludedPlaces: gameData.IncludedPlaces || 0,
      GameIsBetbuilder: gameData.IsBetbuilder || false,
      GameIsLive: gameData.IsLive || false,
      GameIsRace: gameData.IsRace || false,
      GameLocked: false,
      GameName: gameData.GameName,
      GameNotAvailable: false,
      GamePlaceOddsDivisor: gameData.GamePlaceOddsDivisor || "",
      GameSpecialOddsValue: gameData.GameSpecialOddsValue,
      GameTypeId: gameData.GameTypeId,
      ItemId: 0,
      LeagueId: gameData.LeagueId,
      LocationId: gameData.LocationId,
      NodeId: gameData.NodeId,
      Odd: gameData.Odd,
      OddDown: gameData.OddDown,
      OddUp: gameData.OddUp,
      RaceData: null,
      ResultName: gameData.Name,
      ResultsNr: gameData.NodeId,
      Selected: false,
      SportHandle: gameData.SportHandle,
      SportId: gameData.SportId,
      TicketNumber: 0,
      TotalPotentialWin: 0,
    };
  }

  BetslipUpdateLineItem(betslipData: any, lineItems: BetslipLineItem[], stake: StakeModel): BetslipLineItem[] {
    const updatedLineItems = [...lineItems];

    return updatedLineItems;
  }

  createLineItem(game): BetslipLineItem {
    return {
      Amount: 0,
      AmountLocal: 0,
      BetbuilderData: null,
      BetBuilderId: "",
      CanBeEachWay: false,
      EventDate: game.EventDate,
      EventHasHandicap: game.EventHasHandicap,
      EventId: game.EventId,
      EventName: game.EventName,
      GameBetTypeAvailability: game.GameBetTypeAvailability,
      GameId: game.GameId,
      GameIncludedPlaces: game.IncludedPlaces || 0,
      GameIsBetbuilder: game.IsBetbuilder || false,
      GameIsLive: game.IsLive || false,
      GameIsRace: game.IsRace || false,
      GameLocked: false,
      GameName: game.GameName,
      GameNotAvailable: false,
      GamePlaceOddsDivisor: game.GamePlaceOddsDivisor || "",
      GameSpecialOddsValue: game.GameSpecialOddsValue,
      GameTypeId: game.GameTypeId,
      ItemId: 0,
      LeagueId: game.LeagueId,
      LocationId: game.LocationId,
      NodeId: game.NodeId,
      Odd: game.Odd,
      OddDown: false,
      OddUp: false,
      Selected: false,
      RaceData: null,
      ResultName: game.Name,
      ResultsNr: game.NodeId,
      SportHandle: game.SportHandle,
      SportId: game.SportId,
      TicketNumber: 0,
      TotalPotentialWin: 0,
    };
  }
}
