import { ErrorService } from "@providers/ErrorService";
import { Observable } from "rxjs";
import { UserService } from "../UserService";
import { PaymentMethodBehaviour, PaymentMethodBehaviourResult, SkipAmountResult } from "./PaymentMethodBase";

export class PuntoPagoMethodBehaviour extends PaymentMethodBehaviour {
  minDepositAmount: number;
  maxDepositAmount: number;
  amountButtons: number[];
  name = "Punto Pago";
  image = "puntoPagoLogo";
  track = "AccessToPuntoPago";
  isBank = false;
  declare amount: number;

  constructor(userService: UserService, errorService: ErrorService) {
    super(false, userService, errorService);
  }
  skipAmountInput(): Observable<SkipAmountResult> {
    throw new Error("Method not implemented.");
  }

  paymentMetodExecution(): Observable<PaymentMethodBehaviourResult> {
    throw new Error("Method not implemented.");
  }
}
