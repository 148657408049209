/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from "@angular/common";
import {
  AfterContentInit,
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  QueryList,
  ViewChild,
  ViewContainerRef,
  inject,
} from "@angular/core";
import { Router } from "@angular/router";
import { BetDisplayDirective } from "@directives/bet-display/bet-display";
import { TranslateModule } from "@ngx-translate/core";
import { DeviceService } from "@services/device.service";

@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: "sb-grid-item",
  templateUrl: "./sb-grid-item.html",
  standalone: true,
  imports: [CommonModule, TranslateModule, BetDisplayDirective],
  styleUrls: ["./sb-grid-item.scss"],
})
export class SbGridItemComponent implements OnChanges, AfterContentInit {
  @ViewChild("content") content: ElementRef;
  @ViewChild("bets", { read: ViewContainerRef }) bets: ViewContainerRef;
  @ContentChildren(BetDisplayDirective) betsContainer: QueryList<any>;
  @Input() mode = "teams";
  @Input() event;
  @Input() betType: string;
  @Input() special: boolean;
  @Input() hasBets = true;
  @Input() currentPage: string;
  @Output() navigate: EventEmitter<any> = new EventEmitter();
  @HostBinding("class.has-special-bets")
  hasContent: boolean;
  isDesktop = false;
  displayCss = "flex";

  deviceService = inject(DeviceService);
  private router = inject(Router);

  constructor(private cd: ChangeDetectorRef) {
    this.isDesktop = this.deviceService.isDesktop();
  }

  ngOnChanges() {
    this.canBet();
    this.cd.detectChanges();
    if (this.router.routerState.snapshot.url.indexOf("SpecialTeam") > 0) {
      this.displayCss = "block";
    }
  }

  ngAfterContentInit() {
    this._hasNoContent();
  }

  private _hasNoContent() {
    this.hasContent = Boolean(this.content?.nativeElement?.childElementCount);
    this.cd.detectChanges();
  }

  itemHasContent() {
    return !this.content?.nativeElement?.childElementCount || false;
  }

  private getResults(obj) {
    const results = obj?.Results ? "Results" : "GroupResults";
    return obj[results]?.length;
  }

  private canBet() {
    if (this.event) {
      if (this.event.isSixPack || this.event.isCombined) {
        return;
      }
      const results = this.event.Games
        ? this.event.Games.reduce((acc, game) => (acc += this.getResults(game)), 0)
        : this.getResults(this.event);
      this.hasBets = Boolean(results);
    }
  }
}
