import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, AlertOptions } from "@ionic/angular";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateService } from "@ngx-translate/core";
import { MSO_PATHS } from "@shared-constants/routes";
import { EventsService } from "./EventsService";
import { NewBaseService } from "./newBase.service";

@Injectable({ providedIn: "root" })
export class VerificationAlerts {
  globalVars!: GlobalVarsModel;
  newBaseService = inject(NewBaseService);
  router = inject(Router);

  constructor(
    public translate: TranslateService,
    public events: EventsService,
    public alertCtrl: AlertController,
  ) {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  async showPopupDNIExpired(days?: number) {
    let msg = "";
    const daysSinceFirstCheck = days || this.globalVars.extraUserData.daysSinceFirstCheck;
    if (this.hasToBeBlockedByDni(daysSinceFirstCheck)) {
      msg =
        this.globalVars?.LICENSE_TYPE === LicenseTypes.Colombia
          ? "La vigencia del documento registrado ha expirado. En cumplimiento con la Regulación Colombiana, debes actualizarlo para poder reactivar tu actividad en nuestra plataforma. Hasta tanto, no podrás depositar ni iniciar ninguna actividad de juego. Por favor actualízala a la mayor brevedad a través del a través del botón de “Actualizar ahora”"
          : this.translate.instant("notificationplayersafter45");
      this.globalVars.extraUserData.blockDeposits = true;
      this.globalVars.extraUserData.blockWithdrawals = this.globalVars?.LICENSE_TYPE !== LicenseTypes.Colombia;
      this.globalVars.extraUserData.blockBets = this.globalVars?.LICENSE_TYPE === LicenseTypes.Colombia;
    } else {
      msg =
        this.globalVars?.LICENSE_TYPE === LicenseTypes.Colombia
          ? "Te recordamos que la vigencia del documento registrado expirará pronto. Actualizar tu documentación es importante para poder continuar con tu actividad en nuestra plataforma. Una vez expirada no podrás depositar ni iniciar ninguna actividad de juego. Por favor actualízala a la mayor brevedad a través del botón de “Actualizar ahora”."
          : this.translate.instant("notificationplayersbefore45");
    }
    const alertOpt: AlertOptions = {
      header: `${this.translate.instant("playresponsibly")}`,
      cssClass: "verificationAlert",
      message: msg,
      buttons: [
        {
          text: this.translate.instant("updatenow"),
          handler: () => {
            this.events.publish("documentverification:open");
          },
        },
      ],
    };
    return this.presentAlert(alertOpt);
  }

  private hasToBeBlockedByDni(daysSinceFirstCheck: number): boolean {
    const isColombia = this.globalVars?.LICENSE_TYPE === LicenseTypes.Colombia;
    const blockUser = (isColombia && daysSinceFirstCheck >= 90) || daysSinceFirstCheck >= 30;
    return blockUser;
  }

  async showPopupProfesion(days?: number) {
    const daysSinceFirstCheck = days || this.globalVars.extraUserData.daysSinceFirstCheckProf;
    const alertOpt: AlertOptions = {
      header: "Atención",
      cssClass: "verificationAlert",
      message: "Como parte del proceso de verificación, es necesario rellenar la información de la profesión.",
      buttons: [
        {
          cssClass: "confirmBtnProfesion",
          role: "confirm",
          handler: () => {
            this.router.navigateByUrl(`${MSO_PATHS.BASE}/${MSO_PATHS.PersonalInfoPage}`);
          },
          text: "Actualizar",
        },
      ],
    };
    if (daysSinceFirstCheck < 45) {
      alertOpt.buttons.unshift({
        cssClass: "cancelBtnProfesion",
        role: "cancel",
        text: "",
      });
    } else {
      this.globalVars.extraUserData.blockDeposits = true;
      this.globalVars.extraUserData.blockWithdrawals = true;
      alertOpt.message =
        "Como parte del proceso de verificación, es necesario rellenar la información de la profesión para poder continuar con tu actividad en Codere.";
    }

    return this.presentAlert(alertOpt);
  }

  isDniExpired() {
    return this.globalVars.FEATURES.MSO_CheckDNIExpired && this.globalVars.extraUserData.dniExpired;
  }

  isProfesionRequired() {
    return this.globalVars.FEATURES.MSO_CheckProfesion && !this.globalVars.extraUserData.haveProfesion;
  }

  public checkDNIExpired() {
    if (this.isDniExpired()) {
      this.showPopupDNIExpired();
    }
  }

  private async presentAlert(alertOpt: AlertOptions): Promise<any> {
    const alert = await this.alertCtrl.create(alertOpt);
    const alertHeader = alert.querySelector("h2");
    const imgHeader = document.createElement("img");
    imgHeader.classList.add("icon-circle-info");
    imgHeader.src = "assets/global/img/icon-circle-info.svg";
    alertHeader.parentNode.insertBefore(imgHeader, alertHeader);
    await alert.present();
    return new Promise((resolve, _) => {
      alert.onDidDismiss().then(({ data, role }) => {
        resolve({ data, role });
      });
    });
  }
}
