import { CommonModule } from "@angular/common";
/* eslint-disable */
import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BehaviorSubject, Subscription, fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, tap } from "rxjs/operators";

import { ParseStakeMoney } from "@pipes/parseStakeMoney";

import { LineItem } from "@sports-models/index";

import { C_TicketApuestasNew, TicketMethods } from "@sports-models/index";
import { NewTicketFacade } from "@sports-stores/ticket/index";

@Component({
  selector: "sb-betslip-stake-input",
  templateUrl: "./betslip-stake-input.component.html",
  styleUrls: ["./betslip-stake-input.component.scss"],
  standalone: true,
  imports: [CommonModule, FormsModule, ParseStakeMoney],
})
export class SBBetslipStakeComponent {
  @Input() stakeAmount: string = "";
  @Input() currency: string;
  @Input() minBetValue: string;
  @Input() mode: string;
  @Input() ticket: C_TicketApuestasNew;
  @Input() lineItem: LineItem = null;
  @Input() finalRow: number = null;
  @Input() stakeAmountAux: BehaviorSubject<string> = new BehaviorSubject(null);
  @Input() disabled: boolean;
  @Output() update: EventEmitter<any> = new EventEmitter();
  @Output() select: EventEmitter<any> = new EventEmitter();
  @Output() unselect: EventEmitter<any> = new EventEmitter();
  @ViewChild("stake") stake: ElementRef;
  stakeLabel: string = "Apuesta";

  private subscription$: Subscription = new Subscription();
  private _selected: boolean = false;
  private _prevStake: string;
  parseStakeMoney = inject(ParseStakeMoney);

  private newTicketFacade = inject(NewTicketFacade);

  SingleStakeLimits: number;
  SystemStakeLimits: number;

  ngOnChanges() {
    if (this.newTicketFacade.changingStake$.getValue()) return;
    if (this.ticket) {
      this.SingleStakeLimits = this.ticket.SingleStakeLimits;
      this.SystemStakeLimits = this.ticket.SystemStakeLimits;
    }
    if (this.lineItem && !this.finalRow) {
      this.stakeAmountAux.next(this.lineItem.Amount.toString());
      this.stakeAmount = this.lineItem.Amount.toString();
      this.newTicketFacade.changingStake$.next(false);
    } else if (this.finalRow) {
      this.stakeAmountAux.next(this.finalRow.toString());
      this.stakeAmount = this.finalRow.toString();
      this.newTicketFacade.changingStake$.next(false);
    }
  }

  ngAfterViewInit() {
    //Observable.fromEvent --> fromEvent
    const update$ = fromEvent(this.stake.nativeElement, "keyup")
      .pipe(
        tap((event: any) => {
          this.stakeAmountAux.next(event.target.value);
          this.newTicketFacade.changingStake$.next(true);
        }),
      )
      .pipe(
        distinctUntilChanged(),
        debounceTime(2000),
        filter(Boolean),
        filter((amount) => !Number.isNaN(amount)),
      )
      .subscribe((event: any) => {
        const inputAmount = event.target.value;
        let amount;

        if (!!this.ticket) {
          switch (this.ticket.BetType) {
            case 2: // Multi
              amount = this.setSystemAmountController(inputAmount);
              break;
            case 1: // Acumulator
            default: // Singles
              amount = this.setSingleAmountController(inputAmount);
              break;
          }
        }

        const systemId = this.systemId();
        const data = {
          amount,
          betType: this.ticket.BetType,
          systemId: systemId,
          lineItem: this.lineItem,
          lineItems: this.newTicketFacade.getAllItems(),
          multiSingleAmount: TicketMethods.getNumberOfSelections(this.ticket),
        };

        this.update.emit(data);

        if (this.finalRow && +this.stakeAmount <= +this.minBetValue && data.betType == 0) {
          const betAmount = +this.minBetValue * TicketMethods.getNumberOfSelections(this.ticket);
          this.stakeAmountAux.next(betAmount.toString());
          this.stakeAmount = betAmount.toString();
        }
      });

    const select$ = fromEvent(this.stake.nativeElement, "focus")
      .pipe(
        tap((e: any) => {
          this._prevStake = this.stakeAmount;
          this.stakeAmount = null;
          e.target.value = null;
          this.stakeAmountAux.next(null);
        }),
        filter((_) => !this._selected),
      )
      .subscribe(() => {
        this.newTicketFacade.changingStake$.next(true);
        this._selected = true;
        this.select.emit();
      });

    const blur$ = fromEvent(this.stake.nativeElement, "blur").subscribe(() => {
      if (!this.stakeAmount) {
        this.stakeAmount = this._prevStake;
        this.newTicketFacade.changingStake$.next(false);
      }
      this.stakeAmountAux.next(this.stakeAmount.toString());
      this._selected = false;
      this.unselect.emit();
    });

    this.subscription$.add(update$);
    this.subscription$.add(select$);
    this.subscription$.add(blur$);
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }

  @HostBinding("class.currency-right") get currencyEuro() {
    return this.currency === "€";
  }

  @HostBinding("class.currency-left") get currencyDollar() {
    return this.currency === "$";
  }

  @HostBinding("class.simple-view")
  get isSimpleView() {
    return !this.finalRow;
  }

  checkCurrency() {
    return this.currency == "€";
  }

  setSmallText() {
    return (
      (this.finalRow && this.stakeAmount && this.stakeAmount.toString().length > 8) ||
      (!this.finalRow && this.stakeAmount && this.stakeAmount.toString().length > 6)
    );
  }

  @HostBinding("class.text-small")
  get prueba() {
    return this.finalRow && this.stakeAmount && this.stakeAmount.toString().length > 8;
  }

  setSingleAmountController(inputAmount: string) {
    const amount =
      parseFloat(inputAmount) > parseFloat(this.minBetValue)
        ? parseFloat(inputAmount) * 100 > this.SingleStakeLimits
          ? (this.SingleStakeLimits / 100).toString()
          : inputAmount.toString()
        : this.minBetValue;
    this.stakeAmount = amount;
    this.stakeAmountAux.next(amount);
    return amount;
  }

  setSystemAmountController(inputAmount: string) {
    const minValue = this.ticket.SystemSelected.numberOfBetWays * parseFloat(this.minBetValue);
    const amount =
      parseFloat(inputAmount) > minValue
        ? parseFloat(inputAmount) * 100 > this.SystemStakeLimits
          ? (this.SystemStakeLimits / 100).toString()
          : inputAmount.toString()
        : minValue.toString();
    this.stakeAmount = amount;
    this.stakeAmountAux.next(amount);
    return amount;
  }

  systemId() {
    let systemId: number;

    switch (this.ticket.BetType) {
      case 0:
        systemId = 1;
        break;
      case 1:
        systemId = this.ticket.StakeGroups.Accumulator.systemId;
        break;
      case 2:
        systemId = this.ticket.StakeGroups.System.systemId;
        break;

      default:
        break;
    }

    return systemId;
  }
  getTicketHasFreebets(ticket: C_TicketApuestasNew): boolean {
    return TicketMethods.getHasFreebet(ticket);
  }
}
