export class C_Sport {
  BookmakerID: number;
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  EventNodeTypeId: number;
  Priority: number;
  SportHandle: string;
  ChildrenCount: number;
  highlight: any[];
  countries: { collapsed: boolean; data: any[] };
  collapsed: boolean = true;
  isnew: boolean;
  Locked: boolean;
  isCollapsible: boolean;

  constructor(
    isnew: boolean,
    locked: boolean,
    name: string,
    nodeId: string,
    parentNodeId: string,
    priority: number,
    sporthandle: string,
    isCollapsible: boolean,
    countries?: any,
    highlight?: any[],
  ) {
    this.countries = { collapsed: true, data: [] };
    this.highlight = [];
    this.isnew = isnew;
    this.Locked = locked;
    this.Name = name;
    this.NodeId = nodeId;
    this.ParentNodeId = parentNodeId;
    this.Priority = priority;
    this.SportHandle = sporthandle;
    this.isCollapsible = isCollapsible;
  }
}

export interface C_SportSidebar {
  BookmakerID: number;
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  EventNodeTypeId: number;
  Priority: number;
  SportHandle: string;
  ChildrenCount: number;
  highlight: any[];
  countries: { collapsed: boolean; data: any[] };
  collapsed: boolean;
  isnew: boolean;
  Locked: boolean;
  isCollapsible: boolean;
}
