import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, Component, inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AlertController, IonicModule } from "@ionic/angular";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { of, switchMap } from "rxjs";

// MODELS
import { GlobalVarsModel } from "@models/ModelGlobalsVar";

import { NewBaseService } from "@providers/newBase.service";
// SERVICES
import { DeviceService } from "@services/device.service";

// STORES
import { NewTicketFacade } from "@sports-stores/ticket/index";

@Component({
  selector: "betslip-unlocker-page",
  templateUrl: "betslip-unlocker.html",
  styleUrls: ["betslip-unlocker.scss"],
  host: {
    class: "betslip-unlocker",
  },
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule],
})
export class BetslipUnlockerPage {
  globals: GlobalVarsModel;
  router = inject(Router);
  deviceService = inject(DeviceService);
  newBaseService = inject(NewBaseService);
  isMobile: boolean = false;

  ticketFacade = inject(NewTicketFacade);
  translate = inject(TranslateService);
  alertCtrl = inject(AlertController);

  mainMessage = this.translate.instant("SPO_BetslipUnlockerText").split("<br />");

  constructor(public sanitizer: DomSanitizer) {
    this.isMobile = this.deviceService.isMobile();
    this.newBaseService.getVars
      .pipe(
        switchMap((data: GlobalVarsModel) => {
          this.globals = data;
          this.globals.showNavbar = false;
          return of(null);
        }),
      )
      .subscribe();
  }

  clearAll() {
    this.showRestartTicketAlert();
  }

  private async showRestartTicketAlert() {
    const msg = this.translate.instant("SPO_BetslipUnlockerText");
    const alert = await this.alertCtrl.create({
      header: "Importante",
      message: msg,
      buttons: [
        {
          text: "Continuar",
          handler: () => {
            this.ticketFacade.restartBetslip();
            this.globals.showNavbar = true;
            this.router.navigate(["/HomePage"]);
          },
        },
        {
          text: this.translate.instant("tCancel"),
          role: "cancel",
          handler: () => {},
        },
      ],
    });
    alert.present();
  }
}
