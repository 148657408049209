import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, type OnChanges, Output, inject } from "@angular/core";
import { HostBinding } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { IonicModule } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { C_Jackpot } from "@models/casino/lobby/C_Jackpot";
import { NewBaseService } from "@providers/newBase.service";
import { JackpotCounterComponent } from "../jackpot-counter/jackpot-counter";
@Component({
  selector: "lb-jackpot",
  templateUrl: "./jackpot-card.html",
  styleUrls: ["./jackpot-card.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule, JackpotCounterComponent],
})
export class JackpotCardComponent implements OnChanges {
  @Input() jackpot: C_Jackpot;
  @Output() join: EventEmitter<any> = new EventEmitter();
  @Output() info: EventEmitter<any> = new EventEmitter();

  cardImage: string;
  globalVars!: GlobalVarsModel;
  newBaseService = inject(NewBaseService);

  constructor(private _domSanitizer: DomSanitizer) {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  ngOnChanges() {}

  @HostBinding("style.background-image") get background() {
    const backgroundImg = this._domSanitizer.bypassSecurityTrustStyle(
      "url(" + this.jackpot.uiInfo.backgroundImageUrl + ")",
    );
    return backgroundImg;
  }
}
