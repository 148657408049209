import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, Component, inject } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, Subscription, of, switchMap } from "rxjs";

// Models
import { C_NodeTicket } from "@models/C_ResponseTickets";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { I_MyBetsModel } from "@sports-models/index";

// Stores
import { MyBetsFacade } from "@sports-stores/my-bets";
import { MyBetsState } from "@sports-stores/my-bets";
import * as MyBetsSelectors from "@sports-stores/my-bets/my-bets.selectors";

// Services
import { NewBaseService } from "@providers/newBase.service";
import { SportMyBetsService } from "@sports-services/sports.my-bets.service";
import { SportNavigationService } from "@sports-services/sports.navigation.service";

// Pipes
import { CodereFormatDatePipe } from "../../pipes";

import { MyBetsTileComponent } from "@sports-components/my-bets-tile/my-bets-tile.component";
// Components
import { SbTabsComponent } from "@sports-components/sb-tabs/sb-tabs.component";

// Utils
import { SPORTS_PATHS } from "@shared-constants/routes";

@Component({
  standalone: true,
  selector: "my-bets-betslip",
  templateUrl: "my-bets-betslip.html",
  styleUrls: ["./my-bets-betslip.scss"],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [IonicModule, CommonModule, MyBetsTileComponent, SbTabsComponent, CodereFormatDatePipe],
})
export class MyBetsBetslipComponent {
  globalVars: GlobalVarsModel;
  canShowEmpty = false;
  emptyMessage: string;
  auxArrTickets$: BehaviorSubject<C_NodeTicket[]>;
  emptyState: boolean = false;
  showMoreActive: boolean = true;

  newBaseService = inject(NewBaseService);
  translate = inject(TranslateService);

  myBetsService = inject(SportMyBetsService);
  sportNavigationService = inject(SportNavigationService);

  myBetsFacade = inject(MyBetsFacade);
  myBetsStore = inject(Store<MyBetsState>);
  myBetsData$: Observable<any>;
  myBetsDataSub$: Subscription;
  myBetsTickets: I_MyBetsModel[] = [];

  filtersType = [
    { index: "4", name: this.translate.instant("histapabiertas"), disabled: false },
    { index: "All", name: this.translate.instant("cerradas"), disabled: false },
  ];

  selectedFilter = this.filtersType[0];

  constructor() {
    this.newBaseService.getVars
      .pipe(
        switchMap((data: GlobalVarsModel) => {
          this.globalVars = data;
          return of(null);
        }),
      )
      .subscribe();

    this.auxArrTickets$ = new BehaviorSubject([]);
    this.myBetsData$ = this.myBetsStore.select(MyBetsSelectors.getMyBetsState);
    this.myBetsDataSub$ = this.myBetsData$.subscribe((myBetsTicketsState) => {
      this.myBetsTickets = this.setMyBetsTickets(myBetsTicketsState.myBetsTickets);
      this.showMoreActive = true;
      if (myBetsTicketsState.initialStatus) return;
      this.emptyState = this.myBetsTickets.length < 1;
    });
    this.getMyBets();
  }

  changeTicketType(filter) {
    this.selectedFilter = this.filtersType[filter];
    this.getMyBets();
  }

  showMore() {
    let filterParam;
    switch (this.selectedFilter.index) {
      case "4":
        filterParam = { dateValue: 0, typeValue: 1 };
        break;
      default:
        filterParam = { dateValue: 0, typeValue: 0 };
        break;
    }
    this.sportNavigationService.navigateToRoute(SPORTS_PATHS.MisApuestasPage, filterParam);
  }

  getMyBets() {
    if (!this.globalVars.user.logged) return this.globalVars.rootScope.openLogin();
    const params = {
      betType: this.selectedFilter.index,
      ...this.getFilterDate(),
    };
    this.myBetsFacade.fetchMyBets(params);
  }

  getFilterDate() {
    const date = new Date();
    date.setDate(date.getDate() - 2);
    return { date: date.toISOString(), endDate: "" };
  }

  goToEvent(selection) {
    this.sportNavigationService.tryToGoToEvent(selection);
  }

  getCashoutByTicket(params, ticket) {
    this.myBetsFacade.getCashoutByTicket({ ...params, DayOrderNr: ticket.DayOrderNr });
  }

  payOutTicket(params) {
    this.myBetsService.payOutTicket(params);
  }

  get emptyStateMessage() {
    return `No hay apuestas ${this.selectedFilter.name} en los últimos 2 días`;
  }

  setMyBetsTickets(myBetsTicketsData) {
    if (this.selectedFilter.index == "4") return myBetsTicketsData;
    const filteredMyBetsTicketsData = myBetsTicketsData.map((myBetTicketsData) => {
      const filteredTickets = myBetTicketsData.Tickets.filter((ticket) => ticket.TicketStatusNumber != 4);
      return { ...myBetTicketsData, Tickets: filteredTickets };
    });
    return filteredMyBetsTicketsData.filter((item) => item.Tickets.length > 0);
  }
}
