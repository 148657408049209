import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "maxElements",
  standalone: true,
})
export class MaxElementsPipe implements PipeTransform {
  transform(array: any[], maxCount: number): any[] {
    if (!Array.isArray(array)) {
      return [];
    }

    return array.slice(0, maxCount);
  }
}
