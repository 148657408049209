/* eslint-disable eqeqeq */
import { Pipe, PipeTransform } from "@angular/core";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";

@Pipe({ name: "FormatTax" })
export class FormatTax implements PipeTransform {
  globalVars!: GlobalVarsModel;

  transform(t?: number): string {
    if (t == undefined || t == null) return "-";
    return (t * 100).toFixed(2);
  }
}
